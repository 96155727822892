<div mat-dialog-title>
  <span>Editar Comentário</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <span class="mat-body-2">Ação:</span>
  <p class="mat-body-1">{{action.action}}</p>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Comentário</mat-label>
    <textarea name="comment" matInput [(ngModel)]="comment" placeholder="Comentário do Analista"
      rows="8"></textarea>
  </mat-form-field>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close (click)="onSaveClick()" [disabled]="!comment">Salvar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
