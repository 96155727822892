import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { EventModel } from '../../../model/event.model';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { EventService } from '../../../service/model/event.service';
import { ResultOccurrenceDescription } from '../../../model/enums.enum';
import * as moment from 'moment';
import { UserModel } from '../../../model/user.model';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { EventFilterModel } from 'src/app/pages/event/event-filter/event.filter.model';
import { first } from 'rxjs/operators';
import { ESP } from 'src/app/common/constants';

@Component({
  selector: 'app-event-list-dialog',
  templateUrl: './event-list-dialog.component.html',
  styleUrls: ['./event-list-dialog.component.scss']
})
export class EventListDialogComponent implements OnInit {
  /* Lista de eventos */
  displayedColumns: string[];
  eventsModel: EventModel[] = [];
  dataSource: MatTableDataSource<EventModel>;
  selection = new SelectionModel<EventModel>(true, []);
  resultOccurrenceDescription = ResultOccurrenceDescription;

  @ViewChild(MatInput, { static: true }) searchInput: MatInput;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<EventListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public events : EventModel[],
              protected dialog:                MatDialog,
              private logger:                  NGXLogger,
              private eventService:            EventService,
              private toastr:                  ToastrService,
              @Inject(MAT_DIALOG_DATA) public inMemoryFilterModel: EventFilterModel) {

              }

  ngOnInit(): void {
    this.logger.debug('EventListDialogComponent.ngOnInit()');
    this.displayedColumns = ['select', 'validation','identifier', 'result.occurrence', 'date', 'analyst.name',
                             'communicationChannel', 'source', 'duct', 'valve', 'targetPointKM'];


    this.loadRecordsFromServer();
    this.sort.sort({id: "identifier", start: 'asc', disableClear: false});
  }

  loadRecordsFromServer(){
    this.logger.debug('EventListDialogComponent.loadRecordsFromServer()');

    let eventFilterModel = new EventFilterModel();
    this.updateFilterModel(this.inMemoryFilterModel, eventFilterModel);
    
    this.dataSource = new MatTableDataSource(this.eventsModel);
    this.eventService.loadFilteredListFromRestApi(null, null, null, eventFilterModel ).pipe(first()).subscribe((result : EventModel[] ) => {
      this.eventsModel = result ;
      this.buildDataSource();
    },
    error => {
      this.toastr.error("Erro ao buscar os eventos","Erro");
      this.logger.error("Erro ao buscar os eventos " + JSON.stringify(error));
    });
  }

  buildDataSource(){
    this.logger.debug('EventListDialogComponent.buildDataSource()');
    this.dataSource = new MatTableDataSource(this.eventsModel);

    this.dataSource.filterPredicate = (entity: EventModel, filter: string): boolean => {
      return this.getStringEntityForFilter(entity).indexOf(filter) != -1;
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };

    this.dataSource.sort = this.sort;

    this.selection.clear();
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'targetPointKM':
        {
          const val = item['stretchStartKM'] && item['stretchEndKM'] ? item['stretchStartKM'] + ' - ' +
          item['stretchEndKM'] : item['targetPointKM'];
          return val ? val : '';
        }
      default:
        {
          let value = property.split('.').reduce(this.getProperty, item);
          if (typeof value === 'string'){
            value = this.lowerAndTrimText(value);
          }
          return value ? value : '';
        };
    }
  }

   /* reducer method */
  getProperty (o, i) {
    if ( !o ) { return ''; }
    return o[i];
  }

  applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.selection.clear();
  }

  onRemoveSearch(){
    this.searchInput.value = '';
    this.dataSource.filter = '';
    this.selection.clear();
  }

  protected getStringEntityForFilter(event: EventModel): string {
    const identifier = (event.identifier) ? this.lowerAndTrimText(event.identifier) : '';
    const occurrence = event.result && event.result.occurrence ?
      this.lowerAndTrimText(this.resultOccurrenceDescription[event.result.occurrence]) : '';
    const date = (event.date) ? this.formatDate(event.date) : '';
    const analyst = (event.analyst) ? this.lowerAndTrimText(this.getUserNameLoginTitle(event.analyst)) : '';
    const communicationChannel = this.lowerAndTrimText(event.communicationChannel);
    const source = this.lowerAndTrimText(event.source);
    const duct = this.lowerAndTrimText(event.duct);
    const km = event.stretchStartKM && event.stretchEndKM ? event.stretchStartKM + ' - ' +
               event.stretchEndKM : (event.targetPointKM ? event.targetPointKM : '');
    const valve = this.lowerAndTrimText(event.valve);
    return identifier + ESP + status + ESP + occurrence + ESP + date + ESP + analyst + ESP + communicationChannel + ESP + source + ESP + duct + ESP + km + ESP + valve;
  }

  protected lowerAndTrimText(text: string) {
    return (text) ? text.trim().toLowerCase() : '';
  }

  formatDate(date: number){
    if (!date || date == 0)
      return "";
    const startMoment = moment(date);
    return startMoment.format('DD/MM/YYYY HH:mm');
  }

  getUserNameLoginTitle(user) {
    return UserModel.getUserTitle(user);
  }

  onSelectClick() {
    this.dialogRef.close(this.selection.selected);
  }

  masterToggle(){
    if(this.isAllSelected()) {
      this.selection.clear();
    }
    else {
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }
  }

  isAllSelected(){
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  checkboxTip(row?: EventModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Desmarcar' : 'Selecionar'} Todos`;
    }
    return `${this.selection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Evento`;
  }

  onCheckboxClick(alert: EventModel){
    this.selection.toggle(alert);
  }

   /**
   * Atualiza o filtro em memória
   */
  updateFilterModel(sourceFilter: EventFilterModel, targetFilter: EventFilterModel){
    for(const key in sourceFilter){
      targetFilter[key] = sourceFilter[key];
    }
  }

  /*** Valida se pode ser selecionado mais de um evento em caso seja chamado da janela para associar eventos */
  canSelectManyEvents(){
    if(this.inMemoryFilterModel.associatedEventId && this.selection?.selected.length > 0)
      return true;     
    else if (this.selection?.selected.length == 1) 
      return true; 
    return false; 
  }

}
