import { Pipe, PipeTransform } from '@angular/core';
import FieldUtils from '../../service/util/field-utils';

@Pipe({name: 'PointLocationToString'})
export class PointLocationPipe implements PipeTransform {
  transform(location: GeoJSON.Point): String {
    if (!location) {
      return '';
    }

    // inicialização do GeoPoint para alertas está errada, está sendo feito (x=latitude e y=longitude)
    let response = '';
    if (location.coordinates[0]) {
      response += FieldUtils.coordToString(location.coordinates[0]);
    }

    if (location.coordinates[1]) {
      response += ', ' + FieldUtils.coordToString(location.coordinates[1]);
    }

    return response;
  }
}

@Pipe({name: 'LocationToString'})
export class LocationPipe implements PipeTransform {
  transform(location: {lat: number, lng: number}): String {
    if (!location || location.lat == 0 || location.lng == 0) {
      return '';
    }

    return FieldUtils.coordToString(location.lat) + ',' + FieldUtils.coordToString(location.lng);
  }
}
