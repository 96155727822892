import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { PlacementService } from 'src/app/service/model/placement.service';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../../../../service/authorization/authorization.service';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { ESP, SORT_NAME_ASC } from 'src/app/common/constants';
import { RegistrationListComponent } from '../../registration-list-component';
import { RegistrationType } from 'src/app/model/enums.enum';
import { ToastrService } from 'ngx-toastr';
import { PlacementModel } from 'src/app/model/placement.model';

@Component({
  selector: 'app-placement-list',
  templateUrl: './placement-list.component.html',
  styleUrls: ['../../../../app.component.scss', '../../../list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlacementListComponent extends RegistrationListComponent implements OnInit {

  constructor(logger: NGXLogger,
              dialog: MatDialog,
              public authorizationService: AuthorizationService,
              protected storageService:     StorageService,
              placementService: PlacementService,
              protected toastr: ToastrService,
              protected changeDetector:  ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, placementService, dialog, 'placements', environment.PLACEMENT_GROUP_LABEL, environment.PLACEMENT_TITLE_LABEL,
          environment.PLACEMENT_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('PlacementListComponent.constructor()');
    this.registrationType = RegistrationType.PLACEMENT;
    this.sortOptions = SORT_NAME_ASC;
  }

  ngOnInit() {
    super.ngOnInit();

    this.logger.debug('PlacementListComponent.ngOnInit()');
    this.loadRecordsFromServer();
    this.displayedColumns = ['select','gs','regional'];
  }

  protected getDefaultSortColumn(): string {
    return "gs";
  }

  protected getStringEntityForFilter(placement: PlacementModel): string {
    const gs = this.lowerAndTrimText(placement.gs);
    const regional = (placement.regional) ? this.lowerAndTrimText(placement.regional) : '';
    return gs + ESP + regional;
  }
}
