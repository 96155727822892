import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import * as moment from 'moment';

@Component({
  selector: 'date-chooser',
  templateUrl: './date-chooser.component.html',
  styleUrls: ['./date-chooser.component.scss'],
  exportAs: 'dateChooserContext'
})
export class DateChooserComponent {
  @ViewChild('input', { read: MatInput}) input: MatInput;

  _valueData: string;
  @Input()
  set valueData(data: string) {
    this._valueData = data;
    if (this._valueData && this._valueData != "") {
      // parametro strict removido da função moment, pois a data é exibida em dois formatos (DD/MM/YYYY, D/M/YYYY) para habilitar o botão dos filtros
      const formats = ["D/M/YYYY", "DD/MM/YYYY"]; 
      this.date = moment(this._valueData.toString(), formats, true);
      this.invalid = !this.date.isValid();
    }else if(data === ""){
      this.reset();
    }
  }

  get valueData() {
    return this._valueData;
  }

  _readonly: boolean;
  @Input()
  set readonly(val: boolean) {
    this._readonly = val;
  }

  _disabled: boolean;
  @Input()
  set disabled(val: boolean) {
    this._disabled = val;
  }
  
  _required: boolean = false;
  @Input()
  set required(val: boolean) {
    this._required = val;
  }

  // TODO scuri Explicar porque isso é necessário? Se remover para de funcionar
  @Output() valueDataChange = new EventEmitter<string>();

  @Output() dateChangedEvent = new EventEmitter<moment.Moment>();

  @Input() placeholder: String;

  date: moment.Moment;

  invalid: boolean = false;

  onKeyUpEvent(event) {
    let dateString = event.target.value;
    if (dateString != "") {
      this.date = moment(dateString, "D/M/YYYY", true);
      this.invalid = !this.date.isValid();
      if (!this.invalid) {
        this.valueDataChange.emit(this._valueData);
        this.dateChangedEvent.emit(this.date);
      }
    }
    else {
      this.invalid = this._required ? true : false;
    }
  }

  onKeyPressEvent(event){
    if (event.which < 47 || event.which > 57) {
      event.preventDefault();
    }
  }

  onDateChangeEvent() {
    this._valueData = this.date.format("D/M/yyyy");
    this.valueDataChange.emit(this._valueData);
    this.dateChangedEvent.emit(this.date);
  }
  
  reset(){
    if(this.input) this.input.value = '';
  }
}
