import { Injectable } from "@angular/core";
import { FilterModel } from "../../../../general/filter-component/filter.model";

@Injectable({
    providedIn: 'root'
  })
export class PointFilterModel extends FilterModel{
  band: string;
  stretch: string;
  startKm: string;
  endKm:  string;
}
