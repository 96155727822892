import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../../../environments/environment';
import { ServiceResponse } from './service-response';
import { PatrolService } from 'src/app/service/model/patrol.service';
import { AuthenticationService } from '../../pages/login/login-services/authentication.service';
import { RegistrationService } from 'src/app/service/model/registration.service';
import { AlertsService } from '../../service/model/alerts.service';
import { EventService } from '../../service/model/event.service';
import { MarkersService } from '../../service/model/markers.service';
import { SignalsService } from '../../service/model/signals.service';
import { WebSocketSignalsService } from '../../service/model/websocket.signals.service';
import { ReactiveService } from '../../service/model/reactive.service';
import { TrackingService } from 'src/app/service/model/tracking.service';
import { first } from 'rxjs/operators';

declare var require: any;
const appVersion = require('../../../../package.json').version;

@Component({
  selector: 'app-system-check',
  templateUrl: './system-check-dialog.component.html',
  styleUrls: ['../../app.component.scss', './system-check-dialog.component.scss']
})
export class SystemCheckDialogComponent implements OnInit {

  displayedColumns: string[] = ['name', 'url', 'version'];
  dataSource: ServiceResponse[] = [];

  constructor(private logger: NGXLogger,
              private patrolService: PatrolService,
              private authenticationService: AuthenticationService,
              private registrationService: RegistrationService,
              private trackingService: TrackingService,
              private alertsService: AlertsService,
              private eventService: EventService,
              private markersService: MarkersService,
              private signalsService: SignalsService,
              private webSocketSignalsService: WebSocketSignalsService,
              private reactiveService: ReactiveService) { }

  ngOnInit() {
    this.logger.debug('Iniciando dialogo para microserviços');

    // Obtendo informações dos microserviços: Auth, Patrol, Registrations, Tracking, Alert, Event, Marker, Signals, Websocket

    // Passo 1: Iniciando os objetos do datasource
    const alertResponse = new ServiceResponse('Alerts', environment.settings.alerts_address);
    const authResponse = new ServiceResponse('Auth', environment.settings.auth_address);
    const eventResponse = new ServiceResponse('Event Verifications', environment.settings.event_verifications_address);
    const markerResponse = new ServiceResponse('Markers', environment.settings.markers_address);
    const patrolResponse = new ServiceResponse('Patrols', environment.settings.patrols_address);
    const reactiveRouteDeviationResponse = new ServiceResponse('Reactive - RouteDeviation', environment.settings.route_deviation_address);
    const reactiveSIMFResponse = new ServiceResponse('Reactive - SIMF', environment.settings.simf_address);
    const registrationResponse = new ServiceResponse('Registrations', environment.settings.registrations_address);
    const signalResponse = new ServiceResponse('Signals', environment.settings.signals_address);
    const trackingResponse = new ServiceResponse('Tracking', environment.settings.tracking_address);
    const webResponse = new ServiceResponse('Web', environment.settings.web_address);
    const websocketResponse = new ServiceResponse('Websocket', environment.settings.websocket_http_address);

    // Passo 2: Adicionando os objetos à tabela de serviços (ordem alfabética)
    this.dataSource.push(alertResponse);
    this.dataSource.push(authResponse);
    this.dataSource.push(eventResponse);
    this.dataSource.push(markerResponse);
    this.dataSource.push(patrolResponse);
    this.dataSource.push(reactiveRouteDeviationResponse);
    this.dataSource.push(reactiveSIMFResponse);
    this.dataSource.push(registrationResponse);
    this.dataSource.push(signalResponse);
    this.dataSource.push(trackingResponse);
    this.dataSource.push(webResponse);
    this.dataSource.push(websocketResponse);

    // Passo 3: Obtendo resposta dos serviços

    webResponse.version = appVersion;

    // Auth
    this.authenticationService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto auth ${data}`);
        authResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto auth ', error);
        authResponse.version = 'Não Disponível';
      }
    );

    // Patrol
    this.patrolService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto patrol ${data}`);
        patrolResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto patrol ', error);
        patrolResponse.version = 'Não Disponível';
      }
    );

    // Registration
    this.registrationService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto registrations ${data}`);
        registrationResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto registrations ', error);
        registrationResponse.version = 'Não Disponível';
      }
    );

    // Tracking
    this.trackingService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto tracking ${data}`);
        trackingResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto tracking ', error);
        trackingResponse.version = 'Não Disponível';
      }
    );

    // Alert
    this.alertsService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto alert ${data}`);
        alertResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto alert ', error);
        alertResponse.version = 'Não Disponível';
      }
    );

    // Event/Verification
    this.eventService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto event-verification ${data}`);
        eventResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto event-verification ', error);
        eventResponse.version = 'Não Disponível';
      }
    );

    // Marker
    this.markersService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto marker ${data}`);
        markerResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto marker ', error);
        markerResponse.version = 'Não Disponível';
      }
    );

    // Signals
    this.signalsService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto signals ${data}`);
        signalResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto signals ', error);
        signalResponse.version = 'Não Disponível';
      }
    );

    // WebSocket
    this.webSocketSignalsService.getServiceVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto websocket ${data}`);
        websocketResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto websocket ', error);
        websocketResponse.version = 'Não Disponível';
      }
    );

    // Reactive - Route Deviation
    this.reactiveService.getRouteDeviationVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto reactive - route deviation ${data}`);
        reactiveRouteDeviationResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto reactive - route deviation ', error);
        reactiveRouteDeviationResponse.version = 'Não Disponível';
      }
    );

    // Reactive - Route Deviation
    this.reactiveService.getSIMFVersion().pipe(first()).subscribe(
      (data) => {
        this.logger.debug(`Versão recuperada pelo projeto reactive - simf ${data}`);
        reactiveSIMFResponse.version = data;
      },
      (error) => {
        this.logger.error('Erro ao obter a versão do projeto reactive - simf ', error);
        reactiveSIMFResponse.version = 'Não Disponível';
      }
    );
  }

}
