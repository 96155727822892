import { EventMainActionModel } from './event.main.action.model';
import { ResultsModel } from './results.model';
import { UserModel } from './user.model';
import { CommonModel } from './common.model';
import { ResultOccurrenceDescription, EventStatus, EventStatusDescription } from './enums.enum';
import { ValidationNoteModel } from './validation.note.model';
import DateUtils from '../service/util/date-utils';
import { VerificationModel } from './verification.model';
import { InspectionModel } from './inspection.model';
import { AttachmentModel } from './attachment.model';
import LanguageUtils from '../service/util/language-utils';

export class EventModel extends CommonModel {

  analyst:              UserModel;
  date:                 number; // TODO scuri Deveria ser startDate
  communicationChannel: string;
  who:                  string;
  source:               string;
  alertLevel:           string;
  operatorCNCL:         UserModel;
  coturCNCL:            UserModel;
  situation:            string;
  product:              string;
  flow:                 string;
  stolenVolume:         string;
  callId168:            string;
  category168:          string;
  subcategory168:       string;
  validation:           string;
  validationAnalyst:    UserModel;
  validationNotes:      ValidationNoteModel[];
  band:                 string;
  duct:                 string;
  valve:                string;
  targetPointKM:        string; // Mesmo que km em operações
  targetPointLatLong:   string; // Mesmo que latLong em operações
  stretchStartKM:       string;
  stretchEndKM:         string;
  stretchStartLatLong:  string;
  stretchEndLatLong:    string;
  stretch:              string;
  zipCode:              string;
  street:               string;
  city:                 string;
  state:                string;
  result:               ResultsModel;
  actions:              EventMainActionModel[];
  associatedEventId:    string; // evento ao qual este evento é associado
  associatedIdentifier: string; // identificador do evento ao qual este evento é associado
  suspiciousDate:       number;  
  stopDate:             number;
  startDate:            number;
  endDate:              number;
  attachmentOpFile1:    AttachmentModel;
  attachmentOpFile2:    AttachmentModel;
  attachmentOpFile3:    AttachmentModel;
  attachmentOpFile4:    AttachmentModel;
  attachmentOpFile5:    AttachmentModel;

  verifications:        VerificationModel[]; // usado somente na exportação de eventos
  pointsDescription:    string; // usado somente na exportação de eventos
  requestedPoints;      number; // usado somente na exportação de eventos
  visitedPoints:        number; // usado somente na exportação de eventos
  pointsDiff:           number; // usado somente na exportação de eventos
  noAccessPoints:       InspectionModel[]; // usado somente na exportação de eventos

  constructor(){
    super();
    this.actions = [];
    this.result = new ResultsModel();
    this.archived = false;
    this.status = EventStatus.IN_TREATMENT;
    this.verifications = [];
  }


  public static getPopupContent(event: EventModel, search: boolean = false){
    return `<h5 style="text-align: center">Evento</h5>
            ${event.identifier? `<div> <b>ID:</b> ${ event.identifier } </div>` : ''}
            <div> <b>Status:</b> ${ EventStatusDescription[event.status] } </div>
            ${event.result && event.result.occurrence ?
              `<div> <b>Ocorrência:</b>  ${ ResultOccurrenceDescription[event.result.occurrence]}</div>` : ''}
            <div> <b>Data/Hora Criação:</b>  ${ DateUtils.timestampToStringInMinutes(event.date)} </div>
            <div> <b>Analista:</b>  ${event.analyst ? event.analyst.name : ''} </div>
            <div> <b>Canal de Comunicação:</b>  ${event.communicationChannel}</div>
            <div> <b>Origem:</b>  ${event.source}</div>
            ${event.duct ? `<div> <b>Duto:</b>  ${event.duct}</div>` : ""}
            ${event.valve ? `<div> <b>Válvula:</b>  ${event.valve}</div>` : ""}
            ${(event.stretchStartKM && event.stretchEndKM) || event.targetPointKM ?
              `<div> <b>KM:</b>  ${event.stretchStartKM && event.stretchEndKM ? event.stretchStartKM + " - " + event.stretchEndKM : event.targetPointKM}</div>` : ""}
            ${!search && ((event.stretchStartLatLong && event.stretchEndLatLong) || event.targetPointLatLong) ?
              `<div> <b>Lat, Long:</b>  ${event.stretchStartLatLong && event.stretchEndLatLong ?
              event.stretchStartLatLong + " - " + event.stretchEndLatLong : event.targetPointLatLong} </div>` : ""}
          `;
  }
  
  static addLanguageMap(){
    LanguageUtils.languageMap['analyst'] = 'Analista CCPD';
    LanguageUtils.languageMap['validation'] = 'Validação';
    LanguageUtils.languageMap['validationAnalyst'] = 'Analista Validação';
    LanguageUtils.languageMap['validationNotes'] = 'Notas de Validação';
    LanguageUtils.languageMap['stopDate'] = 'Data de Parada';
    LanguageUtils.languageMap['suspiciousDate'] = 'Data de Suspeita';
    LanguageUtils.languageMap['flow'] = 'Vazão - m³/h';
    LanguageUtils.languageMap['stolenVolume'] = 'Volume Subtraído (m³)';
    LanguageUtils.languageMap['callId168'] = 'ID Chamada 168';
    LanguageUtils.languageMap['category168'] = 'Categoria 168';
    LanguageUtils.languageMap['subcategory168'] = 'Subcategoria 168';
    LanguageUtils.languageMap['date'] = 'Data de Início';
    LanguageUtils.languageMap['communicationChannel'] = 'Canal de Comunicação';
    LanguageUtils.languageMap['zipCode'] = 'CEP';
    LanguageUtils.languageMap['street'] = 'Logradouro';
    LanguageUtils.languageMap['validation'] = 'Validação';
    LanguageUtils.languageMap['alertLevel'] = 'Nível de Alerta';
    LanguageUtils.languageMap['operatorCNCL'] = 'Operador CNCL';
    LanguageUtils.languageMap['coturCNCL'] = 'Cotur CNCL';
    LanguageUtils.languageMap['actions'] = 'Ações Principais';
    LanguageUtils.languageMap['state'] = 'UF';
    LanguageUtils.languageMap['who'] = 'Quem?';
    LanguageUtils.languageMap['situation'] = 'Situação';
    LanguageUtils.languageMap['source'] = 'Origem';
    LanguageUtils.languageMap['targetPointKM'] = 'KM';
    LanguageUtils.languageMap['targetPointLatLong'] = 'KM (Lat, Long)';
  }
}
