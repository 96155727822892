<div mat-dialog-title>
    <span>Atenção!</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pl-3 pr-2">
    <mat-form-field fxFlex appearance="outline" class="mr-2">
      <textarea matInput name="observations" matInput rows="5" [(ngModel)]="observations" readonly></textarea>
    </mat-form-field>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]=true mat-dialog-close>Fechar</button>
</mat-dialog-actions>
