import { ChangeDetectorRef, Directive, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import * as GoldenLayout from "golden-layout";
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from "ngx-golden-layout";
import { NGXLogger } from "ngx-logger";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { EntityModel } from '../model/entity.model';
import { AuthorizationService } from "../service/authorization/authorization.service";
import { ArchiveService } from "../service/model/archive.service";
import { StorageService } from "../service/storage-service";
import { OperationModel } from '../model/operation.model';
import { HttpError, MAP_PAGE, MESSAGE_PAGE, PATROL_UPDATE_EVENT, VERIFICATION_UPDATE_EVENT } from '../common/constants';
import { TrackingService } from '../service/model/tracking.service';
import { OperationStatus, OperationType } from '../model/enums.enum';
import { OperationStatusDescription } from 'src/app/model/enums.enum';
import { MessageComponentData, MessageComponentType } from '../general/message/message.component';
import { Websocket } from '../service/websocket/websocket';
import { SignalModel } from 'src/app/model/signal.model';
import { Message } from '@stomp/stompjs';
import { PatrolService } from '../service/model/patrol.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileClassToConsole } from '../common/profile-class.decorator';
import { first } from 'rxjs/operators';
import { ArchiveListComponent } from './archive-list-component';
import { OperationFilterModel } from '../general/filter-component/operation.filter.model';
import { VerificationModel } from '../model/verification.model';
import { MarkersService } from '../service/model/markers.service';
import { MarkerModel } from '../model/marker.model';

@ProfileClassToConsole()
@Directive()
export class OperationListComponent extends ArchiveListComponent implements OnInit, OnDestroy {

  private operationUpdateWebsocket: Websocket;
  private operationWebsocketConnectedSubscription:   Subscription;
  private onNewMarkerSubscription: Subscription;

  public signalsWebsocket: Websocket;
  public signalsWebsocketSubscription: Subscription;

  constructor(public    logger:               NGXLogger,
              protected changeDetector:       ChangeDetectorRef,
              protected ngZone:               NgZone,
              public authorizationService:    AuthorizationService,
              protected entityService:        ArchiveService,
              protected operationType:        OperationType,
              protected dialog:               MatDialog,
              public    componentName:        string,
              public    tabTitle:             string,
              public    title:                string,
              public    modelName:            string,   // Usado em diálogos de confirmação
              protected storageService:       StorageService,
              protected trackingService:      TrackingService,
              protected markerService:        MarkersService,
              protected toastr:               ToastrService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, changeDetector, authorizationService, entityService, dialog, componentName, tabTitle, title, modelName, storageService, trackingService, toastr, goldenLayout, container);
  }

  ngOnInit() {
    this.logger.debug('OperationListComponent.ngOnInit()');
    super.ngOnInit();
    this.isOperationArchiveButtonHiddenForUser();
    this.initWebsocket();
    this.subscribeToOperationUpdateEvent();
    this.subscribeToNewMarkersNotifications();
  }

  ngOnDestroy(){
    super.ngOnDestroy();

    this.operationWebsocketConnectedSubscription?.unsubscribe();
    this.signalsWebsocketSubscription?.unsubscribe();

    this.operationUpdateWebsocket?.destroy();
    this.signalsWebsocket?.destroy();
    this.operationUpdateWebsocket = null;
    this.signalsWebsocket = null;

    if(this.entityService instanceof PatrolService){
      this.glUnSubscribeEvent(PATROL_UPDATE_EVENT);
    }
    else {
      this.glUnSubscribeEvent(VERIFICATION_UPDATE_EVENT);
    }
  }

  initWebsocket() {
    this.ngZone.runOutsideAngular(() => {
      this.subscribeToSignalsWebsocketStream();
      this.subscribeOnOperationWebsocketConnect();
    });
  }

  // Chamada quando algum item da lista foi atualizado pelo backend
  updateModelItem(id: string){
    this.entityService.getRecord(id).pipe(first()).subscribe((entity: EntityModel) => {
      if(!entity) { // Operação removida (note que o tratamento é diferente do RegistrationListComponent)
        this.model = this.model.filter( model => model.id != id);
        this.buildDataSource();
        return;
      }

      let operation = entity as OperationModel;
      operation.type = this.operationType;

      // Antes de adicionar na lista uma nova operação confere se o usuário tem acesso apenas algumas lotações 
      let specificPlacements = this.storageService.getSpecificPlacementIds();
      if(specificPlacements.length > 0 && !specificPlacements.includes(operation.placement.id)){
        return;
      }

      // Se a operação está dentro da lista de operações de um evento, então somente as operações daquele evento devem ser atualizadas.
      // Se não está dentro da lista do evento retorna
      if(!this.checkInsideEventlistOrNotInsideEvent(entity)){ 
        return;
       }

      let exclude = false; 
      let toFilter = false;
      const oldModel = this.model.find( model => operation.id == model.id);
      
      if(this.checkFilterDates(operation)){
        toFilter = true;
      } 
      else if(this.checkFilterStatus(operation)){
        toFilter = true;
      }
      else if(this.checkFilterPatrolTeam(operation)){
        toFilter = true;
      }
      else if(this.operationType == OperationType.EVENT_VERIFICATION && this.checkFilterPlacement(operation)){
        toFilter = true;
      }
      else if(this.operationType == OperationType.EVENT_VERIFICATION && this.checkFilterAnalyst(operation as VerificationModel)){
        toFilter = true;
      }

      if (toFilter == true) {
        if (!oldModel) {
          return;
        } else {
          exclude = true;
        }
      }

      if (oldModel)
        operation.lastSync = oldModel['lastSync'];

      this.findAndUpdateModel(operation, exclude);
    }, (error: HttpErrorResponse) => {
      if (error.status === HttpError.NOT_FOUND) { // Não deve acontecer, mas deixo o tratamento do Registration aqui como referencia
        this.model = this.model.filter( model => model.id != id);
        this.buildDataSource();
        return;
      }
      this.logger.error(error);
    });
  }

  /** Se inscreve para o ouvir o websocket de mudanças nas operações (rondas/verificações) */
  private subscribeToOperationUpdateEvent() {
    if(this.entityService instanceof PatrolService){
      this.glSubscribeEvent(PATROL_UPDATE_EVENT, (data) => {
        this.updateModelItem(data.id);
      });
    }
    else {
      this.glSubscribeEvent(VERIFICATION_UPDATE_EVENT, (data) => {
        this.updateModelItem(data.id);
      });
    }
  }
  /** Se inscreve como listener para receber notificações sempre que um marcador de mensagens for recebido */
  private subscribeToNewMarkersNotifications() {
    this.onNewMarkerSubscription = this.markerService.onNewMarkerReceived().subscribe((newMarker: MarkerModel) => {
      if (!newMarker) return;

      if (!newMarker.operationId) {
        return;
      }
      const entity: any = this.dataSource.data.find(entity => entity.id === newMarker.operationId);
      if (entity && newMarker.timestamp > entity.lastSync) {
        entity.lastSync = newMarker.timestamp;
      }
      this.renderComponent();
}), (error: any) => {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[marker.component.ts] subscribeToNewMarkersNotifications: ${errMsg}`);
    };
  }

  /** Se inscreve para receber mensagens de localização */
  private subscribeToSignalsWebsocketStream() {
    this.signalsWebsocket = Websocket.createSignalsWebsocket();
    this.signalsWebsocketSubscription = this.signalsWebsocket.stream().subscribe((message: Message) => {
      // Não deixar descomentado, sobrecarrega o log
      // this.logger.debug("OperationListComponent.OnSignalsWebsocket()");
      if (message && message.body) {
        const signal: SignalModel = JSON.parse(message.body);
        if (signal && signal.operationId) {
          const entity: any = this.dataSource.data.find(entity => entity.id === signal.operationId);
          if (entity) {
            if (entity.status === 'PLANNED') {
              return;
            }
            if (entity.status === 'FINISHED' && entity.lastSync) {
              return;
            }
            // PAUSED, STARTED
            entity.lastSync = signal.receivedTimestamp ? signal.receivedTimestamp: signal.timestamp;
            this.renderComponent();
          }
        }
      }
    });
  }

  /**
   * Ao conectar com o websocket, recarrega as operações do backend para evitar alterações antigas perdidas
   */
  private subscribeOnOperationWebsocketConnect(){
    if(this.entityService instanceof PatrolService){
      this.operationUpdateWebsocket = Websocket.createPatrolUpdateWebsocket();
    }else{
      this.operationUpdateWebsocket = Websocket.createVerificationUpdateWebsocket();
    }

    this.operationWebsocketConnectedSubscription = this.operationUpdateWebsocket.onConnectCallback().subscribe(() => {
      this.logger.log('OnOperationWebsocketConnect ***** Conexão com o websocket estabelecida.');
      this.loadRecordsFromServer();
      this.renderComponent();
    }, (error: any) => this.logger.error(error));
  }

  onHistoricalTrackingClick(operation: OperationModel ) {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingOperation: operation});
  }

  onHistoricalTrackingManyClick(){
    let historicalTrackingOperationList = [];
    this.selection.selected.forEach( (operation: OperationModel) => {
      if (this.canHasHistoricalTracking(operation)) {
        historicalTrackingOperationList.push(operation);
      }
    });

    // Note que depois de abrir o mapa pode descobrir que não tem sinais, raro mas pode acontecer
    if (historicalTrackingOperationList.length > 0)
      this.glOpenContainer(MAP_PAGE, {historicalTrackingOperationList: historicalTrackingOperationList});
    else
      this.toastr.warning("Nenhuma operação selecionada possui dados de rastro pois não foram iniciadas.");
  }

  canHasHistoricalTracking(operation: OperationModel): boolean {
    if (operation && operation.status != OperationStatus.PLANNED
                  && operation.status != OperationStatus.SENT)
      return true;
    else
      return false;
  }

  hasLocation(operation: OperationModel): boolean {
    return operation &&
           ((operation.route && !!operation.route.fileRouteKml) ||
            (operation.inspections && operation.inspections.length != 0));
   }

  onMessagesClick(entity: EntityModel) {
    this.openMessageComponent(<OperationModel>entity);
  }

  openMessageComponent(operation: OperationModel){
    const messageData: MessageComponentData = new MessageComponentData();
    messageData.componentType = MessageComponentType.OPERATION_MESSAGE;
    messageData.operationId = operation.id;
    messageData.operationType = operation.type;
    this.glOpenContainer(MESSAGE_PAGE, messageData);
  }

  getOperationStatus(operation: OperationModel): string {
    if (!operation) return '';

    if (operation.archived)
      return "Arquivada";
    else if (operation.status)
      return OperationStatusDescription[operation.status];
    else
      return '';
  }

  protected loadLastSignals() {
    if (this.model.length == 0) {
      return;
    }

    this.logger.debug("OperationListComponent.loadLastSignals");

    let opDateName = this.operationType == OperationType.PATROL? 'startDate': 'sentDate';

    // Para otimizar a chamada ao backend olhamos para todas as operações
    // da mais antiga a mais recente da lista
    // model por default é ordenado por Identifier
    let startDate: number;
    let endDate: number;
    for(let i = 0; i < this.model.length; i++) {
      const opDate: number = this.model[i][opDateName];
      if (opDate) {
        if (!startDate || opDate < startDate){
          startDate = opDate;
        }
        if (!endDate || opDate > endDate){
          endDate = opDate;
        }
      }
    }

    if (!startDate && !endDate) return; // Nada a atualizar
    if (!startDate) startDate = endDate; 
    if (!endDate) endDate = startDate; 

    let filterStartDate = moment(startDate).startOf('day').valueOf(); // Horário local
    let filterEndDate = moment(endDate).endOf('day').valueOf();

    this.loadingOn();
    this.trackingService.getLastOperationSignals(this.operationType, filterStartDate, filterEndDate).pipe(first()).subscribe(lastSignalsJSON => {
      let lastSignals:Array<any> = JSON.parse(lastSignalsJSON);
      this.logger.debug("OperationListComponent.loadLastSignals loaded=", lastSignals.length);
      let updatedCount: number = 0;
      for(let i = 0; i < lastSignals.length; i++) {
        let entity = this.model.find( (entity:any) => lastSignals[i].key.operationId == entity.id && lastSignals[i].key.operationType == this.operationType );
        if(entity){
          let lastSync = lastSignals[i].receivedTimestamp? lastSignals[i].receivedTimestamp: lastSignals[i].timestamp;
          (entity as any).lastSync = lastSync;
          updatedCount++;
        }
      }
      this.logger.debug("OperationListComponent.loadLastSignals updated=", updatedCount);
      /** Se a ordenação atual é a última atualização, força a ordenação da tabela */
      if(this.sort.active === 'lastSync')
        this.buildDataSource();
    },
    error => { 
      this.toastr.error("Erro ao carregar dados de Última Atualização!");
      this.logger.error(error); 
    },
    () => { this.loadingOff(); });
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'km':
        {
          const val = item['stretchStartKM'] && item['stretchEndKM'] ? item['stretchStartKM'] + ' - ' + item['stretchEndKM'] : item['km'];
          return val ? val : '';
        }
      case 'status': return this.getOperationStatus(<OperationModel>item);
      default: return super.getPropertyByPath(item, property);
   }
  }  
  
  checkFilterDates(operation: OperationModel){
    // data
    if(this.filterModel.startDate != null && this.filterModel.endDate != null){
      if(!(operation.startDate >= this.filterModel.startDate && operation.startDate <= this.filterModel.endDate))
        return true;
    }else if(this.filterModel.startDate == null && this.filterModel.endDate != null){
      if(!(operation.startDate <= this.filterModel.endDate))
        return true;
    }
    return false;
  }  
  
  checkFilterStatus(operation: OperationModel){

    let filter: OperationFilterModel = this.filterModel as OperationFilterModel;

    if(filter.status != null && filter.status.length > 0){
      if(!filter.status.includes(operation.status))
        return true;
    }
    return false;
  }  
  
  checkFilterPatrolTeam(operation: OperationModel){

    let filter: OperationFilterModel = this.filterModel as OperationFilterModel;

    if(filter.patrolTeams != null && filter.patrolTeams.length > 0){
      if(!filter.patrolTeams.includes(operation.patrolTeam.id))
        return true;
    }
    return false;
  }  
  
  checkFilterPlacement(operation: OperationModel){

    let filter: OperationFilterModel = this.filterModel as OperationFilterModel;

    if(filter.placements != null && filter.placements.length > 0){
      if(!filter.placements.includes(operation.placement.id))
        return true;
    }
    return false;
  }  
  
  checkFilterAnalyst(operation: VerificationModel){
    let filter: OperationFilterModel = this.filterModel as OperationFilterModel;
    if(filter.analysts != null && filter.analysts.length > 0){
      if(!filter.analysts.includes(operation.analyst.id))
        return true;
    }
    return false;
  }  
  
  /** implementado na lista de verificações */
  //Rondas nunca estão dentro de eventos
  protected checkInsideEventlistOrNotInsideEvent(entity : any): boolean{
    return true;
  }

  updateOperationFromPatrolTeam(operation: OperationModel) {
    // Melhoria para abrir operações antigas de antes da mudanças de placement e company (mas a equipe precisa estar atualizada)
    if (!operation.patrolTeam) return;

    if (!operation.company && operation.patrolTeam.company) {
      operation.company = operation.patrolTeam.company;
    }

    if (!operation.placement && operation.company) {
      operation.placement = operation.company.placement;
    }

    if (!operation.serviceType && operation.patrolTeam.serviceType) {
      operation.serviceType = operation.patrolTeam.serviceType;
    }
  }
}
