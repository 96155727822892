import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertsService } from './../../service/model/alerts.service';
import { OperationTypeDescription, AlertPriorityDescription, OperationType, SourceType, Permission } from './../../model/enums.enum';
import { Component, OnInit, OnDestroy, Inject, ViewChild, NgZone, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { AlertModel } from '../../model/alert.model';
import { environment } from 'src/environments/environment';
import { Websocket } from '../../service/websocket/websocket';
import { Message } from '@stomp/stompjs';
import { StompState } from '@stomp/ng2-stompjs';
import { ToastrService } from 'ngx-toastr';
import DateUtils from '../../service/util/date-utils';
import { BaseGoldenPanel } from '../base-golden-panel/base-golden-panel';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { MAP_PAGE, FILL_DATA_PREFIX } from 'src/app/common/constants';
import { EntityModel } from '../../model/entity.model';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../../general/confirmation-dialog/confirmation-dialog.component';
import { StorageService } from 'src/app/service/storage-service';
import { VerificationService } from 'src/app/service/model/verification.service';
import { VerificationModel } from 'src/app/model/verification.model';
import { CentralListComponent } from './central-list/central-list.component';
import { MatInput } from '@angular/material/input';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { EventListDialogComponent } from '../../general/operation/event-list-dialog/event-list-dialog.component';
import { EventModel } from 'src/app/model/event.model';
import { EventFilterModel } from '../event/event-filter/event.filter.model';
import { MessageComponentData, MessageComponentType } from '../../general/message/message.component';
import { MESSAGE_PAGE } from '../../common/constants';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { first } from 'rxjs/operators';
import { ProfileService } from 'src/app/service/model/profile.service';

const DISCONNECTED = {value: 0, text: 'Alertas Desconectado'};
const CONNECTING = {value: 1, text: 'Alertas Conectando'};
const CONNECTED = {value: 2, text: 'Alertas Conectado'};

@ProfileClassToConsole()
@Component({
  selector: 'app-central',
  templateUrl: './central.component.html',
  styleUrls: ['../../app.component.scss', './central.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CentralComponent extends BaseGoldenPanel implements OnInit, OnDestroy {
  title = environment.CENTRAL_TITLE_LABEL;
  tabTitle = environment.CENTRAL_GROUP_LABEL;
  appAlertsTitle = 'Alertas App Móvel';
  simfAlertsTitle = 'Alertas SIMF';

  /** ENUMS */
  alertPriorityDescription  = AlertPriorityDescription;
  operationTypeDescription  = OperationTypeDescription;
  operationType             = OperationType;
  permission                = Permission;

  /** Colunas das listas */
  appDisplayedColumns:    string[];
  simfDisplayedColumns: string[];

  /** Componentes de lista de alertas filhos*/
  @ViewChild('appAlertList', { static: true }) appAlertComponent: CentralListComponent;
  @ViewChild('simfAlertList', { static: true }) simfAlertComponent: CentralListComponent;

  @ViewChild(MatInput, { static: true }) searchInput: MatInput;

  /** Controle de seleção das listas de alertas  */
  appAlertSelection : SelectionModel<AlertModel>;
  simfAlertSelection : SelectionModel<AlertModel>;

  /** Detalhes dos alertas */
  detailsAlert:                     AlertModel;
  inMemoryDetailsAlert:             AlertModel;
  detailsChanged:                   boolean;
  verificationStretch:              string = '';

  showDetails: boolean = false;
  
  /**valida se a janela é fechada quando o usuário não tem acesso à lista de alertas */
  closeWindows: boolean = false;

  alertsConnectionState: {value: number, text: string} = DISCONNECTED;

  /** Subscriptions */
  private alertsWebsocketSubscription: Subscription;
  private loadOperationSubscription: Subscription;
  private websocketStateSubscription: Subscription;
  private websocketConnectSubscription: Subscription;

  private alertsWebsocket: Websocket;

  constructor(public logger:                NGXLogger,
              private changeDetector:       ChangeDetectorRef,
              private ngZone:               NgZone,
              public  dialog:               MatDialog,
              private alertsService:        AlertsService,
              private toastr:               ToastrService,
              private storageService:       StorageService,
              private profileService:       ProfileService,
              private verificationService:  VerificationService,
              public authorizationService:  AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, goldenLayout, container);
  }

  ngOnInit() {
    this.logger.debug('centralComponent.onInit()');

    this.appAlertSelection = this.appAlertComponent.selection;
    this.simfAlertSelection = this.simfAlertComponent.selection;

    this.glUpdateTabTitle(this.tabTitle);

    this.inMemoryDetailsAlert = new AlertModel();

    this.subscribeToWebsocket();

    this.subscribeOnFillData();

    this.appDisplayedColumns = ['select', 'level', 'identifier',  'acknowledged', 'timestamp', 'observedArea', 'alertType', 'band', 'duct', 'km',
                                      'patrolTeam.name', 'patrolTeam.company.placement.name', 'operationType', 'patrolTeam.serviceType', 'observation'];

    this.simfDisplayedColumns = ['select', 'level', 'identifier',  'acknowledged', 'timestamp', 'observedArea', 'alertType', 'duration',
                                      'simf.cpsNumber', 'simf.scalePoint', 'simf.zoneName', 'simf.endChannel',
                                      'observation'];

    if (this.storageService.hasPopoutData()) {
      const popout = this.storageService.getPopoutData();
      this.restorePopoutData(popout);
      this.storageService.setPopoutData(null);
    }
  }

  glOnPopout() {
    super.glOnPopout();
    this.savePopoutData()
  }

  glOnPopin() {
    super.glOnPopin();
    this.savePopoutData()
  }

  savePopoutData() {
    this.storageService.setPopoutData({
      appFilterModel: this.appAlertComponent.filterModel,
      simfFilterModel: this.simfAlertComponent.filterModel,
    });
  }

  restorePopoutData(popout) {
    this.appAlertComponent.filterModel = popout.appFilterModel;
    this.simfAlertComponent.filterModel = popout.simfFilterModel;
  }

  ngOnDestroy(){
    const loggedUser = this.storageService.getLocalUser();
    if(!loggedUser || !loggedUser.id ) return;

    this.alertsWebsocket?.destroy();
    this.alertsWebsocket = null;

    this.alertsWebsocketSubscription?.unsubscribe();
    this.loadOperationSubscription?.unsubscribe();
    this.websocketStateSubscription?.unsubscribe();
    this.websocketConnectSubscription?.unsubscribe();

    this.glUnSubscribeEvent(FILL_DATA_PREFIX + 'central');
  }

  //########################################################
  // SUBSCRIPTIONS
  //########################################################

  /**
   * @description Método que chama todas as inscrições do componente
   */
  private subscribeToWebsocket() {
    this.ngZone.runOutsideAngular(() => {
      this.alertsWebsocket = Websocket.createAlertsWebsocket();
      this.subscribeOnAlertWebsocket();
      this.subscribeOnWebsocketState();
      this.subscribeToWebsocketConnected();
    });
  }

  /**
   * Método que observa as alterações de status do websocket
   */
  private subscribeOnWebsocketState(){
    this.websocketStateSubscription = this.alertsWebsocket.state().subscribe((state: StompState) => {
      if (state === 0 || state === 3){
        this.alertsConnectionState = DISCONNECTED;
      }else if (state === 1){
        this.alertsConnectionState = CONNECTING;
      }else if (state === 2){
        this.alertsConnectionState = CONNECTED;
      }
      this.renderComponent();
    });
  }

  /**
   * Inscrição para receber alertas do tópico de alertas do websocket
   */
  private subscribeOnAlertWebsocket(){
    this.alertsWebsocketSubscription = this.alertsWebsocket.stream().subscribe((message: Message) => {
      this.onNewAlertReceived(message.body);
    }),(error) => {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[central.component.ts] subscribeAlertWebsocket: ${errMsg}`);
    };
  }

  /**
   * Ao conectar com o websocket, recarrega do backend para evitar alertas perdidos
   */
   private subscribeToWebsocketConnected(){
    this.websocketConnectSubscription = this.alertsWebsocket.onConnectCallback().subscribe(() => {
      this.logger.log('OnAlertsWebsocketConnected ***** Conexão com o websocket estabelecida.');
      this.appAlertComponent.loadAlerts();
      this.simfAlertComponent.loadAlerts();
      this.renderComponent();
    }, (error: any) => this.logger.error(error));
  }

  protected renderComponent(){
    // aguarda um pouco para outras tarefas terminarem
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 250);
  }

  //########################################################
  // WEBSOCKET METHODS
  //########################################################

  /**
   * Callback para novo alerta recebido
   */
  protected onNewAlertReceived(updatedAlertId: string) {
    if (!updatedAlertId) return;

    this.alertsService.getRecord(updatedAlertId).pipe(first()).subscribe((entity: EntityModel) => {
      let alert: AlertModel = <AlertModel>entity;
      if (!alert) return;

      // se a alerta não pertence a lista de lotações que tem acesso o usuário, não atualiza a lista 
      const specificPlacement = this.storageService.getSpecificPlacementIds();
      if(specificPlacement.length == 0 || specificPlacement.includes(alert.patrolTeam.company.placement.id)) {
        if(alert.sourceType == SourceType.MOBILE_APP){
          this.handleNewAlert(this.appAlertComponent, alert);
        }else{
          this.handleNewAlert(this.simfAlertComponent, alert);
        }

        this.renderComponent();
      }
    });
  }

  /**
   * Chama os métodos de atualização da lista de alertas dos componentes filhos
   * @param listComponent
   * @param alert
   */
  private handleNewAlert(listComponent: CentralListComponent, alert: AlertModel){
    let existingAlertPos = listComponent.model.findIndex( (model: AlertModel) => model.id === alert.id);
    if (existingAlertPos > -1) {
      listComponent.updateExistingAlert(existingAlertPos, alert);
    }else {
      listComponent.insertNewAlert(alert);
    }
  }

  //########################################################
  // HEADER METHODS
  //########################################################

  /**
   * Aplica o filtro do header nas tabelas
   * @param filterValue
   */
  applySearch(filterValue: string) {
    this.appAlertComponent.dataSource.filter = filterValue.trim().toLowerCase();
    this.simfAlertComponent.dataSource.filter = filterValue.trim().toLowerCase();
    this.appAlertSelection.clear();
    this.simfAlertSelection.clear();
  }

  onRemoveSearch(){
    this.searchInput.value = '';
    this.appAlertComponent.dataSource.filter = '';
    this.simfAlertComponent.dataSource.filter = '';
    this.appAlertSelection.clear();
    this.simfAlertSelection.clear();
  }

  // Context Menu action, não tem confirmação
  eventClicked(data) {
  if (data.create){
      this.glOpenContainer("events-edit", {id: null, options: {selectedAlerts: [data.alert]} }); // Cria um evento e passa a lista de alertas a serem atualizados posteriormente
    }
    else {
      if (data.link) {
        this.selectEventToLink([data.alert]);
      }
      else {
        this.linkAlertsToEvent([data.alert], null);
      }
    }
  }

  operationClicked(data) {
    if (data.operationType == OperationType.PATROL){
      this.glOpenContainer('patrols-edit', {id: data.operationId});
    }
    else {
      this.glOpenContainer('verifications-edit', {id: data.operationId});
    }
  }

  operationMessagesClicked(data) {
    this.openMessageComponent(data);
  }

  openMessageComponent(data){
    const messageData: MessageComponentData = new MessageComponentData();
    messageData.componentType = MessageComponentType.OPERATION_MESSAGE;
    messageData.operationId = data.operationId;
    messageData.operationType = data.operationType;
    this.glOpenContainer(MESSAGE_PAGE, messageData);
  }

  getSelectedAlerts(): AlertModel[] {
    const selectedAlerts: AlertModel[] = [];

    if(this.appAlertSelection && this.appAlertSelection.selected.length > 0) {
      this.appAlertSelection.selected.forEach((alert: AlertModel) => {
        selectedAlerts.push(alert);
      });
    }

    if(this.simfAlertSelection && this.simfAlertSelection.selected.length > 0) {
      this.simfAlertSelection.selected.forEach((alert: AlertModel) => {
        selectedAlerts.push(alert);
      });
    }

    return selectedAlerts;
  }

  onCreateEventManyClick() {
    const selectedAlerts = this.getSelectedAlerts();
    const foundAlertEvent = selectedAlerts.find((alert: AlertModel) => alert.eventIdentifier);
    if (foundAlertEvent){
      this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data:{
          msg: 'Existem alertas já vinculados a eventos, serão desvinculados e vinculados ao novo evento. Deseja continuar?',
          title: "Atenção",
          okLabel: "Continuar"
        }
      }).afterClosed().pipe(first()).subscribe((option:boolean) => {
        if(option) {
          this.glOpenContainer("events-edit", {id: null, options: {selectedAlerts: selectedAlerts} }); // Cria um evento e passa a lista de alertas a serem atualizados posteriormente
        }
      });
    }
    else{
      this.glOpenContainer("events-edit", {id: null, options: {selectedAlerts: selectedAlerts} }); // Cria um evento e passa a lista de alertas a serem atualizados posteriormente
    }
  }

  onLinkEventManyClick(){
    const selectedAlerts = this.getSelectedAlerts();
    const foundAlertEvent = selectedAlerts.find((alert: AlertModel) => alert.eventIdentifier);
    if (foundAlertEvent){
      this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data:{
          msg: 'Existem alertas já vinculados a eventos, serão desvinculados e vinculados ao evento selecionado. Deseja continuar?',
          title: "Atenção",
          okLabel: "Continuar"
        }
      }).afterClosed().pipe(first()).subscribe((option:boolean) => {
        if(option) {
          this.selectEventToLink(selectedAlerts);
        }
      });
    }
    else {
      this.selectEventToLink(selectedAlerts);
    }
  }

  selectEventToLink(selectedAlerts: AlertModel[]){
    let filter: EventFilterModel =  new EventFilterModel();     
    let dialogRef = this.dialog.open(EventListDialogComponent, {
      panelClass: 'sipd-modal',
      data: filter
    });
    dialogRef.afterClosed().pipe(first()).subscribe( (events: EventModel[]) => {
      if(events && events.length == 1){
        this.linkAlertsToEvent(selectedAlerts, events[0].identifier);
      }
    });
  }

  linkAlertsToEvent(selectedAlerts: AlertModel[], eventIdentifier: string){
    const observables: Observable<Object>[] = [];

    for (let i in selectedAlerts) {
      let alert = selectedAlerts[i];
      alert.eventIdentifier = eventIdentifier;
      if (this.updateDetails(alert)) this.inMemoryDetailsAlert.eventIdentifier = alert.eventIdentifier;

      let alertItem: AlertModel = this.getAlertFromList(alert);
      if (alertItem) alertItem.eventIdentifier = alert.eventIdentifier;

      observables.push(this.alertsService.editRecord(alert));
    }

    forkJoin(observables).pipe(first()).subscribe( (alerts: AlertModel[]) => {
      if (eventIdentifier) this.toastr.success('Alerta(s) vinculado(s) ao evento com sucesso.');
      else this.toastr.success('Alerta(s) desvinculado(s) do evento com sucesso.');
    }, error => {
      this.logger.error(error);
      if (eventIdentifier) this.toastr.error('Ocorreu uma falha ao vincular o(s) alerta(s) ao evento ' + eventIdentifier);
      else this.toastr.error('Ocorreu uma falha ao desvincular o(s) alerta(s) do evento');
    });
  }

  onUnLinkEventManyClick(){
    const selectedAlerts = this.getSelectedAlerts();
    const foundAlertEvent = selectedAlerts.find((alert: AlertModel) => alert.eventIdentifier);
    if (!foundAlertEvent){
      this.toastr.info("Nenhuma ação necessária. Todos os alertas selecionados não possuem evento vinculado.");
    }
    else{
      this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data:{
          msg: 'Todos os alertas selecionados serão desvinculados de eventos. Deseja continuar?',
          title: "Atenção",
          okLabel: "Continuar"
        }
      }).afterClosed().pipe(first()).subscribe((option:boolean) => {
        if(option) {
          this.linkAlertsToEvent(selectedAlerts, null);
        }
      });
    }
  }

  private getAlertFromList(selectedAlert: AlertModel){
    let alertItem: AlertModel;
    if (selectedAlert.sourceType == SourceType.MOBILE_APP) {
      alertItem = this.appAlertComponent.model.find((alert: AlertModel) => selectedAlert.id == alert.id);
    }
    else {
      alertItem = this.simfAlertComponent.model.find((alert: AlertModel) => selectedAlert.id == alert.id);
    }
    return alertItem;
  }

  private subscribeOnFillData(){
    this.glSubscribeEvent(FILL_DATA_PREFIX + 'central', (data) => {
      if (data.selectedAlerts) { // Novo Evento salvo com dados dos alertas, devemos atualizar o vinculo dos alertas
        this.logger.debug("CentralComponent-OnFillData");
        let selectedAlerts: AlertModel [] = data.selectedAlerts;
        for (let i in selectedAlerts) {
          let alert = selectedAlerts[i];
          if (this.updateDetails(alert)) this.inMemoryDetailsAlert.eventIdentifier = alert.eventIdentifier;

          let alertItem: AlertModel = this.getAlertFromList(alert);
          if (alertItem) alertItem.eventIdentifier = alert.eventIdentifier;
        }
      }
    });
  }

  onLocationManyClick(){
    let selectedAlerts = this.getSelectedAlerts();
    selectedAlerts = selectedAlerts.filter((alert: AlertModel) => this.hasLocation(alert));

    if (selectedAlerts.length > 0)
      this.glOpenContainer(MAP_PAGE, {alertList: selectedAlerts});
    else
      this.toastr.warning("Nenhum alerta selecionado possui (Lat, Long) definido.");
  }

  locationClicked(alert: AlertModel){
    this.glOpenContainer(MAP_PAGE, {alert: alert});
  }

  canShowMessages(): boolean {
    if (this.appAlertSelection.selected.length+this.simfAlertSelection.selected.length != 1)
      return false;

    if (this.appAlertSelection.selected.length == 1)
      return this.hasOperation(this.appAlertSelection.selected[0]);
    else
      return this.hasOperation(this.simfAlertSelection.selected[0]);
  }

  onDetailsClick(){
    this.showDetails = ! this.showDetails;
  }

  hasOperation(alert: AlertModel): boolean {
    return !!alert && !!alert.operationId;
  }

  hasLocation(alert: AlertModel): boolean {
    return alert && alert.location.coordinates[0] != 0 && alert.location.coordinates[1] != 0;
  }

  isDetailedAlertAcknowledged() {
    return (this.inMemoryDetailsAlert) ? this.inMemoryDetailsAlert.acknowledged : false;
  }

  //########################################################
  // ALERT DETAIL METHODS
  //########################################################

  onDetailedAlertAcknowledgedClick() {
    if (this.inMemoryDetailsAlert.acknowledged) {
      this.inMemoryDetailsAlert.acknowledged = false;
      this.inMemoryDetailsAlert.acknowledgmentAuthor = "";
      this.inMemoryDetailsAlert.acknowledgmentDate = 0;
    }
    else {
      this.inMemoryDetailsAlert.acknowledged = true;
      this.inMemoryDetailsAlert.acknowledgmentAuthor = this.storageService.getLocalUser().login;
      this.inMemoryDetailsAlert.acknowledgmentDate = moment().valueOf();
    }
    this.detailsChanged = true;
  }

  onSaveDetailsClick(): void{
    this.logger.debug(`onSaveDetailsClick - Atualizando o alerta ${this.inMemoryDetailsAlert.id} de ${DateUtils.timestampToStringInSeconds(this.inMemoryDetailsAlert.timestamp)}`);

    this.detailsAlert.acknowledged = this.inMemoryDetailsAlert.acknowledged;
    this.detailsAlert.acknowledgmentAuthor = this.inMemoryDetailsAlert.acknowledgmentAuthor;
    this.detailsAlert.acknowledgmentDate = this.inMemoryDetailsAlert.acknowledgmentDate;
    this.detailsAlert.observation = this.inMemoryDetailsAlert.observation;

    this.alertsService.editRecord(this.detailsAlert).pipe(first()).subscribe( () => {
      this.toastr.success('Alerta alterado com sucesso.');
      this.detailsChanged = false;
    }, (error) => {
      this.logger.error(`Erro ao atualizar o alerta ${this.detailsAlert.id} de
      ${DateUtils.timestampToStringInSeconds(this.detailsAlert.timestamp)}`, error);
      this.toastr.error('Não foi possível alterar o alerta.', 'Falha!');
    });
  }

  onChangedNotes() {
    if(!this.inMemoryDetailsAlert.id) return;
    this.detailsChanged = true;
  }

  changeDetails(alert: AlertModel) {
    this.detailsAlert = alert;
    this.inMemoryDetailsAlert = JSON.parse(JSON.stringify(alert));
    if(!alert.acknowledged){
      this.inMemoryDetailsAlert.acknowledgmentAuthor = "";
      this.inMemoryDetailsAlert.acknowledgmentDate = 0;
    }
    this.detailsChanged = false;

    if(alert.operationType === OperationType.EVENT_VERIFICATION){
      this.getVerificationStretch();
    }
  }

  /**
   * Busca o trecho da verificação que disparou o alerta
   * @returns
   */
  getVerificationStretch(){
    if(!this.inMemoryDetailsAlert) return;

    this.verificationService.loadById(this.inMemoryDetailsAlert.operationId).pipe(first()).subscribe( (verification: VerificationModel) => {
      this.verificationStretch = (verification && verification.stretch) ? verification.stretch : '';
    }, error => this.logger.error(error));
  }

  /**
   * Callback do evento de click nas linhas das tabelas de alerta.
   * Atualiza os dados dos detalhes do alerta selecionado.
   * @param alert
   */
  alertClicked(alert: AlertModel) {
    if (this.detailsChanged) {
      this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data:{
          msg: 'Dados editados serão perdidos. Deseja salvar?',
          title: "Atenção",
          okLabel: "Salvar",
          showDiscardButton: true
        }
      }).afterClosed().pipe(first()).subscribe((option:boolean) => {
        if(typeof(option) === 'string') {//discard
          this.changeDetails(alert);
        }
        else if(option) {//save
          this.onSaveDetailsClick();
          this.changeDetails(alert);
        }
        else if (!option) { //cancel ou clicar fora do dialogo
        }
      });
    }
    else {
      this.changeDetails(alert);
    }
  }

  updateDetails(alert: AlertModel): boolean{
    if (this.detailsAlert && this.detailsAlert.id == alert.id) {
      this.detailsAlert = alert;
      // Não atualiza o inMemoryDetailsAlert porque ele pode ter sido modificado
      return true;
    }
    else {
      return false;
    }
  }

  acknowledgeAlerts(selectedAlerts: AlertModel[]){
    let acknowledgmentAuthor = this.storageService.getLocalUser().login;
    let acknowledgmentDate = moment().valueOf();

    for (let i in selectedAlerts) {
      let alert = selectedAlerts[i];
      alert.acknowledged = true;
      alert.acknowledgmentAuthor = acknowledgmentAuthor;
      alert.acknowledgmentDate = acknowledgmentDate;
      if (this.updateDetails(alert)) {
        this.inMemoryDetailsAlert.acknowledged = alert.acknowledged;
        this.inMemoryDetailsAlert.acknowledgmentAuthor = alert.acknowledgmentAuthor;
        this.inMemoryDetailsAlert.acknowledgmentDate = alert.acknowledgmentDate;

        if (this.inMemoryDetailsAlert.observation != this.detailsAlert.observation) {
          this.detailsChanged = true;
        }
        else {
          this.detailsChanged = false;
        }
      }

      let alertItem: AlertModel = this.getAlertFromList(alert);
      if (alertItem) {
        alertItem.acknowledged = alert.acknowledged;
        alertItem.acknowledgmentAuthor = alert.acknowledgmentAuthor;
        alertItem.acknowledgmentDate = alert.acknowledgmentDate;
      }
    }

    const observables: Observable<Object>[] = [];
    selectedAlerts.forEach(alert => {
      observables.push(this.alertsService.editRecord(alert));
    });

    forkJoin(observables).pipe(first()).subscribe( (alerts: AlertModel[]) => {
      this.toastr.success('Alerta(s) reconhecido(s) com sucesso.');
    }, error => {
      this.logger.error(error);
      this.toastr.error('Ocorreu uma falha ao reconhecer o(s) alerta(s)');
    });
  }

  onAcknowledgeManyClick(){
    let selectedAlerts = this.getSelectedAlerts();
    selectedAlerts = selectedAlerts.filter((alert: AlertModel) => alert.acknowledged == false);
    if (selectedAlerts.length > 0){
      this.acknowledgeAlerts(selectedAlerts);
    }
    else {
      this.toastr.info("Nenhuma ação necessária. Todos os alertas selecionados já estão reconhecidos.");
    }
  }

  // Context Menu action
  acknowledgeClicked(alert: AlertModel) {
    this.acknowledgeAlerts([alert]);
  }

  dataSourceLoaded(event) {
    if (this.searchInput.value && this.searchInput.value != "") {
      this.appAlertComponent.updateSearchFilter(this.searchInput.value.trim().toLowerCase());
      this.simfAlertComponent.updateSearchFilter(this.searchInput.value.trim().toLowerCase());
    }
  }

  getSimfInfo(alert): string{
    return AlertModel.getSimfInfo(alert, "\n");
  }

  /**
   * Método que checa se o usuário pode ou não reconhecer o alerta
   * @returns boolean
   */
  denyAcknowledgedmentControl(){
    if(!this.inMemoryDetailsAlert) return true;

    //TODO Daniel: Descomentar quando o arquivamento de alertas for implementado
    //if(this.inMemoryDetailsAlert.archived && !this.authorizationService.isAdmin()) return false;

    const loggedUser = this.storageService.getLocalUser();

    if(this.inMemoryDetailsAlert.acknowledgmentAuthor !== '' && loggedUser.login !== this.inMemoryDetailsAlert.acknowledgmentAuthor) return true;

    return false;
  }

  getEventIdentifier(){
    if(this.inMemoryDetailsAlert.eventIdentifier) return this.inMemoryDetailsAlert.eventIdentifier;
    else return '';
  }

  getOperationIdentifier(){
    if(this.inMemoryDetailsAlert.operationIdentifier) return this.inMemoryDetailsAlert.operationIdentifier;
    else return '';
  }

  getOperationType(){
    if(this.inMemoryDetailsAlert.operationType) return this.operationTypeDescription[this.inMemoryDetailsAlert.operationType];
    else return '';
  }
  
  // Verifica se o usuário tem autorizaçao para ver a lista de alertas
  loggedUserPermission(event){    
    if(!this.authorizationService.userHasPermission(this.permission.LIST_ALERTS) && !this.closeWindows)
       this.glRemoveLayout('central');
       this.closeWindows = true;
  }

  getLocationTimestamp(){
    if(!this.inMemoryDetailsAlert?.locationTimestamp) return "";
    return DateUtils.timestampToStringInSeconds(this.inMemoryDetailsAlert?.locationTimestamp);
  }
}
