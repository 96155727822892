<div mat-dialog-title>
  <span>Filtrar Pontos</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="p-5">
    <single-autocomplete [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa" [(valueData)]="filterModel.band" fxFlex>
    </single-autocomplete>
  </div>

  <div class="p-5">
    <single-autocomplete [data]="singleDataCacheService.getValues('PipeStretch')" placeholder="Trecho" [(valueData)]="filterModel.stretch" fxFlex>
    </single-autocomplete>
  </div>

  <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
    <mat-form-field appearance="outline" fxFlex>
        <mat-label>KM Inicial</mat-label>
        <input matInput matTooltip="{{ filterModel.startKm }}" name="startKm" placeholder="KM Inicial" [(ngModel)]="filterModel.startKm" mask="000+000" [dropSpecialCharacters]="false">
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex>
        <mat-label>KM Final</mat-label>
        <input matInput matTooltip="{{ filterModel.startKm }}" name="endKm" placeholder="KM Final" [(ngModel)]="filterModel.endKm" mask="000+000" [dropSpecialCharacters]="false">
    </mat-form-field>

    <div class="form-spacer" fxFlex></div>
  </div>
  
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="clearFilter()">Limpar</button>
  <button mat-raised-button (click)="onFilter()">Aplicar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>  
</mat-dialog-actions>
