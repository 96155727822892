import {EntityModel} from './entity.model';
import {CompanyModel} from './company.model';
import LanguageUtils from '../service/util/language-utils';

export class VehicleModel extends EntityModel {
  plate: string;
  model: string;
  company: CompanyModel;
  observations: string;
  
  static addLanguageMap(){
    LanguageUtils.languageMap['plate'] = 'Placa';
    LanguageUtils.languageMap['model'] = 'Carro';
    LanguageUtils.languageMap['company'] = 'Empresa';
    LanguageUtils.languageMap['observations'] = 'Observações';
  }
}
