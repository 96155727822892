<div class="sipd-container">
   
    <div class="sipd-header">
        <button mat-icon-button color="accent" matTooltip="Filtrar Pontos" (click)="openFilterDialog()">
        <mat-icon class="material-icons-outlined">filter_list</mat-icon>
        </button>
        <span class="mat-title m-0">{{title}}</span><small matTooltip="Número de Registros Filtrados">[ {{getListCount()}} ]</small>
        <div class="fill-space"></div>
        <div class="actions">
        <mat-form-field class="list-filter  header-input" appearance="outline" >
            <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
            <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
            <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
        </mat-form-field>
        </div>

        <div class="actions">
            
          <button mat-icon-button (click)="onRefreshClick()" 
          matTooltip="Atualizar Lista">
          <mat-icon class="material-icons-outlined">refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="onImportPointsClick()" 
          matTooltip="Importar Pontos">
          <mat-icon class="material-icons-outlined">upload</mat-icon>
        </button>
        <button mat-icon-button (click)="onLocationManyClick()" 
          matTooltip="Mostrar no Mapa"
          [disabled]="this.selection.selected.length == 0" >
          <mat-icon class="material-icons-outlined">location_on</mat-icon>
        </button>
        <button mat-icon-button (click)="onCreateClick()"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_POINT)'
          matTooltip="Adicionar Ponto">
          <mat-icon class="material-icons-outlined">add</mat-icon>
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Abrir Ponto(s) Selecionado(s)" 
          [disabled]="this.selection.selected.length == 0"
          (click)="onEditManyClick(this.selection.selected)">
          <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar Ponto Selecionado 
                      (Somente 1 Ponto pode estar selecionado"                                  
          [disabled]="this.selection.selected.length != 1"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_POINT)'
          (click)="onCopyClick(this.selection.selected.pop())">
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary"
        [hidden]='!authorizationService.userHasPermission(permission.DELETE_POINT)'
          (click)="onDeleteManyClick(this.selection.selected, $event)" 
          matTooltip="Remover Pontos Selecionados"
          [disabled]="this.selection.selected.length == 0" >
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="sipd-content bg-white">
        <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">
          
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select" sticky>
            <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
              <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
              </mat-checkbox>
            </th>
            <td mat-cell class="txt-center fit-content" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
                [matTooltip]="checkboxTip(row)">
              </mat-checkbox>
            </td>
          </ng-container>
    
          <!-- Ientificador-->
          <ng-container matColumnDef="identifier">
            <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.identifier}} </td>
          </ng-container>
    
          <ng-container matColumnDef="status">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{pointStatusDescription[element?.status] || "Sem status"}}             
            </td>
          </ng-container>

          <ng-container matColumnDef="source">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Origem </th>
            <td mat-cell class="nowrap" *matCellDef="let element">{{element?.source}}              
            </td>
          </ng-container>
    
          <ng-container matColumnDef="creationDate">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora de Criação</th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.creationDate | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>
    
          <ng-container matColumnDef="band">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Faixa </th>
            <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}}  </td>
          </ng-container>
    
          <!-- stretch Column -->
          <ng-container matColumnDef="stretch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Trecho </th>
            <td class="ellipsis" style="max-width: 200px;" mat-cell *matCellDef="let element" [matTooltip]="element?.stretch"> {{element?.stretch}} </td>
          </ng-container>

          <ng-container matColumnDef="km">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> KM </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{kmIntToString(element?.km)}}</td>
          </ng-container>

          <ng-container matColumnDef="dc">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> DC </th>
            <td mat-cell class="nowrap" *matCellDef="let element" [style.color]="element.dc === 'YES' ? 'red' : 'black'"> {{inspectionBooleanDescription[element?.dc] || " "}} </td>
          </ng-container>
    
          <ng-container matColumnDef="accessType">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo de acesso </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.accessType}} </td>
          </ng-container>
    
          <ng-container matColumnDef="category">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Categoria </th>
            <td mat-cell class="nowrap" *matCellDef="let element">{{element?.category}} </td>
          </ng-container>
    
          <ng-container matColumnDef="vulnerabilityView">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Vulnerabilidade </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.vulnerabilityView}}</td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Cidade </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.city}}</td>
          </ng-container>

          <ng-container matColumnDef="uf">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> UF </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.uf}}</td>
          </ng-container>

          <ng-container matColumnDef="reference">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Referência </th>
            <td mat-cell class="nowrap" *matCellDef="let element">{{element?.reference}} </td>
          </ng-container>
         
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
            (contextmenu)="openContextMenu($event, element)"
            [ngClass]="{ 'highlighted': selection.isSelected(row) }">
          </tr>
        </table>

        <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
          [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
        </div>
    
        <mat-menu #contextMenu="matMenu">
          <button mat-menu-item
           (click)="onEditClick(this.contextMenuSelectedItem, $event)">
            <mat-icon class="material-icons-outlined">folder_open</mat-icon>
            <span>Abrir Ponto</span>
          </button>
          <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_POINT)'
           (click)="onCopyClick(this.contextMenuSelectedItem)">
            <mat-icon class="material-icons-outlined">file_copy</mat-icon>
            <span>Copiar Ponto</span>
          </button>
          <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.DELETE_POINT)' (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.identifier, $event)">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            <span>Remover Ponto</span>
          </button>
          <button mat-menu-item (click)="onLocationClick(this.contextMenuSelectedItem)" [disabled]="!hasLocation(this.contextMenuSelectedItem)">
            <mat-icon class="material-icons-outlined">location_on</mat-icon>
            <span>Mostrar no Mapa</span>
          </button>
        </mat-menu>
    
      </div>

    <mat-divider></mat-divider>
    <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
