import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { FILTER_OPTION_ALL } from 'src/app/common/constants';
import { FilterComponent } from 'src/app/general/filter-component/filter-component';
import { ObservedAreaStatus, ObservedAreaStatusDescription, ObservedAreaTypeDescription, UserType } from 'src/app/model/enums.enum';
import { UserModel } from 'src/app/model/user.model';
import { LayerService } from 'src/app/service/model/layer.service';
import { ObservedAreaService } from 'src/app/service/model/observed.area.service';
import { UserService } from 'src/app/service/model/user.service';
import { StorageService } from 'src/app/service/storage-service';
import { ObservedAreaFilterModel } from './observed-area.filter.model';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';

@Component({
  selector: 'app-observed-area-filter',
  templateUrl: './observed-area-filter.component.html',
  styleUrls: ['./observed-area-filter.component.scss', '../../../filter.component.scss']
})
export class ObservedAreaFilterComponent extends FilterComponent implements OnInit, OnDestroy  {

  @Input() inputFilterModel: ObservedAreaFilterModel;

  /** ENUMS */
  status = ObservedAreaStatus;
  statusDescription = ObservedAreaStatusDescription;
  typesDescription = ObservedAreaTypeDescription;

  responsibles: UserModel[];

  private reloadUsersSubscription: Subscription;
  
  constructor(protected logger:               NGXLogger,
              protected userService:          UserService,
              public singleDataCacheService:  SingleDataCacheService,
              protected layerService:         LayerService,
              protected observedAreaService:  ObservedAreaService,
              public entityCacheService:      EntityCacheService,
              public filterModel:             ObservedAreaFilterModel,
              protected storageService:       StorageService) {
    super(logger, userService, layerService, singleDataCacheService, entityCacheService, observedAreaService, filterModel, storageService);
  }

  ngOnInit(): void {
    this.filterModel = new ObservedAreaFilterModel();
    this.inMemoryFilterModel = this.inputFilterModel;

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.reloadUsersSubscription?.unsubscribe();
  }

  loadFormOptionsData() {
    let _this = this; // Necessário por causa do contexto das callacks
    const onUsersLoad = function() {
      const responsibleUserTypes = [UserType.ANALYSIS_CCPD, UserType.COORDINATOR_CCPD, UserType.COORDINATOR_OPPD, UserType.PLANNER];
      _this.responsibles = _this.entityCacheService.getFilteredUsers(responsibleUserTypes);
    };
    this.entityCacheService.loadUsers(this.loadingListService, onUsersLoad);
    this.reloadUsersSubscription = this.entityCacheService.onUsersReload().subscribe(onUsersLoad);

    const metadatas: string[] = [
      "Band",
      "City"];
  
    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  /**
   * Limpa todos os campos do filtro
   */
  clearFilter(){
    super.clearFilter();

    this.filterModel.areaStatus   = FILTER_OPTION_ALL;
    this.filterModel.responsibles = [];
    this.filterModel.types        = [];
    this.filterModel.cities       = [];
    this.filterModel.bands        = [];
  }
}
