import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { LatLongMask, LatLongPattern } from '../../../common/constants';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import FieldUtils from 'src/app/service/util/field-utils';
import { InspectionModel } from '../../../model/inspection.model';
import { LocationModel } from 'src/app/model/location.model';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { LoadingListService } from "src/app/service/loading/loading-list.service";

@Component({
  selector: 'app-insert-point-dialog',
  templateUrl: './insert-point-dialog.component.html',
  styleUrls: ['./insert-point-dialog.component.scss']
})
export class InsertPointDialogComponent implements OnInit, OnDestroy {

  band: string;
  km: string;
  latlong: string;
  city: string;
  state: string;
  activities: string;

  editing: boolean;

  latLongPattern = LatLongPattern;
  latLongMask = LatLongMask;

  oldLatlong: string;
  
  loadingListService = new LoadingListService();

  constructor(private logger:                     NGXLogger,
              public singleDataCacheService:  SingleDataCacheService,
              public dialogRef:                             MatDialogRef<InsertPointDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public inspection:   InspectionModel) { }

  ngOnInit(): void {    
    this.loadFormOptionsData();

    if (this.inspection) {
      this.editing = true;
      this.band = this.inspection.band;
      this.km = this.inspection.km;
      this.oldLatlong = this.latlong = this.inspection.location.latitude + ", " + this.inspection.location.longitude;
      this.city = this.inspection.city;
      this.state = this.inspection.state;
      if (this.inspection.activities) {
        for (let i=0; i<this.inspection.activities.length; i++) {
          if (i==0) {
            this.activities = this.inspection.activities[i];
          }
          else {
            this.activities += "|\n" + this.inspection.activities[i];
          }
        }
      }
    }
    else {
      this.editing = false;
      this.inspection = new InspectionModel();
    }
  }

  ngOnDestroy() {
    this.loadingListService.destroy();
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  getTitle() {
    if (this.editing) {
      let title = "Editar Ponto";
      if (this.inspection.inspectionPointId) {
        title += ": " + this.inspection.inspectionPointId;
      }
      return title;
    }
    else {
      return "Inserir LatLong";
    }
  }

  private loadFormOptionsData() {
    const metadatas: string[] = [
      "State",
      "City"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);

  }
  
  canInsert() {
    if (!this.band || !this.km || !this.latlong)
      return false;

    return true;
  }

  accept() {
    if (this.editing && this.inspection.inspectionPointId) {
      // Só zera o ID se algum campo que não o de atividades foi modificado, pois caso esses sejam modificados não é mais o mesmo ponto vindo do cadastro de pontos
      if (this.inspection.band != this.band ||
          this.inspection.km != this.km ||
          this.inspection.city != this.city ||
          this.inspection.state != this.state ||
          this.oldLatlong != this.latlong) {
        this.inspection.inspectionPointId = null;
      }
    }

    this.inspection.band = this.band;
    this.inspection.km = this.km;
    if (this.latlong) {
      let ltlg = this.latlong.split(",");
      this.inspection.location.latitude = ltlg[0].trim();
      this.inspection.location.longitude = ltlg[1].trim();
    }
    else {
      this.inspection.location = new LocationModel();
    }
    this.inspection.city = this.city;
    this.inspection.state = this.state;
    if (this.activities) {
      // Parses the activities string using as separators the characters '|' and '\n'
      this.inspection.activities = this.activities.split(new RegExp('[|\n]', 'g'));
      // Trims each activity descriptor in the activities array
      this.inspection.activities = this.inspection.activities.map(s => s.trim());
      // Removes from the activity array any activity with a empty descriptor
      this.inspection.activities = this.inspection.activities.filter(e =>  e);
    }
    else
      this.inspection.activities = [];
    this.inspection.name = this.band + ' ' + this.km;
    this.dialogRef.close(this.inspection);
  }

  onPasteLatLong(event: ClipboardEvent){
    let pastedValue = FieldUtils.pasteLatLong(event, this.latlong);
    if (pastedValue != null) {
      this.latlong = pastedValue;
      return true;
     }
     return false;
 }
}
