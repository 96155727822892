
<div class="sipd-container">

  <div class="sipd-header">
    <div class="actions">      
      <span class="mat-title m-0"></span>      
    </div>
    <div class="fill-space"></div>
    <div class="actions">
      <div>
        <mat-form-field class="list-filter header-input" appearance="outline">
          <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
          <input [readonly]="editReadOnly" matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
          <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
        </mat-form-field>
      </div>
    </div>
    <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista" [disabled]="editReadOnly">
      <mat-icon class="material-icons-outlined">refresh</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>

  <div class="sipd-content bg-white">
    <table mat-table [dataSource]="dataSource" matSort class="w-100 -outlined mb-5">

      <!-- Coluna Índice -->
      <ng-container matColumnDef="answerIndex">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Índice </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.answerIndex}} </td>
      </ng-container>

      <!-- Coluna Ponto -->
      <ng-container matColumnDef="inspectionPointIndex">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Ponto </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.inspectionPointIndex}} </td>
      </ng-container>

      <!-- Coluna Faixa -->
      <ng-container matColumnDef="band">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Faixa </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
      </ng-container>

      <!-- Coluna KM -->
      <ng-container matColumnDef="km">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> KM </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.km}} </td>
      </ng-container>

      <!-- Coluna Data -->
      <ng-container matColumnDef="date">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ element?.timestamp | date:'dd/MM/yyyy' }} </td>
      </ng-container>

      <!-- Coluna Hora -->
      <ng-container matColumnDef="time">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Hora </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ element?.timestamp | date:'HH:mm' }} </td>
      </ng-container>

      <!-- Coluna Lat, Long -->
      <ng-container matColumnDef="latLong">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Lat, Long </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.location?.latitude + ", " + element?.location?.longitude}} </td>
      </ng-container>

      <!-- Coluna Profissional -->
      <ng-container matColumnDef="professional">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Profissional </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.professional"> {{element?.professional}} </td>
      </ng-container>

      <!-- Coluna Tipo de Inspeção -->
      <ng-container matColumnDef="inspectionType">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Inspeção </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.inspectionType"> {{element?.inspectionType}} </td>
      </ng-container>

      <!-- Coluna Nome do Formulário -->
      <ng-container matColumnDef="formName">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Nome do Formulário </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.formName"> {{element?.formName}} </td>
      </ng-container>

      <!-- Coluna Pergunta -->
      <ng-container matColumnDef="question">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Pergunta </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.question"> {{element?.question}} </td>
      </ng-container>

      <!-- Coluna Resposta -->
      <ng-container matColumnDef="answer">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Resposta </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.answer"> {{element?.answer}} </td>
      </ng-container>

      <!-- Coluna Comentários -->
      <ng-container matColumnDef="comments">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Comentários </th>
        <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.comments"> {{element?.comments}} </td>
      </ng-container>

    <!-- Coluna Evidência 1 -->
      <ng-container matColumnDef="evidence1">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Evidência 1 </th>
        <td class="nowrap" mat-cell *matCellDef="let element">
          <img  *ngIf="element?.evidenceFileUrl1"   width="30px" height="40px" [src]="element?.evidenceFileUrl1" (click)="viewFile(element?.evidenceFileUrl1)"/>
        </td>
      </ng-container>

      <!-- Coluna Evidência 2 -->
      <ng-container matColumnDef="evidence2">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Evidência 2 </th>
        <td class="nowrap" mat-cell *matCellDef="let element">
          <img  *ngIf="element?.evidenceFileUrl2" width="30px" height="40px" [src]="element?.evidenceFileUrl2" (click)="viewFile(element?.evidenceFileUrl2)"/>
        </td>
      </ng-container>

      <!-- Coluna Evidência 3 -->
      <ng-container matColumnDef="evidence3">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Evidência 3 </th>
        <td class="nowrap" mat-cell *matCellDef="let element">
          <img  *ngIf="element?.evidenceFileUrl3" width="30px" height="30px" [src]="element?.evidenceFileUrl3" (click)="viewFile(element?.evidenceFileUrl3)"/>
        </td>
      </ng-container>

      <!-- Coluna Evidência 4 -->
      <ng-container matColumnDef="evidence4">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Evidência 4 </th>
        <td class="nowrap" mat-cell *matCellDef="let element">
          <img  *ngIf="element?.evidenceFileUrl4" width="30px" height="30px" [src]="element?.evidenceFileUrl4" (click)="viewFile(element?.evidenceFileUrl4)"/>
        </td>
      </ng-container>

      <!-- Coluna Evidência 5 -->
      <ng-container matColumnDef="evidence5">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Evidência 5 </th>
        <td class="nowrap" mat-cell *matCellDef="let element">
          <img  *ngIf="element?.evidenceFileUrl5" width="30px" height="30px" [src]="element?.evidenceFileUrl5" (click)="viewFile(element?.evidenceFileUrl5)"/>
        </td>  
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="operationAnswersDisplayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: operationAnswersDisplayedColumns; let element">
      </tr>
    </table>
  </div>

  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>

</div>
