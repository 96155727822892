import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class LayerService {

  constructor(private http: HttpClient){}

  public getBands(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=Band`);
  }

  /* Não são mais usados - todas as camadas são carregadas em memória
  public getBandsByStates(states: string[]){

    let params = new HttpParams();
    params = params.append('geoMetadata', 'Band');

    for(let i = 0; i < states.length; i++){
      params = params.append('states', states[i]);
    }

    return this.http.get(`${environment.settings.registrations_address}/geo`, { params : params});
  }

  public getGeoItemsByFilter(geoMetadata: string, states: string[], simple: boolean){
    let params = new HttpParams();
    params = params.append('geoMetadata', geoMetadata);

    for(let i = 0; i < states.length; i++){
      params = params.append('states', states[i]);
    }

    params = params.append('simple', String(simple));

    return this.http.get(`${environment.settings.registrations_address}/geo`, { params : params});
  }

  public getKilometerMarksByBand(bandId: string){
    let params = new HttpParams();
    params = params.append('geoMetadata', 'KilometerMark');
    params = params.append('bandIds', bandId);

    return this.http.get(`${environment.settings.registrations_address}/geo`, { params : params});
  }

  public getKilometerMarksByBandIds(bandIds: string[]){
    let params = new HttpParams();
    params = params.append('geoMetadata', 'KilometerMark');

    if(bandIds){
      for(let i = 0; i < bandIds.length; i++){
        params = params.append('bandIds', bandIds[i]);
      }
    }

    return this.http.get(`${environment.settings.registrations_address}/geo`, { params : params});
  }
  */

  public getGasDucts(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=GasDuct`);
  }

  public getOilDucts(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=OilDuct`);
  }

  public getSimf(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=Simf`);
  }

  public getDeliveryPoints(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=DeliveryPoint`);
  }

  public getKilometerMarks(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=KilometerMark`);
  }

  public getRefinary(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=Refinary`);
  }

  public getTerminal(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=Terminal`);
  }

  public getValve(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=Valve`);
  }

  public getDCsHistory(){
    return this.http.get(`${environment.settings.registrations_address}/geo?geoMetadata=Dc`);
  }

}
