import { GeoModel } from './geo.model';
import { environment } from 'src/environments/environment';

export class BandModel extends GeoModel{
  extension: string;
  ducts: string[]; // Nomes dos dutos

  public static getPopupContent(feature): string{
    
    const band = feature.properties as BandModel;

    let ductList = '';
    if (band.ducts) {
      for (const duct of band.ducts){
        ductList = ductList + `<li> ${duct} </li>`;
      }
    }
    let states: string = band.states ? band.states.toString() : '';
    let contents: string = `<h5 style="text-align: center">Faixa</h5>
                            <h6 style="text-align: center"><b>${band.name}</b></h6>
                            <div><b>Extensão (m):</b> ${band.extension?band.extension:''} </div>
                            <div><b>UF(s):</b> ${states} </div>
                            <div>Dutos:</div>
                            ${ductList}
                          `
    if (!environment.showPopupLayerTables || band.description == null || band.description === "") {                              
      return contents;
    }
    else {
      return contents + "<hr/>" + band.description
    }
  }
}
