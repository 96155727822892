import { ObservedAreaStatus } from "./enums.enum";

export class LayerFilterModel {
  years:  string[] = [];
  states: string[] = [];
  bandIds: string[] = [];
  bandNames: string[] = [];
  areaStatus: string = ObservedAreaStatus.ALL;
  responsiblesIds: string[] = [];
  areasIds: string[] = [];
}
