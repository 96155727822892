import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  private toastr: ToastrService
  config: Partial<IndividualConfig>;

  constructor(private _injector: Injector, private logger: NGXLogger, private ngZone: NgZone) {
    this.config = { 
      positionClass: 'toast-top-right', // Já é o default (fica como exemplo)
      timeOut: 20000 // Default é 5000
    };
  }

  handleError(error: Error) {
    if (!this.toastr) {
      this.toastr = this._injector.get(ToastrService);
    }

    if (error && error.message && error.message.startsWith("ExpressionChangedAfterItHasBeenCheckedError"))
      return;

    let errorMessage: string;
    if (error instanceof HttpErrorResponse) { // Erro de resposta do servidor
      if (!navigator.onLine) { // Erro de conexão
        errorMessage = 'Não foi possível a comunicação com o servidor.\nPor favor, verifique sua conexão.';
      } else if (error.error && error.error.exceptionName && error.error.exceptionName.endsWith("DuplicateFieldException")) {
        errorMessage = `Operação Inválida - ${error.error.message} já foi registrado no banco!`;
      } else { // Erro interno do servidor
        errorMessage = `Erro Interno: ${(error.error && error.error.errorDetails) ? error.error.errorDetails : 'Ocorreu um erro inesperado.'}`;
      }
    } else { // Erro do cliente
      errorMessage = `Ocorreu um erro no cliente:  ${error.message}`;
    }

    this.ngZone.run(() => {
      this.toastr.error(errorMessage, 'Erro!', this.config);
    });
    this.logger.error(errorMessage, error);
  }
}
