import { SafeUrl } from '@angular/platform-browser';
import { EntityModel } from './entity.model';
import DateUtils from '../service/util/date-utils';
import FieldUtils from '../service/util/field-utils';
import { OperationTypeDescription } from './enums.enum';

export class MarkerModel extends EntityModel {
  markerType:           string;
  sourceType:           string;
  patrolTeamId:         string;
  operationId:          string;
  operationType:        string;
  operationIdentifier:  string;
  inspectionId:         string;
  inspectionName:       string;
  message:              string;
  location:             {lat: number, lng: number}; // LocationModel no Marker
  appointedLocation:    {lat: number, lng: number}; // LocationModel no Marker
  timestamp:            number;  // Date no Marker
  locationTimestamp:     number; 
  serverTimestamp:      number;
  receivedServerTimestamp: number;
  priority:             boolean;
  userId:               string;
  userName:             string; // É preenchido com o login quando não é profissional em canais de equipe
  uuid:                 string; // Identificador gerado para possibilitar a identificação, mesmo antes de ser salvo no banco
  channelId:            string;
  channelName:          string;

  fileId:               string;
  fileUrl:              string | ArrayBuffer | SafeUrl; // Preenchido apenas aqui no Web

  constructor(){
    super();
  }

  public static getPopupContent(marker: MarkerModel, patrolTeamName){
    return `<h5 style="text-align: center">Marcador de Estado</h5>
            <div style="text-align: center"> <b> ${ marker.message } </b> </div>
            <div> <b>Usuário:</b> ${ marker.userName } </div>
            <div> <b>Equipe:</b>  ${ patrolTeamName } </div>
            <div> <b>Operação:</b>  ${ OperationTypeDescription[marker.operationType] } </div>
            <div style="margin-left: 1rem"> <b>ID:</b> ${ marker.operationIdentifier }</div>
            ${ marker.inspectionName? '<div style="margin-left: 1rem"> <b>Ponto:</b> ' + marker.inspectionName + '</div>': '' }
            <div> <b>Lat, Long:</b>  ${FieldUtils.coordToString(marker.location.lat)},${FieldUtils.coordToString(marker.location.lng)} </div>
            <div> <b>Data/Hora da Localização:</b>  ${ marker?.locationTimestamp ? DateUtils.timestampToStringInMinutes(marker.locationTimestamp) : "Não"} </div>
            <div> <b>Data/Hora:</b>  ${DateUtils.timestampToStringInMinutes(marker.timestamp)} </div>            
          `;
  }

  public static getTooltipContent(marker: MarkerModel){
    return `<div style="text-align: center"> <b> ${ marker.message } </b> </div>
            <div style="text-align: center">${ marker.userName } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${DateUtils.timestampToStringInMinutes(marker.timestamp)} </div>
            <div style="text-align: center"> ${ OperationTypeDescription[marker.operationType] + ' ' + marker.operationIdentifier} </div>
            <div style="text-align: center"> ${FieldUtils.coordToString(marker.location.lat)},${FieldUtils.coordToString(marker.location.lng)} </div>
          `;
  }
}
