<div class="sipd-container">

    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVO]': ':'+ view['identifier'])}}</span>
        <div class="m-0 text-emphasis-2 ml-2">
          <span class="header-tag ml-2" *ngIf="view['status']"> <span>&#9679;</span> {{ pointStatusDescription[view['status']] }}</span>          
        </div>
        <div class="fill-space"></div>
        <div class="actions">
          <button mat-raised-button 
            matTooltip="Habilitar Edição"
            [hidden]="!canEdit() || !readOnly"
            (click)="onEnableEditClick()">
            Habilitar Edição
          </button>
          <button mat-icon-button color="primary"
            matTooltip="Copiar"
            (click)="onCopyClick()"
            [disabled]="!id"
            [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_POINT)'>
            <mat-icon class="material-icons-outlined">file_copy</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="onDeleteClick()"
            matTooltip="Remover"
            [hidden]='!authorizationService.userHasPermission(permission.DELETE_POINT)'>
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
          <div>
            <button mat-icon-button [matMenuTriggerFor]="sideMenu" [hidden]="isStatusButtonHiddenForUser()" matTooltip="Alterar Status" [disabled]="readOnly">
              <mat-icon class="material-icons-outlined">task_alt</mat-icon>
            </button>
            <mat-menu #sideMenu="matMenu">
              <button mat-menu-item  (click)="onStatusChange(pointStatus.IN_ANALYSIS)">
                <span> {{pointStatusDescription.IN_ANALYSIS}}</span>
              </button>
              <button mat-menu-item (click)="onStatusChange(pointStatus.APPROVED)">
                <span>{{pointStatusDescription.APPROVED}}</span>
              </button>                       
            </mat-menu>
          </div>
          <button mat-icon-button  (click)="onLocationClick()" matTooltip="Mostrar no Mapa" [disabled]="!hasLocation()">
            <mat-icon class="material-icons-outlined">location_on</mat-icon>
          </button>
          <button mat-icon-button  [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_POINT)'>
            <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
          </button>
          <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
            <button mat-icon-button (click)="onSaveClick($event)" [disabled]="!canSave() || km.invalid || latLong.invalid" matTooltip="Salvar" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_POINT)'>
              <mat-icon class="material-icons-outlined">save</mat-icon>
            </button>
          </div>
        </div>
      </div>

    <mat-divider></mat-divider>

    <div class="sipd-content">
        <form class="h-100">
        <mat-tab-group mat-align-tabs="start" animationDuration="0ms" color="accent" 
            class="-bg-white -fill-height h-100 -overflow">

            <mat-tab label="Identificação">
                <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
                    
                  
                  <!--Origem-->  
                  <mat-form-field fxFlex appearance="outline">
                    <mat-label>Origem</mat-label>
                    <input matInput name="source" [(ngModel)]="view['source']" readonly>
                  </mat-form-field>

                    <single-autocomplete [data]="entityCacheService.getUsers()" name="name" placeholder="Criador" [(valueData)]="view['author']" [displayName]="getUserNameLoginTitle"
                        [disabled]="readOnly || !this.authorizationService.isCreatorEditAllowedForUser()" fxFlex>
                    </single-autocomplete>
                  
                    <single-autocomplete [data]="entityCacheService.getUsers()" name="name" placeholder="Aprovador" [(valueData)]="view['approve']" [displayName]="getUserNameLoginTitle"
                        [disabled]="readOnly || !this.authorizationService.isApproveEditAllowedForUser()" fxFlex>
                    </single-autocomplete>
                    
                    <mat-form-field appearance="outline" fxFlex>
                      <!-- Data/Hora de criação -->
                      <mat-label>Data/Hora (criação)</mat-label>
                      <input matInput mask="d0/M0/0000 Hh:m0" name="date" [(ngModel)]="view['creationDate']" readonly>
                    </mat-form-field>

                    <single-autocomplete [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa" [(valueData)]="view['band']"
                      [extraData]="model && model['band'] ? model['band'] : null"
                      [required]="true" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>
      
                    <single-autocomplete [data]="singleDataCacheService.getValues('PipeStretch')" placeholder="Trecho" [(valueData)]="view['stretch']"
                      [extraData]="model && model['stretch'] ? model['stretch'] : null"
                      [disabled]="readOnly" fxFlex>
                    </single-autocomplete>
      
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>KM</mat-label>
                        <input [readonly]="readOnly" name="km" matInput  placeholder="KM" matTooltip="{{ view['km'] }}" [(ngModel)]="view['km']"
                          #km="ngModel" km [dropSpecialCharacters]="false" mask="000+000" [validation]="true" required/>
                        <mat-error *ngIf="km.hasError('required')">Campo Obrigatório</mat-error>
                        <mat-error *ngIf="!km.hasError('required') && km.invalid">Formato inválido para o KM.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Lat, Long</mat-label>
                        <input [readonly]="readOnly" name="latLong" matInput placeholder="KM (Lat, Long)" matTooltip="{{ view['latLong'] }}"
                          [(ngModel)]="view['latLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event)" #latLong="ngModel"
                          [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="true" required/>
                          <mat-error *ngIf="latLong.hasError('required')">Campo Obrigatório</mat-error>
                          <mat-error *ngIf="!latLong.hasError('required') && latLong.invalid">Formato inválido para o Lat, Long.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Bairro</mat-label>
                        <input [readonly]="readOnly" name="district" matInput matTooltip="{{ view['district'] }}" placeholder="Bairro" [(ngModel)]="view['district']" />
                    </mat-form-field>

                    <single-autocomplete [data]="singleDataCacheService.getValues('City')" placeholder="Cidade" [(valueData)]="view['city']"
                      [extraData]="model && model['city'] ? model['city'] : null"
                      [disabled]="readOnly" fxFlex>
                    </single-autocomplete>
                  
                    <single-autocomplete [data]="singleDataCacheService.getValues('State')" placeholder="UF"
                      [extraData]="model && model['uf'] ? model['uf'] : null"
                      [(valueData)]="view['uf']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>
                          
                    <single-autocomplete [data]="accessArrangementValues" placeholder="Disposição do Acesso"
                      [(valueData)]="view['accessArrangement']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>
                    
                    <header class="sipd-content">
                      <span class="mat-body-2">Paralelo:</span>
                      <div class="fill-space"></div>
                    </header>
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>metros</mat-label>
                        <input [readonly]="readOnly" name="parallelM" matInput placeholder="metros" matTooltip="{{ view['parallelM'] }}"
                          [(ngModel)]="view['parallelM']" #parallelM="ngModel" parallelM
                          [dropSpecialCharacters]="false" mask="0*" /> 
                        <mat-error *ngIf="parallelM.invalid">Entre apenas com números.</mat-error> 
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>do KM</mat-label>
                        <input [readonly]="readOnly" name="parallelStartKM" matInput placeholder="do KM" matTooltip="{{ view['parallelStartKM'] }}"
                          [(ngModel)]="view['parallelStartKM']" #parallelStartKM="ngModel" parallelStartKM
                          [dropSpecialCharacters]="false" mask="000+000" /> 
                        <mat-error *ngIf="parallelStartKM.invalid">Entre apenas com números.</mat-error> 
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>ao KM</mat-label>
                        <input [readonly]="readOnly" name="parallelEndKM" matInput placeholder="ao KM" matTooltip="{{ view['parallelEndKM'] }}"
                          [(ngModel)]="view['parallelEndKM']" #parallelEndKM="ngModel" parallelEndKM
                          [dropSpecialCharacters]="false" mask="000+000" /> 
                        <mat-error *ngIf="parallelEndKM.invalid">Entre apenas com números.</mat-error>
                    </mat-form-field>

                    <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                  <div class="form-spacer" fxFlex></div>
                </div>
                                        
                <div class="w-100 px-3">
                  <mat-form-field appearance="outline" class=" w-100">
                    <mat-label>Referência</mat-label>
                    <textarea name="reference" matInput placeholder="Referência"
                     [(ngModel)]="view['reference']" rows="5" [readonly]="readOnly"></textarea>
                  </mat-form-field>
                </div>
            </mat-tab>

            <!-- Qualificação-->
            <mat-tab label="Qualificação">
                <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
                    <!--Nome da via-->
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>Nome da Via</mat-label>
                      <input [readonly]="readOnly" matInput matTooltip="{{ view['routeName'] }}" name="routeName" placeholder="Nome da Via" [(ngModel)]="view['routeName']">
                    </mat-form-field>

                    <single-autocomplete [data]="accessTypeValues" placeholder="Tipo de Acesso"
                      [(valueData)]="view['accessType']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Tipo de bloqueio-->
                    <single-autocomplete [data]="blockTypeValues" placeholder="Tipo de Bloqueio"
                      [(valueData)]="view['blockType']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Pavimentada-->
                    <single-autocomplete [data]="inspectionBooleanDescription | keyvalue" placeholder="Pavimentada"
                      [(valueData)]="view['paved']" name="value" optionValue="key" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Condição da estrada de terra-->
                    <single-autocomplete [data]="roadConditionValues" placeholder="Condição da Estrada de Terra"
                      [(valueData)]="view['roadCondition']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Quantidade de saídas-->
                    <single-autocomplete [data]="numberOfOutputsValues" placeholder="Quantidade de Saídas"
                      [(valueData)]="view['outputNumber']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Volume de tráfego-->
                    <single-autocomplete [data]="trafficVolumeValues" placeholder="Volume de Tráfego"
                      [(valueData)]="view['trafficVolume']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Possibilidade de tráfego-->
                    <multiselect-autocomplete [data]="trafficPossibilityValues" placeholder="Possibilidade de Tráfego"
                      [(valueData)]="view['trafficPossibility']" [disabled]="readOnly" fxFlex>
                    </multiselect-autocomplete>
          
                    <!--Rodovia asfaltada mais próxima-->
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>Rodovia Asfaltada mais Próxima</mat-label>
                      <input [readonly]="readOnly" matInput matTooltip="{{ view['nearestPavedRoad'] }}" name="nearestPavedRoad" placeholder="Rodovia Asfaltada mais Próxima" [(ngModel)]="view['nearestPavedRoad']">
                    </mat-form-field>

                    <!--Distância a essa rodovia (em metros)-->
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Distância a essa rodovia (em metros)</mat-label>
                        <input [readonly]="readOnly" name="distanceToPavedRoad" matInput placeholder="Distância (metros)" [(ngModel)]="view['distanceToPavedRoad']"
                        matTooltip="{{ view['distanceToPavedRoad'] }}" #distanceToPavedRoad="ngModel" distanceToPavedRoad [dropSpecialCharacters]="false" mask="0*" />
                        <mat-error *ngIf="distanceToPavedRoad.invalid">Entre apenas com números.</mat-error>
                    </mat-form-field>             
                
                </div>
            </mat-tab>

            <!-- Comunidades-->
            <mat-tab label="Comunidades">
                <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

                    <!--Residências-->
                    <single-autocomplete [data]="residencesValues" placeholder="Residências"
                      [(valueData)]="view['residences']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Abandonadas-->
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Abandonadas</mat-label>
                        <input [readonly]="readOnly" name="abandoned" matInput placeholder="quantidade (numérico)" [(ngModel)]="view['abandoned']"
                        matTooltip="{{ view['abandoned'] }}" #abandoned="ngModel" abandoned [dropSpecialCharacters]="false" mask="0*" />
                        <mat-error *ngIf="abandoned.invalid">Entre apenas com números.</mat-error>
                    </mat-form-field>

                    <!--Propriedade Suspeita próxima-->                    
                    <single-autocomplete [data]="inspectionBooleanDescription | keyvalue" placeholder="Propriedade Suspeita Próxima"
                      [(valueData)]="view['suspiciousResidenceNearby']" name="value" optionValue="key" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Vídeo monitoramento-->
                    <multiselect-autocomplete [data]="videoMonitoringValues" placeholder="Vídeo Monitoramento"
                      [(valueData)]="view['videoMonitoring']" [disabled]="readOnly" fxFlex>
                    </multiselect-autocomplete>

                    <!--Área sob controle-->
                    <single-autocomplete [data]="areaUnderControlValues" placeholder="Área sob Controle"
                      [(valueData)]="view['areaUnderControl']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    <div class="form-spacer" fxFlex></div>
                    </div>

                    <!--Motivo da suspeita-->
                    <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pl-3 pr-2">
                        <mat-form-field fxFlex appearance="outline" class="mr-2">
                          <mat-label>Motivo da Suspeita</mat-label>
                          <textarea matInput name="reasonOfDoubt" matInput placeholder="Motivo da Suspeita"
                            [(ngModel)]="view['reasonOfDoubt']" rows="5" [readonly]="readOnly"></textarea>
                        </mat-form-field>
                    </div>
            </mat-tab>
            
            <!-- Complementares-->
            <mat-tab label="Complementares">
                <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

                    <!--DC-->                    
                    <single-autocomplete [data]="inspectionBooleanDescription | keyvalue" placeholder="DC"
                      [(valueData)]="view['dc']" name="value" optionValue="key" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Vulnerabilidade na visão do técnico de campo-->
                    <single-autocomplete [data]="vulnerabilityViewValues" placeholder="Vulnerabilidade na Visão do Técnico de Campo"
                      [(valueData)]="view['vulnerabilityView']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>


                    <!--Categoria-->
                    <single-autocomplete [data]="categoryValues" placeholder="Categoria"
                      [(valueData)]="view['category']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                    <!--Pesos-->
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Pesos</mat-label>
                        <input [readonly]="readOnly" name="weight" matInput placeholder="Pesos (numérico)" [(ngModel)]="view['weight']"
                        matTooltip="{{ view['weight'] }}" #weight="ngModel" weight [dropSpecialCharacters]="false" mask="0*" />
                        <mat-error *ngIf="weight.invalid">Entre apenas com números.</mat-error>
                    </mat-form-field>

                    <!--Criticidade do acesso-->
                    <single-autocomplete [data]="accessCriticalityValues" placeholder="Criticidade do Acesso"
                      [(valueData)]="view['accessCriticality']" [disabled]="readOnly" fxFlex>
                    </single-autocomplete>

                </div>
            </mat-tab>

        </mat-tab-group>
        </form>
    </div>

    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
