import { GeoModel } from './geo.model';
import { environment } from '../../environments/environment';
import FieldUtils from '../service/util/field-utils';

export class KilometerMarkModel extends GeoModel {
  stretch:          string; // Nome do trecho
  band:             string; // Nome da faixa
  bandId:           string; // Id da faixa
  km:               string;

  public static getPopupContent(feature, search: boolean = false): string{
    if (feature && feature.properties){
      
      const kmMark = feature.properties as KilometerMarkModel;
     
      let name = kmMark.name;
      if (!name) {
        let stretchName: string = kmMark.stretch ? kmMark.stretch : '';
        let bandName: string = kmMark.band ? kmMark.band : '';
        name = bandName + ' - ' + stretchName;
      }
      let km: string = kmMark.km ? kmMark.km : '';
      let latLong: string = GeoModel.getLatLong(kmMark);
      let states: string = kmMark.states ? kmMark.states.toString() : '';
      let contents: string =  `<h5 style="text-align: center">Marco Quilométrico</h5>
                               <h6 style="text-align: center"><b>${ name }</b></h6>
                               <div><b>KM:</b> ${km} </div>
                               <div><b>Faixa:</b> ${kmMark.band? kmMark.band: ''} </div>
                               <div><b>Trecho:</b> ${kmMark.stretch? kmMark.stretch: ''} </div>
                               <div><b>UF(s):</b> ${states} </div>
                               <div><b>Lat, Long:</b> ${!search? latLong: ''} </div>
                              `;
      if (!environment.showPopupLayerTables || kmMark.description == null || kmMark.description === "") {                              
        return contents;
      }
      else {
        return contents + "<div style='height:200px;overflow:auto;'><hr/>" + kmMark.description
      }
    }

    return `<h5>Sem Dados</h5>`;
  }

  public static getTooltipContent(feature): string{
    const kmMark = feature.properties;
    return `<div style="text-align: center"><big><b>${ kmMark.name }</big></b></div>
            <div>${FieldUtils.coordToString(kmMark.lat)},${FieldUtils.coordToString(kmMark.lng)}</div>
           `;
  }
}
