import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpClient} from '@angular/common/http';
import {EntityService} from './entity.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/companies`);
  }
}
