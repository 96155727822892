<div class="sipd-container">

  <header class="sipd-header">
    <span class="mat-title m-0">Evento: {{view['identifier']}}</span>
    <div class="m-0 text-emphasis-2">
      <span class="header-tag ml-2" *ngIf="view['status']"> <span>&#9679;</span> {{ getEventStatus() }}</span>
      <span class="header-tag ml-2" *ngIf="showOccurrenceTag()"> ({{ resultOccurrenceDescription[view['result']['occurrence']] }})</span>
    </div>
    <div class="fill-space"></div>
    <div class="actions">
      <button mat-raised-button
        matTooltip="Habilitar Edição"
        [hidden]="isHiddenButtonEditForUser || !readOnly"
        (click)="onEnableEditClick()">
        Habilitar Edição
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar"
        (click)="onCopyClick()"
        [disabled]="!canCopy()"
        [hidden]="isHiddenButtonCreateEditEvent">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDeleteClick()"
        matTooltip="Remover" [disabled]="!canEdit()"
        [hidden]="isHiddenButtonDeleteEvent">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Exportar"
        (click)="onExportClick()">
        <mat-icon class="material-icons-outlined">save_alt</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!canArchive()"
        (click)="onArchiveClick()"
        [matTooltip]= "!view['archived'] ? 'Arquivar' : 'Desarquivar'"
        [hidden]="isHiddenButtonArchiveForUser">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
      </button>
      <div>
        <button [disabled]="disableStatusChangeComponent()" mat-icon-button style="vertical-align: middle; font-size: 1.5rem;"  [matMenuTriggerFor]="sideMenu" matTooltip="Alterar Status" [hidden]='isHiddenButtonCreateEditEvent'>
          <img src="assets/icons/event_status.png" />
        </button>
        <mat-menu #sideMenu="matMenu">
          <button mat-menu-item  (click) = "view['status'] = eventStatus.PENDING">
            <span>{{eventStatusDescription.PENDING}}</span>
          </button>
          <button mat-menu-item (click)= "view['status'] = eventStatus.IN_TREATMENT">
            <span>{{eventStatusDescription.IN_TREATMENT}}</span>
          </button>
          <button mat-menu-item (click)="view['status'] = eventStatus.FINALIZED">
            <span>{{eventStatusDescription.FINALIZED}}</span>
          </button>
        </mat-menu>
      </div>
      <button mat-icon-button  (click)="onLocationClick(view, mapEvents.EVENT_LOCATION)" matTooltip="Mostrar no Mapa" [disabled]="!hasLocation()">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]="isHiddenButtonCreateEditEvent">
        <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
      </button>
      <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
        <button mat-icon-button [disabled]="!canSave() || targetPointLatLong.invalid || targetPointKM.invalid ||
                                            stretchStartKM.invalid || stretchEndKM.invalid ||
                                            stretchStartLatLong.invalid || stretchEndLatLong.invalid ||
                                            occurrenceKM.invalid || occurrenceLatLong.invalid || eventdate.invalid ||
                                            suspiciousDateEl.invalid || startDateEl.invalid || stopDateEl.invalid || endDateEl.invalid"
          [hidden]="isHiddenButtonCreateEditEvent"
          (click)="onSaveClick($event)" matTooltip="Salvar">
          <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
      </div>
    </div>
  </header>

  <mat-divider></mat-divider>

  <div class="sipd-content">
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms" color="accent" class="-bg-white h-100 -overflow">

      <!-- Identificação (Tab) -->
      <mat-tab label="Identificação">
        <div class="w-100 px-3">
        <form class="edit-form">

          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

            <single-autocomplete [data]="analystUsers" name="name" placeholder="Analista CCPD" [(valueData)]="view['analyst']" [displayName]="getUserNameLoginTitle"
              matTooltip="{{ getUserNameLoginTitle(view['analyst']) }}" [disabled]="!canEditCcpdAnalyst || readOnly" fxFlex>
            </single-autocomplete>

            <date-chooser placeholder="Data" [(valueData)]="view['date']" [disabled]="readOnly"
               matTooltip="{{ view['date'] }}" #eventdate="dateChooserContext" [required]="true" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Hora</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="time" placeholder="Hora"
                matTooltip="{{ view['time'] }}" [(ngModel)]="view['time']" required>
              <mat-icon matSuffix>query_builder</mat-icon>
              <mat-error>Campo Obrigatório</mat-error>
            </mat-form-field>

            <single-autocomplete [data]="singleDataCacheService.getValues('CommunicationChannel')" placeholder="Canal de Comunicação" [(valueData)]="view['communicationChannel']"
              [extraData]="model && model['communicationChannel'] ? model['communicationChannel'] : null"
              [required]="true" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="getSources()" placeholder="Origem" [(valueData)]="view['source']"
              [extraData]="model && model['source'] ? model['source'] : null" (selectionChangedEvent)="sourceSelectionChanged($event)"
              [required]="true" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Quem?</mat-label>
              <input [readonly]="readOnly" matInput matTooltip="{{ view['who'] }}" name="who" placeholder="Quem?" [(ngModel)]="view['who']">
            </mat-form-field>

            <single-autocomplete [data]="alertPriorityDescription | keyvalue" placeholder="Nível de Alerta"
              [(valueData)]="view['alertLevel']" name="value" optionValue="key" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline"  class="no-hint-text mat-form-field-readonly" fxFlex>
              <mat-label>Relacionado ao Evento</mat-label>
              <input matInput matTooltip="{{ view['associatedIdentifier'] }}" name="associatedIdentifier" placeholder="Relacionado ao Evento" [(ngModel)]="view['associatedIdentifier']" readonly>
            </mat-form-field>

            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>

          </div>
        </form>
      </div>

        <mat-divider></mat-divider>

      <div class="w-100 px-3" [hidden]="!showCNCLGroup">
        <form class="edit-form">
          <header class="sipd-header">
            <span class="mat-body-2">CNCL</span>
          </header>
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">            

            <single-autocomplete [data]="coturUsers" name="name" placeholder="Cotur" [(valueData)]="view['coturCNCL']" [displayName]="getUserNameLoginTitle"
              [disabled] ="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="operatorUsers" name="name" placeholder="Operador" [(valueData)]="view['operatorCNCL']" [displayName]="getUserNameLoginTitle"
              [disabled] ="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="eventSituationDescription | keyvalue" placeholder="Situação"
              [(valueData)]="view['situation']" name="value" optionValue="key" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <date-chooser placeholder="Data de Suspeita" [(valueData)]="view['suspiciousDate']" [disabled]="readOnly"
               matTooltip="{{ view['suspiciousDate'] }}" #suspiciousDateEl="dateChooserContext" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Horário de Suspeita</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="suspiciousTime"
                matTooltip="{{ view['suspiciousTime'] }}" placeholder="Horário de Suspeita" [(ngModel)]="view['suspiciousTime']">
              <mat-icon matSuffix>query_builder</mat-icon>
            </mat-form-field>

            <date-chooser placeholder="Data de Parada" [(valueData)]="view['stopDate']" [disabled]="readOnly"
               matTooltip="{{ view['stopDate'] }}" #stopDateEl="dateChooserContext" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Horário de Parada</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="stopTime"
                matTooltip="{{ view['stopTime'] }}" placeholder="Horário de Parada" [(ngModel)]="view['stopTime']">
              <mat-icon matSuffix>query_builder</mat-icon>
            </mat-form-field>

            <date-chooser placeholder="Data de Início" [(valueData)]="view['startDate']" [disabled]="readOnly"
               matTooltip="{{ view['startDate'] }}" #startDateEl="dateChooserContext" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Hora de Início</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="startTime"
                matTooltip="{{ view['startTime'] }}" placeholder="Hora Início" [(ngModel)]="view['startTime']">
              <mat-icon matSuffix>query_builder</mat-icon>
            </mat-form-field>

            <date-chooser placeholder="Data de Fim" [(valueData)]="view['endDate']" [disabled]="readOnly"
               matTooltip="{{ view['endDate'] }}" #endDateEl="dateChooserContext" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Hora de Fim</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="endTime"
                matTooltip="{{ view['endTime'] }}" placeholder="Hora Fim" [(ngModel)]="view['endTime']">
              <mat-icon matSuffix>query_builder</mat-icon>
            </mat-form-field>

            <single-autocomplete [data]="singleDataCacheService.getValues('Product')" placeholder="Produto" [(valueData)]="view['product']"
            [extraData]="model && model['product'] ? model['product'] : null"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Vazão - m³/h</mat-label>
              <input [readonly]="readOnly" name="flow" matInput type="number" matTooltip="{{ view['flow'] }}"  step=".01" placeholder="Vazão" [(ngModel)]="view['flow']">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Volume Subtraído (m³)</mat-label>
              <input [readonly]="readOnly" name="stolenVolume" matTooltip="{{ view['stolenVolume'] }}"  matInput type="number" step=".01" placeholder="Volume Subtraído (m3)"
                [(ngModel)]="view['stolenVolume']">
            </mat-form-field>

            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>

          </div>
        </form>
      </div>

        <mat-divider></mat-divider>

        <div class="w-100 px-3">
        <form class="edit-form">
          <header class="sipd-header">
            <span class="mat-body-2">Canal 168</span>
          </header>
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>ID Chamada 168</mat-label>
              <input [readonly]="readOnly" name="callId168" matInput  matTooltip="{{ view['callId168'] }}" placeholder="ID Chamada" [(ngModel)]="view['callId168']"
                (keypress)="numbersOnly($event)" [required]="isStatusFinalizedAndChannel168()">
                <mat-error>Campo Obrigatório se Status Concluído e Canal 168</mat-error>
              </mat-form-field>

            <single-autocomplete [data]="singleDataCacheService.getValues('Category168')" placeholder="Categoria" [(valueData)]="view['category168']"
              [extraData]="model && model['category168'] ? model['category168'] : null"
              [required]="isStatusFinalizedAndChannel168()" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="singleDataCacheService.getValues('Subcategory168')" placeholder="Subcategoria" [(valueData)]="view['subcategory168']"
             [extraData]="model && model['subcategory168'] ? model['subcategory168'] : null"
              [required]="isStatusFinalizedAndChannel168()" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>

          </div>
        </form>
      </div>
        
        <mat-divider></mat-divider>

        <div class="w-100 px-3">
          <form class="edit-form">
            <header class="sipd-header">
                <span class="mat-body-2">Dados de Validação</span>
            </header>
            <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
              <!-- Validação pode ser preenchido mesmo em modo readonly -->
              <single-autocomplete #selector [data]="eventValidationDescription | keyvalue" placeholder="Validação" matTooltip="Para validar: status precisa ser Concluído, não ter sido validado antes, 
                                                                                                                                ser do perfil Analista CCPD, e não ser o mesmo usuário que criou o evento"
                [(valueData)]="view['validation']" name="value" optionValue="key" (selectionChangedEvent)="onValidationChange($event)" [disabled]="disableValidationComponentRules()" fxFlex>
              </single-autocomplete>

              <single-autocomplete [data]="validationUsers" name="name" placeholder="Analista Validação" [(valueData)]="view['validationAnalyst']"
                [displayName]="getUserNameLoginTitle" [disabled]="!canEditValidationAnalyst" fxFlex>
              </single-autocomplete>

              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>
    
            </div>
          </form>
        </div>

        <!-- Notas de Validação -->
        <div class="w-100 px-3">
          <form class="edit-form">
            <header class="sipd-header">
                <span class="mat-body-2">Notas de Validação</span>
              <div class="fill-space"></div>
              <div class="actions">
                <button mat-icon-button matTooltip="Nova Nota"
                  (click)="onActionAddValidationNote()"
                  [disabled]="!userCanAddValidationNotes()">
                  <mat-icon class="material-icons-outlined">add</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Editar nota Selecionada
                                    (Somente 1 Nota pode estar selecionada)"
                  [disabled]="!userCanEditValidationNotes()"
                  (click)="onActionEditValidationNote(this.validationNoteSelection.selected[0], $event)">
                  <mat-icon class="material-icons-outlined">edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Remover notas Selecionadas"
                  [hidden]="isHiddenButtonDeleteValidationNotes"
                  (click)="onDeleteManyValidationNoteClick()"
                  [disabled]="disableDeleteValidationNotes()">
                  <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
              </div>
            </header>
            <div class="px-3">
              <table mat-table [dataSource]="validationNoteDataSource" class="w-100 -outlined mb-5">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select" sticky>
                  <th class="txt-center fit-content" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggleValidationNotes() : null" color="accent" [disabled]="!userCanSelectValidationNotes()"
                      [checked]="validationNoteSelection.hasValue() && isAllSelectedValidationNotes()"
                      [indeterminate]="validationNoteSelection.hasValue() && !isAllSelectedValidationNotes()" [matTooltip]="checkboxTipValidationNotes()">
                    </mat-checkbox>
                  </th>
                  <td class="txt-center fit-content" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" color="accent" [disabled]="!userCanSelectValidationNotes()"
                      (change)="$event ? onCheckboxValidationNoteClick(row) : null" [checked]="validationNoteSelection.isSelected(row)"
                      [matTooltip]="checkboxTipValidationNotes(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Data/Hora </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.date | date:'dd/MM/yyyy HH:mm'}} </td>
                </ng-container>

                <!-- Author Column -->
                <ng-container matColumnDef="author">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Analista </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.author}} </td>
                </ng-container>

                 <!-- Note Column -->
                 <ng-container matColumnDef="note">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Nota </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.note}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="validationNoteDisplayedColumns sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: validationNoteDisplayedColumns; let element"
                  [ngClass]="{ 'highlighted': validationNoteSelection.isSelected(row) }">
                </tr>
              </table>
            </div>
          </form>
        </div>
      </mat-tab>

      <!-- Localização (Tab) -->
      <mat-tab label="Localização">
        <form class="edit-form">
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pb-3 pr-2">
            <single-autocomplete [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa" [(valueData)]="view['band']"
              [extraData]="model && model['band'] ? model['band'] : null"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <single-autocomplete [data]="singleDataCacheService.getValues('Duct')" placeholder="Duto" [(valueData)]="view['duct']"
              [extraData]="model && model['duct'] ? model['duct'] : null"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <single-autocomplete [data]="singleDataCacheService.getValues('Valve')" placeholder="Válvula" [(valueData)]="view['valve']"
              [extraData]="model && model['valve'] ? model['valve'] : null"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>KM</mat-label>
              <input [readonly]="readOnly" name="targetPointKM" matInput matTooltip="{{ view['targetPointKM'] }}" placeholder="KM" [(ngModel)]="view['targetPointKM']"
                #targetPointKM="ngModel" targetPointKM [dropSpecialCharacters]="false" mask="000+000" [validation]="true" />
              <mat-error *ngIf="targetPointKM.invalid">Formato inválido para o KM.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>KM (Lat, Long)</mat-label>
                <input [readonly]="readOnly" name="targetPointLatLong" matInput placeholder="KM (Lat, Long)" matTooltip="{{ view['targetPointLatLong'] }}"
                [(ngModel)]="view['targetPointLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'targetPointLatLong')"
                #targetPointLatLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="true" />
                <mat-error *ngIf="targetPointLatLong.invalid">Formato inválido para o KM (Lat, Long)).</mat-error>
              </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Início Trecho (KM)</mat-label>
              <input [readonly]="readOnly" name="stretchStartKM" matInput placeholder="Início Trecho (KM)" matTooltip="{{ view['stretchStartKM'] }}"
                [(ngModel)]="view['stretchStartKM']" #stretchStartKM="ngModel" stretchStartKM
                [dropSpecialCharacters]="false" mask="000+000" [validation]="true" />
              <mat-error *ngIf="stretchStartKM.invalid">Formato inválido para o Início do trecho (KM).</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Fim Trecho (KM)</mat-label>
              <input [readonly]="readOnly" name="stretchEndKM" matInput placeholder="Fim Trecho (KM)" [(ngModel)]="view['stretchEndKM']"
                matTooltip="{{ view['stretchEndKM'] }}" #stretchEndKM="ngModel" stretchEndKM [dropSpecialCharacters]="false" mask="000+000" />
              <mat-error *ngIf="stretchEndKM.invalid">Formato inválido para o Fim do trecho (KM).</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Início Trecho (Lat, Long)</mat-label>
              <input [readonly]="readOnly" name="stretchStartLatLong" matInput placeholder="Início Trecho (Lat, Long)" matTooltip="{{ view['stretchStartLatLong'] }}"
                [(ngModel)]="view['stretchStartLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'stretchStartLatLong')"
                #stretchStartLatLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="false"/>
                <mat-error *ngIf="stretchStartLatLong.invalid">Formato inválido para o Início do trecho (Lat, Long).</mat-error>
              </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Fim Trecho (Lat, Long)</mat-label>
              <input [readonly]="readOnly" name="stretchEndLatLong" matInput placeholder="Fim Trecho (Lat, Long)" matTooltip="{{ view['stretchEndLatLong'] }}"
                [(ngModel)]="view['stretchEndLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'stretchEndLatLong')"
                #stretchEndLatLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="false"/>
                <mat-error *ngIf="stretchEndLatLong.invalid">Formato inválido para o Fim do trecho (Lat, Long).</mat-error>
            </mat-form-field>
            <single-autocomplete [data]="singleDataCacheService.getValues('PipeStretch')" placeholder="Trecho" [(valueData)]="view['stretch']"
              [extraData]="model && model['stretch'] ? model['stretch'] : null"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>CEP</mat-label>
              <input [readonly]="readOnly" name="zipCode" matInput placeholder="CEP" matTooltip="{{ view['zipCode'] }}" [(ngModel)]="view['zipCode']" mask="00000-000" [dropSpecialCharacters]="false"/>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Logradouro</mat-label>
              <input [readonly]="readOnly" name="street" matInput matTooltip="{{ view['street'] }}" placeholder="Logradouro" [(ngModel)]="view['street']" />
            </mat-form-field>
            <single-autocomplete [data]="singleDataCacheService.getValues('City')" placeholder="Cidade" [(valueData)]="view['city']"
              [extraData]="model && model['city'] ? model['city'] : null"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <single-autocomplete [data]="singleDataCacheService.getValues('State')" placeholder="UF"
              [extraData]="model && model['state'] ? model['state'] : null"
              [(valueData)]="view['state']" [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>

          </div>
        </form>
      </mat-tab>

      <!-- Verificações (Tab) -->
      <mat-tab label="Verificações">
        <form class="edit-form">
          <div class="w-100 px-3">
            <app-verification-list #verificationList  [editedEvent]="model" *ngIf="identifierEvent" [readOnly]="readOnly"> </app-verification-list>
          </div>
        </form>
      </mat-tab>

      <!-- Resultados (Tab) -->
      <mat-tab label="Resultados">
        <form class="edit-form">
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <!-- Ocorrência -->
            <single-autocomplete [data]="resultOccurrenceDescription | keyvalue" placeholder="Ocorrência"
              [(valueData)]="view['result']['occurrence']" name="value" optionValue="key" [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <!-- Km Ocorrência -->
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>KM Ocorrência</mat-label>
              <input [readonly]="readOnly" name="occurrenceKM" matInput matTooltip="{{ view['result']['occurrenceKM'] }}" placeholder="KM Ocorrência" [dropSpecialCharacters]="false"
                mask="000+000" [(ngModel)]="view['result']['occurrenceKM']" #occurrenceKM="ngModel" [validation]="true"/>
              <mat-error *ngIf="occurrenceKM.invalid">Formato inválido para o KM da Ocorrência.</mat-error>
              </mat-form-field>
            <!-- Lat, Long Ocorrência -->
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Lat, Long Ocorrência</mat-label>
              <input [readonly]="readOnly" name="occurrenceLatLong" matInput placeholder="Lat, Long Ocorrência" matTooltip="{{ view['result']['occurrenceLatLong'] }}"
                [(ngModel)]="view['result']['occurrenceLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'result', 'occurrenceLatLong')"
                #occurrenceLatLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="true" />
              <mat-error *ngIf="occurrenceLatLong.invalid">Formato inválido para o Lat, Long da Ocorrência.</mat-error>
            </mat-form-field>
          </div>
          <div class="w-100 px-3">
            <!-- Notas -->
            <mat-form-field appearance="outline" fxFlex="100%" class="w-100">
              <mat-label>Notas</mat-label>
              <textarea name="text" matInput [(ngModel)]="view['result']['notes']" placeholder="Notas" rows="5" [readonly]="readOnly"></textarea>
            </mat-form-field>
          </div>
          <header class="sipd-header">
            <span class="mat-body-2">Ações Principais (de acordo com a Origem)</span>
            <div class="fill-space"></div>
          </header>
          <div class="px-3">
            <table mat-table [dataSource]="actionsDataSource" class="w-100 -outlined mb-5">

              <!-- Ações principais -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef width="25%"> Descrição </th>
                <td mat-cell *matCellDef="let item"> {{ item.action }} </td>
              </ng-container>
              <!-- Ação foi executada -->
              <ng-container matColumnDef="done">
                <th mat-header-cell *matHeaderCellDef width="100px"> Executada </th>
                <td mat-cell *matCellDef="let item">
                  <mat-slide-toggle (click)="$event.stopPropagation()" color="accent" [disabled]="readOnly"
                    (change)="onChangeActionDone(item)" [checked]="isActionDone(item)">
                  </mat-slide-toggle>
                </td>
              </ng-container>
              <!-- Comentários do Analista -->
              <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef> Comentários do Analista </th>
                <td mat-cell *matCellDef="let item"> {{ item.comment }} </td>
              </ng-container>
              <!-- Edição -->
              <ng-container matColumnDef="edit" width="100px">
                <th mat-header-cell *matHeaderCellDef class="fit-content"> </th>
                <td mat-cell class="fit-content" *matCellDef="let item">
                  <button mat-icon-button color="accent" (click)="onActionEdit(item)" class="edit-button" [disabled]="readOnly">
                    <mat-icon class="material-icons-outlined"  matTooltip="Editar Comentário">rate_review</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="actionDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: actionDisplayedColumns;">
              </tr>
            </table>
          </div>
        </form>
      </mat-tab>
      <!-- Eventos Relacionados (Tab) -->
      <mat-tab label="Eventos Relacionados">
        <form class="edit-form">
          <div class="w-100 px-3">
            <app-event-list #eventList [insideEventEdition]="true" [editReadOnly]="readOnly" [editedEventObservable]="editedEventObservable"> </app-event-list>
          </div>
        </form>
      </mat-tab>
    <!-- Anexos (Tab) -->
    <mat-tab label="Anexos">
      <form class="edit-form">

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile1']" [seeFile]="seeFile"
              file_label="Anexo 1" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.ONE)" (viewFileSelected)="openOperationFile($event, attachmentNumber.ONE)">
            </app-upload>
          </mat-card>          
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile2']" [seeFile]="seeFile" 
              file_label="Anexo 2" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.TWO)" (viewFileSelected)="openOperationFile($event, attachmentNumber.TWO)">
            </app-upload>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile3']" [seeFile]="seeFile" 
              file_label="Anexo 3" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.THREE)" (viewFileSelected)="openOperationFile($event, attachmentNumber.THREE)">
            </app-upload>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile4']" [seeFile]="seeFile" 
              file_label="Anexo 4" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event,attachmentNumber.FOUR)" (viewFileSelected)="openOperationFile($event,attachmentNumber.FOUR)">
            </app-upload>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile5']" [seeFile]="seeFile" 
              file_label="Anexo 5" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.FIVE)" (viewFileSelected)="openOperationFile($event, attachmentNumber.FIVE)">
            </app-upload>
          </mat-card>
        </div>
      </form>
    </mat-tab>
    </mat-tab-group>

    
  
    
    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>

  </div>
</div>
