import { Injectable } from "@angular/core";
import { FILTER_OPTION_ALL } from "src/app/common/constants";
import { FilterModel } from 'src/app/general/filter-component/filter.model';

@Injectable({
  providedIn: 'root'
})
export class ObservedAreaFilterModel extends FilterModel{

  areaStatus:      string;
  types:          string[];
  responsibles:   string[];
  bands:          string[];
  cities:         string[];
  states:         string[];
  years:          string[];

  constructor(){
    super();
    this.areaStatus = FILTER_OPTION_ALL;
  }
}
