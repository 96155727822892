import {UserModel} from './user.model';
import { OperationModel } from './operation.model';
import { OperationType, OperationStatus } from './enums.enum';
import { ResultsModel } from './results.model';
import LanguageUtils from '../service/util/language-utils';

export class PatrolModel extends OperationModel {
  author:             UserModel;
  name:               string;
  patrolRepetitionId: string;
  result:             ResultsModel; 
  patrolInspectionType:    string;

  constructor() {
    super();
    this.type = OperationType.PATROL;
    this.status = OperationStatus.PLANNED;
    this.result = new ResultsModel();
  }
  
  static addLanguageMap(){
    LanguageUtils.languageMap['author'] = 'Planejador';
    LanguageUtils.languageMap['result'] = 'Resultados';
    LanguageUtils.languageMap['patrolInspectionType'] = 'Tipo de Inspeção';
  }
}
