import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseMapComponent } from './base-map/base-map.component';
import { UploadComponent } from './upload/upload.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BooleanBRPipe } from './boolean-br.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { SystemCheckDialogComponent } from './system-check/system-check-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GlobalSpinnerComponent } from './global-spinner/global-spinner.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToViewPipe } from '../model/pipe/user-model-toview.pipe';
import { ImportPointsKmlDialogComponent } from './operation/import-points-kml-dialog/import-points-kml-dialog.component';
import { ImportGeoKmlDialogComponent } from './import-geo-kml-dialog/import-geo-kml-dialog.component';
import { ImportPointsXlsDialogComponent } from './operation/import-points-xls-dialog/import-points-xls-dialog.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { InsertPointDialogComponent } from './operation/insert-point-dialog/insert-point-dialog.component';
import { ImportPointsDialogComponent } from './operation/import-points-dialog/import-points-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { EventListDialogComponent } from './operation/event-list-dialog/event-list-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MessageComponent } from './message/message.component';
import { MarkerFilterModel } from './message/marker.filter.model';
import { MatMenuModule } from '@angular/material/menu';
import { SingleDataEditComponent } from './single-data-edit/single-data-edit.component';
import { SingleDataListComponent } from './single-data-list/single-data-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LocationPipe } from '../model/pipe/location.pipe';
import { AnswerListComponent } from './answer-list/answer-list.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MultiselectAutocomplete } from './multiselect-autocomplete/multiselect-autocomplete.component';
import { SingleAutocomplete } from './single-autocomplete/single-autocomplete.component';
import { TrackPointsDialogComponent } from './track-points-dialog/track-points-dialog.component';
import { LoginAsComponent } from './login-as/login-as.component';
import { DateChooserComponent } from './date-chooser/date-chooser.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LoginErrorComponent } from './login-error/login-error.component';


@NgModule({
  declarations: [
    BaseMapComponent,
    ConfirmationDialogComponent,
    SystemCheckDialogComponent,
    UploadComponent,
    BooleanBRPipe,
    GlobalSpinnerComponent,
    ImportPointsKmlDialogComponent,
    ImportGeoKmlDialogComponent,
    ImportPointsXlsDialogComponent,
    NotificationDialogComponent,
    InsertPointDialogComponent,
    ImportPointsDialogComponent,
    TrackPointsDialogComponent,
    EventListDialogComponent,
    MessageComponent,
    SingleDataEditComponent,
    SingleDataListComponent,
    SingleAutocomplete,
    DateChooserComponent,
    MultiselectAutocomplete,
    ToViewPipe,
    LocationPipe,
    AnswerListComponent,
    LoginAsComponent,
    LoginErrorComponent
  ],
  exports: [
    BaseMapComponent,
    ConfirmationDialogComponent,
    UploadComponent,
    BooleanBRPipe,
    GlobalSpinnerComponent,
    ToViewPipe,
    LocationPipe,
    ImportPointsKmlDialogComponent,
    ImportPointsXlsDialogComponent,
    NotificationDialogComponent,
    EventListDialogComponent,
    MessageComponent,
    SingleDataEditComponent,
    SingleDataListComponent,
    AnswerListComponent,
    SingleAutocomplete,
    DateChooserComponent,
    MultiselectAutocomplete
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatTableModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatGridListModule,
    MatIconModule,
    DragDropModule,
    NgxAudioPlayerModule,
    MatRadioModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatCardModule,
    MatSortModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    BooleanBRPipe,
    MarkerFilterModel
  ]
})
export class GeneralModule { }
