import { Injectable } from '@angular/core';
import { LOCAL_USER_KEY, LOGIN_AS_USER_KEY, POPOUT_DATA_KEY, PROFILE_USER_KEY, SPECIFICS_PLACEMENTS } from '../common/constants';
import { ProfileModel } from '../model/profile.model';
import { UserModel } from '../model/user.model';

/**
 * Serviço responsável por guardar os dados do usuário logado e o estado da lista de objetos móveis da
 * tela de rastreamento na sessão do usuário.
 */
@Injectable({
    providedIn: 'root',
  })
export class StorageService {

    private localUser: UserModel = null;
    private profileUser: ProfileModel = null;
    private loginAsUser: UserModel = null;    
    private specificPlacementsId : string[] = [];

    getLocalUser(): UserModel {
      if (!this.localUser) this.localUser = this.get(LOCAL_USER_KEY);
      return this.localUser;
    }

    setLocalUser(loggedUser: UserModel) {
      this.localUser = loggedUser;
      this.set(LOCAL_USER_KEY, loggedUser);
    }

    getLoginAsUser(): UserModel {
      if (!this.loginAsUser) this.loginAsUser = this.get(LOGIN_AS_USER_KEY);
      return this.loginAsUser;
    }

    setLoginAsUser(loggedUser: UserModel) {
      this.loginAsUser = loggedUser;
      this.set(LOGIN_AS_USER_KEY, loggedUser);
    }

    getLocalProfile(): ProfileModel{
      if(!this.profileUser) this.profileUser = this.get(PROFILE_USER_KEY);
      return this.profileUser; 
    }
    
    setLocalProfile(profileUser: ProfileModel) {
      this.set(PROFILE_USER_KEY, profileUser);
    }

    getSpecificPlacementIds(): string[] {
      this.specificPlacementsId = this.get(SPECIFICS_PLACEMENTS);
      if (!this.specificPlacementsId) return []; // Lista vazia quer dizer que tem acesso a todas as lotações
      return this.specificPlacementsId; 
    }

    setSpecificPlacementIds(specificPlacementsId : string[]){
      this.set(SPECIFICS_PLACEMENTS, specificPlacementsId);
    }

    /**
     * Atribui um valor `value` à uma entrada `key` do `localStorage`.
     *
     * @param key A chave, do tipo `string`.
     * @param value O valor, do tipo `any`. Quando `!= null` o valor é serialiado
     *    em JSON e armazenado. Quando `null` remove a entrada de chave `key` do
     *    `localStorage`.
     */
    set(key: string, data: any): void {
      if(data){
        localStorage.setItem(key, JSON.stringify(data))
      }else{
        localStorage.removeItem(key);
      }
    }

    /**
     * Obtém o valor de chave `key` do `localStorage`.
     *
     * @param key A chave, do tipo `string`.
     * @return O objeto se existir, senão retorna `null`.
     */
    get(key: string): any {
      let data = localStorage.getItem(key);
      if(data){        
        return JSON.parse(data);
      } 
      else{
        return null;
      }
    }

    /**
     * Obtém do `localStorage` dados salvos durante um destacamento de um painel
     * do Golden Layout (@see base-golden-panel.ts).
     *
     * @return Um objeto do tipo `any` se a entrada existir, senão `null`.
     */
    getPopoutData() {
      return this.get(POPOUT_DATA_KEY);
    }

    /**
     * Atribui ao `localStorage` os dados a serem salvos durante um destacamento
     * de um painel do Golden Layout (@see base-golden-panel.ts).
     *
     * @arg data Um objeto do tipo `any`. Se `null` remove o objeto (@see set).
     */
    setPopoutData(data): void {
      this.set(POPOUT_DATA_KEY, data);
    }

   /**
    * Indica se há dado a ser restaurado do `localStorage`durante o destacamento
    * de um painel do Golden Layout.
    *
    * @return `true` se a entrada `POPOUT_DATA_KEY` do `localStorage` for
    *  diferente de `null`, senão retorna `false`.
    */
    hasPopoutData(): boolean {
      return localStorage.getItem(POPOUT_DATA_KEY) != null;
    }
}
