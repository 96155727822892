<div class="sipd-container">

  <div class="sipd-header">
    <span class="mat-title m-0">{{title}}</span><small matTooltip="Número de Registros Lidos">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions">
      <mat-form-field class="list-filter header-input" appearance="outline">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PATROL_TEAM)'
        (click)="onCreateClick()"
        matTooltip="Adicionar Equipe">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Editar Equipe(s) Selecionada(s)"
        [disabled]="this.selection.selected.length == 0"
        (click)="onEditManyClick(this.selection.selected)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
      </button>

      <button mat-icon-button color="primary"
        matTooltip="Copiar Equipe Selecionada
                    (Somente 1 Equipe pode estar selecionada)"
        [disabled]="this.selection.selected.length != 1"
        [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PATROL_TEAM)'
        (click)="onCopyClick(this.selection.selected.pop())">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>

      <button mat-icon-button color="primary"
        (click)="onDeleteManyClick(this.selection.selected, $event)"
        matTooltip="Remover Equipes Selecionadas"
        [hidden]='!authorizationService.userHasPermission(permission.DELETE_PATROL_TEAM)'
        [disabled]="this.selection.selected.length == 0">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar Rastro no Mapa das Equipes Selecionadas"
        [disabled]="this.selection.selected.length == 0"
        (click)="onHistoricalTrackingManyClick()">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="sipd-content bg-white">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.name}} </td>
      </ng-container>

      <ng-container matColumnDef="company.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{getCompany(element)?.name}} </td>
      </ng-container>

      <ng-container matColumnDef="company.placement.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Lotação </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{getCompany(element)?.placement?.name}} </td>
      </ng-container>

      <ng-container matColumnDef="serviceType">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Serviço </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{serviceTypeDescription[element?.serviceType]}}</td>
      </ng-container>

      <ng-container matColumnDef="shift">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Turno </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{shiftDescription[element?.shift]}}</td>
      </ng-container>

      <ng-container matColumnDef="users.0.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Profissional 1 </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element.users ? getUserNameLoginTitle(element.users[0]) : ""}} </td>
      </ng-container>

      <ng-container matColumnDef="users.1.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Profissional 2 </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element.users ? getUserNameLoginTitle(element.users[1]) : ""}} </td>
      </ng-container>

      <ng-container matColumnDef="users.2.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Profissional 3 </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element.users ? getUserNameLoginTitle(element.users[2]) : ""}} </td>
      </ng-container>

      <ng-container matColumnDef="vehicle.plate">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Placa </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element.vehicle?.plate}} </td>
      </ng-container>

      <ng-container matColumnDef="patrolCoordinator.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Coordenador </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{getUserNameLoginTitle(element.patrolCoordinator)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
        (contextmenu)="openContextMenu($event, element)"
        [ngClass]="{ 'highlighted': selection.isSelected(row)}">
      </tr>
    </table>

    <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>

    <mat-menu #contextMenu="matMenu">
      <button mat-menu-item
       (click)="onEditClick(this.contextMenuSelectedItem, $event)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon><span>Abrir Equipe</span>
      </button>
      <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PATROL_TEAM)'
       (click)="onCopyClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon><span>Copiar Equipe</span>
      </button>
      <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.DELETE_PATROL_TEAM)'
        (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.name, $event)" matTooltip="Remover Equipe">
        <mat-icon class="material-icons-outlined">delete</mat-icon><span>Remover Equipe</span>
      </button>
      <button mat-menu-item color="primary" (click)="onHistoricalTrackingClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">my_location</mat-icon><span>Mostrar Rastro no Mapa</span>
      </button>
    </mat-menu>

  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
