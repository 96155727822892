import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { NGXLogger } from 'ngx-logger';
import { v4 as uuidv4 } from 'uuid';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { MarkerModel } from 'src/app/model/marker.model';
import { Observable, Subject } from 'rxjs';
import { API_VERSION_ENDPOINT } from '../../common/constants';

import * as moment from 'moment';
import { EntityModel } from 'src/app/model/entity.model';
import { MarkerFilterModel } from 'src/app/general/message/marker.filter.model';
import { FilterModel } from 'src/app/general/filter-component/filter.model';
import { first } from 'rxjs/operators';
import { UserModel } from 'src/app/model/user.model';
import { MarkerType, SourceType } from 'src/app/model/enums.enum';
import { ChannelModel } from 'src/app/model/channel.model';
import { ChannelService } from './channel.service';

@Injectable({
  providedIn: 'root'
})
export class MarkersService extends EntityService {

  // Notificação de novos marcadores recebidos pelo websocket
  private newMarkerReceived: Subject<MarkerModel> = new Subject();

  // Notificação de conexão estabelecida com o websocket
  private markerWebsocketConnected: Subject<boolean> = new Subject();

  constructor(logger:         NGXLogger,
              private channelService: ChannelService,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.markers_address}/marker`);
  }

  notifyNewMarkerReceived(marker: MarkerModel) {
    this.newMarkerReceived.next(marker);
  }

  onNewMarkerReceived(): Observable<MarkerModel> {
    return this.newMarkerReceived.asObservable();
  }

  notifyWebsocketConnected(connected: boolean) {
    this.markerWebsocketConnected.next(connected);
  }

  onMarkerWebsocketConnected(): Observable<boolean> {
    return this.markerWebsocketConnected.asObservable();
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>( pageIndex?: number, pageSize?: number, sort?: string, filter?: FilterModel, extraParams?: Map<string, string> ): Observable<T> {
    let params: HttpParams = this.createHttpParams(filter as MarkerFilterModel);
    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }


  private createHttpParams(filter: MarkerFilterModel): HttpParams{
    let params: HttpParams = new HttpParams();

    if(filter){

      if(filter.channelId){
        params = params.append('channelId', filter.channelId)
      }else{
        if(filter.operationId){
          params = params.append('operationId', filter.operationId);
        }

        if (filter.operationIds){
          filter.operationIds.forEach( opId => {
            params = params.append('operationIds', opId);
          });
        }
  
        if(filter.operationType){
          params = params.append('operationType', filter.operationType);
        }

        if(filter.inspectionId){
          params = params.append('inspectionId', filter.inspectionId)
        }

        if(filter.patrolTeamId){
          params = params.append('patrolTeamId', filter.patrolTeamId)
        }

        if(filter.startDate){
          const startDateTime = moment(filter.startDate).format('x');
          params = params.append('startDate', startDateTime);
        }

        if(filter.endDate){
          const endDateTime = moment(filter.endDate).format('x');
          params = params.append('endDate', endDateTime);
        }
      }
    }

    return params;
  }

  public loadFileById(fileId: string): Observable<HttpResponse<Blob>>{
    return this.http.get(this.apiUrl + '/file/' + fileId, { observe: 'response', responseType: 'blob' });
  }

  public create(record: MarkerModel, formElements: Map<string, object>) {
    this.logger.debug('.create()');
    const formData: FormData = super.buildFormData("marker", record, formElements);
    return super.postAsFormData(this.apiUrl, formData);
  }

  private createTeamInfoMarker(user: UserModel, patrolTeamId: string, msg: string): MarkerModel{
    let channel: ChannelModel = this.channelService.getChannelIdByReferenceId(patrolTeamId);
    if (!channel) {
      this.logger.error("Canal não encontrado para Equipe de id " + patrolTeamId);
      return null;
    }

    let markerMessage: MarkerModel = new MarkerModel();
    markerMessage.message = msg;
    markerMessage.patrolTeamId = patrolTeamId;
    markerMessage.markerType = MarkerType.INFO_MESSAGE;
    markerMessage.sourceType = SourceType.WEB_APP;
    markerMessage.priority = false;
    markerMessage.location = {lat: 0, lng: 0};
    markerMessage.timestamp = moment().valueOf();
    markerMessage.serverTimestamp = null; // Não temos essa estimativa na Web
    markerMessage.userId = user.id.toString();
    markerMessage.userName = user.login;  // Esconde o nome de quem não é profissional em canais de equipe
    markerMessage.channelId = channel.id;
    markerMessage.channelName = channel.name;
    markerMessage.uuid = uuidv4();

    return markerMessage;
  }

  public sendTeamInfoMessage(user: UserModel, patrolTeamId: string, msg: string) {
    const marker = this.createTeamInfoMarker(user, patrolTeamId, msg);
    if (!marker) return;

    const formElements = new Map<string, object>();
    this.create(marker, formElements).pipe(first()).subscribe((marker: MarkerModel) => {
      this.logger.info('MarkersService.sendTextMessage - envio OK');
    }, error => this.logger.error(error));
  }

  /**
   * Obtem a versão do projeto Event-Verification
   */
  public getServiceVersion(): Observable<any> {
    return this.http.get(`${this.apiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }
}
