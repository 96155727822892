import { Injectable } from "@angular/core";
import { FilterModel } from 'src/app/general/filter-component/filter.model';

@Injectable({
  providedIn: 'root'
})
export class EventFilterModel extends FilterModel{

  sources:        string[];
  occurrences:    string[];
  channels:       string[];
  startKM:        string;
  endKM:          string;
  filterByAssociatedEvent: string;
  identifier: string;
}
