import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { EventModel } from 'src/app/model/event.model';
import { EventService } from 'src/app/service/model/event.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver/dist/FileSaver';
import {
  AlertPriorityDescription,
  EventSituationDescription,
  ResultOccurrenceDescription,
  EventStatusDescription,
  EventValidationDescription,
  EventStatus,
  EventFields,
  PermisionRuleOption,
  Permission,
  UserType,
  EventValidation,
  SourceType,
  SimfAlertTypeDescription
} from '../../../model/enums.enum';
import { MatTableDataSource } from '@angular/material/table';
import { EntityModel } from 'src/app/model/entity.model';
import { SelectionModel } from '@angular/cdk/collections';
import { EventMainActionDialogComponent } from '../event-action-dialog/event-main-action-dialog.component';
import { EventValidationNoteDialogComponent } from '../event-validation-note-dialog/event-validation-note-dialog.component';
import { LatLongPattern,
         LatLongMask,
         FILL_DATA_PREFIX,
         SAVE_DATA_PREFIX,
         MAX_FILE_SIZE,
         } from '../../../common/constants';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { ResultsModel } from 'src/app/model/results.model';

import * as moment from 'moment';

import { UserModel } from 'src/app/model/user.model';
import { EventMainActionsService } from 'src/app/service/model/event.main.actions.service';
import { EventMainActionModel } from '../../../model/event.main.action.model';
import { AuthorizationService } from '../../../service/authorization/authorization.service';
import { ConfirmationDialogComponent } from 'src/app/general/confirmation-dialog/confirmation-dialog.component';
import { ValidationNoteModel } from 'src/app/model/validation.note.model';
import { AlertModel } from 'src/app/model/alert.model';
import { AlertsService } from '../../../service/model/alerts.service';
import FieldUtils from 'src/app/service/util/field-utils';
import { ViewChild } from '@angular/core';
import { VerificationListComponent } from '../../verification/verification-list/verification-list.component';
import { OperationModel } from 'src/app/model/operation.model';
import { OperationType, SourceTypeDescription, AttachmentNumber } from '../../../model/enums.enum';
import { VerificationModel } from '../../../model/verification.model';
import { VerificationService } from '../../../service/model/verification.service';
import { first } from 'rxjs/operators';
import { ASSOCIATED_EVENT_UPDATE_PREFIX } from '../../../common/constants';
import { EventListComponent } from '../event-list/event-list.component';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import ListUtils from '../../../service/util/list-utils';
import { EditComponent } from '../../edit-component';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentModel } from '../../../model/attachment.model';
import DateUtils from 'src/app/service/util/date-utils';
import { SingleAutocomplete } from 'src/app/general/single-autocomplete/single-autocomplete.component';
import { Moment } from 'moment-timezone';

@ProfileClassToConsole()
@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['../../../app.component.scss', './event-edit.component.scss']
})
export class EventEditComponent extends EditComponent implements OnInit, OnDestroy {

  /** ENUMS */
  eventStatusDescription        = EventStatusDescription;
  eventStatus                   = EventStatus;
  alertPriorityDescription      = AlertPriorityDescription;
  eventSituationDescription     = EventSituationDescription;
  resultOccurrenceDescription = ResultOccurrenceDescription;
  eventValidationDescription    = EventValidationDescription;
  permisionRuleOption           = PermisionRuleOption;
  attachmentNumber              = AttachmentNumber

  latLongPattern = LatLongPattern;
  latLongMask = LatLongMask;

  /** The list of columns to display on the validation note grid */
  validationNoteDisplayedColumns: string[];

  /** The list of columns to display on the action grid */
  actionDisplayedColumns: string[];

  /** The data source for validation note table */
  validationNoteDataSource: MatTableDataSource<ValidationNoteModel>;

  /** The data source for action table */
  actionsDataSource: MatTableDataSource<EntityModel>;

  selectedAlerts: AlertModel[];

  /** The structure to control validation note grid selection  */
  validationNoteSelection = new SelectionModel<ValidationNoteModel>(true, []);

  /* Identificador do evento que é passado para a lista de verificações */
  identifierEvent:string = null;

  /** Os usuários carregados do serviço do ccpd-registrations */
  validationUsers: UserModel[];
  analystUsers: UserModel[];
  operatorUsers: UserModel[];
  coturUsers: UserModel[];

  /* Lista de verificações */
  @ViewChild('verificationList', { static: true }) verificationListComponent: VerificationListComponent;

  /* Lista de eventos */
  @ViewChild('eventList', { static: true }) eventListComponent: EventListComponent;

  /* seleccão simples */
  @ViewChild('selector', { static: false }) selector: SingleAutocomplete;

  editedEventSubject: Subject<EventModel> = new Subject<EventModel>();
  editedEventObservable = this.editedEventSubject.asObservable();
  eventFields  = EventFields;

  saveFinishedSubscription: Subscription;
  private reloadUsersSubscription: Subscription;

  isHiddenButtonCreateEditEvent: boolean;  
  isHiddenButtonDeleteEvent: boolean; 
  isHiddenButtonEditForUser: boolean;
  isHiddenButtonDeleteValidationNotes: boolean;
  isHiddenButtonArchiveForUser: boolean;

  canEditCcpdAnalyst: boolean; 
  canEditValidationAnalyst: boolean;

  // arquivos axexo
  attachmentFile1 :   File;
  attachmentFile2 :   File;
  attachmentFile3 :   File;
  attachmentFile4 :   File;
  attachmentFile5 :   File;

  //arquivos backup, em caso de eliminar um ou mais arquivo e desfazer as modificações
  back_attachmentFile1 :   File;
  back_attachmentFile2 :   File;
  back_attachmentFile3 :   File;
  back_attachmentFile4 :   File;
  back_attachmentFile5 :   File;

  seeFile : boolean = true;
  showCNCLGroup: boolean = false;
  stringCNCL = "CNCL";

  constructor(logger:                           NGXLogger,
              public eventService:              EventService,
              storageService:                   StorageService,
              private eventMainActionService:   EventMainActionsService,
              protected dialog:                 MatDialog,
              toastr:                           ToastrService,
              public singleDataCacheService:    SingleDataCacheService,
              public entityCacheService:        EntityCacheService,
              private alertsService:            AlertsService,
              private verificationService:      VerificationService,
              public authorizationService:      AuthorizationService,
              private sanitizer:                DomSanitizer,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, eventService, dialog, environment.EVENT_MODEL_LABEL, environment.EVENT_TITLE_LABEL, storageService,
      'events-edit', environment.EVENT_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
  }

  ngOnInit(){
    this.initializeFields(); // Precisa vir antes da super.ngOnInit
    super.ngOnInit();    
    this.actionDisplayedColumns = ['action', 'done', 'comment', 'edit'];
    this.validationNoteDisplayedColumns = ['select', 'date', 'author','note'];
    this.isOperationArchiveButtonHiddenForUser();

    this.subscribeOnSaveIsFinished()
    this.subscribeOnAssociatedEventUpdate();
  }

  ngOnDestroy(): void {
    this.saveFinishedSubscription?.unsubscribe();      
    this.reloadUsersSubscription?.unsubscribe();
    this.glUnSubscribeEvent(ASSOCIATED_EVENT_UPDATE_PREFIX + 'events-edit');
  }

  subscribeOnSaveIsFinished(){
    this.saveFinishedSubscription = this.saveFinished$.subscribe(() => {
      if(this.selectedAlerts) {

        const observables: Observable<Object>[] = [];

        this.selectedAlerts.forEach((alert:AlertModel) => {
          alert.eventIdentifier = this.model['identifier'];
          observables.push(this.alertsService.editRecord(alert));
        });

        forkJoin(observables).pipe(first()).subscribe( (alerts: AlertModel[]) => {
          this.toastr.success('Alerta(s) vinculado(s) ao evento com sucesso.');
        }, error => {
          this.logger.error(error);
          this.toastr.error('Ocorreu uma falha ao vincular o(s) alerta(s) ao evento ' + this.model['identifier']);
        },
        () => {
          this.selectedAlerts = null;
        });

        this.glEmitEvent(FILL_DATA_PREFIX + 'central', {selectedAlerts: this.selectedAlerts});
      }
    })
  }

  private subscribeOnAssociatedEventUpdate(){
    this.glSubscribeEvent(ASSOCIATED_EVENT_UPDATE_PREFIX + 'events-edit', (e) => {
      let event = e as EventModel;
      if (event.id == this.id) {
        this.logger.debug("EventEditComponent-OnAssociatedEventUpdate");
        this.view['associatedEventId'] = event.associatedEventId;
        this.model['associatedEventId'] = event.associatedEventId;
        this.view['associatedIdentifier'] = event.associatedIdentifier;
        this.model['associatedIdentifier'] = event.associatedIdentifier;
        this.updateInitialView('associatedEventId');
        this.updateInitialView('associatedIdentifier');
      }
    });
  }

  initializeFields(){
    this.view = {};

    // Inicializando campos para evitar erros no HTML
    this.view['result'] = new ResultsModel();
    this.view['time'] = '';
    this.view['suspiciousTime'] = '';
    this.view['stopTime'] = '';
    this.view['startTime'] = '';

    let date = moment();
    this.view['date'] = date.format('DD/MM/yyyy');
    this.view['time'] = date.format('HH:mm');

    this.view['endDate'] = '';
    this.view['startDate'] = '';
    this.view['suspiciousDate'] = '';
    this.view['stopDate'] = '';
    this.view['endTime'] = '';

    this.view['status'] = EventStatus.IN_TREATMENT;

    this.view['identifier'] = "[NOVO]";
    this.identifierEvent = this.view['identifier'];

    this.view['validationNotes'] = [];

    this.buildValidationNoteDataSource();
  }

  loadFormOptionsData() {
    this.logger.debug('EventEditComponent.loadFormOptionsData()');

    const metadatas: string[] = [
      "Product",
      "State",
      "Band",
      "Duct",
      "Valve",
      "PipeStretch",
      "CommunicationChannel",
      "ReportSource",
      "Category168",
      "Subcategory168",
      "City"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);

    let _this = this; // Necessário por causa do contexto das callacks
    const onUsersLoad = function() {
      _this.updateValidationUsers();

      _this.analystUsers = _this.entityCacheService.getFilteredUsers([UserType.ANALYSIS_CCPD, UserType.ADMINISTRATOR, UserType.COORDINATOR_CCPD]);
      _this.operatorUsers = _this.entityCacheService.getFilteredUsers([UserType.CNCL]);
      _this.coturUsers = _this.entityCacheService.getFilteredUsers([UserType.COORDINATOR_CNCL]);
    };
    this.entityCacheService.loadUsers(this.loadingListService, onUsersLoad);
    this.reloadUsersSubscription = this.entityCacheService.onUsersReload().subscribe(onUsersLoad);
  }

  /**A lista de validação de usuários garante que: 
   * 1) o mesmo analista não pode criar e validar o evento, por tanto é removido da lista 
   * 2) Se é administrador,  não é removido da lista pois ele pode criar/validar o evento, além de selecionar um usuário da lista
   * A lista de usuários filtrados aqui, garante que na função  onValidationChange, seja selecionado o usuário de acordo com as regras 1 e 2.
   */
  private updateValidationUsers(){
    const event = this.view as EventModel;

    this.validationUsers = this.entityCacheService.getFilteredUsers([UserType.ANALYSIS_CCPD, UserType.ADMINISTRATOR, UserType.COORDINATOR_CCPD]);
    
    if (event.analyst && !this.isUserAdministrator()) {
      // Retira o Analista CCPD da lista de opções para Analista Validação
      this.validationUsers = this.validationUsers.filter(user => user.id != event.analyst.id);
      ListUtils.orderSimpleModelList(this.validationUsers);
    
      // Se já existia um selecionado, verifica se é igual ao analista
      if (event.validationAnalyst && event.analyst.id == event.validationAnalyst.id) {
        event.validationAnalyst = null; // Analista de Validação não pode ser o mesmo que Analista CCPD
      }
    }

    this.checkValidationAnalystData();
  }

  analystSelectionChanged(){
    this.updateValidationUsers();
  }

  private setAlertDataOnView(alert: AlertModel) {
    const timestamp = moment(alert.timestamp);
    this.view['date'] = timestamp.format('DD/MM/yyyy');
    this.view['time'] = timestamp.format('HH:mm');

    this.view['alertLevel'] = alert.alertPriority;
    this.view['band'] = alert.band;
    this.view['duct'] = alert.duct;
    this.view['targetPointLatLong'] = alert.location.coordinates[0].toFixed(6) + ',' + alert.location.coordinates[1].toFixed(6); // inicialização do GeoPoint para alertas está errada, está sendo feito (x=latitude e y=longitude)

    if (alert.sourceType == SourceType.SIMF) {
      this.view['targetPointKM'] = alert.scalePoint;
      this.view['source'] = SimfAlertTypeDescription[alert.simfAlertType];
    }
    else {
      this.view['targetPointKM'] = alert.km;
      this.view['source'] = SourceTypeDescription[alert.sourceType];
    }
  }

  private getVerificationAlertData(alert: AlertModel){
      this.verificationService.loadById(alert.operationId).pipe(first()).subscribe( (operation: OperationModel) => {
        let verification: VerificationModel = <VerificationModel>operation;
        this.view['stretch'] = (verification &&  verification.stretch) ? verification.stretch : '';
        }, error => this.logger.error(error));
  }

  createData(options?) {
    this.logger.debug('EventEditComponent.createData()');

    this.model = new EventModel();
    this.view = {};

    this.initializeFields();
    
    this.initializeAnalystAndOperatorCNCL();

    if (options && options.selectedAlerts) {
      this.selectedAlerts = options.selectedAlerts;

      this.setAlertDataOnView(this.selectedAlerts[0]);

      if (this.selectedAlerts[0].operationType == OperationType.EVENT_VERIFICATION) {
        this.getVerificationAlertData(this.selectedAlerts[0]);
      }
    }else {
      this.selectedAlerts = null;
    }
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['identifier'] = null;
    this.model['analyst'] = null;
    this.model['status'] = EventStatus.IN_TREATMENT;
    this.model['archived'] = false;

    const dateToSend = (new Date()).getTime();
    this.model['date'] = dateToSend;
    this.model['suspiciousTime'] = '';
    this.model['stopTime'] = '';
    this.model['startTime'] = '';
    this.model['validation'] = null;
    this.model['validationAnalyst'] = null;
    this.model['validationNotes'] = [];
    this.model['actions'] = [];
    this.model['result'] = new ResultsModel();
    this.model['suspiciousDate'] = '';
    this.model['stopDate'] = '';
    this.model['startDate'] = '';
    this.model['endDate'] = '';
    this.model['associatedEventId'] = null;
    this.model['associatedIdentifier'] = null;
  }

  getSources() {
    const sources = this.authorizationService.getEditEventSources();
    if (!sources) return this.singleDataCacheService.getValues('ReportSource');
  }

  canCopy(): boolean {
    if (!this.id) return false;

    // Não preciso testar permissão de criar/editar porque o botão está escondido nesse caso

    // Verifica a permissão de alterar eventos por origem
    const event = this.model as EventModel;
    return this.authorizationService.canEditEventBySource(event.source);
  }
  
  canEdit(): boolean {
    if (!this.model) return false;

    // Não preciso testar permissão de criar/editar porque o botão está escondido nesse caso

    const event = this.model as EventModel;

    // Verifica a permissão de alterar eventos por origem
    if (!this.authorizationService.canEditEventBySource(event.source)) {
      return false;
    }

    if(this.isStatusFinalized()){
      // Se o evento está concluído e validação está incompleta, o analista CCPD do evento tem permissão de editar novamente o evento, 
      // mesmo que o perfil dele não tenha permissão de alternar eventos concluídos
      if ((event.validation == EventValidation.INCOMPLETE && this.loggedUser.id == event.analyst.id)) {
        return true;
      }

      if(!this.authorizationService.userHasPermission(Permission.EDIT_END_EVENT)) {
        return false;
      }
    }
    
    return true;
  }

  mapModelToView() {
    this.logger.debug('EventEditComponent.mapModelToView()');

    super.mapModelToView();

    if (!this.model['result']) {
      this.view['result'] = new ResultsModel();
    }

    if (!this.model['status']) {
      this.view['status'] = EventStatus.IN_TREATMENT;
    }

    let date: Moment;
    if (this.model['date']) {
      date = moment(this.model['date']);
    }
    else {
      date = moment();
    }
    this.view['date'] = date.format('DD/MM/yyyy');
    this.view['time'] = date.format('HH:mm');

    if (this.model['endDate']) {
      date = moment(this.model['endDate']);
      this.view['endDate'] = date.format('DD/MM/yyyy');
      this.view['endTime'] = date.format('HH:mm');
    }else {
      this.view['endDate'] =  '';
      this.view['endTime'] =  '';

    }

    if (this.model['suspiciousDate']) {
      date = moment(this.model['suspiciousDate']);
      this.view['suspiciousDate'] = date.format('DD/MM/yyyy');
      this.view['suspiciousTime'] = date.format('HH:mm');
    }else {      
      if (this.model['suspiciousTime']) { //Evento antigo
        const suspiciousTimestamp = moment(this.model['suspiciousTime']);
        this.view['suspiciousDate'] =  suspiciousTimestamp.format('DD/MM/yyyy');
        this.view['suspiciousTime'] = suspiciousTimestamp.format('HH:mm');
      }else{
        this.view['suspiciousDate'] =  '';
        this.view['suspiciousTime'] =  '';
      }
    }

    if (this.model['stopDate']) {
      date = moment(this.model['stopDate']);
      this.view['stopDate'] = date.format('DD/MM/yyyy');
      this.view['stopTime'] = date.format('HH:mm');
    }else {
      if (this.model['stopTime']) { //Evento antigo
        const stopTimestamp = moment(this.model['stopTime']);
        this.view['stopDate'] = stopTimestamp.format('DD/MM/yyyy');
        this.view['stopTime'] = stopTimestamp.format('HH:mm');
      }else{
        this.view['stopDate'] =  '';
        this.view['stopTime'] =  '';
      }     

    }
    
    if(this.model['startDate']){
      date = moment(this.model['startDate']);
      this.view['startDate'] = date.format('DD/MM/yyyy');
      this.view['startTime'] = date.format('HH:mm');
    }else {      
      this.view['startDate'] =  '';
      this.view['startTime'] =  '';
    }

    if (!this.model['identifier']){
      this.view['identifier'] = "[NOVO]";
      this.identifierEvent = this.view['identifier']  //Um evento novo é tratado para carregar a lista de verificações vazia
    }
    else{
      this.identifierEvent = this.model['identifier']; //Seta o valor do identificador do evento para carregar a lista de verificações
    }

    this.logger.debug('Identificador do evento: ' + this.identifierEvent);

    if (!this.model['validationNotes'])
      this.view['validationNotes'] = [];

    this.view['validationNotes'].sort((note1, note2) => (note1.date < note2.date) ? 1 : -1);

    if(this.model['source']){
      this.sourceSelectionChanged(this.model['source']);
    }

    if (this.copy){
      this.view['analyst'] = null;
      this.view['operatorCNCL'] = null;
      this.initializeAnalystAndOperatorCNCL();
    }

    if (this.model['attachmentOpFile1']) {
      this.view['attachmentFile1'] = this.model['attachmentOpFile1'].name + "." + this.model['attachmentOpFile1'].extension;
    }

    if (this.model['attachmentOpFile2']) {
      this.view['attachmentFile2'] = this.model['attachmentOpFile2'].name + "." + this.model['attachmentOpFile2'].extension;
    }

    if (this.model['attachmentOpFile3']) {
      this.view['attachmentFile3'] = this.model['attachmentOpFile3'].name + "." + this.model['attachmentOpFile3'].extension;
    }

    if (this.model['attachmentOpFile4']) {
      this.view['attachmentFile4'] = this.model['attachmentOpFile4'].name + "." + this.model['attachmentOpFile4'].extension;
    }

    if (this.model['attachmentOpFile5']) {
      this.view['attachmentFile5'] = this.model['attachmentOpFile5'].name + "." + this.model['attachmentOpFile5'].extension;
    }

    this.buildActionDataSource();
    this.buildValidationNoteDataSource();

    this.glUpdateTabTitle(this.modelName + ': ' + this.view['identifier']);
  }

  mapViewToModel() {
    if (this.isSaving && this.isCreating())
      this.view['identifier'] = null;

    super.mapViewToModel();

    const date = DateUtils.stringDateTimeToTimestamp(this.view['date'], this.view['time'], true);
    this.model['date'] = date;

    const startDate = DateUtils.stringDateTimeToTimestamp(this.view['startDate'], this.view['startTime'], true);
    this.model['startDate'] = startDate;

    const endDate = DateUtils.stringDateTimeToTimestamp(this.view['endDate'], this.view['endTime'], true);
    this.model['endDate'] = endDate;

    const suspiciousDate = DateUtils.stringDateTimeToTimestamp(this.view['suspiciousDate'], this.view['suspiciousTime'], true);
    this.model['suspiciousDate'] = suspiciousDate;
    this.model['suspiciousTime'] = null;

    const stopDate = DateUtils.stringDateTimeToTimestamp(this.view['stopDate'], this.view['stopTime'], true);
    this.model['stopDate'] = stopDate;
    this.model['stopTime'] = null;

    this.extractAttachmentFileName();

  }

  /**
   * Carrega as ações principais dada uma origem
   */
  reloadEventMainActions() {
    this.eventMainActionService.loadFromServerBySourceType(this.view['source']).pipe(first()).subscribe( (actions: EventMainActionModel[]) => {
      if (!this.view['actions'] || this.view['actions'].length == 0 || !actions) {
        this.view['actions'] = actions || [];
      }
      else {
        let view_actions = this.view['actions'] as EventMainActionModel[];

        actions.forEach(a => {
          // Se encontrou a mesma, copia os dados existentes
          const v_a = view_actions.find(v_a => v_a.action == a.action && v_a.sourceType == a.sourceType)
          if (v_a) {
            a.comment = v_a.comment;
            a.done = v_a.done;
          }
        });

        this.view['actions'] = actions;
      }
      this.updateInitialView('actions');
      this.buildActionDataSource();
    }, error => {
      this.logger.error(`Erro ao obter as ações principais para a origem
                        ${JSON.stringify(this.view['source'])} :
                        ${JSON.stringify(error)}`);
    });
  }

  // ########################################################
  // GRID METHODS ACTIONS
  // #######################################################

  /**
   * Ação de alteração na execução de uma ação
   */
  onChangeActionDone(item: EventMainActionModel) {
    if (item['done'] === undefined) {
      item['done'] = true;
    } else {
      item['done'] = !item['done'];
    }
  }

  onActionEdit(item: EventMainActionModel){
    this.dialog.open(EventMainActionDialogComponent, {
      data: item,
      width: '28rem',
      panelClass: 'sipd-modal'
    });
  }

  /**
   * Atualiza o datasource das TableDataSource do cliente. A tabela das ações principais
   */
  buildActionDataSource(){
    this.actionsDataSource = new MatTableDataSource(this.view['actions']);
  }

  /***************************************** */

  onActionAddValidationNote() {
    let dialogRef = this.dialog.open(EventValidationNoteDialogComponent, {
      width: '28rem',
      panelClass: 'sipd-modal'
    });

    dialogRef.afterClosed().pipe(first()).subscribe( (note: string) => {
      if (note && note != '') {
        let date = new Date().getTime();
        let author = this.getUserNameLoginTitle(this.loggedUser);

        let validationNote = new ValidationNoteModel(date, author, note);
        this.view['validationNotes'].splice(0, 0, validationNote);
        this.validationNoteDataSource.data = this.view['validationNotes'];
        this.onValidationNoteAdded();
      }
    });
  }
  /* Abre o diálogo com os dados da nota de validação a ser editada */
  onActionEditValidationNote(row: ValidationNoteModel, event: Event){

    let dialogRef = this.dialog.open(EventValidationNoteDialogComponent, {
      width: '28rem',
      data: row,
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe( (note: string) => {
      if (note) {
        row.note = note;
      }
    },
    error => this.logger.error(error),
    () => dialogRef?.unsubscribe());
    this.validationNoteSelection.clear();
  }

  buildValidationNoteDataSource(){
    this.validationNoteDataSource = new MatTableDataSource(this.view['validationNotes']);
  }

  onDeleteManyValidationNoteClick() {
    if (this.validationNoteSelection.isEmpty()) return;

    const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: "Remover Nota(s) selecionada(s)?",
        title: 'Remover Nota',
        okLabel: 'Remover'
      }
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if(result){
        this.deleteSelectedValidationNotes();
      }
    },
    error => this.logger.error(error),
    () => openConfirmationSubscription?.unsubscribe());
  }


  private deleteSelectedValidationNotes(){
    this.validationNoteSelection.selected.forEach( (selectedItem: ValidationNoteModel) => {
        const index = this.view['validationNotes'].indexOf(selectedItem);
        if (index !== -1) {
          this.view['validationNotes'].splice(index, 1);
        }
        this.validationNoteSelection.deselect(selectedItem);
    });

    this.validationNoteDataSource.data = this.view['validationNotes'];
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelectedValidationNotes() {
    const numSelected = this.validationNoteSelection.selected.length;
    let numRows = 0;
    if(this.validationNoteDataSource && this.validationNoteDataSource.data){
      numRows = this.validationNoteDataSource.data.length;
    }
    return numSelected === numRows;
  }

  masterToggleValidationNotes() {
    let isAllSeleceted = this.isAllSelectedValidationNotes();
    if(isAllSeleceted) {
      this.validationNoteSelection.clear();
    }
    else if(this.validationNoteDataSource) {
      this.validationNoteDataSource.data.forEach(row => this.validationNoteSelection.select(row));
    }
  }

  onCheckboxValidationNoteClick(row){
    this.validationNoteSelection.toggle(row);
  }

  /** The tip for the checkbox on the passed row */
  checkboxTipValidationNotes(row?: ValidationNoteModel): string {
    if (!row) {
      return `${this.isAllSelectedValidationNotes() ? 'Desmarcar' : 'Selecionar'} Todos`;
    }
    return `${this.validationNoteSelection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Item`;
  }


  // ########################################################
  // GRID SELECTION CONTROL - Action
  // ########################################################

  /**
   * Verifica se a tag da ocorrência deve ou não aparecer
   */
  showOccurrenceTag() {
    return this.view['result'] && this.view['result']['occurrence']
      && (ResultOccurrenceDescription[this.view['result']['occurrence']] === ResultOccurrenceDescription.DUCT
      || ResultOccurrenceDescription[this.view['result']['occurrence']] === ResultOccurrenceDescription.VALVE);
  }

  /**
   * Verifica se uma ação foi executada
   */
  isActionDone(item: any) {
    return item['done'] === true;
  }

  /**
   * Método utilitário que sinaliza que os requisitos de preenchimento do formulário foram preenchidos.
   * Os campos obrigatórios devem ser verificados aqui para impedir que a ação de salvar avance.
   * @returns True caso todos os campos obrigatórios estejam devidamente preenchidos e false caso contrário
   */
  isRequiredFieldFilled(): boolean {
    if (!this.view['status'] ||
        !this.view['date'] ||
        !this.view['time'] ||
        !(DateUtils.stringDateTimeToTimestamp(this.view['date'], this.view['time'], true)) ||
        !this.view['communicationChannel'] ||
        !this.view['source'] ||
        !this.checkConditionalRequiredFieldFilled()) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['status']) names.push('Status');
    if (!this.view['date']) names.push('Data');
    if (!this.view['time']) names.push('Hora');
    if (!this.view['communicationChannel']) names.push('Canal de Comunicação');
    if (!this.view['source']) names.push('Origem');
    if (this.isStatusFinalizedAndChannel168()) {
      if (!this.view['callId168']) names.push('ID Chamada 168');
      if (!this.view['category168']) names.push('Categoria 168');
      if (!this.view['subcategory168']) names.push('Subcategoria 168');
    }
    return names;
  }

  /**
   * Verifica se as condições de preenchimento obrigatório foram satisfeitas. As regras listadas são:
   *  1) ID Chamada 168: obrigatório quando o status for "Concluído" e canal "168";
   *  2) Categoria 168: obrigatório quando o status for "Concluído" e canal "168";
   *  3) Subcategoria 168: obrigatório quando o status for "Concluído" e canal "168"
   * @returns True caso todas as condições de preenchimento estejam satisfeitas e false caso contrário.
   */
  private checkConditionalRequiredFieldFilled(): boolean {
    // Campos Obrigatórios quando status for "Concluído" e Canal 168
    if (this.isStatusFinalizedAndChannel168()) {
      // Regra 1) ID Chamada 168: obrigatório quando o status for "Concluído" e canal "168"
      if (!this.view['callId168']) {
        return false;
      }

      // Regra 2) Categoria 168: obrigatório quando o status for "Concluído" e canal "168"
      if (!this.view['category168']) {
        return false;
      }

      // Regra 3) Subcategoria 168: obrigatório quando o status for "Concluído" e canal "168"
      if (!this.view['subcategory168']) {
        return false;
      }
    }

    return true;
  }

  isStatusFinalizedAndChannel168(): boolean {
    // Aqui é diferente dos outros casos, usa a view porque é antes de salvar
    return this.isViewStatusFinalized() && this.view['communicationChannel'] == 'Canal 168';
  }

  onPasteLatLong(event: ClipboardEvent, name: string, subname?: string){
    let pastedValue = FieldUtils.pasteLatLong(event, subname? this.view[name][subname]: this.view[name]);
    if (pastedValue != null) {
      if (subname) this.view[name][subname] = pastedValue;
      else this.view[name] = pastedValue;
     return true;
    }
    return false;
  }

  getEventStatus(): string {
    if (this.view['archived'])
      return "Arquivado";
    else if (this.view['status'])
      return EventStatusDescription[this.view['status']];
    else
      return '';
  }

  hasLocation(): boolean {
    return this.view &&
           ((this.view['targetPointLatLong']) ||
            (this.view['stretchStartLatLong'] && this.view['stretchEndLatLong']));
   }

  protected afterLoadData(options){
    this.editedEventSubject.next(this.model as EventModel);
    this.isHiddenButtonEditForUser = !this.canEdit(); //checkPermisions é chamada antes do modelo ser inicializado
    this.updateAttachmentData(); // carrega os arquivos anexo do evento
  }

  isOperationArchiveButtonHiddenForUser() {
    this.isHiddenButtonArchiveForUser = !this.authorizationService.isOperationArchiveAllowed();     
  }

  onExportClick() {
    this.loadingListService.loadingOn();
    this.eventService.exportEvent(this.model as EventModel).pipe(first()).subscribe(response => {
      this.loadingListService.loadingOff();

      let filename = `${(this.model as EventModel).identifier}.zip`;
      filename = filename.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      saveAs(response, filename);
    },
    error =>{
      this.loadingListService.loadingOff();
      this.logger.error(error);
    });
  }

  canArchive(): boolean{
    if (!this.model)
      return false;

    if (this.authorizationService.isAdmin())
      return true;

    let canArchive = this.authorizationService.isControl();
    if (canArchive) {
      if (!this.hasArchivalRequirements(this.model as EventModel))
        canArchive = false;
    }

    return canArchive;
  }

  // Verifica os requisitos do model para arquivamento
  hasArchivalRequirements(model: EventModel): boolean{

    if(model.status != EventStatus.FINALIZED){ // Verifica se o status é Concluído
      return false;
    }

    if(model.validation != EventValidation.COMPLETE){ // Verifica se a validação é Completo
      return false;
    }

    if( this.authorizationService.isControl()){ // Se for perfil ANALYSIS_CCPD, verifica se os eventos foram criados por ele
      if(!model.analyst || model.analyst.id !== this.loggedUser.id.toString()){
        return false;
      }
    }

    return true;
  }

  onArchiveClick(){
    let entities: EntityModel[] = [];
    entities.push(this.model);
    this.eventService.archive(entities).pipe(first()).subscribe( values => {
      let archiveButtonLabel = this.model['archived'] ? 'Arquivar' : 'Desarquivar';
      this.toastr.info('Ação de ' + archiveButtonLabel + ' ' + this.modelName + ' foi realizada com sucesso.');
      this.view['archived'] = this.model['archived'];
      this.updateInitialView('archived');
      this.glEmitEvent(SAVE_DATA_PREFIX + this.componentName, {entity: this.model});
    }, error => this.logger.error(error));
  }

  /**
   * Evento disparado quando é feita uma seleção na caixa "Validação".
   * Regras:
   * <ol>
   *  <li>Se o usuário marcar "Completo", deve ser exibido um diálogo solicitando a confirmação da validação.</li>
   *  <li>Se o usuário logado for Analista CCPD, a caixa de seleção "Analista Validação" é preenchida automaticamente com seu nome (outros perfis não
   *  deveriam validar, e o Administrador determina o valor de todos os campos).</li>
   * </ol>
  */
  onValidationChange(event) {
    if (event === undefined) return;

    if (event === EventValidation.COMPLETE) {

      const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data:{
          msg: "ATENÇÃO: após a validação do evento, esta ação só poderá ser desfeita por um Administrador. Confirma a operação?",
          title: 'Validar Evento',
          okLabel: 'Validar'
        }
      }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
        if (!result) {
          this.view['validation'] = undefined;
          this.view['validationAnalyst'] = undefined;
          return;
        }
        // Força o selector fechar
        this.selector.onClosedEvent(event);
        this.selector.autocomplete.closePanel();
      },
      error => this.logger.error(error),
      () => openConfirmationSubscription?.unsubscribe());
    }

    // seleciona o analista de validação da lista de usuários validados
    if (this.isUserCCPDAnalyst() || this.isAdmin()) {

      const userFound = this.validationUsers.find((user) => {
        return user.id === this.loggedUser.id;
      });
      
      this.view['validationAnalyst'] = userFound;
    }
  }

  /**
   * Regras para que a caixa de seleção "Validação" seja desabilitada ou não:
   * <ol>
   *  <li>A caixa sempre estará habilitada para usuários Administradores.</li>
   *  <li>Se o evento estiver concluído (mas não validado) E o usuário logado for Analista CCPD E não for aquele que criou o evento, habilita.</li>
   *  <li>Para todos os demais casos, a caixa fica desabilitada.</li>
   * </ol>
   * @returns true se a caixa deve ser desabilitada, false para ser habilitada.
   */
  disableValidationComponentRules(): boolean {
    if (this.isUserAdministrator()) return false;
    if (this.isStatusFinalized() && !this.isEventValidated() && this.isUserCCPDAnalyst() && !this.isLoggedUserSameAsEventCreator() ) return false; // Concluído + não já estar validado + é analista CCPD + não é o mesmo da criação
    return true;
  }

  /**
   * Regras para desabilitar o botão de alteração de status do evento:
   * <ol>
   *  <li>Se for Administrador, sempre poderá alterar;</li>
   *  <li>Se o evento estiver concluído, não poderá mais ter seu status alterado (salvo por um Administrador);</li>
   *  <li>Se estiver em modo de visualização, o status não poderá ser alterado, somente em modo de edição.</li>
   * </ol>
   * @returns se a botão deve ser desabilitado, false para ser habilitado.
   */
  disableStatusChangeComponent(): boolean {
    if (this.isUserAdministrator()) return false;
    if (this.isStatusFinalized()) return true;
    return this.readOnly;
  }

  /**
   * Regras para atualização das caixas de seleção "Validação" e "Analista Validação" após inserção de uma nota de validação:
   * <ol>
   *  <li>Se o evento estiver concluído E o usuário logado for Analista CCPD E diferente daquele que criou o evento E o evento ainda não tiver sido validado, 
   *  o campo "Validação" deve assumir o valor "Incompleto" e o campo "Analista Validação" deve receber o nome do usuário logado.</li>
   * </ol>
   */
  onValidationNoteAdded() {
    if (this.isStatusFinalized() && (this.isUserCCPDAnalyst() || this.isAdmin()) && !this.isLoggedUserSameAsEventCreator() && !this.isEventValidated()) {
      this.view['validation'] = EventValidation.INCOMPLETE;
      this.currentUserAsField('validationAnalyst');
    }
  }

  /** Indica se o usuário logado pode ou não adicionar notas de validação. Regras:
   * <ol>
   *  <li>Administradores sempre podem adicionar notas de validação.</li>
   *  <li>Usuários não-administradores só podem adicionar notas de validação se tiverem a permissão para tal E o evento tiver sido concluído E não forem
   *  aquele que criou o evento.</li>
   * </ol>
   */
  userCanAddValidationNotes(): boolean {
    if (this.isUserAdministrator()) return true;
    return this.authorizationService.userHasPermission(Permission.CREATE_EDIT_EVENT_NOTES) && 
           this.isStatusFinalized() &&
           !this.isLoggedUserSameAsEventCreator();
  }

  /** Indica se o usuário logado pode ou não selecionar notas de validação (somente Administradores). */
  userCanSelectValidationNotes(): boolean {
    return  this.isUserAdministrator();
  }

  /** Indica se o usuário logado pode ou não editar notas de validação (somente Administradores E exatamente 1 nota deve estar selecionada). */
  userCanEditValidationNotes(): boolean {
    return  this.userCanSelectValidationNotes() && this.validationNoteSelection?.selected.length == 1;
  }

  /** 
   * Indica se o usuário logado pode ou não remover notas de validação. Regras:
   * <ol>
   *  <li>Usuários administradores sempre podem remover notas.</li>
   *  <li>Usuários não-administradores devem possuir permissão específica.</li>
   * </ol>
   */
  userCanDeleteValidationNotes(): boolean {
    return this.isUserAdministrator() || this.authorizationService.userHasPermission(Permission.DELETE_EVENT_NOTES);
  }

  /**
   * Indica se o botão para remover notas (assumindo que o usuário POSSA remover notas) deve estar desabilitado ou não (ao menos uma nota deve estar selecionada).
   */
  disableDeleteValidationNotes(): boolean {
    if (this.validationNoteSelection.hasValue()) return false;
    return true;
  }

  /** Indica se o evento está concluído ou não. */
  isStatusFinalized(): boolean {
    if (!this.model) return;
    // Usa o modelo, precisa já ter sido salvo para estar realmente concluído
    return this.model['status'] === EventStatus.FINALIZED;
  }

  isViewStatusFinalized(): boolean {
    if (!this.view) return;
    // Usa a view, precisa para antes de salvar
    return this.view['status'] === EventStatus.FINALIZED;
  }

  /** Indica se o evento foi validado ou não. */
  isEventValidated(): boolean {
    if (!this.model) return;
    // Usa o modelo, precisa já ter sido salvo para estar realmente concluído
    return this.model['validation'] === EventValidation.COMPLETE;
  }

  /** Indica se o usuário logado é ou não um administrador. */
  isUserAdministrator(): boolean {
    return this.loggedUserProfile.userType === UserType.ADMINISTRATOR;
  }

  /** Indica se o usuário logado possui ou não o perfil "Analista CCPD". */
  isUserCCPDAnalyst(): boolean {
    return this.loggedUserProfile.userType === UserType.ANALYSIS_CCPD;
  }

  /** Indica se o usuário logado foi o mesmo que criou o evento. */
  isLoggedUserSameAsEventCreator(): boolean {
    return this.loggedUser.id == this.view['analyst']?.id;
  }
  
  /**Verifica se o usuário tem permissão para editar o campo*/
  canEditField(field: string){
    if(this.isUserAdministrator()) return true;

    const permissionOptions = this.authorizationService.getUserPermissionOptions(Permission.CHANGE_ANY_EVENT_FIELD);
    
    if (permissionOptions && permissionOptions.option === PermisionRuleOption.ALL_FIELD) return true; 

    if(permissionOptions && permissionOptions.option === PermisionRuleOption.SOME_FIELDS)
      return !permissionOptions.fields.includes(field); // tem que ser negado porque é a lista dos campos que não podem ser editados. 
    
    return false;
  }

  checkPermissions(){
    this.isHiddenButtonCreateEditEvent = !this.authorizationService.userHasPermission(Permission.CREATE_EDIT_EVENT); 
    this.isHiddenButtonDeleteEvent = !this.authorizationService.userHasPermission(Permission.DELETE_EVENT); 
    this.isHiddenButtonDeleteValidationNotes = !this.userCanDeleteValidationNotes();

    this.canEditCcpdAnalyst = this.canEditField(this.eventFields.CCPD_ANALYST)
    this.canEditValidationAnalyst = this.canEditField(this.eventFields.VALIDATION_ANALYST); 
  }

  onEnableEditClick() {
    if (this.canEdit()) {
      this.readOnly = false;

      this.initializeAnalystAndOperatorCNCL(true);
    }
  }

  private initializeAnalystAndOperatorCNCL(updateDiff?: boolean){
    // Se o Analista CCPD não existe, então é inicializado com o usuário corrente se este for do perfil Analista CCPD, Administrador ou Coordenador CCPD
    if (!this.view['analyst']){
      if (this.loggedUserProfile.userType == UserType.ANALYSIS_CCPD || 
          this.loggedUserProfile.userType == UserType.ADMINISTRATOR ||
          this.loggedUserProfile.userType == UserType.COORDINATOR_CCPD){
        this.currentUserAsField('analyst');
        if (updateDiff) this.updateInitialView('analyst');
      }
    }

    // Se o Operador CNCL não existe, então é inicializado com o usuário corrente se este for do perfil Analista CNCL ou Coordenador CNCL
    if (!this.view['operatorCNCL']){
      if (this.loggedUserProfile.userType == UserType.CNCL || 
          this.loggedUserProfile.userType == UserType.COORDINATOR_CNCL) {
        this.currentUserAsField('operatorCNCL');
        if (updateDiff) this.updateInitialView('operatorCNCL');
      }
    }
  }

 /**Verifica se na lista existe o analista salvo no evento, em caso não adiciona na lista 
  * Usado só para visualização do evento
 */
  checkValidationAnalystData(){
    if(!this.model) return;
    if(this.copy) return;

    let analystEvent =  this.model['validationAnalyst'];
    if(analystEvent){
      let validationAnalystModel =  this.validationUsers.find( validationAnalyst => analystEvent.id == validationAnalyst.id);
      if(!validationAnalystModel)
        this.validationUsers.push(analystEvent);
    }
  }

  /** Adiciona um arquivo anexo
   * file: arquivo
   * attachmentNumber: número de anexo
   */
  addFile(file: File, attachmentNumber: AttachmentNumber): void {
    this.logger.debug('EventEditComponent.addFile()');

    if (file) { // Usuário selecionou um arquivo

      this.logger.debug('File size : ', file.size);
      // 1. Valida o peso do arquivo <=2Mb
      if(file.size > MAX_FILE_SIZE){        
        this.clearOperationFile(true, attachmentNumber, false);
        const title = 'Arquivo Inválido!';
        const message = 'Só podem ser carregados arquivos com até 2Mb';
        this.toastr.error(message, title);        
        return; 
      }

      let attachmentFile = new AttachmentModel();
      let filename = file.name.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      attachmentFile.name = filename.split('.').slice(0, -1).join('.');
      attachmentFile.extension = file.name.split('.').pop();
      attachmentFile.type = file.type;

      switch(attachmentNumber){
        case AttachmentNumber.ONE:
          this.attachmentFile1 = file;
          this.view['attachmentFile1'] = this.attachmentFile1.name;
          this.view['attachmentOpFile1'] = attachmentFile;
          break;
        case AttachmentNumber.TWO:
          this.attachmentFile2 = file;
          this.view['attachmentFile2'] = this.attachmentFile2.name;
          this.view['attachmentOpFile2'] = attachmentFile;
          break; 
        case AttachmentNumber.THREE:
          this.attachmentFile3 = file;
          this.view['attachmentFile3'] = this.attachmentFile3.name;
          this.view['attachmentOpFile3'] = attachmentFile;
          break; 
        case AttachmentNumber.FOUR:
          this.attachmentFile4 = file;
          this.view['attachmentFile4'] = this.attachmentFile4.name;
          this.view['attachmentOpFile4'] = attachmentFile;
          break; 
        case AttachmentNumber.FIVE:
          this.attachmentFile5 = file;
          this.view['attachmentFile5'] = this.attachmentFile5.name;
          this.view['attachmentOpFile5'] = attachmentFile;
          break;        
      }
    }
    else {
      this.clearOperationFile(true, attachmentNumber, true);
    }
  }

  openOperationFile(fileName: string, attachmentNumber: AttachmentNumber) : void {
    switch(attachmentNumber){
      case AttachmentNumber.ONE:
        this.viewFile(this.attachmentFile1 , this.view['attachmentOpFile1'] );
        break;
      case AttachmentNumber.TWO:
        this.viewFile(this.attachmentFile2 , this.view['attachmentOpFile2']);
        break;
      case AttachmentNumber.THREE:
        this.viewFile(this.attachmentFile3 , this.view['attachmentOpFile3']);
        break;
      case AttachmentNumber.FOUR:
        this.viewFile(this.attachmentFile4 ,  this.view['attachmentOpFile4']);
        break;
      case AttachmentNumber.FIVE:
        this.viewFile(this.attachmentFile5 ,  this.view['attachmentOpFile5']);
        break;
    }
   } 
  
  clearOperationFile(removed, attachmentNumber: AttachmentNumber, backup: boolean){
    switch(attachmentNumber){
      case AttachmentNumber.ONE:
        if(backup) this.back_attachmentFile1 = this.attachmentFile1;
        this.attachmentFile1 = undefined;
        this.view['attachmentFile1'] = undefined;
        this.view['attachmentOpFile1'] = undefined;
        break;
      case AttachmentNumber.TWO:
        if(backup) this.back_attachmentFile2 = this.attachmentFile2;
        this.attachmentFile2 = undefined;
        this.view['attachmentFile2'] = undefined;
        this.view['attachmentOpFile2'] = undefined;
        break;
      case AttachmentNumber.THREE:
        if(backup) this.back_attachmentFile3 = this.attachmentFile3;
        this.attachmentFile3 = undefined;
        this.view['attachmentFile3'] = undefined;
        this.view['attachmentOpFile3'] = undefined;
        break;
      case AttachmentNumber.FOUR:
        if(backup) this.back_attachmentFile4 = this.attachmentFile4;
        this.attachmentFile4 = undefined;
        this.view['attachmentFile4'] = undefined;
        this.view['attachmentOpFile4'] = undefined;
        break; 
      case AttachmentNumber.FIVE:
        if(backup) this.back_attachmentFile5 = this.attachmentFile5;
        this.attachmentFile5 = undefined;
        this.view['attachmentFile5'] = undefined;
        this.view['attachmentOpFile5'] = undefined;
        break;
    }
  }
  
  restoreFiles(){
    if(this.back_attachmentFile1) this.attachmentFile1 = this.back_attachmentFile1;
    if(this.back_attachmentFile2) this.attachmentFile2 = this.back_attachmentFile2;
    if(this.back_attachmentFile3) this.attachmentFile3 = this.back_attachmentFile3;
    if(this.back_attachmentFile4) this.attachmentFile4 = this.back_attachmentFile4;
    if(this.back_attachmentFile5) this.attachmentFile5 = this.back_attachmentFile5;
  }

  private extractAttachmentFileName(){
      
      let operation : EventModel = <EventModel>this.model;
      
      if(operation.attachmentOpFile1)
        operation.attachmentOpFile1 = this.view['attachmentOpFile1'];
      
      if(operation.attachmentOpFile2)
        operation.attachmentOpFile2 = this.view['attachmentOpFile2'];
      
      if(operation.attachmentOpFile3)
        operation.attachmentOpFile3 = this.view['attachmentOpFile3'];
      
      if(operation.attachmentOpFile4)
        operation.attachmentOpFile4 = this.view['attachmentOpFile4'];
      
      if(operation.attachmentOpFile5)
        operation.attachmentOpFile5 = this.view['attachmentOpFile5'];
    
  }

  saveEventStatusFinalized() {
    const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: "ATENÇÃO: após salvar evento como concluído, esta ação só poderá ser desfeita por um Administrador. Confirma a operação?",
        title: 'Salvar Evento',
        okLabel: 'Salvar'
      }
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result) {
        this.saveEvent();
      }
    },
    error => this.logger.error(error),
    () => openConfirmationSubscription?.unsubscribe());
  }

  saveEvent(){
    this.mapViewToModel(); 
    
    if(this.isCreating()){
      this.saveCreatedModel();

    }
    else {
      this.saveEditedModel();
    }    
  }

  onSaveClick(saveClickEvent?: any): void {
    this.logger.debug('EventEditComponent.onSaveClick()');
    saveClickEvent?.preventDefault();

    if (this.view['status'] == this.eventStatus.FINALIZED &&
        this.model['status'] != this.eventStatus.FINALIZED) {
      this.saveEventStatusFinalized();
    }
    else {
      this.saveEvent();
    }

  }
  
  saveEditedModel(){
    this.logger.debug('EventEditComponent.saveEditedModel');
    
    const formElements = this.getFormElements();
    
    this.eventService.edit(this.model, formElements).pipe(first()).subscribe( entity => {
      // Atualizando o ID dos componentes criados.
      // Com isso a próxima ação de salvar será reconhecida como uma edição
      this.id = entity.id;
      this.glUpdateCreateId(this.id);
      this.entitySaved(entity);
      
    },
    error => this.entitySaveError(error));

  }

  saveCreatedModel(){
    this.logger.debug('EventEditComponent.saveCreatedModel');

    const formElements = this.getFormElements();

    this.eventService.create(this.model, formElements).pipe(first()).subscribe( entity => {
      // Atualizando o ID dos componentes criados.
      // Com isso a próxima ação de salvar será reconhecida como uma edição
      this.id = entity.id;
      this.glUpdateCreateId(this.id);
      this.entitySaved(entity);
      
    },
    error => this.entitySaveError(error));
  }

  /**
   * Mapeia elementos que serão inseridos nas chamadas de criação/edição dos eventos
   */
   getFormElements() {
    const formElements = new Map<string, object>();
    
    if(this.attachmentFile1){
      formElements.set('attachmentOpFile1',  this.attachmentFile1);
    }

    if(this.attachmentFile2){
      formElements.set('attachmentOpFile2',  this.attachmentFile2);
    }

    if(this.attachmentFile3){
      formElements.set('attachmentOpFile3',  this.attachmentFile3);
    }

    if(this.attachmentFile4){
      formElements.set('attachmentOpFile4',  this.attachmentFile4);
    }

    if(this.attachmentFile5){
      formElements.set('attachmentOpFile5',  this.attachmentFile5);
    }

    return formElements;
  }

  updateAttachmentData() {
    if(this.model['attachmentOpFile1'])    
     this.getAttachmentFile(this.model['attachmentOpFile1'], AttachmentNumber.ONE );

    if(this.model['attachmentOpFile2'])
     this.getAttachmentFile(this.model['attachmentOpFile2'], AttachmentNumber.TWO );

    if(this.model['attachmentOpFile3'])
     this.getAttachmentFile(this.model['attachmentOpFile3'], AttachmentNumber.THREE);

    if(this.model['attachmentOpFile4'])
     this.getAttachmentFile(this.model['attachmentOpFile4'], AttachmentNumber.FOUR);

    if(this.model['attachmentOpFile5'])
     this.getAttachmentFile(this.model['attachmentOpFile5'], AttachmentNumber.FIVE);
   
  }
  
  getAttachmentFile(fileData: AttachmentModel, attachmentNumber: AttachmentNumber){
    this.eventService.loadAttachmentFile(fileData).pipe(first()).subscribe( (file) => {
        switch(attachmentNumber){
          case AttachmentNumber.ONE:
            this.attachmentFile1 = file;
            break;
          case AttachmentNumber.TWO:
            this.attachmentFile2 = file;
            break;
          case AttachmentNumber.THREE:
            this.attachmentFile3 = file;
            break;
          case AttachmentNumber.FOUR:
            this.attachmentFile4 = file;
            break; 
          case AttachmentNumber.FIVE: 
            this.attachmentFile5 = file;
            break;
        }        
      }, (error: any) => this.logger.error(error));
  }

  sourceSelectionChanged(source){
    this.showCNCLGroup = source.indexOf (this.stringCNCL) != -1 ? true : false;
    //Carrega a lista de Ações Principais na aba Resultados de acordo com a origem selecionada
    this.reloadEventMainActions();
  }
}
