import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserModel } from 'src/app/model/user.model';

@Injectable({ 
    providedIn: 'root' 
})
export class UserLoginService {
    private _user: BehaviorSubject<UserModel>;
    public readonly user$: Observable<UserModel>;

    constructor(private http: HttpClient) {
        this._user = new BehaviorSubject({} as UserModel);
        this.user$ = this._user.asObservable();
    }

    setUsuario(loggedUser: UserModel) {
        this._user.next(loggedUser);
    }

    getById(id: number) {
        return this.http.get(`${environment.settings.auth_address}/login-users/${id}`);
    }

    update(loggedUser: UserModel) {
        return this.http.put(`${environment.settings.auth_address}/login-users/${loggedUser.id}`, loggedUser);
    }

    delete(id: number) {
        return this.http.delete(`${environment.settings.auth_address}/login-users/${id}`);
    }
}
