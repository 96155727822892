<div mat-dialog-title>
    <span>Logar como ...</span>
    <button mat-icon-button mat-dialog-close matTooltip="Fechar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>

    <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
        <mat-label>login</mat-label>
        <input matInput name="userLogin" [(ngModel)]="login" required>
    </mat-form-field>    
</div>

<mat-dialog-actions align="end">    
    <button mat-raised-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button (click)="loginAs()">Ok</button>
  </mat-dialog-actions>


  
