<div mat-dialog-title>
  <span>{{getTitle()}}</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="p-3">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nota</mat-label>
      <textarea name="comment" matInput [(ngModel)]="validationNote" placeholder="Nota"
        rows="8"></textarea>
    </mat-form-field>  
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onSaveClick()" [disabled]="!validationNote">Salvar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
