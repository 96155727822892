import {EntityModel} from './entity.model';
import {UserModel} from './user.model';
import {VehicleModel} from './vehicle.model';
import { CompanyModel } from './company.model';
import { ServiceTypeDescription } from 'src/app/model/enums.enum';
import LanguageUtils from '../service/util/language-utils';

export class PatrolTeamModel extends EntityModel {
  name:              string;       // The name of the Patrol Team. (mandatory)
  vehicle:           VehicleModel; // (mandatory)
  shift:             string;
  users:             Array<UserModel>; // professional1, professional2,...  (mandatory)
  patrolCoordinator: UserModel;
  company:           CompanyModel; // (mandatory)
  serviceType:       string;

  static getPopupContent(patrolTeam: PatrolTeamModel, search: boolean = false): string {
    return patrolTeam ?
      `<div> <b>Equipe:</b> ${patrolTeam.name} </div>
      ${patrolTeam.users.length > 0 ? `<div style="margin-left: 1rem"> <b>Profissional 1:</b> ${!search? UserModel.getTitle(patrolTeam.users[0]): patrolTeam.users[0].name} </div> ` : ``}
      ${patrolTeam.users.length > 1 ? `<div style="margin-left: 1rem"> <b>Profissional 2:</b> ${!search? UserModel.getTitle(patrolTeam.users[1]): patrolTeam.users[1].name} </div> ` : ``}
      ${patrolTeam.users.length > 2 ? `<div style="margin-left: 1rem"> <b>Profissional 3:</b> ${!search? UserModel.getTitle(patrolTeam.users[2]): patrolTeam.users[2].name} </div> ` : ``}
      <div style="margin-left: 1rem"> <b>Placa do Veículo:</b>  ${patrolTeam.vehicle ? patrolTeam.vehicle.plate : ``} </div>
      <div style="margin-left: 1rem"> <b>Empresa:</b> ${patrolTeam.company ? patrolTeam.company.name : ``} </div>
      <div style="margin-left: 1rem"> <b>Telefone da Empresa: </b> ${(patrolTeam.company && patrolTeam.company.phone) ? patrolTeam.company.phone : ''}</div>
      <div style="margin-left: 1rem"> <b>Coordenador:</b>  ${patrolTeam.patrolCoordinator ? patrolTeam.patrolCoordinator.name : ``} </div>
      <div style="margin-left: 1rem"> <b>Tipo de Serviço:</b>  ${patrolTeam.serviceType ? ServiceTypeDescription[patrolTeam.serviceType] : ''} </div>
      `
      : 'sem equipe';
  }
 
  static addLanguageMap(){
    LanguageUtils.languageMap['vehicle'] = 'Veículo';
    LanguageUtils.languageMap['shift'] = 'Turno';
    LanguageUtils.languageMap['serviceType'] = 'Tipo de Serviço';
    LanguageUtils.languageMap['users'] = 'Profissionais';
    LanguageUtils.languageMap['patrolCoordinator'] = 'Coordenador';
  }
}
