import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import { HttpClient, HttpParams } from '@angular/common/http';
import {PatrolModel} from '../../model/patrol.model';
import {environment} from '../../../environments/environment';
import {EntityModel} from '../../model/entity.model';
import { forkJoin, Observable } from 'rxjs';
import { OperationType } from 'src/app/model/enums.enum';
import { map } from 'rxjs/operators';
import { OperationService } from './operation.service';
import { PatrolFilterModel } from 'src/app/pages/patrol/patrol-filter-dialog/patrol.filter.model';

import * as moment from 'moment';
import { ArchivedType } from 'src/app/general/filter-component/filter.model';
import { OperationModel } from 'src/app/model/operation.model';
import { PatrolRepetitionModel } from 'src/app/model/patrol.repetition.model';
import { PatrolRepetitionFilterModel } from '../../pages/patrol/patrol-filter-dialog/patrol-repetition.filter.model';
import { DEFAULT_PAGE_INDEX, DEFAULT_SORT } from '../../common/constants';

export const OBJECT_NAME = 'patrolObject';

@Injectable({
  providedIn: 'root'
})
export class PatrolService extends OperationService {

  constructor(logger:           NGXLogger,
              httpClient:       HttpClient) {
    super(logger, httpClient, `${environment.settings.patrols_address}/patrols`, OBJECT_NAME);
  }

  public loadById(id:string): Observable<OperationModel>{
    return super.loadById(id).pipe(map( operation => {
      if (operation) operation.type = OperationType.PATROL;
      return operation;
    }));
  }

  public loadListFromRestApi(pageIndex?: number, pageSize?: number, sort?: string): Observable<EntityModel[]> {
    return super.loadListFromRestApi(pageIndex, pageSize, sort).pipe(map(operations => {
      return this.fillOperationType(operations, OperationType.PATROL);
    }));
  }

  public clone(record: PatrolModel) {
    const formData: FormData = super.buildFormData(OBJECT_NAME, record, new Map<string, Object>());
    return this.postAsFormData(this.apiUrl + '/clone', formData);
  }

  public createPatrolRepetition<T extends EntityModel>(patrolRepetition: PatrolRepetitionModel): Observable<T> {
    return this.http.post<T>(this.apiUrl + '/repetition', patrolRepetition, {responseType: 'json'});
  }

  public getPatrolRepetitionByReferencePatrolId(referencePatrolId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/repetition/${referencePatrolId}`, { responseType: 'json' });
  }

  public getReferencePatrolById(referencePatrolId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/reference/${referencePatrolId}`, { responseType: 'json' });
  }

  public updatePatrolRepetition<T extends EntityModel>(patrolRepetition: PatrolRepetitionModel): Observable<T> {
    return this.http.put<T>(this.apiUrl + `/repetition/${patrolRepetition.referencePatrolId}`, patrolRepetition, {responseType: 'json'});
  }

  public deletePatrolRepetition(patrolRepetition: PatrolRepetitionModel): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/repetition/${patrolRepetition.referencePatrolId}`);
  }

  public loadPatrolRepetitionFromRestApi<PatrolRepetitionModel extends EntityModel[]>(filter?: PatrolRepetitionFilterModel, pageIndex?: number, pageSize?: number, sort?: string): Observable<PatrolRepetitionModel> {
    let params = new HttpParams();
    if (pageIndex != null && pageSize != null) {
      const pageSizeStr = pageSize ? pageSize.toString() : environment.defaultPageSize.toString();
      const pageIndexStr = pageIndex ? pageIndex.toString() : DEFAULT_PAGE_INDEX.toString();
      params = params.set('page', pageIndexStr).set('size', pageSizeStr);
    }
    params = params.set('sort', DEFAULT_SORT);
    

    if (filter.referencePatrolIds){
      filter.referencePatrolIds.forEach( referencePatrolId => {
        params = params.append('referencePatrolIds', referencePatrolId);
      });
    }
    return this.http.get<PatrolRepetitionModel>(this.apiUrl + '/repetition',{ params : params });
  }

  postAsFormData<T extends EntityModel>(url: string, formData: FormData) : Observable<T> {
    return this.http.post<T>(url, formData, {responseType: 'json'});
  }

  putAsFormData<T extends EntityModel>(url: string, formData: FormData) : Observable<T> {
    return this.http.put<T>(url, formData, {responseType: 'json'});
  }

  public exportPatrol(patrol: PatrolModel): Observable<any> {
    return this.http.get(`${this.apiUrl}/${patrol.id}/data`, { responseType: 'blob' });
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: PatrolFilterModel): Observable<T> {

    let params: HttpParams = new HttpParams();

    if (filter){
      if (filter.startDate){
        const startDateTime = moment(filter.startDate).format('x');
        params = params.set('start', startDateTime);
      }

      if (filter.endDate){
        const endDateTime =  moment(filter.endDate).format('x');
        params = params.set('end', endDateTime);
      }

      if (filter.status){
        filter.status.forEach( status => {
          params = params.append('status', status);
        });
      }

      if (filter.placements){
        filter.placements.forEach( placement => {
          params = params.append('placements', placement);
        });
      }

      if (filter.patrolTeams){
        filter.patrolTeams.forEach( patrolTeam => {
          if (patrolTeam) { // Ignorar os nulos
            params = params.append('patrolTeams', patrolTeam);
          }
        });
      }

      if (filter.archived !== ArchivedType.ALL){
        params = params.append('archived', (filter.archived === ArchivedType.ARCHIVED) ? String(true) : String(false));
      }

      if(filter.current == false) {
        if(!filter.endDate) {
          let endDatePlusWeek =  moment().add(1,'weeks').format('x');
          params = params.set('end', endDatePlusWeek);
        }
      }
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  public updatePatrolRepetitions(patrolRepetitions: PatrolRepetitionModel[]){ 
    this.logger.debug('PatrolService.updatePatrolRepetitions()');
    const observables: Observable<Object>[] = [];
    patrolRepetitions.forEach( (patrolRepetition: PatrolRepetitionModel) => {      
      observables.push(this.updatePatrolRepetition(patrolRepetition));
    });
    return forkJoin(observables);
  }
}
