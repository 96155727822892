import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, forkJoin } from 'rxjs';
import { EntityModel } from 'src/app/model/entity.model';
import { VerificationModel } from 'src/app/model/verification.model';
import { VerificationFilterModel } from 'src/app/pages/verification/verification-filter-dialog/verification.filter.model';
import { environment } from 'src/environments/environment';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { OperationType } from 'src/app/model/enums.enum';
import { OperationService } from './operation.service';
import { ArchivedType } from 'src/app/general/filter-component/filter.model';
import { OperationModel } from 'src/app/model/operation.model';
import { DEFAULT_PAGE_INDEX } from 'src/app/common/constants';

export const OBJECT_NAME = 'verificationObject';

@Injectable({
  providedIn: 'root'
})
export class VerificationService extends OperationService {

  constructor(logger: NGXLogger,
              httpClient: HttpClient) {
    super(logger, httpClient, `${environment.settings.event_verifications_address}/verifications`, OBJECT_NAME);
  }

  public loadListFromRestApi(pageIndex?: number, pageSize?: number, sort?: string): Observable<EntityModel[]> {
    return super.loadListFromRestApi(pageIndex, pageSize, sort).pipe(map(operations => {
      return this.fillOperationType(operations, OperationType.EVENT_VERIFICATION);
    }));
  }

  public loadById(id:string): Observable<OperationModel>{
    return super.loadById(id).pipe(map( operation => {
      if (operation) operation.type = OperationType.EVENT_VERIFICATION;
      return operation;
    }));
  }

  public updateStatus(verification: VerificationModel, status: string){
    const params = new HttpParams().set('status', status);
    return this.http.put(this.apiUrl + `/${verification.id}/status`, null, { params: params });
  }

  public exportVerification(verification: VerificationModel): Observable<any> {
    return this.http.get(this.apiUrl + `/${verification.id}/data`, { responseType: 'blob' });
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: VerificationFilterModel): Observable<T> {

    let params: HttpParams = new HttpParams();

    if(filter){
      if (filter.startDate){
        const startDateTime = moment(filter.startDate).format('x');
        params = params.set('start', startDateTime);
      }

      if (filter.endDate){
        const endDateTime = moment(filter.endDate).format('x');
        params = params.set('end', endDateTime);
      }

      if (filter.status){
        filter.status.forEach( status => {
          params = params.append('status', status);
        });
      }

      if (filter.eventIds){
        filter.eventIds.forEach( eventId => {
          params = params.append('eventIds', eventId);
        });
      }

      if (filter.analysts){
        filter.analysts.forEach( analystId => {
          params = params.append('analysts', analystId);
        });
      }

      if (filter.placements){
        filter.placements.forEach( placement => {
          params = params.append('placements', placement);
        });
      }

      if (filter.patrolTeams){
        filter.patrolTeams.forEach( patrolTeam => {
          if (patrolTeam) { // Ignorar os nulos
            params = params.append('patrolTeams', patrolTeam);
          }
        });
      }

      if (filter.ducts){
        filter.ducts.forEach( duct => {
          params = params.append('ducts', duct);
        });
      }

      if (filter.valves){
        filter.valves.forEach( valve => {
          params = params.append('valves', valve);
        });
      }

      if (filter.archived != ArchivedType.ALL){
        params = params.append('archived', (filter.archived == ArchivedType.ARCHIVED) ? String(true) : String(false));
      }      
    }
    
    if (pageIndex != null && pageSize != null) {
      const pageSizeStr = pageSize ? pageSize.toString() : environment.defaultPageSize.toString();
      const pageIndexStr = pageIndex ? pageIndex.toString() : DEFAULT_PAGE_INDEX.toString();
      params = params.set('page', pageIndexStr).set('size', pageSizeStr);
      }

    if(sort){
      params = params.set('sort', sort);
    }

    let options : Object = undefined;
    options = {
      params: params,
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }) };

    return this.http.get<T>(this.apiUrl, options);    
  }

  // recebe lista de Id de operaçoes, apenas maiores que 100
  public loadListVerifications(operationsIds : string[]){
    this.logger.debug('VerificationService.loadListVerifications()');    
    const observables: Observable<Object>[] = [];
    const numberOfCalls = operationsIds.length % 100 > 0 ? Math.trunc(operationsIds.length/100) + 1 : operationsIds.length/100 ;
    for(let i = 0; i < numberOfCalls ; i++){
      const filter: VerificationFilterModel = new VerificationFilterModel();
      const slicedOperationIds = operationsIds.slice((i > 0 ? i*100 + 1 : 0), (i+1)*100);
      filter.eventIds = slicedOperationIds;
      filter.archived = ArchivedType.ALL;      
      observables.push(this.loadFilteredListFromRestApi(null, null, null, filter));
    }
    return forkJoin(observables);
  }
}
