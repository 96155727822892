import { Directive, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { UserModel } from "src/app/model/user.model";
import { EntityService } from "src/app/service/model/entity.service";
import { UserService } from "src/app/service/model/user.service";

import * as moment from 'moment';
import { LayerService } from "src/app/service/model/layer.service";
import DateUtils from "src/app/service/util/date-utils";
import { ArchivedType, FilterModel } from "./filter.model";
import { KeyValue } from "@angular/common";
import { EntityModel } from 'src/app/model/entity.model';
import { Permission, UserType } from "src/app/model/enums.enum";
import { first, map } from "rxjs/operators";
import { LoadingListService } from "src/app/service/loading/loading-list.service";
import { ProfileModel } from "src/app/model/profile.model";
import { StorageService } from "src/app/service/storage-service";
import { SingleDataCacheService } from "src/app/service/model/single.data.cache.service";
import { Observable, Subscription } from "rxjs";
import { EntityCacheService } from "src/app/service/model/entity.cache.service";

@Directive()
export abstract class FilterComponent implements OnInit, OnDestroy {

  @Output() filtered = new EventEmitter<EntityModel[]>();
  protected inMemoryFilterModel: FilterModel;

  analysts: UserModel[];

  viewFilterStartDate : string;
  viewFilterEndDate :   string;
  viewFilterStartTime : string;
  viewFilterEndTime :   string;

  startDate: moment.Moment;
  endDate: moment.Moment;

  archivedFilterType = ArchivedType;

  loggedUser: UserModel;
  loggedUserProfile : ProfileModel;
  permission = Permission;
  
  loadingListService = new LoadingListService();

  private reloadAnalystUsersSubscription: Subscription;

  constructor(protected logger:             NGXLogger,
              protected userService:        UserService,
              protected layerService:       LayerService,
              public singleDataCacheService:  SingleDataCacheService,
              public entityCacheService:    EntityCacheService,
              protected entityService:      EntityService,
              protected filterModel:        FilterModel,
              protected storageService:     StorageService
              ) {  }

  ngOnInit(): void {
    this.loggedUser = this.storageService.getLocalUser();
    this.loggedUserProfile = this.storageService.getLocalProfile();

    this.loadFormOptionsData();

    this.updateFilterModel(this.inMemoryFilterModel, this.filterModel);
    
    this.handleFilterDates();
  }

  ngOnDestroy(){
    this.loadingListService.destroy();
    this.reloadAnalystUsersSubscription?.unsubscribe();
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  startDateChanged() {
    this.viewFilterStartDate = this.startDate.format("DD/MM/yyyy");
  }

  applyStartDate(dateString: string) {
    if(!dateString) return;

    this.startDate = moment(dateString, "DD/MM/yyyy");
  }

  endDateChanged() {
    this.viewFilterEndDate = this.endDate.format("DD/MM/yyyy");
  }

  applyEndDate(dateString: string) {
    if(!dateString) return;

    this.endDate = moment(dateString, "DD/MM/yyyy");
  }

  protected loadFormOptionsData() {
  }

  loadAnalysts(){
    let _this = this; // Necessário por causa do contexto das callacks
    const onUsersLoad = function() {
      const analystUserTypes = [UserType.ANALYSIS_CCPD, UserType.ADMINISTRATOR, UserType.COORDINATOR_CCPD];
      _this.analysts = _this.entityCacheService.getFilteredUsers(analystUserTypes);
    };
    this.entityCacheService.loadUsers(this.loadingListService, onUsersLoad);
    this.reloadAnalystUsersSubscription = this.entityCacheService.onUsersReload().subscribe(onUsersLoad);
  }

  /**
   * Filtra a lista de acordo com os filtros selecionados
   */
  loadFilteredDataModel(): Observable<EntityModel[]> {
    if (this.filterModel.current) {
      this.filterModel.startDate = DateUtils.getTodayStartDate();
      this.filterModel.endDate = DateUtils.getTodayEndDate();
      this.handleFilterDates();
    }
    else {
      this.filterModel.startDate = DateUtils.stringDateTimeToTimestamp(this.viewFilterStartDate, this.viewFilterStartTime, true);
      this.filterModel.endDate = DateUtils.stringDateTimeToTimestamp(this.viewFilterEndDate, this.viewFilterEndTime, false);
    }

    this.updateFilterModel(this.filterModel, this.inMemoryFilterModel);

    return this.entityService.loadFilteredListFromRestApi(null, null, null, this.inMemoryFilterModel);
  }

  onFilter(){
    this.loadingListService.loadingOn();
    this.loadFilteredDataModel().pipe(first()).subscribe( (entity : EntityModel[]) => {
      this.filtered.emit(entity);
    }, error => this.logger.error(error),
    () => {
      this.loadingListService.loadingOff();
    });
  }

  /**
   * Atualiza o filtro em memória
   */
  updateFilterModel(sourceFilter: FilterModel, targetFilter: FilterModel){
    for(const key in sourceFilter){
      targetFilter[key] = sourceFilter[key];
    }
  }

  /**
   * Limpa todos os campos do filtro
   */
  clearFilter(){
    // Não reseta o current para evitar que o diálogo mude de tamanho ao Limpar o filtro
    //this.filterModel.current = false;
    this.filterModel.archived = ArchivedType.NON_ARCHIVED;

    this.filterModel.startDate = undefined;
    this.filterModel.endDate = undefined;
    this.handleFilterDates();

    this.filterModel.analysts = [];
    this.filterModel.status = [];
    this.filterModel.ducts = [];
    this.filterModel.valves = [];
    this.filterModel.placements = this.storageService.getSpecificPlacementIds();
   }

  handleFilterDates(){
    if (this.filterModel.startDate) {
      this.startDate = moment(this.filterModel.startDate);
      this.viewFilterStartDate = this.startDate.format('DD/MM/yyyy');
      this.viewFilterStartTime = this.startDate.format('HH:mm');
    }else{
      this.viewFilterStartDate = '';
      this.viewFilterStartTime = '';
    }

    if (this.filterModel.endDate) {
      this.endDate = moment(this.filterModel.endDate);
      this.viewFilterEndDate = this.endDate.format('DD/MM/yyyy');
      this.viewFilterEndTime = this.endDate.format('HH:mm');
    }else{
      this.viewFilterEndDate = '';
      this.viewFilterEndTime = '';
    }
  }

  // Preserve original property order
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
}
