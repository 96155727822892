import { Injectable } from '@angular/core';
import { UserType, OperationStatus, Permission, PermisionRuleOption } from '../../model/enums.enum';
import { PatrolModel } from '../../model/patrol.model';
import { VerificationModel } from '../../model/verification.model';
import { ProfileModel } from 'src/app/model/profile.model';
import { PermissionOptions } from '../../model/field.model';


/**
 * Serviço responsável por controlar autorizações no sistema - quais as funcionalidades permitidas em função
 * do perfil do usuário.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private profileUser: ProfileModel; // Inicializado pelo AppComponent durante o login
  private permissionsMap:  Map<string, PermissionOptions>;
  
  setLoggedProfileUser(profile: ProfileModel) {
    this.profileUser = profile;
    if (profile) {
      this.permissionsMap = new Map(Object.entries(profile.permissions));
    }
    else {
      this.permissionsMap = null;
    }
  }

  getLoggedProfileName(): string {
    if(this.profileUser){      
      return this.profileUser.name;
    }
    else {
      return null;
    }
  }
   
  public getLoggedUserType() {
    if(this.profileUser){      
      return this.profileUser.userType;
    }
    else {
      return null;
    }
  }

  /**Retorna se false se o usuario tem permissão, em caso ser administrador não avalia a tabela de permissões e retorna false */
  userHasPermission(permission: Permission): boolean{
    if(this.profileUser){      
      return (this.permissionsMap.has(permission));
    }else return false;     
  }

  getUserPermissionOptions(permission: Permission): PermissionOptions {
    if(this.profileUser){      
      return this.permissionsMap.get(permission);
    }
    return null;
  }
  
  isFieldEnabledForUser(permission:Permission, field: string) : boolean {
    if(this.isAdmin()) return true;      
    const permissionOptions = this.getUserPermissionOptions(permission);
    if(permissionOptions && permissionOptions.fields){
      return permissionOptions.fields.includes(field);
    }
    return false;
  }
  
  canEditUser(profileId: string){    
    if(this.isAdmin()) return true;
    if(this.userHasPermission(Permission.CREATE_EDIT_USER)) {
      const permissionOptions = this.getUserPermissionOptions(Permission.CREATE_EDIT_USER);
      if (permissionOptions.option === PermisionRuleOption.ALL_PROFILE) return true;
      else {// PermisionRuleOption.SOME_PROFILE
        return permissionOptions.fields.includes(profileId);
      }
    }
    else return false;
  }

  canDeleteUser(profileId: string){
    if(this.isAdmin()) return true;
    if(this.userHasPermission(Permission.DELETE_USER)){
      const permissionOptions = this.getUserPermissionOptions(Permission.DELETE_USER);
      if (permissionOptions.option === PermisionRuleOption.ALL_PROFILE) return true;
      else {// PermisionRuleOption.SOME_PROFILE
        return permissionOptions.fields.includes(profileId);
      }
    }
    else return false;
  }
  
  /**
   * Verifica se o usuário é admin
   */
   public isAdmin(): boolean {
    const loggedUserType = this.getLoggedUserType();
    return loggedUserType === UserType.ADMINISTRATOR;
  }

  /** Verifica que se o usuário tem acesso ao pelo menos uma opção do grupo para apresentar a opção de administração */
  isAdministrationGroupHidden(){
    if(this.userHasPermission(Permission.LIST_PATROL_TEAM_ADMINISTRATION) || 
       this.userHasPermission(Permission.LIST_USERS_ADMINISTRATION) || 
       this.userHasPermission(Permission.LIST_VEHICLE_ADMINISTRATION) || 
       this.userHasPermission(Permission.LIST_COMPANY_ADMINISTRATION) ||
       this.userHasPermission(Permission.LIST_PLACEMENT_ADMINISTRATION))
      return false; 
    
    return true;
  }
   
  /** Verifica que se o usuário tem acesso ao pelo menos uma opção do grupo para apresentar a opção de cadastro */
  isRegistrationGroupHidden(){
    if(this.userHasPermission(Permission.LIST_REGISTRATION_GROUP))
      return false; 
    
    return true;
  }

  /**
   * Verifica se o usuário logado está autorizado ou não a remover esta verificação
   */
  public isVerificationDeletionAllowed( verification: VerificationModel): boolean {
    if (!verification || !verification.status) {
      return false;
    }    

    if(this.isAdmin()) return true;

    let permissionOptions = this.getUserPermissionOptions(Permission.DELETE_VERIFICATIONS)
    if(permissionOptions)
    {
      switch(permissionOptions.option){
        case PermisionRuleOption.ONLY_NOT_STARTED:  return (verification.status === OperationStatus.PLANNED);
        case  PermisionRuleOption.ALL_VERIFICATIONS:  return true;
        default:  return false 
      }
              
    } else return false
  }

  /**
   * Verifica se o usuário logado está autorizado ou não a remover essa ronda.
   */
  public isPatrolDeletionAllowed(patrol: PatrolModel): boolean {
    if (!patrol || !patrol.status) {
      return false;
    }

    if(this.isAdmin()) return true;

    let permissionOptions = this.getUserPermissionOptions(Permission.DELETE_PATROL)
    if(permissionOptions)
    {
      switch(permissionOptions.option)
      {
        case PermisionRuleOption.ONLY_NOT_STARTED : return (patrol.status == OperationStatus.PLANNED)
        case PermisionRuleOption.ALL_PATROLS : return true;
        default: return false;
        
     }     
    } else return false;
  } 

  isCreatorEditAllowedForUser(){
    if (this.isAdmin())
      return true;
    else
      return false;
  }

  isApproveEditAllowedForUser(){
    if (this.isAdmin())
      return true;
    else
      return false;
  }

  isProfileCreateEditAllowedForUser(){
    if (this.isAdmin())
      return true;
    else
      return false;
  }

  /**
   * Verifica se o usuário logado está autorizado ou não a criar operações.
   */
  public isOperationCreatingAllowed(): boolean {
    return this.isOperationEditingAllowed();
  }

  public isOperationEditingAllowed(): boolean {
    const loggedUserType = this.getLoggedUserType();
    switch (loggedUserType) {
      case UserType.COORDINATOR_CCPD: return true;
      case UserType.COORDINATOR_OPPD: return true
      case UserType.PLANNER: return true;
      case UserType.ANALYSIS_CCPD: return true;
      case UserType.ADMINISTRATOR: return true;
      default: return false;
    }
  }

  public isOperationArchiveAllowed(): boolean {
    const loggedUserType = this.getLoggedUserType();
    switch (loggedUserType) {
      case UserType.ANALYSIS_CCPD: return true;
      case UserType.ADMINISTRATOR: return true;
      default: return false;
    }
  }

  public isPointStatusEditingAllowed(): boolean {
    const loggedUserType = this.getLoggedUserType();
    switch (loggedUserType) {
      case UserType.COORDINATOR_CCPD: return true;
      case UserType.COORDINATOR_OPPD: return true
      case UserType.ADMINISTRATOR: return true;
      default: return false;
    }
  }

  /**
   * Verifica se o usuário tem o perfil ANALYSIS_CCPD
   */
  public isControl(): boolean {
    const loggedUserType = this.getLoggedUserType();
    return loggedUserType === UserType.ANALYSIS_CCPD;
  }

  public canEditEventBySource(source: string): boolean {
    if (this.isAdmin())
      return true;

    if(!this.userHasPermission(Permission.CHANGE_EVENTS_FROM_ANY_SOURCE)){
      return false; // Se a permissão não está definida, não pode alterar
    }else{
      // Se está definida
      if(this.getUserPermissionOptions(Permission.CHANGE_EVENTS_FROM_ANY_SOURCE).option == PermisionRuleOption.SOME_SOURCE){
        // Se o valor é somente algumas origens, tem verificar a origem
        const sources = this.getUserPermissionOptions(Permission.CHANGE_EVENTS_FROM_ANY_SOURCE).fields;
        return sources.includes(source);
      }
      else {
        // Se o valor é qualquer origem
        return true;
      }
    }
  }

  public getEditEventSources(): string[] {
    if(!this.userHasPermission(Permission.CHANGE_EVENTS_FROM_ANY_SOURCE)){
      return []; // Se a permissão não está definida retorna vazio
    }else{      
      // Se está definida
      if(this.getUserPermissionOptions(Permission.CHANGE_EVENTS_FROM_ANY_SOURCE)?.option === PermisionRuleOption.SOME_SOURCE){
        // Se é somente algumas, retorna lista de origens definidas
        const sources = this.getUserPermissionOptions(Permission.CHANGE_EVENTS_FROM_ANY_SOURCE).fields;
        return sources;
      }
      else {
        return null; // Todas
      }
    }
  }

  /**
   * Verifica se o usuário logado está autorizado ou não a enviar senha por e-mail a usuários.
   */
  public isSendingPasswordToUserAllowed(): boolean {
    const loggedUserType = this.getLoggedUserType();
    switch (loggedUserType) {
      case UserType.COORDINATOR_CCPD: return true;
      case UserType.COORDINATOR_OPPD: return true;
      case UserType.PLANNER: return true;
      case UserType.ANALYSIS_CCPD: return true;
      case UserType.ADMINISTRATOR: return true;
      case UserType.CENTRAL_OPERATOR: return true;
      default: return false;
    }
  }
}
