import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { OperationStatusDescription } from 'src/app/model/enums.enum';
import { UserService } from 'src/app/service/model/user.service';
import { VerificationService } from 'src/app/service/model/verification.service';
import { VerificationFilterModel } from './verification.filter.model';
import { LayerService } from 'src/app/service/model/layer.service';
import { PatrolTeamService } from 'src/app/service/model/patrol.team.service';
import { VerificationModel } from 'src/app/model/verification.model';
import { StorageService } from '../../../service/storage-service';
import { OperationFilterComponent } from 'src/app/general/filter-component/operation-filter-component';
import { SingleDataCacheService } from '../../../service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verification-filter-dialog',
  templateUrl: './verification-filter-dialog.component.html',
  styleUrls: ['./verification-filter-dialog.component.scss']
})
export class VerificationFilterDialogComponent extends OperationFilterComponent {

  /** ENUMS */
  operationStatusDescription =  OperationStatusDescription;
  
  constructor(patrolTeamService:                                    PatrolTeamService,
              logger:                                               NGXLogger,
              userService:                                          UserService,
              layerService:                                         LayerService,
              verificationService:                                  VerificationService,
              singleDataCacheService:                               SingleDataCacheService,
              entityCacheService:                                   EntityCacheService,
              protected storageService:                             StorageService,
              protected toastr:                                     ToastrService,
              public dialogRef:                                     MatDialogRef<VerificationFilterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogFilterModel:    VerificationFilterModel,
              public filterModel:                                   VerificationFilterModel) {
    super(logger, userService, layerService, patrolTeamService, singleDataCacheService, entityCacheService, verificationService, filterModel, storageService);
  }

  ngOnInit(): void{
    this.loggedUser = this.storageService.getLocalUser();
    this.loggedUserProfile = this.storageService.getLocalProfile();

    this.filterModel = new VerificationFilterModel();
    this.inMemoryFilterModel = this.dialogFilterModel;

    super.ngOnInit(); // A cópia do filtro será feita aqui
  }

  loadFormOptionsData() {
    this.loadAnalysts();
    this.loadPatrolTeams();
    this.loadPlacements();
  }

  loadPlacements(){
    this.entityCacheService.loadPlacements(this.loadingListService);
  }

  onFilter(){
    // Aqui é o único lugar que as lotações podem ser manipuladas na interface do filtro
    // Então se nenhuma lotação for selecionada precisamos voltar para o default
    if (this.filterModel.placements.length == 0) {
      this.filterModel.placements = this.storageService.getSpecificPlacementIds();
    }

    this.loadingListService.loadingOn();
    this.loadFilteredDataModel().pipe(first()).subscribe( (verifications : VerificationModel[]) => {
      this.dialogRef.close(verifications);
    }, error => {
      if(error.status == 413){
        this.toastr.warning("Sua consulta retornou mais de 2000 registros, por favor refina sua busca.");
        this.dialogRef.close([]);
      }else{
        this.logger.error(error);
      }
    },
    () => {
      this.loadingListService.loadingOff();
    });
  }

  /**
   * Limpa todos os campos do filtro
   */
   clearFilter(){
    super.clearFilter();

    this.filterModel.placements = this.storageService.getSpecificPlacementIds();
    this.filterModel.patrolTeams = [];
    this.filterModel.eventIds = [];
  }
}
