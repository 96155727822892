import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EditComponent } from '../../../edit-component';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UserModel } from 'src/app/model/user.model';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from '../../../../service/authorization/authorization.service';
import { StorageService } from '../../../../service/storage-service';
import { PermisionRuleOption, Permission, RegistrationType, UserFields, UserType } from 'src/app/model/enums.enum';
import { PlacementModel } from 'src/app/model/placement.model';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { UserService } from 'src/app/service/model/user.service';
import { first } from 'rxjs/operators';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { UserTypeDescription } from '../../../../model/enums.enum';
import { ConfirmationDialogComponent } from '../../../../general/confirmation-dialog/confirmation-dialog.component';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { MAP_PAGE } from 'src/app/common/constants';
import { EntityModel } from 'src/app/model/entity.model';
import { MarkersService } from 'src/app/service/model/markers.service';
import { Subscription } from 'rxjs';
import { MultiselectAutocomplete } from 'src/app/general/multiselect-autocomplete/multiselect-autocomplete.component';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './user-edit.component.scss']
})
export class UserEditComponent extends EditComponent implements OnInit, OnDestroy {

  hide: boolean;
  passwordHide: boolean = true;
  userTypeKey = UserType;
  disableOtherPlacements : boolean = false;
  disableAppAccess : boolean = false;
  /**User Profile */  
  permission = Permission;

  patrolTeamName: string = 'sem equipe';

  userFields=  UserFields;
  userTypeDescription = UserTypeDescription;
  
  private reloadProfilesSubscription: Subscription;
  
  @ViewChild('listPlacements') listPlacements: MultiselectAutocomplete;

  constructor(logger:                       NGXLogger,
              public entityCacheService:    EntityCacheService,
              dialog:                       MatDialog,
              toastr:                       ToastrService,
              public userService:           UserService,
              private markerService:        MarkersService,
              authorizationService:         AuthorizationService,
              protected storageService:     StorageService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, userService, dialog, environment.USERS_MODEL_LABEL, environment.USERS_TITLE_LABEL, storageService,
           'users-edit', environment.USERS_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
    this.logger.debug('UserEditComponent.constructor()');
  }

  ngOnInit() {
    this.logger.debug('UserEditComponent.ngOnInit()');
    super.ngOnInit();
    this.hide = true;
    this.subscribeToProfileReload();
  }
  
  ngOnDestroy() {
    this.logger.debug('UserEditComponent.ngOnDestroy()');
    this.reloadProfilesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  createData(options?) {
    this.logger.debug('UserEditComponent.createData()');

    this.model = new UserModel();
    this.view = {};
  }

  loadFormOptionsData() {
    this.logger.debug('UserEditComponent.loadFormOptionsData()');
    this.entityCacheService.loadPlacements(this.loadingListService);
    this.entityCacheService.loadCompanies(this.loadingListService);
    this.entityCacheService.loadProfiles(this.loadingListService);
  }

  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário
    this.replaceModelViewData(data, 'patrolTeamId');

    if (this.entityCacheService.isAdminProfile(data['profileId'])) {
      // Restaura a lista de lotações depois de carregar um admin
      data['placements'] = this.copyModel(this.entityCacheService.getPlacements());
    }

    let profile = this.entityCacheService.getProfileById(data['profileId']);
    if (profile) data['profileName'] = profile.name;

    if (!this.hasAppAccessPermission(data['profileId'])) {
      this.replaceModelViewData(data, 'appAccess'); // Nesse caso é readonly
    }
}
  
  mapViewToModel() {
    // Caso o usuário tenha preenchido uma senha mas clicou em 'Cancelar' a redefinição, deve-se limpar a mudança
    if (this.passwordHide) {
      this.view['password'] = null;
    }
    else {
      this.passwordHide = true; // Esconde automaticamente depois de salvar
    }

    if (this.isProfessionalProfile()) {
      this.view['appAccess'] = true;
    }

    super.mapViewToModel();

    if (this.entityCacheService.isAdminProfile(this.model['profileId'])) {
      // Limpa a lista de lotações antes de salvar um admin
      this.model['placements'] = null;
    }

    this.logger.debug('UserEditComponent.mapViewToModel()');
  }

  entitySaved(entity: EntityModel): void {
    if (this.entityCacheService.isAdminProfile(entity['profileId'])) {
      // Restaura a lista de lotações depois de salvar um admin
      entity['placements'] = this.copyModel(this.entityCacheService.getPlacements());
    }
    super.entitySaved(entity);
  }

  private hasAccessToAllPlacements(profileId: string): boolean{
    if (!profileId) return false;
    const profile = this.entityCacheService.getProfileById(profileId);
    if (!profile) return false;
    if (profile.userType == UserType.ADMINISTRATOR) return true;
    if (profile.permissions[Permission.ACCESS_TO_ALL_PLACEMENT])return true;
    return false;
  }

  private hasAppAccessPermission(profileId: string): boolean{
    if (!profileId) return false;
    const profile = this.entityCacheService.getProfileById(profileId);
    if (!profile) return false;
    if (profile.userType == UserType.ADMINISTRATOR) return true;
    if (profile.userType == UserType.PROFESSIONAL) return true;
    if (profile.permissions[Permission.ACCES_APP]) return true;
    return false;
  }

  private updateAppAccess(){
    if(!this.hasAppAccessPermission(this.view['profileId'])){
      this.disableAppAccess = true;
      this.view['appAccess'] = false;
    }
    else {
      this.disableAppAccess = false;
    }
  }

  mapModelToView() {
    super.mapModelToView();

    this.view['password'] = null;

    if(this.hasAccessToAllPlacements(this.view['profileId'])){
      this.disableOtherPlacements = true;
      this.view['placements'] = this.copyModel(this.entityCacheService.getPlacements()); // Seleciona todos as lotações
    }

    this.updateAppAccess();

    if (this.isProfessionalProfile()) {
      this.view['appAccess'] = true;
    }

    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }

    if (this.view['patrolTeamId']) {
      this.entityCacheService.loadPatrolTeams(this.loadingListService, () =>{
        let patrolTeam: PatrolTeamModel = this.entityCacheService.getPatrolTeamById(this.view['patrolTeamId']);
        if(!patrolTeam) {
          this.patrolTeamName = 'equipe não encontrada';
          this.view['patrolTeamId'] = null;
        }
        else {
          this.patrolTeamName = patrolTeam.name;

          let found = patrolTeam.users.find(el => el.id === this.id);
          if (!found) {
            this.patrolTeamName += "(inconsistente, usuário não pertence à equipe)";
          }
        }
      });
    }
    else {
      this.patrolTeamName = 'sem equipe';
    }

    if (!this.view['originPlacement'] && this.view['placements'] && this.view['placements'].length == 1) {
      this.view['originPlacement'] = this.view['placements'][0];
    }
  }

  placementsTooltip(){
    if (!this.view['placements']) return;
    return this.view['placements'].length > 0 ? this.view['placements'].map(p => p.name): "";
  }

  getAppAccess(): boolean {
    if (this.isProfessionalProfile()) {
      return true;
    }
    return this.view['appAccess'];
  }

  isProfessionalProfile(){
    if(this.view['profileId']){
      let selectedProfile = this.entityCacheService.getProfileById(this.view['profileId']);
      if(selectedProfile && selectedProfile.userType === UserType.PROFESSIONAL) return true;
      else return false;
    }
  }

  isSendPasswordButtonDisabledForUser(): boolean {
    const email = this.view['email'];
    return !this.getAppAccess() || !email || email === '';
  }

  isSendPasswordButtonHiddenForUser(): boolean {
    return !this.authorizationService.isSendingPasswordToUserAllowed();
  }

  isRequiredFieldFilled(): boolean {
    if (!this.view['name'] ||
        (!this.hasAccessToAllPlacements(this.view['profileId']) && (!this.view['placements'] || (this.view['placements'] as PlacementModel[]).length == 0)) || // Se não tem acesso a todas, tem que selecionar ao menos uma
        !this.view['profileId'] ||
        !this.view['login'] ||
        (!this.isProfessionalProfile() && !this.view['email']) ||
        !this.view['company'] ||
        !this.view['originPlacement'] ||
        (!this.isCreating() && this.getAppAccess() && !this.passwordHide && !this.view['password'])) { // Só testa senha como obrigatório se não está criando, e tem acesso ao App, e usuário clicou em Redefinir senha        
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    if (!this.hasAccessToAllPlacements(this.view['profileId']) && (!this.view['placements'] || (this.view['placements'] as PlacementModel[]).length == 0)) names.push('Outras Lotações Acessadas');
    if (!this.view['profileId']) names.push('Perfil');
    if (!this.view['login']) names.push(this.isProfessionalProfile() ? 'Login App': 'Chave Petrobras');
    if (!this.isProfessionalProfile() && !this.view['email']) names.push('E-mail');
    if (!this.view['company']) names.push('Empresa');
    if (!this.view['originPlacement']) names.push('Lotação de Origem');
    if (!this.isCreating() && this.getAppAccess() && !this.passwordHide && !this.view['password']) names.push('Senha App');    
    return names;
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['name'] = null;
    this.model['login'] = null;
    this.model['email'] = null;
    this.model['patrolTeamId'] = null;
    this.model['lastLogin'] = null;
  }

  /**
   * Verifica se o usuário possui permiso para deletar "alguns perfis"(lista)
   * Em caso positivo, os botões para deletar usuário é escondido para usuários que não tiverem o mesmo perfil do usuário na lista,
   * ou seja, não estiverem marcados na seleção de perfis que o usuário pode alterar
   */
   canDelete(){
    if (!this.model) return false;

    // Verifica se o usuário logado pode modificar o perfil do usuário sendo editado
    if(this.authorizationService.canDeleteUser((this.model as UserModel).profileId)) 
      return true;
    else
      return false;
  }

  canEdit(){
    if (!this.model) return false;

    // Verifica se o usuário logado pode modificar o perfil do usuário sendo editado
    if(this.authorizationService.canEditUser((this.model as UserModel).profileId))
      return true;
    else
      return false;
  }

  postEntityDeleteProcess(entity: EntityModel) {
    const user = entity as UserModel;
    if (user.patrolTeamId) {
      this.markerService.sendTeamInfoMessage(this.loggedUser, user.patrolTeamId, "Usuário \"" + user.name + "\" removido da Equipe pois foi removido.");
    }
  }
  
  onPasswordSendClick() {
    let user = this.model as UserModel;
    if (!user.email) {
      this.toastr.warning('Não foi possível enviar o email. Verifique se o usuário tem email válido cadastrado.', 'Atenção!');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: 'Enviar a senha por email deste usuário?',
        title: 'Enviar Senha',
        okLabel: 'Enviar',
        identifier: user.login + ' - ' + user.email,
        showIdentifier: true
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
            this.logger.debug('ListComponent.onPasswordSendClick()');
            this.userService.sendEmailWithPassword(user).pipe(first()).subscribe( (user: UserModel) => {
                if(user!=null){
                  this.logger.debug(`Email enviado para : ${user.email}`);
                  this.toastr.success('Senha enviada com sucesso para o usuário: ' + user.login);
                }else{
                  this.toastr.error('Não foi possível enviar o email. Verifique se o usuário tem email válido cadastrado.');
                }                
            }, error => {
              this.toastr.warning('Não foi possível enviar o email. Verifique se o usuário tem email válido cadastrado.');
              this.logger.error(error);
            });
        }
    });
  }

  /**
   * Função que verifica qual perfil foi selecionado
    Se o perfil selecionado é administrador é desabilitada a lista de lotações
   */
  onProfileChanged(profileId): boolean{
    this.view['profileId'] = profileId;
    
    if(this.hasAccessToAllPlacements(this.view['profileId'])){
      this.disableOtherPlacements = true;
      this.view['placements'] = this.copyModel(this.entityCacheService.getPlacements()); // Seleciona todos as lotações
    }
    else{
      this.listPlacements.unSelectAll();
      this.view['placements'] = [];
      this.disableOtherPlacements = false;
      this.onOriginPlacementChanged('event');
    }
    
    this.updateAppAccess();
   
    return this.disableOtherPlacements;
  }

  /**O usuário logado não pode editar o campo perfil, Lotaçoes de origem e lotações
   * Se o usuário é administrador ou diferente ao usuário logado, é habilitado o campo perfil */
  canProfileEditField(): boolean {

    if(!this.model) return true;
    
    if(this.authorizationService.isAdmin()) return true;
    
    if(this.loggedUser.id === this.model.id) 
      return false;
    else
      return true;
  }

  onHistoricalTrackingClick() {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingUser: this.model});
  }

  getAllowedProfiles() {
    // Só administrador pode criar administrador 
    if (this.authorizationService.isAdmin()) {
      return this.entityCacheService.getAllProfiles();
    }

    if(this.model?.id && !this.canEdit()) { // o usuário existe e a tela está readonly
      return this.entityCacheService.getAllProfiles()
    }

    let profiles = this.entityCacheService.getProfiles();
    const permissionOptions = this.authorizationService.getUserPermissionOptions(Permission.CREATE_EDIT_USER);
    
    if (permissionOptions && permissionOptions.option == PermisionRuleOption.SOME_PROFILE) {
        profiles = profiles.filter(profile => permissionOptions.fields.includes(profile.id));
    }   
    
    return profiles;
    
  }

  private subscribeToProfileReload() {
    this.reloadProfilesSubscription = this.entityCacheService.onProfilesReload().subscribe(() => {
      // Os perfis foram recarregados
        this.updateAppAccess();
    });
  }
  
  onOriginPlacementChanged(event){
    const newOriginPlacement = this.view['originPlacement']
    if(!newOriginPlacement)
      return; 

    if(this.view['placements']?.length > 0){ // edição de usuário com placements
      const found = this.view['placements'].find(placement => placement.id === newOriginPlacement.id);
      if(!found){
        this.view['placements'].push(newOriginPlacement);
      }
    }else{ // usuário novo
      this.view['placements'] = [newOriginPlacement];
      this.listPlacements.toggleSelection(newOriginPlacement);
    }
    this.listPlacements.onClosedEvent(event);
  }
}
