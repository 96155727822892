<div class="sipd-container">

  <div class="sipd-header">
    <span class="mat-title m-0">{{title}}</span><small matTooltip="Número de Registros Lidos">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions">
      <mat-form-field class="list-filter header-input" appearance="outline">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_VEHICLE)'
        (click)="onCreateClick()"
        matTooltip="Adicionar Veículo">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-icon-button color="primary"
          matTooltip="Abrir Veículo(s) Selecionado(s)"
          [disabled]="this.selection.selected.length == 0"
          (click)="onEditManyClick(this.selection.selected)">
          <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar Veículo Selecionado
                    (Somente 1 Veículo pode estar selecionado)"
        [disabled]="this.selection.selected.length != 1"
        [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_VEHICLE)'
        (click)="onCopyClick(selection.selected[0])">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        (click)="onDeleteManyClick(this.selection.selected, $event)"
        matTooltip="Remover Veículos Selecionados"
        [hidden]='!authorizationService.userHasPermission(permission.DELETE_VEHICLE)'
        [disabled]="this.selection.selected.length == 0" >
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar Rastro no Mapa dos Veículos Selecionados"
        [disabled]="this.selection.selected.length == 0"
        (click)="onHistoricalTrackingManyClick()">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="sipd-content bg-white">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="plate">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Placa </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.plate}} </td>
      </ng-container>

      <ng-container matColumnDef="model">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Carro </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.model}} </td>
      </ng-container>

      <ng-container matColumnDef="company.name">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Empresa </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element.company?.name}} </td>
      </ng-container>

      <ng-container matColumnDef="observations">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Obs. </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.observations}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
        (contextmenu)="openContextMenu($event, element)"
        [ngClass]="{ 'highlighted': selection.isSelected(row)}">
      </tr>
    </table>

    <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>

    <mat-menu #contextMenu="matMenu" [hasBackdrop]="viewContextMenu">
      <button mat-menu-item
      (click)="onEditClick(this.contextMenuSelectedItem, $event)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon><span>Abrir Veículo</span>
      </button>
      <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_VEHICLE)' 
      (click)="onCopyClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon><span>Copiar Veículo</span>
      </button>
      <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.DELETE_VEHICLE)'
        (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.plate, $event)">
        <mat-icon class="material-icons-outlined">delete</mat-icon><span>Remover Veículo</span>
      </button>
      <button mat-menu-item color="primary" (click)="onHistoricalTrackingClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">my_location</mat-icon><span>Mostrar Rastro no Mapa</span>
      </button>
    </mat-menu>

  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
