import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { ESP, SORT_NAME_ASC } from 'src/app/common/constants';
import { ObservedAreaStatus, ObservedAreaStatusDescription, ObservedAreaTypeDescription, RegistrationType } from 'src/app/model/enums.enum';
import { ObservedAreaModel } from 'src/app/model/observed.area.model';
import { FilteredListComponent } from 'src/app/pages/filtered-list-component';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { ObservedAreaService } from 'src/app/service/model/observed.area.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { RegistrationListComponent } from '../../registration-list-component';
import { ObservedAreaFilterModel } from '../observed-area-filter/observed-area.filter.model';

@Component({
  selector: 'app-observed-area-list',
  templateUrl: './observed-area-list.component.html',
  styleUrls: ['../../../../app.component.scss', './observed-area-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObservedAreaListComponent extends RegistrationListComponent implements OnInit {

  statusDescription = ObservedAreaStatusDescription;
  typesDescription = ObservedAreaTypeDescription;

  constructor(logger: NGXLogger,
              dialog: MatDialog,
              protected toastr: ToastrService,
              public authorizationService: AuthorizationService,
              protected storageService:     StorageService,
              protected observedAreaService: ObservedAreaService,
              protected changeDetector:                ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, observedAreaService, dialog, 'observed-areas', environment.OBSERVED_AREA_GROUP_LABEL, environment.OBSERVED_AREA_TITLE_LABEL,
          environment.OBSERVED_AREA_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('ObservedAreaListComponent.constructor()');
    this.registrationType = RegistrationType.OBSERVED_AREA;
    this.sortOptions = SORT_NAME_ASC;
  }

  ngOnInit(): void {
    this.logger.debug('ObservedAreaListComponent.ngOnInit()');

    this.displayedColumns = ['select', 'identifier', 'status', 'name', 'areaType', 'createdAt',
                             'responsible.name', 'band', 'duct', 'km', 'startDate', 'endDate', 'updatedAt'];

    super.ngOnInit();
  }

  protected newFilterModel() {
    return new ObservedAreaFilterModel();
  }

  postLoadProcess() {
    this.model.forEach((element: ObservedAreaModel) => {
      element.type = 'ObservedArea';
    });
  }

  hasLocation(observedAreaModel: ObservedAreaModel): boolean {
    return observedAreaModel &&
           (!!observedAreaModel.latLong ||
            (!!observedAreaModel.stretchStartLatLong && !!observedAreaModel.stretchEndLatLong));
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para Buscar.
   */
   protected getStringEntityForFilter(area: ObservedAreaModel): string {
    const identifier = (area.identifier) ? super.lowerAndTrimText(area.identifier) : '';
    const status = super.lowerAndTrimText(ObservedAreaStatusDescription[area.status]);
    const name = super.lowerAndTrimText(area.name);
    const areaType = super.lowerAndTrimText(ObservedAreaTypeDescription[area.areaType]);
    const createdAt = (area.createdAt) ? this.formatDate(area.createdAt) : '';
    const responsible = (area.responsible) ? super.lowerAndTrimText(this.getUserNameLoginTitle(area.responsible)) : '';
    const duct = super.lowerAndTrimText(area.duct);
    const band = super.lowerAndTrimText(area.band);
    const km = area.stretchStartKM && area.stretchEndKM ? area.stretchStartKM + ' - ' + area.stretchEndKM : (area.km ? area.km : '');
    const startedDate = (area.startDate) ? this.formatDate(area.startDate) : '';
    const endDate = (area.endDate) ? this.formatDate(area.endDate) : '';

    return identifier + ESP + status + ESP + name + ESP + areaType + ESP + createdAt + ESP + responsible + ESP + duct + ESP + band + ESP + km + ESP + startedDate + ESP + endDate;
  }
  
  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'areaType':
        {
          const val = this.typesDescription[item['areaType']];
         return val ? val : '';
        }
      case 'km':
        {
          const val = item['stretchStartKM'] && item['stretchEndKM'] ? item['stretchStartKM'] + ' - ' +
          item['stretchEndKM'] : item['km'] ;
         return val ? val : '';
        }
      default: return super.getPropertyByPath(item, property);
   }
  }
}
