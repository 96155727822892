import { Component, OnInit, OnDestroy, Inject, Input, NgZone, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { ShiftDescription, OperationStatus, UserType, OperationStatusDescription, PermisionRuleOption, OperationType, Permission } from 'src/app/model/enums.enum';
import { VerificationService } from 'src/app/service/model/verification.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { VerificationModel } from 'src/app/model/verification.model';
import { saveAs } from 'file-saver/dist/FileSaver';
import { VerificationFilterDialogComponent } from '../verification-filter-dialog/verification-filter-dialog.component';
import { VerificationFilterModel } from '../verification-filter-dialog/verification.filter.model';
import { AuthorizationService } from '../../../service/authorization/authorization.service';
import DateUtils from 'src/app/service/util/date-utils';
import { TrackingService } from 'src/app/service/model/tracking.service';
import { OperationListComponent } from '../../operation-list-component';
import { EntityModel } from 'src/app/model/entity.model';
import { VerificationBandReleaseDescription } from '../../../model/enums.enum';
import { OperationModel } from 'src/app/model/operation.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { first } from 'rxjs/operators';
import { ArchivedType } from '../../../general/filter-component/filter.model';
import { EventModel } from '../../../model/event.model';
import { ESP } from 'src/app/common/constants';
import { MarkersService } from 'src/app/service/model/markers.service';

@ProfileClassToConsole()
@Component({
  selector: 'app-verification-list',
  templateUrl: './verification-list.component.html',
  styleUrls: ['../../../app.component.scss', '../../list.component.scss', './verification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationListComponent extends OperationListComponent implements OnInit, OnDestroy {

  /* Variável setada quando o componente é filho de outro e ajuda para o filtro de dados apresentado na lista */
  identifierEvent: string;

  @Input() readOnly: boolean = false;
  
  _editedEvent : EventModel;
  
  @Input()
  set editedEvent (event : EventModel) {
    this._editedEvent = event;
    if(this._editedEvent != null){
      this.identifierEvent =  event.identifier != null ? event.identifier : "[NOVO]"; // o modelo existe, mais ainda não foi salvo
      if (this.filterModel) {
        let filter: VerificationFilterModel =  this.filterModel as VerificationFilterModel;
        filter.eventIds = [];
        filter.eventIds.push(this.identifierEvent);
        filter.archived = ArchivedType.ALL;
      }
    } else {
      this.identifierEvent = "[NOVO]";
    }
  
  }
  
  get editedEvent(): EventModel {
    return this._editedEvent;
  }

  operationStatusDescription = OperationStatusDescription;
  verificationBandReleaseDescription = VerificationBandReleaseDescription;
  permisionRuleOption = PermisionRuleOption; 

  isHiddenButtonCreateEditVerification: boolean;
  isHiddenButtonDeleteVerification: boolean; 

  constructor(logger:                       NGXLogger,
              protected changeDetector:     ChangeDetectorRef,
              protected ngZone:              NgZone,
              protected verificationService:  VerificationService,
              protected markerService:      MarkersService,
              protected storageService:     StorageService,
              public authorizationService: AuthorizationService,
              protected toastr:             ToastrService,
              protected trackingService:    TrackingService,
              dialog:               MatDialog,              
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, changeDetector, ngZone, authorizationService, verificationService, OperationType.EVENT_VERIFICATION, dialog, 'verifications', environment.VERIFICATION_GROUP_LABEL, environment.VERIFICATION_TITLE_LABEL,
      environment.VERIFICATION_MODEL_LABEL, storageService, trackingService, markerService, toastr, goldenLayout, container);
    logger.debug('VerificationListComponent.constructor()');
  }

  ngOnInit() {
    super.ngOnInit();
    this.logger.debug('VerificationListComponent.ngOnInit()');    
    // Ao editar essa lista, não esquecer de atualizar columnsTitles mais abaixo (select e lastSync não entram, date=>data+hora, km=>km+trecho+trecho)
    if(this.identifierEvent){
      this.displayedColumns = ['select', 'identifier', 'status', 'analyst.name',
                              'sentDate', 'band', 'duct', 'km', 'patrolTeam.company.placement.name',
                              'patrolTeam.name', 'priority', 'bandRelease','lastSync'];
    }else{
      this.displayedColumns = ['select', 'identifier', 'status', 'analyst.name',
                              'sentDate', 'band', 'duct', 'km', 'patrolTeam.company.placement.name',
                              'patrolTeam.name', 'priority', 'bandRelease', 'eventId', 'lastSync'];
    }

    this.glUpdateTabTitle();

    /**Verifica se o usuário possui a restrição "Acesso somente a dados de Lotações específicas" da seção Web.
     * Em caso positivo, somente as verificações que pertencem à mesma Lotação do usuário */
     this.filterModel.placements = this.storageService.getSpecificPlacementIds();
  }

  protected getDefaultSortColumn(): string {
    return "identifier";
  }

  protected getDefaultSortDirection(): 'asc' | 'desc' {
    return 'desc';
  }

  protected newFilterModel() {
    let filter = new VerificationFilterModel();

    if (this.identifierEvent) {
      filter.eventIds = [];
      filter.eventIds.push(this.identifierEvent);
      filter.archived = ArchivedType.ALL;
    }
    
    return filter;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  postLoadProcess(): void {
    this.model.forEach((verification: VerificationModel) => {
      verification.type = OperationType.EVENT_VERIFICATION;
      this.updateOperationFromPatrolTeam(verification);
      this.checkVerificationStartDate(verification);
    });
    
    this.loadLastSignals();
  }

  protected getExportColValue(col, model):string[] {
    let values: string[] = [];

    if(col == 'sentDate') {
      values.push(model[col] ? DateUtils.timestampToStringInDays(model[col]) : "");
      values.push(model[col] ? DateUtils.timestampToTimeString(model[col]) : "");
    }
    else if(col == 'priority') {
      if(model[col]) values.push('Sim');
      else values.push('Não');
    }
    else if(col == 'status') {
      values.push(this.getOperationStatus(model));
    }
    else if (col == 'bandRelease'){
      values.push(VerificationBandReleaseDescription[model[col]]);
    }
    else if(col == 'patrolTeam.name') {
      if(model['patrolTeam']) values.push(model['patrolTeam']['name']);
      else values.push("");
    }
    else if(col == 'patrolTeam.shift') {
      if(model['patrolTeam'] &&  model['patrolTeam']['shift']) values.push(ShiftDescription[model['patrolTeam']['shift']]);
      else values.push("");
    }
    else if(col == 'patrolTeam.company.placement.name') {
      if(model['patrolTeam'] && model['patrolTeam']['company'] && model['patrolTeam']['company']['placement']) return model['patrolTeam']['company']['placement']['name'];
      else values.push("");
    }
    else if(col == 'km') {
      values.push(model['km'] ? model['km'] : "");
      values.push(model['stretchStartKM'] ? model['stretchStartKM'] : "");
      values.push(model['stretchEndKM'] ? model['stretchEndKM'] : "");
    }
    else if(col == 'analyst.name') {
      if(model['analyst']) values.push(this.getUserNameLoginTitle(model['analyst']));
      else values.push("");
    }
    else {
      values.push(model[col]);
    }
    return values;
  }

  onExportManyClick() {
    if(this.selection.selected.length == 1) {
      this.onExportClick(this.selection.selected[0], null);
    }
    else {
      const columnsTitles = ['ID', 'Status', 'Analista', 'Data', 'Hora', 'Faixa', 'Duto', 'KM','Início de Trecho (KM)', 'Fim de Trecho (KM)', 
                             'Lotação', 'Equipe', 'Prioritária', 'Liberação de Faixa', 'Evento'];
      this.exportXls(columnsTitles);
    }
  }

  onExportClick(row: any, event: any) {
    this.loadingListService.loadingOn();
    (<VerificationService>this.entityService).exportVerification(row).pipe(first()).subscribe(response => {
      this.loadingListService.loadingOff();
      this.renderComponent();
      
      let filename = `${row.identifier}.zip`;
      filename = filename.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      saveAs(response, filename);
    },
    error =>{
      this.loadingListService.loadingOff();
      this.renderComponent();
      this.logger.error(error);
    });
  }

  /**
   * Abre dialog de filtro dos dados do grid
   */
  openFilterDialog(): void {
    const dialogRef = this.dialog.open(VerificationFilterDialogComponent, {
      width: '800px',
      data: this.filterModel,
      panelClass: 'sipd-modal'
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.updateDataSource(result);
      }
    });
  }

  canDelete(entity: EntityModel) {
    return this.authorizationService.isVerificationDeletionAllowed(<VerificationModel>entity);
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para Buscar.
   */
   protected getStringEntityForFilter(verification: VerificationModel): string {
    const identifier = (verification.identifier) ? super.lowerAndTrimText(verification.identifier) : '';
    const status = super.lowerAndTrimText(this.getOperationStatus(verification) + ((verification.expired && verification.status=='FINISHED')? ' (expirada)': ''));
    const sentDate = (verification.sentDate) ? this.formatDate(verification.sentDate) : '';
    const priority = verification.priority ? 'sim': 'não';
    const patrolTeamName = verification.patrolTeam? super.lowerAndTrimText(verification.patrolTeam.name): '';
    const placement = verification.patrolTeam && verification.patrolTeam.company && verification.patrolTeam.company.placement && verification.patrolTeam.company.placement.name? super.lowerAndTrimText(verification.patrolTeam.company.placement.name): '';
    const analyst = (verification.analyst) ? super.lowerAndTrimText(this.getUserNameLoginTitle(verification.analyst)) : '';
    const eventId = super.lowerAndTrimText(verification.eventId);
    const duct = super.lowerAndTrimText(verification.duct);
    const band = super.lowerAndTrimText(verification.band);
    const bandRelease  = verification.bandRelease ? super.lowerAndTrimText(this.verificationBandReleaseDescription[verification.bandRelease]) : '';
    const km = verification.stretchStartKM && verification.stretchEndKM ? verification.stretchStartKM + ' - ' +
               verification.stretchEndKM : (verification.km ? verification.km : '');
    return identifier + ESP + status + ESP + sentDate + ESP + priority + ESP + eventId + ESP + band + ESP + duct + ESP + km + ESP + patrolTeamName + ESP + placement + ESP + bandRelease + ESP + analyst;
  }

  // Verifica os requisitos do model para arquivamento
  hasArchivalRequirements(model: VerificationModel){

    if(model.status != OperationStatus.FINISHED){ // Verifica se o status é Concluído
      return false;
    }

    if(this.authorizationService.isControl()){ // Se for perfil ANALYSIS_CCPD, verifica se os eventos foram criados por ele
      if(!model.analyst || model.analyst.id !== this.loggedUser.id.toString()){
        return false;
      }
    }

    return true;
  }

  archiveTooltipText() {
    return `${this.archiveButtonLabel}  Verificação(ões) Selecionada(s)`;
  }

  /** Abre o container de inserção de verificações, passando o identificador do evento */
  onCreateVerificationClick(){
    this.glOpenContainer('verifications-edit', {id: null, options: {event: this.editedEvent}});
  }

  /** Desabilita o botão criar quando a lista está dentro da edição de um evento que não foi salvo */
  public canCreateVerification(): boolean {
    if (this.identifierEvent === '[NOVO]') return false
    else return true;
  }
  
  /** Método sobrescrito do pai que permite reusar o componente da lista de verificações
   *  sem trocar o título do componente que o está contido (evento) */
  glUpdateTabTitle(){
    if(!this.identifierEvent) super.glUpdateTabTitle(this.tabTitle);
  }

  saveData(entity){
    // Confere se o usuário tem acesso apenas algumas lotações, antes de atualizar na lista 
    let specificPlacements = this.storageService.getSpecificPlacementIds();
    const operation = entity as OperationModel;
    if(specificPlacements.length > 0 && !specificPlacements.includes(operation.placement.id)){
      return;
    }

    const oldModel: any = this.model.find( model => operation.id == model.id);
    if (oldModel)
      entity.lastSync = oldModel.lastSync;

    if(!this.identifierEvent || (<VerificationModel>entity).eventId == this.identifierEvent){  
      if (this.identifierEvent) (<VerificationFilterModel>this.filterModel).eventIds.push(this.identifierEvent);
      if (this.model) super.saveData(entity); // TODO scuri Não faz sentido o teste de proteção, mas em algum caso não identificado está ocorrendo. Pode ser concorrência. 
    }
  }

  checkPermissions(){
    this.isHiddenButtonCreateEditVerification = !this.authorizationService.userHasPermission(Permission.CREATE_EDIT_VERIFICATIONS);
    this.isHiddenButtonDeleteVerification = !this.authorizationService.userHasPermission(Permission.DELETE_VERIFICATIONS);
  }
  
  /** chamada pelo updateModelItem inscrito no websocket que ouve as mudanças nas operações */
  checkInsideEventlistOrNotInsideEvent(entity): boolean{
    if(!this.identifierEvent) // se a lista não está numa edição de evento
       return true;

    if(entity.eventId === this.identifierEvent ){ // se a lista pertence a esse evento
      return true;
    }
    else {
      return false;
    }
  }

  onHistoricalTrackingClick(verificationModel : VerificationModel){
    this.checkVerificationStartDate(verificationModel);
    super.onHistoricalTrackingClick(verificationModel);
  } 

  //verifica se a operação tem startDate, se não tiver prenche essa informação com a data de envío
  checkVerificationStartDate(verification : VerificationModel){
    if (verification.sentDate != null && verification.startDate == null) {
        verification.startDate = verification.sentDate;
    }
  }
}