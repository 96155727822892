import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { EventMainActionModel } from 'src/app/model/event.main.action.model';
import { environment } from 'src/environments/environment';
import { EntityService } from './entity.service';

@Injectable({
  providedIn: 'root'
})
export class EventMainActionsService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/event-main-actions`);
  }

  /**
   * Obtem uma lista de ações principais filtradas por origem
   * @param sourceType Origem para filtro
   */
  public loadFromServerBySourceType( sourceType: string ): Observable<EventMainActionModel[]> {
    this.logger.debug(`Obtendo lista de ações principais de eventos filtradas pela origem '${sourceType}'`);

    const params: HttpParams = new HttpParams().set('sourceType', sourceType);
    return this.http.get<EventMainActionModel[]>(this.apiUrl, { params: params });
  }
}
