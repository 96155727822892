import { AlertPriorityDescription, AlertTypeDescription, ChannelTypeDescription, EventFieldsDescription, EventSituationDescription, EventStatusDescription, 
         EventValidationDescription, EvidenceRequirementDescription, InspectionBooleanDescription, InspectionStatusTypeDescription, MarkerTypeDescription, 
         NodeTypeDescription, ObservedAreaStatusDescription, ObservedAreaTypeDescription, OperationStatusDescription, OperationTypeDescription, 
         PointStatusDescription, ResultOccurrenceDescription, ServiceTypeDescription, ShiftDescription, SimfAlertTypeDescription, SourceTypeDescription, 
         UserFieldsDescription, UserTypeDescription, VerificationBandReleaseDescription } from 'src/app/model/enums.enum';

export default class LanguageUtils{

  static languageMap: {};

  static addEnum(e) {
    for (const k in e) {
      LanguageUtils.languageMap[k] = e[k];
    }
  }

  static init(){
    LanguageUtils.languageMap = {};
    
    LanguageUtils.addEnum(UserFieldsDescription);
    LanguageUtils.addEnum(EventFieldsDescription);
    LanguageUtils.addEnum(ChannelTypeDescription);
    LanguageUtils.addEnum(ObservedAreaStatusDescription);
    LanguageUtils.addEnum(NodeTypeDescription);
    LanguageUtils.addEnum(EvidenceRequirementDescription);
    LanguageUtils.addEnum(InspectionStatusTypeDescription);
    LanguageUtils.addEnum(MarkerTypeDescription);
    LanguageUtils.addEnum(OperationTypeDescription);
    LanguageUtils.addEnum(EventStatusDescription);
    LanguageUtils.addEnum(PointStatusDescription);
    LanguageUtils.addEnum(OperationStatusDescription);
    LanguageUtils.addEnum(UserTypeDescription);
    LanguageUtils.addEnum(AlertTypeDescription);
    LanguageUtils.addEnum(SimfAlertTypeDescription);
    LanguageUtils.addEnum(AlertPriorityDescription);
    LanguageUtils.addEnum(EventSituationDescription);
    LanguageUtils.addEnum(ResultOccurrenceDescription);
    LanguageUtils.addEnum(SourceTypeDescription);
    LanguageUtils.addEnum(EventValidationDescription);
    LanguageUtils.addEnum(ShiftDescription);
    LanguageUtils.addEnum(ServiceTypeDescription);
    LanguageUtils.addEnum(VerificationBandReleaseDescription);
    LanguageUtils.addEnum(InspectionBooleanDescription);
    LanguageUtils.addEnum(ObservedAreaTypeDescription);

    LanguageUtils.languageMap['name'] = 'Nome';
    LanguageUtils.languageMap['states'] = 'UF(s)';
    LanguageUtils.languageMap['year'] = 'Ano';
  }
}
