import { Component, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SingleDataService } from '../../service/model/single.data.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../service/storage-service';
import { AuthorizationService } from '../../service/authorization/authorization.service';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import * as GoldenLayout from 'golden-layout';
import { EditComponent } from '../../pages/edit-component';
import { SingleDataModel } from '../../model/singleData.model';
import { Permission } from 'src/app/model/enums.enum';
import { FILL_DATA_PREFIX, MAP_PAGE } from '../../common/constants';
import { EntityModel } from 'src/app/model/entity.model';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { DcModel } from 'src/app/model/dc.model';

@Component({
  selector: 'app-single-data-edit',
  templateUrl: './single-data-edit.component.html',
  styleUrls: ['./single-data-edit.component.scss']
})
export class SingleDataEditComponent extends EditComponent {

  public dataType: string;
  public hasGeometry: boolean;

  constructor(logger:                     NGXLogger,
              protected singleDataService:      SingleDataService,
              public singleDataCacheService:    SingleDataCacheService,
              dialog:                     MatDialog,
              toastr:                     ToastrService,
              protected storageService:   StorageService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
      super(logger, singleDataService, dialog, "", "", storageService,
            container.title, "", toastr, authorizationService, goldenLayout, container);
      this.logger.debug('SingleDataEditComponent.constructor()');

      const dataInfo = singleDataService.getDataInfo(container.title);
      this.dataType = dataInfo.type;
      this.modelName = dataInfo.modelLabel;
      this.hasGeometry = dataInfo.geometry;
    }

  loadFormOptionsData() {
    if (this.hasGeometry) {
      const metadatas: string[] = [
        "State",
      ];
      
      this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
    }
  }

  protected createData(options?: any) {
    this.logger.debug('SingleDataEditComponent.createData()');

    this.model = new SingleDataModel();
    this.model['type'] = this.dataType;
    this.view = {};
    this.view['type'] = this.model['type'];
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.EDIT_REGISTRATION_GROUP)){
      return false
    }

    return true;
  }

  protected getExtraParams() {
    let extraParams = new Map();
    extraParams.set("metadata", this.dataType);
    extraParams.set("id", this.id);
    return extraParams;
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['name']) {
      return false;
    }
    if (this.hasGeometry && !this.view['states']) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    return names;
  }

  mapModelToView() {
    super.mapModelToView();

    if (this.view['name'] == null) {
      if (this.dataType == "Dc") {
          DcModel.updateName(this.view as DcModel);
      }
    }

    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }

    this.view['type'] = this.dataType;
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['name'] = null;
    if (this.hasGeometry) {
      this.model['states'] = []
    }
    if (this.dataType == "Dc") {
      this.model['year'] = null;
    }
  }

  entitySaved(entity: EntityModel): void {
    super.entitySaved(entity);
    this.glEmitEvent(FILL_DATA_PREFIX + MAP_PAGE, {updatedLayer: this.dataType});
  }
  
  onLocationClick(){
    this.glOpenContainer(MAP_PAGE, {layerData: this.view});
  }

  hasLocation(){
    if (this.view && this.view['geometry'])
      return true;
    return false;
  }
}
