import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Directive()
export abstract class Autocomplete implements OnInit {

  dataControl = new FormControl();

  // lista de objetos de entrada
  _data: Object[];
  @Input()
  set data(newData: Object[]) {
    this._data = newData;
    this.filteredData = this.dataControl.valueChanges.pipe(
      startWith<string | Object[]>(''),
      map(value => typeof value === 'string' ? value : ''),
      map(filter => this.filter(filter))
    );
  }

  get data() {
    return this._data;
  }
  
  @Output() valueDataChange = new EventEmitter<any>();

  @Output() selectionChangedEvent = new EventEmitter<any>();
  
  // Define qual o parametro do objeto para receber o valor selecionado (em geral 'id' ou 'key')
  _optionValue: any;
  @Input()
  set optionValue(val: any) {
    this._optionValue = val;
  }
  
  _required: boolean;
  @Input()
  set required(val: boolean) {
    this._required = val;
  }
  
  _disabled: boolean;
  @Input()
  set disabled(val: boolean) {
    this._disabled = val;
    if (val) {
      this.dataControl.disable();
    }
    else {
      this.dataControl.enable();
    }
 }
  
  _readonly: boolean;
  @Input()
  set readonly(val: boolean) {
    this._readonly = val;
  }

  // Usado para ficar vermelho no dado inválido
  @Input() controlColor: String;

  // Define qual o parâmetro que é usado para representar visualmente o objeto (em geral 'name')
  _name: string;
  @Input()
  set name(data: string) {
    this._name = data;
  }

  get name() {
    return this._name;
  }

  @Input() placeholder: string;

  @Input() label: string;

  // Função que retorna o dado do objeto que aparece na tela. Se não estiver definido name é utilizado
  @Input() displayName: (args: any) => void;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @ViewChild('someInput') someInput!: ElementRef;

  selectedData: Object;

  filteredData: Observable<Object[]>;

  scrollEvent = (event: any): void => {
    if(this.autocomplete.panelOpen)
      this.autocomplete.updatePosition();
  };

  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  filter(filter: string): Object[] {
    if (filter) {
      let nfilter:string = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return this._data.filter(option => {
        let item = this._name ? option[this._name] : option;
        item = item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return item.toLowerCase().indexOf(nfilter.toLowerCase()) >= 0;
      })
    } else {
      return this._data ? this._data.slice() : this._data;
    }
  }

  getDataControlObjectValue(value: any, option: string) {
    let retValue: any = undefined;
    if (option) {
      this._data.forEach(item => {
        if(item[option] === value) {
          retValue = item;
        }
      })
    } 
    else {
      retValue = value;
    }
    return retValue;
  }

  getDataControlPropertyValue(value: any, option: string) {
    let retValue: any = undefined;
    if (option && value != null) { //  value != null verifica que o campo selecionado seja diferente de vazío
      this._data.forEach(item => {
        if(item[option] === value[option]) {
          retValue = item[option];
        }
      })
    }
    else {
      retValue = value;
    }
    return retValue;
  }
  
  getData(data: Object){
    if (this._name) {
      return this.displayName ? this.displayName(data) : data[this._name];
    }
    return data;
  }

}
