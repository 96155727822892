import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { PointFilterModel } from 'src/app/pages/registrations/point/point-filter-dialog/point.filter.model';
import { EntityModel } from 'src/app/model/entity.model';
import {environment} from '../../../environments/environment';
import { EntityService } from './entity.service';
import { UserModel } from '../../model/user.model';

export const OBJECT_NAME = 'pointObject';
@Injectable({
  providedIn: 'root'
})
export class PointService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
                super(logger, httpClient, `${environment.settings.registrations_address}/inspection-points`);
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: PointFilterModel): Observable<T> {
    let params: HttpParams = new HttpParams();

    if(filter){
      if (filter.band){
        params = params.set('band', filter.band);
      }

      if (filter.stretch){
        params = params.set('stretch', filter.stretch);
      }

      if (filter.startKm){
        params = params.set('startKm', filter.startKm.replace('+', ''));
      }

      if (filter.endKm){
        params = params.set('endKm', filter.endKm.replace('+', ''));
      }

      if (filter.status){
        filter.status.forEach( status => {
          params = params.append('status', status);
        });
      }
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  public importXls(author: UserModel, xlsFile: File) {
    this.logger.debug('PointService.importXls()');
    const formData: FormData = new FormData();
    if (xlsFile instanceof File) {
      formData.append("fileXls", xlsFile);
    }

    const objectModel = JSON.stringify(author);
    formData.append("author", objectModel);

    return super.postAsFormData(this.apiUrl + '/import', formData);
  }
}
