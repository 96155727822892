import { HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { first, map } from 'rxjs/operators';
import { SORT_NAME_ASC } from 'src/app/common/constants';
import { SingleDataModel } from 'src/app/model/singleData.model';
import { LoadingListService } from '../loading/loading-list.service';
import { SingleDataService } from './single.data.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SingleDataCacheService {

  private values = {};

  constructor(
    public logger: NGXLogger,
    protected singleDataService: SingleDataService) { 
    console.log("SingleDataCacheService.constructor");
  }

  getValues(metadata: string): string[] {
    if (this.values[metadata]) {
      return this.values[metadata];
    }
    else {
      return [];
    }
  }

  private pushValues(data: SingleDataModel[]) {
    for (var i in data) {
      let metadata = (data[i].type == "GasDuct" || data[i].type == "OilDuct")? "Duct": data[i].type;
      if (metadata) {
        const value = data[i].name;
        this.values[metadata].push(value);
      }
    }
  }

  private appendMetadata(params: HttpParams, metadata: string): HttpParams {
    if (metadata == "Duct") {
      params = params.append("metadata", "GasDuct");
      params = params.append("metadata", "OilDuct");
    }
    else {
      params = params.append("metadata", metadata);
    }

    this.values[metadata] = [];

    return params;
  }

  private sortDucts(){
    this.values["Duct"].sort(function(a, b){
      if (!a && !b) return 0
      if (!a) return 1;
      if (!b) return -1;
      let x = a.toLowerCase();
      let y = b.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
  }

  private sortOPS(){
    this.values["OpticalProcessingSystem"].sort((a, b) => {
      return a.opsNumber - b.opsNumber;
    });
  }

  private updateValues(data: SingleDataModel[]){
    const hasDuct: boolean = (this.values["Duct"] && this.values["Duct"].length == 0)? true: false;
    const hasOPS: boolean = (this.values["OpticalProcessingSystem"] && this.values["OpticalProcessingSystem"].length == 0)? true: false;

    this.pushValues(data);

    if (hasDuct) {
      this.sortDucts();
    }
    if (hasOPS) {
      this.sortOPS();
    }
  }

  loadMulipleValues(metadatas: string[], loadingListService: LoadingListService, onLoadCallback?: Function) {
    let params: HttpParams = new HttpParams();

    let hasMeta = false;

    for (var i in metadatas) {
      if (!this.values[metadatas[i]]) {
        params = this.appendMetadata(params, metadatas[i]);
        hasMeta = true;
      }
    }

    if (!hasMeta) {
      if (onLoadCallback) onLoadCallback();
      return;
    }
  
    loadingListService.loadingOn();
    this.singleDataService.loadMultipleListFromRestApi(params).pipe(first()).subscribe((data: SingleDataModel[]) => {
      this.updateValues(data);
      if (onLoadCallback) onLoadCallback();
      loadingListService.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar elementos: ${error}`);
      loadingListService.loadingOff();
    });
  }

  reloadValues(metadata: string) {
    let params: HttpParams = new HttpParams();
    params = this.appendMetadata(params, metadata);
    
    this.singleDataService.loadListFromRestApi(null, null, SORT_NAME_ASC, params).pipe(first()).subscribe((data: SingleDataModel[]) => {
      this.pushValues(data);
    });
  }
}
