import FieldUtils from '../service/util/field-utils';
import { SingleDataModel } from './singleData.model';
import { environment } from 'src/environments/environment';

export class GeoModel extends SingleDataModel{
  // GeoEntity
  identifier:   string;
  description:  string;
  geometry:     any;   // {coordinates: [number, number] | [number, number][] | [], type: string}; // types: 'Point' (único ponto) | 'LineString'/'Polygon'/'MultiPoint' (array de pontos) | 'GeometryCollection' (array de geometry)

  // StatesGeoEntity
  states:       string[];

  // LatLongGeoEntity - DC, DeliveryPoint, Refinary, Terminal, Valve, KilometerMarkModel
  latLong:      string;

  simple:boolean = true;

  public static getLatLong(geo){
    let latLong: string;
    if (geo.latLong){
      latLong = FieldUtils.parseLatLong(geo.latLong);
    }
    else if (geo.lat && geo.lng){
      latLong = FieldUtils.coordToString(geo.lat) + ',' + FieldUtils.coordToString(geo.lng);
    }
    else {
      latLong = '';
    }
    return latLong;
  }

  public static getPopupContent(feature, search: boolean = false): string{
    if (feature && feature.properties){
      const geo = feature.properties as GeoModel;
      
      let typeValues = {Refinary: "Refinaria", DeliveryPoint: "Ponto de Entrega ou Estação", Terminal: "Terminal"};
      let states: string = geo.states ? geo.states.toString() : '';
      let band: string = (geo as any).band;
      let latLong: string = GeoModel.getLatLong(geo);
      let contents: string =  `<h5 style="text-align: center">${ typeValues[geo.type] }</h5>
                               <h6 style="text-align: center"><b>${ geo.name }</b></h6>
                               ${band? '<div><b>Faixa:</b>' + band + '</div>': ''}
                               <div><b>UF(s):</b> ${states} </div>
                               <div><b>Lat, Long:</b> ${!search? latLong: ''} </div>
                              `;
      if (!environment.showPopupLayerTables || geo.description == null || geo.description === "") {                              
        return contents;
      }
      else {
        return contents + "<hr/>" + geo.description
      }
    }

    return `<h5>Sem Dados</h5>`;
  }

  public isSimple() {
    return this.simple;
  }
}
