
import { EntityModel } from './entity.model';
import { QuestionModel } from './question.model';
import { PlacementModel } from './placement.model';
import LanguageUtils from '../service/util/language-utils';

export class FormModel extends EntityModel {
    
    identifier      :       string;
    name            :       string;
    placement       :       PlacementModel;
    inspectionType  :       string;
    published       :       boolean; // Se foi publicado na Web, para ser usado na edição de operações
    questions       :       QuestionModel[];    

    constructor (){
        super();
        this.published = false;
        this.questions = [];
    }
 
  static addLanguageMap(){
    LanguageUtils.languageMap['inspectionType'] = 'Tipo de Inspeção';
    LanguageUtils.languageMap['published'] = 'Publicado';
    LanguageUtils.languageMap['questions'] = 'Perguntas';
  }
}
