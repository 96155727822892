<form class="company-form edit-form h-100">
  <div class="sipd-container">
    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVA]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button 
          matTooltip="Habilitar Edição"
          [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar"
          (click)="onCopyClick()"
          [disabled]="!id"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_COMPANY)'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()"
          matTooltip="Remover"
          [hidden]='!authorizationService.userHasPermission(permission.DELETE_COMPANY)'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_COMPANY)'>
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button  (click)="onSaveClick($event)" [disabled]="!canSave()" matTooltip="Salvar" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_COMPANY)'>
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Nome</mat-label>
          <input [readonly]="readOnly" cdkFocusInitial name="name" matInput placeholder="Nome" [(ngModel)]="view['name']" required>
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Endereço</mat-label>
          <input [readonly]="readOnly" name="address" matInput placeholder="Endereço" [(ngModel)]="view['address']">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Telefone</mat-label>
          <input [readonly]="readOnly" name="phone" matInput placeholder="Telefone" [(ngModel)]="view['phone']" #phone="ngModel" phone
            mask="(00) 0000-0000||(00) 0 0000-0000" [dropSpecialCharacters]="false">
          <mat-error *ngIf="phone.invalid">Entre apenas com números, espaço ou hífen</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>E-mail</mat-label>
          <input [readonly]="readOnly" name="email" matInput placeholder="E-mail" [(ngModel)]="view['email']" #email="ngModel" email
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            oninput="this.value = this.value.toLowerCase().trim()" novalidate >
          <mat-error *ngIf="email.invalid">Formato de e-mail inválido</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Website</mat-label>
          <input [readonly]="readOnly" name="website" matInput placeholder="Website" [(ngModel)]="view['website']">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>CNPJ</mat-label>
          <input [readonly]="readOnly" name="cnpj" type="text" matInput placeholder="CNPJ" [(ngModel)]="view['cnpj']" #cnpj="ngModel" cnpj
            mask="00.000.000/0000-00" [dropSpecialCharacters]="false">
          <mat-error *ngIf="cnpj.invalid">Entre apenas com números, ponto, barra e hífen</mat-error>
        </mat-form-field>
        <single-autocomplete [data]="entityCacheService.getPlacements()" name="name" placeholder="Lotação" [(valueData)]="view['placement']"
          [required]="true" [disabled]="readOnly" fxFlex>
        </single-autocomplete>
        <single-autocomplete [data]="singleDataCacheService.getValues('State')" placeholder="UF" [(valueData)]="view['uf']"
          [disabled]="readOnly" fxFlex>
        </single-autocomplete>

        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pl-3 pr-2">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Observações</mat-label>
          <textarea rows="5" matInput name="observations" matInput placeholder="Observações"
            [(ngModel)]="view['observations']" [readonly]="readOnly"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
