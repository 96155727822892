<div class="mat-small">{{file_label}}</div>
<!--mat-form-field class="upload-selected-file-input">
      <input [(ngModel)]="filename" matInput placeholder="{{file_label}}" name="upload_file" type="text" disabled>
    </mat-form-field-->
<div class="app-upload-content ">
  <button mat-icon-button color="primary" (click)="openFile()" class="ml-auto" *ngIf="filename && seeFile" [disabled]="readOnly">
    <mat-icon class="material-icons-outlined" matTooltip="Ver">visibility</mat-icon>
  </button> 
  <mat-icon color="primary" class="mr-2 text-emphasis-3 " *ngIf="!filename">{{file_icon}}</mat-icon>
  <mat-icon color="primary" class="mr-2" *ngIf="filename">{{file_icon}}</mat-icon>
  <div class="file-field" *ngIf="!filename">
    <span class="text-emphasis-2 ">{{file_placeholder}}</span>
  </div>
  <div class="file-field" *ngIf="filename">
    <span>{{filename}}</span>
  </div>
  <button mat-icon-button color="primary" (click)="clearFile()" class="ml-auto" *ngIf="filename" [disabled]="readOnly">
    <mat-icon class="material-icons-outlined" matTooltip="Remover">clear</mat-icon>
  </button>  
  <button mat-raised-button class="upload-button" *ngIf="!filename" [disabled]="readOnly">
    {{button_text}}
    <input #uploadfileinput type="file" name="upload_file_input" class="upload-input" (change)="fileSelected()" [disabled]="readOnly">
  </button>
</div>