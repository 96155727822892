import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_VERSION_ENDPOINT } from '../../common/constants';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  protected readonly apiUrl: string = `${environment.settings.registrations_address}/registrations`;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Obtem a versão do projeto registrations
   */
  public getServiceVersion(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }
}
