import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { EditComponent } from 'src/app/pages/edit-component';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { StorageService } from 'src/app/service/storage-service';
import { PointService } from '../../../../service/model/point.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

import {PointSourceValues,
  AccessArrangementValues,
  AccessTypeValues,
  BlockTypeValues,
  RoadConditionValues,
  NumberOfOutputsValues,
  TrafficVolumeValues,
  TrafficPossibilityValues,
  ResidencesValues,
  VideoMonitoringValues,
  AreaUnderControlValues,
  VulnerabilityViewValues,
  CategoryValues,
  AccessCriticalityValues,
  LatLongPattern,
  LatLongMask} from '../../../../common/constants';
import{InspectionPointModel} from 'src/app/model/inspection.point.model';
import { PointStatusDescription, PointStatus, InspectionBooleanDescription, Permission} from 'src/app/model/enums.enum';
import { UserType } from '../../../../model/enums.enum';
import { MAP_PAGE } from 'src/app/common/constants';
import FieldUtils from '../../../../service/util/field-utils';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import DateUtils from 'src/app/service/util/date-utils';

@Component({
  selector: 'app-point-edit',
  templateUrl: './point-edit.component.html',
  styleUrls: ['./point-edit.component.scss']
})
export class PointEditComponent extends EditComponent implements OnInit {

  startDate: moment.Moment;

  pointSourceValues =           PointSourceValues;
  accessArrangementValues =     AccessArrangementValues;
  accessTypeValues =            AccessTypeValues;
  blockTypeValues =             BlockTypeValues;
  roadConditionValues =         RoadConditionValues;
  numberOfOutputsValues =       NumberOfOutputsValues;
  trafficVolumeValues =         TrafficVolumeValues;
  trafficPossibilityValues =    TrafficPossibilityValues;
  residencesValues =            ResidencesValues;
  videoMonitoringValues =       VideoMonitoringValues;
  areaUnderControlValues =      AreaUnderControlValues;
  vulnerabilityViewValues =     VulnerabilityViewValues;
  categoryValues =              CategoryValues;
  accessCriticalityValues =     AccessCriticalityValues;

  pointStatus               = PointStatus;
  pointStatusDescription    = PointStatusDescription;

  inspectionBooleanDescription = InspectionBooleanDescription;

  latLongPattern = LatLongPattern;
  latLongMask = LatLongMask;

  date: moment.Moment;

  constructor(logger:                       NGXLogger,
              public pointService:          PointService,
              public entityCacheService:    EntityCacheService,
              public singleDataCacheService:  SingleDataCacheService,
              dialog:                       MatDialog,
              protected http:               HttpClient,
              toastr:                       ToastrService,
              protected storageService:     StorageService,
              authorizationService:         AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
                super(logger, pointService, dialog, environment.POINT_MODEL_LABEL, environment.POINT_TITLE_LABEL, storageService,
                      'points-edit',  environment.POINT_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
   }

  ngOnInit(): void {
    this.initializeFields(); // Precisa vir antes da super.ngOnInit

    super.ngOnInit();
  }

  createData(options?) {
    this.logger.debug('PointEditComponent.createData()');
    this.model = new InspectionPointModel();
    this.view = {};

    this.initializeFields();

    const startTimestamp = moment();
    this.view['creationDate'] = startTimestamp.format('DD/MM/yyyy HH:mm');
    this.view['source'] = 'Web';

    this.currentUserAsField('author');

    this.view['identifier'] = '[NOVO]';

    if (this.authorizationService.getLoggedUserType() === UserType.COORDINATOR_CCPD || this.authorizationService.getLoggedUserType() === UserType.COORDINATOR_OPPD){
      this.view['status'] = PointStatus.APPROVED;
    }
    else {
      this.view['status'] = PointStatus.IN_ANALYSIS;
    }
  }

  mapModelToView() {
    super.mapModelToView();

    this.logger.debug('PointEditComponent.mapModelToView()');

    if (this.model['creationDate']) {
      const createdTimestamp = moment(this.model['creationDate']);
      this.view['creationDate'] = createdTimestamp.format('DD/MM/yyyy HH:mm');
    }

    if (!this.model['source'])
      this.view['source'] = 'Web';

    if (!this.model['identifier'])
      this.view['identifier'] = "[NOVO]";

    if (this.model['km'] || this.model['km'] == 0) {
      this.view['km'] = FieldUtils.kmIntToString(this.model['km']);
    }

    if (this.copy){
      this.currentUserAsField('author');
    }

    this.glUpdateTabTitle(this.modelName + ': ' + this.view['identifier']);
  }

  mapViewToModel() {
    if (this.isSaving && this.isCreating())
      this.view['identifier'] = null;

    super.mapViewToModel();

    if (this.view['creationDate']) {
      const splitted = this.view['creationDate'].split(" ", 2);
      const createDateMiliseconds = DateUtils.stringDateTimeToTimestamp(splitted[0], splitted[1], true);
      this.model['creationDate'] = createDateMiliseconds;
    }

    if (this.view['km']) {
      this.model['km'] = FieldUtils.kmStringToInt(this.view['km']);
    }
  }

  onEnableEditClick() {
    if (this.canEdit()) {
      this.readOnly = false;
      
      if (!this.view['author']) {
        this.currentUserAsField('author');
      }
    }
  }

  applyDate(dateString: string) {
    this.startDate = moment(dateString, "DD/MM/yyyy");
  }

  dateChanged() {
    this.view['creationDate'] = this.startDate.format("DD/MM/yyyy");
  }

  loadFormOptionsData(){
    this.entityCacheService.loadUsers(this.loadingListService);

    const metadatas: string[] = [
      "State",
      "Band",
      "PipeStretch",
      "City"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  initializeFields(){
    // Inicializando campos
    this.view['creationDate'] = '';
  }

  isRequiredFieldFilled(): boolean {
    if (!this.view['band'] ||
        !this.view['km'] ||
        !this.view['latLong']) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['band']) names.push('Faixa');
    if (!this.view['km']) names.push('KM');
    if (!this.view['latLong']) names.push('Lat, Long');
    return names;
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['creationDate'] = moment().valueOf();
    this.model['source'] = 'Web';

    this.model['identifier'] = null;
    this.model['approve'] = null;

    if (this.authorizationService.getLoggedUserType() === UserType.COORDINATOR_CCPD || this.authorizationService.getLoggedUserType() === UserType.COORDINATOR_OPPD){
      this.model['status'] = PointStatus.APPROVED;
    }
    else {
      this.model['status'] = PointStatus.IN_ANALYSIS;
    }
  }

  isStatusButtonHiddenForUser(): boolean {
    return !this.authorizationService.isPointStatusEditingAllowed();
  }

  onStatusChange(status) {
    this.view['status'] = status;
    if (status == PointStatus.APPROVED) {
      this.currentUserAsField('approve');
    }
    else {
      this.view['approve'] = null;
    }
  }
  
  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário
    this.replaceModelViewDateData(data, 'creationDate');
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_POINT)){
      return false
    }

    return true;
  }
  
  onLocationClick(){
    this.glOpenContainer(MAP_PAGE, {inspectionPoint: this.view});
  }

  hasLocation(){
    if (this.view && this.view['latLong'])
      return true;
    return false;
  }

  onPasteLatLong(event: ClipboardEvent){
    let pastedValue = FieldUtils.pasteLatLong(event, this.view['latLong']);
    if (pastedValue != null) {
       this.view['latLong'] = pastedValue;
       return true;
    }
    return false;
  }
}
