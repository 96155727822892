import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { first } from 'rxjs/operators';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';
import { UserModel } from '../../model/user.model';

@Component({
  selector: 'notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit, OnDestroy {

  observations: string;
  
  loadingListService = new LoadingListService();

  constructor(private logger:                     NGXLogger,
    public dialogRef:                             MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:  any){}

  ngOnInit() {
    this.observations = this.data.successMessage + "\n" + this.data.errorMessage;
  }
  
  ngOnDestroy() {
  }

}
