<div mat-dialog-title>
    <span>Selecione um Evento</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div class="sipd-container">
  <div class="sipd-header">
    
    <span class="mat-title m-0">Eventos</span>
    <mat-form-field class="list-filter header-input" appearance="outline" style="margin-left: 300px;">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
    </mat-form-field>
  </div>
</div>

<mat-divider></mat-divider>
<div mat-dialog-content class="p-0">

      <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
            </mat-checkbox>
          </th>
          <td mat-cell class="txt-center fit-content" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="accent"
              (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
              [matTooltip]="checkboxTip(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="validation">
          <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header>
            Validação
          </th>
          <td mat-cell class="txt-center fit-content" *matCellDef="let element">
            <mat-icon class="align-middle" [ngClass]="[(element?.validation == 'COMPLETE')? 'txt-accent':(element?.validation == 'INCOMPLETE')? 'txt-warn':'text-emphasis-3']">
              check_circle_outline</mat-icon>
          </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="identifier">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell class="nowrap" *matCellDef="let element">
            {{element?.identifier}}
          </td>
        </ng-container>

        <!-- Coluna ocorrência -->
        <ng-container matColumnDef="result.occurrence">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Ocorrência </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{
            resultOccurrenceDescription[element?.result?.occurrence] }} </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.date | date:'dd/MM/yyyy HH:mm'}} </td>
        </ng-container>

        <!-- Analyst Column -->
        <ng-container matColumnDef="analyst.name">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Analista </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.analyst | toView }} </td>
        </ng-container>

        <!-- Channel Column -->
        <ng-container matColumnDef="communicationChannel">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Canal de Comunicação </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.communicationChannel }} </td>
        </ng-container>

        <!-- Source Column -->
        <ng-container matColumnDef="source">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Origem </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.source }} </td>
        </ng-container>

        <!-- Duto Column -->
        <ng-container matColumnDef="duct">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Duto </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.duct}} </td>
        </ng-container>

        <!-- Valve Column -->
        <ng-container matColumnDef="valve">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Válvula </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.valve}} </td>
        </ng-container>

        <!-- KM Column -->
        <ng-container matColumnDef="targetPointKM">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> KM </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.stretchStartKM && element?.stretchEndKM ?
            element?.stretchStartKM + " - " + element?.stretchEndKM : element?.targetPointKM}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
          (contextmenu)="openContextMenu($event, element)"
          [ngClass]="{ 'highlighted': selection.isSelected(row) }">
        </tr>

      </table>
      
</div>

<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onSelectClick()" [disabled]="!this.canSelectManyEvents()">Selecionar</button>
  <button mat-raised-button mat-dialog-close> Cancelar </button>
</mat-dialog-actions>
  