import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ChannelModel } from 'src/app/model/channel.model';
import { EntityModel } from 'src/app/model/entity.model';
import { environment } from 'src/environments/environment';
import { EntityService } from './entity.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { StorageService } from '../storage-service';
import { channelUnreadMessagesModel } from 'src/app/model/channelUnreadMessages.model';
import { UserUnreadMessagesModel } from 'src/app/model/userUnreadMessages.model';
import { EntityCacheService } from './entity.cache.service';
import { UserType } from 'src/app/model/enums.enum';

@Injectable({
  providedIn: 'root'
})
export class ChannelService extends EntityService {

  // A contagem de mensagens recebidas fica nessa lista em memória
  inMemoryChannelList: ChannelModel[];

  private unreadMessagesByChannel: channelUnreadMessagesModel[];

  private channelUpdated: Subject<ChannelModel> = new Subject();

  constructor(logger:                         NGXLogger,
              protected authorizationService: AuthorizationService,
              public entityCacheService:      EntityCacheService,
              protected storageService:       StorageService,
              httpClient:                     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/channels`);
    this.inMemoryChannelList = [];  // é preciso para quando a chamada da initializeInMemoryChannelList retorna após do ngInit
    this.unreadMessagesByChannel = [];
  }

  private notifyChannelUpdated(channel: ChannelModel) {
    this.channelUpdated.next(channel);
  }

  onChannelUpdated(): Observable<ChannelModel> {
    return this.channelUpdated.asObservable();
  }
  
  public setChannelUnreadMessages(channelId: string, unreadMessages: string) {
    const found = this.unreadMessagesByChannel.find((obj) => {
      return obj.channelId === channelId;
    });
    found.unreadMessages = unreadMessages;
    this.enrichChannelList();
  }

  public setUnreadMessagesdByChannel(userUnreadMessages: UserUnreadMessagesModel, channelId: string) {
    this.unreadMessagesByChannel.filter(elem => elem.channelId === channelId)
                                .map(elem => elem.unreadMessages = userUnreadMessages.unreadMessages);
  }

  public updateInMemoryChannels(unreadMessages: channelUnreadMessagesModel[]) {
    this.unreadMessagesByChannel = unreadMessages;
    this.enrichChannelList();
  }

  public getUnreadMessagesByUser(userId: string): Observable<any> {
    return this.http.get(`${environment.settings.markers_address}/marker/unread-messages/${userId}`, { responseType: 'json' });
  }

  public resetUnreadChannelMessagesdByUser(userid: string, profileId: string, channelId: string) {
    let unreadMessages = this.getUnreadMessagesInChannel(channelId);
    this.setUnreadMessagesInChannel(channelId, 0);
    this.resetUnreadMessagesByUser(userid, profileId, channelId, unreadMessages).pipe(first()).toPromise().then(() =>{
    }, err =>{
      this.logger.error(JSON.stringify(err));      
    });
  }

  public getUnreadMessagesInChannel(channelId: string) {
    const found = this.inMemoryChannelList.find((obj) => {
      return obj.id === channelId;
    });
    return found && found.newMessagesCount !== 0 ? found.newMessagesCount : 0;
  }

  public setUnreadMessagesInChannel(channelId: string, unreadMessages: number) {
    const found = this.inMemoryChannelList.find((obj) => {
      return obj.id === channelId;
    });
    
    // se o usuário tem acesso a esse canal
    if(found){
      found.newMessagesCount = unreadMessages;
    }    
  }

  isAnalystCCPDProfile(profileId: string): boolean{
    let allProfiles = this.entityCacheService.getAllProfiles();
    return !!allProfiles.find(p => p.id === profileId && p.userType === UserType.ANALYSIS_CCPD);
  }

  protected resetUnreadMessagesByUser(userId: string, profileId: string, channelId: string, unreadMessages: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('userId', userId);
    if (this.isAnalystCCPDProfile(profileId)) {
      params = params.set('profileId', profileId);
    }
    params = params.set('channelId', channelId);
    params = params.set('unreadMessages', unreadMessages.toString());
    return this.http.get(`${environment.settings.markers_address}/marker/unread-messages/reset`, { params: params, responseType: 'json' });
  }

  protected enrichChannel(channel: ChannelModel) {
    const found = this.unreadMessagesByChannel.find((obj) => {
      return obj.channelId === channel.id;
    });
    channel.newMessagesCount = found ? Number(found.unreadMessages) : 0;
    channel.lastMessageReceivedTimestamp = found ? found.lastMessageReceivedTimestamp : null;
  }

  public enrichChannelList() {
      this.inMemoryChannelList
          .map(elem => this.enrichChannel(elem));
  }

  public updateDisplayChannels(entities: EntityModel[]) {
    this.inMemoryChannelList.forEach((inMemoryChannel: ChannelModel) => {
      const updateChannel: ChannelModel = <ChannelModel>entities.find((channel: ChannelModel) => inMemoryChannel.id === channel.id);
      if (updateChannel) {
        updateChannel.lastMessageReceivedTimestamp = inMemoryChannel.lastMessageReceivedTimestamp ? inMemoryChannel.lastMessageReceivedTimestamp : 0;
        updateChannel.newMessagesCount = inMemoryChannel.newMessagesCount ? inMemoryChannel.newMessagesCount : 0;
      }
    });
  }

  // Chamado pelo AppComponent
  public updateUserUnreadMessages(channelId: string, userUnreadMessages: UserUnreadMessagesModel) {
    if (!channelId || !this.inMemoryChannelList) return;
    
    this.setUnreadMessagesdByChannel(userUnreadMessages, channelId);

    const foundChannel = this.inMemoryChannelList.find((channel: ChannelModel) => channel.id === channelId);
    if (foundChannel) {
      foundChannel.lastMessageReceivedTimestamp = userUnreadMessages.lastMessageReceivedTimestamp;
      foundChannel.newMessagesCount = Number(userUnreadMessages.unreadMessages);

      this.notifyChannelUpdated(foundChannel); // Notifica a lista de canais
    }
  }

  /**
   * Retorna um ID de canal com base em seu ID de referência (esse último pode ser ID de usuário ou de equipe, dependendo do tipo de canal).
   */
  public getChannelIdByReferenceId(referenceId: string): ChannelModel {
    const foundChannel = this.inMemoryChannelList.find((channel: ChannelModel) => channel.referencedId === referenceId);
    if (foundChannel) {
      return foundChannel;
    }

    return null;
  }

  /**
   * Retorna o número de canais com mensagens não lidas. Usado para atualizar o título da tab da janela de mensagens.
   */
  public channelCountWithNewMessages(): number {
    let count = 0;
    this.inMemoryChannelList.forEach((channel: ChannelModel) => {
      if (channel.newMessagesCount > 0) count++;
    });
    return count;
  }

  // Foram lidos canais do backend, apenas atualiza a lista (pode ser uma atualização parcial se o filtro estiver ativo)
  public updateChannels(channels: ChannelModel[]) {
    channels.forEach(channel => {
      let index = this.inMemoryChannelList.findIndex(old_channel => channel.id == old_channel.id);
      if (index != -1) {
        const found = this.unreadMessagesByChannel.find((item: channelUnreadMessagesModel) => channel.id === item.channelId);
        this.inMemoryChannelList[index] = channel;
        channel.lastMessageReceivedTimestamp = found ? found.lastMessageReceivedTimestamp : null;
        channel.newMessagesCount = found ? Number(found.unreadMessages) : 0;
      } else {
        this.inMemoryChannelList.push(channel);
        channel.lastMessageReceivedTimestamp = null;
        channel.newMessagesCount = 0;
      }
    });
  }

  setMessageData(messageData: ChannelModel[]) {
    this.inMemoryChannelList = messageData;
  }

  getMessageData() {
    return this.inMemoryChannelList;
  }
}
