// services
import {NGXLogger}  from 'ngx-logger';
import * as L from 'leaflet';
import {PatrolService} from './patrol.service';

// model
import {SignalModel} from '../../model/signal.model';
import {TrackingModel} from '../../model/tracking.model';
import {OperationType, SourceType} from '../../model/enums.enum';

import * as moment from 'moment';

import { API_VERSION_ENDPOINT, APP_SIGNAL_INVALID, Icons, LAST_OPERATION_SIGNALS, LAST_SIGNALS, LAST_SIGNALS_TRACKING_LIMIT, LAST_VEHICLE_SIGNALS, LOST_SIGNAL_LIMIT, MapInfo, TEAM_NOT_FOUND, TRACKING_PAGE, USER_NOT_FOUND } from '../../common/constants';
import { VerificationService } from 'src/app/service/model/verification.service';
import { EntityService } from 'src/app/service/model/entity.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as GoldenLayout from 'golden-layout';
import { FILL_DATA_PREFIX, MAP_PAGE } from 'src/app/common/constants';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { OperationModel } from '../../model/operation.model';
import { AbstractSignalModel } from 'src/app/model/abstract.signal.model';
import { VehicleSignalModel } from 'src/app/model/vehicle.signal.model';
import { EntityCacheService } from './entity.cache.service';
import { first } from 'rxjs/operators';
import { LoadingListService } from '../loading/loading-list.service';
import { CompanyModel } from 'src/app/model/company.model';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService  extends EntityService {
  private startedOperations: Map<string, OperationModel> = new Map<string, OperationModel>();

  private trackingMap: Map<string, TrackingModel> = new Map<string, TrackingModel>(); //key é mobileObjectId - contém a lista completa de tracking recebidos

  private eventHub: GoldenLayout.EventEmitter;

  private loadingLastSignals: boolean = false;
  private loadingLastVehicleSignals: boolean = false;

  private loadAll = 0;
  
  constructor(logger: NGXLogger,
              protected httpClient: HttpClient,
              private patrolService: PatrolService,
              private verificationService: VerificationService,
              public authorizationService: AuthorizationService,
              public entityCacheService: EntityCacheService){
    super(logger, httpClient, `${environment.settings.tracking_address}/tracking`);
  }
    
  isNewerSignal(signal: AbstractSignalModel, signalReceived: AbstractSignalModel): boolean {
    return signalReceived.timestamp > signal.timestamp;
  }

  setEventHub(eventHub: GoldenLayout.EventEmitter){
    this.eventHub = eventHub;
  }

  getTrackingList(): TrackingModel[] {
    return Array.from(this.trackingMap.values());
  }

  // Só pode ser chamada depois de autenticado
  initialize(){
    this.entityCacheService.loadUsers(null, () => {
      this.loadAll++;
      if (this.loadAll == 3) this.updateTrackings();
    });
    this.entityCacheService.loadPatrolTeams(null, () => {
      this.loadAll++;
      if (this.loadAll == 3) this.updateTrackings();
    });
    this.entityCacheService.loadVehicles(null, () => {
      this.loadAll++;
      if (this.loadAll == 3) this.updateTrackings();
    });

    this.loadLastSignals(null, null);
    this.loadLastVehicleSignals(null, null);
  }

  /** Recebe um objeto traking e verifica se o placement da equipe/veículo 
   * encontra-se na lista de placements que pertence o usuário perfil*/
  isPermittedPlacement(tracking: TrackingModel, specificPlacements: string[]):boolean{

    if (specificPlacements.length == 0) return true;
    if (this.authorizationService.isAdmin()) return true;

    // Se o usuário não tem acesso a todas as lotações, filtra só aquelas que pertence

    if(tracking.company && tracking.company.placement){
      return specificPlacements.includes(tracking.company.placement.id);  
    }
    else if (tracking.signal['placementId']) {
      return specificPlacements.includes(tracking.signal['placementId']);
    }
    else {
      return false; // Não administradores não veem se não possuirem a empresa definida
    }
  }

  /**
   * Verifica se já existe um tracking item no mapa para o mobileId do sinal recebido. Se não existir, mantem a flag do updateInfo = true
   * para criar uma nova entrada no mapa.
   * Se já existir, verifica se o sinal tem uma operação ou se o tipo da operação do sinal recebido é diferente da entrada no mapa existente.
   * Se uma dessas condições for verdadeira, também mantém a flag do updateInfo = true para atualizar
   * essas informações.
   *
   * Além disso, se existir a entrada do mobileId no mapa de objetos móveis, ele atualiza
   * o tracking com as novas coordenadas do sinal.
   */
  updateTracking(signalReceived: AbstractSignalModel, notify: boolean){
    let tracking: TrackingModel = this.trackingMap.get(signalReceived.mobileObjectId);
    if (!tracking) {
      tracking = new TrackingModel();
      tracking.signal = signalReceived;
      
      this.updateTrackingInfo(tracking);

      // Salva modificação e notifica o mapa
      this.trackingMap.set(tracking.signal.mobileObjectId, tracking);
      
      if (notify) { 
        this.notifyMap(tracking);
        this.notifyTrackingList();
      }
      return true;
    }

    let updateInfo: boolean = false;

    if(tracking.signal.sourceType === SourceType.MOBILE_APP) {
      if (!this.isNewerSignal(tracking.signal, signalReceived)) {
        return false; // Sinal antigo é ignorado sempre para o APP
      }
      else {
        updateInfo = true;
        tracking.signal = signalReceived;
      }
    }
    else { // VEHICLE
      let vehicleSignal: VehicleSignalModel = tracking.signal as VehicleSignalModel;
      let vehicleSignalReceived: VehicleSignalModel = signalReceived as VehicleSignalModel;

      if (!this.isNewerSignal(tracking.signal, signalReceived)) { // Sinal mais antigo chegou
        // mas no caso de veículo pode ser uma atualização enriquecida
        if (!vehicleSignal.enriched && vehicleSignalReceived.enriched) {
          // Mantenho os dados do sinal mais atual, e copio apenas o que foi enriquecido
          tracking.signal.teamId = vehicleSignalReceived.teamId;
          tracking.signal.operationId = vehicleSignalReceived.operationId;
          tracking.signal.operationType = vehicleSignalReceived.operationType;
          tracking.signal.operationStatus = vehicleSignalReceived.operationStatus;
          vehicleSignal.companyId = vehicleSignalReceived.companyId;
          vehicleSignal.placementId = vehicleSignalReceived.placementId;
          vehicleSignal.enriched = true; // same as vehicleSignalReceived.enriched
          updateInfo = true;
        }
        else {
          return false; // ignora se o recebido não estiver enriquecido, ou se o atual já estiver enriquecido (o recebido é mais antigo então prevalece o atual)
        }
      }
      else { // Sinal mais recente chegou
        // no caso de veículo, pode ser um sinal que ainda não está enriquecido
        if (vehicleSignal.enriched && !vehicleSignalReceived.enriched) {
          // Mantenho os dados do sinal recebido e copio o que já tinha sido enriquecido no sinal atual
          vehicleSignalReceived.teamId = tracking.signal.teamId;
          vehicleSignalReceived.operationId = tracking.signal.operationId;
          vehicleSignalReceived.operationType = tracking.signal.operationType;
          vehicleSignalReceived.operationStatus = tracking.signal.operationStatus;
          vehicleSignalReceived.companyId = vehicleSignal.companyId;
          vehicleSignalReceived.placementId = vehicleSignal.placementId;
          vehicleSignalReceived.enriched = true; // same as vehicleSignal.enriched
        }
        else{
          // Sinal atual não enriquecido ou sinal recebido enriquecido (o atual é mais antigo então prevalece o recebido)
          updateInfo = true;
        }

        tracking.signal = signalReceived;
      }
    }

    this.updateVehicleSignal(tracking);

    // otimização para evitar ter que atualizar dados dos sinais a todo momento TODO scuri - acho que isso não é mais necessário
    if(updateInfo){
      this.updateTrackingInfo(tracking);
    }

    if (notify) {
      this.notifyMap(tracking);
      this.notifyTrackingList();
    }

    return true;
  }

  private reportTrackingList(){
    if (this.loadAll < 3) return;

    let invalidCount = 0;
    this.trackingMap.forEach(tracking => {
      if (tracking.invalidInfo) {
        invalidCount++;
        
        const title = (SourceType.MOBILE_APP ? 'Profissional ' : 'Veículo ') + tracking.signal.mobileObjectId;
        this.logger.debug("TrackingService Item Inválido: " + title + " [" + tracking.invalidCode + "]");
      }
    });
    this.logger.debug("TrackingService Total de Itens Inválidos: " + invalidCount);
    this.logger.debug("TrackingService Total de Itens: " + this.trackingMap.size);
  }

  loadLastSignals(loadingListService: LoadingListService, loadAtMap: boolean) {
    if (!this.loadingLastSignals) {
      this.logger.debug("TrackingService.loadLastSignals");
      this.loadingLastSignals = true;
      loadingListService?.loadingOn();
      const trackLimit = environment.development? 7*24*LAST_SIGNALS_TRACKING_LIMIT: LAST_SIGNALS_TRACKING_LIMIT;
      const startDate = moment().subtract(trackLimit, 'minutes').valueOf(); // Now - interval
      this.getLastSignals(null, startDate, null).pipe(first()).subscribe(lastSignalsJSON => {
        let lastSignals:Array<any> = JSON.parse(lastSignalsJSON);
        this.logger.debug("loadLastSignals loaded= ", lastSignals.length);
        for(let i = 0; i < lastSignals.length; i++) {
          let tracking: TrackingModel = this.trackingMap.get(lastSignals[i].mobileObjectId);
          if(!tracking || tracking.signal.timestamp < lastSignals[i].timestamp){
            this.processLastSignal(lastSignals[i]);
          }
          if (tracking) {
            if (loadAtMap) tracking.removedFromMap = false;
            else tracking.removedFromTracking = false;
          }
        }

        // Atualiza lista depois que todos foram atualziados
        this.logger.debug("loadLastSignals end");
        this.reportTrackingList();
        this.notifyMapList();
        this.notifyTrackingList();
        this.loadingLastSignals = false;
        loadingListService?.loadingOff();
      },
      error => {
        this.logger.error(error);
        this.loadingLastSignals = false;
        loadingListService?.loadingOff();
      });
    }
  }

  private processLastSignal(lastSignal: any) { // LastSignalVO
    let signal: SignalModel = new SignalModel();
    signal.mobileObjectId = lastSignal.mobileObjectId;
    signal.timestamp = lastSignal.timestamp;
    signal.latitude = lastSignal.latitude;
    signal.longitude = lastSignal.longitude;
    signal.sourceType = lastSignal.sourceType;
    signal.userId = lastSignal.userId;
    signal.operationId = lastSignal.operationId;
    signal.operationType = lastSignal.operationType;
    signal.operationStatus = lastSignal.operationStatus;
    signal.receivedTimestamp = lastSignal.receivedTimestamp;
    signal.teamId = lastSignal.key.teamId; // teamId fica dentro da Key

    this.updateTracking(signal, false);
  }

  loadLastVehicleSignals(loadingListService: LoadingListService, loadAtMap: boolean) {
    if (!this.loadingLastVehicleSignals) {
      this.logger.debug("TrackingService.loadLastVehicleSignals");
      this.loadingLastVehicleSignals = true;
      loadingListService?.loadingOn();
      const trackLimit = environment.development? 7*24*LAST_SIGNALS_TRACKING_LIMIT: LAST_SIGNALS_TRACKING_LIMIT;
      const startDate = moment().subtract(trackLimit, 'minutes').valueOf(); // Now - interval
      this.getLastVehicleSignals(null, startDate, null).pipe(first()).subscribe(lastSignalsJSON => {
        let lastSignals:Array<VehicleSignalModel> = JSON.parse(lastSignalsJSON);
        this.logger.debug("loadLastVehicleSignals loaded= ", lastSignals.length);
        for(let i = 0; i < lastSignals.length; i++) {
          let tracking: TrackingModel = this.trackingMap.get(lastSignals[i].mobileObjectId);
          if(!tracking || tracking.signal.timestamp < lastSignals[i].timestamp){
            // Não preciso chamar processLastSignal para LastSignal de veículos porque duplicamos o mobileObjectId da Key na classe LastVehicleSignalVO
            this.updateTracking(lastSignals[i], false);
          }
          if (tracking) {
            if (loadAtMap) tracking.removedFromMap = false;
            else tracking.removedFromTracking = false;
          }
        }

        // Atualiza lista depois que todos foram atualziados
        this.logger.debug("loadLastVehicleSignals end");
        this.reportTrackingList();
        this.notifyMapList();
        this.notifyTrackingList();
        this.loadingLastVehicleSignals = false;
        loadingListService?.loadingOff();
      },
      error => {
        this.logger.error(error);
        this.loadingLastVehicleSignals = false;
        loadingListService?.loadingOff();
      });
    }
  }

  private notifyMap(tracking: TrackingModel){
    if (this.eventHub) this.eventHub.emit(FILL_DATA_PREFIX + MAP_PAGE, {tracking: tracking});
  } 

  private notifyTrackingList(){
    if (this.eventHub) this.eventHub.emit(FILL_DATA_PREFIX + TRACKING_PAGE, {updateFilter: true});
  }

  private notifyMapList(){
    let trackingList: TrackingModel[] = this.getTrackingList();
    if (this.eventHub) this.eventHub.emit(FILL_DATA_PREFIX + MAP_PAGE, {trackingList: trackingList});
  }

  private updateVehicleSignal(tracking: TrackingModel) {
    if(tracking.signal.sourceType === SourceType.MOBILE_APP) {
      if (tracking.patrolTeam && tracking.patrolTeam.vehicle) {
        this.trackingMap.forEach((t: TrackingModel) => {
          // Se é sinal de App, procura por sinal de veículo com mesma placa, e atualiza sinal do veículo
          if (t.signal.sourceType === SourceType.VEHICLE &&
              t.signal.mobileObjectId == tracking.patrolTeam.vehicle.plate){
            let vehicleSignal: VehicleSignalModel = t.signal as VehicleSignalModel;
            if (!vehicleSignal.enriched) { // apenas para sinais de veículo não enriquecidos
              vehicleSignal.teamId = tracking.signal.teamId;
              vehicleSignal.operationId = tracking.signal.operationId;
              vehicleSignal.operationType = tracking.signal.operationType;
              vehicleSignal.operationStatus = tracking.signal.operationStatus;
              t.operation = tracking.operation;
              t.patrolTeam = tracking.patrolTeam;
              t.user = tracking.user;
            }
          }
        });
      }
    }
    else{
      let vehicleSignal: VehicleSignalModel = tracking.signal as VehicleSignalModel;
      if (!vehicleSignal.enriched) { // apenas para sinais de veículo não enriquecidos
        this.trackingMap.forEach((t: TrackingModel) => {
          if (t.patrolTeam && t.patrolTeam.vehicle) {
            // Se é sinal de Veículo, procura por sinal de App com mesma placa
            if (t.signal.sourceType === SourceType.MOBILE_APP &&
                tracking.signal.mobileObjectId == t.patrolTeam.vehicle.plate){
              vehicleSignal.teamId = t.signal.teamId;
              vehicleSignal.operationId = t.signal.operationId;
              vehicleSignal.operationType = t.signal.operationType;
              vehicleSignal.operationStatus = t.signal.operationStatus;
              tracking.operation = t.operation;
              tracking.patrolTeam = t.patrolTeam;
              tracking.user = t.user;
            }
          }
        });
      }
    }
  }

  private getCompany(tracking: TrackingModel): CompanyModel{
    if (tracking.patrolTeam) {
      return tracking.patrolTeam.company;
    }
    if (tracking.signal.sourceType === SourceType.VEHICLE) {
      return tracking.vehicle?.company;
    }
    else {
      return tracking.user?.company;
    }
  }

  private updateTrackingInfo(tracking: TrackingModel){
    // Atualiza os modelos a partir dos Ids
    tracking.operation = tracking.signal.operationId? this.getOperation(tracking.signal.operationId, tracking.signal.operationType, tracking.signal.operationStatus): null;
    if(tracking.operation){
      tracking.operation.type = tracking.signal.operationType;
      tracking.patrolTeam = tracking.operation.patrolTeam;
      tracking.invalidInfo = false;
    }else{
      if (tracking.signal.teamId) {
        tracking.patrolTeam = this.entityCacheService.getPatrolTeamById(tracking.signal.teamId);
        if (!tracking.patrolTeam) {
          // Equipe não encontrada. 
          // Ou é um erro de cadastro (a equipe foi removida)
          // Ou não pode ser obtida por falta de permissão do usuário logado
          // Nesse caso temos que remover o tracking
          tracking.invalidInfo = true;
          tracking.invalidCode = TEAM_NOT_FOUND;
        }
        else {
          tracking.invalidInfo = false;
        }
      }
      else {
        tracking.patrolTeam = null; // Usuário sem equipe OK
        tracking.invalidInfo = false;
      }
    }

    if(tracking.signal.sourceType === SourceType.MOBILE_APP) {
      const signal: SignalModel = <SignalModel>tracking.signal;
      if (signal.userId) {
        tracking.user = this.entityCacheService.getUserById(signal.userId);
        if (!tracking.user) {
          // Usuário não encontrado. 
          // Ou é um erro de cadastro (o usuário foi removido)
          // Ou não pode ser obtido por falta de permissão do usuário logado
          // Nesse caso temos que remover o tracking
          tracking.invalidInfo = true;
          tracking.invalidCode = USER_NOT_FOUND;
        }
        else {
          tracking.invalidInfo = false;
        }
      }
      else {
        tracking.user = null; // Não pode não ter usuário
        tracking.invalidInfo = true;
        tracking.invalidCode = APP_SIGNAL_INVALID;
        this.logger.error("Sinal de App sem UserId");
      }
    }
    else {
      tracking.vehicle = this.entityCacheService.getVehicleByPlate(tracking.signal.mobileObjectId);
    }

    this.updateIcon(tracking);

    tracking.title = TrackingModel.getBaseTitle(tracking.signal.sourceType, tracking.signal.mobileObjectId, tracking.user);

    tracking.company = this.getCompany(tracking);
  }

  private updateTrackings() {
    // Atualiza a partir dele mesmo, pois dados de usuários e equipes terminaram de ser carregados
    this.trackingMap.forEach( (tracking: TrackingModel) => {
      if(tracking.signal){
        this.updateTrackingInfo(tracking);
      }
    });
    this.notifyMapList();
    this.notifyTrackingList();
  }

  /** Atualiza uma operação para quem já está associado à operação */
  updateOperation(operation: OperationModel, loaded: boolean) {
    this.trackingMap.forEach((tracking: TrackingModel) => {
      if(tracking.operation){
        if(tracking.operation.id && tracking.operation.id == operation.id) {
          tracking.operation = operation;
          if (!loaded) this.startedOperations.set(operation.id + operation.type, operation);
          this.notifyMap(tracking);
        }
      }
    });
  };

  /**
   * Verifica se o sinal de um objeto móvel foi perdido (se o último tiver sido recebido há mais tempo
   * do que o limite máximo)
   */
   private isTheSignalLost(signal: AbstractSignalModel){
    let signalMoment = moment(signal.timestamp);
    let currentDay = moment();
    var seconds = currentDay.diff(signalMoment, 'seconds');
    if(seconds > LOST_SIGNAL_LIMIT){
      return true;
    }
    return false;
  }

  /** Retorna o ícone apropriado de acordo com o tipo e o estado do objeto móvel (sinal online/offline) */
  private buildIconAccordingToSignal(signal: AbstractSignalModel) {
    let iconUrlValue = null;
    let isLostSignal: boolean = this.isTheSignalLost(signal);
    if (signal.sourceType == SourceType.VEHICLE) {
      if (signal.operationId) {
        if (signal.operationType == OperationType.PATROL) {
          iconUrlValue = (isLostSignal) ? Icons.ICON_URL_VEHICLE_PATROL_OFFLINE : Icons.ICON_URL_VEHICLE_PATROL;
        }
        else {
          iconUrlValue = (isLostSignal) ? Icons.ICON_URL_VEHICLE_VERIFICATION_OFFLINE : Icons.ICON_URL_VEHICLE_VERIFICATION;
        }
      }
      else {
        iconUrlValue = (isLostSignal) ? Icons.ICON_URL_VEHICLE_OFFLINE : Icons.ICON_URL_VEHICLE;
      }
    }
    else {
      if (signal.operationId) {
        if (signal.operationType == OperationType.PATROL) {
          iconUrlValue = (isLostSignal) ? Icons.ICON_URL_PROFESSIONAL_PATROL_OFFLINE : Icons.ICON_URL_PROFESSIONAL_PATROL;
        }
        else {
          iconUrlValue = (isLostSignal) ? Icons.ICON_URL_PROFESSIONAL_VERIFICATION_OFFLINE : Icons.ICON_URL_PROFESSIONAL_VERIFICATION;
        }
      }
      else {
        iconUrlValue = (isLostSignal) ? Icons.ICON_URL_PROFESSIONAL_OFFLINE : Icons.ICON_URL_PROFESSIONAL;
      }
    }
    const icon = L.icon({
      iconUrl: iconUrlValue,
      iconSize: [ MapInfo.TRACKING_ICON_SIZE, MapInfo.TRACKING_ICON_SIZE ],
      iconAnchor: [ MapInfo.TRACKING_ICON_SIZE/2, MapInfo.TRACKING_ICON_SIZE/2 ] // No centro do ícone
    });
    return icon;
  }

  updateIcon(tracking: TrackingModel){
    const icon = this.buildIconAccordingToSignal(tracking.signal);
    tracking.icon = icon;
  }

  /**
   * Tenta recuperar a ronda ou verificação de evento associada ao sinal recebido
   */
  private getOperation(operationId: string, operationType: string, operationStatus: string): OperationModel {
    if (!operationId) return null;

    let operation : OperationModel = this.startedOperations.get(operationId + operationType);
    if(operation){
      if (operationStatus) operation.status = operationStatus;
      return operation;
    }
    else{
      switch (operationType) {
        case (OperationType.PATROL): 
          this.patrolService.loadById(operationId).toPromise().then (op => {
            if (op) {
              op.type = operationType;
              this.startedOperations.set(operationId + operationType, op);
              this.updateOperation(op, true);
            }
          });
          break;
        case (OperationType.EVENT_VERIFICATION): 
          this.verificationService.loadById(operationId).toPromise().then (op => {
            if (op) {
              op.type = operationType;
              this.startedOperations.set(operationId + operationType, op);
              this.updateOperation(op, true);
            }
          });
          break;
      }
      return null;
    }
  }

  public loadFromServerByTeamId(teamId: string, startDate: number, endDate: number): Observable<SignalModel[]> {
    let httpParams = new HttpParams();
    if(startDate){
      const startDateTime = moment(startDate).format('x');
      httpParams = httpParams.set('startDate',  startDateTime);
    }
    if(endDate){
      const endDateTime = moment(endDate).format('x');
      httpParams = httpParams.set('endDate', endDateTime);
    }
    return this.httpClient.get<SignalModel[]>(this.apiUrl + '/' + teamId, { params: httpParams });
  }

  public loadFromServerByOperationIdAndOperationType(operationId: string, operationType: string,  startDate: number): Observable<SignalModel[]>{
    let httpParams = new HttpParams();
    if(startDate){
      const startDateTime = moment(startDate).format('x');
      httpParams = httpParams.set('startDate',  startDateTime);
    }
    return this.http.get<SignalModel[]>(this.apiUrl + `/${operationId}/${operationType}` , { params: httpParams });
  }

  public loadFromServerByMobileObjectId(mobileObjectId: string, sourceType: SourceType, patrolTeam:PatrolTeamModel, operation: OperationModel, startDate: number, endDate: number): Observable<AbstractSignalModel[]> {
    let httpParams = new HttpParams();
    if(startDate){
      const startDateTime = moment(startDate).format('x');
      httpParams = httpParams.set('startDate',  startDateTime);
    }
    if(endDate){
      const endDateTime = moment(endDate).format('x');
      httpParams = httpParams.set('endDate', endDateTime);
    }
    if (patrolTeam) {
      httpParams = httpParams.set('teamId', patrolTeam.id);
    }
    if (operation) {
      httpParams = httpParams.set('operationId', operation.id);
      httpParams = httpParams.set('operationType', operation.type);
    }

    if (sourceType == SourceType.VEHICLE)
      return this.httpClient.get<VehicleSignalModel[]>(this.apiUrl + '/vehicle/' + mobileObjectId, { params: httpParams });
    else
      return this.httpClient.get<SignalModel[]>(this.apiUrl + '/user/' + mobileObjectId, { params: httpParams });
  }

  /**
   * Obtem a versão do projeto tracking
   */
  public getServiceVersion(): Observable<any> {
    this.logger.debug(`${this.apiUrl}/${API_VERSION_ENDPOINT}`);
    return this.httpClient.get(`${this.apiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }

  /**
   * Obter os últimos sinais registrados para cada: teamId, operationId e operationType
   */
  private getLastSignals(teamId: string, startDate: number, endDate: number): Observable<string> {
    this.logger.debug(`${this.apiUrl}/${LAST_SIGNALS}`);
    let httpParams = new HttpParams();

    if(teamId){    // Não está sendo usado no momento. Hoje carregamos todos os last signals do intervalo de tempo.
      httpParams = httpParams.set('teamId', teamId);
    }

    if(startDate){
      const startDateTime = moment(startDate).format('x');
      httpParams = httpParams.set('startDate',  startDateTime);
    }

    if(endDate){    // Não está sendo usado no momento. 
      const endDateTime =  moment(endDate).format('x');
      httpParams = httpParams.set('endDate',  endDateTime);
    }

    return this.httpClient.get(`${this.apiUrl}/${LAST_SIGNALS}`, { params: httpParams, responseType: 'text' });
  }

  public getLastOperationSignals(operationType: string, startDate: number, endDate: number): Observable<string> {
    this.logger.debug(`${this.apiUrl}/${LAST_OPERATION_SIGNALS}`);
    let httpParams = new HttpParams();

    httpParams = httpParams.set('operationType',  operationType);

    if(startDate){
      const startDateTime = moment(startDate).format('x');
      httpParams = httpParams.set('startDate',  startDateTime);
    }

    if(endDate){
      const endDateTime =  moment(endDate).format('x');
      httpParams = httpParams.set('endDate',  endDateTime);
    }

    return this.httpClient.get(`${this.apiUrl}/${LAST_OPERATION_SIGNALS}`, { params: httpParams, responseType: 'text' });
  }

  /**
   * Obter os últimos sinais registrados para cada veículo
   */
   private getLastVehicleSignals(mobileObjectId: string, startDate: number, endDate: number): Observable<string> {
    this.logger.debug(`${this.apiUrl}/${LAST_VEHICLE_SIGNALS}`);
    let httpParams = new HttpParams();

    if(mobileObjectId){    // Não está sendo usado no momento. Hoje carregamos todos os last signals do intervalo de tempo.
      httpParams = httpParams.set('mobileObjectId',  mobileObjectId);
    }

    if(startDate){
      const startDateTime = moment(startDate).format('x');
      httpParams = httpParams.set('startDate',  startDateTime);
    }

    if(endDate){    // Não está sendo usado no momento. 
      const endDateTime =  moment(endDate).format('x');
      httpParams = httpParams.set('endDate',  endDateTime);
    }

    return this.httpClient.get(`${this.apiUrl}/${LAST_VEHICLE_SIGNALS}`, { params: httpParams, responseType: 'text' });
  }
}
