import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { KeyValue } from '@angular/common';
import { CrudType, EvidenceRequirement, EvidenceRequirementDescription, QuestionType, NodeTypeDescription } from 'src/app/model/enums.enum';
import { QuestionModel } from 'src/app/model/question.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FormService } from '../../../../service/model/form.service';
import { FormModel } from '../../../../model/form.model';

/** Classe usada para comunicação com o diálogo de CRUD de perguntas */
export class QuestionCrudData{
  formModel : FormModel;
  quizNode: QuestionModel;
  crudType: CrudType;

  constructor( crudType: CrudType){  
    this.crudType = crudType;    
  }
}


@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit, OnDestroy {
  /** Colunas exibidas na tabela de referências */
  public displayedColumns: string[];

  /** Cópia do nó da árvore a ser editado ou inserido */
  public formQuizNode: QuestionModel;

 
  /** ENUM */
  public filteredNodeType: QuestionType[];
  public crudType = CrudType;
  public nodeType = QuestionType;
  public nodeTypeDescription = NodeTypeDescription;
  public evidenceRequirement = EvidenceRequirement;
  public evidenceRequirementDescription = EvidenceRequirementDescription;
   
  constructor(private dialogRef: MatDialogRef<QuestionFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: QuestionCrudData,
              private logger:           NGXLogger,
              private formService:  FormService) 
              { }

  
  ngOnDestroy(): void {
    this.logger.info('**** QuestionFormComponent - ngOnDestroy()');
    
  } 
  ngOnInit(): void {
    this.logger.info('**** QuestionFormComponent - ngOnInit()');

    this.displayedColumns = ['select', 'label', 'item'];
    this.filteredNodeType = [QuestionType.YESNO, QuestionType.TEXT, QuestionType.NUMBER, QuestionType.ACTIVEINACTIVE, QuestionType.VIOLATEDCOMPLETE];    

    this.initializeForm();
  }

  /** Inicializa o formQuizNode (model em edição/inserção) */
  private initializeForm(){
    if(this.data.crudType == CrudType.ADD){
      this.formQuizNode = this.formService.createQuizNode('', QuestionType.YESNO);
    }else{
      if(!this.formQuizNode) this.formQuizNode = new QuestionModel();
      this.formService.copyQuizNode(this.data.quizNode, this.formQuizNode);
    }
  }


  /** Método de inserção ou edição de perguntas */
  upsertQuestion(){
    this.logger.debug('**** upsertQuestion(): Inserindo/Alterando uma pergunta');

    if(this.data.crudType == CrudType.EDIT){
      this.formService.editQuestion(this.data.quizNode, this.formQuizNode, this.data.formModel);
    }else{
      this.formService.addQuestion(this.data.quizNode, this.formQuizNode, this.data.formModel);
    }
    this.dialogRef.close(true);
  }

  /** Indica se algum campo obrigatório não foi preenchido */
  isMissingMandatoryField(): boolean {
    return (!this.formQuizNode.label.trim());
  }

  // Preserve original property order
  public originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }
}
