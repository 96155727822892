import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { PointFilterModel } from './point.filter.model';
import { Subscription } from 'rxjs';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { LoadingListService } from "src/app/service/loading/loading-list.service";

@Component({
  selector: 'app-point-filter-dialog',
  templateUrl: './point-filter-dialog.component.html',
  styleUrls: ['./point-filter-dialog.component.scss']
})
export class PointFilterDialogComponent implements OnInit, OnDestroy {

  bandSubscription: Subscription;
  pipeStretchSubscription: Subscription;
  
  loadingListService = new LoadingListService();

  constructor( protected logger:                                    NGXLogger,
               public singleDataCacheService:                       SingleDataCacheService,
               public dialogRef:                                    MatDialogRef<PointFilterDialogComponent>,
               public filterModel:                                  PointFilterModel,
               @Inject(MAT_DIALOG_DATA) public inMemoryFilterModel: PointFilterModel) {

  }

  ngOnInit(): void {
    this.loadFormOptionsData();

    this.filterModel = new PointFilterModel();
    this.updateFilterModel(this.inMemoryFilterModel, this.filterModel);
  }

  ngOnDestroy() {
    this.logger.debug('VerificationEditComponent.ngOnDestroy()');

    if(this.bandSubscription) this.bandSubscription.unsubscribe();
    if(this.pipeStretchSubscription) this.pipeStretchSubscription.unsubscribe();
    this.loadingListService.destroy();
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  loadFormOptionsData() {
    this.logger.debug('EventEditComponent.loadFormOptionsData()');

    const metadatas: string[] = [
      "Band",
      "PipeStretch"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  onFilter(){
    this.updateFilterModel(this.filterModel, this.inMemoryFilterModel);
    this.dialogRef.close(true);
  }

  /**
   * Atualiza o filtro em memória
   */
   updateFilterModel(sourceFilter: PointFilterModel, targetFilter: PointFilterModel){
    for(const key in sourceFilter){
      targetFilter[key] = sourceFilter[key];
    }
  }

  clearFilter(){
    this.filterModel.band    = undefined;
    this.filterModel.stretch = undefined;
    this.filterModel.startKm = undefined;
    this.filterModel.endKm   = undefined;
  }
}
