import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { API_VERSION_ENDPOINT } from 'src/app/common/constants';
import { EntityModel } from 'src/app/model/entity.model';
import { OperationType } from 'src/app/model/enums.enum';
import { OperationModel } from 'src/app/model/operation.model';
import { ArchiveService } from './archive.service';
import { OperationStatus } from '../../model/enums.enum';
import { AttachmentModel } from 'src/app/model/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class OperationService extends ArchiveService {

  constructor(logger: NGXLogger,
              http: HttpClient,
              apiUrl: string,
              private objectName: string) {
    super(logger, http, apiUrl);
  }

  public create(record: EntityModel, formElements: Map<string, object>) {
    this.logger.debug('OperationService.create()');
    const formData: FormData = super.buildFormData(this.objectName, record, formElements);
    return super.postAsFormData(this.apiUrl, formData);
  }

  public edit(record: EntityModel, formElements: Map<string, object>) {
    this.logger.debug('OperationService.edit()');
    const formData: FormData = super.buildFormData(this.objectName, record, formElements);
    return super.putAsFormData(this.apiUrl + `/${record.id}`, formData);
  }

  public editRecord<T extends EntityModel> (record: EntityModel): Observable<T> {
    const formData: FormData = super.buildFormData(this.objectName, record, new Map<string, object>());
    return super.putAsFormData(this.apiUrl + '/' + record.id, formData);
  }

  public loadById(id:string): Observable<OperationModel>{
    return this.http.get<OperationModel>(this.apiUrl + '/' + id);
  }

  /**
 * Obtem a versão do projeto patrol ou event-verifications
 */
  getServiceVersion(): Observable<any> {
    return this.http.get(`${this.apiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }

  protected fillOperationType<T extends EntityModel[]>(result : T, type: OperationType) : T{

    result.forEach((entity: EntityModel) => {
       (<OperationModel>entity).type = type;
    });

    return result;
  }

  /**
   * Obtem a lista de status não-concluído de uma operação.
   */
  public listActiveStatus() {
    return [OperationStatus.PLANNED, OperationStatus.STARTED, OperationStatus.PAUSED, OperationStatus.SENT];
  }

  public loadAttachmentFile(data : AttachmentModel) : Observable<any>{    
    let baseUrl = this.apiUrl+"/attachment-file";    
    return this.http.get(`${baseUrl}/${data.id}`, { responseType: 'blob' }).pipe(first(), map((blob) => {
      return this.blobToFile(blob, data.name);
    }));
  }

  private blobToFile = (blob: Blob, fileName:string): File => {
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(blob);
    let file = new File(arrayOfBlob, fileName);
    return file;
  }
}
