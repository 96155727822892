import * as L from 'leaflet';
import {PatrolTeamModel} from './patrolteam.model';
import { OperationModel } from 'src/app/model/operation.model';
import { UserModel } from './user.model';
import { AbstractSignalModel } from './abstract.signal.model';
import { SourceType } from './enums.enum';
import { VehicleSignalModel } from './vehicle.signal.model';
import FieldUtils from '../service/util/field-utils';
import * as moment from 'moment';
import { USER_NOT_FOUND } from '../common/constants';
import { VehicleModel } from './vehicle.model';
import { CompanyModel } from './company.model';

/**
 * Objeto móvel na tela de rastreamento. Pode ser um aplicativo ou o GPS de um veículo utilizado pelas equipes de vigilância.
 */
export class TrackingModel {
  /** Último sinal recebido pelo sistema deste objeto */
  public signal: AbstractSignalModel;  // O que define um tracking como único é o seu signal.mobileObjectId

  // Info - preenchido na Web

  /** Equipe a que pertence o objeto móvel */
  public patrolTeam: PatrolTeamModel;  // Pode ser null se for veículo ou profissional sem equipe

  /** Ronda ou Verificação de evento que esse objeto esteja executando */
  public operation: OperationModel; // Pode ser null, se equipe não tem operação

  /** Profissional obtido a partir do signal.mobileObjectId */
  public user: UserModel; // Pode ser null se for veículo

  /** Veículo obtido a partir do signal.mobileObjectId. Se tem equipe, é identico à patrolTeam.vehicle */
  public vehicle: VehicleModel;

  /** Se tem equipe é patrolTeam.company, senão é user.company ou vehicle.company */
  company: CompanyModel;

  title: string;

  // INFO

  /** Ícone para a representação gráfica deste objeto no mapa */
  public icon: L.Icon;

  public removedFromMap: boolean;
  public removedFromTracking: boolean;
  public invalidInfo: boolean; // tem teamId mas equipe não encontrada, ou tem userId mas usuário não encontrado
  public invalidCode;

  public static getTitle(sourceType: string, mobileObjectId: string, patrolTeam: PatrolTeamModel, user: UserModel, search: boolean = false) {
    return TrackingModel.getBaseTitle(sourceType, mobileObjectId, user, search) + ' - ' + (patrolTeam? patrolTeam.name: 'sem equipe');
  }

  public static getBaseTitle(sourceType: string, mobileObjectId: string, user: UserModel, search: boolean = false) {
    return sourceType == SourceType.MOBILE_APP ? ((user? (!search? UserModel.getTitle(user): user.name): USER_NOT_FOUND)): mobileObjectId;
  }

  public static getPopupContent(tracking: TrackingModel, search: boolean = false): string{
    const operation: OperationModel = tracking.operation;
    const patrolTeam: PatrolTeamModel = tracking.patrolTeam;
    const signal: AbstractSignalModel = tracking.signal;
    const user: UserModel = tracking.user;
    const title = TrackingModel.getBaseTitle(signal.sourceType, signal.mobileObjectId, user, search);
    let mainTitle = signal.sourceType == SourceType.MOBILE_APP ? 'Profissional' : 'Veículo';
    mainTitle += (patrolTeam)? " em Equipe": " sem Equipe";
    let timestamp = signal.receivedTimestamp? signal.receivedTimestamp: tracking.signal.timestamp;    
    return `<h5 style="text-align: center">${ mainTitle }</h5>
            <h6 style="text-align: center"><b>${ title }</b></h6>
            ${ tracking.company ? '<div> <b>Empresa: </b> ' + tracking.company.name + '</div>': ''}
            ${ operation ? OperationModel.getPopupContent(operation) : ''}
            ${ PatrolTeamModel.getPopupContent(patrolTeam, search) }
            ${ signal.sourceType == SourceType.VEHICLE ? VehicleSignalModel.getPopupContent(tracking.signal as VehicleSignalModel) : '' }
            <div> <b>Data/Hora:</b>  ${moment(signal.timestamp).format('DD/MM/YYYY HH:mm:ss') } </div>
            <div> <b>Lat, Long:</b>  ${!search? FieldUtils.coordToString(signal.latitude): ''},${!search? FieldUtils.coordToString(signal.longitude): ''} </div>
            <div> <b>Última Atualização (GPS):</b>  ${timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm:ss') : '' } </div>
           `;
  }

  public static getTooltipContent(tracking: TrackingModel): string{
    const signal: AbstractSignalModel = tracking.signal;
    return `<div style="text-align: center"> <b>${ tracking.title }</b></div>
            <div>${FieldUtils.coordToString(signal.latitude)},${FieldUtils.coordToString(signal.longitude)} </div>
            <div style="text-align: center"><b>${moment(signal.timestamp).format('DD/MM/YYYY HH:mm:ss') }<b></div>
           `;
  }
}
