import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { PlacementModel } from 'src/app/model/placement.model';
import { PlacementService } from 'src/app/service/model/placement.service';
import { environment } from 'src/environments/environment';
import { EditComponent } from '../../../edit-component';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { Permission } from 'src/app/model/enums.enum';

@Component({
  selector: 'app-placement-edit',
  templateUrl: './placement-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './placement-edit.component.scss']
})
export class PlacementEditComponent extends EditComponent {

  constructor(logger:                     NGXLogger,
              placementService:           PlacementService,
              dialog:                     MatDialog,
              toastr:                     ToastrService,
              protected storageService:   StorageService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, placementService, dialog, environment.PLACEMENT_MODEL_LABEL, environment.PLACEMENT_TITLE_LABEL, storageService,
          'placements-edit', environment.PLACEMENT_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
    this.logger.debug('PlacementditComponent.constructor()');
  }

  createData(options?) {
    this.logger.debug('PlacementditComponent.createData()');

    this.model = new PlacementModel();
    this.view = {};

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  mapModelToView() {
    super.mapModelToView();

    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['gs']) {
      return false;
    }
    return true;
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_PLACEMENT)){
      return false
    }

    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['gs']) names.push('Gerência');
    return names;
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['name'] = null;
  }
}
