import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EntityService } from './entity.service';
import { environment } from 'src/environments/environment';
import { EntityModel } from 'src/app/model/entity.model';
import { Observable } from 'rxjs';
import { DEFAULT_PAGE_INDEX } from 'src/app/common/constants';

@Injectable({
  providedIn: 'root'
})
export class OperationFormAnswersService extends EntityService {

  constructor(logger: NGXLogger, 
              httpClient: HttpClient) {
    super(logger, httpClient, `${environment.settings.patrols_address}/patrols`);
  }

  public loadAnswersFromRestApi(params: HttpParams, operationType : string) :  Observable<any>{
    let baseUrl; 
    if(operationType ==='PATROL')
      baseUrl = `${environment.settings.patrols_address}/patrols`
    else
      baseUrl = `${environment.settings.event_verifications_address}/verifications`

    return this.http.get<any>(baseUrl + '/operation-form-answers', {params : params});
  }
  
  public loadFileById(fileId: string, operationType : string): Observable<HttpResponse<Blob>>{
    let baseUrl; 
    if(operationType ==='PATROL')
      baseUrl = `${environment.settings.patrols_address}/patrols`
    else
      baseUrl = `${environment.settings.event_verifications_address}/verifications`

    return this.http.get(baseUrl + '/evidence/' + fileId, { observe: 'response', responseType: 'blob' });
  } 
}
