import { Observable, throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EntityModel } from '../../model/entity.model';
import { DEFAULT_PAGE_INDEX } from '../../common/constants';
import { FilterModel } from 'src/app/general/filter-component/filter.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export abstract class EntityService {

  protected readonly apiUrl: string;

  /**
   * @description Constructor abstracts information for future operations
   * @author dsgoncal, laigner
   */
  protected constructor(protected logger: NGXLogger, protected http: HttpClient, apiUrl: string) {
    this.apiUrl = apiUrl ? apiUrl : '';
  }

  /**
   * Método que retorna um Page<EntityModel> do backend, caso a configuração de paginação esteja ligada
   * Caso contrário, retorna uma lista
   */
  public loadFromRestApi<T extends EntityModel[]>( pageIndex?: number, pageSize?: number, sort?: string, params?: HttpParams ): Observable<T> {

    params = params ? params : new HttpParams();

    if (pageIndex != null && pageSize != null) {
      const pageSizeStr = pageSize ? pageSize.toString() : environment.defaultPageSize.toString();
      const pageIndexStr = pageIndex ? pageIndex.toString() : DEFAULT_PAGE_INDEX.toString();
      params = params.set('page', pageIndexStr).set('size', pageSizeStr);
      }

    if(sort){
      params = params.set('sort', sort);
    }

    return this.http.get<T>(this.apiUrl, { params : params });
  }

  /**
   * Método que retorna uma lista de Entidades do banco de dados
   * Trata o fato de alguns endpoints retornarem diretamente a lista
   * e outros retornarem um estrutura com dois elementos {first, second}
   * nesse caso a lista está no segundo elemento
   */
  public loadListFromRestApi(pageIndex?: number, pageSize?: number, sort?: string, params?: HttpParams): Observable<EntityModel[]> {
    return this.loadFromRestApi(pageIndex, pageSize, sort, params).pipe(map(entities => {
      if ( entities['first'] || entities['second'] ){
        return entities['second'];
      }else{
        return entities;
      }
    }));
  }

  // Precisa ser sobrescrita por quem vai ter filtro
  // Vai implementar a passagem de dados do filtro para os parametros http
  // Normalmente vai retornar super.loadFromRestApi(...)
  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: FilterModel, extraParams?: Map<string, string>): Observable<T>{
    return this.loadFromRestApi(pageIndex, pageSize, sort, null);
  }

  // Carrega uma lista com filtro e trata o retorno dos dois tipos (ver loadListFromRestApi)
  public loadFilteredListFromRestApi(pageIndex: number, pageSize: number, sort: string, filter: FilterModel): Observable<EntityModel[]> {
    return this.filteredLoadFromRestApi(pageIndex, pageSize, sort, filter).pipe(map(entities => {
      if ( entities['first'] || entities['second'] ){
        return entities['second'];
      }else{
        return entities;
      }
    }));
  }

  public loadFilteredRawListFromRestApi(pageIndex: number, pageSize: number, sort: string, filter: FilterModel): Observable<EntityModel[]> {
    return this.filteredLoadFromRestApi(pageIndex, pageSize, sort, filter);
  }

  /**
   * @description Abstract the code regarding building a formData
   * @author laigner
   */
  protected buildFormData(identifier: string, record: EntityModel, formElements: Map<string, object>){
    this.logger.debug('EntityService.buildFormData()');

    const formData: FormData = new FormData();
    if (formElements) {
      formElements.forEach((value: object, key: string) => {
        if (value instanceof File) {
          formData.append(key, value);
        }
      });
    }
    const objectModel = JSON.stringify(record);
    formData.append(identifier, objectModel);
    return formData;
  }

  /**
   * @description Abstract the code regarding a post with formData
   * @author laigner
   */
  protected postAsFormData<T extends EntityModel>(url: string, formData: FormData): Observable<T>{
    this.logger.debug('EntityService.postAsFormData()');
    return this.http.post<T>(url, formData, {reportProgress: true, responseType: 'json'});
  }

  /**
   * @description Abstract the code regarding a put with formData
   * @author laigner
   */
  protected putAsFormData<T extends EntityModel>(url: string, formData: FormData): Observable<T>{
    this.logger.debug('EntityService.putAsFormData()');
    return this.http.put<T>(url, formData, {reportProgress: true, responseType: 'json'});
  }

  public createRecord<T extends EntityModel> (record: EntityModel, extraParams?: Map<string, string>): Observable<T> {
    this.logger.debug('EntityService.createRecord()');
    this.logger.debug('Entidade a ser salva: ', record);
    return this.http.post<T>(this.apiUrl, record);
  }

  public editRecord<T extends EntityModel> (record: EntityModel, extraParams?: Map<string, string>): Observable<T> {
    this.logger.debug('EntityService.editRecord()');
    return this.http.put<T>(this.apiUrl + '/' + record.id, record);
  }

  public delete(record: EntityModel, extraParams?: Map<string, string>) {
    this.logger.debug('EntityService.delete()');
    return this.http.delete(this.apiUrl + '/' + record.id);
  }

  public getRecord<T extends EntityModel>(id: string, extraParams?: Map<string, string>): Observable<T> {
    return this.http.get<T>(this.apiUrl + '/' + id);
  }

  protected handleError(error: HttpErrorResponse | any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.logger.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
