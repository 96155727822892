import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { SingleDataService } from 'src/app/service/model/single.data.service';

import { SingleDataModel } from '../../model/singleData.model';
import { LoadingListService } from '../../service/loading/loading-list.service';
import { StorageService } from '../../service/storage-service';

@Component({
  selector: 'app-import-geo-kml-dialog',
  templateUrl: './import-geo-kml-dialog.component.html',
  styleUrls: ['./import-geo-kml-dialog.component.scss']
})
export class ImportGeoKmlDialogComponent implements OnInit, OnDestroy {

  filename: string;
  kmlFile: File;

  uploadReplaceMode: boolean;
  validFilename: boolean;
  observations: string;

  loadingListService: LoadingListService = new LoadingListService();

  constructor(private logger:                     NGXLogger,
    public dialogRef:                             MatDialogRef<ImportGeoKmlDialogComponent>,
    public toastr:                                ToastrService,
    private singleDataService:                    SingleDataService,
    private storageService:                       StorageService,
    @Inject(MAT_DIALOG_DATA) public dataType:     string){}

  ngOnInit() {
    this.logger.debug(this.dataType);
    this.uploadReplaceMode = false;
  }

  ngOnDestroy() {
    this.loadingListService.destroy();
  }

  selectFile(file: File) {
    let fileSize = undefined;
    if (file) {  // Usuário selecionou um arquivo
      this.kmlFile = file;
      this.filename = file.name;
      fileSize = this.kmlFile.size/(1024*1024);
    }  
    else {  // Usuário pressionou Remover
      this.kmlFile = undefined;
      this.filename = undefined;
    }
    var fileExt = this.filename.split('.').pop();
    this.validFilename = (fileExt === "kml") ? true : false;
    if (this.validFilename) {
      this.observations = "";
      this.validFilename = fileSize <= 20;
      if (!this.validFilename) {
        this.observations = "O Arquivo deve ter no máximo 20 MB";
      }
    }
    else {
      this.observations = 'O arquivo selecionado dever ter extensão "KML"';
    }
}

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  loadingOn() {    
    this.loadingListService.loadingOn();
  }

  loadingOff() {    
    this.loadingListService.loadingOff();
  }
 
  select() {
    this.logger.debug('ImportGeoKmlDialogComponent.select()');
    this.loadingOn();

    this.singleDataService.importKml(this.dataType, this.uploadReplaceMode, this.kmlFile).pipe(first()).subscribe( errors => {
      let jsonData = JSON.stringify(errors);
      const obj = JSON.parse(jsonData);
      if (obj) {
        var first: boolean = true;
        for(var id in obj) {
          if (first) {
            this.observations += "Alguns elementos não puderam ser importados. Elementos com problema:\n";
            first = false;
          }
          if (id == "---") {
            this.observations += "Avisos:\n";  
              for(var i=0; i<obj[id].length; i++) {
                this.observations += "      Atenção : " + obj[id][i] + "\n";
            }
          }
          else {
            this.observations += "Placemark ID : " + id + "\n";  
              for(var i=0; i<obj[id].length; i++) {
                this.observations += "      Erro : " + obj[id][i] + "\n";
            }
          }
        }
      }
      else {
        this.dialogRef.close(true);
      }
      this.toastr.info("Dados salvos no Banco de Dados");
      this.loadingOff();
    })
  }

  restore(){
    this.logger.debug('ImportGeoKmlDialogComponent.restore()');

    let extraParams = new Map();
    extraParams.set("metadata", this.dataType);

    this.singleDataService.restore(this.storageService.getLocalUser(), extraParams).pipe(first()).subscribe(( entity: SingleDataModel ) => {
      this.toastr.info("Dados restaurados com sucesso");
      this.dialogRef.close(entity);
    });
  };
}
