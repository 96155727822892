import { Component, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { AlertModel } from 'src/app/model/alert.model';
import { Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import tokml from '../../general/tokml';

import { BaseGoldenPanel } from '../base-golden-panel/base-golden-panel';
import { TrackingModel } from 'src/app/model/tracking.model';
import { LayerFilterModel } from 'src/app/model/layer.filter.model';
import { FILL_DATA_PREFIX, PAGE_MAXIMIZED_PREFIX, MAP_PAGE, PAGE_READY_PREFIX, UPDATE_DATA_PREFIX, FILTER_OPTION_ALL, 
         LOCATION_UPDATE_PREFIX, SAVE_DATA_PREFIX, SORT_RECEIVED_SERVER_TIMESTAMP_DESC, USER_NOT_FOUND, SIGNAL_ICON_UPDATE_INTERVAL, SHIFT_DURATION, MAX_TIME_OF_TRACE } from 'src/app/common/constants';
import { MapInfo, Icons, LatLongMask, LatLongPattern } from 'src/app/common/constants';
import { StorageService } from '../../service/storage-service';
import { environment } from 'src/environments/environment';
import * as turf from '@turf/turf'
import { LoadingListService } from '../../service/loading/loading-list.service';

import DateUtils from 'src/app/service/util/date-utils';

import * as GoldenLayout from 'golden-layout';
import * as L from 'leaflet';
import 'leaflet-polylinedecorator';
import * as moment from 'moment';

import { OperationTypeDescription, SourceType, MapEvents, UserType, ObservedAreaStatus, EventStatusDescription,
         ObservedAreaStatusDescription, Permission, MarkerType } from 'src/app/model/enums.enum';
import { TrackingService } from 'src/app/service/model/tracking.service';
import { OperationModel } from 'src/app/model/operation.model';
import { RouteGeographicalService } from 'src/app/service/model/route-geographical-service';
import { BaseMapComponent } from 'src/app/general/base-map/base-map.component';
import {PatrolTeamModel} from 'src/app/model/patrolteam.model';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { InspectionPointModel } from '../../model/inspection.point.model';
import FieldUtils from '../../service/util/field-utils';
import { InspectionModel } from '../../model/inspection.model';
import { EventModel } from '../../model/event.model';

import * as esri_auth from '@esri/arcgis-rest-auth';
import { DcModel } from '../../model/dc.model';
import { UserModel } from 'src/app/model/user.model';
import { AbstractSignalModel } from 'src/app/model/abstract.signal.model';
import { ObservedAreaModel } from 'src/app/model/observed.area.model';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EventListDialogComponent } from '../../general/operation/event-list-dialog/event-list-dialog.component';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { MarkerModel } from '../../model/marker.model';
import { MarkersService } from '../../service/model/markers.service';
import { MarkerFilterModel } from '../../general/message/marker.filter.model';
import { MatSliderChange } from '@angular/material/slider';
import { SignalModel } from '../../model/signal.model';
import { EventFilterModel } from '../event/event-filter/event.filter.model';
import { BandModel } from '../../model/band.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { VehicleModel } from 'src/app/model/vehicle.model';
import { TrackPointsDialogComponent } from 'src/app/general/track-points-dialog/track-points-dialog.component';
import { VehicleSignalModel } from 'src/app/model/vehicle.signal.model';
import { GeoModel } from 'src/app/model/geo.model';
import { OperationStatus } from '../../model/enums.enum';
import { CompanyModel } from 'src/app/model/company.model';

// TODO scuri Menu = 64, mas não sei exatamente de onde vem esse valor
// TODO scuri Quando o GoldenLayout contabilizar corretamente o Menu, talvez não precisemos ter 2 definições
const HEADER_VERTICAL_SPACE = 56+1; // pixels (Title) (spid-header=40(ícone)+8+8(padding))
const MAX_HEADER_VERTICAL_SPACE = 64+28+56; // pixels (Menu+Tab+Title) (ver Tab=IExtendedGoldenLayoutConfig.dimensions.headerHeight=28 em AppComponent)
const SIDE_HORIZONTAL_SPACE = 350; // pixels (Side - ver HTML #sidenav.width)

const authentication = new esri_auth.UserSession({
  token: MapInfo.ARCGIS_TOKEN
})

export enum FilterOption{
  YEAR = 'YEAR',
  STATE = 'STATE',
  BAND = 'BAND',
  AREA_STATUS = 'AREA_STATUS',
  AREA_RESPONSIBLE = 'AREA_RESPONSIBLE',
  AREA_ID = 'AREA_ID'
}

export class AbstractMapObject {
  visible: boolean = true;
  listed: boolean = true;
  popupContent: string;
}

export class AlertMapObject extends AbstractMapObject {
  // Modelo
  alert: AlertModel;

  // Objetos do Mapa
  marker: L.Marker;
}

export class InspectionPointMapObject extends AbstractMapObject {
  // Modelo
  inspectionPoint: InspectionPointModel;

  // Objetos do Mapa
  marker: L.Marker;
}

export class TrackingMapObject extends AbstractMapObject {
  // Rastreamento de profissionais e veículos sem equipe

  // Modelo
  tracking: TrackingModel;

  // Objetos do Mapa (fica dentro do TrackingModel)
}

export class TeamTrackingMapObject extends AbstractMapObject {
  // Rastreamento de Equipes

  // Modelo
  trackings: TrackingModel [] = [];

  // Repete para não ter que buscar no modelo
  patrolTeam: PatrolTeamModel;

  // Objetos do Mapa (fica dentro do TrackingModel)
}

export class EventMapObject extends AbstractMapObject {
  // Modelo
  event: EventModel;

  // Objetos do Mapa
  marker: L.Marker;
  line: L.Polyline;
}

export class OperationMapObject extends AbstractMapObject {
  // Modelo
  operation: OperationModel;
  fileKmlRoute: File;
  removedKmlRoute: boolean;

  // Objetos do Mapa
  geoRoute: L.GeoJSON;
  geoPoints: L.GeoJSON;
  geoRouteBounds: L.LatLngBounds;
  geoPointsBounds: L.LatLngBounds;
}

export class EditionModeObject {
  operation: OperationModel;
  fileKmlRoute: File;
  removedKmlRoute: boolean;
  inspections: InspectionModel[];

  wasVisible: boolean;
  autoRoute : boolean;

  glassPanel: GlassPanelObject;
  OperationId: string;
}

export class GlassPanelObject {
  geoRectangle : L.Rectangle;
  constructor(){
    var corner1 = L.latLng(-90, -360),
    corner2 = L.latLng(90, 360),
    bounds = L.latLngBounds(corner1, corner2);
    this.geoRectangle = L.rectangle(bounds, {color:'#AAAAAA', fillOpacity: MapInfo.GLASS_PANEL_OPACITY, stroke: false });
  };
}

const TIME_FORMAT = 'HH:mm:ss';
const DATE_FORMAT = 'DD/MM/YYYY';

export class HistoricalTrackingObject extends AbstractMapObject {
  // Rastro pode ser de um usuário ou de um veículo,
  // que podem estar em uma operação, ou apenas em uma equipe, ou isolados
  // O rastro de um usuário ou veículo isolado inclui todos os rastros que participou, com e sem operações
  // Casos:
  // 1- usuário de equipe com operação
  // 2- veículo de equipe com operação
  // 3- usuário de equipe  (sem operação)
  // 4- veículo de equipe  (sem operação)
  // 5- usuário sem equipe
  // 6- veículo sem equipe
  // Origens:
  // 1- Operação (gera um rastro para cada usuário da equipe e para o veículo, mas o sinal tem que ter a operação, ignora data)
  // 2- Equipe (gera um rastro para cada usuário da equipe e para o veículo, mas pode incluir várias operações, definido pela data)
  // 3- Usuário (um único rastro para aquele usuário, definido pela data)
  // 4 - Veículo (um único rastro para aquele veículo, definido pela data)
  // Data default:
  // Início = Now - Shift
  // Fim = = Now

  // Modelo
  mobileObjectId: string; // Identificador do Usuário para o App (userId) ou Placa para o Veículo (plate)
  sourceType: SourceType; // Indica se é App ou Veículo
  user: UserModel; // Se não é de um usuário é null
  vehicle: VehicleModel; // Se não é de um veículo é null
  patrolTeam: PatrolTeamModel; // Se não tem equipe é null
  operation: OperationModel; // Se não tem uma operação é null

  // Lista de marcadores da operação
  markers: MarkerModel [];

  //////////////////
  // Objetos do Mapa

  /** Linha do rastro desenhada no mapa */
  trackingLine: L.Polyline;
  trackingDecorator: L.PolylineDecorator;
  timeMarkers: L.CircleMarker [];
  timeVisible: boolean = true;
  stateMarkers: L.Marker [];
  stateVisible: boolean = true;

  //////////////////
  // Controle de leitura

  firstDate:  string; // Somente o dia
  firstDateInvalid: boolean;
  firstTime:  string; // Somente a hora
  firstTimeInvalid: boolean;
  firstTimestamp: number;

  lastDate: string;
  lastDateInvalid: boolean;
  lastTime: string;
  lastTimeInvalid: boolean;
  lastTimestamp: number;

  //////////////////
  // Controle de Interação

  // Timestamps são usados nos sliders, Date/Time são usados nos campos de edição de data e hora

  startDate:  string; // Somente o dia
  startDateInvalid: boolean;
  startTime:  string; // Somente a hora
  startTimeInvalid: boolean;
  startTimestamp: number;

  finishDate: string;
  finishDateInvalid: boolean;
  finishTime: string;
  finishTimeInvalid: boolean;
  finishTimestamp: number;
  finishChanged: boolean = false;

  //////////////////
  // Sinais Carregados

  /** Cache em memória de todos os sinais históricos recebidos da ronda ou verificação */
  signals : AbstractSignalModel[] = [];

  /** data/hora do último sinal */
  lastSignalTimestamp : number;

  /** data/hora do primeiro sinal */
  firstSignalTimestamp : number;

  // Outros
  accumDistance: number = 0;
}


@ProfileClassToConsole()
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends BaseGoldenPanel implements OnInit, OnDestroy, AfterViewInit {
  title = environment.MAP_TITLE_LABEL;
  tabTitle = environment.MAP_TITLE_LABEL;

  loadingListService: LoadingListService = new LoadingListService();

  @ViewChild('sidenav') sidenav: MatSidenav;
  openedSideList: boolean = false;

  @ViewChild('sidenavcontent') sidenavcontent: MatSidenavContent;
  
  searchValue: string;
  searchTimeOut;
  layersSearchCount: number;
  lastSearchResult;

  // Usados no baseMap
  mapStyle  : any;

  @ViewChild(BaseMapComponent)
  protected baseMap: BaseMapComponent;

  // Marcador manual

  locationMarkerLatLong : string = undefined;
  locationMarker  : L.Marker;

   /**
   * ####### Edition Mode
   */
  editionMode : EditionModeObject;


  /**
   * ####### Tracking
   */
  trackingObjectsMap: Map<string, TrackingMapObject> = new Map<string, TrackingMapObject>();
  trackingsVisible = { MOBILE_APP:  true, VEHICLE: true};
  trackingsListedCount = { MOBILE_APP: 0, VEHICLE: 0};
  trackingsCount = { MOBILE_APP:  0, VEHICLE: 0};
  trackingsExpanded = { MOBILE_APP:  false, VEHICLE: false};

  /**
   * ####### Team Tracking
   */
  teamTrackingObjectsMap: Map<string, TeamTrackingMapObject> = new Map<string, TeamTrackingMapObject>();
  teamTrackingsVisible = true;
  teamTrackingsListedCount = 0;
  teamTrackingsCount = 0;
  teamTrackingsExpanded = false;

  private lostSignalStatusCheckInterval: NodeJS.Timeout;

  /**
   * ####### ALERT
   */
  alertObjectsMap: Map<string, AlertMapObject> = new Map<string, AlertMapObject>();
  alertsVisible: boolean = true;
  alertsListedCount: number = 0;
  alertsExpanded: boolean = false;

  private alertIcon = L.icon({
    iconUrl: Icons.ICON_URL_ALERT,
    iconSize: [ MapInfo.ICON_SIZE, MapInfo.ICON_SIZE ],
    iconAnchor: [ MapInfo.ICON_SIZE/2, MapInfo.ICON_SIZE ]  // no centro da base do ícone
 });


  /**
   * ####### InspectionPoint
   */
  pointObjectsMap: Map<string, InspectionPointMapObject> = new Map<string, InspectionPointMapObject>();
  pointsVisible: boolean = true;
  pointsListedCount: number = 0;
  pointsExpanded: boolean = false;

  private pointIcon = L.icon({
     iconUrl: Icons.ICON_URL_POINT,
     iconSize: [ MapInfo.ICON_SIZE, MapInfo.ICON_SIZE ],
     iconAnchor: [ MapInfo.ICON_SIZE/2, MapInfo.ICON_SIZE ]  // no centro da base do ícone
  });


  /**
   * ####### EVENT
   */
  eventObjectsMap: Map<string, EventMapObject> = new Map<string, EventMapObject>();
  eventsVisible: boolean = true;
  eventsListedCount: number = 0;
  eventsExpanded: boolean = false;

  private eventIcon = L.icon({
     iconUrl: Icons.ICON_URL_EVENT,
     iconSize: [ MapInfo.ICON_SIZE, MapInfo.ICON_SIZE ],
     iconAnchor: [ MapInfo.ICON_SIZE/2, MapInfo.ICON_SIZE ]  // no centro da base do ícone
   });


  /**
   * ####### HISTORICAL TRACKING
   */
  historicalTrackingMap: Map<string, HistoricalTrackingObject> = new Map<string, HistoricalTrackingObject>();
  historicalTrackingsVisible: boolean = true;
  historicalTrackingsListedCount: number = 0;
  historicalTrackingsExpanded: boolean = false;

  private onNewMarkerSubscription: Subscription;

  private markerIcon = L.icon({
    iconUrl: Icons.ICON_URL_STATE_MARKER,
    iconSize: [ MapInfo.ROUTE_ICON_SIZE, MapInfo.ROUTE_ICON_SIZE ],
    iconAnchor: [ MapInfo.ROUTE_ICON_SIZE/2, MapInfo.ROUTE_ICON_SIZE ]  // no centro da base do ícone
 });

  /**
   * ####### Operations
   */
  operationObjectsMap : Map<string, OperationMapObject> = new Map<string, OperationMapObject>();
  operationsVisible = {'PATROL':  true, 'EVENT_VERIFICATION': true};
  operationsListedCount = {'PATROL':  0, 'EVENT_VERIFICATION': 0};
  operationsCount = {'PATROL':  0, 'EVENT_VERIFICATION': 0};
  operationsExpanded = {'PATROL':  false, 'EVENT_VERIFICATION': false};

  /**
   * ####### Highlight
   */
  highlight: L.Layer;
  highlightLine: L.Layer;

  /**
   * ####### Layers
   */
  // Key= layerId, value=layer com todos os dados da camada
  layersMap: Map<string, L.LayerGroup> = new Map<string, L.LayerGroup>();
  layersVisible: boolean = true;
  layersExpanded: boolean = false;

  layersSearchMap: Map<string, GeoModel[]> = new Map<string, GeoModel[]>();
  layersSearchExpanded: boolean[] = [];

  // ####### Filtro de Camadas

  /** filtro usado no base map */
  layersFilter: LayerFilterModel = new LayerFilterModel();

  allDcYears: string[] = []; // Todos os anos que possuem DCs

  allBands: BandModel[] = []; // Todas as faixas
  bandsFiltered: BandModel[] = []; // conteúdo da lista de faixas filtradas por estado

  allResponsibles: UserModel[] = []; // conteúdo da lista de Responsáveis
  allObservedAreas: ObservedAreaModel[] = [];  // Todas as Áreas Observadas
  areasFiltered: ObservedAreaModel[] = []; // conteúdo da lista de Áreas Observadas filtrada por Estados, Faixas, Status e Responsável

  lastSavedArea: ObservedAreaModel;

  // ####### Enums used in HTML

  operationTypeDescription = OperationTypeDescription;
  latLongPattern = LatLongPattern;
  latLongMask = LatLongMask;
  permission = Permission;
  eventStatusDescription = EventStatusDescription;
  sourceType: SourceType;
  filterOption = FilterOption;
  filterOptionALL = FILTER_OPTION_ALL;
  areaStatus = ObservedAreaStatus;
  areaStatusDescriptions = ObservedAreaStatusDescription;

  kmIntToString = FieldUtils.kmIntToString;

  private reloadUsersSubscription: Subscription;

  constructor(public logger:                          NGXLogger,
              private trackingService:                TrackingService,  // Somente usado para carregar dados históricos
              private routeGeographicalObjectService: RouteGeographicalService,
              private markerService:                  MarkersService,
              private storageService:                 StorageService,
              private toastr:                         ToastrService,
              protected dialog:                       MatDialog,
              private entityCacheService:             EntityCacheService,
              public singleDataCacheService:          SingleDataCacheService,
              protected changeDetector:               ChangeDetectorRef,
              public authorizationService:            AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayoutComponent: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected goldenLayoutContainer: GoldenLayout.Container) {
    super(logger, goldenLayoutComponent, goldenLayoutContainer);
  }

  ngOnInit(){
    this.logger.debug("MapComponent.ngOnInit()");
    this.layersFilter = new LayerFilterModel();
    
    this.loadFormOptionsData();

    this.glUpdateTabTitle(this.tabTitle);
    this.loadFromCache();

    this.subscribeOnFillData();
    this.subscribeOnPageMaximizedEvent();
    this.subscribeOnModelUpdate();
    this.subscribeToSaveEdit();
    this.subscribeToNewMarkersNotifications();
    this.subscribeOnLostSignalStatusCheck();

    this.editionMode = null;
  }

  ngOnDestroy(){
    this.glUnSubscribeEvent(FILL_DATA_PREFIX + MAP_PAGE);
    this.glUnSubscribeEvent(PAGE_MAXIMIZED_PREFIX + MAP_PAGE);
    this.glUnSubscribeEvent(SAVE_DATA_PREFIX + 'patrols-edit');
    this.glUnSubscribeEvent(SAVE_DATA_PREFIX + 'verifications-edit');
    this.glUnSubscribeEvent(SAVE_DATA_PREFIX + 'events-edit');
    this.glUnSubscribeEvent(SAVE_DATA_PREFIX + 'points-edit');
    this.glUnSubscribeEvent(SAVE_DATA_PREFIX + 'observed-areas-edit');
    this.glUnSubscribeEvent(UPDATE_DATA_PREFIX + 'patrols-edit');
    this.glUnSubscribeEvent(UPDATE_DATA_PREFIX + 'verifications-edit');
   
    if (this.editionMode) this.glEmitEvent(LOCATION_UPDATE_PREFIX + 'operation-edit', {id: this.editionMode.OperationId, clearEditionMode: true});

    this.onNewMarkerSubscription?.unsubscribe();
    this.reloadUsersSubscription?.unsubscribe();

    if (this.searchTimeOut) {clearTimeout(this.searchTimeOut); this.searchTimeOut = null;}
    if (this.lostSignalStatusCheckInterval) clearInterval(this.lostSignalStatusCheckInterval);

    this.loadingListService.destroy();
  }

  ngAfterViewInit() {
    this.logger.debug("MapComponent.ngAfterViewInit");
    this.baseMap.initializeLayers(this.layersMap);

    setTimeout(() => {

      // Feito um tempo depois porque o mapa demora para inicializar
      this.glEmitEvent(PAGE_READY_PREFIX + MAP_PAGE, null);

      // Para forçar uma leitura dos últimos sinais ao abrir essa tela
      this.trackingService.loadLastVehicleSignals(this.loadingListService, true);
      this.trackingService.loadLastSignals(this.loadingListService, true);

      if (this.storageService.hasPopoutData()) {
        const popout = this.storageService.getPopoutData();
        this.restorePopoutData(popout);
        //this.storageService.setPopoutData(null); // TODO scuri Porque isso está comentado?
      }
    }, 500);
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  subscribeOnPageMaximizedEvent(){
    this.glSubscribeEvent(PAGE_MAXIMIZED_PREFIX + MAP_PAGE, (data:string) => {
      this.glOnResize(true);
    });
  }

  subscribeOnFillData(){
    this.glSubscribeEvent(FILL_DATA_PREFIX + MAP_PAGE, (data) => {
      this.fillData(data);
    });
  }

  private loadFormOptionsData() {
    const metadatas: string[] = [
      "State"];
    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  private fillData(data: any) {
    if (data.tracking)
      this.onTrackingSelection(data.tracking, data.fit);
    else if (data.trackingList)
      this.onTrackingListSelection(data.trackingList, data.fit);
    else if (data.removeEditionMode)
      this.onRemoveEditionMode(data.operationId, data.operationType);
    else if (data.updatedLayer)
      this.updateLayer(data.updatedLayer);
    else if (data.layerData)
      this.onLayerSelection(data.layerData);
    else if (data.layerDataList)
      this.onLayerListSelection(data.layerDataList);
    else if (data.editionMode !== undefined) // Mudando para true ou false
      this.onEditionModeSelection(data);
    else {
      // Não permite mudar o mapa se modo de edição está ativo
      if (this.editionMode) {
        this.toastr.warning("Modo de Edição ativo, desative para adicionar novos elementos.");
        return;
      }

      if (data.historicalTracking)
        this.onHistoricalTrackingSelection(data.historicalTracking);
      else if (data.historicalTrackingList)
        this.onHistoricalTrackingListSelection(data.historicalTrackingList);
      else if (data.historicalTrackingLastOperation)
        this.onHistoricalTrackingLastOperationSelection(data.historicalTrackingLastOperation);
      else if (data.historicalTrackingLastOperationList)
        this.onHistoricalTrackingLastOperationListSelection(data.historicalTrackingLastOperationList);
      else if (data.historicalTrackingOperation)
        this.onHistoricalTrackingOperationSelection(data.historicalTrackingOperation, true);
      else if (data.historicalTrackingOperationList)
        this.onHistoricalTrackingOperationListSelection(data.historicalTrackingOperationList);
      else if (data.historicalTrackingTeam)
        this.onHistoricalTrackingTeamSelection(data.historicalTrackingTeam, true);
      else if (data.historicalTrackingTeamList)
        this.onHistoricalTrackingTeamListSelection(data.historicalTrackingTeamList);
      else if (data.historicalTrackingUser)
        this.onHistoricalTrackingUserSelection(data.historicalTrackingUser, true);
      else if (data.historicalTrackingUserList)
        this.onHistoricalTrackingUserListSelection(data.historicalTrackingUserList);
      else if (data.historicalTrackingVehicle)
        this.onHistoricalTrackingVehicleSelection(data.historicalTrackingVehicle, true);
      else if (data.historicalTrackingVehicleList)
        this.onHistoricalTrackingVehicleListSelection(data.historicalTrackingVehicleList);
      else if (data.operationId)
        this.onOperationIdSelection(data, true);
      else if (data.mapEvent === MapEvents.OPERATION_LOCATION)
        this.onOperationSelection(data.entity, true);
      else if (data.mapEvent === MapEvents.MANY_OPERATION_LOCATION)
        this.onOperationListSelection(data.entityList);
      else if (data.alert)
        this.onAlertSelection(data.alert, true);
      else if (data.alertList)
        this.onAlertListSelection(data.alertList);
      else if (data.inspectionPoint)
        this.onInspectionPointSelection(data.inspectionPoint, true);
      else if (data.inspectionPointList)
        this.onInspectionPointListSelection(data.inspectionPointList);
      else if (data.mapEvent === MapEvents.EVENT_LOCATION)
        this.onEventSelection(data.entity, true);
      else if (data.mapEvent === MapEvents.MANY_EVENT_LOCATION)
        this.onEventListSelection(data.entityList);
      else if (data.mapEvent === MapEvents.OBSERVED_AREA_LOCATION)
        this.onObservedAreaSelection(data.entity, true);
      else if (data.mapEvent === MapEvents.MANY_OBSERVED_AREA_LOCATION)
        this.onObservedAreaListSelection(data.entityList);
    }
  }

  public subscribeToSaveEdit() {
    // Escuta a edição correspondente para saber se algo foi modificado

    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'patrols-edit', (data) => {
      if (this.operationObjectsGet(data.entity.id, data.entity.type))
        this.onOperationSelection(data.entity, false);
    });

    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'verifications-edit', (data) => {
      if (this.operationObjectsGet(data.entity.id, data.entity.type))
        this.onOperationSelection(data.entity, false);
    });

    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'events-edit', (data) => {
      if (this.eventObjectsMap.get(data.entity.id))
        this.onEventSelection(data.entity, false);
    });

    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'points-edit', (data) => {
      if (this.pointObjectsMap.get(data.entity.id))
        this.onInspectionPointSelection(data.entity, false);
    });

    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'observed-areas-edit', (data) => {
      this.onObservedAreaSaved(data.entity);
    });
  }

  renderComponent() {
    // aguarda um pouco para outras tarefas terminarem
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 250);
  }


  ///////////////////////////////////
  //// Team Tracking

  hasTeamTracking(): boolean {
    return this.teamTrackingObjectsMap.size != 0;
  }

  getTeamTrackingObjectTitle(teamTracking: TeamTrackingMapObject): string {
    return this.getPatrolTeamTitle(teamTracking.patrolTeam);
  }

  onTeamTrackingClick(teamTrackingObject: TeamTrackingMapObject){
    let trackings: TrackingModel[] = [];
    if (teamTrackingObject.visible) {
      teamTrackingObject.trackings.forEach(tracking =>{
        trackings.push(tracking);
      });
    }
    let markers = this.baseMap.centerTrackingMarkers(trackings);
    this.highlightMarkers(markers);
  }

  onTeamTrackingRemove(teamTrackingObject: TeamTrackingMapObject) {
    teamTrackingObject.trackings.forEach(tracking =>{
      tracking.removedFromMap = true;
      this.baseMap.removeTrackingMarker(tracking);
    });
    this.teamTrackingObjectsMap.delete(teamTrackingObject.patrolTeam.id);
    this.teamTrackingsCount--;
    if (teamTrackingObject.listed) this.teamTrackingsListedCount--;
  }

  onTeamTrackingsRemoveClick(){
    this.teamTrackingObjectsMap.forEach( (teamTrackingObject, id) => {
        this.onTeamTrackingRemove(teamTrackingObject);
    });
  }

  onTeamTrackingHistoricalTrackingClick(teamTrackingObject: TeamTrackingMapObject) {
    this.onHistoricalTrackingTeamSelection(teamTrackingObject.patrolTeam, true);
  }

  onTeamTrackingVisibilityClick(teamTrackingObject: TeamTrackingMapObject) {
    if (teamTrackingObject.visible) {
      teamTrackingObject.visible = false;
      teamTrackingObject.trackings.forEach(tracking =>{
        this.baseMap.removeTrackingMarker(tracking);
      });
    }
    else{
      teamTrackingObject.visible = true;
      teamTrackingObject.trackings.forEach(tracking =>{
        this.baseMap.updateTrackingMarker(tracking, false);
      });
    }
  }

  onTeamTrackingsVisibilityClick(){
    this.teamTrackingObjectsMap.forEach( (teamTrackingObject, id) => {
      if (this.teamTrackingsVisible) {
        teamTrackingObject.visible = false;
        teamTrackingObject.trackings.forEach(tracking =>{
          this.baseMap.removeTrackingMarker(tracking);
        });
      }
      else{
        teamTrackingObject.visible = true;
        teamTrackingObject.trackings.forEach(tracking =>{
          this.baseMap.updateTrackingMarker(tracking, false);
        });
      }
    });

    if (this.teamTrackingsVisible) {
      this.teamTrackingsVisible = false;
    }
    else{
      this.teamTrackingsVisible = true;
    }
  }

  teamTrackingListOrder = (a: KeyValue<string, TeamTrackingMapObject>, b: KeyValue<string, TeamTrackingMapObject>): number => {
    return a.value.patrolTeam.name.localeCompare(b.value.patrolTeam.name);
  }  

  onTeamTrackingItemClick(tracking: TrackingModel){
    let marker = this.baseMap.centerTrackingMarker(tracking);
    this.highlightMarker(marker);
  }

  trackingItemListOrder = (a: KeyValue<string, TrackingModel>, b: KeyValue<string, TrackingModel>): number => {
    if (a.value.signal.sourceType == 'MOBILE_APP' && b.value.signal.sourceType == 'VEHICLE')
      return -1;
    else if (a.value.signal.sourceType == 'VEHICLE' && b.value.signal.sourceType == 'MOBILE_APP')
      return 1;
    else if (a.value.signal.sourceType == 'MOBILE_APP' && b.value.signal.sourceType == 'MOBILE_APP') {
      return a.value.user?.name.localeCompare(b.value.user?.name);
    }
    else { // Ambos VEHICLE
      return a.value.signal.mobileObjectId.localeCompare(b.value.signal.mobileObjectId);
    }
  }
  
  ///////////////////////////////////
  //// Tracking

  /** Programa a execução do listener de sinal perdido para intervalos regulares 
   *  Verifica se algum objeto móvel perdeu o sinal, e se for o caso, atualiza seu ícone no mapa
   * (o ícone de pessoa ou veículo deixa de ficar colorido e fica cinza)
  */
  private subscribeOnLostSignalStatusCheck(){
    this.lostSignalStatusCheckInterval = setInterval( () => {
      this.logger.trace('***** Running update lost signal job.');
      // Só atualiza tracking existente
      this.trackingObjectsMap.forEach( (trackingObject: TrackingMapObject) => {
        this.trackingService.updateIcon(trackingObject.tracking);

        if (this.trackingsVisible[trackingObject.tracking.signal.sourceType] && trackingObject.visible) {
          this.baseMap.updateTrackingMarker(trackingObject.tracking, false);
        }
      });
      this.teamTrackingObjectsMap.forEach( (teamTrackingObject: TeamTrackingMapObject) => {
        teamTrackingObject.trackings.forEach(tracking => {
          this.trackingService.updateIcon(tracking);

          if (this.teamTrackingsVisible && teamTrackingObject.visible) {
            this.baseMap.updateTrackingMarker(tracking, false);
          }
        });
      });
    }, SIGNAL_ICON_UPDATE_INTERVAL);
  }

  hasTracking(sourceType: string): boolean {
    let found: boolean = false;
    this.trackingObjectsMap.forEach( (trackingObject: TrackingMapObject) => {
      if (trackingObject.tracking.signal.sourceType == sourceType) {
        found = true;
        return;
      }
    });
    return found;
  }

  getTrackingId(tracking: TrackingModel): string {
    return tracking.signal.mobileObjectId;
  }

  isTrackingFromSource(tracking: TrackingModel, sourceType: string): boolean {
    return tracking.signal.sourceType == sourceType;
  }

  getTrackingTitle(tracking: TrackingModel): string {
    if (!tracking) return;

    if (tracking.signal.sourceType == 'MOBILE_APP') {
      return tracking.user ? tracking.user.name: USER_NOT_FOUND; // A pedido, aqui só mostra o nome do usuário, não inclui o login
    }
    else {
      return tracking.signal.mobileObjectId; // É a placa do veículo
    }
  }

  private selectTeamTracking(tracking: TrackingModel, fit: boolean) {
    // Remove da lista sem equipe se estava lá
    let trackingObject = this.trackingObjectsMap.get(this.getTrackingId(tracking));
    if (trackingObject) {
      this.trackingRemove(trackingObject);
    }

    let teamTrackingObject = this.teamTrackingObjectsMap.get(tracking.patrolTeam.id);
    if (!teamTrackingObject) {
      teamTrackingObject = new TeamTrackingMapObject();
      this.teamTrackingObjectsMap.set(tracking.patrolTeam.id, teamTrackingObject);

      teamTrackingObject.patrolTeam = tracking.patrolTeam;

      this.teamTrackingsCount++;
    }

    let found: boolean = false;
    teamTrackingObject.trackings.forEach( (t, i) => {
      if (t.signal.mobileObjectId == tracking.signal.mobileObjectId) {
        found = true;
        teamTrackingObject.trackings[i] = tracking;
      }
    });
    if (!found){
      teamTrackingObject.trackings.push(tracking);
    }

    if (this.teamTrackingsVisible) {
      let marker = this.baseMap.updateTrackingMarker(tracking, fit);
      teamTrackingObject.visible = true;

      if (marker){
        this.highlightMarker(marker);
      }
    }

    if (this.searchValue) {
      this.updateTeamTrackingListed(teamTrackingObject);
    }

    this.updateTeamTrackingListedCount();
  }

  private selectTracking(tracking: TrackingModel, fit: boolean) {
    let trackingObject = this.trackingObjectsMap.get(this.getTrackingId(tracking));
    if (!trackingObject) {
      trackingObject = new TrackingMapObject();
      this.trackingObjectsMap.set(this.getTrackingId(tracking), trackingObject);

      this.trackingsCount[tracking.signal.sourceType]++;
    }

    trackingObject.tracking = tracking;
    // TODO scuri Não usamos trackingObject.popupContent porque o conteúdo é atualizado dinamicamente ao longo do tempo. O ideal é o basemap armazenar essa string.

    if (this.trackingsVisible[tracking.signal.sourceType]) {
      let marker = this.baseMap.updateTrackingMarker(tracking, fit);
      trackingObject.visible = true;

      if (marker){
        this.highlightMarker(marker);
      }
    }

    if (this.searchValue) {
      this.updateTrackingListed(trackingObject);
    }

    this.updateTrackingListedCount();
  }

  onTrackingSelection(tracking: TrackingModel, fit: boolean) {
    // Não fazer essa chamada para evitar sobrecarregar o log, descomentar apenas quando necessário
    // this.logger.debug('MapComponent.onTrackingSelection()');

    if(!tracking || !tracking.signal) return;

    this.updateHistoricalTracking(tracking);

    let specificPlacements = this.storageService.getSpecificPlacementIds();
    if (tracking.invalidInfo || tracking.removedFromMap || !this.trackingService.isPermittedPlacement(tracking, specificPlacements)) return;

    if (tracking.patrolTeam) {
      this.selectTeamTracking(tracking, fit);
    }
    else {
      this.selectTracking(tracking, fit);
    }

    if (fit) this.renderComponent();
  }

  onTrackingListSelection(trackingList: TrackingModel[], fit: boolean){
    trackingList.forEach((tracking) =>{
      this.onTrackingSelection(tracking, false);
    });

    if (fit) {
      this.fitTrackingListBounds(trackingList);
    }

    this.renderComponent();
  }

  getLatLongBounds(latLongs: L.LatLngTuple[]){
    // Aceita também um array de coordenadas no construtor
    let bounds: L.LatLngBounds = new L.LatLngBounds(latLongs).pad(MapInfo.BOUNDS_PAD_RATIO);
    return bounds;
  }

  fitTrackingListBounds(trackingList: TrackingModel[]) {
    let latLongs: L.LatLngTuple[] = [];

    trackingList.forEach(tracking =>{
      latLongs.push([tracking.signal.latitude, tracking.signal.longitude]);
    });

    if (latLongs.length > 1) {
      let bounds: L.LatLngBounds = this.getLatLongBounds(latLongs);
      this.baseMap.fitBounds(bounds);
    }
    else if (latLongs.length == 1) {
      this.baseMap.fitPoint(latLongs[0]);
    }
  }

  onTrackingClick(trackingObject: TrackingMapObject){
    if (trackingObject.visible) {
      let marker = this.baseMap.centerTrackingMarker(trackingObject.tracking);
      this.highlightMarker(marker);
    }
  }

  trackingRemove(trackingObject: TrackingMapObject){
    this.baseMap.removeTrackingMarker(trackingObject.tracking);
    this.trackingObjectsMap.delete(this.getTrackingId(trackingObject.tracking));
    this.trackingsCount[trackingObject.tracking.signal.sourceType]--;
    if (trackingObject.listed) this.trackingsListedCount[trackingObject.tracking.signal.sourceType]--;
  }

  onTrackingRemove(tracking : TrackingModel) {
    this.logger.debug('MapComponent.onTrackingRemove()');
    if(!tracking || !tracking.signal) return;

    let trackingObject: TrackingMapObject = this.trackingObjectsMap.get(this.getTrackingId(tracking));
    if (trackingObject) {
      trackingObject.tracking.removedFromMap = true;
      this.trackingRemove(trackingObject);
    }
  }

  onTrackingsRemoveClick(sourceType: string){
    this.trackingObjectsMap.forEach( (trackingObject, id) => {
      if (trackingObject.tracking.signal.sourceType == sourceType) {
        trackingObject.tracking.removedFromMap = true;
        this.trackingRemove(trackingObject);
      }
    });
  }

  onTrackingHistoricalTrackingClick(trackingObject: TrackingMapObject) {
    if (trackingObject.tracking.signal.sourceType == 'MOBILE_APP') {
      this.onHistoricalTracking(trackingObject.tracking.user.id, SourceType.MOBILE_APP, null, null, trackingObject.tracking.user, true);
    }
    else{
      this.onHistoricalTracking(trackingObject.tracking.signal.mobileObjectId, SourceType.VEHICLE, null, null, null, true);
    }
  }

  onTrackingVisibilityClick(trackingObject: TrackingMapObject) {
    if (trackingObject.visible) {
      trackingObject.visible = false;
      this.baseMap.removeTrackingMarker(trackingObject.tracking);
    }
    else{
      trackingObject.visible = true;
      this.baseMap.updateTrackingMarker(trackingObject.tracking, false);
    }
  }

  onTrackingsVisibilityClick(sourceType: string){
    this.trackingObjectsMap.forEach( (trackingObject, id) => {
      if (trackingObject.tracking.signal.sourceType == sourceType) {
        if (this.trackingsVisible[sourceType]) {
          trackingObject.visible = false;
          this.baseMap.removeTrackingMarker(trackingObject.tracking);
        }
        else{
          trackingObject.visible = true;
          this.baseMap.updateTrackingMarker(trackingObject.tracking, false);
        }
      }
    });

    if (this.trackingsVisible[sourceType]) {
      this.trackingsVisible[sourceType] = false;
    }
    else{
      this.trackingsVisible[sourceType] = true;
    }
  }

  trackingListOrder = (a: KeyValue<string, TrackingMapObject>, b: KeyValue<string, TrackingMapObject>): number => {
    if (a.value.tracking.signal.sourceType == 'MOBILE_APP' && b.value.tracking.signal.sourceType == 'VEHICLE')
      return -1;
    else if (a.value.tracking.signal.sourceType == 'VEHICLE' && b.value.tracking.signal.sourceType == 'MOBILE_APP')
      return 1;
    else if (a.value.tracking.signal.sourceType == 'MOBILE_APP' && b.value.tracking.signal.sourceType == 'MOBILE_APP') {
      return a.value.tracking.user?.name.localeCompare(b.value.tracking.user?.name);
    }
    else { // Ambos VEHICLE
      return a.value.tracking.signal.mobileObjectId.localeCompare(b.value.tracking.signal.mobileObjectId);
    }
  }

  /**
   * ###################################################
   * Camadas
   * ###################################################
   */

  onLayerSelection(layerData){
    this.onLayerListSelection([layerData]);
  }

  public layerTypeToId(type: string) {
    switch (type) {
      case ("Band"): {
        return this.baseMap.BAND_ID;
      };
      case ("GasDuct"): {
        return this.baseMap.GASDUCT_ID;
      };
      case ("OilDuct"): {
        return this.baseMap.OILDUCT_ID;
      };
      case ("Simf"): {
        return this.baseMap.SIMF_ID;
      };
      case ("KilometerMark"): {
        return this.baseMap.KILOMETER_MARK_ID;
      };
      case ("DeliveryPoint"): {
        return this.baseMap.DELIVERY_POINT_ID;
      };
      case ("Terminal"): {
        return this.baseMap.TERMINAL_ID;
      };
      case ("Refinary"): {
        return this.baseMap.REFINARY_ID;
      };
      case ("Dc"): {
        return this.baseMap.DC_HISTORY_ID;
      };
      case ("Valve"): {
        return this.baseMap.VALVE_ID;
      };
      case ("ObservedArea"): {
        return this.baseMap.OBSERVED_AREA_ID;
      };
    }
  }

  onLayerListSelection(layerDataList: GeoModel[]){
    const type = layerDataList[0].type;
    const id = this.layerTypeToId(type);
    if (!id) return;

    let layer: L.LayerGroup = this.layersMap.get(id);

    // Necessariamente faz o layer ficar visivel
    if (!this.baseMap.hasLayer(layer)) {
      this.baseMap.addLayerToMap(layer);
    }

    let latLongs: L.LatLngTuple[] = [];
    layerDataList.forEach(geoLayer =>{
      // Verifica se aquele item da camada está visivel no mapa
      const feature = {properties: geoLayer};
      if (this.baseMap.filterGeoModel(id, feature.properties)) {
        let geoLayerLatLongs: L.LatLngTuple[] = [];
        if (geoLayer.geometry.type == 'GeometryCollection'){
          const geometries = geoLayer.geometry.geometries;
          for(let p = 0; p < geometries.length; p++) {
            if (geometries[p].type == 'Point'){
              const point = geometries[p].coordinates as [number, number];
              geoLayerLatLongs.push([point[1], point[0]]);
            }
            else{
              geoLayerLatLongs = geoLayerLatLongs.concat(L.GeoJSON.coordsToLatLngs(geometries[p].coordinates));
            }          
          }
        }
        else if (geoLayer.geometry.type == "Point"){
          const point = geoLayer.geometry.coordinates as [number, number];
          geoLayerLatLongs.push([point[1], point[0]]);
        }
        else{ // geoLayer.geometry.type == "Polygon" ou "LineString"
          if (geoLayer.type == "ObservedArea") {
            geoLayerLatLongs = L.GeoJSON.coordsToLatLngs(geoLayer.geometry.coordinates[0]);
          }
          else {
            geoLayerLatLongs = L.GeoJSON.coordsToLatLngs(geoLayer.geometry.coordinates);
          }
        }
        latLongs.push(...geoLayerLatLongs);
      }
    });

    if (latLongs.length > 1) {
      let bounds: L.LatLngBounds = this.getLatLongBounds(latLongs);
      this.highlightBounds(bounds);
      let line = L.polyline(latLongs);
      this.highlightPolyline(line);
      this.baseMap.fitBounds(bounds);
    }
    else if (latLongs.length == 1) {
      let lls: L.LatLng[] = [];
      lls.push(L.latLng(latLongs[0]));
      this.highlightLatLongPoints(lls);
      this.baseMap.fitPoint(latLongs[0]);
    }
  }

  public updateLayer(data: string) {
    this.baseMap.updateGeoLayer(data);
  }

  layerListOrder = (a: KeyValue<string, L.LayerGroup>, b: KeyValue<string, L.LayerGroup>): number => {
    // Ordem determinada pelo "Ajustes 3" do Aditivo
    const layersOrder = {
      [this.baseMap.DC_HISTORY_ID]: 1,
      [this.baseMap.OBSERVED_AREA_ID]: 2,
      [this.baseMap.SIMF_ID]: 3,
      [this.baseMap.BAND_ID]: 4,
      [this.baseMap.OILDUCT_ID]: 5,
      [this.baseMap.GASDUCT_ID]: 6,
      [this.baseMap.KILOMETER_MARK_ID]: 7,
      [this.baseMap.VALVE_ID]: 8,
      [this.baseMap.TERMINAL_ID]: 9,
      [this.baseMap.REFINARY_ID]: 10,
      [this.baseMap.DELIVERY_POINT_ID]: 11,
    };

    return layersOrder[a.key] - layersOrder[b.key];
  }

  layerFilterIcon(key) {
    return this.baseMap.layerFilterIcon(key);
  }

  onLayerVisibilityClick(layer: L.LayerGroup) {
    if (this.baseMap.hasLayer(layer)) {
      this.baseMap.removeLayerFromMap(layer);
    }else{
      this.baseMap.addLayerToMap(layer);
    }
  }

  onLayersVisibilityClick(){
    this.layersMap.forEach( (layer, id) => {
      if (this.layersVisible) {
        if (this.baseMap.hasLayer(layer)) {
          this.baseMap.removeLayerFromMap(layer);
        }
      }
      else {
        if (!this.baseMap.hasLayer(layer)) {
          this.baseMap.addLayerToMap(layer);
        }
      }
    });

    this.layersVisible = !this.layersVisible;
  }

  onLayerIsVisibile(layer: L.LayerGroup): boolean {
    return this.baseMap.hasLayer(layer);
  }

  /**
   * ###################################################
   * Áreas Observadas
   * ###################################################
   */

  onObservedAreaSaved(area : ObservedAreaModel){
    this.logger.debug('MapComponent.onObservedAreaSaved');

    // TODO scuri Atualiza tudo necessariamente, otimizar

    // Recarrega todas do banco para o Mapa
    this.baseMap.initializeObservedAreaLayer();

    this.lastSavedArea = area;
  }

  /*
    Áreas observadas é a única camada que ao ser selecionada para visualização 
    altera o seu próprio filtro para incluir a área sendo visualizada.
  */

  onObservedAreaSelection(area: ObservedAreaModel, fit: boolean){
    this.logger.debug('MapComponent.onObservedAreaSelection');

    if(this.layersFilter.areasIds && this.layersFilter.areasIds.length > 0 && !this.layersFilter.areasIds.includes(area.id)){
      this.layersFilter.areasIds = [...this.layersFilter.areasIds, area.id];
      this.baseMap.updateFilter(this.baseMap.OBSERVED_AREA_ID, false, true);
    }

    if (!this.baseMap.hasLayer(this.baseMap.geoLayers[this.baseMap.OBSERVED_AREA_ID])) {
      this.baseMap.addLayerToMap(this.baseMap.geoLayers[this.baseMap.OBSERVED_AREA_ID]);
    }

    if (fit) {
      let latLongs: L.LatLngTuple[] = L.GeoJSON.coordsToLatLngs(area.geometry.coordinates[0]); //coordinates em Areas Observadas são arrays de arrays de coordenadas
      let bounds = this.getLatLongBounds(latLongs);
      this.highlightBounds(bounds);
      let line = L.polyline(latLongs);
      this.highlightPolyline(line);
      this.baseMap.fitBounds(bounds);
    }
  }

  onObservedAreaListSelection(areaList: ObservedAreaModel[]){
    let newAreas: string[] = []
    areaList.forEach(area =>{
      if(this.layersFilter.areasIds && !this.layersFilter.areasIds.includes(area.id)){
        newAreas.push(area.id);
      }
    });

    if(newAreas.length > 0){
      this.layersFilter.areasIds = [...this.layersFilter.areasIds, ...newAreas];
    }

    this.baseMap.updateFilter(this.baseMap.OBSERVED_AREA_ID, false, true);

    let latLongs: L.LatLngTuple[] = [];
    areaList.forEach(area =>{
      let areaLatLongs: L.LatLngTuple[] = L.GeoJSON.coordsToLatLngs(area.geometry.coordinates[0]); //coordinates em Areas Observadas são arrays de arrays de coordenadas
      latLongs.push(...areaLatLongs);
    });

    this.baseMap.fitBounds(this.getLatLongBounds(latLongs));
  }

  /**
   * ###################################################
   * Filtro de Camadas
   * ###################################################
   */

  private loadFromCache(){ 
    let _this = this; // Necessário por causa do contexto das callbacks
    const onUsersLoad = function() {
      const responsibleUserTypes = [UserType.ANALYSIS_CCPD, UserType.COORDINATOR_CCPD, UserType.COORDINATOR_OPPD, UserType.PLANNER];
      _this.allResponsibles = _this.entityCacheService.getFilteredUsers(responsibleUserTypes);
    };
    this.entityCacheService.loadUsers(this.loadingListService, onUsersLoad);
    this.reloadUsersSubscription = this.entityCacheService.onUsersReload().subscribe(onUsersLoad);

    this.entityCacheService.loadVehicles(this.loadingListService);
  }

  private filterObservedArea(){
    this.areasFiltered = this.allObservedAreas.filter((area: ObservedAreaModel) => {

      if(this.layersFilter){
        if(this.layersFilter.areaStatus !== FILTER_OPTION_ALL){
          if(this.layersFilter.areaStatus !== area.status){
            return false;
          }
        }

        if(this.layersFilter.states?.length > 0){
          let match = true;

          if(!area.states || area.states.length === 0) return false;

          for(let state of area.states){
            match = this.layersFilter.states.includes(state);
            if(match){
              break;
            }
          }

          if(!match) return false;
        }

        if(this.layersFilter.bandNames?.length > 0){
          if(!this.layersFilter.bandNames.includes(area.band)){ // area.band É o nome da área observada
            return false;
          }
        }

        if(this.layersFilter.responsiblesIds?.length > 0){
          if(!this.layersFilter.responsiblesIds.includes(area.responsible?.id)){
            return false;
          }
        }
      }

      return true;
    });

    this.areasFiltered.sort(this.layerFilterSort);
  }

  private layerFilterSort(a: GeoModel, b: GeoModel) {
    return a.name.localeCompare(b.name);
  }

  layersFilterSelectState() {
    this.bandsFiltered = [];

    // Ao selecionar Estados, outros filtros são zerados
    this.layersFilter.bandIds = [];
    this.layersFilter.bandNames = [];
    this.layersFilter.areasIds = [];

    if (this.layersFilter.states.length) {
      this.bandsFiltered = this.allBands.filter(band => {
        let match = true;

        if(!band.states || band.states.length === 0) return false;
    
        for(let state of band.states){
          match = this.layersFilter.states.includes(state);
          if(match){
            break;
          }
        }
    
        if(!match) return false;
    
        return true;
      });
    }else {
      this.bandsFiltered = this.allBands;
    }

    this.bandsFiltered.sort(this.layerFilterSort);
    
    this.baseMap.updateAllFilters(); // atualiza o mapa
    this.filterObservedArea(); // atualiza a combo
  }

  private layersFilterSelectBand() {
    this.layersFilter.bandIds = [];

    // Se tem uma Faixa selecionada,
    // então seleciona todas as faixas com o mesmo nome no mapa
    if (this.layersFilter.bandNames.length > 0) {
      this.layersFilter.bandNames.forEach(name => {
        let band = this.bandsFiltered.find(band => band.name == name);
        this.layersFilter.bandIds.push(band.id);
      });
    }
    
    this.baseMap.updateFilterBands(); // atualiza o mapa

    this.filterObservedArea(); // atualiza a combo
  }

  layerFilterSelectObservedArea(){
    this.filterObservedArea(); // atualiza a combo
    this.baseMap.updateFilter(this.baseMap.OBSERVED_AREA_ID); // atualiza o mapa
  }

  layersFilterSelectDcYear(){
    this.baseMap.updateFilter(this.baseMap.DC_HISTORY_ID);
  }

  isLayerFilterListSelected(value: string, values: string[]){
    return values.includes(value);
  }

  onLayerFilterListClick(checked: boolean, value: string, values: string[], option: FilterOption) {
    if (checked) {
      if (!values.includes(value)) {
        values.push(value);
        this.selectLayerFilter(option, false);
      }
    }
    else {
      const index = values.indexOf(value);
      if (index != -1) {
        values.splice(index, 1);
        this.selectLayerFilter(option, false);
      }
    }
  }

  selectLayerFilter(option: FilterOption, reset: boolean){
    switch(option){
      case FilterOption.YEAR:
        if(reset) this.layersFilter.years = [];
        this.layersFilterSelectDcYear();
        this.resetSearchLayer(this.baseMap.DC_HISTORY_ID);
        break;
      case FilterOption.STATE:
        if(reset) this.layersFilter.states = [];
        this.layersFilterSelectState();
        this.resetAllLayersSearch();
        break;
      case FilterOption.BAND:
        if(reset) { this.layersFilter.bandNames = []; }
        this.layersFilterSelectBand();
        this.resetAllLayersSearch();
        break;
      case FilterOption.AREA_STATUS:
        if(reset) this.layersFilter.areaStatus = ObservedAreaStatus.ACTIVE;
        this.layerFilterSelectObservedArea();
        this.resetSearchLayer(this.baseMap.OBSERVED_AREA_ID);
        break;
      case FilterOption.AREA_RESPONSIBLE:
        if(reset) this.layersFilter.responsiblesIds = [];
        this.layerFilterSelectObservedArea();
        this.resetSearchLayer(this.baseMap.OBSERVED_AREA_ID);
        break;
      case FilterOption.AREA_ID:
        if(reset) this.layersFilter.areasIds = [];
        this.layerFilterSelectObservedArea();
        this.resetSearchLayer(this.baseMap.OBSERVED_AREA_ID);
        break;
    }
  }

  onResetLayersFilterClick() {
    this.layersFilter = new LayerFilterModel(); // Isso vai gerar uma atualização de todos os filtros no baseMap
    this.layersFilterSelectState();
  }

  /**
   * ###################################################
   * Operações
   * ###################################################
   */

  private operationObjectsGet(operationId: string, operationType: string): OperationMapObject {
    return this.operationObjectsMap.get(operationId+operationType);
  }

  public getOperationObjectPopupContent(operation: OperationModel): string {
    return OperationModel.getPopupContent(operation) + PatrolTeamModel.getPopupContent(operation.patrolTeam, true);
  }

  private operationObjectsNew(operation: OperationModel): OperationMapObject {
    let operationMapObject = new OperationMapObject();
    operationMapObject.operation = operation;

    this.operationObjectsMap.set(operation.id+operation.type, operationMapObject);
    this.operationsCount[operation.type]++;

    operationMapObject.popupContent = this.getOperationObjectPopupContent(operation);

    if (this.searchValue) {
      this.updateListedObject(operationMapObject);
    }

    this.updateOperationListedCount();

    return operationMapObject;
  }

  private operationObjectsUpdate(operationMapObject: OperationMapObject, operation: OperationModel) {
    operationMapObject.operation = operation;

    operationMapObject.popupContent = this.getOperationObjectPopupContent(operation);

    if (this.searchValue) {
      this.updateListedObject(operationMapObject);
    }

    this.updateOperationListedCount();
  }

  private operationObjectsDelete(operationId: string, operationType: string) {
    let operationMapObject = this.operationObjectsGet(operationId, operationType);
    if (operationMapObject) {
      if (operationMapObject.listed) this.operationsListedCount[operationType]--;
      this.operationsCount[operationType]--;
    }
    this.operationObjectsMap.delete(operationId+operationType);
  }

  onRemoveEditionMode(operationId: string, operationType: string){
    this.logger.debug('MapComponent.onRemoveEditionMode()');
    // Estava em modo de edição dessa operação
    if (this.editionMode &&
        this.editionMode.OperationId == operationId &&
        this.editionMode.operation.type == operationType) {
      this.clearEditionMode();
    }
  }

  /* Usado para visualização de rota e pontos de operações sendo editadas */
  onOperationIdSelection(data, fit: boolean){
    this.logger.debug('MapComponent.onOperationIdSelection()');

    if (data.fileKmlRoute) // importou kml de rota
      this.updateOperationFileDataRoute(data.operationId, data.operationType, data.fileKmlRoute, data.operation, fit);

    let operationMapObject = this.operationObjectsGet(data.operationId, data.operationType);
    if (operationMapObject) {
       if (data.removedKmlRoute) { // removeu kml de rota
        this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
        operationMapObject.geoRoute = null;
        operationMapObject.geoRouteBounds = null;
        operationMapObject.fileKmlRoute = null;
        operationMapObject.removedKmlRoute = true;
      }

      if (operationMapObject.removedKmlRoute && // removeu kml de rota e não tem inspeções
         (!operationMapObject.operation.inspections || operationMapObject.operation.inspections.length == 0))
        this.operationObjectsDelete(data.operationId, data.operationType);
    }

    // Removeu kml de Rota, e não importou kml de rota, mas tem operação, então atualiza rota
    if (!data.removedKmlRoute && !data.fileKmlRoute && data.operation) {
      if (!data.operation.id) data.operation.id = data.operationId;
      this.updateOperationRoute(data.operation, fit);
    }

    // Se tem operação, atualiza pontos
    if (data.operation) {
      if (!data.operation.id) data.operation.id = data.operationId;
      this.updateOperation(data.operation, fit);
    }
  }

  updateOperationFileDataRoute(operationId: string, operationType: string, fileKmlRoute: File, operation: OperationModel, fit: boolean){
    let operationMapObject = this.operationObjectsGet(operationId, operationType);
    if (operationMapObject) {
      // Se existe, remove do mapa antes de atualizar
      this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
      operationMapObject.geoRoute = null;
      operationMapObject.geoRouteBounds = null;
    }

    this.routeGeographicalObjectService.importKmlRoute(fileKmlRoute, operation).then((geoRoute) => {
      geoRoute.on('ready', () => {
        let operationMapObject = this.operationObjectsGet(operationId, operationType);
        if (!operationMapObject) {
          operationMapObject = this.operationObjectsNew(operation);
        }
        else {
          this.operationObjectsUpdate(operationMapObject, operation);
        }

        operationMapObject.fileKmlRoute = fileKmlRoute;
        operationMapObject.geoRoute = geoRoute;
        operationMapObject.removedKmlRoute = false;
   
        this.baseMap.addLayerToMap(operationMapObject.geoRoute);
        operationMapObject.visible = true;

        operationMapObject.geoRouteBounds = operationMapObject.geoRoute.getBounds(); // Esse get bounds retorna um novo bounds
        if (operationMapObject.geoRouteBounds) operationMapObject.geoRouteBounds = operationMapObject.geoRouteBounds.pad(MapInfo.BOUNDS_PAD_RATIO);

        if (fit) this.fitOperationBounds(operationMapObject);
      });
    });
  }

  updateOperationRoute(operation: OperationModel, fit: boolean) {
    let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
    if (operationMapObject) {
      // Se existe, remove do mapa antes de atualizar
      this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
      operationMapObject.geoRoute = null;
      operationMapObject.geoRouteBounds = null;
    }

    if (operation.route && operation.route.fileRouteKml) {
      this.routeGeographicalObjectService.loadKML(operation)
        .then((geoRoute) => {
          geoRoute.on('ready', () => {
            let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
            if (!operationMapObject) {
              operationMapObject = this.operationObjectsNew(operation);
            }
            else {
              this.operationObjectsUpdate(operationMapObject, operation);
            }
    
            operationMapObject.geoRoute = geoRoute;
            operationMapObject.fileKmlRoute = null;
            operationMapObject.removedKmlRoute = false;
   
            this.baseMap.addLayerToMap(operationMapObject.geoRoute);
            operationMapObject.visible = true;

            operationMapObject.geoRouteBounds = operationMapObject.geoRoute.getBounds(); // Esse get bounds retorna um novo bounds
            if (operationMapObject.geoRouteBounds) operationMapObject.geoRouteBounds = operationMapObject.geoRouteBounds.pad(MapInfo.BOUNDS_PAD_RATIO);

            if (fit) this.fitOperationBounds(operationMapObject);
          });
        })
        .catch( error => {
          this.toastr.error("Falha ao carregar rota");
          this.logger.error(error);
        });
    }
  }

  updateOperation(operation: OperationModel, fit: boolean){
    let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
    if (operationMapObject) {
      // Se existe, remove do mapa antes de atualizar
      this.baseMap.removeLayerFromMap(operationMapObject.geoPoints);
      operationMapObject.geoPoints = null;
      operationMapObject.geoPointsBounds = null;
    }

    let geoPoints = this.routeGeographicalObjectService.getOperationGeoPoints(operation);
    if (geoPoints) {
      let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
      if (!operationMapObject) {
        operationMapObject = this.operationObjectsNew(operation);
      }
      else {
        this.operationObjectsUpdate(operationMapObject, operation);
      }

      operationMapObject.geoPoints = geoPoints;

      this.baseMap.addLayerToMap(operationMapObject.geoPoints);
      operationMapObject.visible = true;

      operationMapObject.geoPointsBounds = operationMapObject.geoPoints.getBounds(); // Esse get bounds retorna um novo bounds
      if (operationMapObject.geoPointsBounds) operationMapObject.geoPointsBounds = operationMapObject.geoPointsBounds.pad(MapInfo.BOUNDS_PAD_RATIO);

      if (fit) this.fitOperationBounds(operationMapObject);
    }
  }

  onOperationListSelection(operationList: OperationModel[]){
    operationList.forEach(operation =>{
      this.onOperationSelection(operation, false);
    });

    this.fitOperationListBounds(operationList);
  }

  calcPlannedDistance(geoRoute): number{
    let polylines: L.Polyline [] = RouteGeographicalService.getGeoRoutePolyline(geoRoute);
    let distance: number = 0;
    if (polylines && polylines.length > 0) {

      for(let p = 0; p < polylines.length; p++) {
        let polyline = polylines[p];

        let latLngs = polyline.getLatLngs() as L.LatLng[];
        let prevLatLng = this.latLngToTurfPoint(latLngs[0]);

        for(let i = 1; i < latLngs.length; i++) {
          let curLatLng = this.latLngToTurfPoint(latLngs[i]);
          distance += turf.distance(prevLatLng, curLatLng, {units: 'kilometers'});
          prevLatLng = curLatLng;
        }        
      }
    }
    return distance;
  }

  onOperationSelection(operation: OperationModel, fit: boolean) {
    this.logger.debug('MapComponent.onOperationSelection()');

    let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
    if (operationMapObject) {
      // Se existe, remove do mapa antes de atualizar
      this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
      this.baseMap.removeLayerFromMap(operationMapObject.geoPoints);
    }

    if (operation.route && operation.route.fileRouteKml) {
      this.routeGeographicalObjectService.loadKML(operation)
        .then((geoRoute) => {
          geoRoute.on('ready', () => {
            let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
            if (!operationMapObject) {
              operationMapObject = this.operationObjectsNew(operation);
            }
            else {
              this.operationObjectsUpdate(operationMapObject, operation);
            }
    
            operationMapObject.geoRoute = geoRoute;
    
            if (this.operationsVisible[operation.type]) {
              this.baseMap.addLayerToMap(operationMapObject.geoRoute);
              operationMapObject.visible = true;

              operationMapObject.geoRouteBounds = operationMapObject.geoRoute.getBounds(); // Esse get bounds retorna um novo bounds
              if (operationMapObject.geoRouteBounds) operationMapObject.geoRouteBounds = operationMapObject.geoRouteBounds.pad(MapInfo.BOUNDS_PAD_RATIO);

              if (fit) {
                let bounds = this.fitOperationBounds(operationMapObject);
                if (bounds) this.highlightBounds(bounds);
                if (operationMapObject.geoRoute) this.highlightGeoRoute(operationMapObject.geoRoute);
              }
            }
          });
        })
        .catch( error => {
          this.toastr.error("Falha ao carregar rota");
          this.logger.error(error);
        });
    }

    let geoPoints = this.routeGeographicalObjectService.getOperationGeoPoints(operation);
    if (geoPoints) {
      let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
      if (!operationMapObject) {
        operationMapObject = this.operationObjectsNew(operation);
      }
      else {
        this.operationObjectsUpdate(operationMapObject, operation);
      }

      operationMapObject.geoPoints = geoPoints;

      if (this.operationsVisible[operation.type]) {
        this.baseMap.addLayerToMap(operationMapObject.geoPoints);
        operationMapObject.visible = true;

        operationMapObject.geoPointsBounds = operationMapObject.geoPoints.getBounds(); // Esse get bounds retorna um novo bounds
        if (operationMapObject.geoPointsBounds) operationMapObject.geoPointsBounds = operationMapObject.geoPointsBounds.pad(MapInfo.BOUNDS_PAD_RATIO);
        if (fit) {
          let bounds = this.fitOperationBounds(operationMapObject);
          if (bounds) this.highlightBounds(bounds);
          if (operationMapObject.geoRoute) this.highlightGeoRoute(operationMapObject.geoRoute);
        }
      }
    }
  }

  copyBounds(bounds: L.LatLngBounds): L.LatLngBounds{
    if (!bounds) return null;
    return new L.LatLngBounds(bounds.getSouthWest(), bounds.getNorthEast());
  }

  fitOperationBounds(operationMapObject: OperationMapObject) {
    let bounds: L.LatLngBounds = this.copyBounds(operationMapObject.geoRouteBounds); // O extend modifica a bounds, então tem que fazer uma cópia na primeira vez

    if (operationMapObject.geoPointsBounds)  {
      if (bounds) bounds.extend(operationMapObject.geoPointsBounds);
      else bounds = this.copyBounds(operationMapObject.geoPointsBounds);
    }

    if(bounds) {
      this.baseMap.fitBounds(bounds);
    }

    return bounds;
  }

  fitOperationListBounds(operationList: OperationModel[]) {
    let bounds: L.LatLngBounds = null; // O extend modifica a bounds, então tem que fazer uma cópia na primeira vez

    operationList.forEach( (operation: OperationModel) =>{
      let operationMapObject = this.operationObjectsGet(operation.id, operation.type);

      if (operationMapObject.geoRouteBounds)  {
        if (bounds) bounds.extend(operationMapObject.geoRouteBounds);
        else bounds = this.copyBounds(operationMapObject.geoRouteBounds);
      }

      if (operationMapObject.geoPointsBounds)  {
        if (bounds) bounds.extend(operationMapObject.geoPointsBounds);
        else bounds = this.copyBounds(operationMapObject.geoPointsBounds);
      }
    });

    if(bounds)
      this.baseMap.fitBounds(bounds);
  }

  onOperationsRemoveClick(operationType: string) {
    this.operationObjectsMap.forEach( (operationMapObject: OperationMapObject, id) => {
      if (operationMapObject.operation.type == operationType) {
        this.onOperationRemoveClick(operationMapObject);
      }
    });
  }

  onOperationClick(operationMapObject: OperationMapObject) {
    if (operationMapObject.visible) {
      let bounds = this.fitOperationBounds(operationMapObject);
      if (bounds) this.highlightBounds(bounds);
      if (operationMapObject.geoRoute) this.highlightGeoRoute(operationMapObject.geoRoute);
    }
  }

  onOperationRemoveClick(operationMapObject: OperationMapObject) {
    this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
    this.baseMap.removeLayerFromMap(operationMapObject.geoPoints);
    this.operationObjectsDelete(operationMapObject.operation.id, operationMapObject.operation.type);
  }

  canHasOperationHistoricalTracking(operationMapObject: OperationMapObject): boolean {
    if (operationMapObject && operationMapObject.operation && operationMapObject.operation.status != OperationStatus.PLANNED
                  && operationMapObject.operation.status != OperationStatus.SENT)
      return true;
    else
      return false;
  }

  onOperationHistoricalTrackingClick(operationMapObject: OperationMapObject ) {
    this.onHistoricalTrackingOperationSelection(operationMapObject.operation, true);
  }

  onOperationVisibilityClick(operationMapObject: OperationMapObject) {
    if (operationMapObject.visible) {
      this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
      this.baseMap.removeLayerFromMap(operationMapObject.geoPoints);
      operationMapObject.visible = false;
    }
    else{
      if (operationMapObject.geoRoute) {
        this.baseMap.addLayerToMap(operationMapObject.geoRoute);
        operationMapObject.geoRouteBounds = operationMapObject.geoRoute.getBounds(); // Esse get bounds retorna um novo bounds
        if (operationMapObject.geoRouteBounds) operationMapObject.geoRouteBounds = operationMapObject.geoRouteBounds.pad(MapInfo.BOUNDS_PAD_RATIO);
      }
      if (operationMapObject.geoPoints) {
        this.baseMap.addLayerToMap(operationMapObject.geoPoints);
        operationMapObject.geoPointsBounds = operationMapObject.geoPoints.getBounds(); // Esse get bounds retorna um novo bounds
        if (operationMapObject.geoPointsBounds) operationMapObject.geoPointsBounds = operationMapObject.geoPointsBounds.pad(MapInfo.BOUNDS_PAD_RATIO);
      }
      operationMapObject.visible = true;
    }
  }

  onOperationsVisibilityClick(operationType: string) {
    this.operationObjectsMap.forEach( (operationMapObject: OperationMapObject, id) => {
      if (operationMapObject.operation.type == operationType) {
        if (this.operationsVisible[operationType]) {
          this.baseMap.removeLayerFromMap(operationMapObject.geoRoute);
          this.baseMap.removeLayerFromMap(operationMapObject.geoPoints);
          operationMapObject.visible = false;
        }
        else{
          if (operationMapObject.geoRoute) {
            this.baseMap.addLayerToMap(operationMapObject.geoRoute);
            operationMapObject.geoRouteBounds = operationMapObject.geoRoute.getBounds(); // Esse get bounds retorna um novo bounds
            if (operationMapObject.geoRouteBounds) operationMapObject.geoRouteBounds = operationMapObject.geoRouteBounds.pad(MapInfo.BOUNDS_PAD_RATIO);
          }
          if (operationMapObject.geoPoints) {
            this.baseMap.addLayerToMap(operationMapObject.geoPoints);
            operationMapObject.geoPointsBounds = operationMapObject.geoPoints.getBounds(); // Esse get bounds retorna um novo bounds
            if (operationMapObject.geoPointsBounds) operationMapObject.geoPointsBounds = operationMapObject.geoPointsBounds.pad(MapInfo.BOUNDS_PAD_RATIO);
          }
          operationMapObject.visible = true;
        }
      }
    });

    if (this.operationsVisible[operationType]) {
      this.operationsVisible[operationType] = false;
    }
    else{
      this.operationsVisible[operationType] = true;
    }
  }

  hasOperation(operationType: string) {
    let found = false;
    this.operationObjectsMap.forEach( (operationMapObject: OperationMapObject) => {
      if (operationMapObject.operation.type == operationType) {
        found = true;
        return;
      }
    });
    return found;
  }

  operationListOrder = (a: KeyValue<string, OperationMapObject>, b: KeyValue<string, OperationMapObject>): number => {
    return b.value.operation.startDate - a.value.operation.startDate;
  }

  getPatrolTeamTitle(patrolTeam: PatrolTeamModel): string {
    return patrolTeam && patrolTeam.name ? patrolTeam.name: 'sem equipe';
  }

  getOperationTitle(operation: OperationModel) {
    let identifier: string = operation.identifier;
    if (!identifier) identifier = '(sem ID)';
    return identifier + ' - ' + this.getPatrolTeamTitle(operation.patrolTeam);
  }

  private getOperationsList() {
    let operationList: OperationModel[] = [];
    this.operationObjectsMap.forEach( (operationMapObject: OperationMapObject) => {
      if (!operationMapObject.fileKmlRoute && !operationMapObject.removedKmlRoute)
        operationList.push(operationMapObject.operation);
    });
    return operationList;
  }

  private getOperationsIdList() {
    let operationsIdList = [];
    this.operationObjectsMap.forEach( (operationMapObject: OperationMapObject) => {
      if (operationMapObject.fileKmlRoute || operationMapObject.removedKmlRoute)
        operationsIdList.push({operationId: operationMapObject.operation.id, operation: operationMapObject.operation,
                               fileKmlRoute: operationMapObject.fileKmlRoute, removedKmlRoute: operationMapObject.removedKmlRoute
                              });
    });
    return operationsIdList;
  }

  private subscribeOnModelUpdate(){
    this.glSubscribeEvent(UPDATE_DATA_PREFIX + 'patrols-edit', (operation) => {
      // Emitido quando o modelo da operação foi atualizado do backend
      let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
      if (operationMapObject) {
        this.logger.debug("MapComponent.OnModelUpdate-Patrol");
        this.updateOperation(operation, false); // TODO scuri Isso não atualiza rota
      }
    });

    this.glSubscribeEvent(UPDATE_DATA_PREFIX + 'verifications-edit', (operation) => {
      // Emitido quando o modelo da operação foi atualizado do backend
      let operationMapObject = this.operationObjectsGet(operation.id, operation.type);
      if (operationMapObject) {
        this.logger.debug("MapComponent.OnModelUpdate-Verification");
        this.updateOperation(operation, false); // TODO scuri Isso não atualiza rota
      }
    });
  }

  /**
   * ###################################################
   * Rastro
   * ###################################################
   */

  checkDate(event, historicalTracking: HistoricalTrackingObject, key) {
    let dateString = event.target.value;
    if (dateString != "") {
      const formats = ["D/M/YYYY", "DD/MM/YYYY"];
      let date = moment(dateString, formats, true);
      historicalTracking[key] = !date.isValid();
    }
    else {
      historicalTracking[key] = true;
    }
  }

  checkTime(event, historicalTracking: HistoricalTrackingObject, key) {
    let dateString = event.target.value;
    if (dateString != "") {
      let date = moment(dateString, "H:m:s", true);
      historicalTracking[key] = !date.isValid();
    }
    else {
      historicalTracking[key] = true;
    }
  }

  onDateKeypressEvent(event: any){
    if (event.which < 47 || event.which > 57) { // barra + 0 a 9 - barra é logo antes do 0
      event.preventDefault();
    }
  }

  onTimeKeypressEvent(event: any){
    if (event.which < 48 || event.which > 58) { // 0 a 9 + :  - dois pontos é logo depois do 9
      event.preventDefault();
    }
  }

  getHistoricalTrackingId(mobileObjectId: string, sourceType: string, patrolTeam: PatrolTeamModel, operation: OperationModel, userId: string) {
    let id;

    // O mesmo celular pode ser usado por diferentes usuários
    if (sourceType == SourceType.MOBILE_APP) {
      if (userId) {
        id = userId;
      }
      else {
        id = mobileObjectId;
      }
    }
    else {
      id = mobileObjectId; // plate      
    }

    // O mesmo usuário pode ter feito diferentes operações
    if (operation) {
      id += '-' + operation.id + '-' + operation.type;
    }
    else if (patrolTeam) {
      id += '-' + patrolTeam.id;  // Equipe sem operação
    }

    return id;
  }

  getHistoricalTrackingTitle(mobileObjectId: string, sourceType: SourceType, patrolTeam: PatrolTeamModel, operation: OperationModel, user: UserModel): string {
    let title = '';

    if (operation){
      title += operation.identifier;
      title += ' - ';
    }
    else if (patrolTeam) {
      title += this.getPatrolTeamTitle(patrolTeam);  // Equipe sem operação
      title += ' - ';
    }

    if (sourceType == SourceType.MOBILE_APP) {
      title += user ? user.name: USER_NOT_FOUND;  // A pedido, aqui só mostra o nome do usuário, não inclui o login
    }
    else {
      title += mobileObjectId; // É a placa do veículo
    }

    return title;
  }

  private getHistoricalTrackingList() {
    let trackingList = [];
    this.historicalTrackingMap.forEach( (historicalTracking: HistoricalTrackingObject) => {
      trackingList.push({mobileObjectId: historicalTracking.mobileObjectId, 
                         operation: historicalTracking.operation, 
                         patrolTeam: historicalTracking.patrolTeam, 
                         user: historicalTracking.user, 
                         sourceType: historicalTracking.sourceType});
    });
    return trackingList;
  }

  private getHistoricalTrackingOptionsList() {
    let optionsList = [];
    this.historicalTrackingMap.forEach( (historicalTracking: HistoricalTrackingObject) => {
      optionsList.push({
        mobileObjectId: historicalTracking.mobileObjectId,
        finishTime: historicalTracking.finishTime,
        startTime: historicalTracking.startTime,
        visible: historicalTracking.visible,
        timeVisible: historicalTracking.timeVisible,
        stateVisible: historicalTracking.stateVisible
      });
    });
    return optionsList;
  }

  onHistoricalTrackingOperationListSelection(historicalTrackingOperationList: OperationModel[]){
    historicalTrackingOperationList.forEach((operation) =>{
      this.onHistoricalTrackingOperationSelection(operation, false);
    });
  }

  compareSignalTime(a: AbstractSignalModel, b: AbstractSignalModel): number {
    return a.timestamp - b.timestamp; // mais antigos primeiro, para a poder adicionar pontos novos ao final
  }

  sortHistoricalSignals(historicSignals: AbstractSignalModel[]) {
    historicSignals.sort(this.compareSignalTime);
  }

  private addHistoricalTrackingObject(mobileObjectId: string, sourceType: SourceType, patrolTeam: PatrolTeamModel, operation: OperationModel, user: UserModel, historicSignals: AbstractSignalModel[], startDate: number, endDate: number) {
    let id = this.getHistoricalTrackingId(mobileObjectId, sourceType, patrolTeam, operation, user?.id);
    let historicalTracking: HistoricalTrackingObject = this.historicalTrackingMap.get(id);
    if (!historicalTracking){
      historicalTracking = new HistoricalTrackingObject();
      this.historicalTrackingMap.set(id, historicalTracking);
      this.historicalTrackingsListedCount++;
    }

    historicalTracking.patrolTeam = patrolTeam;
    historicalTracking.operation = operation;
    historicalTracking.sourceType = sourceType;
    historicalTracking.user = user;
    historicalTracking.mobileObjectId = mobileObjectId;

    if (sourceType == SourceType.VEHICLE) {
      historicalTracking.vehicle = this.entityCacheService.getVehicleByPlate(mobileObjectId);
    }

    historicalTracking.visible = true;

    this.sortHistoricalSignals(historicSignals);

    historicalTracking.signals = historicSignals;

    if (startDate && endDate) {
      historicalTracking.firstTimestamp = startDate;
      historicalTracking.lastTimestamp = endDate;

      const firstTimeMoment = moment(historicalTracking.firstTimestamp);
      historicalTracking.firstTime = firstTimeMoment.format(TIME_FORMAT);
      historicalTracking.firstDate = firstTimeMoment.format(DATE_FORMAT);

      const lastTimeMoment = moment(historicalTracking.lastTimestamp);
      historicalTracking.lastTime = lastTimeMoment.format(TIME_FORMAT);
      historicalTracking.lastDate = lastTimeMoment.format(DATE_FORMAT);
    }

    this.updateTimeFields(historicalTracking);
    this.buildTrackingLine(historicalTracking, historicalTracking.signals);

    return historicalTracking;
  }

  private getTeamUser(patrolTeam: PatrolTeamModel, userId: string): UserModel {
    let user = null;
    patrolTeam.users.forEach(teamUser => {
      if (teamUser.id == userId) {
        user = teamUser;
      }
    });
    return user;
  }

  onHistoricalTrackingOperationSelection(operation: OperationModel, fit: boolean) {
    this.logger.debug('MapComponent.onHistoricalTrackingOperationSelection');

    // Mostra também a operação
    this.onOperationSelection(operation, false);

    let bounds: L.LatLngBounds = null;
    let polyLine: L.LatLng[] = []; 

    this.loadingListService.loadingOn();
    this.trackingService.loadFromServerByOperationIdAndOperationType(operation.id, operation.type, operation.startDate).pipe(first()).subscribe((historicSignals: AbstractSignalModel[]) => {
      this.logger.debug(`MapComponent.onHistoricalTrackingOperationSelection - Carregou Rastro de Operação com ${historicSignals?.length} sinais.`);
      this.loadingListService.loadingOff();
      
      if (!historicSignals || historicSignals.length < 1) {
        this.toastr.warning("Não foram encontrados sinais para a operação " + (operation.identifier? operation.identifier: ''), 'Leitura de Rastro');
        operation.patrolTeam.users.forEach(user => {
          this.addHistoricalTrackingObject(user.id, SourceType.MOBILE_APP, operation.patrolTeam, operation, user, [], null, null);
        });
        return;
      }

      let signalsPerId = {};
      historicSignals.forEach( (signal : AbstractSignalModel) => {
        let id = this.getHistoricalTrackingId(signal.mobileObjectId, signal.sourceType, operation.patrolTeam, operation, signal['userId']);
        if (!signalsPerId[id]) {
          signalsPerId[id] = [];
        }

        signalsPerId[id].push(signal);
      });

      for (const id in signalsPerId) {
        let firstSignal = signalsPerId[id][0] as SignalModel;
        let user = this.getTeamUser(operation.patrolTeam, firstSignal.userId);
        if (!user) {
          if (firstSignal.teamId != operation.patrolTeam.id)
            this.logger.error("MapComponent.onHistoricalTrackingOperationSelection - Dados inconsistentes no Sinal! Equipe não é a mesma da operação", 'Leitura de Rastro');
          else
            this.logger.error("MapComponent.onHistoricalTrackingOperationSelection - Dados inconsistentes na Equipe! Usuário não encontrado", 'Leitura de Rastro');
        }
        let historicalTracking = this.addHistoricalTrackingObject(firstSignal.mobileObjectId, SourceType.MOBILE_APP, operation.patrolTeam, operation, user, signalsPerId[id], null, null);

        if (user) {
          this.loadHistoricalTrackingOperationMarkers(historicalTracking);
        }

        if(fit && historicalTracking.trackingLine) {
           // O extend modifica a bounds, então tem que fazer uma cópia na primeira vez
          bounds ? bounds.extend(historicalTracking.trackingLine.getBounds()) : bounds = this.copyBounds(historicalTracking.trackingLine.getBounds()); // Esse get bounds retorna o bounds interno da Polyline
          polyLine = polyLine.concat(historicalTracking.trackingLine.getLatLngs() as L.LatLng[]);
        }
      }

      if (bounds){
        this.highlightBounds(bounds);
        this.highlightLatLongLine(polyLine);
        this.baseMap.fitBounds(bounds);
      }
    });

    let endDate = moment(operation.startDate).add(SHIFT_DURATION*2, 'hours').valueOf(); // Apenas um default, o que vai valer mesmo é o operationId

    if (!operation.patrolTeam.vehicle || !operation.patrolTeam.vehicle.plate) return; // Isso não deveria acontecer. Mas ainda acontece...

    this.loadingListService.loadingOn();
    this.trackingService.loadFromServerByMobileObjectId(operation.patrolTeam.vehicle.plate, SourceType.VEHICLE, operation.patrolTeam, operation, operation.startDate, endDate).pipe(first()).subscribe((historicSignals: AbstractSignalModel[]) => {
      this.logger.debug(`MapComponent.onHistoricalTrackingOperationSelection - Carregou Rastro de Veículo da Operação com ${historicSignals?.length} sinais.`);
      this.loadingListService.loadingOff();

      if (!historicSignals || historicSignals.length < 1) {
        this.addHistoricalTrackingObject(operation.patrolTeam.vehicle.plate, SourceType.VEHICLE, operation.patrolTeam, operation, null, [], null, null);
        return; // Não mostra warning, pode não haver sinais do veículo
      }

      let historicalTracking = this.addHistoricalTrackingObject(operation.patrolTeam.vehicle.plate, SourceType.VEHICLE, operation.patrolTeam, operation, null, historicSignals, null, null);

      if(fit && historicalTracking.trackingLine) {
        // O extend modifica a bounds, então tem que fazer uma cópia na primeira vez
        bounds ? bounds.extend(historicalTracking.trackingLine.getBounds()) : bounds = this.copyBounds(historicalTracking.trackingLine.getBounds()); // Esse get bounds retorna o bounds interno da Polyline
        polyLine = polyLine.concat(historicalTracking.trackingLine.getLatLngs() as L.LatLng[]);
      }

      if (bounds){
        this.highlightBounds(bounds);
        this.highlightLatLongLine(polyLine);
        this.baseMap.fitBounds(bounds);
      }
    });
  }

  onHistoricalTrackingSelection(tracking: TrackingModel){
    this.onHistoricalTracking(tracking.signal.mobileObjectId, <SourceType>tracking.signal.sourceType, tracking.patrolTeam, null, tracking.user, true);
  }

  onHistoricalTrackingListSelection(trackingList: TrackingModel[]){
    trackingList.forEach((tracking) =>{
      this.onHistoricalTracking(tracking.signal.mobileObjectId, <SourceType>tracking.signal.sourceType, tracking.patrolTeam, null, tracking.user, false);
    });
  }
  
  onHistoricalTrackingLastOperationSelection(tracking : TrackingModel){
    this.onHistoricalTracking(tracking.signal.mobileObjectId, <SourceType>tracking.signal.sourceType, tracking.patrolTeam, tracking.operation, tracking.user, true);
  }

  onHistoricalTrackingLastOperationListSelection(trackingList: TrackingModel[]){
    trackingList.forEach((tracking) =>{
      this.onHistoricalTracking(tracking.signal.mobileObjectId, <SourceType>tracking.signal.sourceType, tracking.patrolTeam, tracking.operation, tracking.user, false);
    });
  }

  onHistoricalTrackingUserListSelection(userList: UserModel[]){
    userList.forEach((user) =>{
      this.onHistoricalTrackingUserSelection(user, false);
    });
  }

  onHistoricalTrackingUserSelection(user: UserModel, fit: boolean) {
    this.onHistoricalTracking(user.id, SourceType.MOBILE_APP, null, null, user, fit);
  }

  onHistoricalTrackingVehicleListSelection(vehicleList: VehicleModel[]){
    vehicleList.forEach((vehicle) =>{
      this.onHistoricalTrackingVehicleSelection(vehicle, false);
    });
  }

  onHistoricalTrackingVehicleSelection(vehicle: VehicleModel, fit: boolean) {
    this.onHistoricalTracking(vehicle.plate, SourceType.VEHICLE, null, null, null, fit);
  }

  onHistoricalTrackingTeamListSelection(historicalTrackingTeamList: PatrolTeamModel[]){
    historicalTrackingTeamList.forEach((patrolTeam) =>{
      this.onHistoricalTrackingTeamSelection(patrolTeam, false);
    });
  }

  onHistoricalTrackingTeamSelection(patrolTeam: PatrolTeamModel, fit: boolean) {
    this.logger.debug('MapComponent.onHistoricalTrackingTeamSelection');

    let bounds: L.LatLngBounds = null;
    let polyLine: L.LatLng[] = []; 

    let startDate = moment().subtract(SHIFT_DURATION, 'hours').valueOf(); // startDate = Now - Shift
    let endDate = moment(startDate).add(SHIFT_DURATION, 'hours').valueOf(); // subtract e add mudam o moment original, então não dá para reusar

    this.loadingListService.loadingOn();
    this.trackingService.loadFromServerByTeamId(patrolTeam.id, startDate, endDate).pipe(first()).subscribe((historicSignals: AbstractSignalModel[]) => {
      this.logger.debug(`MapComponent.onHistoricalTrackingTeamSelection - Carregou Rastro de Equipe com ${historicSignals?.length} sinais.`);
      this.loadingListService.loadingOff();

      if (!historicSignals || historicSignals.length < 1) {
        this.toastr.warning("Não foram encontrados sinais para a equipe " + patrolTeam.name, 'Leitura de Rastro');
        patrolTeam.users.forEach(user => {
          this.addHistoricalTrackingObject(user.id, SourceType.MOBILE_APP, patrolTeam, null, user, [], startDate, endDate);
        });
        return;
      }

      let signalsPerId = {};
      historicSignals.forEach( (signal : AbstractSignalModel) => {
        let id = this.getHistoricalTrackingId(signal.mobileObjectId, signal.sourceType, patrolTeam, null, signal['userId']);
        if (!signalsPerId[id]) {
          signalsPerId[id] = [];
        }

        signalsPerId[id].push(signal);
      });

      for (const id in signalsPerId) {
        let firstSignal = signalsPerId[id][0] as SignalModel;
        let user = this.getTeamUser(patrolTeam, firstSignal.userId);
        if (!user) {
          if (firstSignal.teamId != patrolTeam.id)
            this.logger.error("MapComponent.onHistoricalTrackingTeamSelection - Dados inconsistentes no Sinal! Equipe não é a mesma", 'Leitura de Rastro');
          else
            this.logger.error("MapComponent.onHistoricalTrackingTeamSelection - Dados inconsistentes na Equipe! Usuário não encontrado", 'Leitura de Rastro');
        }
        let historicalTracking = this.addHistoricalTrackingObject(firstSignal.mobileObjectId, SourceType.MOBILE_APP, patrolTeam, null, user, signalsPerId[id], startDate, endDate);
        if(fit && historicalTracking.trackingLine) {
          // O extend modifica a bounds, então tem que fazer uma cópia na primeira vez
          bounds ? bounds.extend(historicalTracking.trackingLine.getBounds()) : bounds = this.copyBounds(historicalTracking.trackingLine.getBounds()); // Esse get bounds retorna o bounds interno da Polyline
          polyLine = polyLine.concat(historicalTracking.trackingLine.getLatLngs() as L.LatLng[]);
        }
      }

      if (bounds){
        this.highlightBounds(bounds);
        this.highlightLatLongLine(polyLine);
        this.baseMap.fitBounds(bounds);
      }
    });

    this.loadingListService.loadingOn();
    this.trackingService.loadFromServerByMobileObjectId(patrolTeam.vehicle.plate, SourceType.VEHICLE, patrolTeam, null, startDate, endDate).pipe(first()).subscribe((historicSignals: AbstractSignalModel[]) => {
      this.logger.debug(`MapComponent.onHistoricalTrackingTeamSelection - Carregou Rastro de Veículo da Equipe com ${historicSignals?.length} sinais.`);
      this.loadingListService.loadingOff();

      if (!historicSignals || historicSignals.length < 1) {
        this.addHistoricalTrackingObject(patrolTeam.vehicle.plate, SourceType.VEHICLE, patrolTeam, null, null, [], startDate, endDate);
        return;  // Não mostra warning, pode não haver sinais do veículo
      }

      let historicalTracking = this.addHistoricalTrackingObject(patrolTeam.vehicle.plate, SourceType.VEHICLE, patrolTeam, null, null, historicSignals, startDate, endDate);

      if(fit && historicalTracking.trackingLine) {
        // O extend modifica a bounds, então tem que fazer uma cópia na primeira vez
        bounds ? bounds.extend(historicalTracking.trackingLine.getBounds()) : bounds = this.copyBounds(historicalTracking.trackingLine.getBounds()); // Esse get bounds retorna o bounds interno da Polyline
        polyLine = polyLine.concat(historicalTracking.trackingLine.getLatLngs() as L.LatLng[]);
      }

      if (bounds){
        this.highlightBounds(bounds);
        this.highlightLatLongLine(polyLine);
        this.baseMap.fitBounds(bounds);
      }
    });
  }

  onHistoricalTracking(mobileObjectId: string, sourceType: SourceType, patrolTeam: PatrolTeamModel, operation: OperationModel, user: UserModel, fit: boolean) {
    this.logger.debug('MapComponent.onHistoricalTracking');

    // Chamado a partir do rastreamento

    if (operation) {
      // Mostra também a operação
      this.onOperationSelection(operation, false);
    }

    // Data/hora a partir da qual os dados históricos serão recuperados do servidor inicialmente;
    let startDate: number;
    let endDate: number;
    if (operation) {
      startDate = operation.startDate;
      endDate = moment(startDate).add(SHIFT_DURATION*2, 'hours').valueOf(); // endDate = startDate + Shift
    }
    else {
      endDate = moment().valueOf();
      startDate = moment(endDate).subtract(SHIFT_DURATION, 'hours').valueOf();  // startDate = Now - Shift
    }

    this.loadingListService.loadingOn();
    this.trackingService.loadFromServerByMobileObjectId(mobileObjectId, sourceType, patrolTeam, operation, startDate, endDate).pipe(first()).subscribe((historicSignals: AbstractSignalModel[]) => {
      this.logger.debug(`MapComponent.onHistoricalTracking - Carregou Rastro do Rastreamento com ${historicSignals?.length} sinais.`);
      this.loadingListService.loadingOff();

      if (!historicSignals || historicSignals.length < 1) {
        this.toastr.warning("Não foram encontrados sinais para o rastro de " + this.getHistoricalTrackingTitle(mobileObjectId, sourceType, patrolTeam, operation, user), 'Leitura de Rastro');
        this.addHistoricalTrackingObject(mobileObjectId, sourceType, patrolTeam, operation, user, [], startDate, endDate);
        return;
      }

      let historicalTracking = this.addHistoricalTrackingObject(mobileObjectId, sourceType, patrolTeam, operation, user, historicSignals, startDate, endDate);

      if (operation) {
        this.loadHistoricalTrackingOperationMarkers(historicalTracking);
      }

      if (fit && this.historicalTrackingsVisible) {
        let bounds = this.fitHistoricalTrackingBounds(historicalTracking);
        if (bounds) this.highlightBounds(bounds);
        if (historicalTracking.trackingLine) this.highlightPolyline(historicalTracking.trackingLine);
      }
    });
  }

  private addStateMarker(historicalTracking: HistoricalTrackingObject, marker: MarkerModel){
    let inOperation = false;
    if (historicalTracking.firstSignalTimestamp == historicalTracking.startTimestamp && marker.timestamp < historicalTracking.startTimestamp){
      inOperation = true;
    }
    if (historicalTracking.lastSignalTimestamp == historicalTracking.finishTimestamp && marker.timestamp > historicalTracking.finishTimestamp){
      inOperation = true;
    }
    if (inOperation || marker.timestamp >= historicalTracking.startTimestamp && marker.timestamp <= historicalTracking.finishTimestamp) {
      let stateMarker = this.baseMap.createMarker([marker.location.lat, marker.location.lng], this.markerIcon, MarkerModel.getPopupContent(marker, historicalTracking.patrolTeam.name));
      stateMarker.bindTooltip(MarkerModel.getTooltipContent(marker));
      historicalTracking.stateMarkers.push(stateMarker);
      return stateMarker;
    }
  }

  private subscribeToNewMarkersNotifications() {
    this.onNewMarkerSubscription = this.markerService.onNewMarkerReceived().subscribe((marker: MarkerModel) => {
      if (!marker) return;

      // ATENCAO: appointedLocation e serverTimestamp não vem no avro do websocket
      // id vem em objectId
      if (!marker.id) {
        marker.id = marker['objectId'];
      }
      // Location vem separada
      if (!marker.location){
        marker.location = {lat: marker['latitude'], lng: marker['longitude']};
      }

      // Mesmo filtro que loadHistoricalTrackingOperationMarkers
      if (marker.sourceType === SourceType.MOBILE_APP && 
          marker.markerType !== MarkerType.TEXT_MESSAGE &&
          marker.markerType !== MarkerType.AUDIO_MESSAGE &&
          marker.markerType !== MarkerType.IMAGE_MESSAGE &&
          marker.markerType !== MarkerType.VIDEO_MESSAGE){
        // mudanças de estado
        this.historicalTrackingMap.forEach( historicalTracking => {
          if (historicalTracking.sourceType == SourceType.MOBILE_APP &&
              historicalTracking.patrolTeam && historicalTracking.patrolTeam.id == marker.patrolTeamId &&
              historicalTracking.operation && (historicalTracking.operation.id == marker.operationId && historicalTracking.operation.type == marker.operationType) &&
              historicalTracking.user && historicalTracking.user.id == marker.userId){
            if (!historicalTracking.markers) historicalTracking.markers = [];
            historicalTracking.markers.push(marker);

            let stateMarker = this.addStateMarker(historicalTracking, marker);

            if(historicalTracking.visible && historicalTracking.stateVisible && stateMarker) {
              this.baseMap.addMarker(stateMarker);
            }
          }
        });
      }

      this.renderComponent();
    });
  }

  buildStateMarkers(historicalTracking: HistoricalTrackingObject) {
    this.removeHistoricalTrackingStateMarkers(historicalTracking);

    if (!historicalTracking.markers || historicalTracking.markers.length == 0) return;

    historicalTracking.stateMarkers = [];
    historicalTracking.markers.forEach( marker => {
      this.addStateMarker(historicalTracking, marker);
    });

    if(historicalTracking.visible && historicalTracking.stateVisible) {
      historicalTracking.stateMarkers.forEach(marker =>{
        this.baseMap.addMarker(marker);
      });
    }
  }

  loadHistoricalTrackingOperationMarkers(historicalTracking: HistoricalTrackingObject){
    let markerFilterModel: MarkerFilterModel = new MarkerFilterModel();
    markerFilterModel.patrolTeamId = historicalTracking.operation.patrolTeam.id;
    markerFilterModel.operationId = historicalTracking.operation.id;
    markerFilterModel.operationType = historicalTracking.operation.type;

    this.markerService.loadFilteredListFromRestApi(null, null, SORT_RECEIVED_SERVER_TIMESTAMP_DESC, markerFilterModel).pipe(first()).subscribe((markers: MarkerModel[]) =>{
      historicalTracking.markers = [];
      markers.forEach((marker: MarkerModel) => {
        if (marker.sourceType === SourceType.MOBILE_APP && 
            marker.markerType !== MarkerType.TEXT_MESSAGE &&
            marker.markerType !== MarkerType.AUDIO_MESSAGE &&
            marker.markerType !== MarkerType.IMAGE_MESSAGE &&
            marker.markerType !== MarkerType.VIDEO_MESSAGE &&
            marker.userId == historicalTracking.user.id){
          // mudanças de estado
          historicalTracking.markers.push(marker);
        }
      });
      
      this.buildStateMarkers(historicalTracking);
    }, error => this.logger.error(error));
  }

  onHistoricalTrackingClick(historicalTracking: HistoricalTrackingObject){
    if (historicalTracking.visible) {
      let bounds = this.fitHistoricalTrackingBounds(historicalTracking);
      if (bounds) this.highlightBounds(bounds);
      if (historicalTracking.trackingLine) this.highlightPolyline(historicalTracking.trackingLine);
    }
  }

  private removeHistoricalTrackingStateMarkers(historicalTracking: HistoricalTrackingObject){
    if(historicalTracking.stateMarkers) {
      historicalTracking.stateMarkers.forEach(marker =>{
        this.baseMap.removeMarker(marker);
      });
    }
  }

  private removeHistoricalTrackingTimeMarkers(historicalTracking: HistoricalTrackingObject){
    if(historicalTracking.timeMarkers) {
      historicalTracking.timeMarkers.forEach(marker =>{
        this.baseMap.removeMarker(marker);
      });
    }
  }

  private removeHistoricalTrackingLine(historicalTracking: HistoricalTrackingObject){
    if(historicalTracking.trackingLine) {
      this.baseMap.removeLayerFromMap(historicalTracking.trackingLine);
    }
    if(historicalTracking.trackingDecorator) {
      this.baseMap.removeLayerFromMap(historicalTracking.trackingDecorator);
    }
  }

  onHistoricalTrackingRemoveClick(id, historicalTracking: HistoricalTrackingObject) {
    this.removeHistoricalTrackingLine(historicalTracking);
    this.removeHistoricalTrackingTimeMarkers(historicalTracking);
    this.removeHistoricalTrackingStateMarkers(historicalTracking);
    this.historicalTrackingMap.delete(id);
  }

  onHistoricalTrackingsRemoveClick() {
    this.historicalTrackingMap.forEach( (historicalTracking: HistoricalTrackingObject, id) => {
      this.onHistoricalTrackingRemoveClick(id, historicalTracking);
    });
  }

  onHistoricalTrackingsVisibilityClick()  {
    this.historicalTrackingMap.forEach( (historicalTracking: HistoricalTrackingObject) => {
      // Se global é visible/invisible, faz todos invisible/visible, independente do estado individual
      if (this.historicalTrackingsVisible) {
        this.removeHistoricalTrackingLine(historicalTracking);
        this.removeHistoricalTrackingTimeMarkers(historicalTracking);
        this.removeHistoricalTrackingStateMarkers(historicalTracking);
        historicalTracking.visible = false;
      }
      else {
        if (historicalTracking.trackingLine) {
          this.baseMap.addLayerToMap(historicalTracking.trackingLine);
          this.baseMap.addLayerToMap(historicalTracking.trackingDecorator);
        }
        if(historicalTracking.timeMarkers && historicalTracking.timeVisible) {
          historicalTracking.timeMarkers.forEach(marker =>{
            this.baseMap.addMarker(marker);
          });
        }
        if(historicalTracking.stateMarkers && historicalTracking.stateVisible) {
          historicalTracking.stateMarkers.forEach(marker =>{
            this.baseMap.addMarker(marker);
          });
        }
        historicalTracking.visible = true;
      }
    });

    if (this.historicalTrackingsVisible) {
      this.historicalTrackingsVisible = false;
    }
    else {
      this.historicalTrackingsVisible = true;
    }
  }

  onHistoricalTrackingVisibilityClick(historicalTracking: HistoricalTrackingObject) {
    // se é individual, então troca o estado (como é um botão o estado ainda não mudou)
    if (historicalTracking.visible) {
      this.removeHistoricalTrackingLine(historicalTracking);
      this.removeHistoricalTrackingTimeMarkers(historicalTracking);
      this.removeHistoricalTrackingStateMarkers(historicalTracking);
      historicalTracking.visible = false;
    }
    else
    {
      if (historicalTracking.trackingLine) {
        this.baseMap.addLayerToMap(historicalTracking.trackingLine);
      }
      if(historicalTracking.timeMarkers && historicalTracking.timeVisible) {
        historicalTracking.timeMarkers.forEach(marker =>{
          this.baseMap.addMarker(marker);
        });
      }
      if(historicalTracking.stateMarkers && historicalTracking.stateVisible) {
        historicalTracking.stateMarkers.forEach(marker =>{
          this.baseMap.addMarker(marker);
        });
      }
      historicalTracking.visible = true;
    }
  }

  onToggleStateMarkerChange(historicalTracking: HistoricalTrackingObject) {
    if (!historicalTracking.stateVisible) {
      this.removeHistoricalTrackingStateMarkers(historicalTracking);
    }
    else
    {
      if(historicalTracking.stateMarkers) {
        historicalTracking.stateMarkers.forEach(marker =>{
          this.baseMap.addMarker(marker);
        });
      }
    }
  }

  onToggleTimeMarkerChange(historicalTracking: HistoricalTrackingObject) {
    if (!historicalTracking.timeVisible) {
      this.removeHistoricalTrackingTimeMarkers(historicalTracking);
    }
    else
    {
      if(historicalTracking.timeMarkers) {
        historicalTracking.timeMarkers.forEach(marker =>{
          this.baseMap.addMarker(marker);
        });
      }
    }
  }

  private reloadSignalsForHistoricalTracking(historicalTracking: HistoricalTrackingObject) {
    // recarrega os dados do servidor
    this.loadingListService.loadingOn();
    this.trackingService.loadFromServerByMobileObjectId(historicalTracking.mobileObjectId, historicalTracking.sourceType, historicalTracking.patrolTeam, historicalTracking.operation, historicalTracking.firstTimestamp, historicalTracking.lastTimestamp).pipe(first()).subscribe((historicSignals: AbstractSignalModel[]) => {
      this.logger.debug(`MapComponent.reloadSignalsForHistoricalTracking - Carregou Rastro com ${historicSignals?.length} sinais.`);
      this.loadingListService.loadingOff();

      if (!historicSignals || historicSignals.length < 1) {
        this.toastr.warning("Não foram encontrados sinais para o rastro de " + this.getHistoricalTrackingTitle(historicalTracking.mobileObjectId, historicalTracking.sourceType, historicalTracking.patrolTeam, historicalTracking.operation, historicalTracking.user), 'Leitura de Rastro');
      }

      this.sortHistoricalSignals(historicSignals);

      historicalTracking.signals = historicSignals;

      this.updateTimeFields(historicalTracking);
      this.buildStateMarkers(historicalTracking);
      this.buildTrackingLine(historicalTracking, historicalTracking.signals);
    },
    (error) => {
      if(error.status == 400){
        this.toastr.warning("Parâmetros inválidos, o intervalo entre a data de início e fim não deve ser maior a 15 días");
      }
      else{
        this.logger.error('Erro ao obter o rastro ', error);
      }
      this.loadingListService.loadingOff();
    }    
    );
  }

  /** Centraliza o mapa nos dados históricos */
  private fitHistoricalTrackingBounds(historicalTracking: HistoricalTrackingObject) {
    let bounds: L.LatLngBounds = null;

    if(historicalTracking.trackingLine) {
      bounds = this.copyBounds(historicalTracking.trackingLine.getBounds()); // Esse get bounds retorna o bounds interno da Polyline
    }

    if (bounds) {
      this.baseMap.fitBounds(bounds);
    }
    
    return bounds;
  }

  /** Callback para restabelecer o histórico completo do rastro */
  onHistoricalTrackingResetFilterClick(historicalTracking: HistoricalTrackingObject) {
    this.updateTimeFields(historicalTracking);
    this.buildStateMarkers(historicalTracking);
    this.buildTrackingLine(historicalTracking, historicalTracking.signals);
  }

  onStartTimeSliderChange(event: MatSliderChange, historicalTracking: HistoricalTrackingObject) {
    historicalTracking.startTimestamp = event.value;
    const startTimeMoment = moment(historicalTracking.startTimestamp);
    historicalTracking.startTime = startTimeMoment.format(TIME_FORMAT);
    historicalTracking.startDate = startTimeMoment.format(DATE_FORMAT);
    this.filterHistoricalTracking(historicalTracking);
  }

  onFinishTimeSliderChange(event: MatSliderChange, historicalTracking: HistoricalTrackingObject) {
    historicalTracking.finishTimestamp = event.value;
    if (historicalTracking.finishTimestamp/1000 == historicalTracking.lastSignalTimestamp/1000) {// compara em segundos apenas (ignora miliseconds)
      historicalTracking.finishChanged = false;
    }
    else {
      historicalTracking.finishChanged = true;
    }
    const finishTimeMoment = moment(historicalTracking.finishTimestamp);
    historicalTracking.finishTime = finishTimeMoment.format(TIME_FORMAT);
    historicalTracking.finishDate = finishTimeMoment.format(DATE_FORMAT);
    this.filterHistoricalTracking(historicalTracking);
  }

  /** Atualiza a interface a partir dos sinais */
  private updateTimeFields(historicalTracking: HistoricalTrackingObject, runTime?: boolean) {
    if (historicalTracking.signals.length != 0){
      historicalTracking.firstSignalTimestamp = historicalTracking.signals[0].timestamp;
      historicalTracking.lastSignalTimestamp = historicalTracking.signals[historicalTracking.signals.length-1].timestamp; // ordem cronológica

      if (runTime && historicalTracking.lastSignalTimestamp > historicalTracking.lastTimestamp){
        historicalTracking.lastTimestamp = historicalTracking.lastSignalTimestamp;

        const lastTimeMoment = moment(historicalTracking.lastTimestamp);
        historicalTracking.lastTime = lastTimeMoment.format(TIME_FORMAT);
        historicalTracking.lastDate = lastTimeMoment.format(DATE_FORMAT);
      }
    }
    else {
      // Não tem nenhum sinal, apenas copia os valores
      historicalTracking.firstSignalTimestamp = historicalTracking.firstTimestamp;
      historicalTracking.lastSignalTimestamp = historicalTracking.lastTimestamp;
    }

    if (!runTime) {
      const firstSignalMoment = moment(historicalTracking.firstSignalTimestamp);
      historicalTracking.startTime = firstSignalMoment.format(TIME_FORMAT);
      historicalTracking.startDate = firstSignalMoment.format(DATE_FORMAT);
      historicalTracking.startTimestamp = historicalTracking.firstSignalTimestamp;

      const lastSignalMoment = moment(historicalTracking.lastSignalTimestamp);
      historicalTracking.finishTime = lastSignalMoment.format(TIME_FORMAT);
      historicalTracking.finishDate = lastSignalMoment.format(DATE_FORMAT);
      historicalTracking.finishTimestamp = historicalTracking.lastSignalTimestamp;
    }
    else {
      // Em run time atualiza somente se a string não foi modificada
      if (!historicalTracking.finishChanged){
        const lastSignalMoment = moment(historicalTracking.lastSignalTimestamp);
        historicalTracking.finishTime = lastSignalMoment.format(TIME_FORMAT);
        historicalTracking.finishDate = lastSignalMoment.format(DATE_FORMAT);
        historicalTracking.finishTimestamp = historicalTracking.lastSignalTimestamp;
      }
    }
  }

  /** Atualiza os sinais a partir da interface */
  onHistoricalTrackingApplyFilterClick(historicalTracking: HistoricalTrackingObject) {
    const startTimestamp = DateUtils.stringDateTimeToTimestamp(historicalTracking.startDate, historicalTracking.startTime, true);
    const finishTimestamp = DateUtils.stringDateTimeToTimestamp(historicalTracking.finishDate, historicalTracking.finishTime, false);
    if (startTimestamp > finishTimestamp) {
      this.toastr.warning("O valor final deve ser maior que o valor inicial.", "Parâmetros inválidos");
      return;
    }

    historicalTracking.startTimestamp = startTimestamp;
    historicalTracking.finishTimestamp = finishTimestamp; 

    if (historicalTracking.startTimestamp < historicalTracking.firstSignalTimestamp) {
      historicalTracking.startTimestamp = historicalTracking.firstSignalTimestamp;
      const firstSignalMoment = moment(historicalTracking.firstSignalTimestamp);
      historicalTracking.startTime = firstSignalMoment.format(TIME_FORMAT);
      historicalTracking.startDate = firstSignalMoment.format(DATE_FORMAT);
    }

    if (historicalTracking.finishTimestamp > historicalTracking.lastSignalTimestamp) {
      historicalTracking.finishTimestamp = historicalTracking.lastSignalTimestamp;
      const lastSignalMoment = moment(historicalTracking.lastSignalTimestamp);
      historicalTracking.finishTime = lastSignalMoment.format(TIME_FORMAT);
      historicalTracking.finishDate = lastSignalMoment.format(DATE_FORMAT);
      }

    this.filterHistoricalTracking(historicalTracking);
  }

  onHistoricalTrackingLoadClick(historicalTracking: HistoricalTrackingObject) {
    const firstTimestamp = DateUtils.stringDateTimeToTimestamp(historicalTracking.firstDate, historicalTracking.firstTime, true);
    const lastTimestamp = DateUtils.stringDateTimeToTimestamp(historicalTracking.lastDate, historicalTracking.lastTime, false);
    if (firstTimestamp > lastTimestamp) {
      this.toastr.warning("O valor final deve ser maior que o valor inicial.", "Parâmetros inválidos");
      return;
    }

    const numberOfHours = ((((lastTimestamp - firstTimestamp) / 1000) / 60)/ 60);
    
    if(numberOfHours > MAX_TIME_OF_TRACE){
      this.toastr.warning("O máximo interválo de tempo a ser consultado é 15 días.", "Parâmetros inválidos");
      return;
    }

    historicalTracking.firstTimestamp = firstTimestamp;
    historicalTracking.lastTimestamp = lastTimestamp;

    this.reloadSignalsForHistoricalTracking(historicalTracking);
  }

  private getCompany(historicalTracking: HistoricalTrackingObject): CompanyModel{
    if (historicalTracking.patrolTeam) {
      return historicalTracking.patrolTeam.company;
    }
    if (historicalTracking.sourceType === SourceType.VEHICLE) {
      return historicalTracking.vehicle?.company;
    }
    else {
      return historicalTracking.user?.company;
    }
  }
  
  onHistoricalTrackingFilterDataClick(historicalTracking: HistoricalTrackingObject) {
    const historicSignals = this.filterHistoricalSignals(historicalTracking);
    if (!historicSignals || historicSignals.length < 1) {
      this.toastr.warning("Sem dados para mostrar. \nO filtro eliminou todos os sinais do rastro de " + this.getHistoricalTrackingTitle(historicalTracking.mobileObjectId, historicalTracking.sourceType, historicalTracking.patrolTeam, historicalTracking.operation, historicalTracking.user), 'Filtro de Rastro');
      return;
    }

    this.dialog.open(TrackPointsDialogComponent, {
      data: {
        signals: historicSignals, 
        title: this.getHistoricalTrackingTitle(historicalTracking.mobileObjectId, historicalTracking.sourceType, historicalTracking.patrolTeam, historicalTracking.operation, historicalTracking.user),
        isVehicle: historicalTracking.sourceType == SourceType.VEHICLE? true: false,
        showOperation: historicalTracking.operation? false: true, // Mostra dados de operação somente se não é o rastro de uma operação
        companyName: this.getCompany(historicalTracking)?.name
      },
      panelClass: 'sipd-modal'
    });
  }

  private filterHistoricalTracking(historicalTracking: HistoricalTrackingObject){
    const historicSignals = this.filterHistoricalSignals(historicalTracking);
    if (!historicSignals || historicSignals.length < 1) {
      this.toastr.warning("O filtro eliminou todos os sinais do rastro de " + this.getHistoricalTrackingTitle(historicalTracking.mobileObjectId, historicalTracking.sourceType, historicalTracking.patrolTeam, historicalTracking.operation, historicalTracking.user), 'Filtro de Rastro');
    }
    this.buildTrackingLine(historicalTracking, historicSignals);
    this.buildStateMarkers(historicalTracking);
  }

  /** Filtra os sinais históricos já existentes no cliente dentro de um período */
  private filterHistoricalSignals(historicalTracking: HistoricalTrackingObject) : AbstractSignalModel[] {
    return historicalTracking.signals.filter( (signal: AbstractSignalModel) => {
      return signal.timestamp >= historicalTracking.startTimestamp && signal.timestamp <= historicalTracking.finishTimestamp;
    });
  }

  private getHistoricalTrackingPopupContent(historicalTracking: HistoricalTrackingObject, search: boolean = false): string{
    const operation: OperationModel = historicalTracking.operation;
    const patrolTeam: PatrolTeamModel = historicalTracking.patrolTeam;
    const user: UserModel = historicalTracking.user;
    const title = TrackingModel.getTitle(historicalTracking.sourceType, historicalTracking.mobileObjectId, patrolTeam, user, search);
    return `<h5 style="text-align: center">${historicalTracking.sourceType == SourceType.MOBILE_APP?'Rastro de Profissional': 'Rastro de Veículo'}</h5>
            <h6 style="text-align: center"><b> ${ title }</b></h6>
            ${ operation ? OperationModel.getPopupContent(operation) : ''}
            ${ PatrolTeamModel.getPopupContent(patrolTeam, search) }
            <div> Distância Percorrida: ${historicalTracking.accumDistance.toLocaleString("pt-br", {minimumFractionDigits: 3, maximumFractionDigits: 3}) + ' km'} </div>
           `;
  }

  private getTimeMarkerTooltipContent(historicalTracking: HistoricalTrackingObject, signal: AbstractSignalModel){
    const patrolTeam: PatrolTeamModel = historicalTracking.patrolTeam;
    const user: UserModel = historicalTracking.user;
    const title = TrackingModel.getTitle(historicalTracking.sourceType, historicalTracking.mobileObjectId, patrolTeam, user);
    return `<h6 style="text-align: center">${ title }</h6>
            <div style="text-align: center"><b> ${DateUtils.timestampToStringInSeconds(signal.timestamp)} </b></div>
            <div style="text-align: center"> ${FieldUtils.coordToString(signal.latitude)},${FieldUtils.coordToString(signal.longitude)} </div>
            <div style="text-align: center"> Distância Percorrida: ${historicalTracking.accumDistance.toLocaleString("pt-br", {minimumFractionDigits: 3, maximumFractionDigits: 3}) + ' km'} </div>
          `;
  }

  private addTimeMarker(historicalTracking: HistoricalTrackingObject, signal: AbstractSignalModel) {
    let marker = L.circleMarker(L.latLng(signal.latitude, signal.longitude), {radius: MapInfo.TRACKING_LINE_WEIGHT, color: MapInfo.TRACKING_LINE_COLOR, fill: true, fillOpacity: 1.0});
    const tips = this.getTimeMarkerTooltipContent(historicalTracking, signal);
    marker.bindTooltip(tips);
    marker.bindPopup(tips, { minWidth: MapInfo.POPUP_MIN_WIDTH });
    historicalTracking.timeMarkers.push(marker);
    if ((this.historicalTrackingsVisible || historicalTracking.visible) && historicalTracking.timeVisible)
      this.baseMap.addMarker(marker);
  }

  private signalToTurfPoint(signal : AbstractSignalModel): turf.Coord{
    return turf.point([signal.longitude, signal.latitude]); // Note que é invertido (longitude, latitude)
  }

  private latLngToTurfPoint(latLng: L.LatLng): turf.Coord{
    return turf.point([latLng.lng, latLng.lat]); // Note que é invertido (longitude, latitude)
  }

  private buildTimeMarkers(historicalTracking: HistoricalTrackingObject, historicSignals: AbstractSignalModel[]) {
    historicalTracking.timeMarkers = [];
    let prevLatLng: turf.Coord, lastLatLng: turf.Coord;

    historicalTracking.accumDistance = 0;
    this.addTimeMarker(historicalTracking, historicSignals[0]);
    lastLatLng = prevLatLng = this.signalToTurfPoint(historicSignals[0]);

    for (let i = 1; i < historicSignals.length; i++) {
      let signal = historicSignals[i];
      let curLatLng = this.signalToTurfPoint(signal);
      historicalTracking.accumDistance += turf.distance(prevLatLng, curLatLng, {units: 'kilometers'});
      prevLatLng = curLatLng;

      if(turf.distance(lastLatLng, curLatLng, {units: 'kilometers'}) > MapInfo.TIME_MARKER_DISTANCE) {
        this.addTimeMarker(historicalTracking, signal);
        lastLatLng = curLatLng;
      }
    };
  }

  private addArrowsToTracking(historicalTracking: HistoricalTrackingObject){
    historicalTracking.trackingDecorator = L.polylineDecorator(historicalTracking.trackingLine, {
      patterns: [{
          repeat: 200,
          symbol: L.Symbol.arrowHead({
              pixelSize: MapInfo.TRACKING_LINE_WEIGHT*3,
              headAngle: 75,
              polygon: false,
              pathOptions: {
                  stroke: true,
                  weight: MapInfo.TRACKING_LINE_WEIGHT,
                  color: MapInfo.TRACKING_LINE_COLOR
              }
          })
      }]
    });
  }

  /** Constrói o objeto geográfico do rastro a partir de uma lista de sinais */
  private buildTrackingLine(historicalTracking: HistoricalTrackingObject, historicSignals: AbstractSignalModel[]){
    const latLngsArray = [];
    let latLngs = [];

    if (!historicSignals || historicSignals.length < 1) {
      return;
    }

    historicSignals.forEach( (signal : AbstractSignalModel) => {

      latLngs.push([signal.latitude, signal.longitude]);

      // Subdivide o poligono se a distancia entre dois pontos for maior que o definido
      // Comentado temporariamente para avaliar rastros de veículos em produção
      // Outro problema Ronda 22021016R0001 dá erro de null[0] nessa etapa
      // if(latLngs.length>1){
      //   const from = this.latLngToTurfPoint(latLngs[latLngs.length-2]); // penultimo
      //   const to = this.latLngToTurfPoint(latLngs[latLngs.length-1]); // ultimo adicionado
      //   const dist = turf.distance(from, to, {units: 'kilometers'});
      //   if (dist > MapInfo.MAX_DISTANCE_KM) {
      //     const lastPoint = latLngs[latLngs.length-1];
      //     const temp = latLngs.slice(0,-1);
      //     if (temp.length === 1) {
      //       latLngsArray.push(temp.push(latLngs[0]));
      //     }
      //     else {
      //       latLngsArray.push(temp);
      //     }
      //     latLngs = [];
      //     latLngs.push(lastPoint);
      //   }
      // }
    });

    if (latLngs.length === 1) {
      latLngs.push(latLngs[0]); // repete o ponto para permitir à polyline traçar uma linha
      latLngsArray.push(latLngs);
    }
    else
    {
      latLngsArray.push(latLngs);
    }

    this.removeHistoricalTrackingLine(historicalTracking);
    this.removeHistoricalTrackingTimeMarkers(historicalTracking);

    this.buildTimeMarkers(historicalTracking, historicSignals);

    historicalTracking.trackingLine = L.polyline(latLngsArray, { color: MapInfo.TRACKING_LINE_COLOR, weight: MapInfo.TRACKING_LINE_WEIGHT, dashArray: MapInfo.TRACKING_LINE_STYLE });
    historicalTracking.popupContent = this.getHistoricalTrackingPopupContent(historicalTracking, true);
    historicalTracking.trackingLine.bindPopup(this.getHistoricalTrackingPopupContent(historicalTracking));
    this.addArrowsToTracking(historicalTracking);

    if (this.historicalTrackingsVisible || historicalTracking.visible){
      this.baseMap.addLayerToMap(historicalTracking.trackingLine);
      this.baseMap.addLayerToMap(historicalTracking.trackingDecorator);
      historicalTracking.visible = true;
    }

    if (this.searchValue) {
      this.updateListedObject(historicalTracking);
    }

    this.historicalTrackingsListedCount = this.updateListedCount(this.historicalTrackingMap);
  }

  updateHistoricalTracking(tracking: TrackingModel) {
    // Chamada quando o tracking é atualizado
    // Ou seja para que o rastro seja real time, a página de tracking tem que estar aberta
    let id = this.getHistoricalTrackingId(tracking.signal.mobileObjectId, tracking.signal.sourceType, tracking.patrolTeam, tracking.operation, tracking.user?.id);
    let historicalTracking: HistoricalTrackingObject = this.historicalTrackingMap.get(id);
    if (historicalTracking){
      if (tracking.operation && tracking.signal.operationStatus != OperationStatus.STARTED) {
        // Somente sinais que estão com status STARTED fazem parte da operação
        return;
      }

      let curLatLng = this.signalToTurfPoint(tracking.signal);

      if (historicalTracking.signals.length > 0) {
        let lastSignal = historicalTracking.signals[historicalTracking.signals.length-1]; // ordem cronológica, pega o último sinal antes de acrescentar o novo
        let prevLatLng = this.signalToTurfPoint(lastSignal);
        historicalTracking.accumDistance += turf.distance(prevLatLng, curLatLng, {units: 'kilometers'})
      }
      else {
        historicalTracking.accumDistance = 0;
      }

      historicalTracking.signals.push(tracking.signal);

      // Se o filtro está posicionado no último sinal, então atualiza os valores na interface
      if (!historicalTracking.finishChanged) {
        this.updateTimeFields(historicalTracking, true);
      }

      if (historicalTracking.visible) {
        if (tracking.signal.timestamp >= historicalTracking.startTimestamp && tracking.signal.timestamp <= historicalTracking.finishTimestamp) {
          if (historicalTracking.trackingLine) {
            if(historicalTracking.trackingDecorator) {
              this.baseMap.removeLayerFromMap(historicalTracking.trackingDecorator);
            }
        
            historicalTracking.trackingLine.addLatLng([tracking.signal.latitude, tracking.signal.longitude]);
            this.addArrowsToTracking(historicalTracking);

            if (this.historicalTrackingsVisible || historicalTracking.visible){
              this.baseMap.addLayerToMap(historicalTracking.trackingDecorator);
              historicalTracking.visible = true;
            }
        
            if (!historicalTracking.timeMarkers || historicalTracking.timeMarkers.length == 0){
              historicalTracking.timeMarkers = [];
              let marker = this.addTimeMarker(historicalTracking, tracking.signal);
              if ((this.historicalTrackingsVisible || historicalTracking.visible) && historicalTracking.timeVisible)
                this.baseMap.addMarker(marker);
            }
            else{
              let prevTimeLatLng = historicalTracking.timeMarkers[historicalTracking.timeMarkers.length-1].getLatLng();
              let lastLatLng = this.latLngToTurfPoint(prevTimeLatLng);
              if (turf.distance(lastLatLng, curLatLng, {units: 'kilometers'}) > MapInfo.TIME_MARKER_DISTANCE) {
                this.addTimeMarker(historicalTracking, tracking.signal);
              }
            }
          }
          else {
            this.buildTrackingLine(historicalTracking, historicalTracking.signals);
          }
        }
      }
    }
  }

  historicalTrackingListOrder = (a: KeyValue<string, HistoricalTrackingObject>, b: KeyValue<string, HistoricalTrackingObject>): number => {
    if (a.value.patrolTeam && b.value.patrolTeam)
      return a.value.patrolTeam.name.localeCompare(b.value.patrolTeam.name);
    else if (a.value.patrolTeam)
      return -1;
    else if (b.value.patrolTeam)
      return 1;
    else {
      if (a.value.sourceType == 'MOBILE_APP' && b.value.sourceType == 'MOBILE_APP') {
        return a.value.user?.name.localeCompare(b.value.user?.name);
      }
      else {
        return a.key.localeCompare(b.key);
      }
    }
  }


  /**
   * ###########################################
   * Location Marker
   * ###########################################
   */

  onPasteLatLong(event: ClipboardEvent){
    let pastedValue = FieldUtils.pasteLatLong(event, this.locationMarkerLatLong);
    if (pastedValue != null) {
       this.locationMarkerLatLong = pastedValue;
       return true;
    }
    return false;
  }

  onZoomFitClick() {
    this.baseMap.fitZoom();
  }

  /** Posiciona um marcador na posição solicitada (componente de busca de localização) */
  onAddLocationMarker() {
    try {
      if(!this.locationMarkerLatLong) return;

      const latlong: Array<string> =  this.locationMarkerLatLong.split(',');
      if(latlong.length !== 2) return;

      const latitude: number = +latlong[0].trim();
      const longitude: number = +latlong[1].trim();

      this.baseMap.removeMarker(this.locationMarker);

      this.locationMarker = this.baseMap.createMarker([latitude, longitude], this.baseMap.searchLatLngIcon, this.getLocationMarkerPopupContent(latitude, longitude));
      this.locationMarker.bindTooltip(this.getLocationMarkerTooltipContent(latitude, longitude));
      this.baseMap.addMarker(this.locationMarker);
      this.baseMap.fitMarker(this.locationMarker);
    } catch (e) {
      this.toastr.warning('Siga o padrão: <latitude>,<longitude> separados por virgula.', 'Dados inválidos');
    }
  }

  onRemoveLocationMarker() {
    this.locationMarkerLatLong = undefined;

    this.baseMap.clearSearchResults();

    if (this.locationMarker) {
      this.baseMap.removeMarker(this.locationMarker);
      this.locationMarker = undefined;
    }
  }

  getLocationMarkerPopupContent(latitude: number, longitude: number){
    return `<h5 style="text-align: center">Marcador de Posição</h5>
            <div>Lat, Long:${FieldUtils.coordToString(latitude)},${FieldUtils.coordToString(longitude)}</div>`;
  }

  getLocationMarkerTooltipContent(latitude: number, longitude: number){
    return `<div>${FieldUtils.coordToString(latitude)},${FieldUtils.coordToString(longitude)}</div>`;
  }

  /**
   * ###################################################
   * Alertas
   * ###################################################
   */

  onAlertListSelection(alertList: AlertModel[]){
    alertList.forEach(alert =>{
      this.onAlertSelection(alert, false);
    });

    this.fitAlertListBounds(alertList);
  }

  fitAlertListBounds(alertList: AlertModel[]) {
    let latLongs: L.LatLngTuple[] = [];

    alertList.forEach(alert =>{
      latLongs.push([alert.location.coordinates[0], alert.location.coordinates[1]]);  // inicialização do GeoPoint para alertas está trocada, está sendo feito (x=latitude e y=longitude)
    });

    if (latLongs.length > 1) {
      let bounds: L.LatLngBounds = this.getLatLongBounds(latLongs);
      this.baseMap.fitBounds(bounds);
    }
    else if (latLongs.length == 1) {
      this.baseMap.fitPoint(latLongs[0]);
    }
  }

  onAlertsRemoveClick(){
    this.alertObjectsMap.forEach( (alertMapObject, id) => {
      this.onAlertRemoveClick(alertMapObject);
    });
  }

  onAlertClick(alertMapObject: AlertMapObject){
    if (alertMapObject.visible) {
      this.baseMap.fitMarker(alertMapObject.marker);
      this.highlightMarker(alertMapObject.marker);
    }
  }

  onAlertRemoveClick(alertMapObject: AlertMapObject){
    this.baseMap.removeMarker(alertMapObject.marker);
    this.alertObjectsMap.delete(alertMapObject.alert.id);
    if (alertMapObject.listed) this.alertsListedCount--;
  }

  onAlertsVisibilityClick(){
    this.alertObjectsMap.forEach( (alertMapObject, id) => {
      if (this.alertsVisible) {
        this.baseMap.removeMarker(alertMapObject.marker);
        alertMapObject.visible = false;
      }
      else{
        this.baseMap.addMarker(alertMapObject.marker);
        alertMapObject.visible = true;
      }
    });

    if (this.alertsVisible) {
      this.alertsVisible = false;
    }
    else{
      this.alertsVisible = true;
    }
  }

  onAlertVisibilityClick(alertMapObject: AlertMapObject){
    if (alertMapObject.visible) {
      this.baseMap.removeMarker(alertMapObject.marker);
      alertMapObject.visible = false;
    }
    else{
      this.baseMap.addMarker(alertMapObject.marker);
      alertMapObject.visible = true;
    }
  }

  onAlertSelection(alert : AlertModel, fit: boolean){
    this.logger.debug('MapComponent.onSelectAlert()');
    let alertMapObject = this.alertObjectsMap.get(alert.id);
    if (alertMapObject) {
      // Se existe, remove do mapa antes de atualizar
      this.baseMap.removeMarker(alertMapObject.marker);
    }
    else {
      alertMapObject = new AlertMapObject();
      this.alertObjectsMap.set(alert.id, alertMapObject);
    }

    alertMapObject.alert = alert;
    alertMapObject.popupContent = AlertModel.getPopupContent(alert, true);
    alertMapObject.marker = this.baseMap.createMarker([alert.location.coordinates[0], alert.location.coordinates[1]], this.alertIcon, AlertModel.getPopupContent(alert));  // inicialização do GeoPoint para alertas está errada, está sendo feito (x=latitude e y=longitude)

    if (this.alertsVisible) {
      this.baseMap.addMarker(alertMapObject.marker);
      alertMapObject.visible = true;

      if (fit) {
        this.highlightMarker(alertMapObject.marker);
        this.baseMap.fitMarker(alertMapObject.marker);
      }
    }

    if (this.searchValue) {
      this.updateListedObject(alertMapObject);
    }

    this.alertsListedCount = this.updateListedCount(this.alertObjectsMap);
  }

  private getAlertsList() {
    let alertList: AlertModel[] = [];
    this.alertObjectsMap.forEach( (alertMapObject: AlertMapObject) => {
      alertList.push(alertMapObject.alert);
    });
    return alertList;
  }

  alertListOrder = (a: KeyValue<string, AlertMapObject>, b: KeyValue<string, AlertMapObject>): number => {
    return b.value.alert.timestamp - a.value.alert.timestamp;
  }

  /**
   * ###################################################
   * Pontos
   * ###################################################
   */

  pointListOrder = (a: KeyValue<string, InspectionPointMapObject>, b: KeyValue<string, InspectionPointMapObject>): number => {
    return b.value.inspectionPoint.identifier.localeCompare(a.value.inspectionPoint.identifier);
  }

  private getPointsList() {
    let pointList: InspectionPointModel[] = [];
    this.pointObjectsMap.forEach( (inspectionPointObject: InspectionPointMapObject) => {
      pointList.push(inspectionPointObject.inspectionPoint);
    });
    return pointList;
  }

  onPointsRemoveClick(){
    this.pointObjectsMap.forEach( (inspectionPointObject, id) => {
      this.onPointRemoveClick(inspectionPointObject);
    });
  }

  onPointClick(inspectionPointObject: InspectionPointMapObject){
    if (inspectionPointObject.visible) {
      this.baseMap.fitMarker(inspectionPointObject.marker);
      this.highlightMarker(inspectionPointObject.marker);
    }
  }

  onPointVisibilityClick(inspectionPointObject: InspectionPointMapObject){
    if (inspectionPointObject.visible) {
      this.baseMap.removeMarker(inspectionPointObject.marker);
      inspectionPointObject.visible = false;
    }
    else{
      this.baseMap.addMarker(inspectionPointObject.marker);
      inspectionPointObject.visible = true;
    }
  }

  onPointsVisibilityClick(){
    this.pointObjectsMap.forEach( (inspectionPointObject, id) => {
      if (this.pointsVisible) {
        this.baseMap.removeMarker(inspectionPointObject.marker);
        inspectionPointObject.visible = false;
      }
      else{
        this.baseMap.addMarker(inspectionPointObject.marker);
        inspectionPointObject.visible = true;
      }
    });

    if (this.pointsVisible) {
      this.pointsVisible = false;
    }
    else {
      this.pointsVisible = true;
    }
  }

  onPointRemoveClick(inspectionPointObject: InspectionPointMapObject){
    this.baseMap.removeMarker(inspectionPointObject.marker);
    this.pointObjectsMap.delete(inspectionPointObject.inspectionPoint.id);
    if (inspectionPointObject.listed) this.pointsListedCount--;
  }

  getInspectionPointLatLong(inspectionPoint: InspectionPointModel): L.LatLngTuple {
    const latlong: Array<string> = inspectionPoint.latLong.split(',');
    if (latlong.length !== 2)
      return null;

    const latitude: number = +latlong[0].trim();
    const longitude: number = +latlong[1].trim();

    return [latitude, longitude];
  }

  onInspectionPointSelection(inspectionPoint: InspectionPointModel, fit: boolean){
    this.logger.debug('MapComponent.onInspectionPointSelection()');

    const latlong: L.LatLngTuple = this.getInspectionPointLatLong(inspectionPoint);
    if (!latlong) return;

    let inspectionPointObject = this.pointObjectsMap.get(inspectionPoint.id);
    if (inspectionPointObject) {
      // Se existe, remove do mapa antes de atualizar
      this.baseMap.removeMarker(inspectionPointObject.marker);
    }
    else {
      inspectionPointObject = new InspectionPointMapObject();
      this.pointObjectsMap.set(inspectionPoint.id, inspectionPointObject);
    }

    inspectionPointObject.inspectionPoint = inspectionPoint;
    inspectionPointObject.popupContent = InspectionPointModel.getPopupContent(inspectionPoint, latlong[0], latlong[1], true);
    inspectionPointObject.marker = this.baseMap.createMarker(latlong, this.pointIcon, InspectionPointModel.getPopupContent(inspectionPoint, latlong[0], latlong[1]));

    if (this.pointsVisible) {
      this.baseMap.addMarker(inspectionPointObject.marker);
      inspectionPointObject.visible = true;
      
      if (fit) {
        this.highlightMarker(inspectionPointObject.marker);
        this.baseMap.fitMarker(inspectionPointObject.marker);
      }
    }

    if (this.searchValue) {
      this.updateListedObject(inspectionPointObject);
    }

    this.pointsListedCount = this.updateListedCount(this.pointObjectsMap);
  }

  onInspectionPointListSelection(inspectionPointList: InspectionPointModel[]){
    inspectionPointList.forEach(inspectionPoint =>{
      this.onInspectionPointSelection(inspectionPoint, false);
    });

    this.fitInspectionPointListBounds(inspectionPointList);
  }

  fitInspectionPointListBounds(inspectionPointList: InspectionPointModel[]) {
    let latLongs: L.LatLngTuple[] = [];

    inspectionPointList.forEach(inspectionPoint =>{
      const latlong: L.LatLngTuple = this.getInspectionPointLatLong(inspectionPoint);
      if (latlong) latLongs.push(latlong);
    });

    if (latLongs.length > 1) {
      let bounds: L.LatLngBounds = this.getLatLongBounds(latLongs);
      this.baseMap.fitBounds(bounds);
    }
    else if (latLongs.length == 1) {
      this.baseMap.fitPoint(latLongs[0]);
    }
  }

  /**
   * ###################################################
   * Eventos
   * ###################################################
   */

   getLatLong(latlong: String): L.LatLngTuple {
    const latlongArray: Array<string> = latlong.split(',');
    if (latlongArray.length !== 2)
      return null;

    const latitude: number = +latlongArray[0].trim();
    const longitude: number = +latlongArray[1].trim();

    return [latitude, longitude];
  }

  onEventClick(eventMapObject: EventMapObject){
    if (eventMapObject.visible) {
      if (eventMapObject.marker) {
        this.baseMap.fitMarker(eventMapObject.marker);
        this.highlightMarker(eventMapObject.marker);
      }
      else {
        let bounds = eventMapObject.line.getBounds().pad(MapInfo.BOUNDS_PAD_RATIO); // Esse get bounds retorna o bounds interno da Polyline
        this.baseMap.fitBounds(bounds);
        this.highlightBounds(bounds);
        this.highlightPolyline(eventMapObject.line);
      }
    }
  }

  onEventsRemoveClick(){
    this.eventObjectsMap.forEach( (eventMapObject, id) => {
      if (eventMapObject.marker) {
        this.baseMap.removeMarker(eventMapObject.marker);
      }
      else {
        this.baseMap.removeLayerFromMap(eventMapObject.line);
      }
      this.eventObjectsMap.delete(id);
    });
  }

  onEventRemoveClick(eventMapObject: EventMapObject){
    if (eventMapObject.marker) {
      this.baseMap.removeMarker(eventMapObject.marker);
    }
    else {
      this.baseMap.removeLayerFromMap(eventMapObject.line);
    }
    this.eventObjectsMap.delete(eventMapObject.event.id);
  }

  onEventsVisibilityClick(){
    this.eventObjectsMap.forEach( (eventMapObject, id) => {
      if (this.eventsVisible){
        if (eventMapObject.marker) {
          this.baseMap.removeMarker(eventMapObject.marker);
        }
        else {
          this.baseMap.removeLayerFromMap(eventMapObject.line);
        }
        eventMapObject.visible = false;
      }
      else{
        if (eventMapObject.marker) {
          this.baseMap.addMarker(eventMapObject.marker);
        }
        else {
          this.baseMap.addLayerToMap(eventMapObject.line);
        }
        eventMapObject.visible = true;
      }
    });

    if (this.eventsVisible){
      this.eventsVisible = false;
    }
    else{
      this.eventsVisible = true;
    }
  }

  onEventVisibilityClick(eventMapObject: EventMapObject){
    if (eventMapObject.visible){
      if (eventMapObject.marker) {
        this.baseMap.removeMarker(eventMapObject.marker);
      }
      else {
        this.baseMap.removeLayerFromMap(eventMapObject.line);
      }
      eventMapObject.visible = false;
    }
    else{
      if (eventMapObject.marker) {
        this.baseMap.addMarker(eventMapObject.marker);
      }
      else {
        this.baseMap.addLayerToMap(eventMapObject.line);
      }
      eventMapObject.visible = true;
    }
  }

  onEventSelection(event : EventModel, fit: boolean){
    this.logger.debug('MapComponent.onEventSelection()');
    let eventMapObject = this.eventObjectsMap.get(event.id);
    if (eventMapObject) {
      // Se existe, remove do mapa antes de atualizar
      if (eventMapObject.marker) {
        this.baseMap.removeMarker(eventMapObject.marker);
      }
      else {
        this.baseMap.removeLayerFromMap(eventMapObject.line);
      }
    }
    else {
      eventMapObject = new EventMapObject();
      this.eventObjectsMap.set(event.id, eventMapObject);
      this.eventsListedCount++;
    }

    eventMapObject.event = event;
    eventMapObject.popupContent = EventModel.getPopupContent(event, true);

    if (!!event.stretchStartLatLong && !!event.stretchEndLatLong) {
      const latLngs = [];
      latLngs.push(this.getLatLong(event.stretchStartLatLong));
      latLngs.push(this.getLatLong(event.stretchEndLatLong));

      eventMapObject.line = L.polyline(latLngs, { color: MapInfo.PLANNED_ROUTE_LINE_COLOR, weight: MapInfo.PLANNED_ROUTE_LINE_WEIGHT });
      eventMapObject.line.bindPopup(EventModel.getPopupContent(event));

      if (this.eventsVisible) {
        this.baseMap.addLayerToMap(eventMapObject.line);
        eventMapObject.visible = true;
        
        if (fit) {
          let bounds: L.LatLngBounds = this.getLatLongBounds(latLngs);
          this.highlightBounds(bounds);
          this.highlightPolyline(eventMapObject.line);
          this.baseMap.fitBounds(bounds);
        }
      }
    }
    else  if (!!event.targetPointLatLong) {
      const latlong = this.getLatLong(event.targetPointLatLong);
      if (!latlong) return;

      eventMapObject.marker = this.baseMap.createMarker(latlong, this.eventIcon, EventModel.getPopupContent(event));

      if (this.eventsVisible) {
        this.baseMap.addMarker(eventMapObject.marker);
        eventMapObject.visible = true;

        if (fit) {
          this.highlightMarker(eventMapObject.marker);
          this.baseMap.fitMarker(eventMapObject.marker);
        }
      }
    }

    if (this.searchValue) {
      this.updateListedObject(eventMapObject);
    }

    this.eventsListedCount = this.updateListedCount(this.eventObjectsMap);
  }

  onEventListSelection(eventList: EventModel[]){
    eventList.forEach(event =>{
      this.onEventSelection(event, false);
    });

    this.fitEventListBounds(eventList);
  }

  fitEventListBounds(eventList: EventModel[]) {
    let latLongs: L.LatLngTuple[] = [];
    let latlong: L.LatLngTuple;

    eventList.forEach(event =>{
      if (!!event.stretchStartLatLong && !!event.stretchEndLatLong) {
        latlong = this.getLatLong(event.stretchStartLatLong);
        if (latlong) latLongs.push(latlong);
        latlong = this.getLatLong(event.stretchEndLatLong);
        if (latlong) latLongs.push(latlong);
        }
      else {
        latlong = this.getLatLong(event.targetPointLatLong);
        if (latlong) latLongs.push(latlong);
      }
    });

    if (latLongs.length > 1) {
      let bounds: L.LatLngBounds = this.getLatLongBounds(latLongs);
      this.baseMap.fitBounds(bounds);
    }
    else if (latLongs.length == 1) {
      this.baseMap.fitPoint(latLongs[0]);
    }
  }

  private getEventsList() {
    let eventList: EventModel[] = [];
    this.eventObjectsMap.forEach( (eventMapObject: EventMapObject) => {
      eventList.push(eventMapObject.event);
    });
    return eventList;
  }

  eventListOrder = (a: KeyValue<string, EventMapObject>, b: KeyValue<string, EventMapObject>): number => {
    return b.value.event.date - a.value.event.date;
  }

  /**
   * ###################################################
   * Modo de Edição
   * ###################################################
   */

  onEditionModeSelection(data){
    this.logger.debug('MapComponent.onEditionModeSelection()');
    if (data.editionMode) {
      this.updateDrawPolylineButton(data.autoRoute);
      if (this.editionMode){
        // Já está em modo de edição = > atualizando a operação
        if (this.editionMode.OperationId != data.operationId ||
            this.editionMode.operation.type != data.operation.type) {
          this.glEmitEvent(LOCATION_UPDATE_PREFIX + 'operation-edit', {id: this.editionMode.OperationId, clearEditionMode: true});
          // Remove anterior antes de colocar um novo
          this.clearEditionMode();
        }
        else {
          this.updateEditionMode(data.operationId, data.operation, data.inspections, data.fileKmlRoute, data.removedKmlRoute, data.autoRoute);
          return;
        }
      }
      this.setEditionMode(data.operationId, data.operation, data.inspections, data.fileKmlRoute, data.removedKmlRoute, data.autoRoute);
    }
    else {
      if (this.editionMode) {
        // Estava em modo de edição => saindo modo de edção
        if (this.editionMode.OperationId == data.operationId &&
            this.editionMode.operation.type == data.operation.type) {
          // Remove o modo de edição existente, se o pedido vier da própria operação
          this.clearEditionMode();
        }
      }
    }
  }

  updateObjectsOpacity(opacity: number) {
    this.alertObjectsMap.forEach((alertMapObject) => {
      alertMapObject.marker.setOpacity(opacity);
    });

    this.pointObjectsMap.forEach((inspectionPointObject) => {
      inspectionPointObject.marker.setOpacity(opacity);
    });

    this.operationObjectsMap.forEach((operationMapObject) => {
      if (operationMapObject.geoPoints) operationMapObject.geoPoints.invoke('setOpacity', opacity);
      if (operationMapObject.geoRoute) operationMapObject.geoRoute.setStyle({ opacity: opacity });
    });

    this.historicalTrackingMap.forEach((historicalTracking) => {
      if (historicalTracking.trackingLine) historicalTracking.trackingLine.setStyle({ opacity: opacity });
      if (historicalTracking.trackingDecorator) historicalTracking.trackingDecorator.setStyle({ opacity: opacity });
      historicalTracking.timeMarkers.forEach( timeMarker =>{
        timeMarker.setStyle({ opacity: opacity });
      });
      if (historicalTracking.stateMarkers) {
        historicalTracking.stateMarkers.forEach( marker =>{
          marker.setOpacity(opacity);
        });
      }
    });

    this.eventObjectsMap.forEach((eventMapObject) => {
      if (eventMapObject.marker) {
        eventMapObject.marker.setOpacity(opacity);
      }
      else {
        eventMapObject.line.setStyle({ opacity: opacity });
      }
    });

    this.baseMap.updateObjectsOpacity(opacity); // Trata Camadas e Equipes
  }

  onCloseEditionMode() {
    this.glEmitEvent(LOCATION_UPDATE_PREFIX + 'operation-edit', {id: this.editionMode.OperationId, clearEditionMode: true});
    this.clearEditionMode();
  }

  isMarker(layer: L.Layer): boolean {
    return layer instanceof L.Marker;
  }

  isPolyline(layer: L.Layer): boolean {
    return layer instanceof L.Polyline;
  }

  findInspectionFromMarker(inspections: any [], marker) {
    if(marker.editionId){
      return inspections.find(inspection => (inspection.editionId == marker.editionId));
    }
    else{
      const id = L.stamp(marker); // pego o id do layer
      return inspections.find(inspection => (inspection.id_layer == id));
    }
  }

  findInspectionIndexFromMarker(inspections: any [], marker) {
    if(marker.editionId){
      return inspections.findIndex(inspection => (inspection.editionId == marker.editionId));
    }
    else{
      const id= L.stamp(marker); // pego o id do layer
      return inspections.findIndex(inspection => inspection.id_layer == id);
    }
  }

  kmlStringToFile(kmlStr: string){
    let strArray = [];
    strArray.push(kmlStr);
    let blob = new Blob(strArray);
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(blob);
    let file = new File(arrayOfBlob, '[Rota Editada]');
    return file;
  }

  removeEditedRoute() {
    let layers = this.baseMap.getDrawLayers();

    layers.forEach( layer => {
      if (!this.isMarker(layer)) {
        this.baseMap.removeDrawLayer(layer);
      }
    });
  }

  getEditedKmlRoute() {
    let layers = this.baseMap.getDrawLayers();
    let routeGroup = new L.LayerGroup();
    let hasLine = false;

    layers.forEach( layer => {
      if (!this.isMarker(layer)) {
        routeGroup.addLayer(layer);
        hasLine = true;
      }
    });

    if (!hasLine)
      return null;

    let routeGeoJSON = routeGroup.toGeoJSON();

    let kmlStr = tokml(routeGeoJSON, {
      name: 'Rota Editada',
      description: 'description'
    });

    let fileKmlRoute = this.kmlStringToFile(kmlStr);
    return fileKmlRoute;
  }

 /** Função que espera a criação do panel leaflet draw
 * dependendo da opção do toggle (rota automatizada ou não)
 * apresenta ou esconde botão para o desenho de polyline
 */
 updateDrawPolylineButton(autoRoute) {
    const myDrawPanel  = document.getElementById('mapDOM');
    const observer = new MutationObserver(mutations=>{
      mutations.forEach(record => {
        if(record.type==='childList'){
          let button = <HTMLButtonElement>document.getElementsByClassName("leaflet-draw-draw-polyline")[0];
            if(button){ // se o button existe
              if (autoRoute) {
                // esconde button se rota automática
                button.style.display = "none"
              } else {
                // mostra button se rota manual
                button.style.display = ""
              }
              observer.disconnect();
            }
        }
      });
    });
    observer.observe(<HTMLElement>myDrawPanel,{
      childList:true,
      subtree: true,
    })
  }

  mapEditUpdate(data) {
    this.logger.debug("MapComponent.mapEditUpdate - ", data);
    let inspections: InspectionModel [] = undefined; // indica que houve alguma mudança nos pontos
    let rebuildKmlRoute = false; // indica que alguma linha mudou e a rota precisa ser atualizada
    if (data.createdLayer) {
      // Adiciona novos
      if (data.layerType == "marker") {
        let marker: L.Marker = data.createdLayer;
        let inspection = new InspectionModel();

        marker.setIcon(this.routeGeographicalObjectService.getCriticalPointIcon(this.editionMode.inspections.length+1));

        let latLng: L.LatLng = marker.getLatLng();
        inspection.location.latitude = FieldUtils.coordToString(latLng.lat);
        inspection.location.longitude = FieldUtils.coordToString(latLng.lng);
        inspection.id_layer = data.idlayer;
        this.editionMode.inspections.push(inspection);
        inspections = this.editionMode.inspections;
      }
      else { /* "polyline" */
        let polyline: L.Polyline = data.createdLayer;
        polyline.setStyle( {opacity: 1.0});

        rebuildKmlRoute = true;
      }
    }
    else if (data.editedLayers) {
      // Modifica os existentes
      let layerGroup = <L.LayerGroup>data.editedLayers;
      let layers: L.Layer[] = layerGroup.getLayers();

      layers.forEach( (layer: L.Layer) => {
        if (this.isMarker(layer)) {
          let marker: L.Marker = <L.Marker>layer;
          let inspection = this.findInspectionFromMarker(this.editionMode.inspections, marker);
          if (inspection) {
            let latLng: L.LatLng = marker.getLatLng();
            inspection.location.latitude = FieldUtils.coordToString(latLng.lat);
            inspection.location.longitude = FieldUtils.coordToString(latLng.lng);
            inspections = this.editionMode.inspections;
          }
          else {
            this.logger.debug("MapComponent.mapEditUpdate - Erro Interno: marcador não encontrado nas inspeções");
          }
        }
        else{
          // let polyline: L.Polyline = <L.Polyline>layer;
          rebuildKmlRoute = true;
        }
      });
    }
    else if (data.deletedLayers) {
      // Remove existentes
      let layerGroup = <L.LayerGroup>data.deletedLayers;
      let layers: L.Layer[] = layerGroup.getLayers();

      layers.forEach( (layer: L.Layer) => {
        if (this.isMarker(layer)) {
          let marker: L.Marker = <L.Marker>layer;

          let inspectionIndex = this.findInspectionIndexFromMarker(this.editionMode.inspections, marker);
          if (inspectionIndex != -1) {
            this.editionMode.inspections.splice(inspectionIndex, 1);
            inspections = this.editionMode.inspections;
          }
          else {
            this.logger.debug("MapComponent.mapEditUpdate - Erro Interno: marcador não encontrado nas inspeções");
          }
        }
        else{
          //let polyline: L.Polyline = <L.Polyline>layer;
          rebuildKmlRoute = true;
        }
      });
    }
    else if (data.editStart) {
      if (this.editionMode.autoRoute) {
        // Se estava com rota automática, simplifica antes de editar, mas não desliga o automático ainda
        this.removeEditedRoute();
        this.addEditionRouteToMap(true);
      }
    }

    if (rebuildKmlRoute && this.editionMode.autoRoute) { // Se alguma linha mudou e estava em rota automática, muda para rota manual
      this.editionMode.autoRoute = false;
      this.updateDrawPolylineButton(this.editionMode.autoRoute);
    }

    if(this.editionMode.autoRoute){
      if (inspections) { // Se algum ponto mudou
        this.glEmitEvent(LOCATION_UPDATE_PREFIX + 'operation-edit', {id: this.editionMode.OperationId, inspections: inspections, autoRoute: this.editionMode.autoRoute});
      }
    }
    else if (data.createdLayer || data.editedLayers || data.deletedLayers) {
      this.updateKmlRouteAndNotifyEdition(rebuildKmlRoute, inspections);
    }
  }

  updateKmlRouteAndNotifyEdition(rebuildKmlRoute:boolean, inspections: InspectionModel []){
    let fileKmlRoute = undefined;
    let removedKmlRoute = undefined;

    if (rebuildKmlRoute) {
      fileKmlRoute = this.getEditedKmlRoute();
      if (!fileKmlRoute) removedKmlRoute = true;

      this.editionMode.fileKmlRoute = fileKmlRoute;
      this.editionMode.removedKmlRoute = removedKmlRoute;
    }

    this.glEmitEvent(LOCATION_UPDATE_PREFIX + 'operation-edit', {id: this.editionMode.OperationId,
                      inspections: inspections, fileKmlRoute: fileKmlRoute, removedKmlRoute: removedKmlRoute, autoRoute: this.editionMode.autoRoute});
  }

  private simplifyPolyline(polyline: L.Polyline, tolerance: number): L.Polyline{
    let simp = true;
    if (simp) {
      var turfOptions = {
        tolerance: tolerance,
        // whether or not to spend more time to create a higher-quality simplification with a different algorithm
        highQuality: false 
      };
      let turfPolygon = turf.simplify(polyline.toGeoJSON(), turfOptions);
      let leafletGeoJSON = new L.GeoJSON(turfPolygon);
      let layer = leafletGeoJSON.getLayers()[0];
      if (layer instanceof L.Polyline) {
        layer.options = polyline.options;
        return layer;
      }
    }
    return polyline;
  }

  addEditionRouteToMap(simplify: boolean){
    if (this.editionMode.fileKmlRoute) {
      this.routeGeographicalObjectService.importKmlRoute(this.editionMode.fileKmlRoute, this.editionMode.operation).then((geoRoute) => {
        geoRoute.on('ready', () => {
          let polylines: L.Polyline [] = RouteGeographicalService.getGeoRoutePolyline(geoRoute);
          if (polylines && polylines.length > 0) {
            // Tem que adicionar um a um no mapa para que sejam reconhecidas pelo leaflet-draw
            polylines.forEach(polyline => {
              if (simplify) polyline = this.simplifyPolyline(polyline, MapInfo.SIMPLIFY_POLYLINE_TOL);
              this.baseMap.addDrawLayer(polyline);
            });
            this.baseMap.fitDrawLayer();
          }
        });
      });
    } else if (!this.editionMode.removedKmlRoute) {
      if (this.editionMode.operation.route && this.editionMode.operation.route.fileRouteKml) {
        this.routeGeographicalObjectService.loadKML(this.editionMode.operation)
          .then((geoRoute) => {
            geoRoute.on('ready', () => {
              let polylines: L.Polyline [] = RouteGeographicalService.getGeoRoutePolyline(geoRoute);
              if (polylines && polylines.length > 0) {
                polylines.forEach(polyline => {
                  if (simplify) polyline = this.simplifyPolyline(polyline, MapInfo.SIMPLIFY_POLYLINE_TOL);
                  this.baseMap.addDrawLayer(polyline);
                });
                this.baseMap.fitDrawLayer();
              }
            });
          })
          .catch( error => {
            this.toastr.error("Falha ao carregar rota");
            this.logger.error(error);
          });
      }
    }
  }

  addEditionOperationToMap() {
    // Chamada quando vem da tela de edição para o mapa

    let markers: L.Marker[] = this.routeGeographicalObjectService.getOperationPointMarkers(this.editionMode.operation, this.editionMode.inspections);
    if (markers) markers.forEach( (marker => {
      this.baseMap.addDrawLayer(marker);
    }));

    if (markers && markers.length != 0) {
      this.baseMap.fitDrawLayer();
    }

    this.addEditionRouteToMap(false);

    let operationMapObject = this.operationObjectsGet(this.editionMode.operation.id, this.editionMode.operation.type);
    if (operationMapObject) {
      this.editionMode.wasVisible = operationMapObject.visible;
      operationMapObject.visible = false;
    }
  }

  setEditionMode(operationId: string, operation: OperationModel, inspections: InspectionModel[], fileKmlRoute: File, removedKmlRoute: boolean, autoRoute: boolean) {
    this.logger.debug('MapComponent.setEditionMode()');
    this.editionMode = new EditionModeObject();
    this.editionMode.OperationId = operationId;
    this.editionMode.operation = operation;
    this.editionMode.inspections = JSON.parse(JSON.stringify(inspections));
    this.editionMode.fileKmlRoute = fileKmlRoute;
    this.editionMode.removedKmlRoute = removedKmlRoute;
    this.editionMode.autoRoute = autoRoute;

    this.editionMode.glassPanel = new GlassPanelObject();
    this.baseMap.addLayerToMap(this.editionMode.glassPanel.geoRectangle);
    this.editionMode.glassPanel.geoRectangle.bringToFront();

    this.updateObjectsOpacity(MapInfo.GLASS_OBJECT_OPACITY);

    this.baseMap.shownDraw = true;
    this.addEditionOperationToMap();
  }

  updateEditionMode(operationId: string, operation: OperationModel, inspections: InspectionModel[], fileKmlRoute: File, removedKmlRoute: boolean, autoRoute: boolean) {
    this.logger.debug('MapComponent.updateEditionMode()');
    this.editionMode.OperationId = operationId;
    this.editionMode.operation = operation;
    this.editionMode.inspections = JSON.parse(JSON.stringify(inspections));
    this.editionMode.fileKmlRoute = fileKmlRoute;
    this.editionMode.removedKmlRoute = removedKmlRoute;
    this.editionMode.autoRoute = autoRoute;

    this.baseMap.removeDrawLayers();

    this.addEditionOperationToMap();
  }

  clearEditionMode() {
    this.baseMap.removeLayerFromMap(this.editionMode.glassPanel.geoRectangle);

    let operationMapObject = this.operationObjectsGet(this.editionMode.operation.id, this.editionMode.operation.type);
    if (operationMapObject) {
      operationMapObject.visible = this.editionMode.wasVisible ? true: false;
    }

    this.editionMode = null;
    this.baseMap.shownDraw = false;

    this.baseMap.removeDrawLayers();

    this.updateObjectsOpacity(1.0);
  }


   /**
   * ###################################################
   * Métodos do mapa
   * ###################################################
   */

  sidenavToggle(opened) {
    this.openedSideList = opened;
    this.glOnResize();
  }

  mapReady(map: L.Map){
    this.logger.debug("MapComponent.mapReady");
    this.glOnResize();

    map.on('click', () => {
      if (this.highlight) { 
        this.baseMap.removeLayerFromMap(this.highlight);
        this.highlight = null;
      }
      if (this.highlightLine) { 
        this.baseMap.removeLayerFromMap(this.highlightLine);
        this.highlightLine = null;
      }
    });
  }

  highlightMarker(marker: L.Marker){
    let latLongs: L.LatLng[] = [];
    latLongs.push(marker.getLatLng());
    this.highlightLatLongPoints(latLongs);
  }

  highlightMarkers(markers: L.Marker[]){
    if (markers.length == 1) {
      this.highlightMarker(markers[0]);
    }
    else {
      let latLongs: L.LatLngTuple[] = [];

      markers.forEach(marker => {
        const latLong: L.LatLng = marker.getLatLng();
        latLongs.push([latLong.lat, latLong.lng]);
      });

      let bounds: L.LatLngBounds = this.getLatLongBounds(latLongs);
      this.highlightBounds(bounds);
    }
  }

  highlightLatLongPoints(latLongs: L.LatLng[]){
    if (this.highlight) this.baseMap.removeLayerFromMap(this.highlight);

    let layerGroup = L.layerGroup();

    latLongs.forEach(latLong => {
      layerGroup.addLayer(L.circle(latLong, {radius: MapInfo.HIGHLIGHT_CIRCLE_RADIUS, fill: true, fillColor: MapInfo.HIGHLIGHT_FILL_COLOR, opacity: MapInfo.HIGHLIGHT_OPACITY}));
    });

    this.highlight = layerGroup;

    this.baseMap.addLayerToMap(this.highlight);
  }

  highlightBounds(bounds: L.LatLngBounds) {
    if (this.highlight) this.baseMap.removeLayerFromMap(this.highlight);

    this.highlight = L.rectangle(bounds, {fill: true, fillColor: MapInfo.HIGHLIGHT_FILL_COLOR, opacity: MapInfo.HIGHLIGHT_OPACITY});

    this.baseMap.addLayerToMap(this.highlight);
  }

  highlightLatLongLine(latLongLine: L.LatLng[]) {
    if (this.highlightLine) this.baseMap.removeLayerFromMap(this.highlightLine);

    this.highlightLine = L.polyline(latLongLine, {stroke: true, weight: MapInfo.HIGHLIGHT_LINE_WEIGHT, color: MapInfo.HIGHLIGHT_LINE_COLOR, opacity: MapInfo.HIGHLIGHT_OPACITY-0.3});

    this.baseMap.addLayerToMap(this.highlightLine);
  }

  highlightPolyline(line: L.Polyline) {
    this.highlightLatLongLine(line.getLatLngs() as L.LatLng[]);
  }

  highlightGeoRoute(geoRoute: L.GeoJSON){
    if (this.highlightLine) this.baseMap.removeLayerFromMap(this.highlightLine);

    let layerGroup = L.layerGroup();

    let polylines: L.Polyline [] = RouteGeographicalService.getGeoRoutePolyline(geoRoute);
    polylines.forEach(line => {
      let highLine = L.polyline(line.getLatLngs() as L.LatLng[], {stroke: true, weight: MapInfo.HIGHLIGHT_LINE_WEIGHT, color: MapInfo.HIGHLIGHT_LINE_COLOR, opacity: MapInfo.HIGHLIGHT_OPACITY-0.3});
      layerGroup.addLayer(highLine);
    });

    this.highlightLine = layerGroup;
    
    this.baseMap.addLayerToMap(this.highlightLine);
  }

  glOnResize(maximizing?) {
    let width: number;
    let height: number;

    if (this.sidenavcontent) {
      let nativeElement = this.sidenavcontent.getElementRef().nativeElement;
      width = nativeElement.parentElement.clientWidth;
      height = nativeElement.parentElement.clientHeight;
    }
    else {
      width = this.glContainerWidth();
      height = this.glContainerHeight() - HEADER_VERTICAL_SPACE;
    }

    width = Math.floor(width) - 1;
    height = Math.floor(height) - 1;

    if (this.openedSideList) {
      width = width - SIDE_HORIZONTAL_SPACE;
    }

    if (width <= 0 || height <= 0) return;

    this.logger.debug(`MapComponent.glOnResize - [${width.toFixed(0)}, ${height.toFixed(0)}]`);

    this.mapStyle = { width:width.toFixed(0) + 'px', height: height.toFixed(0) + 'px'};

    setTimeout(() => {this.baseMap.invalidateSize();}, 100);
  }

  /**
   * ###################################################
   * Popout
   * ###################################################
   */

  glOnPopout() {
    super.glOnPopout();
    this.savePopoutData()
  }

  glOnPopin() {
    super.glOnPopin();
    this.savePopoutData()
  }

  savePopoutData() {
    this.storageService.setPopoutData({
      alertsList: this.getAlertsList(),
      pointsList: this.getPointsList(),
      operationsIdList: this.getOperationsIdList(),
      operationsList: this.getOperationsList(),
      eventsList: this.getEventsList(),
      historicalTrackingList: this.getHistoricalTrackingList(),
      historicalTrackingOptionsList: this.getHistoricalTrackingOptionsList(),
      layerFilter: this.layersFilter,
      areasFiltered: this.areasFiltered,
      allResponsibles: this.allResponsibles,
      openedSideList: this.openedSideList,
    });
  }

  restorePopoutData(popout) {
    this.logger.debug("MapComponent.restorePopoutData - ", popout);

    if (popout.layerFilter) {
      this.layersFilter = popout.layerFilter;
      this.areasFiltered = popout.areasFiltered;
      this.allResponsibles = popout.allResponsibles;

      this.baseMap.updateAllFilters();
    }

    popout.alertsList.forEach( (alert: AlertModel) => {
      this.onAlertSelection(alert, false); // Não centraliza
    });

    popout.pointsList.forEach( (inspectionPoint: InspectionPointModel) => {
      this.onInspectionPointSelection(inspectionPoint, false); // Não faz fit
    });

    popout.operationsList.forEach( (operation: OperationModel) => {
      this.onOperationSelection(operation, false); // Não faz fit
    });

    popout.operationsIdList.forEach( (data) => {
      this.onOperationIdSelection(data, false); // Não faz fit
    });

    popout.eventsList.forEach( (event: EventModel) => {
      this.onEventSelection(event, false); // Não centraliza
    });

    // Apenas adiciona na lista
    popout.historicalTrackingList.forEach( (options) => {
      let historicalTracking = new HistoricalTrackingObject();
      historicalTracking.patrolTeam = options.patrolTeam;
      historicalTracking.operation = options.operation;
      historicalTracking.user = options.user;
      historicalTracking.sourceType = options.sourceType;
      historicalTracking.mobileObjectId = options.mobileObjectId;
      let id = this.getHistoricalTrackingId(options.mobileObjectId, options.sourceType, options.patrolTeam, options.operation, options.user?.id);
      this.historicalTrackingMap.set(id, historicalTracking);
    });

    // Agora processa os dados considerando as opções selecionadas
    popout.historicalTrackingOptionsList.forEach( (options) => {
      let id = this.getHistoricalTrackingId(options.mobileObjectId, options.sourceType, options.patrolTeam, options.operation, options.user?.id);
      let historicalTracking: HistoricalTrackingObject = this.historicalTrackingMap.get(id);
      historicalTracking.finishTime = options.finishTime;
      historicalTracking.startTime = options.startTime;
      historicalTracking.visible = options.visible;
      historicalTracking.timeVisible = options.timeVisible;
      historicalTracking.stateVisible = options.stateVisible;

      this.reloadSignalsForHistoricalTracking(historicalTracking);
    });

    this.openedSideList = popout.openedSideList;
    if (this.openedSideList) {
      this.sidenav.open();
      this.glOnResize();
    }
  }

  /**
   * ###################################################
   * Menu de Contexto
   * ###################################################
   */

  eventClicked(marker){
     // Cria um novo evento
    this.glOpenContainer("events-edit", {id: null, options: {} });
  }

  verificationClicked(marker) {  // Use "any" because of mobileObjectId and patrolTeamId
    let patrolTeam : PatrolTeamModel;
    const trackingObject = this.trackingObjectsMap.get(marker.mobileObjectId);
    if(trackingObject){
      patrolTeam = trackingObject.tracking.patrolTeam;
    }
    else {
      const teamTrackingObject = this.teamTrackingObjectsMap.get(marker.patrolTeamId);
      if (teamTrackingObject) {
        patrolTeam = teamTrackingObject.patrolTeam;
      }
    }

    // Mas antes seleciona qual o evento ficará associado a verificação

    let filter: EventFilterModel =  new EventFilterModel();
    let dialogRef = this.dialog.open(EventListDialogComponent, {
      panelClass: 'sipd-modal',
      data: filter
    });
    dialogRef.afterClosed().pipe(first()).subscribe( (events: EventModel[]) => {
      if(events && events.length == 1){
        // Cria uma nova verificação
        this.glOpenContainer('verifications-edit', {id: null, options: {event: events[0], patrolTeam: patrolTeam }});
      }
    });
  }

  /**
   * ###################################################
   * Busca
   * ###################################################
   */

  updateListedCount(map: Map<string,AbstractMapObject>) {
    let count: number = 0;
    if (this.searchValue) {
      map.forEach (object => {
        if (object.listed) count++;
      });
    }
    else {
      count = map.size;
    }
    return count;
  }

  updateOperationListedCount() {
    this.operationsListedCount['PATROL'] = 0;
    this.operationsListedCount['EVENT_VERIFICATION'] = 0;
    this.operationObjectsMap.forEach(operationObject => {
      if (operationObject.listed) this.operationsListedCount[operationObject.operation.type]++;
    });
  }

  updateTrackingListedCount() {
    this.trackingsListedCount['MOBILE_APP'] = 0;
    this.trackingsListedCount['VEHICLE'] = 0;
    this.trackingObjectsMap.forEach (trackingObject => {
      if (trackingObject.listed) this.trackingsListedCount[trackingObject.tracking.signal.sourceType]++;
    });
  }

  updateTrackingListed(trackingObject: TrackingMapObject){
    // TODO scuri Não usamos trackingObject.popupContent porque o conteúdo é atualizado dinamicamente ao longo do tempo. O ideal é o basemap armazenar essa string.
    const str = TrackingModel.getPopupContent(trackingObject.tracking, true).toLowerCase();
    if (str.includes(this.searchValue)){
      trackingObject.listed = true;
      this.lastSearchResult = {object: trackingObject};
    }
    else {
      trackingObject.listed = false;
    }
  }

  updateTeamTrackingListedCount() {
    this.teamTrackingsListedCount = 0;
    this.teamTrackingObjectsMap.forEach (teamTrackingObject => {
      if (teamTrackingObject.listed) this.teamTrackingsListedCount++;
    });
  }

  updateTeamTrackingListed(teamTrackingObject: TeamTrackingMapObject){
    let str = '';
    teamTrackingObject.trackings.forEach(tracking => {
      // TODO scuri Não usamos trackingObject.popupContent porque o conteúdo é atualizado dinamicamente ao longo do tempo. O ideal é o basemap armazenar essa string.
      str += TrackingModel.getPopupContent(tracking, true).toLowerCase();
    });
    if (str.includes(this.searchValue)){
      teamTrackingObject.listed = true;
      this.lastSearchResult = {object: teamTrackingObject};
    }
    else {
      teamTrackingObject.listed = false;
    }
  }

  updateListedObject(object: AbstractMapObject) {
    if (!object.popupContent) {       
      object.listed = false;
      return;
    }
    
    const str = object.popupContent.toLowerCase();
    if (str.includes(this.searchValue)){
      object.listed = true;
      this.lastSearchResult = {object: object};
    }
    else {
      object.listed = false;
    }
  }

  updateListedObjectsMap(map: Map<string,AbstractMapObject>) {
    let count: number = 0;
    map.forEach (object => {
      this.updateListedObject(object);
      if (object.listed) count++;
    });
    return count;
  }

  resetListedObjectsMap(map: Map<string,AbstractMapObject>) {
    let count: number = map.size;
    map.forEach (object => {
      object.listed = true;
    });
    return count;
  }

  resetListedObjects() {
    this.trackingsListedCount['MOBILE_APP'] = 0;
    this.trackingsListedCount['VEHICLE'] = 0;
    this.trackingObjectsMap.forEach (trackingObject => {
      trackingObject.listed = true;
      this.trackingsListedCount[trackingObject.tracking.signal.sourceType]++;
    });
    this.trackingsExpanded['MOBILE_APP'] = false;
    this.trackingsExpanded['VEHICLE'] = false;

    this.teamTrackingsListedCount = 0;
    this.teamTrackingsListedCount = 0;
    this.teamTrackingObjectsMap.forEach (teamTrackingObject => {
      teamTrackingObject.listed = true;
      this.teamTrackingsListedCount++;
    });
    this.teamTrackingsExpanded = false;
    this.teamTrackingsExpanded = false;

    this.operationsListedCount['PATROL'] = 0;
    this.operationsListedCount['EVENT_VERIFICATION'] = 0;
    this.operationObjectsMap.forEach (operationObject => {
      operationObject.listed = true;
      this.operationsListedCount[operationObject.operation.type]++;
    });
    this.operationsExpanded['PATROL'] = false;
    this.operationsExpanded['EVENT_VERIFICATION'] = false;

    this.alertsListedCount = this.resetListedObjectsMap(this.alertObjectsMap);
    this.pointsListedCount = this.resetListedObjectsMap(this.pointObjectsMap);
    this.eventsListedCount = this.resetListedObjectsMap(this.eventObjectsMap);
    this.historicalTrackingsListedCount = this.resetListedObjectsMap(this.historicalTrackingMap);
    this.alertsExpanded = false;
    this.pointsExpanded = false;
    this.eventsExpanded = false;
    this.historicalTrackingsExpanded = false;

    this.resetAllLayersSearch();
    this.layersExpanded = false;
  }

  resetAllLayersSearch() {
    this.resetSearchLayer(this.baseMap.DC_HISTORY_ID);
    this.resetSearchLayer(this.baseMap.BAND_ID);
    this.resetSearchLayer(this.baseMap.GASDUCT_ID);
    this.resetSearchLayer(this.baseMap.OILDUCT_ID);
    this.resetSearchLayer(this.baseMap.SIMF_ID);
    this.resetSearchLayer(this.baseMap.DELIVERY_POINT_ID);
    this.resetSearchLayer(this.baseMap.KILOMETER_MARK_ID);
    this.resetSearchLayer(this.baseMap.REFINARY_ID);
    this.resetSearchLayer(this.baseMap.TERMINAL_ID);
    this.resetSearchLayer(this.baseMap.VALVE_ID);
    this.resetSearchLayer(this.baseMap.OBSERVED_AREA_ID);
    this.layersSearchCount = 0;
  }

  layerReady(id: string){
    this.logger.debug("MapComponent.layerReady("+id+")");
    if (this.searchValue) {
      if (this.layersSearchMap.get(id)) {  // Proteção para caso a busca seja feita antes de todos os layers terem sido carregados
        this.layersSearchCount -= this.layersSearchMap.get(id).length;

        this.searchLayer(id);

        this.layersSearchCount += this.layersSearchMap.get(id).length;
        this.layersExpanded = this.layersSearchCount > 0;
      }
    }
    else {
      this.resetSearchLayer(id);
    }

    if (id == this.baseMap.DC_HISTORY_ID) {
      let dcs = this.baseMap.layerData(this.baseMap.DC_HISTORY_ID) as DcModel[];
      dcs.forEach(dc => {
        if(!this.allDcYears.includes(dc.year)){
          this.allDcYears.push(dc.year);
        }
      });
      this.allDcYears.sort();
    }

    if (id == this.baseMap.BAND_ID) {
      this.allBands = this.baseMap.layerData(this.baseMap.BAND_ID) as BandModel[];
      this.layersFilterSelectState();
    }

    if (id == this.baseMap.OBSERVED_AREA_ID){
      this.allObservedAreas = this.baseMap.layerData(this.baseMap.OBSERVED_AREA_ID) as ObservedAreaModel[];
      this.filterObservedArea();
      if (this.lastSavedArea) {
        this.onObservedAreaSelection(this.lastSavedArea, false);
        this.lastSavedArea = null;
      }
    }
  }

  getLayerTooltip(id) {
    if (id==this.baseMap.KILOMETER_MARK_ID) {
      return "\nMarcos Quilométricos são listados apenas durante o Buscar.\nE são visiveis apenas a partir de um determinado nível de zoom.";
    }
    return "";
  }

  resetSearchLayer(id: string) {
    if (id == this.baseMap.KILOMETER_MARK_ID) {
      this.layersSearchMap.set(this.baseMap.KILOMETER_MARK_ID, []);
      return;
    }

    let geoData: GeoModel[] = this.baseMap.layerData(id);
    this.layersSearchMap.set(id, geoData);
    this.layersSearchExpanded[id] = false;
  }

  searchLayer(id: string): number {
    let searchResult: GeoModel[] = [];
    this.layersSearchMap.set(id, searchResult);

    // Algumas camadas não podem ser consultadas dependendo do perfil do usuário
    if (!this.baseMap.isLayerOptionToProfile(id)) return 0;

    // Como são muitos KmMarks, criamos a função sob demanda
    let getPopupContent;
    if (id == this.baseMap.KILOMETER_MARK_ID) {
      getPopupContent = function(feature) {
        const kmMark = feature.properties;
        return kmMark?.name;
      };
    }
    else {
      getPopupContent = this.baseMap.layerPopupContent(id);
      if (!getPopupContent) return 0; // Proteção para caso a busca seja feita antes de todos os layers terem sido carregados
    }

    let result_count = 0;

    let geoData: GeoModel[] = this.baseMap.layerData(id);
    geoData?.forEach(geo => {
      if (result_count > MapInfo.MAX_LAYER_RESULT){
        return;
      }
      const feature = {properties: geo};
      const str = getPopupContent(feature, true).toLowerCase();
      if (str.includes(this.searchValue)){
        searchResult.push(geo);
        this.lastSearchResult = {layer: geo};
      }
    });

    let count = this.layersSearchMap.get(id).length;
    this.layersSearchExpanded[id] = count > 0;

    return count;
  }

  layerCount(id: string) {
    let geoData: GeoModel[] = this.baseMap.layerData(id);
    return geoData.length;
  }

  showLastSearchResult(){
    if (this.lastSearchResult){
      if (this.lastSearchResult.layer) {
        this.onLayerSearchClick(this.lastSearchResult.layer);
      }
      else {
        if (this.lastSearchResult.object instanceof AlertMapObject){
          this.onAlertClick(this.lastSearchResult.object);
        }
        if (this.lastSearchResult.object instanceof TrackingMapObject){
          this.onTrackingClick(this.lastSearchResult.object);
        }
        if (this.lastSearchResult.object instanceof EventMapObject){
          this.onEventClick(this.lastSearchResult.object);
        }
        if (this.lastSearchResult.object instanceof OperationMapObject){
          this.onOperationClick(this.lastSearchResult.object);
        }
        if (this.lastSearchResult.object instanceof HistoricalTrackingObject){
          this.onHistoricalTrackingClick(this.lastSearchResult.object);
        }
        if (this.lastSearchResult.object instanceof InspectionPointMapObject){
          this.onPointClick(this.lastSearchResult.object);
        }
      }
    }
  }

  onLayerSearchClick(geo){
    this.onLayerSelection(geo);
  }

  searchMapObjects(){
    let searchCount: number = 0;

    this.trackingsListedCount['MOBILE_APP'] = 0;
    this.trackingsListedCount['VEHICLE'] = 0;
    this.trackingObjectsMap.forEach(trackingObject => {
      this.updateTrackingListed(trackingObject);
      if (trackingObject.listed) this.trackingsListedCount[trackingObject.tracking.signal.sourceType]++;
    });
    searchCount += this.trackingsListedCount['MOBILE_APP'];
    searchCount += this.trackingsListedCount['VEHICLE'];
    this.trackingsExpanded['MOBILE_APP'] = this.trackingsListedCount['MOBILE_APP'] > 0;
    this.trackingsExpanded['VEHICLE'] = this.trackingsListedCount['VEHICLE'] > 0;

    this.teamTrackingsListedCount = 0;
    this.teamTrackingsListedCount = 0;
    this.teamTrackingObjectsMap.forEach(teamTrackingObject => {
      this.updateTeamTrackingListed(teamTrackingObject);
      if (teamTrackingObject.listed) this.teamTrackingsListedCount++;
    });
    searchCount += this.teamTrackingsListedCount;
    searchCount += this.teamTrackingsListedCount;
    this.teamTrackingsExpanded = this.teamTrackingsListedCount > 0;
    this.teamTrackingsExpanded = this.teamTrackingsListedCount > 0;

    this.operationsListedCount['PATROL'] = 0;
    this.operationsListedCount['EVENT_VERIFICATION'] = 0;
    this.operationObjectsMap.forEach(operationObject => {
      this.updateListedObject(operationObject);
      if (operationObject.listed) this.operationsListedCount[operationObject.operation.type]++;
    });
    searchCount += this.operationsListedCount['PATROL'];
    searchCount += this.operationsListedCount['EVENT_VERIFICATION'];
    this.operationsExpanded['PATROL'] = this.operationsListedCount['PATROL'] > 0;
    this.operationsExpanded['EVENT_VERIFICATION'] = this.operationsListedCount['EVENT_VERIFICATION'] > 0;

    searchCount += this.alertsListedCount = this.updateListedObjectsMap(this.alertObjectsMap);
    searchCount += this.pointsListedCount = this.updateListedObjectsMap(this.pointObjectsMap);
    searchCount += this.eventsListedCount = this.updateListedObjectsMap(this.eventObjectsMap);
    searchCount += this.historicalTrackingsListedCount = this.updateListedObjectsMap(this.historicalTrackingMap);
    this.alertsExpanded = this.alertsListedCount > 0;
    this.pointsExpanded = this.pointsListedCount > 0;
    this.eventsExpanded = this.eventsListedCount > 0;
    this.historicalTrackingsExpanded = this.historicalTrackingsListedCount > 0;

    this.layersSearchCount = 0;
    this.layersSearchCount += this.searchLayer(this.baseMap.DC_HISTORY_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.BAND_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.GASDUCT_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.OILDUCT_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.SIMF_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.DELIVERY_POINT_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.KILOMETER_MARK_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.REFINARY_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.TERMINAL_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.VALVE_ID);
    this.layersSearchCount += this.searchLayer(this.baseMap.OBSERVED_AREA_ID);
    this.layersExpanded = this.layersSearchCount > 0;
    
    searchCount += this.layersSearchCount;

    if (searchCount == 1) this.showLastSearchResult();
  }

  layerSearchListOrder = (a: KeyValue<string, GeoModel>, b: KeyValue<string, GeoModel>): number => {
    return a.value.name.toLowerCase().localeCompare(b.value.name.toLowerCase());
  }

  keyupSearch() {
    // A cada tecla o timer é reiniciado
    if (this.searchTimeOut) {clearTimeout(this.searchTimeOut); this.searchTimeOut = null;}

    // A busca só acontece quando o timer é acionado
    this.searchTimeOut = setTimeout(() => {
      if (this.searchValue) {
        this.searchValue = this.searchValue.trim().toLowerCase();

        if (this.searchValue.length == 0) {
          this.searchValue = undefined;
          this.resetListedObjects();
        }
        else {
          this.searchMapObjects();
        }
      }

      this.searchTimeOut = null;
    }, 2000);
  }

  onRemoveSearch(){
    this.searchValue = undefined;
    this.resetListedObjects();
    if (this.searchTimeOut) {clearTimeout(this.searchTimeOut); this.searchTimeOut = null;}
  }
}
