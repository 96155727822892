import { Component, Inject } from '@angular/core';
import { EditComponent } from '../../../edit-component';
import { NGXLogger } from 'ngx-logger';
import { CompanyService } from 'src/app/service/model/company.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CompanyModel } from 'src/app/model/company.model';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { Permission } from 'src/app/model/enums.enum';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './company-edit.component.scss']
})
export class CompanyEditComponent extends EditComponent {

  constructor(logger:                     NGXLogger,
              companyService:             CompanyService,
              public singleDataCacheService:  SingleDataCacheService,
              dialog:                     MatDialog,
              toastr:                     ToastrService,
              public entityCacheService:  EntityCacheService,
              protected storageService:   StorageService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, companyService, dialog, environment.COMPANY_MODEL_LABEL, environment.COMPANY_TITLE_LABEL, storageService,
          'companies-edit', environment.COMPANY_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
    this.logger.debug('CompanyEditComponent.constructor()');
  }

  createData(options?) {
    this.logger.debug('CompanyEditComponent.createData()');

    this.model = new CompanyModel();
    this.view = {};

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  loadFormOptionsData(): void {
    this.logger.debug('CompanyEditComponent.loadFormOptionsData()');
    this.entityCacheService.loadPlacements(this.loadingListService);

    const metadatas: string[] = [
      "State"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_COMPANY)){
      return false
    }

    return true;
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['name'] ||
        !this.view['placement']) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    if (!this.view['placement']) names.push('Lotação');
    return names;
  }

  mapModelToView() {
    super.mapModelToView();

    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['name'] = null;
  }
}
