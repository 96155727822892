<div class="sipd-container">

  <div class="sipd-header">
    <button mat-icon-button color="accent" (click)="sidenav.toggle(); sidenavToggle(sidenav.opened)">
      <mat-icon *ngIf="!sidenav.opened">filter_list</mat-icon>
      <mat-icon *ngIf="sidenav.opened">arrow_left</mat-icon>
    </button>
    <div class="mat-title m-0">{{title}}</div><small matTooltip="Número de Registros Filtrados">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions">
      <div>
        <mat-form-field class="list-filter header-input" appearance="outline">
          <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
          <input matInput type="search" (keyup)="applySearch($event?.target?.value)" placeholder="Buscar...">
          <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
        </mat-form-field>
      </div>
    </div>

    <div class="actions">
      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar no Mapa as Áreas Observadas Selecionadoas"
        [disabled]="this.selection?.selected?.length == 0"
        (click)="onLocationManyClick(mapEvents.MANY_OBSERVED_AREA_LOCATION)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button  color="primary" (click)="onCreateClick()"
       [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_OBSERVED_AREA)'
        matTooltip="Adicionar Área Observada">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-icon-button color="primary" matTooltip="Abrir Área(s) Observada(s) Selecionada(s)"
        [disabled]="this.selection?.selected?.length == 0"
        (click)="onEditManyClick(this.selection?.selected)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDeleteManyClick(this.selection?.selected, $event)"
      [hidden]='!authorizationService.userHasPermission(permission.DELETE_OBSERVED_AREA)'
         matTooltip="Remover Áreas Observadas Selecionadas"
         [disabled]="this.selection.selected.length == 0">
         <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="sipd-content bg-white">
    <mat-sidenav-container class="h-100">
      <mat-sidenav #sidenav mode="side" closed style="width: 320px;">
        <mat-accordion multi displayMode="flat" class="map-panel m-0 p-0">
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="mat-body-2">Filtro</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-observed-area-filter [inputFilterModel]="filterModel" (filtered)="updateDataSource($event)"></app-observed-area-filter>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-sidenav>
      <mat-sidenav-content>
        <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select" sticky>
            <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
                [checked]="selection?.hasValue() && isAllSelected()"
                [indeterminate]="selection?.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
              </mat-checkbox>
            </th>
            <td mat-cell class="txt-center fit-content" *matCellDef="let row">
              <mat-checkbox (click)="$event?.stopPropagation()" color="accent"
                (change)="$event ? onCheckboxClick(row) : null" [checked]="selection?.isSelected(row)"
                [matTooltip]="checkboxTip(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="identifier">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell class="nowrap" *matCellDef="let element">
              {{element?.identifier}}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell class="nowrap" *matCellDef="let element">
              <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip [class]="element?.status ? element.status + ' text-white' : null " class="justify-content-center"
                  [disabled]="element?.status ? null : 'disabled'">
                  {{ statusDescription[element?.status] }}
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Nome da Área </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.name }} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="areaType">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{ typesDescription[element?.areaType] }} </td>
          </ng-container>

          <!-- Creation Date Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora (Criação) </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.createdAt | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <!-- Responsible Column -->
          <ng-container matColumnDef="responsible.name">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Responsável </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.responsible | toView }} </td>
          </ng-container>

          <!-- Band Column -->
          <ng-container matColumnDef="band">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Faixa </th>
            <td mat-cell class="nowrap" class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
          </ng-container>

          <!-- Duto Column -->
          <ng-container matColumnDef="duct">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Duto </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.duct}} </td>
          </ng-container>

          <!-- KM Column -->
          <ng-container matColumnDef="km">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> KM </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.stretchStartKM && element?.stretchEndKM ?
              element?.stretchStartKM + " - " + element?.stretchEndKM : element?.km}} </td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="startDate">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data de Início </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.startDate | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <!-- End Date Column -->
          <ng-container matColumnDef="endDate">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data de Fim </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.endDate | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <!-- Update Date Column -->
          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Atualizado </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.updatedAt | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
            (contextmenu)="openContextMenu($event, element)"
            [ngClass]="{ 'highlighted': selection?.isSelected(row) }">
          </tr>

        </table>

        <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
          [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
        </div>

        <mat-menu #contextMenu="matMenu">
          <button mat-menu-item
           (click)="onEditClick(this.contextMenuSelectedItem, $event)">
            <mat-icon class="material-icons-outlined">folder_open</mat-icon>
            <span>Abrir Área Observada</span>
          </button>
          <button mat-menu-item
            (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.identifier, $event)"
            [hidden]='!authorizationService.userHasPermission(permission.DELETE_OBSERVED_AREA)'>
            <mat-icon class="material-icons-outlined">delete</mat-icon>
            <span>Remover Área Observada</span>
          </button>
          <button mat-menu-item color="primary" (click)="onLocationClick(this.contextMenuSelectedItem, mapEvents.OBSERVED_AREA_LOCATION)" [disabled]="!hasLocation(this.contextMenuSelectedItem)">
            <mat-icon class="material-icons-outlined">location_on</mat-icon><span>Mostrar no Mapa</span>
          </button>
        </mat-menu>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

