import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { EntityService } from './entity.service';

@Injectable({
  providedIn: 'root'
})
export class PlacementService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/placements`);
  }
}
