import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { FILTER_OPTION_ALL } from 'src/app/common/constants';
import { EntityModel } from 'src/app/model/entity.model';
import { ObservedAreaFilterModel } from 'src/app/pages/registrations/observed-area/observed-area-filter/observed-area.filter.model';
import { environment } from 'src/environments/environment';
import { EntityService } from './entity.service';

@Injectable({
  providedIn: 'root'
})
export class ObservedAreaService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/observed-areas`);
  }


  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: ObservedAreaFilterModel): Observable<T> {

    let params: HttpParams = new HttpParams();

    if (filter){
      if (filter.startDate){
        const startDateTime = moment(filter.startDate).format('x');
        params = params.set('start', startDateTime);
      }

      if (filter.endDate){
        const endDateTime = moment(filter.endDate).format('x');
        params = params.set('end', endDateTime);
      }

      if (filter.areaStatus != FILTER_OPTION_ALL){
        params = params.append('status', filter.areaStatus);
      }

      if (filter.responsibles && filter.responsibles.length > 0){
        filter.responsibles.forEach( responsibleId => {
          params = params.append('responsibles', responsibleId);
        });
      }

      if (filter.types && filter.types.length > 0){
        filter.types.forEach( type => {
          params = params.append('types', type);
        });
      }

      if (filter.bands && filter.bands.length > 0){
        filter.bands.forEach( band => {
          params = params.append('bands', band);
        });
      }

      if (filter.cities && filter.cities.length > 0){
        filter.cities.forEach( city => {
          params = params.append('cities', city);
        });
      }

      if (filter.states && filter.states.length > 0){
        filter.states.forEach( state => {
          params = params.append('states', state);
        });
      }
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }
}
