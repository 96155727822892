import {Injectable} from '@angular/core';
import { PointType, OperationType, OperationTypeDescription, InspectionStatusTypeDescription, InspectionStatusType } from '../../model/enums.enum';
import * as L from 'leaflet';
import * as omnivore from '@mapbox/leaflet-omnivore';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { InspectionModel } from '../../model/inspection.model';
import { OperationModel } from '../../model/operation.model';
import { Icons, MapInfo } from '../../common/constants';
import FieldUtils from '../util/field-utils';
import ObjectID from 'bson-objectid';
import { PatrolTeamModel } from '../../model/patrolteam.model';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import DateUtils from '../util/date-utils';
import { VerificationModel } from 'src/app/model/verification.model';

@Injectable()
export class RouteGeographicalService {

  constructor(private http: HttpClient){}

  private getRouteStyle = () => ({color: MapInfo.PLANNED_ROUTE_LINE_COLOR, weight: MapInfo.PLANNED_ROUTE_LINE_WEIGHT});

  public getRoutePopupContent(operation: OperationModel): string {
    return `<h5 style="text-align: center">Rota de Operação</h5>
            ${ OperationModel.getPopupContent(operation) }
            ${ PatrolTeamModel.getPopupContent(operation.patrolTeam) }
           ` ;
  }

  public getCriticalPointIcon(index: number, operationType?: string) {
    const point_icon_size = MapInfo.ROUTE_ICON_SIZE-4;
    let cpClassName: string = operationType === "EVENT_VERIFICATION" ? 'map-critical-point-event-verification' : 'map-critical-point';
    const icon = L.divIcon({
      iconSize: [ point_icon_size, point_icon_size ],
      iconAnchor: [ point_icon_size/2, point_icon_size/2 ],  // no centro do ícone
      className: cpClassName,
      html:'<div>'+index+'</div>'
    });
    return icon;
  }

  public getPointsMarker = (type: string, latLng: L.LatLngExpression, index: number, operationType?: string): L.Marker => {
    let icon;
    if (type === PointType.BASE){
      let baseIcon: string = operationType === "EVENT_VERIFICATION" ? Icons.ICON_URL_BASE_VERIFICATION : Icons.ICON_URL_BASE
      icon = L.icon({
        iconSize: [ MapInfo.ROUTE_ICON_SIZE, MapInfo.ROUTE_ICON_SIZE ],
        iconAnchor: [ MapInfo.ROUTE_ICON_SIZE/2, MapInfo.ROUTE_ICON_SIZE/2 ],  // no centro do ícone
        iconUrl: baseIcon
      });
    }
    else {
      icon = this.getCriticalPointIcon(index, operationType);
    }

    return L.marker(latLng, {icon: icon});
  }

  getPointsPopupContent(properties, operation: OperationModel): string {
    if (!properties.activities) {
      properties.activities = [];
    }
    let activityText = '';
    for (const text of properties.activities) {
      activityText = activityText + `<li>${text}</li>`;
    }

    return`<h5 style="text-align: center">Ponto de Operação</h5>
           <h6 style="text-align: center"><b>${ properties.index }</b></h6>
           <div><b>Status:</b> ${InspectionStatusTypeDescription[properties.status]} </div>
           ${ this.getTagDateOperationForPointsPopupContent(properties, operation) }
           <div><b>Sem Acesso:</b> ${properties.noAccess? ' Sim': ' Não'} </div>
           <div><b>Form. Preen.:</b> ${properties.hasFilledForms? ' Sim': ' Não'} </div>
           ${ OperationModel.getPopupContent(operation) }
           ${ PatrolTeamModel.getPopupContent(operation.patrolTeam) }
           ${!properties.band? `<div><b>Nome:</b> ${properties.name? properties.name: ''} </div>`: ''}
           <div><b>Base:</b> ${(properties.type === PointType.BASE)? ' Sim': ' Não'} </div>
           ${properties.band? `<div> <b>Faixa:</b>  ${properties.band} </div>` : ''}
           ${properties.km? `<div> <b>KM:</b> ${ properties.km} </div>` : ''}
           <div><b>Lat, Long:</b> ${FieldUtils.coordToString(properties.lat)},${FieldUtils.coordToString(properties.lng)} </div>
           ${properties.activities.length > 0 ?
              `<div><b>Atividades:</b></div>
              ${activityText}
              ` : ''}
          `;
  }

  /*
   Se o ponto de inspeção foi finalizado, exibir data/hora da conclusão.
   Caso contrário, exibir data/hora do planejamento da ronda OU do envio da verificação.
  */
  private getTagDateOperationForPointsPopupContent(properties, operation: OperationModel) : string {
    let labelDateOperation: string = 'Data/Hora ';
    let dateOperation: number;

    if (InspectionStatusType[properties.status] === InspectionStatusType.FINISHED) {
      labelDateOperation += 'do Ponto Finalizado';
      dateOperation = properties.timestamp;
    } else if (OperationType[operation.type] === OperationType.EVENT_VERIFICATION) {
      labelDateOperation += 'da Solicitação';
      dateOperation = (operation as VerificationModel).sentDate
    } else {
      labelDateOperation += 'de Início';
      dateOperation = operation.startDate;
    }
    return dateOperation ? `<div><b>${labelDateOperation}:</b> ${DateUtils.timestampToStringInMinutes(dateOperation)}</div>` : ''
  }

  getPointsTooltipContent(properties, operation: OperationModel): string {
    return`<div style="text-align: center"><b><big>${ properties.index }</big></b></div>
           <div>${OperationTypeDescription[operation.type] } ${operation.identifier}</div>
           <div>${FieldUtils.coordToString(properties.lat)},${FieldUtils.coordToString(properties.lng)}</div>
          `;
  }

  setInternalIds(object, inspection){
    inspection.editionId = object.editionId = ObjectID.generate();
  }

  getOperationPointMarkers(operation: OperationModel, inspections: InspectionModel[]): L.Marker[] {
    if (!inspections || inspections.length == 0)
      return null;

    let markers: L.Marker[] = [];

    inspections.forEach((inspection: InspectionModel, index) => {
      let properties = {
        name: inspection.name,
        index: index + 1,
        activities: inspection.activities,
        type: inspection.type,
        band: inspection.band,
        km: inspection.km,
        status: inspection.status,
        timestamp: inspection.timestamp,
        noAccess: inspection.inspectionPointNoAccess,
        operationType: operation.type,
        operationIdentifier: operation.identifier,
        lat: +inspection.location.latitude,
        lng: +inspection.location.longitude
      }

      let marker = this.getPointsMarker(inspection.type, [properties.lat, properties.lng], properties.index, operation.type);
      marker.bindPopup(this.getPointsPopupContent(properties, operation));
      marker.bindTooltip(this.getPointsTooltipContent(properties, operation));

      this.setInternalIds(marker, inspection);

      markers.push(marker);
    });

    return markers;
  }

  getOperationGeoPoints(operation: OperationModel): L.GeoJSON {
    if (!operation.inspections || operation.inspections.length == 0)
      return null;

    let geoData;

    geoData = [];

    operation.inspections.forEach((inspection: InspectionModel, index) => {
      let properties = {
        name: inspection.name,
        index: index + 1,
        activities: inspection.activities,
        type: inspection.type,
        band: inspection.band,
        km: inspection.km,
        status: inspection.status,
        timestamp: inspection.timestamp,
        noAccess: inspection.inspectionPointNoAccess,
        hasFilledForms: inspection.inspectionForms && inspection.inspectionForms.length > 0,
        operationType: operation.type,
        operationIdentifier: operation.identifier
      }

      let geoPoint = {
        type: 'Point',
        coordinates: [inspection.location.longitude, inspection.location.latitude], // Note que é invertido em coordinates (longitude, latitude)
        properties: properties
      };

      geoData.push(geoPoint);
    });

    // Necessário porque "this" não é reconhecido dentro do contexto daquelas callbacks
    const _this = this;

    return L.geoJSON(geoData, {
      pointToLayer: (feature, latLng) => {
        feature.properties.lat = latLng.lat;
        feature.properties.lng = latLng.lng;
        return _this.getPointsMarker(feature.properties.type, latLng, feature.properties.index, operation.type);
      },
      onEachFeature(feature, layer): void {
        layer.bindPopup(_this.getPointsPopupContent(feature.properties, operation));
        layer.bindTooltip(_this.getPointsTooltipContent(feature.properties, operation));
      }
    });
  }

  private cleanProperties = (properties: any) => {
    delete properties.styleUrl;
    delete properties.styleHash;
    delete properties.description;
  }

  validateRouteGeometry(geoRoute: L.GeoJSON): boolean {
    let layers: L.Layer[] = geoRoute.getLayers();
    let found = false;

    for (var id in layers) {
      let layer = layers[id];
      if (layer instanceof L.Polyline) {
        found = true;
      }
      else if (layer instanceof L.LayerGroup) {
        let geomLayers = layer.getLayers();
        for(var geomId in geomLayers) {
          let geomLayer = geomLayers[geomId];
          if (geomLayer instanceof L.Polyline) {
            found = true;
          }
        }
      }
    }

    return found;
  }

  static getGeoRoutePolyline(geoRoute: L.GeoJSON): L.Polyline [] {
    let layers: L.Layer[] = geoRoute.getLayers();
    let polylines: L.Polyline [] = [];

    for (var id in layers) {
      let layer = layers[id];
      if (layer instanceof L.Polyline) {
        polylines.push(layer);
      }
      else if (layer instanceof L.LayerGroup) {
        let geomLayers = layer.getLayers();
        for(var geomId in geomLayers) {
          let geomLayer = geomLayers[geomId];
          if (geomLayer instanceof L.Polyline) {
            polylines.push(geomLayer);
          }
        }
      }
    }

    return polylines;
  }

  importKmlRoute(blob: Blob, operation: OperationModel): Promise<any> {
    return new Promise<any>( (resolve) => {
      const _this = this;
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        const geoRouteData = omnivore.kml(event.target.result, null, L.geoJSON(null, {
          style: () => {
            return _this.getRouteStyle();
          },
          pointToLayer: () => {
            // clear all points of the route
            return null;
          },
          onEachFeature(feature, layer): void {
            feature.properties.operationIdentifier = operation.identifier;
            feature.properties.operationType = operation.type;
            layer.bindPopup(_this.getRoutePopupContent(operation));
            _this.cleanProperties(feature.properties);
          }
        }));
        resolve(geoRouteData);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  importPointsKml(blob: Blob): Promise<any>{
    return new Promise<any>((resolve) => {
      const reader = new FileReader();
      const _this = this;
      reader.onload = (event: any) => {
        const geoPoints = omnivore.kml(event.target.result, null, L.geoJSON(null, {
          pointToLayer: (feature, latLng) => {
            const iconName = feature.properties.styleUrl.substring(0, 10);
            let type;
            if (iconName === '#icon-1603'){
              type = PointType.BASE;
            }else {
              type = PointType.CRITICAL_POINT;
            }
            feature.properties.type = type;
            return _this.getPointsMarker(type, latLng, 0); // Será ignorado nesse momento
          },
          onEachFeature(feature, layer): void {
            feature.properties.activities = [];
            if (feature.properties.description) {
              feature.properties.activities = feature.properties.description.split('|');
            }
            _this.cleanProperties(feature.properties);
          }
        }));
        resolve(geoPoints);
      };
      reader.readAsDataURL(blob);
    });
  }

  public loadKML(operation: OperationModel): Promise<any>{
    return new Promise<any>((resolve) => {
      let baseUrl = `${environment.settings.patrols_address}/patrols`;
      if (operation.type === OperationType.EVENT_VERIFICATION){
        baseUrl = `${environment.settings.event_verifications_address}/verifications`;
      }
      let kmlUrl = 'kml-route';
      this.http.get(`${baseUrl}/${operation.id}/${kmlUrl}`, { responseType: 'blob' }).pipe(first()).subscribe((blob) => {
          this.importKmlRoute(blob, operation).then( (object) => {
            resolve(object);
          });
        });
    });
  }

  private blobToFile = (blob: Blob, fileName:string): File => {
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(blob);
    let file = new File(arrayOfBlob, fileName);
    return file;
  }

  public loadKMLFile(operationId: string, operationType: string, fileName: string): Observable<any> {

    let baseUrl = `${environment.settings.patrols_address}/patrols`;
    if (operationType === OperationType.EVENT_VERIFICATION) {
      baseUrl = `${environment.settings.event_verifications_address}/verifications`;
    }

    let kmlUrl = 'kml-route';
    return this.http.get(`${baseUrl}/${operationId}/${kmlUrl}`, { responseType: 'blob' }).pipe(first(), map((blob) => {
        return this.blobToFile(blob, fileName);
    }));
  }


  public importGeoKml(blob: Blob): Promise<any>{
    return new Promise<any>((resolve) => {
      const reader = new FileReader();
      const _this = this;
      reader.onload = (event: any) => {
        const geoPoints = omnivore.kml(event.target.result, null, L.geoJSON(null, {
        }));
        resolve(geoPoints);
      };
      reader.readAsDataURL(blob);
    });
  }
}
