<div [ngStyle]="mapStyle" id ="mapDOM"
     leaflet
     [leafletOptions]="options"
     [leafletLayersControl]="layersControl"
     [leafletLayers]="markerLayers"
     (leafletMapZoomEnd)="onMapZoomEnd($event)"
     (leafletMapMoveEnd)="onMapMoveEnd($event)"
     (leafletMapReady)="onMapReady($event)">

     <div *ngIf="shownDraw"
          leafletDraw
          [leafletDrawOptions]="drawOptions"
          [leafletDrawLocal]="drawLocal"
          (leafletDrawReady)="onDrawReady($event)"
          (leafletDrawCreated)="onDrawCreated($event)"
          (leafletDrawEdited)="onDrawEdited($event)"
          (leafletDrawDeleted)="onDrawDeleted($event)"          
          (leafletDrawEditStart)="onDrawEditStart($event)"          
          >          
     </div> 
    <div [leafletLayer]="drawLayer"></div>     
</div>
