import { Subject, Observable, Subscription } from 'rxjs';

// NOTA: Não estamos usando o LoadingListService injetado para que cada tela tenha o seu feedback.
//       Se o controle do feedback for comum, ao abrir telas lado a lado, todas as telas mostram o feedback quando uma está carregando.
// TODO scuri Falta melhorar o feedback dos componentes de filtros

export class LoadingListService {
  private loadingSubscription:Subscription;
  private loadingSubject = new Subject<boolean>();
  private loading$: Observable<boolean> = this.loadingSubject.asObservable();
  private showSpinner: boolean = false;
  private spinnerCount: number = 0;

  constructor(){
    this.subscribeToLoading();
    console.log("LoadingListService.constructor", this.spinnerCount);
  }

  // Para cada "new LoadingListService();" deve ter uma chamada para destroy
  destroy() {
    console.log("LoadingListService.destroy");
    this.loadingSubscription?.unsubscribe();
  }

  private subscribeToLoading(){
    // Fazemos isso dentro de um subscribe para evitar problema de concorrencia
    this.loadingSubscription = this.loading$.subscribe((loading: boolean)=>{
      if (loading) 
        this.spinnerCount++;
      else 
        this.spinnerCount--;

      // TODO scuri Essa chamada ao console faz essa função ficar um pouco mais lenta e com isso ela funciona melhor 
      //            quando várias chamadas ao loading são feitas em um intervalo de tempo curto
      console.log("LoadingListService.subscribeToLoading spinnerCount=", +this.spinnerCount);

      if (this.spinnerCount > 0)
        this.showSpinner = true;
      else
        this.showSpinner = false;
    });
  }

  getShowSpinner(): boolean {
    return this.showSpinner;
  }

  // Deve ser chamado antes de inciar um carregamento
  loadingOn() {
    this.loadingSubject.next(true);
  }

  // Deve ser chamado depois de um carregamento terminar
  loadingOff() {
    this.loadingSubject.next(false);
  }
  
  public getSpinnerCount(){
    return this.spinnerCount;
  }
}
