import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/general/confirmation-dialog/confirmation-dialog.component';
import { ValidationNoteModel } from 'src/app/model/validation.note.model';

@Component({
  selector: 'app-event-validation-note-dialog',
  templateUrl: './event-validation-note-dialog.component.html',
  styleUrls: ['./event-validation-note-dialog.component.scss']
})
export class EventValidationNoteDialogComponent implements OnInit{
  
  public validationNote:string;
  
  editing: boolean;

  constructor(public dialogRef: MatDialogRef<EventValidationNoteDialogComponent>, 
              protected dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: ValidationNoteModel) {
  }

  ngOnInit():void {
    if(this.data){
      this.editing = true;
      this.validationNote = this.data.note 
    }
    else{
      this.editing = false;    
    }
  }
  
  getTitle(){
    if(this.editing){
      return 'Editar Nota de Validação' ;
    }
    else {
      return 'Nova Nota de Validação';
    }
  }

  onSaveClick() {
    let msg: string = 'Atenção! Ao confirmar esta nota, esta operação não poderá ser desfeita. Se desejar, cancele e revise suas anotações antes da confirmação definitiva.';
    let data:any = {
      msg: msg, 
      title: 'Atenção', 
      okLabel: 'Confirmar'
    };
    
    this.dialog.open(ConfirmationDialogComponent, {
      data: data,
      width: '28rem',
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe((result)=>{
      if(result) {
        this.dialogRef.close(this.validationNote);
      }
    });
  }
}
