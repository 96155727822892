<div mat-dialog-title>
  <span>Salvar Ronda {{predicted? 'Prevista': 'com Repetição'}}</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-card>
    <div class="pb-2">
      <mat-radio-group [(ngModel)]="editionOptions">
        <mat-radio-button class="mb-4" [value]="justThisPatrolRadioButton" >Alterar somente esta ronda.</mat-radio-button>
        <br>
        <mat-radio-button [value]="fromThisPatrolRadioButton">Alterar esta ronda e todas as previstas a seguir.</mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="returnOption()">Salvar</button>
  <button mat-raised-button mat-dialog-close (click)="cancel()">Cancelar</button>
</mat-dialog-actions>
