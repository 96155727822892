import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, ElementRef, OnDestroy, HostListener, NgZone, Inject  } from '@angular/core';
import { Router} from '@angular/router';
import { AuthenticationService } from './pages/login/login-services';
import { StorageService } from './service/storage-service';
import { environment } from '../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { of, Subscription, Subject } from 'rxjs';
import { AuthorizationService } from './service/authorization/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { SystemCheckDialogComponent } from './general/system-check/system-check-dialog.component';
import { GoldenLayoutComponent, IExtendedGoldenLayoutConfig } from 'ngx-golden-layout';
import { BaseGoldenPanel } from './pages/base-golden-panel/base-golden-panel';
import { PAGE_MAXIMIZED_PREFIX, MAP_PAGE, REGISTRATION_UPDATE_EVENT, PATROL_UPDATE_EVENT, VERIFICATION_UPDATE_EVENT } from './common/constants';
import { OperationType, Permission, RegistrationType, UserType } from './model/enums.enum';
import { Websocket } from './service/websocket/websocket';
import { Message } from '@stomp/stompjs';
import { MarkersService } from './service/model/markers.service';
import { ChannelService } from './service/model/channel.service';
import { ToastrService } from 'ngx-toastr';
import { SingleDataCacheService } from './service/model/single.data.cache.service';
import { SingleDataService } from './service/model/single.data.service';
import { EntityCacheService } from './service/model/entity.cache.service';
import LanguageUtils from './service/util/language-utils';
import { EventMainActionModel } from 'src/app/model/event.main.action.model';
import { EventModel } from 'src/app/model/event.model';
import { FormModel } from 'src/app/model/form.model';
import { InspectionPointModel } from 'src/app/model/inspection.point.model';
import { ObservedAreaModel } from 'src/app/model/observed.area.model';
import { OperationModel } from 'src/app/model/operation.model';
import { PatrolModel } from 'src/app/model/patrol.model';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { PlacementModel } from 'src/app/model/placement.model';
import { ProfileModel } from 'src/app/model/profile.model';
import { UserModel } from 'src/app/model/user.model';
import { VehicleModel } from 'src/app/model/vehicle.model';
import { VerificationModel } from 'src/app/model/verification.model';
import { CompanyModel } from 'src/app/model/company.model';
import { LoginAsComponent } from './general/login-as/login-as.component';
import { TrackingService } from './service/model/tracking.service';
import { OperationService } from './service/model/operation.service';
import { VerificationService } from './service/model/verification.service';
import { PatrolService } from './service/model/patrol.service';
import { AbstractSignalModel } from './model/abstract.signal.model';
import { filter, first, takeUntil } from 'rxjs/operators';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { ConfirmationDialogComponent } from './general/confirmation-dialog/confirmation-dialog.component';


const INITIAL_LAYOUT: IExtendedGoldenLayoutConfig = {
  content: [{
    type: 'stack',
    id: 'stack_ccpd',
    isClosable: false,
    content: [{
      type: 'component',
      id: 'central-id',
      componentName: 'central',
      title: environment.CENTRAL_GROUP_LABEL,
      isClosable: false
    },
    {
      type: 'component',
      id: 'tracking-id',
      componentName: 'tracking',
      title: environment.TRACKING_GROUP_LABEL,
      isClosable: true
    },
    {
      type: 'component',
      id: 'channels-id',
      componentName: 'channels',
      title: environment.CHANNEL_GROUP_LABEL,
      isClosable: true
    }],
  }],
  settings: {
    maximiseAllItems: true,  // Funcionalidade extra do ngx-goldenlayout (todos os tabs ficam na stack maximizada, mesmo os de stacks/colunas/linhas diferentes )
    hasHeaders: true,
    constrainDragToContainer: true,
    reorderEnabled: true,
    selectionEnabled: false,
    popoutWholeStack: false,
    blockedPopoutsThrowError: true,
    closePopoutsOnUnload: true,
    showPopoutIcon: true,
    showMaximiseIcon: true,
    showCloseIcon: false,
  },
  dimensions: {
    borderWidth: 1,
    minItemHeight: 60,
    minItemWidth: 60,
    headerHeight: 28,
    dragProxyWidth: 350,
    dragProxyHeight: 350
  },
  labels: {
    maximise: 'Maximizar/Restaurar', // para compensar o bug abaixo
    minimise: 'Restaurar',
    popout: 'Destacar',
    // popin: 'Restaurar'  -- bug do GL, não tem o popin label no Labels, embora tenha no defaultConfig
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  loggedUser: UserModel;

  permission = Permission;
  notification: string;
  environment = environment;
  registeredDrag = false;

  public layout$ = of(INITIAL_LAYOUT);
  @ViewChild('comp', { static: true }) layout;

  @ViewChild('mainMenu', { static: false }) mainMenu: ElementRef;
  @ViewChild('adminMenu', { static: false }) adminMenu: ElementRef;
  @ViewChild('mapButton', { static: false }) mapButton: ElementRef;

  countClick = 0;
  baseGoldenPanel: BaseGoldenPanel;
  goldenLayoutComponent: GoldenLayoutComponent;

  isHiddenButtonListAlert: boolean;
  isHiddenButtonListTracking: boolean;
  isHiddenButtonTrackingTeam: boolean;
  isHiddenButtonListEvents: boolean;
  isHiddenButtonListVerification: boolean;
  isHiddenButtonListPatrols: boolean;
  isHiddenButtonListChannels: boolean;
  isHiddenButtonAdministration: boolean;
  isHiddenButtonRegistration: boolean;
  isHiddenButtonListPatrolTeam: boolean;
  isHiddenButtonListUsers: boolean;
  isHiddenButtonListProfiles: boolean;
  isHiddenButtonListVehicles: boolean;
  isHiddenButtonListCompany: boolean;
  isHiddenButtonListPlacement: boolean;
  isHiddenButtonMainEvents: boolean;
  isHiddenButtonObservedArea: boolean;
  isHiddenButtonForms: boolean;
  isHiddenButtonPoints: boolean;
  isHiddenButtonRegistrationGroup: boolean;
  hasUserProfile: boolean;

  private authenticationSubscription: Subscription;
  private inconsistentDataSubscription: Subscription;
  private notificationSubscription: Subscription;

  private websocketConnectSubscription: Subscription;

  private markersWebsocketSubscription: Subscription;
  private patrolUpdateWebsocketSubscription: Subscription;
  private verificationUpdateWebsocketSubscription: Subscription;
  private vehicleSignalsWebsocketSubscription: Subscription;
  private signalsWebsocketSubscription: Subscription;
  private registrationUpdateWebsocketSubscription: Subscription;
  private resetChannelWebsocketSubscription: Subscription;

  private markersWebsocket: Websocket;
  private patrolUpdateWebsocket: Websocket;
  private verificationUpdateWebsocket: Websocket;
  private vehicleSignalsWebsocket: Websocket;
  private signalsWebsocket: Websocket;
  private registrationUpdateWebsocket: Websocket;
  private resetChannelWebsocket: Websocket;


  isUserLogin = false;
  private readonly _destroying$ = new Subject<void>();


  constructor ( public logger:                  NGXLogger,
                private ngZone:                 NgZone,
                private router:                 Router,
                private toastr:                 ToastrService,
                private changeDetector:         ChangeDetectorRef,
                public  dialog:                 MatDialog,
                private authenticationService:  AuthenticationService,
                protected singleDataService:    SingleDataService,
                protected singleDataCacheService: SingleDataCacheService,
                protected entityCacheService:   EntityCacheService,
                private storageService:         StorageService,
                private trackingService:        TrackingService,
                private verificationService:    VerificationService,
                private patrolService: PatrolService,
                protected authorizationService: AuthorizationService,
                private markerService:          MarkersService,
                private channelService:         ChannelService,
                @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
                private msalService:            MsalService,
                private msalBroadcastService: MsalBroadcastService) {
  }

  showSettings() {
    const settings = environment.settings;
    this.logger.debug(' << Service URLs >>\n',
      `          auth: ${settings.auth_address}\n`,
      `        alerts: ${settings.alerts_address}\n`,
      `       markers: ${settings.markers_address}\n`,
      `       patrols: ${settings.patrols_address}\n`,
      ` registrations: ${settings.registrations_address}\n`,
      `      tracking: ${settings.tracking_address}\n`,
      ` verifications: ${settings.event_verifications_address}\n`,
      `     websocket: ${settings.websocket_address}\n`);
  }

  ngOnInit() {
    this.logger.debug("AppComponent.ngOnInit()");  // Chamado novamente quando um painel faz popout

    this.initLanguageUtils();

    this.showSettings();

    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      this.initLogin();
    });

    this.setIntervalToReloadToken();
  }

  private setIntervalToReloadToken(){
    setInterval(() => {
      this.authenticationService.reloadToken();
    }, 300000); // 5 minutos
  }

  private initLogin() {
    this.isUserLogin = this.msalService.instance.getAllAccounts().length > 0;
    if(!this.isUserLogin){
      this.login();
    }else{
      this.getAccessTokenByMsal();
    }
  }

  private getAccessTokenByMsal(){
    const account = this.msalService.instance.getAllAccounts()[0];
     const accessTokenRequest = {
      scopes: ["user.read"],
      account: account,
    };

    let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;

    msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      this.createUserSession(accessToken);
    })
    .catch(function (error) {
      //Acquire token silent failure
      this.logger.error("AppComponent.getAccessTokenByMsal()", error);
    });
  }

  /**
   * Método que procura no localStorage o accesstoken
   */
  private getAccessTokenFromLocalStorage(){
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && account.homeAccountId){

      let storage = window[this.msalService.instance.getConfiguration().cache.cacheLocation];

      let result:any;
      Object.keys(storage).forEach((key,index)=>{
                            const k = localStorage[key];
                            if(key.includes('accesstoken')){
                              result = k;
                              return;
                            }
                          });

      let accessToken = null;
      if(result){
        let o = JSON.parse(result);
        accessToken = o?.secret;
      }

      if (accessToken) {
        this.createUserSession(accessToken);
      }else{
        this.logger.error("AppComponent.getAccessTokenFromLocalStorage() - token not found");
      }
    }else{
      this.logger.error("AppComponent.getAccessTokenFromLocalStorage() - invalid account");
    }
  }

  private showLoginError() {
    this.resetLayout(); // fecha as janelas de: alertas, mensagens e rastreamento
    this.baseGoldenPanel.glOpenContainer('login-error', null);
  }

  async createUserSession(accessToken){
    this.subscribeOnAuthenticatedStatus();
    this.subscribeInconsistentData();

    const existUser = await this.authenticationService.createSession(accessToken)
    if(!existUser){
      this.showLoginError();
      return;
    }
  }

  private login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
        } else {
          this.msalService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.msalService.instance.setActiveAccount(response.account);
            });
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  ngOnDestroy(): void {
    this.authenticationSubscription?.unsubscribe();
    this.inconsistentDataSubscription?.unsubscribe();
    this.notificationSubscription?.unsubscribe();

    this.websocketConnectSubscription?.unsubscribe();

    this.markersWebsocketSubscription?.unsubscribe();
    this.registrationUpdateWebsocketSubscription?.unsubscribe();
    this.resetChannelWebsocketSubscription?.unsubscribe();
    this.patrolUpdateWebsocketSubscription?.unsubscribe();
    this.verificationUpdateWebsocketSubscription?.unsubscribe();
    this.vehicleSignalsWebsocketSubscription?.unsubscribe();
    this.signalsWebsocketSubscription?.unsubscribe();

    this.markersWebsocket?.destroy();
    this.markersWebsocket = null;
    this.registrationUpdateWebsocket?.destroy();
    this.registrationUpdateWebsocket = null;
    this.resetChannelWebsocket?.destroy();
    this.resetChannelWebsocket = null;
    this.patrolUpdateWebsocket?.destroy();
    this.patrolUpdateWebsocket = null;
    this.verificationUpdateWebsocket?.destroy();
    this.verificationUpdateWebsocket = null;
    this.vehicleSignalsWebsocket?.destroy();
    this.vehicleSignalsWebsocket = null;

    let goldenLayoutInstance = this.goldenLayoutComponent.getGoldenLayoutInstance();
    if (!goldenLayoutInstance.isSubWindow) {
      this.baseGoldenPanel.glUnSubscribeEvent('OPEN_CONTAINER');
    }
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngAfterViewInit() {
    this.logger.debug("AppComponent.ngAfterViewInit()");  // Chamado novamente quando um painel faz popout
    this.goldenLayoutComponent = this.layout as GoldenLayoutComponent;
    this.baseGoldenPanel = new BaseGoldenPanel(this.logger, this.goldenLayoutComponent, null);

    let goldenLayoutInstance = this.goldenLayoutComponent.getGoldenLayoutInstance();
    if (!goldenLayoutInstance.isSubWindow) {
      this.baseGoldenPanel.glSubscribeEvent('OPEN_CONTAINER', data => {
        this.logger.debug("AppComponent.OnOpenContainer()");
        this.baseGoldenPanel.glOpenContainer(data.componentName, data.data);
      });
    }

    this.trackingService.setEventHub(goldenLayoutInstance.eventHub);
  }

  initLanguageUtils() {
    LanguageUtils.init();

    EventMainActionModel.addLanguageMap();
    PlacementModel.addLanguageMap();
    VehicleModel.addLanguageMap();
    CompanyModel.addLanguageMap();
    UserModel.addLanguageMap();
    PatrolTeamModel.addLanguageMap();
    FormModel.addLanguageMap();
    ProfileModel.addLanguageMap();
    PatrolModel.addLanguageMap();
    VerificationModel.addLanguageMap();
    ObservedAreaModel.addLanguageMap();
    EventModel.addLanguageMap();
    OperationModel.addLanguageMap();
    InspectionPointModel.addLanguageMap();
  }

  registerDragElements() {
    let goldenLayoutInstance = this.goldenLayoutComponent.getGoldenLayoutInstance();
    if (!this.registeredDrag && !goldenLayoutInstance.isSubWindow &&
      this.baseGoldenPanel && this.mainMenu && this.adminMenu && this.mapButton) {
      this.logger.debug("AppComponent.registerDragElements()");
      this.baseGoldenPanel.glRegisterDragMainMenu(this.mainMenu.nativeElement);
      this.baseGoldenPanel.glRegisterDragMainMenu(this.adminMenu.nativeElement);
      this.baseGoldenPanel.glRegisterDragMainButton(this.mapButton.nativeElement.childNodes[0]);
      this.registeredDrag = true;
    }
  }

  glOpenContainerFromMainMenu(componentName) {
    this.baseGoldenPanel.glOpenContainerFromMainMenu(componentName);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (!this.baseGoldenPanel.glResetLayout()) {
      // TODO scuri Supostamente event.returnValue poderia conter uma string para o diálogo. Mas não consegui fazer isso funcionar. Ficou o diálogo default mesmo.
      return false;
    }
  }

  resetLayout() {
    if (!this.baseGoldenPanel.glResetLayout()) {
      this.toastr.warning("Existem janelas de edição com modificações. Salve ou descarte as edições antes de fechar todas as janelas.");
    }
  }

  renderComponent() {
    // aguarda um pouco para outras tarefas terminarem
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 250);
  }

  private subscribeOnAuthenticatedStatus() {
    this.authenticationSubscription = this.authenticationService.authenticated$.subscribe(auth => {
      this.logger.debug('AppComponent.OnAuthenticatedStatus() - ' + auth); // Notificação enviada quando o processo de login e load do usuário/profile corrente terminou por completo
      if (auth) {
        this.loggedUser = this.storageService.getLocalUser();

        this.checkPermissions();

        this.router.navigate(['/']);

        this.trackingService.initialize();

        // TODO scuri Roda o websocket fora do Angular, com isso não gera ChangeDetect. Porque????
        this.ngZone.runOutsideAngular(() => {
          this.subscribeOnMarkerWebsocketConnected();
          this.subscribeOnMarkersWebsocket();
          this.subscribeToRegistrationUpdateWebsocket();
          this.subscribeToResetChannelWebsocket();
          this.subscribeToVerificationUpdateWebsocket();
          this.subscribeToPatrolUpdateWebsocket();
          this.subscribeToSignalsWebsocketStream();
          this.subscribeToVehicleSignalsWebsocketStream();
        });
      }
    });
  }

  private subscribeInconsistentData() {
    this.inconsistentDataSubscription = this.authenticationService.idata$.subscribe(i_data => {
      this.showLoginError();
    });
  }

  private subscribeToResetChannelWebsocket() {
    this.resetChannelWebsocket = Websocket.createResetChannelWebsocket();
    this.resetChannelWebsocketSubscription = this.resetChannelWebsocket.stream().subscribe((message: Message) => {
      const marker = JSON.parse(message.body);
      if (!marker) return;

      if (marker.channelId) {
        let unreadMessages = marker.unreadMessages.find(element => element.userId === this.loggedUser.id);  // Esse array só vem pelo avro/kafka/websocket
        if (unreadMessages != null) {
          this.channelService.updateUserUnreadMessages(marker.channelId, unreadMessages); // Atualiza o contador de novas mensagens do canal em memória e notifica a lista de canais
        }
      }
    }, (error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Erro no servidor';
      this.logger.error(`AppComponent.onResetChannelWebsocket: ${errMsg}`);
    });
  }

  private subscribeOnMarkersWebsocket() {
    this.markersWebsocketSubscription = this.markersWebsocket.stream().subscribe((message: Message) => {
      if (message) {
        const marker = JSON.parse(message.body);
        if (!marker) return;

        if (marker.channelId) {
          let unreadMessages = marker.unreadMessages.find(element => element.userId === this.loggedUser.id);  // Esse array só vem pelo avro/kafka/websocket
          if (unreadMessages != null) {
            this.channelService.updateUserUnreadMessages(marker.channelId, unreadMessages); // Atualiza o contador de novas mensagens do canal em memória e notifica a lista de canais
          }
        }

        this.markerService.notifyNewMarkerReceived(marker); // Notifica nova mensagem recebida
      }
    }), (error: any) => {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[app.component.ts] subscribeToMarkersWebsocket: ${errMsg}`);
    };
  }

  /**
   * Ao conectar com o websocket, notifica os observadores
   */
  private subscribeOnMarkerWebsocketConnected() {
    this.markersWebsocket = Websocket.createMarkersWebsocket();
    this.websocketConnectSubscription = this.markersWebsocket.onConnectCallback().subscribe(() => {
      this.logger.log('OnMarkerWebsocketConnected ***** Conexão com o websocket estabelecida.');
      this.markerService.notifyWebsocketConnected(true);
    }, (error: any) => this.logger.error(error));
  }

  private operationUpdateEvent(id, operationService: OperationService, operationType: string){
    this.logger.debug('AppComponent.operationUpdateEvent id:' + id);
    operationService.getRecord(id).pipe(first()).subscribe((operation: OperationModel) => {
      if (operation) { // Pode retornar null se a operação foi removida
        operation.type = operationType;
        this.trackingService.updateOperation(operation, false);
      }
    }, (error: any) => this.logger.error(error));
  }

  private subscribeToPatrolUpdateWebsocket() {
    this.patrolUpdateWebsocket = Websocket.createPatrolUpdateWebsocket();
    this.patrolUpdateWebsocketSubscription = this.patrolUpdateWebsocket.stream().subscribe((message: Message) => {
      const entity = JSON.parse(message.body);
      if (entity && entity.id) {
        this.baseGoldenPanel.glEmitEvent(PATROL_UPDATE_EVENT, { id: entity.id, type: entity.type, patrolTeamId: entity.patrolTeamId, status: entity.status });
        this.operationUpdateEvent(entity.id, this.patrolService, OperationType.PATROL);
      }
    }, (error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[tracking.component.ts] subscribeToChangePatrolWebsocket: ${errMsg}`);
    });
  }

  private subscribeToVerificationUpdateWebsocket() {
    this.verificationUpdateWebsocket = Websocket.createVerificationUpdateWebsocket();
    this.verificationUpdateWebsocketSubscription = this.verificationUpdateWebsocket.stream().subscribe((message: Message) => {
      const entity = JSON.parse(message.body);
      if (entity && entity.id) {
        this.baseGoldenPanel.glEmitEvent(VERIFICATION_UPDATE_EVENT, { id: entity.id, type: entity.type, patrolTeamId: entity.patrolTeamId, status: entity.status });
        this.operationUpdateEvent(entity.id, this.verificationService, OperationType.EVENT_VERIFICATION);
      }
    }, (error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[tracking.component.ts] subscribeToChangeVerificationStatusWebsocket: ${errMsg}`);
    });
  }

  /** Se inscreve para receber mensagens do websocket de sinais de veículos */
  private subscribeToVehicleSignalsWebsocketStream() {
    this.vehicleSignalsWebsocket = Websocket.createVehicleSignalsWebsocket();
    this.vehicleSignalsWebsocketSubscription = this.vehicleSignalsWebsocket.stream().subscribe( (message: Message) => {
      this.processReceivedSignal(message.body);
    },(error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[tracking.component.ts] subscribeToVehicleSignalsWebsocketStream: ${errMsg}`);
    });
  }

  /** Se inscreve para receber mensagens do websocket de sinais */
  private subscribeToSignalsWebsocketStream() {
    this.signalsWebsocket = Websocket.createSignalsWebsocket();
    this.signalsWebsocketSubscription = this.signalsWebsocket.stream().subscribe( (message: Message) => {
      this.processReceivedSignal(message.body);
    },(error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[tracking.component.ts] subscribeToSignalsWebsocketStream: ${errMsg}`);
    });
  }

  /** Processa um sinal recebido, inserindo ou atualizando a lista lateral e atualizando o mapa */
  processReceivedSignal(signalInJson: string) {
    // Atenção esse método vai ser chamado com alta frequencia
    // this.logger.trace(`Sinal recebido: ${signalInJson}`);
    let signal: AbstractSignalModel;

    try {
      signal = JSON.parse(signalInJson);
    }catch (e) {
      this.logger.error(`Signal could not be processed. Body received: ${signalInJson}`);
      return;
    }

    if(!signal) return;

    // Atualiza o rastreamento
    this.trackingService.updateTracking(signal, true);
  }

  logoutClick(event) {
    let logoutSSO = true;
    if (event.ctrlKey) logoutSSO = false;

    if (logoutSSO) {
        // Só funciona corretamente na produção
        // No Ambiente de desenvolvimento dá erro:
        // ClientAuthError: end_session_endpoint_not_supported: Provided authority does not support logout
        const user = this.msalService.instance.getAllAccounts()[0];
        this.msalService.instance.logoutRedirect({ account: user});
    }

    this.authenticationService.logout();
  }

  public onControlClick() {
    this.dialog.open(SystemCheckDialogComponent, {
      width: '800px',
      panelClass: 'sipd-modal'
    });
  }

  stateChanged() {
    this.logger.debug('AppComponent.stateChanged()');

    if (!this.registeredDrag)
      this.registerDragElements();

    // TODO scuri getSerializableState causa um warning quando um painel faz popout
    // Ocorreu um erro no cliente:  Can't create config, layout not yet initialised
    // Can't create config, layout not yet initialised
    let serializable = this.goldenLayoutComponent.getSerializableState();
    if (serializable && serializable.maximisedItemId === MAP_PAGE + '-id')
      this.baseGoldenPanel.glEmitEvent(PAGE_MAXIMIZED_PREFIX + MAP_PAGE, null);
  }

  checkPermissions() {
    if (!this.authorizationService.getLoggedProfileName()) return;

    // Enable as opçoes do menu principal
    this.isHiddenButtonListAlert = !this.authorizationService.userHasPermission(this.permission.LIST_ALERTS);
    this.isHiddenButtonTrackingTeam = !this.authorizationService.userHasPermission(this.permission.VIEW_TRACKING_TEAM);
    this.isHiddenButtonListEvents = !this.authorizationService.userHasPermission(this.permission.LIST_EVENTS);
    this.isHiddenButtonListVerification = !this.authorizationService.userHasPermission(this.permission.LIST_VERIFICATIONS);
    this.isHiddenButtonListPatrols = !this.authorizationService.userHasPermission(this.permission.LIST_PATROLS);
    this.isHiddenButtonListChannels = !this.authorizationService.userHasPermission(this.permission.LIST_CHANELS);
    this.isHiddenButtonAdministration = this.authorizationService.isAdministrationGroupHidden();
    this.isHiddenButtonRegistration = this.authorizationService.isRegistrationGroupHidden();
    this.isHiddenButtonListPatrolTeam = !this.authorizationService.userHasPermission(this.permission.LIST_PATROL_TEAM_ADMINISTRATION);
    this.isHiddenButtonListUsers = !this.authorizationService.userHasPermission(this.permission.LIST_USERS_ADMINISTRATION);
    this.isHiddenButtonListProfiles = !this.authorizationService.userHasPermission(this.permission.LIST_PROFILE_ADMINISTRATION);
    this.isHiddenButtonListVehicles = !this.authorizationService.userHasPermission(this.permission.LIST_VEHICLE_ADMINISTRATION);
    this.isHiddenButtonListCompany = !this.authorizationService.userHasPermission(this.permission.LIST_COMPANY_ADMINISTRATION);
    this.isHiddenButtonListPlacement = !this.authorizationService.userHasPermission(this.permission.LIST_PLACEMENT_ADMINISTRATION);
    this.isHiddenButtonMainEvents = !this.authorizationService.userHasPermission(this.permission.LIST_MAIN_EVENT_ADMINISTRATION);
    this.isHiddenButtonObservedArea = !this.authorizationService.userHasPermission(this.permission.LIST_OBSERVED_AREA_ADMINISTRATION);
    this.isHiddenButtonForms = !this.authorizationService.userHasPermission(this.permission.LIST_FORMS_ADMINISTRATION);
    this.isHiddenButtonPoints = !this.authorizationService.userHasPermission(this.permission.LIST_POINTS_ADMINISTRATION);
    this.isHiddenButtonRegistrationGroup = !this.authorizationService.userHasPermission(this.permission.LIST_REGISTRATION_GROUP);

    this.hasUserProfile = true;
  }

  private subscribeToRegistrationUpdateWebsocket() {
    this.registrationUpdateWebsocket = Websocket.createRegistrationsUpdateWebsocket();
    this.registrationUpdateWebsocketSubscription = this.registrationUpdateWebsocket.stream().subscribe((message: Message) => {
      this.logger.debug("AppComponent.onRegistrationUpdateWebsocket()", message.body);
      const entity = JSON.parse(message.body);
      if (!entity) {
        return;
      }
      if (entity.registrationType) {
        if (entity.registrationType == RegistrationType.PROFILE && entity.id == this.loggedUser.profileId) {
          this.openAlertLogoutDialog();
          return;
        }

        const dataInfo = this.singleDataService.getDataInfo(entity.registrationType); // SingleData envia no registrationType o nome da classe
        if (dataInfo) {
          this.singleDataCacheService.reloadValues(entity.registrationType);
        }
        else {
          this.entityCacheService.reloadObjects(entity.registrationType);
        }

        this.baseGoldenPanel.glEmitEvent(REGISTRATION_UPDATE_EVENT, { registrationType: entity.registrationType, id: entity.id });
      }
    }, (error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Erro no servidor';
      this.logger.error(`AppComponent.onRegistrationUpdateWebsocket: ${errMsg}`);
    });
  }

  /**Método que apresenta o diálogo para logarse com outro usuário
   * Só disponível para administradores
  */
  onControlClickUser(){
    const userType = this.authorizationService.getLoggedUserType();
    if(!userType)
      return;

    if(userType != UserType.ADMINISTRATOR)
      return;

    const openLoginAsDialogSubscription = this.dialog.open(LoginAsComponent, {
      width: '500px',
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe((newUser: UserModel) => {
      if (newUser) {
        let newProfile = this.entityCacheService.getProfileById(newUser.profileId);
        if(newProfile){
          const permissionsMap = new Map(Object.entries(newProfile.permissions));
          if(newProfile.userType == UserType.ADMINISTRATOR || permissionsMap.has(Permission.ACCES_WEB)){
            newUser.token = this.loggedUser.token; // salva para o AuthInterceptor
            this.storageService.setLoginAsUser(newUser); //salva para o createSession
            this.storageService.setLocalUser(newUser); // salva para o AuthInterceptor
            this.storageService.setLocalProfile(newProfile);
            location.reload();
          }else{
            this.toastr.warning("Usuário sem acesso à Web, não é possivel mudar a sessão");
          }
        }else{
          this.toastr.warning("Usuário sem perfil associado, não é possivel mudar a sessão");
          return;
        }
      }
    },error => this.logger.error(error),
      () => openLoginAsDialogSubscription?.unsubscribe());
  }

  protected openAlertLogoutDialog() {
    const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      disableClose: true,
      data:{
        msg: "O seu perfil de usuário foi alterado!\nLogout obrigatório",
        title: 'Logout obrigatório',
        okLabel: 'Logout'
      }
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if(result){
        // Não faz o logout completo
        this.authenticationService.logout();
      }
    },
    error => this.logger.error(error),
    () => openConfirmationSubscription?.unsubscribe());
  }
}
