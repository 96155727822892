<div class="sipd-container">
  <div class="sipd-header">
    <span class="mat-title m-0">{{title}}</span><small matTooltip="Número de Registros Filtrados">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>

    <div class="actions">
      <mat-form-field class="list-filter  header-input" appearance="outline" >
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
    </div>

    <div class="actions">
      <div class="sync-status">
        <mat-icon class="connection-icon" *ngIf="signalsConnectionState.value===2">sync</mat-icon>
        <mat-icon class="connection-icon" *ngIf="signalsConnectionState.value===1">refresh</mat-icon>
        <mat-icon class="connection-icon" *ngIf="signalsConnectionState.value===0">sync_problem</mat-icon>
        <span class="connection-icon small">{{signalsConnectionState.text}}</span>
      </div>

      <button mat-icon-button (click)="onTrackingManyClick(selection.selected)" matTooltip="Mostrar no Mapa"
        [disabled]="this.selection.selected.length == 0" color="primary">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button >
      <button mat-icon-button (click)="onHistoricalTrackingManyClick(selection.selected)" matTooltip="Mostrar Rastro no Mapa"
        [disabled]="this.selection.selected.length == 0" color="primary">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button >
      <button mat-icon-button (click)="onOperationLocationManyClick(selection.selected)" matTooltip="Mostrar Rastro da Última Operação no Mapa"
        [disabled]="this.selection.selected.length == 0" color="primary">
        <mat-icon class="material-icons-outlined">mode_of_travel</mat-icon>
      </button >
      <button mat-icon-button (click)="onRemoveManyClick(selection.selected)" matTooltip="Remover Rastreamento"
        [disabled]="this.selection.selected.length == 0" color="primary">
        <mat-icon class="material-icons-outlined">remove_circle_outline</mat-icon>
      </button >

    </div>
  </div>


  <mat-divider></mat-divider>
  <div class="sipd-content bg-white">

    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!--Source-->
      <ng-container matColumnDef="signal.sourceType">
        <th mat-header-cell class="nowrap" style="max-width: 30px;" *matHeaderCellDef mat-sort-header> Origem </th>
        <td mat-cell class="nowrap" *matCellDef="let element" style="max-width: 30px;">
        <mat-icon mat-list-icon [ngClass]="getSourceClass(element)" (click)="onTrackingClick(element)"
          [matTooltip]="getSourceTooltip(element)">
          <span *ngIf="sourceType.VEHICLE === element?.signal?.sourceType"> directions_car</span>
          <span *ngIf="sourceType.MOBILE_APP === element?.signal?.sourceType"> person</span>
        </mat-icon>
      </td>
      </ng-container>

      <!--Description-->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuário/Veículo </th>
        <td mat-cell class="ellipsis" style="max-width: 100px;" *matCellDef="let element"> {{ getDescription(element) }} </td>
      </ng-container>

      <!-- Company-->
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
        <td mat-cell class="ellipsis" style="max-width: 100px;" *matCellDef="let element" [matTooltip]="element?.company?.name"> {{ element?.company?.name }} </td>
      </ng-container>

      <!--Patrol Team-->
      <ng-container matColumnDef="patrolTeam.name">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Equipe </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ element.patrolTeam ? element.patrolTeam?.name: '' }} </td>
      </ng-container>

      <!--Operation-->
      <ng-container matColumnDef="operation.identifier">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Última Operação  </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ !element.operation ? '' : element.operation?.identifier }} </td>
      </ng-container>

      <!--Operation type-->
      <ng-container matColumnDef="operation.type">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo  </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ !element.operation ? '' : operationTypeDescription[element.operation?.type] }} </td>
      </ng-container>

      <!---Status -->
      <ng-container matColumnDef="operation.status">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Status </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ !element.operation ? '' :  operationStatusDescription[element.operation?.status]  }} </td>
      </ng-container>

      <!---Data  -->
      <ng-container matColumnDef="operation.startDate">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora de Início </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ element?.operation?.startDate | date:'dd/MM/yyyy HH:mm' }} </td>
      </ng-container>

      <!---Last Update -->
      <ng-container matColumnDef="signal.receivedTimestamp">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Última Atualização (GPS)</th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{  getLastUpdateTime(element)  }} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
      (contextmenu)="openContextMenu($event, element)"
      [ngClass]="{ 'highlighted': selection.isSelected(row) }">
      </tr>
    </table>

    <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>

    <mat-menu #contextMenu="matMenu">
      <button  mat-menu-item color="primary"  (click)="onTrackingClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
        <span>Mostrar no Mapa</span>
      </button>
      <button  mat-menu-item color="primary"  (click)="onHistoricalTrackingClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">my_location</mat-icon><span>Mostrar Rastro no Mapa</span>
      </button>
      <button  mat-menu-item color="primary"  (click)="onOperationLocationClick(this.contextMenuSelectedItem)"
        [disabled]="!hasOperationLocation(contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">mode_of_travel</mat-icon><span>Mostrar Rastro da Última Operação no Mapa</span>
      </button>
      <button  mat-menu-item color="primary"  (click)="onOperationClick(this.contextMenuSelectedItem)"
        [disabled]="!hasOperation(contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon><span>Abrir Operação</span>
      </button>
      <button  mat-menu-item color="primary"  (click)="onRemoveItemClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">remove_circle_outline</mat-icon><span>Remover Rastreamento</span>
      </button>
    </mat-menu>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
