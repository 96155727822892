import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { UserService } from 'src/app/service/model/user.service';
import { ToastrService } from 'ngx-toastr';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss']
})
export class LoginAsComponent implements OnInit {

  login:string;

  constructor(public dialogRef: MatDialogRef<LoginAsComponent>,
              public userService:      UserService,
              protected toastr:             ToastrService,
              public entityCacheService:  EntityCacheService,
              protected logger:             NGXLogger) {}

  ngOnInit(): void {
  }

  loginAs(){
    if(!this.login || !this.login.trim())
      return;
    
    const user = this.entityCacheService.getUsers().find((user) => { return user.login === this.login.trim()});
    if(!user){
      this.toastr.error("Usuário não encontrado");
      return;
    }

    this.dialogRef.close(user);
  }
}
