import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as JWT from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { StorageService } from 'src/app/service/storage-service';
import { NGXLogger } from 'ngx-logger';
import { API_VERSION_ENDPOINT, NO_PROFILE_ID } from '../../../common/constants';
import { CookieService } from 'ngx-cookie-service';
import { ProfileService } from '../../../service/model/profile.service';
import { ProfileModel } from '../../../model/profile.model';
import { UserService } from '../../../service/model/user.service';
import { UserModel } from 'src/app/model/user.model';
import { Permission } from 'src/app/model/enums.enum';
import { UserType } from '../../../model/enums.enum';
import { PermissionOptions } from '../../../model/field.model';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { first } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authenticatedSubject: Subject<boolean> = new Subject();
  public readonly authenticated$: Observable<boolean> = this.authenticatedSubject.asObservable();

  private inconsistentData: Subject<boolean> = new Subject();
  public readonly idata$: Observable<boolean> = this.inconsistentData.asObservable();

  constructor(private logger:           NGXLogger,
              private http:             HttpClient,
              public storage:           StorageService,
              private cookieService:    CookieService,
              public entityCacheService: EntityCacheService,
              protected authorizationService: AuthorizationService,
              private msalService:      MsalService,
              private toastr:           ToastrService,
              private router:           Router,
              private _injector:        Injector,
              private profileService:   ProfileService,
              private userService: UserService) {
  }

  protected logoutErase() {
    this.cookieService.deleteAll();

    this.storage.setLocalUser(null);
    this.storage.setLocalProfile(null);

    this.storage.setPopoutData(null);
    this.storage.setSpecificPlacementIds(null);
  }

  logout() {
    if (this.storage.getLocalUser()) {
      this.logoutErase();
      this.router.navigated = false;
      localStorage.clear();
      location.reload();
      this.authenticatedSubject.next(false);
    }
  }

  injectUpdate() {
    if (!this.toastr) {
      this.toastr = this._injector.get(ToastrService);
    }
    if (!this.logger) {
      this.logger = this._injector.get(NGXLogger);
    }
  }

  reloadToken(){
    this.injectUpdate();

    const account = this.msalService.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: account,
    };

    let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;

    const _this = this;

    msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      _this.updateUserToken(accessToken);
    })
    .catch(function (error) {
      //Acquire token silent failure
      if (_this.logger) _this.logger.error("AuthenticationService.reloadToken()", error); else console.error("AuthenticationService.reloadToken()", error);
      if (_this.toastr) _this.toastr.error("AuthenticationService.reloadToken() - Erro ao renovar o token de acesso. Recomendamos fazer logout ou recarregar a página.");
    });
  }

  private updateUserToken(token){
    const loggedUser = this.storage.getLocalUser();
    loggedUser.token = token;
    this.storage.setLocalUser(loggedUser);
  }

  private onUserProfileLoad(user: UserModel, profile: ProfileModel){
    if(profile.userType===UserType.ADMINISTRATOR){
      // Faz com que o administrador tenha todas as permissões
      profile.permissions = {};
      const keyPermission = Object.keys(Permission);
      keyPermission.forEach((key,index)=>{
        let permissionOption = new PermissionOptions();
        profile.permissions[key] = permissionOption;
      });
    }

    let perm = profile.permissions[Permission.ACCES_WEB];
    if(!perm){
      localStorage.setItem("Login Error", "Perfil sem acesso a Web: " + profile.name);
      this.inconsistentData.next(false);
      return;
    }

    // Se não tem a permissão de acessar todas as lotações
    perm = profile.permissions[Permission.ACCESS_TO_ALL_PLACEMENT];
    if(!perm){
      // Se não tem a permissão de acessar todas as lotações
      let userPlacements : string[] = [];
      userPlacements = userPlacements.concat(user?.placements.map(( { id }) => id));

      if(user.originPlacement && userPlacements.length > 0 && !userPlacements.includes(user?.originPlacement.id) ){
        userPlacements.push(user.originPlacement.id);
      }

      if(userPlacements.length == 0) {
        localStorage.setItem("Login Error", "Perfil com lotações de acesso não definidas: " + profile.name);
        this.inconsistentData.next(true);
        return;
      }

      this.storage.setSpecificPlacementIds(userPlacements);
    }

    this.storage.setLocalProfile(profile);
    this.authorizationService.setLoggedProfileUser(profile);

    this.authenticatedSubject.next(true);
  }

   loadProfile(user: UserModel){
    if(user.profileId === NO_PROFILE_ID) {
      localStorage.setItem("Login Error", "Usuário sem perfil definido!");
      this.inconsistentData.next(true);
      return;
    }

    this.profileService.getProfileById(user.profileId).pipe(first()).subscribe((profile:ProfileModel)=>{
      if(profile){
        this.onUserProfileLoad(user, profile);
      }
      else {
        localStorage.setItem("Login Error", "Perfil não encontrado: " + user.profileId);
        this.inconsistentData.next(true);
      }
    },
    error => {
      localStorage.setItem("Login Error", "Falha ao carregar o perfil: " + user.profileId);
      this.inconsistentData.next(true);
    });
  }

  /** Retorna a lotação de origem do usuário logado*/
  getUserPlacementId() {
    const loggedUser =  this.storage.getLocalUser()
    if (!loggedUser.originPlacement && loggedUser.placements && loggedUser.placements.length == 1) {
      return loggedUser.placements[0].id;
    }

    return loggedUser.originPlacement ? loggedUser.originPlacement.id : null;
  }

  private loadAllProfiles(){
    this.entityCacheService.loadProfiles(null);
  }

  private loadUserData(loggedUser : UserModel): Promise<any>{
    // Keycloak => email , Microsoft Azure => unique_name
    const email = loggedUser.email ? loggedUser.email: loggedUser.unique_name;
    if(!email){
      localStorage.setItem("Login Error", "Usuário sem e-mail no SSO!");
      return new Promise<any>((resolve, reject) => {
        resolve(false);
      });
    }

    return new Promise<any>((resolve, reject)=>{
      this.userService.getUserFromEmail(email.toLocaleLowerCase()).pipe(first()).subscribe((user:UserModel)=>{
        if(user){ // existe usuário
          const token = loggedUser.token;
          loggedUser = user;
          loggedUser.token = token;

          // Chama novamente depois de carregar os dados do usuário (ver createSession)
          this.storage.setLocalUser(loggedUser);

          this.loadProfile(loggedUser); // Aqui não aguarda carregar o perfil, mas os dados inconsistentes irão forçar um logout depois de uma tela de confirmação
          resolve(true);
        }else{
          localStorage.setItem("Login Error", "Usuário não encontrado! email=" + email.toLocaleLowerCase());
          resolve(false);
        }
      }, error => {
        localStorage.setItem("Login Error", "Falha ao carregar dados do usuário! email=" + email.toLocaleLowerCase());
        resolve(false);
      });
    });
  }

  /**
   * Cria uma sessão para o usuário logado a partir do seu token JWT vindo da requisição
   * @param token Token JWT criado na autenticação no CCPD-AUTH
   */
  async createSession(token: string): Promise<boolean>{
    let user: UserModel;

    const loginAsUser = this.storage.getLoginAsUser();
    if(loginAsUser){
      user = loginAsUser;
    }
    else {
      user = JWT(token.substring(7)); //Remove "Bearer " from string and parse
    }

    user.token = token;

    // Isso se repete em loadUserData
    // Armazena mesmo que incompleto, logo depois vamos carregar os dados completos do backend
    // É necessario para que o AuthInterceptor pegue o token ao procurar os dados usuário no Bd
    this.storage.setLocalUser(user);

    let existUser: boolean;
    if (loginAsUser) {
      const profile = this.storage.getLocalProfile();
      setTimeout(() => {
        this.onUserProfileLoad(user, profile); // é preciso esperar até iniciar os componentes e a subscripción que valida o acesso para as telas abertas inicialmente (Alertas, Rastreamento e Mensagens)
      },700);
      existUser = true;
    }
    else {
      existUser = await this.loadUserData(user);
    }

    this.loadAllProfiles();

     this.logger.debug(`Sessão = { Subject='${user.login}', Token='${token}' }`);
     return existUser;
  }

  /**
   * Obtem a versão do projeto auth
   */
  public getServiceVersion(): Observable<any> {
    return this.http.get(`${environment.settings.auth_address}/auth/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' });
  }
}
