import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormService } from 'src/app/service/model/form.service';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFilterModel } from './form.filter.model';
import { SORT_NAME_ASC } from 'src/app/common/constants';
import { FormModel } from 'src/app/model/form.model';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-form-filter-dialog',
  templateUrl: './form-filter-dialog.component.html',
  styleUrls: ['./form-filter-dialog.component.scss']
})
export class FormFilterDialogComponent implements OnInit, OnDestroy {

  loadingListService = new LoadingListService();

  constructor( private formService:                                 FormService,
               public singleDataCacheService:                       SingleDataCacheService,
               protected logger:                                    NGXLogger,
               public dialogRef:                                    MatDialogRef<FormFilterDialogComponent>,
               public filterModel:                                  FormFilterModel,
               @Inject(MAT_DIALOG_DATA) public inMemoryFilterModel: FormFilterModel) {
  }

  ngOnInit() {
    this.loadFormOptionsData();
    
    this.filterModel = new FormFilterModel();
    this.updateFilterModel(this.inMemoryFilterModel, this.filterModel);
  }

  ngOnDestroy() {
    this.loadingListService.destroy();
   }

  onFilter(){
    this.updateFilterModel(this.filterModel, this.inMemoryFilterModel);

    this.loadingListService.loadingOn();
    this.formService.loadFilteredListFromRestApi(null, null, SORT_NAME_ASC,  this.inMemoryFilterModel).pipe(first()).subscribe( (forms : FormModel[]) => {
      this.dialogRef.close(forms);
    },
    error => this.logger.error(error),
    () => {
      this.loadingListService.loadingOff();
    });
  }

  clearFilter(){
    this.filterModel.name    = undefined;
    this.filterModel.placement = undefined;
    this.filterModel.inspectionType = undefined;
  }

  private loadFormOptionsData() {
    const metadatas: string[] = [
      "InspectionType"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  /**
   * Atualiza o filtro em memória
   */
   updateFilterModel(sourceFilter: FormFilterModel, targetFilter: FormFilterModel){
    for(const key in sourceFilter){
      targetFilter[key] = sourceFilter[key];
    }
  }
}
