import LanguageUtils from '../service/util/language-utils';
import {EntityModel} from './entity.model';

export class EventMainActionModel extends EntityModel {
  sourceType:  string;
  action:  string;
  comment: string;
  done:    boolean;
  
  static addLanguageMap(){
    LanguageUtils.languageMap['sourceType'] = 'Origem';
    LanguageUtils.languageMap['action'] = 'Ação Principal';
  }
}
