import { Component, OnInit, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { RouteGeographicalService } from '../../../service/model/route-geographical-service';
import { InspectionModel } from '../../../model/inspection.model';

@Component({
  selector: 'app-import-points-kml-dialog',
  templateUrl: './import-points-kml-dialog.component.html',
  styleUrls: ['./import-points-kml-dialog.component.scss']
})
export class ImportPointsKmlDialogComponent implements OnInit {

  filename: string;
  kmlFile: File;

  constructor(private logger:                     NGXLogger,
    public dialogRef:                             MatDialogRef<ImportPointsKmlDialogComponent>,
    public toastr:                                ToastrService,
    private routeGeographicalObjectService:       RouteGeographicalService,
    @Inject(MAT_DIALOG_DATA) public points:       InspectionModel[]){}

  ngOnInit(): void {
  }

  selectFile(file: File) {
    if (file) {  // Usuário selecionou um arquivo
      this.kmlFile = file;
      this.filename = file.name;
    }  
    else {  // Usuário pressionou Remover
      this.kmlFile = undefined;
      this.filename = undefined;
    }
  }

  select() {
    this.logger.debug('ImportPointsKmlDialogComponent.select()');

    this.routeGeographicalObjectService.importPointsKml(this.kmlFile).then((geoPoints) => {
      geoPoints.on('ready', () => {
        const layers = geoPoints._layers;

        const old_length = this.points.length;

        for (const id in layers) {
          let layer = layers[id];
          if (layer.feature.geometry.type !== 'Point') {
            continue;
          }
          
          let inspection = new InspectionModel();
          inspection.activities = [];

          inspection.location.latitude = layer.feature.geometry.coordinates[1];   // Note que é invertido em geometry.coordinates (longitude, latitude)
          inspection.location.longitude = layer.feature.geometry.coordinates[0];
          inspection.type = layer.feature.properties.type;
          inspection.name = layer.feature.properties.name;
          inspection.band = inspection.name;

          for (let i=0; i<layer.feature.properties.activities.length; i++) {
            inspection.activities[i] = layer.feature.properties.activities[i];
          }

          this.points.push(inspection);
        }

        if (old_length == this.points.length) {
          const title = 'KML Inválido!';
          const message = 'O arquivo deve ter ao menos 1 ponto.';
          this.toastr.error(message, title);
        }
        else {
          this.dialogRef.close(true);
        }
      });
      geoPoints.on('error', () => {
        const title = 'KML Inválido!';
        const message = 'Erro no arquivo KML de Pontos';
        this.toastr.error(message, title);
      });
    });
  }
}
