<form class="patrol-teams-form edit-form h-100">
  <div class="sipd-container">
    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVA]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button 
          matTooltip="Habilitar Edição"
          [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar"
          (click)="onCopyClick()"
          [disabled]="!id"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PATROL_TEAM)'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()"
          matTooltip="Remover"
          [hidden]='!authorizationService.userHasPermission(permission.DELETE_PATROL_TEAM)'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Mostrar Rastro no Mapa"
          (click)="onHistoricalTrackingClick()">
          <mat-icon class="material-icons-outlined">my_location</mat-icon>
        </button>
        <button mat-icon-button  [disabled]="!canRevert()" (click)="onRevertClick()" 
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PATROL_TEAM)'
          matTooltip="Desfazer Modificações">
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button  (click)="onSaveClick($event)" [disabled]="!canSave()" 
            [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PATROL_TEAM)'
            matTooltip="Salvar">
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Nome</mat-label>
          <input [readonly]="readOnly" cdkFocusInitial name="name" matInput placeholder="Nome" [(ngModel)]="view['name']" required>
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>

        <single-autocomplete [data]="entityCacheService.getCompanies()" name="name" placeholder="Empresa" [(valueData)]="view['company']"
          [required]="true" [disabled]="readOnly" (selectionChangedEvent)="updateFormData(false)" fxFlex>
        </single-autocomplete>

        <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
          <mat-label>Lotação</mat-label>
          <input readonly cdkFocusInitial matInput [value]="view['company']?.placement?.name">
        </mat-form-field>

        <single-autocomplete [data]="serviceTypeDescription | keyvalue" name="value" placeholder="Tipo de Serviço" [(valueData)]="view['serviceType']"
          [required]="true" [disabled]="!view['company'] || readOnly"  optionValue="key" fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="shiftDescription | keyvalue" name="value" placeholder="Turno" [(valueData)]="view['shift']"
          [disabled]="readOnly" optionValue="key" fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="professionals" name="name" placeholder="Profissional 1" [(valueData)]="view['professional1']"
          [disabled]="!view['company'] || readOnly" fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="professionals" name="name" placeholder="Profissional 2" [(valueData)]="view['professional2']"
          [disabled]="!view['company'] || readOnly" fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="professionals" name="name" [placeholder]="'Profissional 3'" [(valueData)]="view['professional3']"
          [disabled]="!view['company'] || readOnly" fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="vehicles" name="plate" placeholder="Placa" [(valueData)]="view['vehicle']"
          [disabled]="!view['company'] || readOnly" [required]=true fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="coordinators" name="name" placeholder="Coordenador" [(valueData)]="view['patrolCoordinator']"
          [disabled]="!view['company'] || readOnly" fxFlex>
        </single-autocomplete>

        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
      </div>

    </div>
    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
  </div>

</form>
