import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { EntityService } from './entity.service';
import { NGXLogger } from 'ngx-logger';
import { EntityModel } from '../../model/entity.model';
import { FilterModel } from '../../general/filter-component/filter.model';
import { Observable } from 'rxjs';
import { SingleDataModel } from '../../model/singleData.model';
import { UserModel } from '../../model/user.model';
import { SORT_NAME_ASC } from 'src/app/common/constants';

@Injectable({
  providedIn: 'root'
})
export class SingleDataService extends EntityService {

  protected propsMap;

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) { 
    super(logger, httpClient, `${environment.settings.registrations_address}/singleData`);

    const singleData = require('../../../assets/singleData.json');
    let jsonData = JSON.stringify(singleData);
    const propsObject = JSON.parse(jsonData);
    this.propsMap = new Map(Object.entries(propsObject));
  }

  getDataInfo(metadata: string) {
    return this.propsMap.get(metadata);
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>( pageIndex?: number, pageSize?: number, sort?: string, filter?: FilterModel, extraParams?: Map<string, string> ): Observable<T> {

    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value); 
      });
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  public loadMultipleListFromRestApi<T extends EntityModel[]>(params: HttpParams): Observable<EntityModel[]> {
    params = params.set('sort', SORT_NAME_ASC);
    return this.http.get<T>(this.apiUrl + '/multiple', { params : params });
  }

  public getRecord<T extends EntityModel>(id: string, extraParams?: Map<string, string>): Observable<T> {

    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value);
      });
    }

    return this.http.get<T>(this.apiUrl + '/id', { params : params });
  }

  public createRecord<T extends EntityModel> (record: SingleDataModel, extraParams?: Map<string, string>): Observable<T> {
    this.logger.debug('SingleDataService.createRecord()');
    this.logger.debug('Entidade a ser salva: ', record);
 
    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value);
      });
    }

    return this.http.post<T>(this.apiUrl, record, { params : params });
  }

  public insertMany (record: SingleDataModel[], extraParams?: Map<string, string>): Observable<Object> {
    this.logger.debug('SingleDataService.createRecord()');
 
    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value);
      });
    }

    return this.http.post(this.apiUrl + '/many', record, { params : params });
  }

  public importKml(metadata: string, replace: boolean, kmlFile: File) {
    this.logger.debug('PointService.importKml()');
    const formData: FormData = new FormData();
    if (kmlFile instanceof File) {
      formData.append("fileKml", kmlFile);
    }

    formData.append("replace", JSON.stringify(replace));

    formData.append("metadata", metadata);

    return super.postAsFormData(this.apiUrl + '/import', formData);
  }

  public restore<T extends EntityModel> (user: UserModel, extraParams?: Map<string, string>): Observable<T> {
    this.logger.debug('SingleDataService.restore()');
    this.logger.debug('Usuário responsável: ', user);
 
    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value);
      });
    }

    return this.http.post<T>(this.apiUrl + '/restore', user, { params : params });
  }

  public editRecord<T extends EntityModel> (record: EntityModel, extraParams?: Map<string, string>): Observable<T> {
    this.logger.debug('SingleDataService.editRecord()');
 
    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value);
      });
    }

    return this.http.put<T>(this.apiUrl + '/id', record, { params : params });
  }

  public delete(record: SingleDataModel, extraParams?: Map<string, string>) {
    this.logger.debug('SingleDataService.delete()');
  
    let params: HttpParams = new HttpParams();

    if (extraParams) {
      extraParams.forEach( (value, key) => {
        params = params.append(key, value);
      });
    }

    return this.http.delete(this.apiUrl + '/id', { params : params });
  }

  public getGasDucts(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=GasDuct`);
  }

  public getOilDucts(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=OilDuct`);
  }

  public getSimf(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=Simf`);
  }

  public getBands(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=Band`);
  }

  public getValves(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=Valve`);
  }

  public getPipeStretchs(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=PipeStretch`);
  }

  public getCommunicationChannels(){
    return this.http.get(`${environment.settings.registrations_address}/singleData?metadata=CommunicationChannel`);
  }

}
