import { Component, Input, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Autocomplete } from '../autocomplete/autocomplete.component';

/**
 * @title Multi-select autocomplete
 */
@Component({
  selector: 'single-autocomplete',
  templateUrl: 'single-autocomplete.component.html',
  styleUrls: ['single-autocomplete.component.css']
})
export class SingleAutocomplete extends Autocomplete implements OnInit {

  // valueData é dado que vai ser atualizado no model (aqui um único objeto)
  _valueData: Object;
  @Input()
  set valueData(data: Object) {
    this._valueData = data;
    this.dataControl.setValue(this.getDataControlObjectValue(this._valueData, this._optionValue));
  }

  get valueData() {
    return this._valueData;
  }

  // Define um item que não está cadastrado
  _extraData: Object;
  @Input()
  set extraData(data: Object) {
    if (this._data.length == 0) {
      return;
    }
    this._extraData = data;

    if (this._extraData) {
      if (!this._data.includes(this.extraData)) {
        this._data = this._data.slice(); // cria uma cópia para evitar modificar dados externos
        this._data.push(this.extraData);
      }
    }
  }

  get extraData() {
    return this._extraData;
  }

  displayFn = (value: Object| string): string | undefined => {
    let displayValue: string;
    if (value) {
      displayValue = this.getData(value);
    }
    else {
      return "";
    }
    return displayValue;
  }

  optionClicked(event: Event, data: Object) {
    this.autocomplete.closePanel();
    this.toggleSelection(data);
  }

  toggleSelection(data: Object) {
    let valueData = this.getDataControlPropertyValue(data, this._optionValue);
    this.dataControl.setValue(data);
    this.valueDataChange.emit(valueData);
    this.controlColor = "primary";
    this.selectionChangedEvent.emit(valueData);
    this.someInput.nativeElement.focus();
  }

  onSearchChange(event) { 
    let value: string = this.dataControl.value;
    if (value==null) {
      value = '';
    }
    let nvalue:string = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    let match = this._data.find(val => {
      let name: String = this._name ? val[this._name] : val
      let sval: String = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      if (sval ==  nvalue) {
        return true;
      }
      return false;
    });
    if (value && match != undefined) {
      this.dataControl.setValue(match);
      this.controlColor = "primary";
      this.valueDataChange.emit(this.getDataControlPropertyValue(match, this._optionValue));
    }
    else {
      this.someInput.nativeElement.value = event.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      this.dataControl.setErrors({'incorrect': true});
      this.controlColor = "warn";
    }
  }

  focusInFunction() {
    this.dataControl.setValue('');
    this.autocomplete.openPanel();
  }

  onOpenedEvent(event) {
  }

  onClosedEvent(event) {
    this.dataControl.setValue(this.getDataControlObjectValue(this.valueData, this._optionValue));
  }

  onSelectionEvent(event: MatAutocompleteSelectedEvent) {
    let value = event.option.value ? event.option.value : null;
    let valueData = this.getDataControlPropertyValue(value, this._optionValue);
    this.dataControl.setValue(value);
    this.valueDataChange.emit(valueData);
    this.controlColor = "primary";
    this.selectionChangedEvent.emit(valueData);
  }
}