/**
 * Utilitário para manipulação de campos diversos
 */
 export default class FieldUtils {

  static padLeadingZeros(num: number, size: number): string {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  static padEndZeros(num: number, size: number): string {
    var s = num+"";
    while (s.length < size) s = s + "0";
    return s;
  }

  static padEndZerosString(string: string, size: number): string {
    var s = string+"";
    while (s.length < size) s = s + "0";
    return s;
  }

  // Converte KM no formato 000000 para 000+000
  static kmIntToString(km: number) {
    if (km == null || km == undefined) return ''; // Cuidado !km=true se km=0
    let km_str: string = FieldUtils.padLeadingZeros(km, 6);
    return km_str.slice(0, 3) + '+' + km_str.slice(3, 6);
  }

  // Converte KM no formato 000+000 para 000000
  static kmStringToInt(km: string) {
    if (!km) return 0;
    return +km.slice(0, 3) * 1000 + +km.slice(4, 7);
  }

  static coordToString(coord): string {
    if (typeof coord == 'number')
      return coord.toFixed(6);
    else {
      let c: number = +coord;
      return c.toFixed(6);
    }
  }

  // Converte KM no formato ??0.??0 para 000+000
  static kmSimfToString(kmSimf: string){
    const km: string [] = kmSimf.split('.');
    if(km.length == 1) return FieldUtils.padLeadingZeros(+km[0], 3) + '+000';
    //Ao utilizar a função padEndZeros com um número iniciado com zero o parametro da função que era number 
    //eliminada os zeros a esquerda, no caso replicada a função agora recebendo uma string  #FSWSQD-1330
    const kmEdit = FieldUtils.padLeadingZeros(+km[0], 3) + '+' + FieldUtils.padEndZerosString(km[1], 3);
    return kmEdit;
  }

  static kmStringToSimf(kmSimf: string){
    const km: string [] = kmSimf.split('+');
    const km1: number = +km[0];
    const km2: number = +km[1];
    return km1 + "." + km2;
  }

  static parseLatLong(value: string){
    const latlong: string [] = value.split(',');
    if(latlong.length !== 2) return null;
    const latitude: number = +latlong[0].trim();
    const longitude: number = +latlong[1].trim();
    value = FieldUtils.coordToString(latitude) + ',' + FieldUtils.coordToString(longitude);
    return value;
  }

  static pasteLatLong(event: ClipboardEvent, oldValue: string): string {
    let newValue: string = event.clipboardData.getData('text');
    // Se novo valor está vazio
    if (!newValue || newValue.length == 0) {
      event.preventDefault();
      return newValue;
    }

    // Se antigo valor está vazio, apenas faz o parse do novo valor
    if (!oldValue || oldValue.length == 0) {
      event.preventDefault();
      return FieldUtils.parseLatLong(newValue);
    }
    else {
      var el = $(event.target);
      var cursorPosStart = el.prop('selectionStart');
      var cursorPosEnd = el.prop('selectionEnd');
      // Só faz o paste se o valor antigo está completamente selecionado
      if (cursorPosStart == 0 && cursorPosEnd == oldValue.length){
        event.preventDefault();
        return FieldUtils.parseLatLong(newValue);
      }

      return null;
    }
  }
}
