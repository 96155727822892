import { VehicleService } from './../../../../service/model/vehicle.service';
import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { VehicleModel } from 'src/app/model/vehicle.model';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../../../../service/authorization/authorization.service';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { ESP, MAP_PAGE, SORT_PLATE_ASC } from 'src/app/common/constants';
import { ToastrService } from 'ngx-toastr';
import { RegistrationListComponent } from '../../registration-list-component';
import { RegistrationType } from 'src/app/model/enums.enum';
import { SAVE_DATA_PREFIX } from '../../../../common/constants';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['../../../../app.component.scss', '../../../list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleListComponent extends RegistrationListComponent implements OnInit {

  constructor(logger:                       NGXLogger,
              vehicleService:               VehicleService,
              public authorizationService:  AuthorizationService,
              protected storageService:     StorageService,
              protected changeDetector:     ChangeDetectorRef,
              protected toastr:             ToastrService,
              dialog:                       MatDialog,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, vehicleService, dialog, 'vehicles', environment.VEHICLES_GROUP_LABEL, environment.VEHICLES_TITLE_LABEL,
      environment.VEHICLES_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('VehicleListComponent.constructor()');
    this.subscribeToSaveCompaniesData();
    this.registrationType = RegistrationType.VEHICLE;
    this.sortOptions = SORT_PLATE_ASC;
  }

  ngOnInit() {
    super.ngOnInit();

    this.logger.debug('VehicleListComponent.ngOnInit()');
    this.loadRecordsFromServer();
    this.displayedColumns = ['select',
                             'plate',
                             'model',
                             'company.name',
                             'observations'];
  }

  protected getDefaultSortColumn(): string {
    return "plate";
  }

  public subscribeToSaveCompaniesData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'companies-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

/**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   */
  protected getStringEntityForFilter(vehicle: VehicleModel): string {
    const plate = super.lowerAndTrimText(vehicle.plate);
    const model = super.lowerAndTrimText(vehicle.model);
    const company = (vehicle.company) ? super.lowerAndTrimText(vehicle.company.name) : '';
    const observations = super.lowerAndTrimText(vehicle.observations);
    return plate + ESP + model + ESP + company + ESP + observations;
  }

  loadRecordsFromServer(): void {
    this.logger.debug('VehicleListComponent.loadRecordsFromServer()');
    /**Verifica se o perfil do usuário logado  possui a restrição "Acesso somente a dados de Lotações específicas" da seção Web.
     * Em caso positivo, somente as verificações que pertencem à mesma Lotação do usuário */

    this.filterModel.placements = this.storageService.getSpecificPlacementIds();

    super.loadRecordsFromServer();
  }

  onHistoricalTrackingClick(vehicle: VehicleModel ) {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingVehicle: vehicle});
  }

  onHistoricalTrackingManyClick(){
    let historicalTrackingVehicleList = [];
    this.selection.selected.forEach( (vehicle: VehicleModel) => {
      historicalTrackingVehicleList.push(vehicle);
    });

    // Note que depois de abrir o mapa pode descobrir que não tem sinais
    this.glOpenContainer(MAP_PAGE, {historicalTrackingVehicleList: historicalTrackingVehicleList});
  }
}
