import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { Permission, RegistrationType } from 'src/app/model/enums.enum';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { ProfileService } from 'src/app/service/model/profile.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { UserType } from '../../../../model/enums.enum';
import { RegistrationListComponent } from '../../registration-list-component';
import { ToastrService } from 'ngx-toastr';
import { ProfileModel } from 'src/app/model/profile.model';
import { ESP } from 'src/app/common/constants';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileListComponent extends RegistrationListComponent implements OnInit, OnDestroy {

  permission = Permission;
  constructor(logger:                             NGXLogger,
              protected profileService:           ProfileService,
              protected dialog:                   MatDialog,
              public authorizationService:     AuthorizationService,
              protected changeDetector:                  ChangeDetectorRef,
              protected storageService:           StorageService,
              protected toastr: ToastrService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container
              ) {
    super(logger, authorizationService, profileService, dialog, 'profiles', environment.PROFILES_GROUP_LABEL, environment.PROFILES_TITLE_LABEL,
    environment.PROFILES_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    this.registrationType = RegistrationType.PROFILE;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.logger.debug('ProfileListComponent.ngOnInit()');
    this.loadRecordsFromServer();
    this.displayedColumns = ['select',
                             'name'];
  }
  protected getDefaultSortColumn(): string {
    return "name";
  }

  /** Deleta o perfil administrador da lista */
  postLoadProcess(){
    // Remove o administrador da lista
    const index = this.model.findIndex((admProfile) => { return admProfile['userType'] === UserType.ADMINISTRATOR });
    this.model.splice(index, 1);
  }

  protected getStringEntityForFilter(profile: ProfileModel): string {
    const name = this.lowerAndTrimText(profile.name);
    return name;
  } 
}
