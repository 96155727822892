// ANTIGO: No momento não usamos dessa forma
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ATUAL: No momento definimos variáveis no deploy (Ver pasta src\assets\environments\)
// O Dockerfile roda um shell script (ccpd-env.sh) que copia o environment.json.in para environment.json usando as váriaveis do deploy para preencher valores
// Como isso não acontece no localhost, o environment.json serve como valor default para ambiente local


export const environment = {
  // Essas configurações são substituídas pelo "environment.json.in" (obrigatórias)
  production: true, // controla o production mode do angular (Local=false  Servidor=true)
  development: false, // Indica se estamos em ambiente de desenvolvimento (Tecgraf) ou (Transpetro) - controla o acesso ao OpenId
  hmg: false, // Indica se estamos em ambiente de homologação

  // No momento não estão definidas no "environment.json.in", são opcionais.
  // Algumas definimos no environment.json default para uso local
  allUsersCanUseApp: false,
  showPopupLayerTables: false, // Mostra somente se rondando o Web local
  useServerPagination: false,
  defaultPageSize: 50,
  profileThresHold: 10, // allow filtering log to only those that took more than the threshold (in ms) on ProfileClassToConsole Decorator
  profileClassToConsoleEnabled: false, // enables ProfileClassToConsole Decorator

  // Essas configurações são substituídas pelo "environment.json" (obrigatória)
  settings: {
    authority_address           : '',
    alerts_address              : '',
    auth_address                : '',
    event_verifications_address : '',
    events_address              : '',
    markers_address             : '',
    patrols_address             : '',
    registrations_address       : '',
    route_deviation_address     : '',
    signals_address             : '',
    simf_address                : '',
    tracking_address            : '',
    web_address                 : '',
    websocket_address           : '',
    websocket_http_address      : ''
  },

  // TODO lbarros estas não são variáveis de ambiente e sim internacionalização - ver https://angular.io/guide/i18n
  // *_MODEL_LABEL são usando em títulos de diálogos de confirmação
  // *_TITLE_LABEL são usados em títulos de páginas
  // *_GROUP_LABEL são usados em títulos de acesso
  LANDING_PAGE                  : 'central',

  CENTRAL_TITLE_LABEL           : 'Central de Alertas',
  CENTRAL_GROUP_LABEL           : 'Alertas',

  TRACKING_MODEL_LABEL          : 'Rastreamento',
  TRACKING_TITLE_LABEL          : 'Rastreamento de Equipes e Veículos',
  TRACKING_GROUP_LABEL          : 'Rastreamento',

  EVENT_MODEL_LABEL             : 'Evento',
  EVENT_TITLE_LABEL             : 'Registro de Eventos e Ocorrências',
  EVENT_GROUP_LABEL             : 'Eventos',

  VERIFICATION_MODEL_LABEL      : 'Verificação',
  VERIFICATION_TITLE_LABEL      : 'Verificações de Eventos',
  VERIFICATION_GROUP_LABEL      : 'Verificações',

  PATROL_MODEL_LABEL            : 'Ronda',
  PATROL_TITLE_LABEL            : 'Planejamento de Rondas',
  PATROL_GROUP_LABEL            : 'Rondas',

  CHANNEL_TITLE_LABEL           : 'Canais de Mensagens',
  CHANNEL_GROUP_LABEL           : 'Mensagens',

  ADMINISTRATION_GROUP_LABEL    : 'Administração',

  COMPANY_MODEL_LABEL           : 'Empresa',
  COMPANY_TITLE_LABEL           : 'Administração de Empresas',
  COMPANY_GROUP_LABEL           : 'Empresas',

  PATROL_TEAM_MODEL_LABEL       : 'Equipe',
  PATROL_TEAM_TITLE_LABEL       : 'Administração de Equipes',
  PATROL_TEAM_GROUP_LABEL       : 'Equipes',

  PLACEMENT_MODEL_LABEL         : 'Lotação',
  PLACEMENT_TITLE_LABEL         : 'Administração de Lotações',
  PLACEMENT_GROUP_LABEL         : 'Lotações',

  USERS_MODEL_LABEL             : 'Usuário',
  USERS_TITLE_LABEL             : 'Administração de Usuários',
  USERS_GROUP_LABEL             : 'Usuários',

  PROFILES_MODEL_LABEL           : 'Perfil',
  PROFILES_TITLE_LABEL           : 'Administração de Perfis',
  PROFILES_GROUP_LABEL           : 'Perfis',

  VEHICLES_MODEL_LABEL          : 'Veículo',
  VEHICLES_TITLE_LABEL          : 'Administração de Veículos',
  VEHICLES_GROUP_LABEL          : 'Veículos',

  EVENT_MAIN_ACTIONS_MODEL_LABEL: 'Ação Principal',
  EVENT_MAIN_ACTIONS_TITLE_LABEL: 'Administração de Ações Principais',
  EVENT_MAIN_ACTIONS_GROUP_LABEL: 'Ações Principais',

  POINT_MODEL_LABEL             : 'Ponto',
  POINT_TITLE_LABEL             : 'Administração de Pontos',
  POINT_GROUP_LABEL             : 'Pontos',

  FORM_MODEL_LABEL              : 'Formulário',
  FORM_TITLE_LABEL              : 'Administração de Formulários',
  FORM_GROUP_LABEL              : 'Formulários',

  OBSERVED_AREA_MODEL_LABEL     : 'Área Observada',
  OBSERVED_AREA_TITLE_LABEL     : 'Administração de Áreas Observadas',
  OBSERVED_AREA_GROUP_LABEL     : 'Áreas Observadas',

  REGISTRATION_GROUP_LABEL      : 'Cadastros',

  BANDS_GROUP_LABEL             : 'Faixas',
  GASDUCT_GROUP_LABEL           : 'Gasodutos',
  DC_GROUP_LABEL                : 'Histórico de DCs',
  KILOMETERMARK_GROUP_LABEL     : 'Marcos Quilométricos',
  DELIVERYPOINT_GROUP_LABEL     : 'Pontos de Entrega e Estações',
  REFINARY_GROUP_LABEL          : 'Refinarias',
  TERMINAL_GROUP_LABEL          : 'Terminais',
  STRETCH_GROUP_LABEL           : 'Trechos',
  OILDUCT_GROUP_LABEL           : 'Oleodutos',
  VALVE_GROUP_LABEL             : 'Válvulas',
  SIMF_GROUP_LABEL              : 'SIMFs',

  COMMUNICATION_CHANNEL_GROUP_LABEL : 'Canais de Comunicação',
  REPORT_SOURCE_GROUP_LABEL     : 'Origens',
  CATEGORY_168_GROUP_LABEL      : 'Categorias 168',
  SUBCATEGORY_168_GROUP_LABEL   : 'Subcategorias 168',
  CITY_GROUP_LABEL              : 'Cidades',
  PRODUCT_GROUP_LABEL           : 'Produtos',
  STATE_GROUP_LABEL             : 'Estados',
  INSPECTION_TYPE_GROUP_LABEL   : 'Tipos de Inspeção',

  HISTORIC_TITLE_LABEL          : 'Rastro Histórico',

  MAP_TITLE_LABEL               : 'Mapa',

  APP_TITLE                     : 'SIPD',
  APP_VERSION                   : '2.22.6',
};
