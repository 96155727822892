import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { first } from 'rxjs/operators';
import { FILL_DATA_PREFIX, MESSAGE_PAGE, PAGE_READY_PREFIX } from 'src/app/common/constants';
import { MarkerFilterModel } from 'src/app/general/message/marker.filter.model';
import { MessageComponent, MessageComponentData, MessageComponentType } from 'src/app/general/message/message.component';
import { OperationType } from 'src/app/model/enums.enum';
import { OperationModel } from 'src/app/model/operation.model';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { OperationService } from 'src/app/service/model/operation.service';
import { PatrolService } from 'src/app/service/model/patrol.service';
import { VerificationService } from 'src/app/service/model/verification.service';
import { StorageService } from 'src/app/service/storage-service';
import { BaseGoldenPanel } from '../base-golden-panel/base-golden-panel';
import { UserModel } from '../../model/user.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';

@ProfileClassToConsole()
@Component({
  selector: 'app-message-page',
  templateUrl: './message-page.component.html',
  styleUrls: ['./message-page.component.scss']
})
export class MessagePageComponent extends BaseGoldenPanel implements OnInit, OnDestroy {

  loggedUser:      UserModel;

  markerFilterModel: MarkerFilterModel;

  selectedComponentType: MessageComponentType;

  currentTeam: PatrolTeamModel;
  currentOperation: OperationModel;

  /** Componentes de mensagem */
  @ViewChild('messageComponent', {static: true}) messageComponent: MessageComponent;

  constructor(protected logger:               NGXLogger,
              private storageService:         StorageService,
              private patrolService:          PatrolService,
              private verificationService:    VerificationService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, goldenLayout, container);
  }

  ngOnInit(): void {
    this.glUpdateTabTitle('Mensagens: Carregando...');

    this.loggedUser = this.storageService.getLocalUser();

    this.subscribeFillDataEvent();
  }

  ngOnDestroy(): void {
    this.glUnSubscribeEvent(FILL_DATA_PREFIX + MESSAGE_PAGE);
  }

  ngAfterViewInit() {
    this.glEmitEvent(PAGE_READY_PREFIX + MESSAGE_PAGE, null);
  }

  private subscribeFillDataEvent(){
    this.glSubscribeEvent(FILL_DATA_PREFIX + MESSAGE_PAGE, (data: MessageComponentData) => {
      this.initializeComponent(data);
    });
  }

  private initializeComponent(data: MessageComponentData){

    if(!data) return;

    this.selectedComponentType = data.componentType;

    this.markerFilterModel = new MarkerFilterModel();
    this.markerFilterModel.patrolTeamId = data.patrolTeamId;
    this.markerFilterModel.operationId = data.operationId;
    this.markerFilterModel.operationType = data.operationType;

    if(data.operationType === OperationType.PATROL){
      this.loadOperation(this.patrolService, data.operationType);
    }else{
      this.loadOperation(this.verificationService, data.operationType);
    }
  }

  private loadOperation(operationService: OperationService, operationType: string){
    operationService.loadById(this.markerFilterModel.operationId).pipe(first()).subscribe((operation: OperationModel) => {
      if(!operation) {
        return;
      }

      operation.type = operationType;
      this.currentOperation = operation;
      this.currentTeam = this.currentOperation.patrolTeam;
      let tabTitle = 'Mensagens: ' + this.currentOperation.identifier;

      this.glUpdateTabTitle(tabTitle);
      this.messageComponent.clearData();
      this.messageComponent.updateTitle(operation.identifier);
      this.messageComponent.loadMarkers(true); // true: indica que deve ser carregado todos os marcadores da operação 
    }, error => this.logger.error(error));
  }
}
