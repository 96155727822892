<div class="sipd-container">

    <div class="sipd-header">
        <button mat-icon-button color="accent" matTooltip="Filtrar Formulários" (click)="openFilterDialog()">
        <mat-icon class="material-icons-outlined">filter_list</mat-icon>
        </button>
        <span class="mat-title m-0">{{title}}</span><small matTooltip="Número de Registros Filtrados">[ {{getListCount()}} ]</small>
        <div class="fill-space"></div>
        <div class="actions">
        <mat-form-field class="list-filter  header-input" appearance="outline" >
            <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
            <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
            <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
        </mat-form-field>
        </div>

        <div class="actions">

        <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
          <mat-icon class="material-icons-outlined">refresh</mat-icon>
        </button>
        <button  mat-icon-button (click)="onCreateClick()"
            matTooltip="Adicionar Formulário"
            [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'>
            <mat-icon class="material-icons-outlined">add</mat-icon>
        </button>
        <button mat-icon-button color="primary"
            matTooltip="Abrir Formulário(s) Selecionado(s)"
            [disabled]="this.selection.selected.length == 0"
            (click)="onEditManyClick(this.selection.selected)">
            <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        </button>
        <button mat-icon-button color="primary"
            matTooltip="Copiar Formulário Selecionado
                        (Somente 1 Formulário pode estar selecionado"
            [disabled]="this.selection.selected.length != 1"
            [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'
            (click)="onCopyClick(this.selection.selected.pop())">
            <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary"
            (click)="onDeleteManyClick(this.selection.selected, $event)"
            matTooltip="Remover Formulários Selecionados"
            [disabled]="this.selection.selected.length == 0 || checkManyUserPlacements(this.selection.selected)"
            [hidden]='!authorizationService.userHasPermission(permission.DELETE_FORM)'
             >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>

        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="sipd-content bg-white">
        <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select" sticky>
            <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
              <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
              </mat-checkbox>
            </th>
            <td mat-cell class="txt-center fit-content" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
                [matTooltip]="checkboxTip(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Identificador-->
          <ng-container matColumnDef="identifier">
            <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.identifier}} </td>
          </ng-container>

          <!-- Nome-->
          <ng-container matColumnDef="name">
            <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
            <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.name}} </td>
          </ng-container>

          <!-- Lotação-->
          <ng-container matColumnDef="placement.name">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Lotação </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.placement?.name}}
            </td>
          </ng-container>

          <!-- Tipo de Inspeção-->
          <ng-container matColumnDef="inspectionType">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo de Inspeção</th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.inspectionType}}
            </td>
          </ng-container>

          <!-- Publicado-->
          <ng-container matColumnDef="published">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Publicado</th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.published? 'Sim': 'Não'}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
            (contextmenu)="openContextMenu($event, element)"
            [ngClass]="{ 'highlighted': selection.isSelected(row) }">
          </tr>
        </table>

        <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
      </div>

      <mat-menu #contextMenu="matMenu" [hasBackdrop]="viewContextMenu">
        <button mat-menu-item
          (click)="onEditClick(this.contextMenuSelectedItem, $event)">
          <mat-icon class="material-icons-outlined">folder_open</mat-icon>
          <span>Abrir Formulário</span>
        </button>
        <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'
          (click)="onCopyClick(this.contextMenuSelectedItem)">
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
          <span>Copiar Formulário</span>
        </button>
        <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.DELETE_FORM)'  
          (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.identifier, $event)"
          [disabled]="checkUserPlacement(this.contextMenuSelectedItem)">
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          <span>Remover Formulário</span>
        </button>
      </mat-menu>

    </div>
    <mat-divider></mat-divider>
    <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
