<div class="sipd-container">

  <header class="sipd-header">
    <div *ngIf="selectedComponentType === componentType.OPERATION_MESSAGE; else others" class="message-header">
      <span class="mat-title m-0">Mensagens da Operação: {{ currentOperation?.identifier }} </span>
      <div class="m-0 text-emphasis-2">
        <span class="header-tag ml-2"> <span>&#9679;</span> {{ title }}</span>
      </div>
    </div>

    <ng-template #others>
      <span class="mat-title m-0">Canal: {{ title }} </span>
    </ng-template>

    <div class="fill-space"></div>

    <div class="actions">
      <mat-form-field *ngIf="selectedComponentType === componentType.CHANNEL_MESSAGE" appearance="outline" class="header-input" fxFlex>
        <mat-select name="filter" placeholder="Filtro" (selectionChange)="filterSelectionChanged()"
                    [(ngModel)]="filterSelection" multiple>
          <mat-option [value]="filterSelectionOptions.PATROL">Rondas</mat-option>
          <mat-option [value]="filterSelectionOptions.VERIFICATION">Verificações</mat-option>
          <mat-option [value]="filterSelectionOptions.NO_OPERATION">Sem Operação</mat-option>
        </mat-select>
      </mat-form-field>

      <button *ngIf="selectedComponentType === componentType.CHANNEL_MESSAGE" mat-mini-fab [disabled]="noMoreMessage || (selectedComponentType === componentType.CHANNEL_MESSAGE && !selectedChannel)"
        (click)="paginateMarkers()" matTooltip="Carregar mensagens antigas">
        <mat-icon>keyboard_double_arrow_up</mat-icon>
      </button>

      <button *ngIf="selectedComponentType === componentType.CHANNEL_MESSAGE" mat-mini-fab matTooltip="Exibir participantes" (click)="toggleShowMessages()">
        <mat-icon>group</mat-icon>
      </button>
    </div>

    <button mat-fab *ngIf="showScrollDownButton" class="floating-buttom"
      (click)="scrollToPosition(matList?.scrollHeight); lastListItem = matListItems.last;">
      <mat-icon>markunread</mat-icon>
    </button>

  </header>

  <mat-divider></mat-divider>

  <div #matListElement class="sipd-content py-4 bg-shade-100" (scroll)="scrolled($event)">

    <div #item *ngFor="let marker of filteredMarkers; let index = index"
      [ngClass]="{'audio-item': marker?.markerType === markerType.AUDIO_MESSAGE}">

      <div class="message-card" [ngClass]="{'my-message  right ': marker?.userId === loggedUser?.id,
                            'other-message left': marker?.userId !== loggedUser?.id,
                            'team-message': (marker?.markerType !== markerType.TEXT_MESSAGE &&
                                             marker?.markerType !== markerType.AUDIO_MESSAGE &&
                                             marker?.markerType !== markerType.IMAGE_MESSAGE &&
                                             marker?.markerType !== markerType.VIDEO_MESSAGE)}">

        <mat-card-header class="message-header">
          <mat-icon *ngIf="marker?.sourceType === sourceType.WEB_APP" matTooltip="Enviado da Central">
            desktop_windows
          </mat-icon>
          <mat-icon *ngIf="marker?.sourceType === sourceType.MOBILE_APP" matTooltip="Enviado do Aplicativo">
            phone_iphone
          </mat-icon>
          <span class="mr-2" [style.color]="getUserColor(marker?.userId)">{{marker?.userName}}</span>
          <div class="ml-auto">
            <span *ngIf="marker?.operationIdentifier">
              {{ marker?.operationIdentifier }}
            </span>
            <span *ngIf="marker?.operationIdentifier && marker?.inspectionName"> - </span>
            <span *ngIf="marker?.inspectionName">
              {{ marker?.inspectionName }}
            </span>
          </div>
        </mat-card-header>

        <mat-card-content class="message-content">
          {{marker?.message}}
        </mat-card-content>

        <mat-card-content *ngIf="marker?.markerType === markerType.AUDIO_MESSAGE">
          <span *ngIf="!marker?.fileUrl">&nbsp;&nbsp;[Áudio ainda não disponível] </span>
          <audio *ngIf="marker?.fileUrl" class="audio-player" type="audio/mpeg" [src]="marker?.fileUrl" controls></audio>
        </mat-card-content>

        <mat-card-content *ngIf="marker?.markerType === markerType.VIDEO_MESSAGE">
          <span *ngIf="!marker?.fileUrl">&nbsp;&nbsp;[Video ainda não disponível] </span>
          <video class="w-100 p-2" [src]="marker?.fileUrl" type='video/mp4' controls
          *ngIf="marker?.fileUrl">
          </video>
        </mat-card-content>

        <mat-card-content *ngIf="marker?.markerType === markerType.IMAGE_MESSAGE">
          <span *ngIf="!marker?.fileUrl">&nbsp;&nbsp;[Imagem ainda não disponível] </span>
          <img *ngIf="marker?.fileUrl" class="w-100 p-2" [src]="marker?.fileUrl">
        </mat-card-content>

        <mat-card-footer class="message-footer">
          <span class="mr-auto"> {{ marker?.location | LocationToString }} 
            <span *ngIf="marker?.appointedLocation && marker?.appointedLocation?.lat != 0 && marker?.appointedLocation?.lng != 0">
              <mat-icon class="material-icons-outlined icon-sm2">edit_location_alt</mat-icon>
            </span>
          </span>
          <span class="ml-auto"> {{dateUtils.timestampToStringInSeconds(marker?.receivedServerTimestamp)}} 
          </span>
        </mat-card-footer>
        <span class="warning-message" *ngIf="marker?.priority === true">
          <mat-icon class="warning-icon">error</mat-icon>Atenção
        </span>
      </div>

    </div>

  </div>
  <mat-divider></mat-divider>
  <footer class="sipd-header">
    <form class="min-w-100 footer-form" *ngIf="selectedComponentType === componentType.CHANNEL_MESSAGE">

      <mat-form-field appearance="outline" fxFlex>
        <textarea cdkTextareaAutosize name="messageText" (keypress)="textKeyPress($event)" style="overflow:hidden" matInput
                  placeholder="Mensagem" [(ngModel)]="messageText" [disabled]="!selectedChannel"></textarea>
      </mat-form-field>

      <button mat-icon-button matTooltip="Enviar" [disable]="sendingMessage" (click)="sendMessage()" color="accent">
        <mat-icon class="icon-lg">telegram</mat-icon>
      </button>

    </form>
  </footer>
  
  <app-global-spinner class="sipd-spinner" style="left:75%" *ngIf="selectedComponentType === componentType.CHANNEL_MESSAGE" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
  <app-global-spinner class="sipd-spinner" *ngIf="selectedComponentType === componentType.OPERATION_MESSAGE" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
   
</div>
