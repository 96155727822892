import { Permission, UserType } from './../../../../model/enums.enum';
import { PatrolTeamModel } from './../../../../model/patrolteam.model';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { EditComponent } from '../../../edit-component';
import { NGXLogger } from 'ngx-logger';
import { PatrolTeamService } from 'src/app/service/model/patrol.team.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { VehicleModel } from 'src/app/model/vehicle.model';
import { UserModel } from 'src/app/model/user.model';
import { ShiftDescription, ServiceTypeDescription } from 'src/app/model/enums.enum';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { Subscription } from 'rxjs';
import { MAP_PAGE } from 'src/app/common/constants';
import { EntityModel } from 'src/app/model/entity.model';
import { MarkersService } from 'src/app/service/model/markers.service';

@Component({
  selector: 'app-patrol-team-edit',
  templateUrl: './patrol-team-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './patrol-team-edit.component.scss']
})
export class PatrolTeamEditComponent extends EditComponent implements OnInit, OnDestroy {

  /** SHIFTS: Morning, Night */
  shiftDescription = ShiftDescription;
  serviceTypeDescription = ServiceTypeDescription;

  /** The Vehicles filtered by the company chosed  */
  vehicles:           VehicleModel[];

  /** The Professionals loaded from the Registrations Service, to allow the user to choose from the list */
  professionals:      UserModel[];

  /** The Users of type Coordinators loaded from the Registrations Service, to allow the user to choose from the list */
  coordinators:       UserModel[];

  // Usado ao salvar para avisar a equipe quem mudou
  oldUsers: UserModel[];
  
  private reloadUsersSubscription: Subscription;
  private reloadVehiclesSubscription: Subscription;

  constructor(logger:                     NGXLogger,
              patrolTeamService:          PatrolTeamService,
              dialog:                     MatDialog,
              toastr:                     ToastrService,
              private markerService:      MarkersService,
              public entityCacheService:  EntityCacheService,
              protected storageService:   StorageService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, patrolTeamService, dialog, environment.PATROL_TEAM_MODEL_LABEL, environment.PATROL_TEAM_TITLE_LABEL, storageService,
          'patrol-teams-edit', environment.PATROL_TEAM_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
    this.logger.debug('PatrolTeamEditComponent.constructor()');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.reloadUsersSubscription?.unsubscribe();
    this.reloadVehiclesSubscription?.unsubscribe();
  }

  mapModelToView() {
    super.mapModelToView();

    this.view['professional1'] = this.model['users'] ? this.model['users'][0] ? this.model['users'][0] : null : null;
    this.view['professional2'] = this.model['users'] ? this.model['users'][1] ? this.model['users'][1] : null : null;
    this.view['professional3'] = this.model['users'] ? this.model['users'][2] ? this.model['users'][2] : null : null;
    this.view['shift'] = this.model['shift'];

    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }

    if (!this.view['company']) {
      let patrolTeam = this.model as PatrolTeamModel;
      if (patrolTeam.users && 
          patrolTeam.users[0] && 
          patrolTeam.users[0].company) {
        this.view['company'] = patrolTeam.users[0].company; // backward compatibility code
      }
    }
  }

  mapViewToModel() {
    super.mapViewToModel();

    this.logger.debug('PatrolTeamEditComponent.mapViewToModel()');

    delete this.model['professional1'];
    delete this.model['professional2'];
    delete this.model['professional3'];

    this.model['users'] = [];
    if(this.view['professional1'] && this.view['professional1'] !== '') this.model['users'].push(this.view['professional1']);
    if(this.view['professional2'] && this.view['professional2'] !== '') this.model['users'].push(this.view['professional2']);
    if(this.view['professional3'] && this.view['professional3'] !== '') this.model['users'].push(this.view['professional3']);
    this.model['shift'] = this.view['shift'];
  }

  createData(options?) {
    this.logger.debug('PatrolTeamEditComponent.createData()');

    this.model = new PatrolTeamModel();
    this.view = {};

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  loadFormOptionsData() {
    this.logger.debug('PatrolTeamEditComponent.loadFormOptionsData()');
    let _this = this; // Necessário por causa do contexto das callacks
    const onUsersLoad = function(){
      _this.coordinators = _this.entityCacheService.getFilteredUsers([UserType.COORDINATOR_CCPD, UserType.COORDINATOR_OPPD]);

      if(_this.id || _this.copy){
        _this.updateFormData(true);
      }
    }
    this.entityCacheService.loadUsers(this.loadingListService, onUsersLoad);
    this.reloadUsersSubscription = this.entityCacheService.onUsersReload().subscribe(onUsersLoad);

    const onVehiclesLoad = function(){
      if(_this.id || _this.copy){
        _this.updateFormData(true);
      }
    };
    this.entityCacheService.loadVehicles(this.loadingListService, onVehiclesLoad);
    this.reloadVehiclesSubscription = this.entityCacheService.onVehiclesReload().subscribe(onUsersLoad);

    this.entityCacheService.loadCompanies(this.loadingListService);
  }

  /**
   * Método que recebe a empresa selecionada ou a empresa da equipe e recarrega
   * os dados das combos usando a empresa como filtro
   */
  updateFormData(load: boolean){
    let patrolTeam = this.view as PatrolTeamModel;

    if (!patrolTeam.company && load) {
      if (patrolTeam.users && 
          patrolTeam.users[0] && 
          patrolTeam.users[0].company) {
        patrolTeam.company = patrolTeam.users[0].company; // backward compatibility code
        this.toastr.warning("Empresa não encontrada. Usada empresa do primeiro profissional.");
        this.onEnableEditClick();
      }
    }

    if(!patrolTeam.company) {
      this.toastr.warning("Empresa não encontrada. Dados de Profissionais e Veículo removidos.");
      this.clearCompanyDependentyFields();
      this.onEnableEditClick();
      return;
    };

    if (this.entityCacheService.getVehicles().length == 0 ||
        this.entityCacheService.getUsers().length == 0){
      return;
    }

    this.vehicles = this.entityCacheService.getVehicles().filter( vehicle => vehicle.company?.id === patrolTeam.company.id );

    if (environment.allUsersCanUseApp) {
      this.professionals = this.entityCacheService.getUsers();
    }else {
      this.professionals = this.entityCacheService.getFilteredUsers([UserType.PROFESSIONAL]);
    }
    this.professionals = this.professionals.filter(elem => (elem.patrolTeamId === '' || elem.patrolTeamId == this.id) && elem.company?.id === patrolTeam.company.id);
  }

  clearCompanyDependentyFields(){
    this.view['professional1'] = '';
    this.view['professional2'] = '';
    this.view['professional3'] = '';

    this.view['vehicle'] = '';
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_PATROL_TEAM)){
      return false
    }

    return true;
  }

  onSaveClick(saveClickEvent?) {
    const professional1 = this.view['professional1'];
    const professional2 = this.view['professional2'];
    const professional3 = this.view['professional3'];
    const teamId = this.view['id']; // Existe somente se o modelo já havia sido salvo antes,
                                    // pode ser null que não invalida os testes abaixo

    // Ao menos 1 profissional precisa ser selecionado
    if(!professional1 && !professional2 && !professional3){
      this.toastr.error('Ao menos 1 profissional precisa ser selecionado');
      return;
    }

    // Verifica se o profissional foi escolhido mais de 1 vez
    // TODO: Falta verificar Login e Nome repetidos
    if (((professional1 && professional2) && professional1.id === professional2.id) ||
        ((professional1 && professional3) && professional1.id === professional3.id) ||
        ((professional2 && professional3) && professional2.id === professional3.id)) {
      this.toastr.error('Os profissionais precisam ser diferentes');
      return;
    }

    // Verifica se os profissionais escolhidos já fazem parte de outra equipe
    const professional1HasTeam: boolean = professional1 && professional1.patrolTeamId && professional1.patrolTeamId != teamId;
    const professional2HasTeam: boolean = professional2 && professional2.patrolTeamId && professional2.patrolTeamId != teamId;
    const professional3HasTeam: boolean = professional3 && professional3.patrolTeamId && professional3.patrolTeamId != teamId;

    if (professional1HasTeam && professional2HasTeam && professional3HasTeam) {
      this.toastr.error('Os Profissionais ' + professional1.name + ', ' + professional2.name + ' e '  + professional3.name +
        ' já foram cadastrados em outra equipe.');
      return;
    }
    else if (professional1HasTeam && professional2HasTeam) {
      this.toastr.error('Os Profissionais ' + professional1.name + ' e '  + professional2.name +
        ' já foram cadastrados em outra equipe.');
      return;
    }
    else if (professional1HasTeam && professional3HasTeam) {
      this.toastr.error('Os Profissionais ' + professional1.name + ' e '  + professional3.name +
        ' já foram cadastrados em outra equipe.');
      return;
    }
    else if (professional2HasTeam && professional3HasTeam) {
      this.toastr.error('Os Profissionais ' + professional2.name + ' e '  + professional3.name +
        ' já foram cadastrados em outra equipe.');
      return;
    }
    else if (professional1HasTeam) {
      this.toastr.error('O Profissional ' + professional1.name + ' já foi cadastrado em outra equipe.');
      return;
    }
    else if (professional2HasTeam) {
      this.toastr.error('O Profissional ' + professional2.name + ' já foi cadastrado em outra equipe.');
      return;
    }else if (professional3HasTeam) {
      this.toastr.error('O Profissional ' + professional3.name + ' já foi cadastrado em outra equipe.');
      return;
    }
    else {
      this.view['name'] = this.view['name'].trim();
      this.oldUsers = (this.model as PatrolTeamModel).users; // Em uma nova equipe users é null
      if (this.oldUsers) this.oldUsers = this.oldUsers.slice();
      super.onSaveClick(saveClickEvent);
    }
  }

  entitySaved(entity: EntityModel): void {
    const newUsers = (this.model as PatrolTeamModel).users;
    if (this.oldUsers) {
      this.oldUsers.forEach(oldUser => {
        if (!newUsers.find(newUser => newUser.id == oldUser.id)) {
          // Não encontrou o antigo na lista nova, antigo removido da lista
          this.markerService.sendTeamInfoMessage(this.loggedUser, oldUser.patrolTeamId, "Usuário \"" + oldUser.name + "\" removido da Equipe.");
        }
      });
      newUsers.forEach(newUser => {
        if (!this.oldUsers.find(oldUser => newUser.id == oldUser.id)) {
          // Não encontrou o novo na lista antiga, novo acrescentado na equipe
          newUser.patrolTeamId = this.model.id;
          this.markerService.sendTeamInfoMessage(this.loggedUser, newUser.patrolTeamId, "Usuário \"" + newUser.name + "\" adicionado à Equipe.");
        }
      });
      this.oldUsers = null;
    }
    super.entitySaved(entity);
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['name'] ||
        !this.view['vehicle'] ||
        !this.view['company'] ||
        !this.view['serviceType']) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    if (!this.view['vehicle']) names.push('Veículo');
    if (!this.view['company']) names.push('Empresa');
    if (!this.view['serviceType']) names.push('Tipo de Serviço');
    return names;
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['name'] = null;
    this.model['users'] = [];
  }

  onHistoricalTrackingClick() {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingTeam: this.model});
  }

  replaceRunTimeData(data){
    this.replaceUserData(data, 0, 'patrolTeamId');
    this.replaceUserData(data, 1, 'patrolTeamId');
    this.replaceUserData(data, 2, 'patrolTeamId');
    super.replaceRunTimeData(data);
  }

  replaceUserData(data, userIndex, key) {
    if (!this.model['users'][userIndex] || !data['users'][userIndex])
      return;

    this.model['users'][userIndex][key] = data['users'][userIndex][key];

    if (this.view['professional' + userIndex + 1]) {
      this.view['professional' + userIndex + 1][key] = data['users'][userIndex][key];
      this.updateInitialView('professional' + userIndex + 1 + '.' + key);
    }
  }
}
