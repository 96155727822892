<form class="vehicle-form edit-form h-100">
  <div class="sipd-container">

    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVO]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button 
          matTooltip="Habilitar Edição"
          [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar"
          (click)="onCopyClick()"
          [disabled]="!id"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_VEHICLE)'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()"
          matTooltip="Remover"
          [hidden]='!authorizationService.userHasPermission(permission.DELETE_VEHICLE)'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Mostrar Rastro no Mapa"
          (click)="onHistoricalTrackingClick()">
          <mat-icon class="material-icons-outlined">my_location</mat-icon>
        </button>
        <button mat-icon-button  [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_VEHICLE)'>
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button  (click)="onSaveClick($event)" [disabled]="!canSave()" matTooltip="Salvar" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_VEHICLE)'>
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Placa</mat-label>
          <input cdkFocusInitial name="plate" matInput placeholder="Placa" [(ngModel)]="view['plate']" required [readonly]="readOnly">
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Carro</mat-label>
          <input name="model" matInput placeholder="Carro" [(ngModel)]="view['model']" [readonly]="readOnly">
        </mat-form-field>
        <single-autocomplete [data]="entityCacheService.getCompanies()" name="name" placeholder="Empresa" [(valueData)]="view['company']"
          [required]="true" [disabled]="readOnly" fxFlex>
        </single-autocomplete>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class=" pl-3 pr-2">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Observações</mat-label>
          <textarea matInput name="observations" matInput placeholder="Observações"
            [(ngModel)]="view['observations']" rows="5" [readonly]="readOnly"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>

</form>
