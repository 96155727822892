import { PlacementModel } from './placement.model';
import { CompanyModel } from './company.model';
import { SimpleModel } from './simple.model';
import LanguageUtils from '../service/util/language-utils';

export class UserModel extends SimpleModel {
  login:         string;
  cpf:           string;
  mobilePhone:   string;
  email:         string;  
  appAccess:     boolean;
  patrolTeamId:  string;
  company:       CompanyModel;
  originPlacement: PlacementModel;
  placements:    PlacementModel[];
  lastLogin:     number;
  profileId:     string;
  unique_name:   string; // usado só para salvar o email da transpetro quando retorna do openId

  token: string; //usado para a comunicação com o auth
  password: string; // Usado somente na tela de login

  static getTitle(user: UserModel): string {
    if (!user) {
      return '';
    }

    let title = '';
    if (user.name) {
      title += user.name;
    }
    if (user.login) {
      title += ' (' + user.login + ')';
    }
    return title;
  }

  static getUserTitle(user) {
    if (!user) { return ''; }

    let title = '';
    if (user['name']) {
      title += user['name'];
    }

    if (user['login']) {
      title += ' (' + user['login'] + ')';
    }

    return title;
  } 
 
  static addLanguageMap(){
    LanguageUtils.languageMap['cpf'] = 'CPF';
    LanguageUtils.languageMap['mobilePhone'] = 'Celular';
    LanguageUtils.languageMap['profileId'] = 'Perfil';
    LanguageUtils.languageMap['profileName'] = 'Perfil';
    LanguageUtils.languageMap['appAccess'] = 'Acesso ao App';
    LanguageUtils.languageMap['originPlacement'] = 'Lotação de Origem';
    LanguageUtils.languageMap['placements'] = 'Outras Lotações Acessadas';
    LanguageUtils.languageMap['login'] = 'Login App';
  }
}
