<div class="sipd-container">
    <div class="sipd-header">    
        <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVO]':'')}}</span>
        <div class="fill-space"></div>
        <div class="actions"> 
            <button mat-raised-button 
                matTooltip="Habilitar Edição"
                [hidden]="!canEdit() || !readOnly"
                (click)="onEnableEditClick()">
                Habilitar Edição
            </button>
            <button mat-icon-button color="primary"
                matTooltip="Copiar"
                (click)="onCopyClick()"
                [disabled]="!id"
                [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'>
                <mat-icon class="material-icons-outlined">file_copy</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="onDeleteClick()"
                matTooltip="Remover"
                [hidden]='!authorizationService.userHasPermission(permission.DELETE_FORM) || !canEdit()'>
                <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Publicar" (click)="onPublishClick()" [disabled]="!canPublish()" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'>
                <mat-icon class="material-icons-outlined">send</mat-icon>      
            </button>
            <button mat-icon-button  matTooltip="Desfazer alterações" [disabled]="!canRevert()" (click)="onRevertClick()" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'>
                <mat-icon class="material-icons-outlined">restart_alt</mat-icon>      
            </button>
            <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
                <button mat-icon-button  matTooltip="Salvar formulário" (click)="onSaveClick($event)" [disabled]="!canSave()" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_FORM)'>
                    <mat-icon class="material-icons-outlined">save</mat-icon>      
                </button>   
            </div>
        </div>
    </div>    

    <mat-divider></mat-divider>
    
    <div class="sipd-content bg-white">
        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <mat-form-field appearance="outline" >
                <mat-label>Nome</mat-label>
                <input [readonly]="readOnly" matInput name="name" placeholder="Nome"  matTooltip="{{ view['name'] }}" [(ngModel)]="view['name']" required>     
                <mat-error>Campo Obrigatório</mat-error>
            </mat-form-field> 
            <single-autocomplete [data]="entityCacheService.getPlacements()" name="name" placeholder="Lotação" [(valueData)]="view['placement']"
                [required]="true" [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            <single-autocomplete [data]="singleDataCacheService.getValues('InspectionType')" placeholder="Tipo de Inspeção" [(valueData)]="view['inspectionType']"
                [extraData]="model && model['inspectionType'] ? model['inspectionType'] : null"
                [required]="true" [disabled]="readOnly" fxFlex>
            </single-autocomplete>
        </div>

    <div *ngIf="questions.data && questions.data.length != 0; else emptyTree">
        <mat-tree [dataSource]="questions" [treeControl]="treeControl" class="quiz-tree" *ngIf="questions.data">
        <mat-tree-node *matTreeNodeDef="let quizNode" matTreeNodeToggle [ngClass]="quizNode.type === quizNodeType.ANSWER? 'answer' : 'question'">
            <li class="mat-tree-node tree-item ">
            <!-- <button mat-icon-button color="primary" disabled>
                <mat-icon  fontSet="material-icons-outlined" class="mat-icon-rtl-mirror">
                chevron_right
                </mat-icon>
            </button> -->
            <div class="quiz-tag-type">          
                <span *ngIf="quizNode.type !== quizNodeType.ANSWER">P</span>
                <span *ngIf="quizNode.type === quizNodeType.ANSWER">R</span>
            </div>
            <div class="title">
                <span class="mat-small">
                {{quizNode.type === quizNodeType.ANSWER? "Resposta:" : "Pergunta tipo:"}}
                <span *ngIf="quizNode.type !== quizNodeType.ANSWER">
                    {{ nodeTypeDescription[quizNode.type] }}
                </span>
                </span>
                <span class="mat-body-2"> {{ getQuizIndex(quizNode) }}) {{ quizNode.label }}</span>
            </div>
            <div class="tree-node-action">        
                <button mat-icon-button color="primary" (click)="onEdit(quizNode)"
                *ngIf="quizNode.type != quizNodeType.ANSWER" [disabled]="readOnly"
                matTooltip="Alterar pergunta">
                <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="onRemove(quizNode)"
                *ngIf="quizNode.type != quizNodeType.ANSWER" [disabled]="readOnly"
                matTooltip="Remover pergunta">
                <mat-icon  fontSet="material-icons-outlined">delete</mat-icon>
                </button>
            </div>
            </li>
        </mat-tree-node>
    
        <mat-nested-tree-node *matTreeNodeDef="let quizNode; when: hasNestedChild" [ngClass]="quizNode.type === quizNodeType.ANSWER? 'answer' : 'question'">
            <li class="mat-tree-node tree-item">
            <!--<button mat-icon-button color="primary" matTreeNodeToggle [attr.aria-label]="'toggle ' + quizNode?.value">
                <mat-icon  fontSet="material-icons-outlined" class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(quizNode) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>-->
            <div class="quiz-tag-type">
                <span *ngIf="quizNode.type !== quizNodeType.ANSWER">P</span>
                <span *ngIf="quizNode.type === quizNodeType.ANSWER">R</span>
            </div>
            <div class="title" matTreeNodeToggle>
                <span class="mat-small">
                {{ quizNode.type === quizNodeType.ANSWER? "Resposta:" : "Pergunta tipo:"}}
                <span *ngIf="quizNode.type !== quizNodeType.ANSWER">
                    {{ nodeTypeDescription[quizNode.type] }}
                </span>
                </span>
                <span class="mat-body-2"> {{ getQuizIndex(quizNode) }}) {{ quizNode.label }}</span>
            </div>
            <div class="tree-node-action">        
                <button mat-icon-button color="primary" (click)="onEdit(quizNode)" [disabled]="readOnly"
                *ngIf="quizNode.type != quizNodeType.ANSWER" matTooltip="Alterar pergunta">
                <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="onRemove(quizNode)" [disabled]="readOnly"
                *ngIf="quizNode.type != quizNodeType.ANSWER" matTooltip="Remove pergunta">
                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                </button>
            </div>
            </li>
            <div class="tree-nested-content" [class.quiz-tree-invisible]="!treeControl.isExpanded(quizNode)">
            <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
        </mat-tree>
    
        <div class="quiz-footer">
            <button mat-raised-button (click)="handleQuestionCrud(questionCrudType.ADD, null, view)" matTooltip="Adicionar Pergunta" style="margin-right:auto" [disabled]="readOnly">
                <mat-icon fontSet="material-icons-outlined">add</mat-icon>
                Nova Pergunta
            </button>   
        </div>
    
    </div>
    
    <ng-template #emptyTree>
        <div class="quiz-empty mat-body-1">
          Formulário vazio.
        </div>

        <div class="quiz-footer">
            <button mat-raised-button (click)="handleQuestionCrud(questionCrudType.ADD, null, view)" matTooltip="Adicionar Pergunta" style="margin-right:auto" [disabled]="readOnly">
                <mat-icon fontSet="material-icons-outlined">add</mat-icon>
                Nova Pergunta
            </button>    
        </div>
    </ng-template>
   
</div>
 
<app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
