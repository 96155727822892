import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { LayerService } from 'src/app/service/model/layer.service';
import { UserService } from 'src/app/service/model/user.service';
import { PatrolFilterModel } from './patrol.filter.model';
import { OperationStatusDescription } from 'src/app/model/enums.enum';
import { PatrolService } from 'src/app/service/model/patrol.service';
import { PatrolTeamService } from 'src/app/service/model/patrol.team.service';
import { PatrolModel } from 'src/app/model/patrol.model';
import { StorageService } from 'src/app/service/storage-service';
import { OperationFilterComponent } from 'src/app/general/filter-component/operation-filter-component';
import { SingleDataCacheService } from '../../../service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patrol-filter-dialog',
  templateUrl: './patrol-filter-dialog.component.html',
  styleUrls: ['./patrol-filter-dialog.component.scss']
})
export class PatrolFilterDialogComponent extends OperationFilterComponent {

  operationStatusDescription = OperationStatusDescription;
  
  constructor(patrolTeamService:                                  PatrolTeamService,
              logger:                                             NGXLogger,
              layerService:                                       LayerService,
              userService:                                        UserService,
              protected patrolService:                            PatrolService,
              public singleDataCacheService:                      SingleDataCacheService,
              entityCacheService:                                 EntityCacheService,
              protected storageService:                           StorageService,
              protected toastr:                                   ToastrService,
              public dialogRef:                                   MatDialogRef<PatrolFilterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogFilterModel:  PatrolFilterModel,
              public filterModel:                                 PatrolFilterModel) {
    super(logger, userService, layerService, patrolTeamService, singleDataCacheService, entityCacheService, patrolService, filterModel, storageService);
  }

  ngOnInit(): void{
    this.loggedUser = this.storageService.getLocalUser();

    this.filterModel = new PatrolFilterModel();
    this.inMemoryFilterModel = this.dialogFilterModel;
    
    super.ngOnInit(); // A cópia do filtro será feita aqui
  }

  loadFormOptionsData() {
    this.loadPatrolTeams();
  }

  onFilter(){
    this.loadingListService.loadingOn();
    this.loadFilteredDataModel().pipe(first()).subscribe( (patrols : PatrolModel[]) => {
      this.dialogRef.close(patrols);
    }, error => {
      if(error.status == 413){
        this.toastr.warning("Sua consulta retornou mais de 2000 registros, por favor refina sua busca.");
        this.dialogRef.close([]);
      }else{
        this.logger.error(error);
      }
    },
    () => {
      this.loadingListService.loadingOff();
    });
  }

  /**
   * Limpa todos os campos do filtro
   */
   clearFilter(){
    super.clearFilter();

    this.filterModel.patrolTeams = [];
  }
}
