<div mat-dialog-title>
    <span>{{ getTitle() }}</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
  <div fxLayout="column wrap" class="h-100 ">
    <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pb-3 pr-2">

      <single-autocomplete [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa" [(valueData)]="band"
        [required]="true" matTooltip="{{ band }}" fxFlex>
      </single-autocomplete>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>KM</mat-label>
        <input name="KM" matInput matTooltip="{{ km }}" placeholder="KM" [(ngModel)]="km"
          #KM="ngModel" [dropSpecialCharacters]="false" mask="000+000" required [validation]="true"/>
        <mat-error *ngIf="KM.hasError('required')">Campo Obrigatório</mat-error>
        <mat-error *ngIf="!KM.hasError('required') && KM.invalid">Formato inválido para o KM.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Lat, Long</mat-label>
        <input name="latLong" matInput placeholder="Lat, Long" matTooltip="{{ latlong }}"
          [(ngModel)]="latlong" [mask]="latLongMask" (paste)="onPasteLatLong($event)"
          [patterns]="latLongPattern" [dropSpecialCharacters]="false" #latLong="ngModel" [validation]="true" required/>
        <mat-error *ngIf="latLong.hasError('required')">Campo Obrigatório</mat-error>
        <mat-error *ngIf="!latLong.hasError('required') && latLong.invalid">Formato inválido para o Lat, Long.</mat-error>
      </mat-form-field>

      <single-autocomplete [data]="singleDataCacheService.getValues('City')" placeholder="Cidade" [(valueData)]="city"
        matTooltip="{{ city }}" fxFlex>
      </single-autocomplete>

      <single-autocomplete [data]="singleDataCacheService.getValues('State')" placeholder="UF" [(valueData)]="state"
        matTooltip="{{ state }}" fxFlex>
      </single-autocomplete>

      <mat-form-field appearance="outline" class=" w-100">
        <mat-label>Atividades</mat-label>
        <textarea name="activities" matInput placeholder="Atividades" matTooltip="Atividades são separadas por | ao fim de uma linha"
         [(ngModel)]="activities" rows="5"></textarea>
      </mat-form-field>
    </div>
  </div>
  
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]=true (click)="accept()" [disabled]="!canInsert() || KM.invalid || latLong.invalid">{{ editing? 'Salvar': 'Inserir'}}</button>
  <button mat-raised-button [mat-dialog-close]=false>Cancelar</button>
</mat-dialog-actions>
