/**
 * Classe abstrata de sinais recebidos do app ou veículo.
 */
export abstract class AbstractSignalModel {

  public mobileObjectId: string; // userId para App, plate para Veículo
  public timestamp: number;

  public latitude: number;
  public longitude: number;
  public sourceType: string;

  public teamId: string;
  public operationId: string;
  public operationType: string;
  public operationStatus: string;
  
  public receivedTimestamp: number;

  constructor(){}
}
