import LanguageUtils from "../service/util/language-utils";
import { EntityModel } from "./entity.model";

export class ProfileModel extends EntityModel {
  name : string;
  userType: string; // TODO scuri Porque esse tipo não é UserType ???
  permissions: {};

  static addLanguageMap(){
    LanguageUtils.languageMap['permissions'] = 'Permissões';
  }
}
