import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss']
})
export class GlobalSpinnerComponent implements OnInit {

  @Input() color?:                  ThemePalette = "primary";
  @Input() diameter?:               number = 150;
  @Input() mode?:                   ProgressSpinnerMode = "indeterminate";
  @Input() strokeWidth?:            number = 20;
  @Input() value?:                  number;
  @Input() showSpinner:             boolean;

  constructor() { }

  ngOnInit() : void{

  }
}
