import { Directive, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import * as GoldenLayout from "golden-layout";
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from "ngx-golden-layout";
import { NGXLogger } from "ngx-logger";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponent } from "../general/confirmation-dialog/confirmation-dialog.component";
import { ArchivedType } from "../general/filter-component/filter.model";
import { CommonModel } from "../model/common.model";
import { EntityModel } from '../model/entity.model';
import { AuthorizationService } from "../service/authorization/authorization.service";
import { ArchiveService } from "../service/model/archive.service";
import { StorageService } from "../service/storage-service";
import { TrackingService } from '../service/model/tracking.service';
import { FilteredListComponent } from './filtered-list-component';
import { ProfileClassToConsole } from '../common/profile-class.decorator';
import { first } from 'rxjs/operators';

@ProfileClassToConsole()
@Directive()
export class ArchiveListComponent extends FilteredListComponent implements OnInit, OnDestroy {

  // ARQUIVAMENTO
  denyArchiving: boolean = true; // Disable do botão de arquivamento
  archiveButtonLabel: string = 'Arquivar'; // Label do botão de arquivamento

  denyRemoveMany: boolean = true; // Disable do botão de remover

  isHiddenButtonArchiveForUser : boolean;

  constructor(public    logger:               NGXLogger,
              protected changeDetector:       ChangeDetectorRef,
              public authorizationService:    AuthorizationService,
              protected entityService:        ArchiveService,
              protected dialog:               MatDialog,
              public    componentName:        string,
              public    tabTitle:             string,
              public    title:                string,
              public    modelName:            string,   // Usado em diálogos de confirmação
              protected storageService:       StorageService,
              protected trackingService:      TrackingService,              
              protected toastr:               ToastrService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, entityService, dialog, componentName, tabTitle, title, modelName, storageService, changeDetector,toastr, goldenLayout, container);
  }

  ngOnInit() {
    this.logger.debug('ArchiveListComponent.ngOnInit()');
    super.ngOnInit();
    this.isOperationArchiveButtonHiddenForUser();
  }

  ngOnDestroy(){
    super.ngOnDestroy();
  }

  // Atualiza o disabled do botão de remover
  updateRemoveRequirement(){
    this.denyRemoveMany = !(this.checkRemoveRequirements());
  }

  // Percorre os itens selecionados verificando se atende aos requisitos para remover
  checkRemoveRequirements(): boolean{
    if (!this.selection.hasValue()) {
      return false;
    }

    for(let model of this.selection.selected){
      if (!this.canDelete(model))
        return false;
    }

    return true;
  }

  canDelete(model: EntityModel): boolean {
    return true;
  }

  //########################################################
  // MÉTODOS DE ARQUIVAMENTO
  //########################################################

  // Submit do botão de arquivamento
  onArchiveManyClick(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
          msg: this.archiveButtonLabel + ' item(s) selecionado(s)?',
          title: this.archiveButtonLabel + ' ' + this.modelName,
          okLabel: this.archiveButtonLabel,
          showIdentifier: false
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.archive();
      }
    }, error => this.logger.error(error));
  }

  // Método de arquivamento
  private archive(){
    this.entityService.archive(this.selection.selected).pipe(first()).subscribe( values => {
      this.toastr.info('Ação de ' + this.archiveButtonLabel + ' ' + this.modelName + ' foi realizada com sucesso.');

      this.archiveUpdate();
    }, error => this.logger.error(error));
  }

  selectionUpdate() {
    this.updateArchiveRequirement();
    this.updateRemoveRequirement();
  }

  private entityArchiveUpdate(entity: EntityModel) {
    if(this.filterModel.archived == ArchivedType.NON_ARCHIVED || this.filterModel.archived == ArchivedType.ARCHIVED){
      const index = this.model.findIndex(item => item.id === entity.id);
      this.model.splice(index, 1);
    }else{
      const updated: CommonModel = <CommonModel>this.model.find((model) => entity.id == model.id);
      updated.archived = !updated.archived;
    }
  }

  // Remove os elementos da lista caso o filtro esteja marcado como apenas os não arquivados
  archiveUpdate(){
    this.selection.selected.forEach(entity => {
      this.entityArchiveUpdate(entity);
    });

    if(this.filterModel.archived == ArchivedType.NON_ARCHIVED || this.filterModel.archived == ArchivedType.ARCHIVED){
      this.buildDataSource();
    }
  }

  onArchiveClick(entity: EntityModel){
    let entities: EntityModel[] = [];
    entities.push(entity);
    this.entityService.archive(entities).pipe(first()).subscribe( values => {
      this.toastr.info('Ação de ' + this.archiveButtonLabel + ' ' + this.modelName + ' foi realizada com sucesso.');
      this.entityArchiveUpdate(entity);

      if(this.filterModel.archived == ArchivedType.NON_ARCHIVED || this.filterModel.archived == ArchivedType.ARCHIVED){
        this.buildDataSource();
      }
    }, error => this.logger.error(error));
  }

  isOperationCreateButtonHiddenForUser(): boolean {
    return !this.authorizationService.isOperationCreatingAllowed();
  }

  isOperationArchiveButtonHiddenForUser(){
    this.isHiddenButtonArchiveForUser = !this.authorizationService.isOperationArchiveAllowed();     
  }

  // Atualiza o disabled do botão de arquivamento
  updateArchiveRequirement(){
    this.denyArchiving = !(this.checkArchivalRequirements());
  }

  // Percorre os itens selecionados verificando se atende aos requisitos para arquivamento
  checkArchivalRequirements(): boolean{
    if(!this.selection.hasValue()) {
      this.archiveButtonLabel = 'Arquivar'; //Label default
      return false;
    }

    if (this.authorizationService.isAdmin())
      return true;

    let canArchive = this.authorizationService.isControl();
    if (canArchive) {
      let someArchived = false;
      let someNotArchived = false;

      for(let model of this.selection.selected as CommonModel[]){
        if (canArchive) {
          if (!this.hasArchivalRequirements(model))
            canArchive = false;
        }

        (model.archived) ? someArchived = true : someNotArchived = true;
      }

      this.archiveButtonLabel = someNotArchived ? 'Arquivar' : 'Desarquivar'; //Atualiza a label do botão de arquivamento

      if(someArchived && someNotArchived) canArchive = false; // Seleção de itens arquivados e não arquivados desabilita o botão
    }

    return canArchive;
  }

  canArchive(model: EntityModel): boolean{
    if (!model)
      return false;

    if (this.authorizationService.isAdmin())
      return true;

    let canArchive = this.authorizationService.isControl();
    if (canArchive) {
      if (!this.hasArchivalRequirements(model as CommonModel))
        canArchive = false;
    }

    return canArchive;
  }

  // Sobreescrito para verificações, rondas e eventos
  hasArchivalRequirements(model: CommonModel){
    return true;
  }
}
