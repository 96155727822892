import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileModel } from 'src/app/model/profile.model';
import { environment } from 'src/environments/environment';
import { EntityService } from './entity.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/profiles`);
  }

  public getProfileById (id: string): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.apiUrl + '/' + id);
  }
}
