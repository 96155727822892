import { Directive, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import * as GoldenLayout from "golden-layout";
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from "ngx-golden-layout";
import { NGXLogger } from "ngx-logger";
import { FilterModel } from "../general/filter-component/filter.model";
import { EntityModel } from '../model/entity.model';
import { AuthorizationService } from "../service/authorization/authorization.service";
import { StorageService } from "../service/storage-service";
import { ListComponent } from "./list-component";
import { MAP_PAGE } from '../common/constants';
import { MatSidenav } from '@angular/material/sidenav';
import { EntityService } from '../service/model/entity.service';
import { ToastrService } from 'ngx-toastr';
import { MapEvents } from '../model/enums.enum';
import { environment } from 'src/environments/environment';
import { ProfileClassToConsole } from '../common/profile-class.decorator';
import { first } from 'rxjs/operators';


@ProfileClassToConsole()
@Directive()
export class FilteredListComponent extends ListComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;
  openedSideList: boolean = false;

  filterModel: FilterModel;

  mapEvents = MapEvents;
  isLoading: boolean = false;

  constructor(public    logger:               NGXLogger,
              public authorizationService: AuthorizationService,
              protected entityService:        EntityService,
              protected dialog:               MatDialog,
              public    componentName:        string,
              public    tabTitle:             string,
              public    title:                string,
              public    modelName:            string,   // Usado em diálogos de confirmação
              protected storageService:       StorageService,
              protected changeDetector:       ChangeDetectorRef,
              protected toastr:               ToastrService,              
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, entityService, dialog, toastr, componentName, tabTitle, title, modelName, storageService, changeDetector, goldenLayout, container);
  }

  ngOnInit() {
    super.ngOnInit();
    this.logger.debug('FilteredListComponent.ngOnInit()');

    if (this.hasListPopoutData()) {
      const popout = this.getListPopoutData();
      this.filterModel = popout.filterModel;
      this.restorePopoutData(popout);
      this.setListPopoutData(null);
    }
    else {
      this.filterModel = this.newFilterModel();
    }

    /**Verifica se o usuário possui a restrição "Acesso somente a dados de Lotações específicas" da seção Web.
     * Em caso positivo, filtra por somente as que pertencem à mesma Lotação do usuário */
    this.filterModel.placements = this.storageService.getSpecificPlacementIds();

    this.loadRecordsFromServer();
  }

  protected newFilterModel() {
    return new FilterModel();
  }

  restorePopoutData(popout){}

  ngOnDestroy(){
    super.ngOnDestroy();
  }

  loadRecordsFromServer() {
    if (this.isLoading) // Controle para evitar que a primeira conexão com o Websocket dispare num novo load
      return;

    this.logger.debug('FilteredListComponent.loadRecordsFromServer()');

    this.loadingOn();
    this.isLoading = true;

    this.dataSource = new MatTableDataSource([]);
    
    this.entityService.loadFilteredRawListFromRestApi(this.paginator? 0: null, this.paginator? this.initialPageSize: null, this.sortOptions, this.filterModel).pipe(first()).subscribe( (result) => {
      this.updateDataSource(result);
    }, error => {      
      if(error.status == 413){// não é um erro, a consulta foi maior do límite (2000 registros)
        this.toastr.warning("Sua consulta retornou mais de 2000 registros, por favor refina sua busca.");
        this.isLoading = false;
        this.loadingOff();
      }else{      
        this.logger.error(error);
      }
    },
    () => {
      this.isLoading = false;
      this.loadingOff();
      this.renderComponent();
    });
  }

  glOnPopout() {
    this.logger.debug('FilteredListComponent.glOnPopout()');
    this.setListPopoutData({filterModel: this.filterModel});
  }

  glOnPopin() {
    this.logger.debug('FilteredListComponent.glOnPopout()');
    this.setListPopoutData({filterModel: this.filterModel});
  }

  onLocationClick(entity: EntityModel, mapEvent: MapEvents) {
    this.glOpenContainer(MAP_PAGE, {entity: entity, mapEvent: mapEvent});
  }

  onLocationManyClick(mapEvent: MapEvents){
    let entityList = [];
    this.selection.selected.forEach( (entity: EntityModel) => {
      if(this.hasLocation(entity))
        entityList.push(entity);
    });

    if(entityList.length > 0)
      this.glOpenContainer(MAP_PAGE, {entityList: entityList, mapEvent: mapEvent});
    else
      this.toastr.warning("Nenhum item selecionado possui dados de localização.");
  }

  hasLocation(entity: EntityModel): boolean{ return true; }

  onPageChanged(event){
    if (environment.useServerPagination) {
      const pageIndex = event.pageIndex;
      this.loadingOn();
      this.entityService.loadFilteredRawListFromRestApi(pageIndex.toString(), this.pageSize, this.sortOptions, this.filterModel).pipe(first()).subscribe( (result) => {
        this.updateDataSource(result);
      }, error => {
        this.logger.error(error);
      },
      () => {
        this.loadingOff();
        this.renderComponent();
      });
    }
  }

  sidenavToggle(opened: any) {
    this.openedSideList = opened;
  }
}
