import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MarkerFilterModel } from 'src/app/general/message/marker.filter.model';
import { MessageComponent, MessageComponentType } from 'src/app/general/message/message.component';
import { ChannelModel } from 'src/app/model/channel.model';
import { ChannelType, ChannelTypeDescription, OperationStatus, OperationType, Permission, UserType } from 'src/app/model/enums.enum';
import { OperationModel } from 'src/app/model/operation.model';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { ChannelService } from 'src/app/service/model/channel.service';
import { PatrolService } from 'src/app/service/model/patrol.service';
import { VerificationService } from 'src/app/service/model/verification.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { OperationService } from 'src/app/service/model/operation.service';
import { OperationFilterModel } from 'src/app/general/filter-component/operation.filter.model';
import { CHANNEL_PAGE_SIZE, ESP, MEMBERS_PAGE_SIZE, PATROL_UPDATE_EVENT, REGISTRATION_UPDATE_EVENT, SHIFT_DURATION, VERIFICATION_UPDATE_EVENT } from 'src/app/common/constants';
import { ListComponent } from '../../list-component';
import { ChannelFilterModel } from '../channel-filter/channel.filter.model';
import { RegistrationType } from '../../../model/enums.enum';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { AuthenticationService } from '../../login/login-services/authentication.service';
import { MarkerModel } from 'src/app/model/marker.model';
import { Websocket } from 'src/app/service/websocket/websocket';
import { StompState } from '@stomp/ng2-stompjs';
import { MatSidenav } from '@angular/material/sidenav';
import ListUtils from 'src/app/service/util/list-utils';
import { PlacementModel } from 'src/app/model/placement.model';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { UserModel } from 'src/app/model/user.model';
import { ChannelUsersService } from 'src/app/service/model/channel.users.service';
import { ChannelUsersModel } from 'src/app/model/channel.users.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserService } from 'src/app/service/model/user.service';

/** WEBSOCKET STATES */
const DISCONNECTED = { value: 0, text: 'Mensagens Desconectado' };
const CONNECTING = { value: 1, text: 'Mensagens Conectando' };
const CONNECTED = { value: 2, text: 'Mensagens Conectado' };

export class TypeChannels {
  name : string; // Enum do tipo
  value : string; // Descrição do tipo
};

@ProfileClassToConsole()
@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelListComponent extends ListComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;
  openedSideList: boolean = false;

  channelTypeDescription = ChannelTypeDescription;

  selectedChannel: ChannelModel;

  markerFilterModel: MarkerFilterModel;
  filterModel: ChannelFilterModel

  // Depeendem do canal selecionado
  currentTeam: PatrolTeamModel;
  currentOperation: OperationModel;

  selectedComponentType = MessageComponentType.CHANNEL_MESSAGE;

  // Lista de todos os canais sem restrições
  allChannels: ChannelModel[];

  // this.model vai conter a lista de canais filtrada pelo filtro

  // Lista de canais permitidos ao usuário logado
  allowedChannels: ChannelModel[] = [];
  
  // Lista de tipos canais permitidos ao usuário logado
  allowedChannelTypes: TypeChannels[] = [];
  
  // Lista de lotações permitidos ao usuário logado
  allowedPlacements: PlacementModel [] = [];

  private loadOperationSubscription: Subscription;
  private onNewMarkerReceivedSubscription: Subscription;
  private updateInMemoryChannelsSubscription: Subscription;

  authenticatedSubscription: Subscription;

  markersConnectionState: {value: number, text: string} = DISCONNECTED;
  private markersWebsocket: Websocket;
  private markersWebsocketStateSubscription: Subscription;

  /** Componentes de mensagem */
  @ViewChild('messageComponent', { static: true }) messageComponent: MessageComponent;

  @ViewChild('membersPagination', { static: true }) membersPaginator: MatPaginator;

  @ViewChild('membersMatSort', { static: true }) membersSort: MatSort
  
  membersDataSource: MatTableDataSource<UserModel>;

  /** The list of columns to display on the grid */
  membersDisplayedColumns: string[];

  showMessages: boolean = true;

  channelUsersMap = new Map<string, UserModel[]>();

  membersPageSize = environment.defaultPageSize;
  membersPageLength = undefined;

  constructor(logger: NGXLogger,
              storageService: StorageService,
              protected toastr: ToastrService,
              public authorizationService: AuthorizationService,
              dialog: MatDialog,
              private userService: UserService,
              private channelService: ChannelService,
              public entityCacheService: EntityCacheService,
              private patrolService: PatrolService,
              private channelUsersService: ChannelUsersService,
              private verificationService: VerificationService,
              private authenticationService: AuthenticationService,
              protected changeDetector: ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, channelService, dialog, toastr, 'channels', environment.CHANNEL_GROUP_LABEL, environment.CHANNEL_TITLE_LABEL,
          "Canal", storageService, changeDetector, goldenLayout, container);
    logger.debug('ChannelListComponent.constructor()');

    this.sortOptions = 'lastMessageReceivedTimestamp,DESC';
    this.pageSize = CHANNEL_PAGE_SIZE;
    this.membersPageSize = MEMBERS_PAGE_SIZE;

    this.filterModel = new ChannelFilterModel();
    this.markerFilterModel = new MarkerFilterModel();
  }

  ngOnInit(): void {    
    this.logger.debug('ChannelListComponent.ngOnInit()');
    
    this.displayedColumns = ['newMessagesCount',
      'name',
      'lastMessageReceivedTimestamp',
      'company.name',
      'type',
      'company.placement.name',
      'company.uf'];

      this.membersDisplayedColumns = ['name',
      'login',
      'company.name',
      'originPlacement.name',
      'profile.name'];

    super.ngOnInit();

    this.membersSort.sort({id: this.getDefaultMembersSortColumn(), start: this.getDefaultMembersSortDirection(), disableClear: false});

    this.updateTabTitle();
    this.loggedUser = this.storageService.getLocalUser();
    this.subscribeToPatrolUpdateEvent();
    this.subscribeToVerificationUpdateEvent();
    this.subscribeToRegistrationUpdateEvent();

    this.subscribeToNewChannelMessageReceived();
    this.subscribeOnAuthenticatedStatus();
    this.subscribeToMarkersWebsocketStatusCheck();

    if (!this.authorizationService.getLoggedUserType()) {
      this.allowedChannelTypes = [];
      this.allowedPlacements = [];
      this.clearFilter();
    }
    else {
      this.updateAllowedChannelTypes(); // Depende de autenticação
      this.updateAllowedPlacements(); // Depende de autenticação
      this.clearFilter();
      this.loadRecordsFromServer();      
    }
  }

  protected getDefaultMembersSortColumn(): string {
    return "name";
  }

  protected getDefaultMembersSortDirection(): 'asc' | 'desc' {
    return 'asc';
  }

  private updateTabTitle() {
    const title = environment.CHANNEL_GROUP_LABEL;
    const channelsWithNewMessagesCount = this.channelService.channelCountWithNewMessages();
    if (channelsWithNewMessagesCount > 0) {
      this.glUpdateTabTitle(title + ' (' + channelsWithNewMessagesCount + ')');
    } else {
      this.glUpdateTabTitle(title);
    }
  }

  /**Como a tela de mensagem é carregado no início, é preciso verificar se o usuario tem permissão e se sim, realizar o filtro precisa das permissões do usuário */
  subscribeOnAuthenticatedStatus() {
    this.authenticatedSubscription = this.authenticationService.authenticated$.subscribe(authenticated => {
      this.logger.debug('ChannelListComponent.OnAuthenticatedStatus() - ' + authenticated);
      if (authenticated) {

        if(!this.authorizationService.userHasPermission(this.permission.LIST_CHANELS)){
          this.glRemoveLayout('channels');
          return;
        } 

        this.loggedUser = this.storageService.getLocalUser();
        this.updateAllowedChannelTypes(); // Depende de autenticação
        this.updateAllowedPlacements(); // Depende de autenticação
        this.clearFilter();
        this.loadRecordsFromServer();
      }
    });
  }

  /** Verifica o estado da conexão com o websocket de sinais */
  private subscribeToMarkersWebsocketStatusCheck(){
    this.markersWebsocket = Websocket.createSignalsWebsocket();
    this.markersWebsocketStateSubscription = this.markersWebsocket.state().subscribe((state: StompState) => {
      if (state === 0 || state === 3){
        this.markersConnectionState = DISCONNECTED;
      }else if (state === 1){
        this.markersConnectionState = CONNECTING;
      }else if (state === 2){
        this.markersConnectionState = CONNECTED;
      }
    }, error => this.logger.error(error));
  }

  updateAllowedPlacements() {
    this.entityCacheService.loadPlacements(this.loadingListService, () =>{
      this.allowedPlacements = this.entityCacheService.getPlacements();
      
      this.onChannelOptionFilterChange(); 
      this.onFilter(true); 
    });
  }

  updateAllowedChannelTypes() {
    const allChannelTypes: TypeChannels [] = Object.keys(ChannelTypeDescription).map((name) => {
      return {
        name,
        value: ChannelTypeDescription[name as keyof typeof ChannelTypeDescription],
      };
    });

    this.allowedChannelTypes = [];

    if (this.authorizationService.isAdmin()) {
      // Acrescenta todos
      this.allowedChannelTypes = allChannelTypes;
    }
    else {
      // Só acrescenta se está na lista do perfil
      const permissionOptions = this.authorizationService.getUserPermissionOptions(this.permission.LIST_CHANELS);
      if (permissionOptions && permissionOptions.fields && permissionOptions.fields.length > 0) {
        const channelTypeNames = permissionOptions.fields;
        allChannelTypes.forEach(element => {
          if(channelTypeNames.find(name => name === element.name)) {
            this.allowedChannelTypes.push(element);
          }      
        });
      }
    }
  }

  buildDataSource() {
    // Tem uma lista de canais guardada no channelService que contém também a contagem de mensagens não lidas
    // this.model vai ser atualizado com a contagem
    this.channelService.updateChannels(this.model as ChannelModel[]);
    super.buildDataSource();
  }

  postLoadProcess() { 
    this.logger.debug("ChannelListComponent.postLoadProcess");
    this.loggedUser = this.storageService.getLocalUser();

    this.allChannels = this.model as ChannelModel [];

    this.onChannelOptionFilterChange(); // Os campos do filtro lateral dependem da lista de canais ter sido carregada
    this.onFilter(false);

    // Depois que ler os canais do backend, carregamnos a contagem de mensagens não lidas para todos os canais
    this.channelService.getUnreadMessagesByUser(this.loggedUser.id).toPromise().then(response => {
      this.channelService.updateInMemoryChannels(response);
      this.updateTabTitle();
      this.buildDataSource();
    }).catch((err) => {
      this.logger.error("Erro ao carregar contagem de mensagens não lidas:", err);
    });
  }

  onFilter(updateDataSource: boolean){
    // Filtra allowedChannels por filterModel.channelIds para obter this.model
    if (this.filterModel.channelIds.length > 0) {
      this.model = this.allowedChannels.filter(channel => this.filterModel.channelIds.includes(channel.id));
    }
    else {
      this.model = this.allowedChannels;
    }

    if (updateDataSource) super.buildDataSource(); // Para reordenar a lista apenas
  }

  copyModel(src) {
    return JSON.parse(JSON.stringify(src));
  }

  clearFilter(){
    // Somente limpa o filtro, não aplica
    this.filterModel.channelTypes = [];
    this.allowedChannelTypes.forEach(t => {
      this.filterModel.channelTypes.push(t.name);
    });
    this.filterModel.placementNames = this.allowedPlacements.map(p => p.name);
    this.filterModel.channelIds = [];

    this.onChannelOptionFilterChange();
  }

  onChannelOptionFilterChange(){
    if(!this.allChannels) return;

    this.allowedChannels = this.allChannels.filter((channel: ChannelModel) => {
      let match = true;

      if(this.filterModel.channelTypes.length > 0){
        match = this.filterModel.channelTypes.includes(channel.type);
      }

      // Só filtra lotações para canais que não são esses 3 tipos
      if (match && channel.type != ChannelType.CNCL && channel.type != ChannelType.CORE && channel.type != ChannelType.MANAGERIAL ) {
        if(this.filterModel.placementNames.length > 0){
          match = this.filterModel.placementNames.includes(channel.referencedName);
        }
        else {
          let placementNames = this.allowedPlacements.map( p => p.name);
          match = placementNames.includes(channel.referencedName);
        }
      }

      return match;
    });

    ListUtils.orderSimpleModelList(this.allowedChannels);
  }
  
  ngOnDestroy(): void {
    this.loggedUser = this.storageService.getLocalUser();
    if(!this.loggedUser || !this.loggedUser.id ) return;

    super.ngOnDestroy();

    this.loadOperationSubscription?.unsubscribe();
    this.onNewMarkerReceivedSubscription?.unsubscribe();
    this.updateInMemoryChannelsSubscription?.unsubscribe();
    this.authenticatedSubscription?.unsubscribe();
    this.markersWebsocketStateSubscription?.unsubscribe();

    this.glUnSubscribeEvent(REGISTRATION_UPDATE_EVENT);
    this.glUnSubscribeEvent(PATROL_UPDATE_EVENT);
    this.glUnSubscribeEvent(VERIFICATION_UPDATE_EVENT);
  }

  private updateChannelNewMessage(updatedChannel: ChannelModel) {
    if (!this.allChannels) return;

    // Só notifica se for mensagem nova em canal que não esteja filtrado
    let index = this.model.findIndex((channel: ChannelModel) => channel.id === updatedChannel.id);
    if (index != -1) {
      if (updatedChannel.newMessagesCount > 0 &&
        (this.sort.active != 'lastMessageReceivedTimestamp' || // não é a coluna de data hora
          this.sort.direction != 'desc')) { // ou não está na ordenação decrescente  (não avisa se estiver na ordenação padrão)
        this.toastr.info('Você tem novas mensagens!');
      }
    }

    index = this.allChannels.findIndex((channel: ChannelModel) => channel.id === updatedChannel.id);
    if (index != -1) {
      if (this.selectedChannel && updatedChannel.id == this.selectedChannel.id) {
        updatedChannel.newMessagesCount = 0;
      }

      this.allChannels[index] = updatedChannel;
    }

    this.updateTabTitle();
    super.buildDataSource(); // Para reordenar a lista apenas
  }

  private subscribeToNewChannelMessageReceived() {
    this.onNewMarkerReceivedSubscription = this.channelService.onChannelUpdated().subscribe((updatedChannel: ChannelModel) => {
      if (updatedChannel) {
        // Neste ponto o canal já foi incrementado no channelService, basta atualizar o canal na lista aqui
        this.updateChannelNewMessage(updatedChannel);
      }
    }), (error: any) => {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[marker.component.ts] subscribeToNewMarkersNotifications: ${errMsg}`);
    };
  }

  private reloadUserData() {
    this.loggedUser = this.storageService.getLocalUser();
    this.userService.getUserFromEmail(this.loggedUser.email.toLocaleLowerCase()).pipe(first()).subscribe((user:UserModel)=>{
      if(user){ // existe usuário
        const token = this.loggedUser.token;
        this.loggedUser = user;
        this.loggedUser.token = token;

        // Chama novamente depois de carregar os dados do usuário (ver createSession)
        this.storageService.setLocalUser(this.loggedUser);

        let perm = this.authorizationService.userHasPermission(Permission.ACCESS_TO_ALL_PLACEMENT);
        if(!perm){
          // Se não tem a permissão de acessar todas as lotações        
          let userPlacements : string[] = [];
          userPlacements = userPlacements.concat(user?.placements.map(( { id }) => id));
            
          if(user.originPlacement && userPlacements.length > 0 && !userPlacements.includes(user?.originPlacement.id) ){
            userPlacements.push(user.originPlacement.id);
          }
    
          this.storageService.setSpecificPlacementIds(userPlacements);

          this.allowedPlacements = user.placements;

          this.clearFilter();
          this.onFilter(true);
          this.buildDataSource();
        }
      }
    });
  }

  private subscribeToRegistrationUpdateEvent() {
    this.glSubscribeEvent(REGISTRATION_UPDATE_EVENT, (data) => {
      this.loggedUser = this.storageService.getLocalUser();
      if (data.registrationType === RegistrationType.USER) {
        if (this.loggedUser.id == data.id) {
          // TODO scuri Eu acho que isso deveria ficar no AppComponent e não aqui
          this.reloadUserData();
        }
      }
      else if (data.registrationType === RegistrationType.CHANNEL) {
        this.loadRecordsFromServer();
        this.loadChannelUsers(data.id);
      }
      else if (data.registrationType === RegistrationType.PROFILE) {
        if (this.loggedUser.profileId == data.id) {
          this.authenticationService.loadProfile(this.loggedUser);
          this.updateAllowedChannelTypes();
        }
        this.channelUsersMap.clear();
        if (this.selectedChannel) {
          this.loadChannelUsers(this.selectedChannel.id);
        }
      }
      else if (data.registrationType === RegistrationType.CHANNEL_RESET) {
        let index = this.channelService.getMessageData().findIndex(channel => data.id == channel.id);
        if (index != -1) {
          let channel = this.channelService.getMessageData()[index];
          this.channelService.setUnreadMessagesInChannel(data.id, 0);
          this.updateTabTitle();
          super.buildDataSource(); // Para reordenar a lista apenas
          this.logger.debug("onRegistrationUpdateEvent - ResetChannel: " + channel.name);
        }
      }      
    });
  }

  private subscribeToPatrolUpdateEvent() {
    this.glSubscribeEvent(PATROL_UPDATE_EVENT, (data) => {
      this.updateOperation(data.id, data.type, data.patrolTeamId, data.status);
    });
  }

  private subscribeToVerificationUpdateEvent() {
    this.glSubscribeEvent(VERIFICATION_UPDATE_EVENT, (data) => {
      this.updateOperation(data.id, data.type, data.patrolTeamId, data.status);
    });
  }

  private selectedChannelhasTeamOrOperation() {
    const channelOperationTypes = [ChannelType.PD_MOBILE, ChannelType.PD_TECHNICAL, ChannelType.CCPD];
    return (channelOperationTypes.includes(ChannelType[this.selectedChannel.type]))
  }

  private loadOperation(operationService: OperationService, operationId: string, operationType: string) {
    this.loadOperationSubscription = operationService.getRecord(operationId).pipe(first()).subscribe((operation: OperationModel) => {
      operation.type = operationType;
      // Atualizado quando a operação é atualizada
      this.currentOperation = operation;
    }, (error: any) => this.logger.error(error));
  }

  private updateOperation(id: string, type: string, patrolTeamId: string, status: string) {
    if (!this.selectedChannel) return;

    if (this.selectedChannelhasTeamOrOperation()) {
      if (this.currentTeam && patrolTeamId === this.currentTeam.id && status === OperationStatus.STARTED) {
        if (this.currentOperation && this.currentOperation.id != id) {
          if (type === OperationType.PATROL) {
            this.loadOperation(this.patrolService, id, type);
          } else {
            this.loadOperation(this.verificationService, id, type);
          }
        }
      }
    }
  }

  private resetUnreadChannelMessages() {
    this.channelService.resetUnreadChannelMessagesdByUser(this.loggedUser.id, this.loggedUser.profileId, this.selectedChannel.id);
  }

  onRefreshClick() {
    this.loadRecordsFromServer();
  }

  loadChannelUsers(id: string) {
    if (this.channelUsersMap.get(id) == null) {
      this.channelUsersService.getRecord(id).pipe(first()).subscribe((entity: ChannelUsersModel) => {
        entity.users.sort((a, b) => {
          let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        })
        this.channelUsersMap.set(entity.channel.id, entity.users);
        this.buildMembersDataSource();
      },
      error => {
        this.logger.debug("loadChannelUsers = canal não encontrado ou removido");
      });
    }
  }

  getProfileName(user: UserModel):string {
    let profile = this.entityCacheService.getProfileById(user.profileId);
    if (profile) return profile.name;
    return "";
  }

  buildMembersDataSource(){
    if (!this.selectedChannel) return;
    
    this.membersDataSource = new MatTableDataSource(this.channelUsersMap.get(this.selectedChannel.id));

    this.membersDataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };

    this.membersDataSource.sort = this.membersSort;
    
    if (!environment.useServerPagination){
      this.membersDataSource.paginator = this.membersPaginator;
    }

    this.renderComponent();
  }

  /** Evento de mudança na seleção do canal */
  onChannelSelection(channel: ChannelModel) {
    if (!channel) return;

    if (this.selectedChannel === channel) {
      return;
    }

    if (this.selectedChannel) {
      // envia reset do anterior antes de trocar
      this.resetUnreadChannelMessages();
    }

    this.selectedChannel = channel;
    this.markerFilterModel.channelId = channel.id;

    this.resetUnreadChannelMessages();
    this.updateTabTitle();
    super.buildDataSource(); // Para reordenar a lista apenas

    this.loadChannelUsers(channel.id);
    this.buildMembersDataSource();

    this.currentOperation  = null;
    this.currentTeam = null;

    this.messageComponent.updateTitle(this.selectedChannel?.name);
    this.messageComponent.clearData();

    if (this.selectedChannel.type === ChannelType.PD_MOBILE || this.selectedChannel.type === ChannelType.PD_TECHNICAL) {
      this.loadPatrolTeam();
    } else {
      this.messageComponent.loadMarkers();
    }
  }

  private loadPatrolTeam() {
    this.entityCacheService.loadPatrolTeams(this.loadingListService, () => {
      let patrolTeam: PatrolTeamModel = this.entityCacheService.getPatrolTeamById(this.selectedChannel.referencedId); // referencedId é um patrolTeamId
      this.updatePatrolTeamData(patrolTeam);
    });
  }

  public getUnreadMessages(marker: MarkerModel) {
    return this.channelService.getUnreadMessagesInChannel(marker.id);
  }

  private async updatePatrolTeamData(patrolTeam: PatrolTeamModel) {
    this.currentTeam = patrolTeam;

    if (!patrolTeam) return;

    this.messageComponent.loadMarkers();

    await this.loadCurrentOperationByTeam(this.patrolService, OperationType.PATROL);
    if (!this.currentOperation) await this.loadCurrentOperationByTeam(this.verificationService, OperationType.EVENT_VERIFICATION);
  }

  private loadCurrentOperationByTeam(operationService: OperationService, operationType) {
    const filterModel = new OperationFilterModel();
    filterModel.patrolTeams = [this.currentTeam?.id];
    filterModel.status = [OperationStatus.STARTED];
    filterModel.startDate = moment().subtract({hours: SHIFT_DURATION}).utc().valueOf();  // now - SHIFT
    filterModel.endDate = moment().valueOf();
    
    return operationService.loadFilteredListFromRestApi(null, null, null, filterModel).pipe(first(), map((operations: OperationModel[]) => {
      if (!operations || operations.length === 0) return;
      operations[0].type = operationType;
      // Atualizado quando muda de canal
      this.currentOperation = operations[0];
    })).toPromise();
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   */
  protected getStringEntityForFilter(channel: ChannelModel): string {
    const name = super.lowerAndTrimText(channel.name);
    const lastMessageReceivedTimestamp = (channel.lastMessageReceivedTimestamp) ? this.formatDate(channel.lastMessageReceivedTimestamp) : '';
    const type = super.lowerAndTrimText(ChannelTypeDescription[channel.type]);
    const company = super.lowerAndTrimText(channel.company?.name);
    const placement = super.lowerAndTrimText(this.getPlacementName(channel));
    const uf = super.lowerAndTrimText(channel.company?.uf);
    return name + ESP + lastMessageReceivedTimestamp + ESP + type + ESP + company + ESP + placement + ESP + uf;
  }

  protected getStringUserForFilter(user: UserModel): string {
    const name = super.lowerAndTrimText(user.name);
    const login = super.lowerAndTrimText(user.login);
    const company = super.lowerAndTrimText(user.company?.name);
    const placement = super.lowerAndTrimText(user.originPlacement?.name);
    const profile = super.lowerAndTrimText(this.getProfileName(user));
    return name + ESP + login + ESP + company + ESP + placement + ESP + profile;
  }

  getPropertyByPath(item: Object, property: string) {
    switch (property) {
      case 'company.placement.name':
        {
          let value = this.getPlacementName(item as ChannelModel);
          return this.lowerAndTrimText(value);
        }
        case 'profile.name':
          {
            let value = this.getProfileName(item as UserModel);
            return this.lowerAndTrimText(value);
          }
        default: return super.getPropertyByPath(item, property);
    }
  }

  getPlacementName(channel: ChannelModel) {
    if (!channel) {
      return "";
    }
    return channel.type === ChannelType.CORE || channel.type === ChannelType.MANAGERIAL  || channel.type === ChannelType.CNCL ? "" : channel.referencedName;
  }

  getUserPlacementName(user: UserModel) {
    if (!user) {
      return "";
    }
    if (!user.originPlacement && user.placements && user.placements.length == 1) {
      return user.placements[0].name;
    }
     
    return user.originPlacement ? user.originPlacement.name : "";
  }

  protected getDefaultSortColumn(): string {
    return "lastMessageReceivedTimestamp";
  }

  protected getDefaultSortDirection(): 'asc' | 'desc' {
    return 'desc';
  }

  restorePopoutData(popout) {
    this.logger.debug('ChannelListComponent.restorePopoutData()');
    // restaura contagem de mensagens no ChannelService
    this.channelService.setMessageData(popout.messageData);
  }

  glOnPopout() {
    this.logger.debug('ChannelListComponent.glOnPopout()');
    this.setListPopoutData({ filterModel: this.filterModel, messageData: this.channelService.getMessageData() });
  }

  glOnPopin() {
    this.logger.debug('ChannelListComponent.glOnPopin()');
    this.setListPopoutData({ filterModel: this.filterModel, messageData: this.channelService.getMessageData() });
  }

  sidenavToggle(opened: any) {
    this.openedSideList = opened;
  }

  onListMembersClick(row: any, event: Event) {
  }

  toggleShowMessages() {
    this.showMessages = this.showMessages == true ? false : true;
  }
}
