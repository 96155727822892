<div class="sipd-container">

  <header class="sipd-header">
    <button mat-icon-button color="accent" (click)="sidenav.toggle(); sidenavToggle(sidenav.opened)">
      <mat-icon *ngIf="!sidenav.opened">filter_list</mat-icon>
      <mat-icon *ngIf="sidenav.opened">arrow_left</mat-icon>
    </button>
    <div class="mat-title m-0">{{title}}</div><small matTooltip="Número de Registros Lidos">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions">
      <mat-form-field class="list-filter header-input" appearance="outline">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
    </div>

    <div class="actions">
      <div class="sync-status">
        <mat-icon class="connection-icon" *ngIf="markersConnectionState.value===2">sync</mat-icon>
        <mat-icon class="connection-icon" *ngIf="markersConnectionState.value===1">refresh</mat-icon>
        <mat-icon class="connection-icon" *ngIf="markersConnectionState.value===0">sync_problem</mat-icon>
        <span class="connection-icon small">{{markersConnectionState.text}}</span>
      </div>

      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>

    </div>
  </header>

  <mat-divider></mat-divider>

    <mat-sidenav-container class="h-100">
      <mat-sidenav #sidenav mode="side" closed style="width: 320px;">
        <mat-accordion multi displayMode="flat" class="map-panel m-0 p-0">
          <mat-expansion-panel expanded>

            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="mat-body-2">Filtro</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            
            <div class="d-flex flex-column">
        
              <multiselect-autocomplete [data]="allowedChannelTypes" name="value" optionValue="name" placeholder="Tipo"
                [(valueData)]="filterModel.channelTypes" (selectionChangedEvent)="onChannelOptionFilterChange()" fxFlex>
              </multiselect-autocomplete>
        
              <multiselect-autocomplete [data]="allowedPlacements" name="name" optionValue="name" placeholder="Lotação"
                [(valueData)]="filterModel.placementNames" (selectionChangedEvent)="onChannelOptionFilterChange()" fxFlex>
              </multiselect-autocomplete>
        
              <multiselect-autocomplete [data]="allowedChannels" name="name" optionValue="id" placeholder="Nomes de Canais"
                [(valueData)]="filterModel.channelIds" fxFlex>
              </multiselect-autocomplete>
        
            </div>
          
            <mat-divider></mat-divider>
          
            <div class="m-2 py-2" fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0">
              <button mat-raised-button (click)="clearFilter()" fxFlex>Limpar</button>
              <button mat-raised-button (click)="onFilter(true)" fxFlex>Aplicar</button>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </mat-sidenav>
      <mat-sidenav-content>

        <as-split direction="horizontal">
          <as-split-area [size]="50">

            <div class="sipd-content bg-white" style="height:92%">
              <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

                <!-- Coluna Novas Mensagens -->
                <ng-container matColumnDef="newMessagesCount">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> # </th>
                  <td mat-cell class="nowrap" *matCellDef="let element">
                    <div class="message-counter" *ngIf="element?.newMessagesCount !== 0"> {{ (element?.newMessagesCount > 99)? '99+' : element?.newMessagesCount }} </div>
                  </td>
                </ng-container>

                <!-- Coluna nome -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Nome do Canal </th>
                  <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.name">{{ element?.name }}</td>
                </ng-container>

                <!-- Coluna Data/Hora -->
                <ng-container matColumnDef="lastMessageReceivedTimestamp">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora </th>
                  <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.lastMessageReceivedTimestamp | date:'dd/MM/yyyy HH:mm:ss' }} </td>
                </ng-container>

                <!-- Coluna Empresa -->
                <ng-container matColumnDef="company.name">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Empresa </th>
                  <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.company?.name }} </td>
                </ng-container>

                <!-- Coluna Tipo -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo </th>
                  <td mat-cell class="nowrap" *matCellDef="let element"> {{ channelTypeDescription[element?.type] }} </td>
                </ng-container>

                <!-- Coluna Lotação -->
                <ng-container matColumnDef="company.placement.name">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Lotação </th>
                  <td mat-cell class="nowrap" *matCellDef="let element"> {{ getPlacementName(element) }} </td>
                </ng-container>

                <!-- Coluna UF -->
                <ng-container matColumnDef="company.uf">
                  <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> UF </th>
                  <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.company?.uf }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row  *matRowDef="let row; columns: displayedColumns; let element" (click)="onChannelSelection(row)"
                  [ngClass]="{ 'highlighted': selection.isSelected(row), 'actived': row?.id === selectedChannel?.id }">
                </tr>

              </table>
            </div>

            <mat-divider></mat-divider>
            <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>

          </as-split-area>

          <as-split-area [size]="50">

            <app-message [hidden]="!showMessages" #messageComponent
                 [loggedUser]="loggedUser"
                 [selectedChannel]="selectedChannel"
                 [currentTeam]="currentTeam"
                 [currentOperation]="currentOperation"
                 [markerFilterModel]="markerFilterModel"
                 [selectedComponentType]="selectedComponentType"
                 (showMessages)="showMessages=false"
                 (parentTabUpdate)="glUpdateTabTitle($event)"></app-message>

            <div  [hidden]="showMessages" style="height:92%">
              <div class="sipd-content bg-white">
                <header class="sipd-header">
                  <div class="message-header">
                    <span class="mat-title m-0">Participantes do Canal: {{  selectedChannel ? selectedChannel?.name : "Selecione o Canal" }} </span>
                  </div>
                        
                  <div class="fill-space"></div>
              
                    <button mat-mini-fab matTooltip="Exibir mensagens" (click)="showMessages=true">
                      <mat-icon>chat</mat-icon>
                    </button>
              
                </header>

                <div class="sipd-content bg-shade-100" style="height:90%">
                <table mat-table [dataSource]="membersDataSource" matSort #membersMatSort="matSort" class="-table-hover w-100">

                  <!-- Coluna nome -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Nome </th>
                    <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.name">{{ element?.name }}</td>
                  </ng-container>


                  <!-- Coluna login -->
                  <ng-container matColumnDef="login">
                    <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Login </th>
                    <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.login }} </td>
                  </ng-container>


                  <!-- Coluna Empresa -->
                  <ng-container matColumnDef="company.name">
                    <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Empresa </th>
                    <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.company?.name }} </td>
                  </ng-container>

                  <!-- Coluna Lotação -->
                  <ng-container matColumnDef="originPlacement.name">
                    <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Lotação </th>
                    <td mat-cell class="nowrap" *matCellDef="let element"> {{ getUserPlacementName(element) }} </td>
                  </ng-container>

                  <!-- Coluna Perfil -->
                  <ng-container matColumnDef="profile.name">
                    <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Perfil </th>
                    <td mat-cell class="nowrap" *matCellDef="let element"> {{ getProfileName(element) }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="membersDisplayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: membersDisplayedColumns; let element">
                  </tr>

                </table>
                </div>
              </div>

              <mat-divider></mat-divider>
              <mat-paginator #membersPagination [pageSize]="membersPageSize" [length]="membersPageLength" [showFirstLastButtons]="true"></mat-paginator>
            </div>

          </as-split-area>
        </as-split>

      </mat-sidenav-content>
    </mat-sidenav-container>

    <app-global-spinner class="sipd-spinner" style="left:25%" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
  </div>
