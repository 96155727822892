import { ProfileService } from './../../../../service/model/profile.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { PermissionOptions } from 'src/app/model/field.model';
import { ProfileModel } from 'src/app/model/profile.model';
import { EditComponent } from 'src/app/pages/edit-component';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { AlertTypeDescription, EventFieldsDescription, Permission, UserFieldsDescription, UserTypeDescription, AlertType, ChannelTypeDescription, UserType } from '../../../../model/enums.enum';
import { PermisionRuleOption } from '../../../../model/enums.enum';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './profile-edit.component.scss']
})
export class ProfileEditComponent extends EditComponent implements OnInit {

  permissionsMap: Map<string,PermissionOptions > = new Map();

  /**Enums */
  permission               = Permission;
  eventFieldsDescription   = EventFieldsDescription;
  userFieldsDescription    = UserFieldsDescription;
  permisionRuleOption      = PermisionRuleOption;
  userTypeDescription      = UserTypeDescription;
  channelTypeDescription   = ChannelTypeDescription;

  alertTypeMap: Map<string, string>;
  categoryMap: Map<string, string>;
  profileMap: Map<string, string>;

  disableProfileList : boolean = true;
  disableAlertList : boolean = true;

  alertPermissionOptions: PermissionOptions = new PermissionOptions();
  profilePermisionOptions : PermissionOptions = new PermissionOptions();
  eventPermissionOptions : PermissionOptions = new PermissionOptions();
  userFields : PermissionOptions = new PermissionOptions();
  verificationPermisionOptions : PermissionOptions = new PermissionOptions();
  patrolPermisionOptions : PermissionOptions = new PermissionOptions();
  channelPermisionOptions : PermissionOptions = new PermissionOptions();
  sourcePermissionOptions : PermissionOptions = new PermissionOptions();

  constructor(logger:                       NGXLogger,
              public profileService:        ProfileService,
              dialog:                       MatDialog,
              protected storageService:     StorageService,
              public entityCacheService:    EntityCacheService,
              public singleDataCacheService:  SingleDataCacheService,
              toastr:                       ToastrService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container
              ) {
                super(logger, profileService, dialog, environment.PROFILES_MODEL_LABEL, environment.PROFILES_TITLE_LABEL, storageService,
                  'profiles-edit', environment.PROFILES_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
                  this.logger.debug('ProfileEditComponent.constructor()');

                  this.alertPermissionOptions.option = this.permisionRuleOption.SOME_FIELDS;
                  this.verificationPermisionOptions.option = this.permisionRuleOption.ONLY_NOT_STARTED;
                  this.patrolPermisionOptions.option = this.permisionRuleOption.ONLY_NOT_STARTED;
                  this.profilePermisionOptions.option =this.permisionRuleOption.SOME_PROFILE;
               }

  ngOnInit() {
    this.logger.debug('ProfileEditComponent.ngOnInit()');
    super.ngOnInit();

    this.initializeFields();
    
    this.alertTypeMap = this.enumToMap( AlertTypeDescription );
    this.alertTypeMap.set(AlertType.SIMF,AlertType.SIMF); 
    
    this.categoryMap = this.enumToMap( UserTypeDescription );
    this.categoryMap.delete(UserType.ADMINISTRATOR); // não pode ser criado perfis da categoria administrador

    this.profileMap = new Map();
    this.entityCacheService.getProfiles().forEach(profile =>{
      this.profileMap.set(profile.id, profile.name);
    });
  }

  createData(options?) {
    this.logger.debug('ProfileEditComponent.createData()');

    this.model = new ProfileModel();
    this.view = {};
  }

  mapModelToView() {
    super.mapModelToView();
    
    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }

    // Cria uma cópia para poder manipular 
    let profile = this.model as ProfileModel;
    this.permissionsMap = new Map(Object.entries(profile.permissions));

    Object.entries(profile.permissions).forEach(([key, value]) => {
      const permissionOption : PermissionOptions = value as PermissionOptions;

      switch(key){
        case this.permission.LIST_ALERTS:
          this.alertPermissionOptions.fields=permissionOption.fields;
          this.alertPermissionOptions.option=permissionOption.option;
          this.disableAlertList = this.alertPermissionOptions.option  == this.permisionRuleOption.ALL_FIELD ? true : false;
          break;
        case this.permission.CHANGE_ANY_EVENT_FIELD:
          this.eventPermissionOptions.fields=permissionOption.fields;
          this.eventPermissionOptions.option=permissionOption.option;
          break;
        case this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE:
          this.sourcePermissionOptions.fields=permissionOption.fields;
          this.sourcePermissionOptions.option=permissionOption.option;
          break;
        case  this.permission.CREATE_EDIT_USER:
          this.profilePermisionOptions.fields=permissionOption.fields;
          this.profilePermisionOptions.option=permissionOption.option;
          this.disableProfileList = this.profilePermisionOptions.option ==  this.permisionRuleOption.ALL_PROFILE ? true : false;
          break;
        case this.permission.DELETE_USER:
          this.profilePermisionOptions.fields=permissionOption.fields;
          this.profilePermisionOptions.option=permissionOption.option;
          break;          
        case this.permission.DELETE_PATROL:
            this.patrolPermisionOptions.fields=permissionOption.fields;
            this.patrolPermisionOptions.option=permissionOption.option;
            break;          
        case this.permission.DELETE_VERIFICATIONS:
            this.verificationPermisionOptions.fields=permissionOption.fields;
            this.verificationPermisionOptions.option=permissionOption.option;
            break;
        case this.permission.LIST_CHANELS:
            this.channelPermisionOptions.fields = permissionOption.fields;
            this.channelPermisionOptions.option = permissionOption.option;
            break;
      }
    });
  }

  canEdit() {
    if (!this.model) return false;

    return this.authorizationService.isProfileCreateEditAllowedForUser();
  }

  loadFormOptionsData(){
    this.logger.debug('ProfileEditComponent.loadFormOptionsData()');
    this.entityCacheService.loadProfiles(this.loadingListService);

    const metadatas: string[] = [
      "ReportSource"];
    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
    }

  clearCopyData(){
    super.clearCopyData();

    this.model['name'] = null;
  }

  /*** METODOS DO HTML */

  selectAlertFields(){
    this.permissionsMap.set('LIST_ALERTS', this.alertPermissionOptions);
    this.view['permissions']['LIST_ALERTS'] = this.alertPermissionOptions;
  }

  updateAlertFields(){
    if(this.alertPermissionOptions.option == this.permisionRuleOption.ALL_FIELD) {
      this.disableAlertList = true;
      this.alertPermissionOptions.fields = Object.keys(AlertTypeDescription);
      this.alertPermissionOptions.fields.push(AlertType.SIMF);
    }
    else {
      this.disableAlertList = false;
      this.alertPermissionOptions.fields = [];
    }
  }

  alertRadioChange(){
    this.updateAlertFields();
    this.permissionsMap.set(this.permission.LIST_ALERTS, this.alertPermissionOptions);
    this.view['permissions']['LIST_ALERTS'] = this.alertPermissionOptions;
  }

  selectEventsFields(){
    this.permissionsMap.set('CHANGE_ANY_EVENT_FIELD', this.eventPermissionOptions);
    this.view['permissions']['CHANGE_ANY_EVENT_FIELD'] = this.eventPermissionOptions;
  }

  selectChannelFields(){
    this.permissionsMap.set('LIST_CHANELS', this.channelPermisionOptions);
    this.view['permissions']['LIST_CHANELS'] = this.channelPermisionOptions;
  }

  selectProfileFields(){
    if(this.permissionsMap.get(this.permission.DELETE_USER)) {
      this.permissionsMap.set('DELETE_USER', this.profilePermisionOptions);
      this.view['permissions']['DELETE_USER'] = this.profilePermisionOptions;
    }
    if(this.permissionsMap.get(this.permission.CREATE_EDIT_USER)) {
      this.permissionsMap.set('CREATE_EDIT_USER', this.profilePermisionOptions);
      this.view['permissions']['CREATE_EDIT_USER'] = this.profilePermisionOptions;
    }
  }

  profilesRadioChange(){
    if(this.profilePermisionOptions.option == this.permisionRuleOption.ALL_PROFILE){
      this.disableProfileList = true;
      this.profilePermisionOptions.fields = this.entityCacheService.getProfiles().map((obj) => obj.id);
    }
    else{
      this.disableProfileList = false;
      this.profilePermisionOptions.fields = [];
    }
    this.setDualPermission(this.permission.CREATE_EDIT_USER,this.permission.DELETE_USER, this.profilePermisionOptions);
  }

  selectSourceFields(){
    this.permissionsMap.set(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE, this.sourcePermissionOptions);
    this.view['permissions']['CHANGE_EVENTS_FROM_ANY_SOURCE'] = this.sourcePermissionOptions;
  }

  verificationRadioChange(){    
    this.permissionsMap.set(this.permission.DELETE_VERIFICATIONS,this.verificationPermisionOptions); 
    this.view['permissions']['DELETE_VERIFICATIONS'] = this.verificationPermisionOptions;
  }

  patrolRadioChange(){
    this.permissionsMap.set(this.permission.DELETE_PATROL, this.patrolPermisionOptions)
    this.view['permissions']['DELETE_PATROL'] = this.patrolPermisionOptions;
  }

  // Função que trata as mudanças nos toggles principais
  changeMap($event : any, permision: string){
    let  permissionOptions : PermissionOptions = new PermissionOptions();

    if($event.checked) // add a permission
    {
     this.permissionsMap.set(permision, permissionOptions);
     switch(permision){       
       case (this.permission.DELETE_VERIFICATIONS):
         this.verificationRadioChange();
         break;       
       case (this.permission.DELETE_PATROL):
       this.patrolRadioChange();
         break;
       case (this.permission.CREATE_EDIT_USER):
         this.profilesRadioChange();
         break;
       case (this.permission.DELETE_USER):
         this.profilesRadioChange();
         break;
       case (this.permission.LIST_ALERTS):
         this.alertRadioChange();
         break;
       case (this.permission.ACCES_WEB):
        this.permissionsMap.set(this.permission.LIST_CHANELS, permissionOptions);        
        break;
       case (this.permission.CREATE_EDIT_EVENT):
        this.eventPermissionOptions.fields = [];
        this.eventPermissionOptions.option = this.permisionRuleOption.ALL_FIELD;
        this.sourcePermissionOptions.fields = [];
        this.sourcePermissionOptions.option = this.permisionRuleOption.ALL_SOURCE;
        this.permissionsMap.set(this.permission.CHANGE_ANY_EVENT_FIELD,this.eventPermissionOptions ); 
        this.permissionsMap.set(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE, this.sourcePermissionOptions);
        break;
       case (this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE):
        this.sourcePermissionOptions.fields = [];
        this.sourcePermissionOptions.option = this.permisionRuleOption.ALL_SOURCE;
        this.permissionsMap.set(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE, this.sourcePermissionOptions);                 
        break;
       case (this.permission.CHANGE_ANY_EVENT_FIELD):
        this.eventPermissionOptions.fields = [];
        this.eventPermissionOptions.option = this.permisionRuleOption.ALL_FIELD;
        this.permissionsMap.set(this.permission.CHANGE_ANY_EVENT_FIELD,this.eventPermissionOptions );                   
        break;  
     }
    }else { // delete a permission

     this.permissionsMap.delete(permision);

     switch(permision){
       case this.permission.ACCES_WEB:
         this.removeAllOptions(this.permissionsMap,this.permission.ACCES_APP)
        break;
       case this.permission.LIST_ALERTS:
         this.permissionsMap.delete(this.permission.EDIT_ALERT);
         this.alertPermissionOptions.fields=[];
         this.alertPermissionOptions.option = PermisionRuleOption.SOME_FIELDS;
         this.disableAlertList = true;
         break;
       case this.permission.LIST_EVENTS:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_EVENT);
         this.permissionsMap.delete(this.permission.DELETE_EVENT);
         this.permissionsMap.delete(this.permission.CREATE_EDIT_EVENT_NOTES);
         this.permissionsMap.delete(this.permission.DELETE_EVENT_NOTES);
         this.permissionsMap.delete(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE);
         this.permissionsMap.delete(this.permission.CHANGE_ANY_EVENT_FIELD);
         this.eventPermissionOptions.fields=[];
         break;
       case this.permission.CREATE_EDIT_EVENT:
         this.permissionsMap.delete(this.permission.DELETE_EVENT);
         this.permissionsMap.delete(this.permission.CREATE_EDIT_EVENT_NOTES);
         this.permissionsMap.delete(this.permission.DELETE_EVENT_NOTES);
         this.permissionsMap.delete(this.permission.EDIT_END_EVENT);
         this.permissionsMap.delete(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE);
         this.permissionsMap.delete(this.permission.CHANGE_ANY_EVENT_FIELD);
         this.eventPermissionOptions.fields=[];
         break;
         case (this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE): 
            this.sourcePermissionOptions.fields = [];
            this.sourcePermissionOptions.option = this.permisionRuleOption.SOME_SOURCE;
          if(this.permissionsMap.has(this.permission.CREATE_EDIT_EVENT)){ 
             this.permissionsMap.set(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE,this.sourcePermissionOptions)
          } else {
            this.permissionsMap.delete(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE);
          }
          break;
        case (this.permission.CHANGE_ANY_EVENT_FIELD):          
          this.eventPermissionOptions.option = this.permisionRuleOption.SOME_FIELDS;
          this.eventPermissionOptions.fields = [];
          if(this.permissionsMap.has(this.permission.CREATE_EDIT_EVENT)){
            this.permissionsMap.set(this.permission.CHANGE_ANY_EVENT_FIELD,this.eventPermissionOptions)
          } else {
            this.permissionsMap.delete(this.permission.CHANGE_ANY_EVENT_FIELD); 
          }
          break;
       case this.permission.CREATE_EDIT_EVENT_NOTES:
         this.permissionsMap.delete(this.permission.DELETE_EVENT_NOTES);
         break;
       case this.permission.LIST_VERIFICATIONS:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_VERIFICATIONS);
         this.permissionsMap.delete(this.permission.DELETE_VERIFICATIONS);
         this.verificationPermisionOptions.option=this.permisionRuleOption.ONLY_NOT_STARTED
         break;
        case this.permission.CREATE_EDIT_VERIFICATIONS:
         this.permissionsMap.delete(this.permission.DELETE_VERIFICATIONS);
         this.verificationPermisionOptions.option=this.permisionRuleOption.ONLY_NOT_STARTED
         break;
       case this.permission.LIST_PATROLS:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_PATROL);
         this.permissionsMap.delete(this.permission.DELETE_PATROL);
         this.patrolPermisionOptions.option = this.permisionRuleOption.ONLY_NOT_STARTED;
         break;
       case this.permission.CREATE_EDIT_PATROL:
         this.permissionsMap.delete(this.permission.DELETE_PATROL);
         this.patrolPermisionOptions.option = this.permisionRuleOption.ONLY_NOT_STARTED;
         break;
       case this.permission.LIST_USERS_ADMINISTRATION:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_USER);
         this.permissionsMap.delete(this.permission.DELETE_USER);         
         this.profilePermisionOptions.fields=[];
         this.profilePermisionOptions.option = this.permisionRuleOption.SOME_PROFILE;
         this.userFields.fields = [];
         break;
       case this.permission.CREATE_EDIT_USER:
          this.permissionsMap.delete(this.permission.DELETE_USER)          
          this.profilePermisionOptions.fields = [];
          this.disableProfileList  = true;
          this.profilePermisionOptions.option = this.permisionRuleOption.SOME_PROFILE;
          this.userFields.fields = [];
         break;
       case this.permission.LIST_PATROL_TEAM_ADMINISTRATION:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_PATROL_TEAM);
         this.permissionsMap.delete(this.permission.DELETE_PATROL_TEAM);
         break;
        case this.permission.CREATE_EDIT_PATROL_TEAM:
          this.permissionsMap.delete(this.permission.DELETE_PATROL_TEAM);
          break;
       case this.permission.LIST_PLACEMENT_ADMINISTRATION:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_PLACEMENT);
         this.permissionsMap.delete(this.permission.DELETE_PLACEMENT);
         break;
       case this.permission.CREATE_EDIT_PLACEMENT:
         this.permissionsMap.delete(this.permission.DELETE_PLACEMENT);
        break;
       case this.permission.LIST_COMPANY_ADMINISTRATION:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_COMPANY);
         this.permissionsMap.delete(this.permission.DELETE_COMPANY);
         break;
       case this.permission.CREATE_EDIT_COMPANY:
         this.permissionsMap.delete(this.permission.DELETE_COMPANY);
         break;
       case this.permission.LIST_VEHICLE_ADMINISTRATION:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_VEHICLE);
         this.permissionsMap.delete(this.permission.DELETE_VEHICLE);
         break;
       case this.permission.CREATE_EDIT_VEHICLE:
         this.permissionsMap.delete(this.permission.DELETE_VEHICLE);
         break;       
       case this.permission.LIST_MAIN_EVENT_ADMINISTRATION:
         this.permissionsMap.delete(this.permission.CREATE_EDIT_MAIN_EVENT);
         this.permissionsMap.delete(this.permission.DELETE_MAIN_EVENT);
         break;
        case this.permission.CREATE_EDIT_MAIN_EVENT:
          this.permissionsMap.delete(this.permission.DELETE_MAIN_EVENT);
          break;
        case this.permission.LIST_OBSERVED_AREA_ADMINISTRATION:
          this.permissionsMap.delete(this.permission.CREATE_EDIT_OBSERVED_AREA);
          this.permissionsMap.delete(this.permission.DELETE_OBSERVED_AREA);
          break;
        case this.permission.CREATE_EDIT_OBSERVED_AREA:
          this.permissionsMap.delete(this.permission.DELETE_OBSERVED_AREA);         
          break;
        case this.permission.LIST_FORMS_ADMINISTRATION:
          this.permissionsMap.delete(this.permission.CREATE_EDIT_FORM);
          this.permissionsMap.delete(this.permission.DELETE_FORM);
          break;
        case this.permission.CREATE_EDIT_FORM:
          this.permissionsMap.delete(this.permission.DELETE_FORM);         
          break;
        case this.permission.LIST_POINTS_ADMINISTRATION:
          this.permissionsMap.delete(this.permission.CREATE_EDIT_POINT);
          this.permissionsMap.delete(this.permission.DELETE_POINT);
          break;
        case this.permission.CREATE_EDIT_POINT:          
          this.permissionsMap.delete(this.permission.DELETE_POINT);
          break;
        case this.permission.LIST_CHANELS:
          this.channelPermisionOptions.fields = [];
          break;
        case this.permission.LIST_REGISTRATION_GROUP:
          this.permissionsMap.delete(this.permission.EDIT_REGISTRATION_GROUP);
          this.permissionsMap.delete(this.permission.DELETE_REGISTRATION_GROUP);
          break;
        case this.permission.EDIT_REGISTRATION_GROUP:          
          this.permissionsMap.delete(this.permission.DELETE_REGISTRATION_GROUP);
          break;          
      }
    }
    this.view['permissions'] = this.mapToObject();    
  }

  /*** END METODOS DO HTML */

  /**METODOS ÚTEIS */
  setDualPermission(permission_one: Permission, permission_two: Permission, permissionOptions: PermissionOptions){
    if(this.permissionsMap.get(permission_one) &&  this.permissionsMap.get(permission_two)){
      this.permissionsMap.set(permission_one, permissionOptions);
      this.permissionsMap.set(permission_two, permissionOptions);
      this.view['permissions'][permission_one] = permissionOptions;
      this.view['permissions'][permission_two] = permissionOptions;
    }else if(this.permissionsMap.get(permission_one)){
      this.permissionsMap.set(permission_one, permissionOptions);
      this.view['permissions'][permission_one] = permissionOptions;
    }else if (permission_two){
      this.permissionsMap.set(permission_two, permissionOptions);
      this.view['permissions'][permission_two] = permissionOptions;
    }
  }

  removeAllOptions(map, option) {
    let listToDelete : any []= [];
    map.forEach(( permisionOptions,  key) => {
      if(key !== option)
          listToDelete.push(key);
    });
    listToDelete.forEach(e => {
      map.delete(e)
    });
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['name'] ||
        !this.view['userType'] ||
        (this.permissionsMap.get(this.permission.LIST_ALERTS)?.option==this.permisionRuleOption.SOME_FIELDS && this.permissionsMap.get(this.permission.LIST_ALERTS)?.fields.length<1) ||
        (this.permissionsMap.get(this.permission.LIST_CHANELS) && this.permissionsMap.get(this.permission.LIST_CHANELS)?.fields.length<1) ||
        (this.permissionsMap.get(this.permission.CHANGE_ANY_EVENT_FIELD)?.option === this.permisionRuleOption.SOME_FIELDS && this.permissionsMap.get(this.permission.CHANGE_ANY_EVENT_FIELD)?.fields.length<1) || 
        (this.permissionsMap.get(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE)?.option === this.permisionRuleOption.SOME_SOURCE && this.permissionsMap.get(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE)?.fields.length<1) ||        
        (this.permissionsMap.get(this.permission.CREATE_EDIT_USER)?.option==this.permisionRuleOption.SOME_PROFILE && this.permissionsMap.get(this.permission.CREATE_EDIT_USER)?.fields.length<1)) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    if (!this.view['userType']) names.push('Categoria');
    if (this.permissionsMap.get(this.permission.LIST_ALERTS)?.option==this.permisionRuleOption.SOME_FIELDS && this.permissionsMap.get(this.permission.LIST_ALERTS)?.fields.length<1) names.push('Campos na seção alerta');
    if((this.permissionsMap.get(this.permission.CHANGE_ANY_EVENT_FIELD)?.option === this.permisionRuleOption.SOME_FIELDS && this.permissionsMap.get(this.permission.CHANGE_ANY_EVENT_FIELD)?.fields.length<1)) names.push('Campos que podem ser alterados (eventos)');
    if((this.permissionsMap.get(this.permission.LIST_CHANELS) && this.permissionsMap.get(this.permission.LIST_CHANELS)?.fields.length<1)) names.push('Canais que participa o perfil'); 
    if((this.permissionsMap.get(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE)?.option === this.permisionRuleOption.SOME_SOURCE && this.permissionsMap.get(this.permission.CHANGE_EVENTS_FROM_ANY_SOURCE)?.fields.length<1)) names.push('Alterar somente eventos de origem');   
    if (this.permissionsMap.get(this.permission.CREATE_EDIT_USER)?.option==this.permisionRuleOption.SOME_PROFILE && this.permissionsMap.get(this.permission.CREATE_EDIT_USER)?.fields.length<1) names.push('Perfil na seção Administração de Usuários');
    return names;
  }

  /**Função que converte o map to string */
  mapToObject():{} {
    let object = {};

    this.permissionsMap.forEach((value, key) => {
      object[key] = value
    });    
    return object; 
  }

  enumToMap(type :any) : Map<string, string>{
    const map = new Map<string, string>();
    for(let element in type){
      map.set(element,type[element]);
    }   
    return map; 
  }

  initializeFields(){
    this.view = {};
    this.view['name'] = '';
    this.view['userType'] = '';
    this.permissionsMap.clear();
    this.disableAlertList = true; 
    this.disableProfileList = true;    

    this.alertPermissionOptions.fields = []; 
    this.eventPermissionOptions.fields = []; 
    this.userFields.fields = [];
    this.channelPermisionOptions.fields = [];
    
    this.verificationPermisionOptions.option = this.permisionRuleOption.ONLY_NOT_STARTED;
    this.patrolPermisionOptions.option = this.permisionRuleOption.ONLY_NOT_STARTED;
    this.profilePermisionOptions.option = this.permisionRuleOption.SOME_PROFILE;
  }

  /**END METODOS ÚTEIS */
}
