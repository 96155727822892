<div class="sipd-container">
    <div class="sipd-header">
      <div class="mat-title m-0">{{title}}</div><small matTooltip="Número de Registros Lidos">[ {{getListCount()}} ]</small>
      <div class="fill-space"></div>
      <div class="actions">
        <mat-form-field class="list-filter header-input" appearance="outline">
          <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
          <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
          <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
        </mat-form-field>

        <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
          <mat-icon class="material-icons-outlined">refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="onLocationManyClick()" 
          matTooltip="Mostrar no Mapa"
          [disabled]="this.selection.selected.length == 0" >
          <mat-icon class="material-icons-outlined">location_on</mat-icon>
        </button>
        <button mat-icon-button (click)="onCreateClick()"
          [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP)'
          matTooltip="Adicionar {{modelName}}">
          <mat-icon class="material-icons-outlined">add</mat-icon>
        </button>
        <button mat-icon-button (click)="onImportClick()"
          [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP) || !hasGeometry'
          matTooltip="Importar {{modelName}}">
          <mat-icon class="material-icons-outlined">file_upload</mat-icon>
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Abrir {{modelName}}(s) Selecionado(s)"
          [disabled]="this.selection.selected.length == 0"
          (click)="onEditManyClick(this.selection.selected)">
          <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        </button>
        <button mat-icon-button color="primary"
        matTooltip="Copiar {{modelName}} Selecionado
                    (Somente 1 {{modelName}} pode estar selecionado)"
        [disabled]="this.selection.selected.length != 1"
        (click)="onCopyClick(this.selection.selected.pop())"
        [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP)'>
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDeleteManyClick(this.selection.selected, $event)"
        matTooltip="Remover {{modelName}}(s) Selecionado(s)"
        [hidden]='!authorizationService.userHasPermission(permission.DELETE_REGISTRATION_GROUP)'
        [disabled]="this.selection.selected.length == 0" >
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="sipd-content bg-white">
      <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell class="txt-center fit-content"  *matHeaderCellDef >
            <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
            </mat-checkbox>
          </th>
          <td mat-cell class="txt-center fit-content" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="accent"
              (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
              [matTooltip]="checkboxTip(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Nome </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="geometry" *ngIf="hasGeometry">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Georreferenciado </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.geometry? 'Sim': 'Não'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
          (contextmenu)="openContextMenu($event, element)"
          [ngClass]="{ 'highlighted': selection.isSelected(row)}">
        </tr>
      </table>

      <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
      </div>

      <mat-menu #contextMenu="matMenu">
        <button mat-menu-item (click)="onEditClick(this.contextMenuSelectedItem, $event)">
          <mat-icon class="material-icons-outlined">folder_open</mat-icon><span>Abrir {{modelName}}</span>
        </button>
        <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.EDIT_REGISTRATION_GROUP)' (click)="onCopyClick(this.contextMenuSelectedItem)">
          <mat-icon class="material-icons-outlined">file_copy</mat-icon><span>Copiar {{modelName}}</span>
        </button>
        <button mat-menu-item [hidden]='!authorizationService.userHasPermission(permission.DELETE_REGISTRATION_GROUP)'
          (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.name, $event)">
          <mat-icon class="material-icons-outlined">delete</mat-icon><span>Remover {{modelName}}</span>
        </button>
        <button mat-menu-item (click)="onLocationClick(this.contextMenuSelectedItem)" [disabled]="!hasLocation(this.contextMenuSelectedItem)">
          <mat-icon class="material-icons-outlined">location_on</mat-icon>
          <span>Mostrar no Mapa</span>
        </button>
    </mat-menu>

    </div>
    <mat-divider></mat-divider>
    <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
  </div>

