import { Inject, Injectable, Component } from '@angular/core';
import * as GoldenLayout from 'golden-layout';
import { GlOnPopin,
         GlOnPopout,
         GlOnTab,
         GlOnHide,
         GlOnResize,
         GlOnUnload,
         GlOnClose,
         GlOnShow,
         GoldenLayoutComponent,
         GoldenLayoutComponentHost,
         GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { MAX_TAB_TITLE, PAGE_READY_PREFIX, FILL_DATA_PREFIX } from 'src/app/common/constants';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

const LANDING_PAGE = environment.LANDING_PAGE;
@ProfileClassToConsole()
@Injectable()
export class BaseGoldenPanel implements GlOnPopin, GlOnShow, GlOnPopout, GlOnTab, GlOnResize, GlOnUnload, GlOnHide, GlOnClose {

  protected goldenLayoutInstance: GoldenLayout;

  constructor(protected logger: NGXLogger,
              @Inject(GoldenLayoutComponentHost) protected goldenLayoutComponent: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected goldenLayoutContainer: GoldenLayout.Container) {
    this.goldenLayoutInstance = goldenLayoutComponent.getGoldenLayoutInstance();
    // Container é o DOM element, ele fica dentro de um ContentItem
    // goldenLayoutContainer.parent acessa o próprio ContentItem
  }

 /* MAIN MENU
 * - Registro e assinatura de evento 'itemDragged'
 * - Gerência do tipo de click: single ou dbl click
 * - Reset do layout para a página inicial
 * - this.goldenLayoutContainer não pode ser utilizado por métodos do main manu
 */

  glRegisterDragMainMenu(mainMenu: HTMLElement) {
    mainMenu.childNodes.forEach((btn: HTMLButtonElement) => {
      this.glRegisterDragMainButton(btn);
    });

    this.subscribeDragMainMenu();
  }

  glRegisterDragMainButton(btn: HTMLButtonElement) {
    if (btn.value) {
      const componentName: string = btn.value;
      const itemConfiguration: GoldenLayout.ComponentConfig = this.buildItemConfig(componentName);
      itemConfiguration.title = btn.title;
      this.goldenLayoutInstance.createDragSource(btn, itemConfiguration);
    }
  }

  private getMainMenuContainerById(id: string): GoldenLayout.ContentItem {
    // Chamado sempre da tela principal, então esse root é sempre o MainRoot
    let mainRoot: GoldenLayout.ContentItem = this.goldenLayoutInstance.root;
    let contentItems: GoldenLayout.ContentItem[] = mainRoot.getItemsById(id);
    if (contentItems && contentItems.length > 0)
      return contentItems[0];

    return null;
  }

  private subscribeDragMainMenu() {
    this.goldenLayoutInstance.on('itemDragged', (item) => {
      const contentItem: GoldenLayout.ContentItem = this.getMainMenuContainerById(item.id.toString());
      if (contentItem) {
        if (item.parent === undefined) {
          if (item.componentName === LANDING_PAGE) {
            throw new Error('Landing-page em uso');
          }
          contentItem.remove();
        }
      }
    });
  }

  private activateContentItem(contentItem: GoldenLayout.ContentItem, browserWindow: GoldenLayout.BrowserWindow) {
    if (contentItem) {
      if (contentItem.parent.isStack) {
        let mainRoot: GoldenLayout.ContentItem = this.goldenLayoutInstance.root;
        let maxStack: GoldenLayout.ContentItem = mainRoot.getItemsById('__glMaximised')[0];
        if (maxStack) {
          // maxStack.setActiveContentItem(contentItem); -- isso também não funciona
          // o GL cria uma stack temporária para mostrar os itens maximizados
          // E todos os itens anteriores ganham novas instancias com novos ids
          // Tenho que procurar o item dentro dessa nova stack
          for (let i=0; i<maxStack.contentItems.length; i++) {
            let item = maxStack.contentItems[i];
            if ((item.config as GoldenLayout.ComponentConfig).componentState.originalId == contentItem.id) {
              maxStack.setActiveContentItem(item);
            }
          }
        }
        else {
          contentItem.parent.setActiveContentItem(contentItem);
        }
      }

      if (browserWindow)
        browserWindow.getWindow().focus();

      this.tabTitleAnimation(contentItem);
    }
  }

  private getMainStack(mainRoot: GoldenLayout.ContentItem): GoldenLayout.ContentItem {
    return mainRoot.getItemsById('stack_ccpd')[0];
  }

  private recreateStack(): GoldenLayout.ComponentConfig {
    return {
      componentName:'ccpd_root',
      type: 'stack',
      id: 'stack_ccpd',
      isClosable: false,
    };
  }

  private addComponentToMainRoot(componentName: string, mainRoot: GoldenLayout.ContentItem, data?) {
    if(mainRoot.contentItems.length == 0) {
      // Se não achou a main stack é porque ela foi removida e precisa ser recriada
      mainRoot.addChild(this.recreateStack());
    }
    const itemConfiguration: GoldenLayout.ComponentConfig = this.buildItemConfig(componentName, data)
    let mainStack: GoldenLayout.ContentItem = this.getMainStack(mainRoot);
    if (mainStack) {
      mainStack.addChild(itemConfiguration);
    }
    else {
      // Se não achou a main stack é porque ela foi removida e precisa ser recriada
      // Mas esse caso, tem algum outro item na mainRoot
      // A mainRoot só pode conter um único item, então temos que mover o item para a nova main stack
      let oldItem: GoldenLayout.ContentItem = mainRoot.contentItems[0];
      if (oldItem.isStack) {
        oldItem.addId('stack_ccpd');
        oldItem.addChild(itemConfiguration);
      }
      else {
        mainRoot.removeChild(<GoldenLayout.Config>oldItem); // , false); se for usar o código addChild(oldItem);
        mainRoot.addChild(this.recreateStack());
        mainStack = this.getMainStack(mainRoot);
        mainStack.addChild(itemConfiguration); // Até aqui apenas acrescenta o novo elemento, e remove os elementos antigos
        // TODO scuri Isso não funciona bem. Fica uma aba vazia com a coluna ou a linha dentro.
        // mainStack.addChild(oldItem);
        // TODO scuri isso também não funciona, pois em geral as colunas/linhas são compostas por elementos dentro de outras stacks
        // if (oldItem.isColumn || oldItem.isRow) {
        //   oldItem.contentItems.forEach( (item: GoldenLayout.ContentItem) => {
        //     mainStack.addChild(item);
        //   });
        // }
      }
    }
  }

  private isEditComponent(componentName: string): boolean {
    return componentName.indexOf('-edit') > 0;
  }

  private getComponentInfo(componentName: string, mainRoot: GoldenLayout.ContentItem, data?) {
    let contentItem: GoldenLayout.ContentItem = null;
    let browserWindow: GoldenLayout.BrowserWindow = null;

    let id;
    // Se é um componente de edição
    if(this.isEditComponent(componentName)) {
      // Se é um novo ou uma cópia não precisa procurar, sempre cria um novo painel
      if(!data || !data.id || data.copy || data.options?.predicted) {
        return {contentItem: contentItem, browserWindow: browserWindow};
      }
      else {
        id = data.id;
      }
    }
    else {
      id = componentName + '-id'; // listagem, mensagem, rastreamento ou mapa
    }
    
    let contentItems: GoldenLayout.ContentItem[] = mainRoot.getItemsById(id);
    if (contentItems && contentItems.length > 0)
      contentItem = contentItems[0];
    else
    {
      this.goldenLayoutInstance.openPopouts.forEach(window => {
        contentItems = window.getGlInstance().root.getItemsById(id);
        if(contentItems && contentItems.length > 0) {
          if (contentItems && contentItems.length > 0) {
            contentItem = contentItems[0];
            browserWindow = window;
            return;
          }
        }
      });
    }  

    return {contentItem: contentItem, browserWindow: browserWindow};
  }

  glOpenContainerFromMainMenu(componentName: string) {
    // Chamado sempre da tela principal, então esse root é sempre o MainRoot
    let mainRoot: GoldenLayout.ContentItem = this.goldenLayoutInstance.root;
    let itemInfo = this.getComponentInfo(componentName, mainRoot);
    let contentItem: GoldenLayout.ContentItem = itemInfo.contentItem;
    if (contentItem) {
      let browserWindow: GoldenLayout.BrowserWindow = itemInfo.browserWindow;
      this.activateContentItem(contentItem, browserWindow);
    }
    else
      this.addComponentToMainRoot(componentName, mainRoot);
  }

  checkChildrenSaved(contentItems: GoldenLayout.ContentItem[]): boolean {
    let check: boolean = true;
    contentItems.forEach((c: GoldenLayout.ContentItem) => {
      if (c.isComponent){
        if(this.isEditComponent((c as any).componentName)) {
          // TODO scuri Isso é um hack, esses campos não são exportados pelo ngx-goldenlayout. Temos que tomar cuidado nas atualziações do mesmo.
          let cmp = (c as any).container.__ngComponent; 
          if (cmp.viewIsChanged()) check = false;
        }
      }
      else {
        if (!this.checkChildrenSaved(c.contentItems)) check = false;
      }
    });

    return check;
  }

  glResetLayout(): boolean {
    // Antes de fechar todas, verifica se tem alguma edição não salva
    // Chamado sempre da tela principal, então esse root é sempre o MainRoot
    let mainRoot: GoldenLayout.ContentItem = this.goldenLayoutInstance.root;
    let contentItems: GoldenLayout.ContentItem[] = mainRoot.contentItems;
    if (!this.checkChildrenSaved(contentItems)) {
      return false;
    }

    contentItems.forEach((c: GoldenLayout.ContentItem) => {
      c.remove();
    });

    this.goldenLayoutInstance.openPopouts.forEach( (window: GoldenLayout.BrowserWindow) => {
      contentItems = window.getGlInstance().root.contentItems;
      contentItems.forEach((c: GoldenLayout.ContentItem) => {
        c.remove();
      });
      window.close();
    });

    return true;
  }

  // Recebe o nome do layout e verifica se existe na pilha , caso sim é removido
  glRemoveLayout(nameLayout:string) {  
    let mainRoot: GoldenLayout.ContentItem = this.goldenLayoutInstance.root;
    let contentItems: GoldenLayout.ContentItem[] = mainRoot.contentItems;  
    contentItems.forEach((c: GoldenLayout.ContentItem) => {      
      c.contentItems.forEach((element:any) => {
        if(element.componentName == nameLayout){
          element.remove(); 
        }
      });
    });
  }

  glUpdateCreateId(id:string){
    // Atualiza o id temporário do painel para ser o id do modelo
    let contentItem: GoldenLayout.ContentItem = this.goldenLayoutContainer.parent;
    contentItem.addId(id);
    contentItem.id = id;
  }

  /**
   * Verifica se já tem uma instância do painel aberta ou se precisa criar um novo. 
   * Após, emite o evento de seleção para carregar a partir de um dado.
   * Chamado pelo ListComponent, CentralComponent e TrackingComponent
   * @param componentName Nome do componente
   * @param data Dados passados para o painel aberto
   */
  glOpenContainer(componentName: string, data: any){
    if (this.goldenLayoutInstance.isSubWindow) {
      // This layout is a popout, notify the main AppComponent
      this.glEmitEvent('OPEN_CONTAINER', {componentName: componentName, data: data});
      return;
    }

    // Chamado sempre da tela principal, então esse root é sempre o MainRoot
    let mainRoot: GoldenLayout.ContentItem = this.goldenLayoutInstance.root;
    let itemInfo = this.getComponentInfo(componentName, mainRoot, data);
    let contentItem: GoldenLayout.ContentItem = itemInfo.contentItem;
    // Se exite, ativa e preenche os dados
    if (contentItem) {
      let browserWindow: GoldenLayout.BrowserWindow = itemInfo.browserWindow;
      this.activateContentItem(contentItem, browserWindow);
      this.glEmitEvent(FILL_DATA_PREFIX + componentName, data);
    }
    // Se Não existe então cria novo
    else {
      this.addComponentToMainRoot(componentName, mainRoot, data);

      let pageReadyCallback = function() {
        // Emitido pelo componente para avisar que está pronto para receber dados
        this.logger.debug('BaseGoldenPanel.OnPageReady() - ' + componentName);

        // Notifica o painel para preencher dados a partir do data
        this.glEmitEvent(FILL_DATA_PREFIX + componentName, data);

        this.glUnSubscribeEvent(PAGE_READY_PREFIX + componentName, pageReadyCallback); // Ocorre apenas 1 vez
      }

      this.glSubscribeEvent(PAGE_READY_PREFIX + componentName, pageReadyCallback);
    }
  }

  private buildItemConfig(componentName: string, data?): GoldenLayout.ComponentConfig {
    let id = data && data.id? data.id: null;
    if(!id) {
      if (this.isEditComponent(componentName)) {
        // Se não tem id, mas é um componente de edição então é uma criação
        // gero um id temporário para o painel
        id = data.creation_id = uuidv4();  // Será sobrescrito ao salvar
      }
      else {
        id = componentName + '-id';
      }
    }
    else{
      if (data.copy || data.options?.predicted) {
        // Se é uma cópia gero um id temporário para o painel
        id = data.creation_id = uuidv4();  // Será sobrescrito ao salvar
      }
    }

    const isClosable = (id == 'central-id')? false: true;
    
    return {
      componentName: componentName,
      type: 'component',
      title: componentName, // Será sobrescrito posteriormente
      id: id,
      isClosable: isClosable,
      componentState: { maximisedItemId: null }
    };
  }

  glEmitEvent(event, data) {
    this.logger.debug(`BaseGoldenPanel.glEmitEvent(${event}, ${data})`);
    this.goldenLayoutInstance.eventHub.emit(event, data);
  }

  glSubscribeEvent(eventName: string, callback: Function) {
    this.goldenLayoutInstance.eventHub.on(eventName, callback, this);
  }

  glUnSubscribeEvent(eventName: string, callback?: Function) { // TODO scuri Acho que callback aqui não é mais necessária, já que usamos o context sempre
    // Unsubscribes either all listeners if just an eventName is provided, 
    // just a specific callback if invoked with eventName and callback or 
    // just a specific context if invoked with eventName and context
    this.goldenLayoutInstance.eventHub.off(eventName, callback, this);
  }

  glContainerClose() {
    this.goldenLayoutContainer.close();
  }

  glContainerWidth() {
    return this.goldenLayoutContainer.width;
  }

  glContainerHeight() {
    return this.goldenLayoutContainer.height;
  }

  glUpdateTabTitle(tabTitle: string) {
    let name: string = tabTitle;
    if (name.length > MAX_TAB_TITLE) name = name.substr(0, MAX_TAB_TITLE) + "...";

    let contentItem: GoldenLayout.ContentItem = this.goldenLayoutContainer.parent;
    contentItem.setTitle(name);
  }

  private tabTitleAnimation(contentItem: GoldenLayout.ContentItem) {
    contentItem.setTitle('<b><font color="red">' + contentItem.config.title + '</font></b>');
    const elem = contentItem.layoutManager.container;
    elem.animate({ display: 'none' }, '3000', function() {
      elem.animate({ display: 'none' }, '1500');
      if (contentItem.config.title.startsWith('<b><font'))
        contentItem.setTitle(contentItem.config.title.slice(21, contentItem.config.title.length-11));
    });
  }

  /** Hooks */

  glOnPopin() {
    // Esse evento ocorre depois que o componente é restaurado
    this.logger.debug('BaseGoldenPanel.glOnPopin()');
  }

  glOnHide(): void {
    this.logger.debug('BaseGoldenPanel.glOnHide()');
  }

  glOnPopout() {
    // Esse evento ocorre antes do componente ser destacado
    this.logger.debug('BaseGoldenPanel.glOnPopout()');
  }

  // Definido como async pelo GL
  async glOnClose() {
    this.logger.debug('BaseGoldenPanel.glOnClose()');
  }

  glOnShow() {
    this.logger.debug('BaseGoldenPanel.glOnShow()');
  }

  glOnResize() {
    this.logger.debug('BaseGoldenPanel.glOnResize()');
  }

  glOnTab() {
    this.logger.debug('BaseGoldenPanel.glOnTab()');
  }

  glOnUnload() {
    this.logger.debug('BaseGoldenPanel.glOnUnload()');
  }
}
