<form class="edit-form h-100">
  <div class="sipd-container">
    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVA]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button 
          matTooltip="Habilitar Edição"
          [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar"
          (click)="onCopyClick()"
          [disabled]="!id"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PLACEMENT)'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()"
          matTooltip="Remover"
          [hidden]='!authorizationService.userHasPermission(permission.DELETE_PLACEMENT)'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!canRevert()" (click)="onRevertClick()" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PLACEMENT)' matTooltip="Desfazer Modificações">
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button (click)="onSaveClick($event)" [disabled]="!canSave()" matTooltip="Salvar" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_PLACEMENT)'>
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
        <mat-form-field  appearance="outline" fxFlex>
          <mat-label>Gerência</mat-label>
          <input [readonly]="readOnly" cdkFocusInitial name="gs" matInput placeholder="Gerência" [(ngModel)]="view['gs']" required>
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Regional</mat-label>
          <input [readonly]="readOnly" name="regional" matInput placeholder="Regional" [(ngModel)]="view['regional']">
        </mat-form-field>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>

      </div>
    </div>
  </div>

</form>