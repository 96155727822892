<div class="sipd-container">

  <div class="sipd-header">
    <button mat-icon-button color="accent" (click)="sidenav.toggle(); sidenavToggle(sidenav.opened)" [hidden]="insideEventEdition">
      <mat-icon *ngIf="!sidenav.opened">filter_list</mat-icon>
      <mat-icon *ngIf="sidenav.opened">arrow_left</mat-icon>
    </button>
    <div class="mat-title m-0" [hidden]="insideEventEdition">{{title}}</div ><small matTooltip="Número de Registros Filtrados" [hidden]="insideEventEdition">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions" [hidden]="insideEventEdition">
      <div>
        <mat-form-field class="list-filter header-input" appearance="outline">
          <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
          <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
          <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
        </mat-form-field>
      </div>
    </div>

    <div class="actions">
      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button [hidden]='isHiddenButtonCreateEditEvent || insideEventEdition' (click)="onCreateClick()"
        matTooltip="Adicionar Evento">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Abrir Evento(s) Selecionado(s)"
        [disabled]="selection.selected.length == 0"
        (click)="onEditManyClick(selection.selected)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Copiar Evento Selecionado
                                          (Somente 1 Evento pode estar selecionado)" 
        [hidden]='isHiddenButtonCreateEditEvent || insideEventEdition'
        [disabled]="selection.selected.length != 1 || !canCopy(selection.selected[0])" (click)="onCopyClick(selection.selected.pop())">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button [disabled]="denyRemoveMany" (click)="onDeleteManyClick(selection.selected, $event)"
        [hidden]='isHiddenButtonDeleteEvent || insideEventEdition'
        matTooltip="Remover Eventos Selecionados">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button (click)="onExportManyClick()" matTooltip="Exportar Evento Selecionado ou
                    Exportar Lista de Eventos" [hidden]='insideEventEdition'>
        <mat-icon class="material-icons-outlined" class="material-icons-outlined">save_alt</mat-icon>
      </button>
      <button mat-icon-button [disabled]="denyArchiving" (click)="onArchiveManyClick()"
        [matTooltip]="archiveTooltipText()" [hidden]="isHiddenButtonArchiveForUser || insideEventEdition">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar no Mapa os Eventos Selecionados"
        [disabled]="selection.selected.length == 0"
        [hidden]='insideEventEdition'
        (click)="onLocationManyClick(mapEvents.MANY_EVENT_LOCATION)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button (click)="onCreateVerificationClick(selection.selected[0])" [disabled]="selection.selected.length != 1" 
        [hidden]="isOperationCreateButtonHiddenForUser() || insideEventEdition" 
        matTooltip="Adicionar Verificação
                    (Somente 1 Evento pode estar selecionado)">
        <mat-icon class="material-icons-outlined">add_box</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Vincular Evento"
        [hidden]='!insideEventEdition'
        [disabled]="!canLinkEvent() || editReadOnly"
        (click)="onLinkEventClick()"
        >
        <mat-icon class="material-icons-outlined">add_link</mat-icon>
      </button>      
      <button mat-icon-button color="primary"
        matTooltip="Desvincular de Eventos"
        [hidden]='!insideEventEdition'
        (click)="onUnLinkEventManyClick()"
        [disabled]="selection.selected.length == 0 || editReadOnly">
        <mat-icon class="material-icons-outlined">link_off</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="sipd-content bg-white">
    <mat-sidenav-container class="h-100">
      <mat-sidenav #sidenav mode="side" closed style="width: 320px;">
        <mat-accordion multi displayMode="flat" class="map-panel m-0 p-0">
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="mat-body-2">Filtro</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-event-filter [inputFilterModel]="filterModel" (filtered)="updateDataSource($event)"></app-event-filter>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-sidenav>
      <mat-sidenav-content>
        <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select" sticky>
            <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
              </mat-checkbox>
            </th>
            <td mat-cell class="txt-center fit-content" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" color="accent"
                (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
                [matTooltip]="checkboxTip(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="validation">
            <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header>
              Validação
            </th>
            <td mat-cell class="txt-center fit-content" *matCellDef="let element">
              <mat-icon class="align-middle" [ngClass]="[(element?.validation == 'COMPLETE')? 'txt-accent':(element?.validation == 'INCOMPLETE')? 'txt-warn':'text-emphasis-3']">
                check_circle_outline</mat-icon>
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="identifier">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell class="nowrap" *matCellDef="let element">
              {{element?.identifier}}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell class="nowrap" *matCellDef="let element">
              <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip [class]="element.status? element.status + ' text-white': null " class="justify-content-center"
                  [disabled]="element.status ? null:'disabled'">
                  {{ getEventStatus(element) }}
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- Coluna ocorrência -->
          <ng-container matColumnDef="result.occurrence">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Ocorrência </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{
              element?.result?.occurrence ? resultOccurrenceDescription[element.result.occurrence] : '' }} </td>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.date | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <!-- Analyst Column -->
          <ng-container matColumnDef="analyst.name">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Analista </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.analyst | toView }} </td>
          </ng-container>

          <!-- Channel Column -->
          <ng-container matColumnDef="communicationChannel">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Canal de Comunicação </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.communicationChannel }} </td>
          </ng-container>

          <!-- Source Column -->
          <ng-container matColumnDef="source">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Origem </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.source }} </td>
          </ng-container>

          <!-- Duto Column -->
          <ng-container matColumnDef="duct">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Duto </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.duct}} </td>
          </ng-container>

          <!-- Valve Column -->
          <ng-container matColumnDef="valve">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Válvula </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.valve}} </td>
          </ng-container>

          <!-- KM Column -->
          <ng-container matColumnDef="targetPointKM">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> KM </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.stretchStartKM && element?.stretchEndKM ?
              element?.stretchStartKM + " - " + element?.stretchEndKM : element?.targetPointKM}} </td>
          </ng-container>

          <!-- associatedEvent Column -->
          <ng-container matColumnDef="associatedIdentifier"  *ngIf="!insideEventEdition">
            <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Evento Relacionado </th>
            <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.associatedIdentifier}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
            (contextmenu)="openContextMenu($event, element)"
            [ngClass]="{ 'highlighted': selection.isSelected(row) }">
          </tr>

        </table>

        <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
          [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
        </div>

        <mat-menu #contextMenu="matMenu">
          <button mat-menu-item
            (click)="onEditClick(contextMenuSelectedItem, $event)">
            <mat-icon class="material-icons-outlined">folder_open</mat-icon>
            <span>Abrir Evento</span>
          </button>
          <button mat-menu-item [hidden]='isHiddenButtonCreateEditEvent'
            (click)="onCopyClick(contextMenuSelectedItem)" [disabled]="!canCopy(contextMenuSelectedItem)" [hidden]='insideEventEdition'>
            <mat-icon class="material-icons-outlined">file_copy</mat-icon>
            <span>Copiar Evento</span>
          </button>
          <button mat-menu-item [hidden]='isHiddenButtonDeleteEvent || insideEventEdition'
            [disabled]="!canDelete(contextMenuSelectedItem)"
            (click)="onDeleteClick(contextMenuSelectedItem, contextMenuSelectedItem.identifier, $event)">
            <mat-icon class="material-icons-outlined">delete</mat-icon>
            <span>Remover Evento</span>
          </button>
          <button mat-menu-item (click)="onExportClick(contextMenuSelectedItem, $event)" [hidden]='insideEventEdition' matTooltip="Salvar">
            <mat-icon class="material-icons-outlined">save_alt</mat-icon>
            <span>Exportar Evento</span>
          </button>
          <button mat-menu-item [disabled]="!canArchive(contextMenuSelectedItem)"
            (click)="onArchiveClick(contextMenuSelectedItem)" [hidden]="isHiddenButtonArchiveForUser"
            [hidden]='insideEventEdition'>
            <mat-icon class="material-icons-outlined">archive</mat-icon>
            <span>{{ archiveButtonLabel }}</span>
          </button>
          <button mat-menu-item color="primary" (click)="onLocationClick(contextMenuSelectedItem, mapEvents.EVENT_LOCATION)" [disabled]="!hasLocation(contextMenuSelectedItem)"
            [hidden]='insideEventEdition'>
            <mat-icon class="material-icons-outlined">location_on</mat-icon><span>Mostrar no Mapa</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [hidden]='isHiddenButtonCreateEditEvent'
            (click)="onCreateVerificationClick(contextMenuSelectedItem)"
            [hidden]='insideEventEdition' matTooltip="Adiciona uma nova verificação a esse evento">
            <mat-icon class="material-icons-outlined">add_box</mat-icon>
            <span>Adicionar Verificação</span>
          </button>
          <button mat-menu-item [hidden]='isHiddenButtonCreateEditEvent || insideEventEdition'
            (click)="onLinkEventManyClick(contextMenuSelectedItem)" matTooltip="Vincula vários eventos a esse evento">
            <mat-icon class="material-icons-outlined">add_link</mat-icon>
            <span>Vincular Eventos...</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [hidden]='isHiddenButtonCreateEditEvent || insideEventEdition'
            (click)="onAssociateEvent(contextMenuSelectedItem)" matTooltip="Vincula esse evento a um outro evento">
            <mat-icon class="material-icons-outlined">link</mat-icon>
            <span>Vincular a Evento...</span>
          </button>
          <button mat-menu-item [hidden]='isHiddenButtonCreateEditEvent'
            [disabled]="!canUnLinkEvent(contextMenuSelectedItem)"
            (click)="onUnLinkEventClick(contextMenuSelectedItem)" matTooltip="Desvincula esse evento do evento relacionado">
            <mat-icon class="material-icons-outlined">link_off</mat-icon>
            <span>Desvincular de Evento</span>
          </button>
        </mat-menu>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
