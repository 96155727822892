import { GeoModel } from './geo.model';
import { environment } from 'src/environments/environment';

export class DcModel extends GeoModel {
  duct:         string; // Nome do duto
  occurrence:   string;
  date:         string;
  time:         string;
  km:           string;
  city:         string;
  year:         string;
  place:        string;
  identificationMode: string;

  public static getPopupContent(feature, search: boolean = false): string{
    const dc = feature.properties as DcModel;

    // Obs: Não tem nome
    
    let states: string = dc.states ? dc.states.toString() : '';
    let date: string = dc.date ? dc.date : '';
    let time: string = dc.time ? dc.time : '';
    let occurrence: string = dc.occurrence ? dc.occurrence : '';
    let place: string = dc.place ? dc.place : '';
    let duct: string = dc.duct ? dc.duct : '';
    let identificationMode: string = dc.identificationMode ? dc.identificationMode : '';
    let latLong: string = GeoModel.getLatLong(dc);
    let km: string = dc.km ? dc.km : '';
    let city: string = dc.city ? dc.city : '';
    let contents:string = `<h5 style="text-align: center">DC</h5>
                            <h6 style="text-align: center"><b>${ dc.name }</b></h6>
                            <div><b>Local:</b> ${place}</div>
                            <div><b>Ocorrência:</b> ${occurrence} </div>
                            <div><b>Modo de Identificação:</b> ${identificationMode} </div>
                            <div><b>Data/Hora:</b> ${date} ${time}</div>
                            <div><b>Duto/Trecho:</b> ${duct} </div>
                            <div><b>KM:</b> ${km} </div>
                            <div><b>Cidade:</b> ${city} </div>
                            <div><b>UF(s):</b> ${states} </div>
                            <div><b>Lat, Long:</b> ${!search? latLong: ''} </div>
                            `;

    if (!environment.showPopupLayerTables || dc.description == null || dc.description === "") {                              
      return contents;
    }
    else {
      return contents + "<hr/>" + dc.description
    }
  }

  public static updateName(dc: DcModel) {
    if (!dc.name) {
      let desc: Array<string> = dc.description.split("<br>");
      dc.name = desc[0];
      if (desc[1] !== undefined) {
        dc.name +=  ", " + desc[1];
      }
    }
  }
}
