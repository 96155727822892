import {EntityModel} from './entity.model';
import { CompanyModel } from './company.model';

export class ChannelModel extends EntityModel {
  // Depende de ChannelType:  ReferencedId / ReferencedName
  // SYNERGY = PlacementId / PlacementName          (ChannelName="Sinergia " + ReferencedName
  // PD_MOBILE = PatrolTeamId / PlacementName       (ChannelName=PatrolTeamName)
  // PD_TECHNICAL = PatrolTeamId / PlacementName    (ChannelName=PatrolTeamName)
  // CCPD = UserId / OriginPlacementName            (ChannelName=UserName) - Não inclui profissionais
  // CNCL = null / "CNCL"                           (ChannelName=ReferencedName)
  // MANAGERIAL = null / "Gerencial"                (ChannelName=ReferencedName)
  // CORE = null / "Núcleo"  (CCPD Interno)         (ChannelName=ReferencedName)
  name:           string;
  type:           string;
  referencedId:   string;
  referencedName: string;
  // Só é usado por PD_MOBILE, PD_TECHNICAL e CCPD
  company:        CompanyModel;

  // O atributo abaixo somente são usados no front-end
  lastMessageReceivedTimestamp: number;
  newMessagesCount: number;
}
