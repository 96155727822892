import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patrol-repetition-edit-dialog',
  templateUrl: './patrol-repetition-edit-dialog.component.html',
  styleUrls: ['./patrol-repetition-edit-dialog.component.scss']
})
export class PatrolRepetitionEditDialogComponent {

  editionOptions: any = 'justThisPatrol';
  justThisPatrolRadioButton = 'justThisPatrol';
  fromThisPatrolRadioButton = 'fromThisPatrol';
  
  constructor(public dialogRef: MatDialogRef<PatrolRepetitionEditDialogComponent>,
              protected confirmDialog: MatDialog, 
              @Inject (MAT_DIALOG_DATA) public predicted: boolean) {
  }

  returnOption(){
    if(this.editionOptions === 'justThisPatrol') {
      this.dialogRef.close('justThisPatrol');
    }
    else if(this.editionOptions === 'fromThisPatrol') {
      this.dialogRef.close('fromThisPatrol');
    }
    else {
      this.dialogRef.close(false);
    }
  }

  cancel(){
    this.dialogRef.close(false);
  }
}
