
<div mat-dialog-title>
  <span>Dados do Rastro</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div class="sipd-container">
  <div class="sipd-header">
    <span class="mat-title m-0">Rastro de "{{ data.title }}"</span>
    <mat-form-field class="list-filter header-input" appearance="outline" style="margin-left: 300px;">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
    </mat-form-field>
  </div>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content class="p-0">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100 mb-5">
      <!-- Data/Hora -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Data/Hora </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{getTimestamp(element?.timestamp)}} </td>
      </ng-container>

      <!-- Data/Hora Recebido -->
      <ng-container matColumnDef="receivedTimestamp">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Data/Hora Recebido</th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{getTimestamp(element?.receivedTimestamp)}} </td>
      </ng-container>

      <!-- Lat,Long -->
      <ng-container matColumnDef="latLong">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Lat, Long </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{getLatLong(element)}} </td>
      </ng-container>

      <!-- OperationIdentifier -->
      <ng-container matColumnDef="OperationIdentifier" *ngIf="data.showOperation">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Operação </th>
        <td mat-cell class="ellipsis nowrap" style="max-width: 200px;" *matCellDef="let element"> {{ element.operationType ? operationTypeDescription[element.operationType]: '' }} - {{element?.operationIdentifier}} </td>
      </ng-container>

      <!-- OperationStatus -->
      <ng-container matColumnDef="OperationStatus" *ngIf="data.showOperation">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell class="ellipsis nowrap" style="max-width: 200px;" *matCellDef="let element"> {{operationStatusDescription[element?.operationStatus]}} </td>
      </ng-container>

      <!-- Motor -->
      <ng-container matColumnDef="motor" *ngIf="data.isVehicle">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Motor </th>
        <td mat-cell class="ellipsis nowrap" style="max-width: 200px;" *matCellDef="let element"> {{element?.motor}} </td>
      </ng-container>

      <!-- Movimento -->
      <ng-container matColumnDef="movimento" *ngIf="data.isVehicle">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Movimento </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.movimento}} </td>
      </ng-container>

      <!-- Velocidade  -->
      <ng-container matColumnDef="velocidade" *ngIf="data.isVehicle">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Velocidade  </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.velocidade}} </td>
      </ng-container>

      <!-- GPS -->
      <ng-container matColumnDef="gps" *ngIf="data.isVehicle">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> GPS </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.gps}} </td>
      </ng-container>

      <!-- Precisão  -->
      <ng-container matColumnDef="precisao" *ngIf="data.isVehicle">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Precisão </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.precisao }} </td>
      </ng-container>

      <!-- Direção -->
      <ng-container matColumnDef="direcao" *ngIf="data.isVehicle">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Direção </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.direcao}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element">
      </tr>
    </table>
</div>
<mat-divider></mat-divider>
<mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onExportClick()">Exportar</button>
  <button mat-raised-button mat-dialog-close> Fechar </button>
</mat-dialog-actions>
