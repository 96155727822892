import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {EntityService} from './entity.service';
import {environment} from '../../../environments/environment';

import {EntityModel} from '../../model/entity.model';
import { Observable, throwError } from 'rxjs';
import { FilterModel } from '../../general/filter-component/filter.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelUsersService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/channel-users`);
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: FilterModel): Observable<T> {

    let params: HttpParams = new HttpParams();

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  protected handleError(error: HttpErrorResponse | any) {    
    return throwError(error);
  }
}
