import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Observable } from 'rxjs';
import { CommonModel } from 'src/app/model/common.model';
import { EntityModel } from 'src/app/model/entity.model';
import { EntityService } from './entity.service';
import { EventStatus, OperationStatus, OperationType } from 'src/app/model/enums.enum';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService extends EntityService{

  constructor(logger: NGXLogger,
              http:   HttpClient,
              apiUrl: string) {
    super(logger, http, apiUrl);
  }

  /**
   * Método que chama a API para arquivamento dos models
   * @param records
   * @returns
   */
  public archive(records: EntityModel[]){
    const observables: Observable<Object>[] = [];

    records.forEach( (record: CommonModel) => {
      if(record.archived == null){ // Se o model ainda não tem a flag ARCHIVED
        record.archived = true;
      }else{
        record.archived = !record.archived;
      }

      //se o flag "archived" foi mudado para true, modifica o status da operação
      if(record.archived){
        this.changeOperationStatus(record);
      }

      observables.push(this.editRecord(record));
    });

    return forkJoin(observables);
  }
  
  changeOperationStatus(record){
    if(record?.type && (record.type === OperationType.PATROL || record.type === OperationType.EVENT_VERIFICATION)){ // ronda ou verificação
      record.status = OperationStatus.FINISHED;
    } else{ // Evento
      record.status  = EventStatus.FINALIZED;
    }
  }
}
