import { BehaviorSubject, Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { EntityService } from '../service/model/entity.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../general/confirmation-dialog/confirmation-dialog.component';
import { EntityModel } from '../model/entity.model';
import { AfterViewInit, OnDestroy, OnInit, Directive, Inject, ViewChild } from '@angular/core';
import { UserModel } from '../model/user.model';
import { StorageService }  from '../service/storage-service';
import { KeyValue } from '@angular/common';
import { PAGE_READY_PREFIX, FILL_DATA_PREFIX, SAVE_DATA_PREFIX, DELETE_DATA_PREFIX, MOUSE_ENTER_TIME, MAP_PAGE, UPDATE_DATA_PREFIX } from 'src/app/common/constants';

import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { BaseGoldenPanel } from './base-golden-panel/base-golden-panel';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from '../service/authorization/authorization.service';

import * as diff from 'deep-diff';
import { MatMenuTrigger } from '@angular/material/menu';
import { MapEvents, Permission } from '../model/enums.enum';
import { first } from 'rxjs/operators';
import { LoadingListService } from '../service/loading/loading-list.service';
import { ProfileModel } from '../model/profile.model';
import { ProfileClassToConsole } from '../common/profile-class.decorator';
import { AttachmentModel } from '../model/attachment.model';
import { saveAs } from 'file-saver/dist/FileSaver';
import LanguageUtils from '../service/util/language-utils';
import DateUtils from '../service/util/date-utils';

@ProfileClassToConsole()
@Directive()
export abstract class EditComponent extends BaseGoldenPanel implements OnDestroy, OnInit, AfterViewInit {

  loggedUser: UserModel;
  loggedUserProfile :ProfileModel; 

  /** Identificador (ObjectId) do objeto sendo criado/editado nessa instância de componente.
   * Esse atributo se faz necessário porque na construção da tela o identificador é passado
   * para então o modelo ser carregado do serviço (vide ngOnInit dessa directive).
   */
  id: string = '';
  model: EntityModel;
  backupModel: EntityModel; // Usado para backup ao salvar
  view = {};
  initialView = {};
  /* Booleano para indicar que a tela esta aguardando a resposta do servidor impedindo que a ação de salvar seja pedida em duplicidade */
  isSaving: boolean = false;
  timeOutCanSave;
  public isBusySaveConfirm: boolean = false;
  permission = Permission;
  /**booleano para indicar se esta sendo feito copia de um modelo */
  copy : boolean = false;
  updatingModel: boolean = false;
   
  readOnly: boolean = true;  

  // Context Menu
  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger, static: true }) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuSelectedItem; // Leave t as any, for now

  mapEvents = MapEvents;

  loadingListService: LoadingListService = new LoadingListService();

  private _saveFinished: BehaviorSubject<boolean>;
  public readonly saveFinished$: Observable<boolean>;

  constructor(public logger:                NGXLogger,
              protected entityService:      EntityService,
              protected dialog:             MatDialog,
              public modelName:             string,
              public title:                 string,
              protected storageService:     StorageService,
              public componentName:         string,
              public tabTitle:              string,
              public toastr:                ToastrService,
              public authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, goldenLayout, container);
    this.loggedUser = this.storageService.getLocalUser();
    this.loggedUserProfile = this.storageService.getLocalProfile();

    this._saveFinished = new BehaviorSubject(false);
    this.saveFinished$ = this._saveFinished.asObservable();
    logger.debug('EditComponent.constructor()');
  }

  ngOnInit() {
    this.logger.debug('EditComponent.ngOnInit()');

    this.glUpdateTabTitle(this.modelName);

    this.subscribeToDataEvents();

    if (this.hasEditPopoutData()) {
      this.logger.debug("EditComponent-hasEditPopoutData");
      const popout = this.getEditPopoutData();
      // Don't clear popout data just yet, we still need it to restore the view
      this.openModel(popout.id, null, false, null);
    }

    this.checkPermissions();
  }

  ngOnDestroy() {
    this.logger.debug('EditComponent.ngOnDestroy()');
    this.id = null;

    this.glUnSubscribeEvent(FILL_DATA_PREFIX + this.componentName, this.fillDataCallback);
    this.glUnSubscribeEvent(DELETE_DATA_PREFIX + this.componentName, this.onDeleteData);
    this.glUnSubscribeEvent(UPDATE_DATA_PREFIX + this.componentName, this.onModelUpdate);

    this.loadingListService.destroy();
  }

  glUpdateTabTitle(tabTitle: string) {
    this.tabTitle = tabTitle;
    super.glUpdateTabTitle(tabTitle);
  }

  subscribeToDataEvents(){
    this.glSubscribeEvent(FILL_DATA_PREFIX + this.componentName, this.fillDataCallback);
    this.glSubscribeEvent(UPDATE_DATA_PREFIX + this.componentName, this.onModelUpdate);
    this.glSubscribeEvent(DELETE_DATA_PREFIX + this.componentName, this.onDeleteData);
  }

  fillDataCallback(data){
    // Painel não existia, e foi criado vazio
    // Preencher os dados do painel por dados novos baseados em modelId
    // Podem ser dados existentes ou novos

    // Quando a mensagem de filldata-xxx-edit é enviada para o componente xxx, todos os paineis desse componente recebem a mensagem
    // Então temos que garantir que apenas o painel certo processe a mensagem

    // Se for criação não tem o id no painel, tem um texto genérico que termina com '-edit-id'
    // Se não for criação, é edição, e o painel já possui o id nele
    let id = this.goldenLayoutContainer.parent.id;
    if((data.id == id && !data.copy) || data.creation_id == id) {
      this.logger.debug("EditComponent-OnFillData data.id=" + data.id);
      this.openModel(data.id, data.model, data.copy, data.options);
    }
  }

  onDeleteData(data){
    if (data.id == this.id) {
      this.logger.debug('EditComponent.onDeleteData() id:', this.id);
      this.restoreInitialView();
      this.glContainerClose();
    }
  }

  private getObjectName(value): string{
    if (value.name) return value.name;
    if (value.plate) return value.plate; //  Veículos
    if (value.label) return value.label; // Perguntas e respostas de formulários
    if (value.identifier) return value.identifier; // Operações e Eventos
    return value.constructor? 'objeto do tipo ' + value.constructor.name: 'objeto sem nome';
  }

  private getDiffStr(key, value, index?): string {
    if (!value) value = '';
    if (key == 'profileId') return ''
    if (LanguageUtils.languageMap[key]) key = LanguageUtils.languageMap[key];
    if (typeof value == 'object') value = this.getObjectName(value);
    else {
      if (LanguageUtils.languageMap[value]) value = LanguageUtils.languageMap[value];
      else if (DateUtils.isTimestamp(value)) value = DateUtils.timestampToStringInMinutes(value);
    }
    
    if (index) return key + "[" + index + "]" + ": " + value + "\n";
    else return key + ": " + value + "\n";
  }

  private getModelChangesMsg(originalModel, updatedModel): string{
    var differences = diff.diff(originalModel, updatedModel);
    if (!differences) return;

    let changes: string = '--- Dados Novos ---\n';

    let pathChecked = {};

    differences.forEach( diff => {
      let key = diff.path[0];

      if (diff.path.length > 1) {
        if (pathChecked[key]) return;
        pathChecked[key] = true;
      }

      switch(diff.kind){
        case 'N':{
          changes += this.getDiffStr(key, updatedModel[key]);
          break;
        }
        case 'D':{
          changes += this.getDiffStr(key, null);
          break;
        }
        case 'E':{
          changes += this.getDiffStr(key, updatedModel[key]);
          break;
        }
        default:{ // 'A'
          let item = diff.item;
          switch (item.kind){
            case 'N':{
              changes += this.getDiffStr(key, item.rhs, diff.index);
              break;
            }
            case 'D':{
              changes += this.getDiffStr(key, null, diff.index);
              break;
            }
            case 'E':{
              changes += this.getDiffStr(key, item.rhs, diff.index);
              break;
            }
          }
        }
      }
    });

    return changes;
  }

  replaceModelViewDateData(data, key){
    this.model[key] = data[key];

    if (this.model[key]) {
      this.view[key] = DateUtils.timestampToStringInMinutes(this.model[key]);
    }
    else {
      this.view[key] = this.model[key];
    }

    this.updateInitialView(key);
  }

  replaceModelViewData(data, key){
    this.model[key] = data[key];
    this.view[key] = data[key];
    this.updateInitialView(key);
  }

  // Para ser sobrescrita
  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário.
    // Vai modificar pontualmente model, view e initialView
    // Necessário para evitar que viewIsChanged detecte mudanças que não afeta a edição
  }

  onModelUpdate(data, checkUpdate: boolean = true){
    // Emitido quando o modelo foi atualizado do backend, a lista ouve e repassa para a edição
    if (data.id == this.id) {
      this.logger.debug("EditComponent.OnModelUpdate-" + this.componentName);

      if (this.updatingModel) {
        if (checkUpdate) {
          setTimeout(() => {
            this.onModelUpdate(data, false);
          }, 3000); // 3s
          return;
        }
        else {
          return;  // Depois de uma tentativa aborta a atualização
        }
      }

      this.updatingModel = true;

      this.replaceRunTimeData(data);

      if (this.viewIsChanged()) {
        // Se tem campos modificados, verifica se tem algo modificado no modelo
        let diffMsg = this.getModelChangesMsg(this.model, data);
        if (!diffMsg){
          // Nenhuma mudança detectada. Foram apenas mudanças em Run Time, não precisa atualizar o resto.
          this.updatingModel = false;
          return;
        }

        let dlg = this.dialog.open(ConfirmationDialogComponent, {
          width: '480px',
          panelClass: 'sipd-modal',
          disableClose: true,
          data:{
            msg: "Dados novos de " + this.tabTitle + " foram recebidos. Desfazer modificações atuais e substituir?",
            title: "Atenção",
            okLabel: "Substituir",
            cancelLabel: "Ignorar",
            cancelTooltip: "Ignora novos dados e mantém modificações atuais. Dados novos serão perdidos ao salvar.",
            okTooltip: "Substui dados modificados pelos dados novos. Modificações serão perdidas.",
            linesMsg: diffMsg
          }
        });

        dlg.afterClosed().pipe(first()).subscribe((result) => {
          if (result) {
            this.setModel(data, false, null);
          }
        });
      }
      else {
        this.setModel(data, false, null);
      }
    }
  
    this.updatingModel = false;
  }

  private updateViewFromPopout() {
    if (this.hasEditPopoutData()) {
      const popout = this.getEditPopoutData();
      this.view = popout.view;
      this.setEditPopoutData(null);
    }
  }

  // Durante a criação, inicializa model e view com alguns valores default, depende de cada modelo
  protected abstract createData(options?);
 
  private openModel(modelId: string, entity: EntityModel, copy: boolean, options: any) {
    if (modelId) { /* Edição */
      this.glUpdateTabTitle(this.modelName + (copy?': [NOVO]': ''));
      if (entity) {
        this.setModel(entity, copy, options);
      }
      else {
        this.id = modelId;
        this.loadData(copy, options);
      }
    } else { /* Criação */
      this.clearPreviousData(); // Se o modelo é re-carregado em uma edição que já estava aberta (acho que não faz sentido aqui)

      this.glUpdateTabTitle(this.modelName + ': [NOVO]');
      this.id = null;
      this.readOnly = false;

      // Inicialização da View (criação)
      this.createData(options); // Durante a criação, inicializa model e view com alguns valores default, depende de cada modelo
      this.updateViewFromPopout();
      this.backupInitialView();

      this.loadFormOptionsData();
    }
  }

  copyModel(src) {
    if (!src) return src;
    return JSON.parse(JSON.stringify(src));
  }
  
  /**
   * Copia todas as propriedades presente em *view* para *model*
   */
  protected mapViewToModel() {
    this.logger.debug(`EditComponent.mapViewToModel() - ${this.modelName}`);
    this.model = this.copyModel(this.view);
  }

  /**
   * Copia todas as propriedades presente em *model* para *view*
   */
  protected mapModelToView() {
    this.logger.debug('EditComponent.mapModelToView() - ' + this.modelName);

    this.view = {};

    if (!this.model) {
      return;
    }

    this.view = this.copyModel(this.model);
  }

  // Inicializa campos do HTML para evitar erros quando o modelo ainda está vazio
  // Chamado na ngOnInit e na createData
  protected initializeFields(){
  }

  public onRevertClick(){
    let dlg = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: "Desfazer modificações e restaurar dados?",
        title: "Atenção",
        okLabel: "Desfazer",
      }
    });

    dlg.afterClosed().pipe(first()).subscribe((result) => {
      if (result) {
        if(this.isCreating()){
          this.initializeFields();
        }else{
          this.mapModelToView();
          this.restoreFiles();
        }
        this.backupInitialView();
      }
    });
  }
  
  // restaura os arquivos salvos do modelo na edição
  protected restoreFiles(){

  }

  canRevert(): boolean {
    return this.viewIsChanged();
  }

  // Cria uma copia da View para ser comparada posteriormente e identificar edições do usuário
  protected backupInitialView() {
    this.initialView = this.copyModel(this.view);
  }

  protected updateInitialView(key) {
    this.initialView[key] = this.copyModel(this.view[key]);
  }

  /**
   * Por referência, voltar(utilizar o backup) o estado da view para a initialView.
   */
  private restoreInitialView() {
    this.view = this.initialView;
  }

  /* diff.diff
     https://github.com/flitbit/diff/

    kind - indicates the kind of change; will be one of the following:
      N - indicates a newly added property/element
      D - indicates a property/element was deleted
      E - indicates a property/element was edited
      A - indicates a change occurred within an array
    path - the property path (from the left-hand-side root)
    lhs - the value on the left-hand-side of the comparison (undefined if kind === 'N')
    rhs - the value on the right-hand-side of the comparison (undefined if kind === 'D')
    index - when kind === 'A', indicates the array index where the change occurred
    item - when kind === 'A', contains a nested change record indicating the change that occurred at the array index 

    Para o caso abaixo:
      left = initialView (antigo)
      right = view (novo)
  */
  public viewIsChanged():boolean {
    var differences = diff.diff(this.initialView, this.view);
    if(!differences) return false;

    let changed = false;

    // DEBUG Útil para debugar campos não tratados corretamente
    //this.logger.debug("viewIsChanged()", differences);

    differences.forEach( diff => {
      switch(diff.kind){
        case 'N':{
          if(diff.rhs && diff.rhs != ''){
            changed = true;
          }
          break;
        }
        case 'D':{
          if(diff.lhs && diff.lhs != ''){
            changed = true;
          }
          break;
        }
        case 'E':{ //Edit - one of the two must contain some changes
          if((diff.lhs && diff.lhs != '') || (diff.rhs && diff.rhs != '')){
            changed = true;
          }
          break;
        }
        default:{
          changed = true;
        }
      }
    });

    return changed;
  }
  
  protected getRecord(id: string, extraParams?: Map<string, string>, options?: any) {
    return this.entityService.getRecord(id, this.getExtraParams());
  }

  canEdit(): boolean {
    return true;
  }

  onEnableEditClick() {
    if (this.canEdit()) {
      this.readOnly = false;
    }
  }

  onCopyClick()  {
    this.glOpenContainer(this.componentName, {id: this.id, model: this.model, copy: true});
  }
  
  onDeleteClick() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: 'Remover ' + this.modelName + '?',
        title: 'Remover ' + this.modelName,
        okLabel: 'Remover',
        identifier: this.model['plate']? this.model['plate']: (this.model['identifier']? this.model['identifier']: this.model['name']),
        showIdentifier: true
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.logger.debug('EditComponent.onDeleteClick()');
        this.entityService.delete(this.model, this.getExtraParams()).pipe(first()).subscribe((deletedRecord: any) => {
          this.logger.debug('EditComponent.onDeleteClick - Element deleted: ', deletedRecord.id);
          let name = ""
          if (deletedRecord.name) {
            name = deletedRecord.name;
          }
          else if (deletedRecord.plate) {
            name = deletedRecord.plate;
          }
          this.postEntityDeleteProcess(this.model);
          this.toastr.success(this.modelName + " " + name + " foi removido(a) com sucesso");
          this.glEmitEvent(DELETE_DATA_PREFIX + this.componentName, {id: deletedRecord.id});
        },
        (error) => {
          this.logger.error(error)
          this.toastr.error("Não foi possível remover " + this.modelName + ":" + "\n" + error.error.message);
        });
      }
    });
  }

  postEntityDeleteProcess(entity: EntityModel) {}

  // Chamada depois de carregar o modelo existente (load ou copy ou open)
  private setModel(entity: EntityModel, copy: boolean, options: any) {
    // Se o modelo é re-carregado em uma edição que já estava aberta
    // Serve para remover valores de dados externos ao modelo que podem não estar inicializados no modelo novo
    this.clearPreviousData();

    this.model = this.copyModel(entity);
    this.id = entity.id;
    
    if (!this.model) {
      this.logger.error('Não foi possível carregar o ' + this.modelName);
      this.openAlertDialog('Erro, não foi possível carregar o objeto ' + this.modelName);
      return;
    }

    if (copy) {
      this.clearCopyData();  // Limpa dados do model, depois de load/copy, que não devem ser copiados
      this.copy = true;
      this.readOnly = false;
    }

    if (!this.canEdit()){
      this.readOnly = true;
    }

    // Chamada depois que o model foi carregado, mas antes da view ser inicializada
    // Serve para reforçar certos campos do model
    this.afterLoadData(options);

    // Inicialização da View (load/copy)
    this.mapModelToView();
    this.updateViewFromPopout();
    this.backupInitialView();

    this.loadFormOptionsData();
  }

  /**
   * @description Loads the current object being created/edited attributes on the screen components!!
   */
  protected loadData(copy: boolean, options: any) {
    this.logger.debug('EditComponent.loadData() - ' + this.modelName);

    this.getRecord(this.id, this.getExtraParams(), options).pipe(first()).subscribe((entity: EntityModel) => {
      this.setModel(entity, copy, options);
    });
  }

  protected getExtraParams() {
    return null;
  }

  // Chamada depois que o model foi carregado, mas antes da view ser inicializada
  // Serve para reforçar certos campos do model
  protected afterLoadData(options){}

  // Se o modelo é re-carregado em uma edição que já estava aberta
  // Serve para remover valores de dados externos ao modelo que podem não estar inicializados no modelo novo
  protected clearPreviousData(){
  }

  // Limpa dados do model, depois de load/copy, que não devem ser copiados
  protected clearCopyData(){
    this.id = null;
    this.model['id'] = null;
  }

  /**
   * @description Ação executada quando o usuário clica em Salvar
   * @param saveClickEvent O clickEvent do botão salvar
   */
  onSaveClick(saveClickEvent?) {
    this.logger.debug(`EditComponent.onSaveClick() - ${this.modelName}`);

    this.isSaving = true;
    this.backupModel = this.copyModel(this.model);
    this.mapViewToModel();

    // Se o entity está sendo criado
    if (this.isCreating()) {
      return this.saveCreatedModel();
    }else { // Else, o entity está sendo editado
      return this.saveEditedModel();
    }
  }

  entitySaved(entity: EntityModel){
    this.model = entity;
    this.mapModelToView();
    this.backupInitialView();

    let message = `${this.modelName} salvo(a) com sucesso.`;

    this.toastr.info(message);
    this.logger.debug(message);

    this.glEmitEvent(SAVE_DATA_PREFIX + this.componentName, {entity: this.model});

    this.isSaving = false;
    this.backupModel = null;
    this._saveFinished?.next(true);
  }

  entitySaveError(error: any){
    // Ao terminar a edição o botão salvar deve ser liberado
    this.isSaving = false;
    this.model = this.copyModel(this.backupModel); // Restaura o modelo de depois de erro ao salvar
    this.backupModel = null;
    this._saveFinished?.next(true);

    if (error.error && error.error.exceptionName && error.error.exceptionName.endsWith("DuplicateFieldException"))
      throw(error);
    else if(error.error && error.error.message != null){      
      this.toastr.error(`Erro ao salvar ${this.modelName}: ` + error.error.message);
    }
    else
      this.toastr.error(`Erro ao salvar ${this.modelName}: ` + (error.error?.errorDetails ? error.error?.errorDetails: ''));

    this.logger.error(`Erro ao salvar ${this.modelName} de id ${this.model.id}`, error);
  }

  saveEditedModel(){
    this.logger.debug('EditComponent.saveEditedModel');
    this.entityService.editRecord(this.model, this.getExtraParams()).pipe(first()).subscribe((entity: EntityModel) => {
      this.entitySaved(entity);
    },
    error => this.entitySaveError(error));
  }

  saveCreatedModel(){
    this.logger.debug('EditComponent.saveCreatedModel');

    this.entityService.createRecord(this.model, this.getExtraParams()).pipe(first()).subscribe(( entity: EntityModel ) => {
      // Atualizando o ID dos componentes criados.
      // Com isso a próxima ação de salvar será reconhecida como uma edição
      this.id = entity.id;
      this.glUpdateCreateId(this.id);

      this.entitySaved(entity);
    },
    error => {
      this.entitySaveError(error)
    });
  }

  /**
   * Verifica se a tela esta operando uma ação de edição (PUT) ou de criação (POST) de um entity.
   */
  public isCreating(): boolean {
    return !this.id;
  }

  // Usado para carregar valores para combos
  // chamada depois de carregar/copiar o modelo ou criar um novo
  protected loadFormOptionsData() {}

  protected openAlertDialog(message: string) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: message,
        title: this.title,
        hideOkButton: true,
        cancelLabel: 'Fechar'
      }
    });
  }

  protected openSimpleConfirmDialog(message: string) {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: message,
        title: "Atenção",
        hideOkButton: true,
        showDiscardButton: true
      }
    });
  }

  protected openConfirmDialog(message: string){
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: message,
        title: "Atenção",
        okLabel: "Salvar",
        showDiscardButton: true
      }
    });
  }

  /**
   * @description Called when the user clicks the Cancel button. It cancels the create/edit operation and returns to the main panel.
   */
  onCancelClick(event?) {
    this.logger.debug('EditComponent.onCancelClick() - ' + this.modelName);
    this.restoreInitialView();
    this.glContainerClose();
  }

  /** @description Returns a dummy UserModel derived from the loggedUser with only name and login copied.
   *  Used to set the Author in PatrolEdit and
   *  Used to set the Analyst in EventEdit.
   */
  public getCurrentUser(): UserModel {
    const currentUser = new UserModel();    
    currentUser.login = this.loggedUser.login;
    currentUser.name = this.loggedUser.name;
    return currentUser;
  }

  /**
   * Método utilitário que sinaliza que os requisitos de preenchimento do formulário foram preenchidos.
   * Caso a classe filha faça override, será de sua responsabilidade definir os campos obrigatórios.
   */
  protected isRequiredFieldFilled(): boolean {
    return true;
  }

  /**
     * Verifica se o usuário é administrador do sistema.
     */
  isAdmin(): boolean {
    return this.authorizationService.isAdmin();
  }

  /**
   * Verifica se todas as condições para salvamento foram preenchidas
   */
  canSave(): boolean {                     // Pode salvar quando:
    return this.isRequiredFieldFilled() && // Campos Obrigatórios preenchidos
           !this.isSaving && (      // Não está salvando (aguardando retorno do backend)
              this.isCreating() ||  // É uma criação ou
              this.viewIsChanged()  // É uma edição e tem campos modificados
              );
  }

  getRequiredFieldNames(): string [] {
    return [''];
  }

  private getRequiredFields(): string {
    let names = this.getRequiredFieldNames();
    let str = '';
    names.forEach(n => str = str + '<li>' + n + '</li>');
    return str;
  }
  /**
   * Método utilitário para sinalizar o porque que o formulário não pode ser submetido
   */
  enterCanSave() {
    this.timeOutCanSave = setTimeout(() => {
      if (!this.canSave()) {
        if (!this.isRequiredFieldFilled()) {
          this.toastr.info('Campo(s) obrigatório(s) não preenchido(s):<br>' + this.getRequiredFields());
          return;
        }

        if (this.isSaving) {
          this.timeOutCanSave = setTimeout(() => {
            this.toastr.info('Salvando. Aguarde...');
            this.timeOutCanSave = null;
          }, 2 * MOUSE_ENTER_TIME);
          return;
        }
      }
      
      this.timeOutCanSave = null;
    }, MOUSE_ENTER_TIME);
  }

  leaveCanSave() {
    if (this.timeOutCanSave) {
      clearTimeout(this.timeOutCanSave);
      this.timeOutCanSave = null;
    }
  }

  numbersOnly(event: KeyboardEvent): boolean {
    return event.key >= '0' && event.key <= '9';
  }

  // Preserve original property order
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 && (o1.id === o2.id);
  }

  async glOnClose() {
    if(this.viewIsChanged()) {
      this.logger.debug("EditComponent.glOnClose()");
      this.showSaveConfirmDialog().pipe(first()).subscribe((option:boolean) => {
        this.isBusySaveConfirm = false;
        if(typeof(option) === 'string') {//discard
          this.logger.debug("EditComponent-AfterConfirmDialog Discard");
          this.restoreInitialView();
          this.glContainerClose(); // Vai trigar a glOnClose, por isso restauramos a View
        }
        else if(option) {//save
          this.logger.debug("EditComponent-AfterConfirmDialog Save");
          this.onSaveClick();
          // Preciso esperar o processo de Salvar terminar para fechar o painel
          this.saveFinished$.pipe(first()).subscribe(() => {
            this.logger.debug("EditComponent-AfterSaveFinished OnClose");
            this.restoreInitialView();
            this.glContainerClose(); // Vai trigar a glOnClose, por isso restauramos a View
          });
        }
        else if(!option) { //cancel ou clicar fora do dialogo
          this.logger.debug("EditComponent-AfterConfirmDialog Cancel");
          return;
        }
      });;
      this.glUnSubscribeEvent("close");
    }
  }

  protected showSaveConfirmDialog(): Observable<any> {
    let dialog;

    if (this.canSave())
      dialog = this.openConfirmDialog("Dados não salvos."); // Salvar+Descartar+Cancelar
    else
      dialog = this.openSimpleConfirmDialog("Dados não salvos, mas salvar não é permitido. Verifique campos obrigatórios.");  // Descartar+Cancelar

    this.isBusySaveConfirm = true;

    return dialog.afterClosed();
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  loadingOn() {
    this.loadingListService.loadingOn();
  }

  loadingOff() {
    this.loadingListService.loadingOff();
  }

  getEditPopoutData() {
    return this.storageService.getPopoutData();
  }

  setEditPopoutData(data): void {
    this.storageService.setPopoutData(data);
  }

  hasEditPopoutData(): boolean {
    return this.storageService.hasPopoutData();
  }

  glOnPopout() {
    super.glOnPopout();
    this.setEditPopoutData({id: this.id, view: this.view});
  }

  glOnPopin() {
    super.glOnPopin();
    this.setEditPopoutData({id: this.id, view: this.view});
  }

  ngAfterViewInit() {
    this.logger.debug("EditComponent.ngAfterViewInit()");
    this.glEmitEvent(PAGE_READY_PREFIX + this.componentName, null);
  }

  getUserNameLoginTitle(user) {
    return UserModel.getUserTitle(user);
  }

  openContextMenu(event: MouseEvent, row) {
    event.preventDefault();
    this.contextMenuSelectedItem = row;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onLocationClick(entity: any, mapEvent: MapEvents) {
    this.glOpenContainer(MAP_PAGE, {entity: entity, mapEvent: mapEvent});
  }
    /**
   * Seleciona o usuário logado na lista de usuários como padrão.
   */
  protected currentUserAsField(fieldName: string) {
    this.view[fieldName] = this.loggedUser;
  }

  // sobrescrita nas clases, verifica as permissões dos usuários.
  protected checkPermissions(){}

  /** Abre no navegador (ou baixa) o arquivo
    * imagens e .pdf são apresentados numa nova aba
    * outros arquivos é feito download
    */
  viewFile(file: File, attachmentData : AttachmentModel){
    if(file){
      if (attachmentData.extension === 'jpg' ||
          attachmentData.extension === 'gif' ||
          attachmentData.extension === 'jpeg' ||
          attachmentData.extension === 'pdf'){
          
          let blob, url;
          const fr = new FileReader();
          fr.readAsArrayBuffer(file);      
           
          fr.onload = (e: any) => {          
          blob = new Blob([fr.result], {type : attachmentData.type});
          url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');          
        };

      } 
      else {       
        let filename = attachmentData.name + "." + attachmentData.extension;        
        saveAs(file, filename);
      }     
    }
  }
}
