import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { EventMainActionsService } from 'src/app/service/model/event.main.actions.service';
import { RegistrationListComponent } from '../../registration-list-component';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RegistrationType } from '../../../../model/enums.enum';
import { EventMainActionModel } from 'src/app/model/event.main.action.model';
import { ESP } from 'src/app/common/constants';

@Component({
  selector: 'app-event-main-actions-list',
  templateUrl: './event-main-actions-list.component.html',
  styleUrls: ['../../../../app.component.scss', '../../../list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventMainActionsListComponent extends RegistrationListComponent implements OnInit {

  constructor(logger:                       NGXLogger,
              eventMainActionsService:      EventMainActionsService,
              dialog:           MatDialog,
              public authorizationService: AuthorizationService,
              protected storageService:     StorageService,
              protected changeDetector:                ChangeDetectorRef,
              protected toastr: ToastrService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container){
    super(logger, authorizationService, eventMainActionsService, dialog, 'event-main-actions', environment.EVENT_MAIN_ACTIONS_GROUP_LABEL,
          environment.EVENT_MAIN_ACTIONS_TITLE_LABEL, environment.EVENT_MAIN_ACTIONS_MODEL_LABEL,
          storageService, changeDetector, toastr, goldenLayout, container);
          logger.debug('EventMainActionsListComponent.constructor()');
    this.registrationType = RegistrationType.EVENT_MAIN_ACTION;
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.logger.debug('EventMainActionsListComponent.ngOnInit()');
    this.loadRecordsFromServer();
    this.displayedColumns = ['select', 'sourceType', 'action' ];
  }
 
  protected getDefaultSortColumn(): string {
    return "sourceType";
  }

  protected getStringEntityForFilter(eventMainAction: EventMainActionModel): string {
    const sourceType = this.lowerAndTrimText(eventMainAction.sourceType);
    const action = this.lowerAndTrimText(eventMainAction.action);
    return sourceType + ESP + action;
  }
}
