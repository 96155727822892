import * as moment from 'moment';

/**
 * Utilitário para manipulação de datas
 *
 * Lembretes: 
 *   Data/Hora é sempre passada para o frontend via um timestamp
 *   No Web Timestamp são tratados como UTC
 *   No Web strings e outros são tratados como horário local, em geral UTC-3
 *   MomentJs faz essa conversão para nós 
 *   By default, moment parses and displays in local time.
 * 
 *   moment().valueOf() - retorna da Data/Hora de corrente em UTC
 *   moment().format('DD/MM/yyyy HH:mm'); - retorna em horário local
 *   O mesmo acontece ao passar um timestamp especifico como paramtro nas chamadas acima
 *   moment(timestamp).format('x');  Apenas converte o timestamp em uma string, não faz parse, continua em UTC
 * 
 *   moment(timestamp).startOf('day') e moment(timestamp).endOf('day') são problemáticos,
 *   porque em geral usamos esses valores em UTC no backend, mas ambas as funções retornam 00:00 e 23:59 em horário local.
 *   Isso em UTC resulta em 03:00 e 02:59 em UTC.
 *   Em alguns casos queremos filtrar pelo horário local mesmo.
 *   Mas em outros casos queremos os valores de hora em UTC sendo 00:00 e 23:59. Tomar cuidado com isso!
 */
export default class DateUtils {

  static containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  static isTimestamp(timestamp){
    if (typeof timestamp == 'number' && timestamp < 946684800000) // 1 January 2000 00:00:00
      return false;
    if (typeof timestamp == 'string' && !DateUtils.containsOnlyNumbers(timestamp))
      return false;
    return (new Date(timestamp)).getTime() > 0;
  }

  /**
   * Transforma um timestamp para o formato string com o formato 'DD/MM/YYYY HH:mm:ss'
   * @param timestamp Timestamp para conversão
   */
  static timestampToStringInSeconds(timestamp: number): string {
    return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
  }

  /**
   * Transforma um timestamp para o formato string com o formato 'DD/MM/YYYY HH:mm' (sem segundos)
   * @param timestamp Timestamp para conversão
   */
  static timestampToStringInMinutes(timestamp: number): string {
    return moment(timestamp).format('DD/MM/YYYY HH:mm');
  }

  /**
   * Transforma um timestamp para o formato string com o formato 'DD/MM/YYYY' (sem horário)
   * @param timestamp Timestamp para conversão
   */
  static timestampToStringInDays(timestamp: number): string {
    return moment(timestamp).format('DD/MM/YYYY');
  }

  /**
   * Transforma um timestamp para o formato string com o formato 'MM/YYYY - HH:mm' (sem o dia)
   * @param timestamp Timestamp para conversão
   */
  static timestampToStringInMonths(timestamp: number): string {
    return moment(timestamp).format('MM/YYYY');
  }

  static timestampToTimeString(timestamp: number): string{
    return moment(timestamp).format('HH:mm:ss');
  }

  private static formatTime(t) {
    return ('00'+t).slice(-2);
  }

  // Transforma uma duração de timestamp para uma string no formato HH:mm:ss
  static durationToTimeString(duration: number){
    let sec = Math.floor(duration / 1000);
    let min = Math.floor(sec / 60);
    let hour = Math.floor(min / 60);
    min = min % 60;
    sec = sec % 60;
    return DateUtils.formatTime(hour) + ':'+ DateUtils.formatTime(min) + ':' + DateUtils.formatTime(sec);
  }

  /**
   * Converte dados de um Date,Time String para long (millisecond)
   * A string vem em local time, o long é em UTC
   */
   static stringDateTimeToTimestamp(stringDate: string, stringTime: string, isStart: boolean): number{
    if(!stringDate) return null;

    const dateSplit = String( stringDate ).split('/');
    const day: number = Number( dateSplit[0] );
    const month: number = Number( dateSplit[1] );
    const year: number = Number( dateSplit[2] );

    let hours: number = 0;
    let minutes: number = 0;
    let seconds: number = 0;

    if(stringTime){
      const timeSplit = String( stringTime ).split(':');
      hours = Number( timeSplit[0] );
      minutes = Number( timeSplit[1] );
      if (timeSplit[2]) seconds = Number( timeSplit[2] );
    }else{
      if(!isStart){
        hours = 23;
        minutes = 59;
        seconds = 59;
      }
    }

    let tempStartDate: Date = new Date(year, month-1, day, hours, minutes, seconds, 0); // Local time
    return tempStartDate.getTime(); // UTC
  }

  /**
   * Converte dados de um Date+Time String para long (millisecond)
   */
   static stringDateToTimestamp(stringDateTime: string){
    if(!stringDateTime) return null;

    return moment(stringDateTime,"DD/MM/yyyy HH:mm").valueOf();
  }

  /**
   * Obtem o timestamp do inicio do dia de hoje 00:00 no horário local, convertido para UTC.
   * Ou seja, a hora em UTC vai ser 03:00
   */
  static getTodayStartDate(): number {
    return moment().startOf('day').valueOf();
  }

  /**
   * Obtem o timestamp do final do dia de hoje 23:59 no horário local, convertido para UTC.
   * Ou seja, a hora em UTC vai ser 02:59 do dia seguinte
   */
  static getTodayEndDate(): number {
    return moment().endOf('day').valueOf();
  }

  /**
   * Obtem o timestamp do inicio do dia 00:00 em UTC.
   * Ou seja, a hora em UTC vai ser 00:00
   */
   static getStartOfDayUTC(timestamp: number): number {
    return moment(timestamp).utc().startOf('day').valueOf();
  }

  /**
   * Obtem o timestamp do final do dia 23:59 em UTC.
   * Ou seja, a hora em UTC vai ser 23:59
   */
   static getEndOfDayUTC(timestamp: number): number {
    return moment(timestamp).utc().endOf('day').valueOf();
  }
}
