import { StompService, StompConfig, StompState } from '@stomp/ng2-stompjs';
import { IMessage, Message } from '@stomp/stompjs';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

// Não é injetado, é instanciado
// Para cada stream de tópico que queremos ouvir, instanciamos a classe

export class Websocket {
  private countWebsocketClosedCheck: number;
  private WEBSOCKET_STUCK_CONN_COUNT: number = 4;

  private stateCheckInterval: NodeJS.Timeout;
  private WEBSOCKET_CONN_CHECK_RATE: number = 30000;  // milliseconds (30 seconds)

  private websocketTestSubscription: Subscription;

  private messages: Observable<Message>;
  private stompService: StompService;

  constructor(socketUrl: string, streamUrl: string) {

    this.countWebsocketClosedCheck = 0;

    // Create Stomp Configuration
    const stompConfig: StompConfig = {
      url: socketUrl,
      headers: {
        login: '',
        passcode: ''
      },
      heartbeat_in: 0,
      heartbeat_out: 20000,
      reconnect_delay: 5000,
      debug: false

    };
    // Create Stomp Service
    this.stompService = new StompService(stompConfig);
    // Connect to a Stream
    this.messages = this.stompService.subscribe(streamUrl);

    this.subscribeOnWebsocketTest();
    this.setCheckStateInterval();
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar mudanças nos
   * eventos.
   */
   public static createEventUpdateWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/eventUpdate';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

   /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar mudanças nas
   * verificações.
   */
  public static createVerificationUpdateWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/verificationUpdate';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar mudanças nas rondas.
   */
  public static createPatrolUpdateWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/patrolUpdate';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar mudanças na
   * nos cadastros.
   */
  public static createRegistrationsUpdateWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/registrationUpdate';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar notificar
   * altera;'ao na cotagem de canais.
   */
  public static createResetChannelWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/resetChannel';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar o recebimento
   * de sinais GPS de aplicativos.
   */
  public static createSignalsWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/signals';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar o recebimento
   * de sinais GPS de veículos.
   */
  public static createVehicleSignalsWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/vehicle_signals';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar o recebimento
   * de marcadores.
   */
  public static createMarkersWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/markers';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /**
   * Método "Fábrica" para retornar um websocket já configurado para notificar o recebimento
   * de alertas.
   */
  public static createAlertsWebsocket(): Websocket {
    const WEBSOCKET_TOPIC = '/topic/alerts';
    return Websocket.internalCreateWebsocket(WEBSOCKET_TOPIC);
  }

  /** Método auxiliar para apoiar a construção de instâncias pelos métodos "Fábrica" de Websockets. */
  private static internalCreateWebsocket(topic: string): Websocket {
    const WEBSOCKET_URL = environment.settings.websocket_address;
    return new Websocket(WEBSOCKET_URL, topic);
  }

  public stream(): Observable<Message> {
    return this.messages;
  }

  public send(url: string, message: any) {
    return this.stompService.publish(url, JSON.stringify(message));
  }

  public state(): BehaviorSubject<StompState> {
    return this.stompService.state;
  }

  private disconnect(){
    this.stompService?.deactivate();
    console.log("***** Websocket.disconnect - Conexão com o websocket terminada");
  }

  public destroy(){
    this.disconnect();
    this.websocketTestSubscription?.unsubscribe();
    clearInterval(this.stateCheckInterval);
  }

  private connect(){
    this.stompService.activate();
    this.countWebsocketClosedCheck = 0;
    console.log("***** Websocket.connect - Conexão com o websocket re-conectada");
  }

  public onConnectCallback() {
    return this.stompService.connected$;
  }

  /********************************************************************************/
  /*****************************      HEALTH CHECK     ****************************/
  /********************************************************************************/

  /*
   * Inscrição para receber as mudanças de status do stomp
   */
  private setCheckStateInterval(){
    clearInterval(this.stateCheckInterval);
    this.stateCheckInterval = setInterval(() => {
        this.checkWebsocketState();
    }, this.WEBSOCKET_CONN_CHECK_RATE);
  }

  /*
   * Método que verifica se a conexão com o websocket está travada.
   * Caso esteja, reativa a conexão
   * @returns
   */
   private checkWebsocketState(){
    if(this.stompService?.client.connected){ // Verifica se a conexão com stomp está ativa
      if(this.countWebsocketClosedCheck === this.WEBSOCKET_STUCK_CONN_COUNT){ // Atingiu o limite de tentativas sem resposta
        console.log("***** Websocket.checkWebsocketState - Conexão travada. Reativando a conexão");

        this.disconnect(); // desconecta

        setTimeout(() => {
          this.connect();  // e conecta novamente, um tempo depois
        }, 5000); // 5s

        return;
      }else{
        console.log("***** Websocket.checkWebsocketState - Enviando mensagem de teste");
        const WEBSOCKET_TOPIC = '/app/test';
        this.send(WEBSOCKET_TOPIC, "Just Checking"); // Enviando uma mensagem de teste para o backend

        this.countWebsocketClosedCheck++; // Incrementa o contador de tentativas de resposta do backend
      }
    }
  }

  /**
   * Inscrição para receber a callback do teste de ping do servidor websocket
   */
  private subscribeOnWebsocketTest(){
    const WEBSOCKET_TOPIC = '/topic/callback';
    console.log("***** Websocket.subscribeOnWebsocketTest - Inscrição no tópico de teste do websocket: ", WEBSOCKET_TOPIC);
    this.websocketTestSubscription = this.stompService.watch(WEBSOCKET_TOPIC).subscribe((message: IMessage) => {
        //console.log("***** Mensagem recebida no broker:", message); // Recebeu a confirmação da mensagem de teste do backend
        this.countWebsocketClosedCheck = 0; // Zera o contador de tentativas
    }, (error: any) => console.error(error));
  }
}
