import { ConfirmationDialogComponent } from './../general/confirmation-dialog/confirmation-dialog.component';
import { EntityModel } from '../model/entity.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild, Directive, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Observable, of } from 'rxjs';
import { EntityService } from '../service/model/entity.service';
import * as moment from 'moment';
import { StorageService } from '../service/storage-service';
import { BaseGoldenPanel } from './base-golden-panel/base-golden-panel';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { DEFAULT_SORT, DELETE_DATA_PREFIX, SAVE_DATA_PREFIX, LIST_UPDATE_PREFIX, LARGE_PAGE_SIZE, UPDATE_DATA_PREFIX, ESP } from '../common/constants';
import { SelectionModel } from '@angular/cdk/collections';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthorizationService } from '../service/authorization/authorization.service';
import * as XLSX from 'xlsx';
import { MatInput } from '@angular/material/input';
import { UserModel } from '../model/user.model';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';
import { ProfileModel } from '../model/profile.model';
import { PermisionRuleOption, Permission } from '../model/enums.enum';
import { first, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ProfileClassToConsole } from '../common/profile-class.decorator';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationDialogComponent } from '../general/notification-dialog/notification-dialog.component';

@ProfileClassToConsole()
@Directive()
export class ListComponent extends BaseGoldenPanel implements OnInit, OnDestroy {

  loggedUser: UserModel;
  loggedUserProfile : ProfileModel;
  permission = Permission;
  permisionRuleOption = PermisionRuleOption;

  /** The list of loaded */
  model: EntityModel[];

  /** The list of columns to display on the grid */
  displayedColumns: string[];

  /** The MatTableDataSource bound to the grid to show data */
  dataSource: MatTableDataSource<EntityModel>;

  /** The structure to control grid selection  */
  selection = new SelectionModel<EntityModel>(true, []);

  /** The MatPaginator bound to the grid to show data */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatInput, { static: true }) searchInput: MatInput;

  // pagination
  initialPageSize = environment.useServerPagination? environment.defaultPageSize: LARGE_PAGE_SIZE;
  pageSize = environment.defaultPageSize;
  pageLength = undefined;
  sortOptions = DEFAULT_SORT;

  // Context Menu
  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger, static: true }) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuSelectedItem; // Leave t as any, for now
  viewContextMenu: boolean = true; // valida se o menu de contexto é apresentado para o usuário

  loadingListService: LoadingListService = new LoadingListService();

  protected apiUrl: string;

  constructor(public logger:                NGXLogger,
              public authorizationService:  AuthorizationService,
              protected entityService:      EntityService,
              protected dialog:             MatDialog,
              protected toastr:             ToastrService,
              public componentName:         string,
              public    tabTitle:           string,
              public    title:              string,
              public    modelName:          string,   // Usado em diálogos de confirmação
              protected storageService:     StorageService,
              protected changeDetector:     ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, goldenLayout, container);
    this.loggedUser = this.storageService.getLocalUser();
    this.loggedUserProfile = this.storageService.getLocalProfile();
    this.apiUrl = this.componentName;
    this.subscribeToSaveData();
    this.subscribeToDeleteData();
    this.subscribeToListUpdateData();
  }

  ngOnInit() {
    this.logger.debug('ListComponent.ngOnInit()');

    this.glUpdateTabTitle(this.tabTitle);

    this.sort.sort({id: this.getDefaultSortColumn(), start: this.getDefaultSortDirection(), disableClear: false});

    this.checkPermissions();
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  loadingOn() {
    this.loadingListService.loadingOn();
  }

  loadingOff() {
    this.loadingListService.loadingOff();
  }

  protected getDefaultSortColumn(): string {
    return "name";
  }

  protected getDefaultSortDirection(): 'asc' | 'desc' {
    return 'asc';
  }

  getListPopoutData() {
    return this.storageService.getPopoutData();
  }

  setListPopoutData(data): void {
    this.storageService.setPopoutData(data);
  }

  hasListPopoutData(): boolean {
    return this.storageService.hasPopoutData();
  }

  ngOnDestroy() {
    this.loadingListService.destroy();
    this.glUnSubscribeEvent(SAVE_DATA_PREFIX + this.componentName + '-edit');
    this.glUnSubscribeEvent(LIST_UPDATE_PREFIX + this.componentName);
    this.glUnSubscribeEvent(DELETE_DATA_PREFIX + this.componentName + '-edit');
  }

  getListCount(){
    return this.dataSource? this.dataSource.filteredData.length: 0;
  }

  protected selectionUpdate(){
  }

  protected renderComponent(){
    // aguarda um pouco para outras tarefas terminarem
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 250);
  }

  // Chamado de dentro da updateModelItem
  findAndUpdateModel(updatedModel: EntityModel, exclude?: boolean){
    const oldModel = this.model.find( model => updatedModel.id == model.id);
    if (!oldModel) { // Novo objeto
      this.model.push(updatedModel);
      this.buildDataSource();
    }
    else {
      // Atualização de objeto existente, itera pelos atributos e os copia um a um (mantém o mesmo ponteiro de objeto na lista)
      for (const key in updatedModel) {
        let value = updatedModel[key];
        oldModel[key] = value;
      }
      this.renderComponent();
      
      if(exclude){ // se a objeto existe, mas foi removido pelo filtro atual
        const index = this.model.findIndex((op) => { return op.id === updatedModel.id });
        this.model.splice(index, 1);
        this.buildDataSource();
      }
      
      // Notifica as edições que o objeto foi atualizado
      this.glEmitEvent(UPDATE_DATA_PREFIX + this.componentName + '-edit', oldModel);
    }
  }

  public subscribeToSaveData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + this.componentName + '-edit', (data) => {
      this.saveData(data.entity);
    });
  }

  public subscribeToListUpdateData() {
    // A lista escuta uma outra lista para saber se algo foi modificado
    this.glSubscribeEvent(LIST_UPDATE_PREFIX + this.componentName, () => {
      this.loadRecordsFromServer();
    });
  }

  public subscribeToDeleteData() {
    this.glSubscribeEvent(DELETE_DATA_PREFIX + this.componentName + '-edit', (data) => {
      if (!data.fromList) {
        // A edição avisou que foi removida
        this.removeFromList(data.id);
        this.buildDataSource();
      }
    });
  }

  public saveData(new_entity){
    const index = this.model.findIndex(entity => entity.id === new_entity.id);
    if (index >= 0) {
      this.model[index] = new_entity;
    }
    else{
      this.model.splice(0, 0, new_entity); // Insere no topo sempre, a ordenação corrente vai colocar no lugar certo na tela
    }

    this.buildDataSource();    
  }

  // Pode ter sido sobre-escrita por filhos, ver Eventos e Verificações
  loadRecordsFromServer() {
    this.logger.debug('ListComponent.loadRecordsFromServer()');

    this.loadingOn();
    this.dataSource = new MatTableDataSource([]);
    
    // Não usa loadListFromRestApi porque precisa ler o pageLength da paginação
    this.entityService.loadFromRestApi(this.paginator? 0: null, this.paginator? this.initialPageSize: null, this.sortOptions).pipe(first()).subscribe( (result) => {
      this.updateDataSource(result);
      this.loadingOff();
    }, error => {
      this.logger.error(error);
      this.loadingOff();
    });
  }

  updateDataSource(result: any){
    this.handleRestApiResult(result);
    this.postLoadProcess();
    this.buildDataSource();
  }

  handleRestApiResult(result){
    // checa se retorno eh um objeto pair
    if (result) {
      if ( result['first'] || result['second'] ){
          this.model = result['second'];
          this.pageLength = result['first'];
      } else {
          this.model = result;
      }
    }
  }

  buildDataSource(){
    this.dataSource = new MatTableDataSource(this.model);

    this.dataSource.filterPredicate = (entity: EntityModel, filter: string): boolean => {
      return this.getStringEntityForFilter(entity).indexOf(filter) != -1;
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };

    if (this.searchInput.value && this.searchInput.value != "") {
      this.dataSource.filter = this.searchInput.value.trim().toLowerCase();
    }

    this.dataSource.sort = this.sort;
    
    if (!environment.useServerPagination){
      this.dataSource.paginator = this.paginator;
    }

    this.selection.clear();
    this.selectionUpdate();
    this.renderComponent();
  }

  /* reducer method */
  getProperty (o, i) {
    if ( !o ) { return ''; }
    return o[i];
  }

  /* Retorna a propriedade definida pelo caminho */
  getPropertyByPath(item: Object, property: string){
    let value = property.split('.').reduce(this.getProperty, item);
    if (typeof value === 'string'){
        value = this.lowerAndTrimText(value);
        value = this.removeAccentsFromString(value);
    }
    return value ? value : '';
  }

  /**Remove accents/diacritics in a string */
  removeAccentsFromString(value : string){
   return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   * Implementação default para entidades simples. Deve ser sobrescrito para entidades mais complexas.
   */
  protected getStringEntityForFilter(data: EntityModel): string {
    return '';
  }

  /** Método utilitário para passar strings para caixa alta e remover espaços (se existirem) */
  protected lowerAndTrimText(text: string) {
    return (text) ? text.trim().toLowerCase() : '';
  }

  /** Método utilitário para formatar a apresentação das datas na lista */
  formatDate(date: number){
    if (!date || date == 0)
      return "";
    const startMoment = moment(date);
    return startMoment.format('DD/MM/YYYY HH:mm');
  }

  applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.selection.clear();
  }

  onRemoveSearch(){
    this.searchInput.value = '';
    this.dataSource.filter = '';
    this.selection.clear();
  }

  onCreateClick() {
    const componentName = this.componentName + '-edit';
    this.glOpenContainer(componentName, {id: null});
  }

  onCopyClick(row: any)  {
    const componentName = this.componentName + '-edit';
    this.glOpenContainer(componentName, {id: row.id, model: row, copy: true});
  }

  onEditClick(row: any, event: Event) {
    const componentName = this.componentName + '-edit';
    this.glOpenContainer(componentName, {id: row.id, model: row});
  }

  onDeleteManyClick(rows: EntityModel[], event?: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
          msg: 'Remover ' + rows.length + ' item(s) selecionado(s)?',
          title: 'Remover ' + this.modelName,
          okLabel: 'Remover',
          showIdentifier: false
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.logger.debug('ListComponent.onDeleteManyClick()');
        this.deleteSelectedEntities(rows);
        this.postListDeleteProcess(rows);
      }
    },
    error => {
      this.logger.error(error)
      this.toastr.error(`Erro ao salvar ${this.modelName} ` + (error.error?.errorDetails ? error.error?.errorDetails: ''));
    });
  }

  /**
   * Abre containers de vários elementos da lista para edição encadeando várias chamadas
   */
   onEditManyClick(selectedEntities: EntityModel[]){
    selectedEntities.forEach( (selectedItem: EntityModel) => {
        setTimeout(() => {
          this.glOpenContainer(this.componentName+'-edit', {id: selectedItem.id, model: selectedItem});
        }, 500);
    });
  }

  /**
   * Remove vários elementos da lista encadeando várias chamadas
   */
  protected deleteSelectedEntities(selectedEntities: EntityModel[], extraParams?: Map<string, string>){
    const observables: Observable<Object>[] = [];

    let successMessage : string = "";
    let errorMessage : string = "";

    if (!extraParams) extraParams = new Map();

    selectedEntities.forEach( (selectedItem: EntityModel) => {
      extraParams.set('id', selectedItem.id);
  
      observables.push(this.entityService.delete(selectedItem, extraParams).pipe(catchError(error => of(error))));
    });

    forkJoin(observables).pipe(first()).subscribe( entities => {
      // Será chamado uma única vez depois de todos os subscribes completarem
      let errorCount = 0;
      let successCount = 0;
      entities.forEach( (entity: any) => {
        if (entity instanceof HttpErrorResponse) {
          let error = entity as HttpErrorResponse;
          this.logger.error(error);
          errorMessage = errorMessage +  error.error.message + "\n\n";
          errorCount++;
        }
        else {
          successCount++;
          this.removeFromListAndNotify(entity);
        }
        if (successCount == 1) {
          successMessage = "Um item do tipo " + this.modelName + " foi removido com sucesso\n\n";
        }
        else {
          successMessage = successCount + " itens do tipo " + this.modelName + " foram removidos com sucesso\n\n";
        }
      });
      this.buildDataSource();
      if (errorCount == 0) {
        if (entities.length == 1) {
          this.toastr.success("Um item do tipo " + this.modelName + " foi removido com sucesso");
        }
        else {
          this.toastr.success(entities.length + " itens do tipo " + this.modelName + " foram removidos com sucesso");
        }
      }
      else {
        this.dialog.open(NotificationDialogComponent, {
          width: '480px',
          panelClass: 'sipd-modal',
          data: { successMessage: successMessage, errorMessage: errorMessage }
        });
      }
    },
    error => {
      this.logger.error(`Erro ao deletar vários itens da lista: ${JSON.stringify(error)}`);
    });
  }

  onDeleteClick(row: EntityModel, identifier: string, event: any, extraParams?: Map<string, string>) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: 'Remover ' + this.modelName + '?',
        title: 'Remover ' + this.modelName,
        okLabel: 'Remover',
        identifier: identifier,
        showIdentifier: true
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.logger.debug('ListComponent.onDeleteClick()');
        this.entityService.delete(row, extraParams).pipe(first()).subscribe((deletedRecord: any) => {
          this.logger.debug('Element deleted: ', deletedRecord.id);
          this.removeFromListAndNotify(deletedRecord);
          this.buildDataSource();
          this.postListDeleteProcess([row]);
          let name = ""
          if (deletedRecord.name) {
            name = deletedRecord.name;
          }
          else if (deletedRecord.plate) {
            name = deletedRecord.plate;
          }
          this.toastr.success(this.modelName + " " + name + " foi removido(a) com sucesso");
        },
        (error) => {
          this.logger.error(error);
          this.toastr.error(error.error.message);
        });
      }
    });
  }

  removeFromListAndNotify(deletedRecord){
    this.removeFromList(deletedRecord.id);

    // Aviso a edição que esse id foi removido (mas coloca um flag porque a própria list recebe essa notificação)
    this.glEmitEvent(DELETE_DATA_PREFIX + this.componentName + '-edit', {id: deletedRecord.id, fromList: true});
  }

  removeFromList(id){
    const index = this.model.findIndex(item => item.id == id);
    this.model.splice(index, 1);
  }

  onPageChanged(event){
    if (environment.useServerPagination) {
      const pageIndex = event.pageIndex;
      this.loadingOn();
      // Não usa loadListFromRestApi porque precisa ler o pageLength da paginação
      this.entityService.loadFromRestApi(pageIndex.toString(), this.pageSize, this.sortOptions).pipe(first()).subscribe( (result) => {
        this.updateDataSource(result);
        this.loadingOff();
      }, error => {
        this.logger.error(error);
        this.loadingOff();
      });
    }
  }

  postLoadProcess(){}

  postListDeleteProcess(rows: EntityModel[]){}

  openContextMenu(event: MouseEvent, row) {
    
    if(!this.viewContextMenu) return

    event.preventDefault();
    this.contextMenuSelectedItem = row;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  getUserNameLoginTitle(user) {
    return UserModel.getUserTitle(user);
  }

  onRefreshClick(){
    this.loadRecordsFromServer();
  }

  //########################################################
  // GRID SELECTION CONTROL
  //########################################################

  /** Whether in a page the number of selected elements matches the total number of rows. */
  isAllSelected() {
    //Chamada diretamente no html, datasource pode não estar inicializado ainda.
    if(!this.dataSource) return false; 
    
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  /** Selects all rows of a page if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if(this.isAllSelected())
      this.selection.deselect(...this.getPageData());
    else
      this.selection.select(...this.getPageData());

    this.selectionUpdate();
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  /** The tip for the checkbox on the passed row */
  checkboxTip(row?: EntityModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Desmarcar' : 'Selecionar'} Todos`;
    }
    return `${this.selection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Item`;
  }

  protected getExportColValue(col, model):string[] {
    let colsValues: string[] = [];
    colsValues.push(model[col]);
    return colsValues;
  }

  private parseDataToArray(columnsTitles){
    let ws_data = [];
    let line = [];
    columnsTitles.forEach((col:string) => { line.push(col); });
    ws_data.push(line);

    if(this.selection.selected.length > 0) {
      this.selection.selected.forEach(model => {
        let line = [];
        this.displayedColumns.forEach(col => {
          if(col != 'select' && col != 'editDelete' && col != 'lastSync') {
            line = line.concat(this.getExportColValue(col, model));
          }
        });
        ws_data.push(line);
      });
    }
    else {
      this.dataSource.filteredData.forEach(model => {
        let line = [];
        this.displayedColumns.forEach(col => {
          if(col != 'select' && col != 'editDelete' && col != 'lastSync') {
            line = line.concat(this.getExportColValue(col, model));
          }
        });
        ws_data.push(line);
      })
    }
    return ws_data;
  }

  public exportXls(columnsTitles){
    const EXCEL_EXTENSION = '.xlsx';
    var ws_name = this.tabTitle;
    var wb = XLSX.utils.book_new();

    let exportDate = moment().format('DD-MM-yyyy HH-mm');

    /* make worksheet */
    var ws_data = this.parseDataToArray(columnsTitles);

    var ws = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, ws_name+' '+exportDate+EXCEL_EXTENSION);
  }

  // Trigger do click nos checkbox do grid que atualiza as propriedades do botão de arquivamento
  onCheckboxClick(row: EntityModel){
    this.selection.toggle(row);
    this.selectionUpdate();
  }

  protected checkPermissions(){}
}
