/**
 * Arquivo para guardar dados constantes para a aplicação
 */

// String default para users sem profile Id
export const NO_PROFILE_ID = "No_profileId";

// Endpoint of api version
export const API_VERSION_ENDPOINT = 'api-version';

// Endpoint get last signals
export const LAST_SIGNALS = 'last-signals';
export const LAST_OPERATION_SIGNALS = 'last-operation-signals';
export const LAST_VEHICLE_SIGNALS = 'vehicle/last-signals';

// Registrations Service
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_SORT = 'createdAt,DESC';
export const MARKERS_PAGE_SIZE = 20;
export const CHANNEL_PAGE_SIZE = 30;
export const MEMBERS_PAGE_SIZE = 30;
export const LARGE_PAGE_SIZE = 20000;   // Para quando queremos pegar todos os itens mas o backend default tem paginação
export const SORT_NAME_ASC = 'name,ASC';
export const SORT_PLATE_ASC = 'plate,ASC';
export const SORT_TIMESTAMP_DESC = 'timestamp,DESC';
export const SORT_TIMESTAMP_ASC = 'timestamp,ASC';
export const SORT_RECEIVED_SERVER_TIMESTAMP_DESC = 'receivedServerTimestamp,DESC';

// Usado nos filtros das páginas
export const FILTER_OPTION_ALL =  'ALL';

// Usado para Upload Anexos, máximo 2MB
export const MAX_FILE_SIZE = 2097152;

export const USER_NOT_FOUND = 'Usuário Não Enc.'; // Sinal de App com usuário que foi removido, não temos como mapear o UserId em um nome/login
export const TEAM_NOT_FOUND = 'Equipe Não Enc.';
export const APP_SIGNAL_INVALID = 'Sinal de App sem Usuário';

//Usado para separar cada campo de uma clase para evitar criar novas palavras ao concatenar antes das buscas
export const ESP = "|";

// Usado para validar o tempo máximo na consulta de rastros no mapa
// 360 => 24horas*15dias
export const MAX_TIME_OF_TRACE = 360;

// Icons  (todos esses são quadrados)
export const Icons = {
  ICON_URL_BASE                  : 'assets/icons/base.png',
  ICON_URL_BASE_VERIFICATION     : 'assets/icons/base_verification.png',
  ICON_URL_CRITICAL_POINT        : 'assets/icons/criticalpoint.png',
  ICON_URL_PROFESSIONAL                      : 'assets/icons/professional.png',
  ICON_URL_PROFESSIONAL_OFFLINE              : 'assets/icons/professional_offline.png',
  ICON_URL_PROFESSIONAL_PATROL               : 'assets/icons/professional_patrol.png',
  ICON_URL_PROFESSIONAL_PATROL_OFFLINE       : 'assets/icons/professional_patrol_offline.png',
  ICON_URL_PROFESSIONAL_VERIFICATION         : 'assets/icons/professional_verification.png',
  ICON_URL_PROFESSIONAL_VERIFICATION_OFFLINE : 'assets/icons/professional_verification_offline.png',
  ICON_URL_VEHICLE                      : 'assets/icons/vehicle.png',
  ICON_URL_VEHICLE_OFFLINE              : 'assets/icons/vehicle_offline.png',
  ICON_URL_VEHICLE_PATROL               : 'assets/icons/vehicle_patrol.png',
  ICON_URL_VEHICLE_PATROL_OFFLINE       : 'assets/icons/vehicle_patrol_offline.png',
  ICON_URL_VEHICLE_VERIFICATION         : 'assets/icons/vehicle_verification.png',
  ICON_URL_VEHICLE_VERIFICATION_OFFLINE : 'assets/icons/vehicle_verification_offline.png',
  ICON_URL_MARKER                : 'assets/icons/marker.png',
  ICON_URL_STATE_MARKER          : 'assets/icons/state_marker.png',
  ICON_URL_ALERT                 : 'assets/icons/alert.png',
  ICON_URL_POINT                 : 'assets/icons/point.png',
  ICON_URL_EVENT                 : 'assets/icons/event.png',
};

// Map
export const MapInfo = {
  // As cores das linhas foram escolhidas de forma a contrastarem entre si
  // e funcionarem bem em modo Ruas e Hibrido.
  // Existe uma indicação do cliente na escolha das cores
  BAND_COLOR: '#00C8FF',   // Ciano um pouco mais escuro
  OILDUCT_COLOR: '#00B91A',  // Verde um pouco mais escuro
  GASDUCT_COLOR: '#DF0202', // Vermelho um pouco mais escuro
  SIMF_COLOR: '#6d32a8', 
  DUCT_WEIGHT: 5,
  LAYERS_ICON_SIZE: 32,

  PLANNED_ROUTE_LINE_COLOR: '#0505F7BF', // Roxo com transparencia
  PLANNED_ROUTE_LINE_WEIGHT: 8,

  TRACKING_LINE_COLOR: '#ff0000BF', // Vermelho com transparencia
  TRACKING_LINE_WEIGHT: 4, // pixels
  TRACKING_LINE_STYLE: '3 6',

  AUTO_ROUTE_LINE_COLOR: '#1E90FF', // Azul Claro
  AUTO_ROUTE_LINE_WEIGHT: 4,

  DRAW_LINE_COLOR: '#0000FF', // Azul
  DRAW_LINE_WEIGHT: 5,

  OBSERVED_AREA_LINE_COLOR: '#F9FF41', // Amarelo com transparencia
  OBSERVED_AREA_LINE_WEIGHT: 4,
  OBSERVED_AREA_LINE_STYLE: '6 12',

  HIGHLIGHT_LINE_WEIGHT: 32,
  HIGHLIGHT_LINE_COLOR: '#ff9500',
  HIGHLIGHT_FILL_COLOR: '#ff9500',
  HIGHLIGHT_CIRCLE_RADIUS: 200,
  HIGHLIGHT_OPACITY: 0.7,

  ROUTE_ICON_SIZE: 32,
  TRACKING_ICON_SIZE: 48,
  ICON_SIZE: 48,
  MARKER_ICON_SIZE: 52,

  DEFAULT_LATITUDE: -22.70562,
  DEFAULT_LONGITUDE: -43.26366,
  DEFAULT_ZOOM: 5,
  FIT_ZOOM: 16,

  BOUNDS_PAD_RATIO: 0.05, // Aumenta em 5% - note que pad não muda o bounds atual, retorna um novo bounds
  GLASS_PANEL_OPACITY: 0.5,
  GLASS_OBJECT_OPACITY: 0.4,
  
  REAL_TIME_SIGNALS_REFRESH_INTERVAL: 5000, // milliseconds (5 seconds)

  POPUP_MIN_WIDTH: 350,

  MAX_DISTANCE_KM: 0.5, // 500 metros de distância para dividir o desenho do rastro

  TIME_MARKER_DISTANCE: 0.05, // 50 metros - distância minima entre marcadores temporais

  // na documentação não explica muito esse parametro
  // 1 equivale a uma única reta estre os pontos
  // quando menor, menos simplifica
  // por exemplo 0.01 simplificação alta
  // por exemplo 0.001 simplificação média
  // por exemplo 0.0001 simplificação baixa
  SIMPLIFY_POLYLINE_TOL: 0.0003, // tolerância para simplificação de rota automatizada

  MAX_LAYER_RESULT: 2000, // Número máximo de resultados na busca de camadas

  ARCGIS_TOKEN: "AAPKdf2b6c7b89af454399f8c0d31198b3b7R7JzNYwwf7-g0k000NMIjziT_VPhMSMnRwUZ2BSWe21Sk9Rprdm6Bj_64VkCdNH2"
};

// Storage
export const LOCAL_USER_KEY = 'localUser';
export const LOGIN_AS_USER_KEY = 'localLoginAsUser'
export const POPOUT_DATA_KEY = 'popoutData';
export const PROFILE_USER_KEY = 'localProfile';
export const SPECIFICS_PLACEMENTS = 'specificPlacements';

// Tracking
export const LOST_SIGNAL_LIMIT              = 60;     // seconds
export const SIGNAL_ICON_UPDATE_INTERVAL    = 30000;  // milliseconds (30 seconds)
export const LAST_SIGNALS_TRACKING_LIMIT    = 90;     // minutos (1 hora e meia)

// PATROL
export const SHIFT_DURATION = 12; // Hours

export enum HttpError {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404
}

// Mouse Enter time to show toastr for disabled button
export const MOUSE_ENTER_TIME = 800;  // milliseconds

// Golden Layout Events
export const PAGE_READY_PREFIX     = 'pageReady-'; // Emitida no ngAfterViewInit para avisar que página já pode receber dados
export const FILL_DATA_PREFIX      = 'fillData-'; // Emitida para preencher dados em uma página
export const PAGE_MAXIMIZED_PREFIX = 'pageMaximized-'; // Emitida pelo AppComp para forçar um calculo de resize no Mapa
export const LOCATION_UPDATE_PREFIX = 'locationUpdate-'; // Emitido pelo modo de edição de operação no mapa, para a edição da operação

// TODO scuri remover no futuro e tratar diretamente of *_UPDATED_EVENT pelas edições e mapa
export const UPDATE_DATA_PREFIX    = 'updateData-';  // Emitido pela lista para as edições e mapa, quando um item é atualizado pelo backend

// Essa atualizações são redundantes em relação aos *_UPDATE_EVENT. Mas funcionam localmente independente do retorno do backend
export const SAVE_DATA_PREFIX      = 'saveData-'; // Emitido pela edição para as listas e mapa quando um objeto é salvo
export const LIST_UPDATE_PREFIX    = 'listUpdate-'; // Emitido pela lista, para outras listas se atualizarem
export const DELETE_DATA_PREFIX    = 'deleteData-'; // Emitido pela edição ou lista para notificar listas ou edições que o item foi removido

export const ASSOCIATED_EVENT_UPDATE_PREFIX  = 'associatedEventUpdate-'; // Emitido pela lista de eventos, para avisar a edição do evento associado

// Essas acionam updateModelItem, que chama findAndUpdateModel, que emite UPDATE_DATA_PREFIX
export const REGISTRATION_UPDATE_EVENT = 'registrationUpdateEvent'; // Emitido pelo AppComp. quando chega uma atualização de cadastro do backend, escutado pelas listas
export const PATROL_UPDATE_EVENT       = 'patrolUpdateEvent'; // Emitido pelo AppComp. quando chega uma atualização de ronda do backend, escutado pelas listas
export const VERIFICATION_UPDATE_EVENT = 'verificationUpdateEvent'; // Emitido pelo AppComp quando chega uma atualização de verificação do backend, escutado pelas listas
// Eventos escutam diretamente a atualização do backend e acionam updateModelItem

export const MAP_PAGE             = 'map';
export const MESSAGE_PAGE         = 'message';
export const TRACKING_PAGE        = 'tracking';

export const MAX_TAB_TITLE = 30;

export const LatLongPattern = {
  'M': { pattern: new RegExp('[+-]') },
  '0': { pattern: new RegExp('-|[0-9]') },
  '9': { pattern: new RegExp('[0-9]') },
  'V': { pattern: new RegExp('[,]') }
};

// Máscara para lat,long na América do Sul
// Obrigatório inicio com '+' ou '-'. Obrigatório 6 casas decimais.
export const LatLongMask = "M99.999999,-99.999999";

/** Constantes para pontos */
export const PointSourceValues = [
  'Web'
  //'App'   opcão pendente: quando implementarmos a AMO-1, haverá outra opção "App"
];

export const AccessArrangementValues = [
  'Cruzando',
  'Paralelo (até 100m)',
  'Paralelo (sobre a faixa ou colado)'
];

export const AccessTypeValues = [
  'Privado aberto',
  'Privado com bloqueio',
  'Privado com bloqueio noturno',
  'Público'
];

export const BlockTypeValues = [
  'Porteira com cadeado aberto durante o dia',
  'Porteira com cadeado fechado',
  'Porteira com cadeado Transpetro',
  'Tronqueira aberta',
  'Tronqueira com cadeado aberto durante o dia',
  'Tronqueira com cadeado fechado'
];

export const RoadConditionValues = [
  'Precário',
  'Razoável'
];

export const NumberOfOutputsValues = [
  'Acesso sem saída',
  'Com entrada e saída',
  'Sem saída, mas com manobra'
];

export const TrafficVolumeValues = [
  'Pequeno',
  'Médio',
  'Alto',
  'Apenas Moradores'
];

export const TrafficPossibilityValues = [
  'Carreta Bi-trem',
  'Caminhão Toco',
  'Caminhão Truck'
];

export const ResidencesValues = [
  'Até 2',
  'De 2 a 10',
  'Mais de 10',
  'Nenhuma',
];

export const VideoMonitoringValues = [
  'Sinal telefônico',
  'Energia',
  'Iluminação pública'
];

export const EvaluationValues = [
  'Positivo',
  'Negativo',
  'Indefinido'
];

export const AreaUnderControlValues = [
  'Estado',
  'Facções criminosas',
  'Paramilitares'
];

export const VulnerabilityViewValues = [
  'Alta',
  'Baixa',
  'Moderada'
];
export const CategoryValues = [
  'Acesso',
  'Corpos hídricos',
  'Pontos vulneráveis'
];

export const AccessCriticalityValues = [
  'Alta',
  'Baixa',
  'Moderada'
];

/** Constantes para formulários */
export const InspectionTypeValues = [
  'Suspeita Furto',
  'Conservação da Faixa',
  'Área de Válvula',
  'Inspeção Canina',
  'Levantamento de Campo',
  'Levantamento de Corpo Hídrico',
  'Derivação Clandestina'
];

