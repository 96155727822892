<mat-form-field appearance="outline" fxFlex [ngClass]="{'mat-form-field-invalid': invalid}">
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput name="strDate" 
    [(ngModel)]="valueData" #textdate="ngModel"
    (keyup)="onKeyUpEvent($event)" (keypress)="onKeyPressEvent($event)"
    [disabled]="_disabled"  [required]="_required">
  <input #input matInput name="pickerDate" [matDatepicker]="picker" [hidden]="true" [(ngModel)]="date"
    (dateChange)="onDateChangeEvent()">
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="_disabled"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint class="mat-error" *ngIf="textdate.hasError('required')">Campo Obrigatório</mat-hint>
</mat-form-field>
