import { Injectable } from "@angular/core";
import { FilterModel } from 'src/app/general/filter-component/filter.model';

@Injectable({
  providedIn: 'root'
})
export class MarkerFilterModel extends FilterModel{

  operationId:    string;
  operationIds:    string[];
  operationType:  string;
  inspectionId:   string;
  patrolTeamId:   string;
  startDate:      number;
  endDate:        number;
  channelId:      string;
}
