import { EntityModel } from './entity.model';

export class PatrolRepetitionModel extends EntityModel {

    referencePatrolId: string;
    referencePatrolIdentifier: string;

    startDate: number;
    startTime: string; // Hora local, em geral UTC-3

    dayWeek:         boolean;
    eachPeriod:      boolean;
    daysOfWeek: number[] = [];
    theEachQuantity: number;
    unitPeriodSelected: string;

    neverFinish:     boolean;
    afterNumberRepetitionsToFinish: boolean;
    finishDate: boolean;
    endDate:    number;
    afterRepetitionQuantity: number;

    excludedRepetitions: string[] = [];

    constructor(){
        super();
    }

}