import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../pages/login/login-services';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class InvalidTokenApiService implements HttpInterceptor {
  constructor(private logger: NGXLogger, private authenticationService: AuthenticationService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        // Se o auth retornar erro no token, temos que fazer logout
        // Unauthorized ou Forbidden
        if (errorResponse.status === 401 || errorResponse.status === 403) {
          // Comentado para que apenas apareca a mensagem de erro
          // this.authenticationService.logout()
          this.logger.error('Token Inválido na chamada ' + errorResponse.status);
          this.authenticationService.reloadToken();
        }
        return throwError(errorResponse);
      })
    );
  }
}
