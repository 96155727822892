<div mat-dialog-title>
  <span>Filtrar Verificações</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="pt-3 pl-4">
    <label id="period-group-label " class="mr-4">Arquivados:</label>
    <mat-radio-group aria-labelledby="period-group-label" [(ngModel)]="filterModel.archived">
      <mat-radio-button class="mr-4" color="accent" [value]="archivedFilterType.ALL">Todos</mat-radio-button>
      <mat-radio-button class="mr-4" color="accent" [value]="archivedFilterType.ARCHIVED">Arquivados</mat-radio-button>
      <mat-radio-button color="accent" [value]="archivedFilterType.NON_ARCHIVED">Não Arquivados</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="p-5">
    <label id="period-group-label" class="mr-4">Período:</label>
    <mat-radio-group class="mr-4" aria-labelledby="period-group-label" [(ngModel)]="filterModel.current">
      <mat-radio-button class="mr-4" color="accent" [value]="true">Hoje</mat-radio-button>
      <mat-radio-button color="accent" [value]="false">Especificado</mat-radio-button>
    </mat-radio-group>
  </div>
  <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2"
    [hidden]="filterModel.current == true">
    <date-chooser placeholder="Data Inicial" [(valueData)]="viewFilterStartDate" #startDateEl="dateChooserContext" fxFlex></date-chooser>

    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Hora Inicial</mat-label>
      <input matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="startTime" placeholder="Hora Inicial" [(ngModel)]="viewFilterStartTime">
      <mat-icon matSuffix>query_builder</mat-icon>
    </mat-form-field>

    <date-chooser placeholder="Data Final" [(valueData)]="viewFilterEndDate" #endDateEl="dateChooserContext" fxFlex></date-chooser>

    <mat-form-field appearance="outline" class="mr-2" fxFlex>
      <mat-label>Hora Final</mat-label>
      <input matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="endTime" placeholder="Hora Final" [(ngModel)]="viewFilterEndTime">
      <mat-icon matSuffix>query_builder</mat-icon>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

    <multiselect-autocomplete [data]="operationStatusDescription | keyvalue: originalOrder" name="value" optionValue="key" placeholder="Status"
      [(valueData)]="filterModel.status" fxFlex>
    </multiselect-autocomplete>

    <multiselect-autocomplete [data]="analysts" name="name" optionValue="id" placeholder="Analista"
      [(valueData)]="filterModel.analysts" fxFlex>
    </multiselect-autocomplete>

    <multiselect-autocomplete [data]="entityCacheService.getPatrolTeams()" name="name" optionValue="id" placeholder="Equipe"
      [(valueData)]="filterModel.patrolTeams" fxFlex>
    </multiselect-autocomplete>

    <multiselect-autocomplete [data]="entityCacheService.getPlacements()" name="name" optionValue="id" placeholder="Lotação"
      [(valueData)]="filterModel.placements" fxFlex>
    </multiselect-autocomplete>

    <div class="form-spacer" fxFlex></div>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="clearFilter()">Limpar</button>
  <button mat-raised-button [disabled]="startDateEl.invalid || endDateEl.invalid" (click)="onFilter()">Aplicar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
