import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_VERSION_ENDPOINT } from '../../common/constants';

@Injectable({
  providedIn: 'root'
})
export class ReactiveService {
  protected readonly routeDeviationApiUrl: string = `${environment.settings.route_deviation_address}/route-deviation`;
  protected readonly simfApiUrl: string = `${environment.settings.simf_address}/simf`;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Obtem a versão do projeto reactive - modulo Route deviation
   */
  public getRouteDeviationVersion(): Observable<any> {
    return this.httpClient.get(`${this.routeDeviationApiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }

  /**
   * Obtem a versão do projeto reactive - modulo SIMF
   */
  public getSIMFVersion(): Observable<any> {
    return this.httpClient.get(`${this.simfApiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }
}
