<div mat-dialog-title>
    <span>Importar KML</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
  <div fxLayout="column wrap" class="h-100 ">
    <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
      <mat-card fxFlex class="form-spacer mb-3 bg-shade-100 mat-elevation-z border">
        <app-upload [(filename)]="filename" class="app-upload"
          file_label="KML de Pontos" button_text="Selecionar..." file_icon="location_on"
          file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="selectFile($event)">
        </app-upload>
      </mat-card>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="select()" [disabled]="!kmlFile">Importar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
