import { RouteModel } from './route.model';
import { InspectionModel } from './inspection.model';
import { PatrolTeamModel } from './patrolteam.model';
import { CommonModel } from './common.model';
import { FormModel } from './form.model';
import { OperationStatusDescription, OperationType, OperationTypeDescription } from './enums.enum';
import { PlacementModel } from './placement.model';
import { CompanyModel } from './company.model';
import { AttachmentModel } from './attachment.model';
import LanguageUtils from '../service/util/language-utils';

export abstract class OperationModel extends CommonModel {
  startDate:          number; // Em Rondas é a data prevista de início, em Verificações é inicializada com o mesmo valor de sentDate
  updatedAt:          number;
  priority:           boolean;
  expired:            boolean;
  observations:       string;
  route:              RouteModel;
  inspections:        InspectionModel[];
  patrolTeam:         PatrolTeamModel;
  type:               string;
  autoRoute:          boolean;
  forms:              FormModel[];
  stretch:            string;
  band:               string;
  duct:               string;
  km:                 string;
  placement :         PlacementModel;
  company:            CompanyModel;
  serviceType:        string;
  executedPoints:     string;
  valve:              string;
  latLong:            string;
  stretchStartKM:     string;
  stretchEndKM:       string;
  stretchStartLatLong: string;
  stretchEndLatLong:  string;
  situationFound:     string;
  dateSituationFound: number;
  attachmentOpFile1:  AttachmentModel;
  attachmentOpFile2:  AttachmentModel;
  attachmentOpFile3:  AttachmentModel;
  attachmentOpFile4:  AttachmentModel;
  attachmentOpFile5:  AttachmentModel;

  // usados apenas no front-end: 
  lastSync: number;

  static getPopupContent(operation: OperationModel): string {
    const priorityName = operation.priority? (operation.type==OperationType.PATROL? " (Bloqueada)": " (Prioritária)"): "";
    return `<h6 style="text-align: center"> ${OperationTypeDescription[operation.type] } ${operation.identifier} - <b>${ OperationStatusDescription[operation.status ] + priorityName }</b></h6>
            ${operation.type == OperationType.PATROL? '<div> <b>Nome da Ronda: </b>' + (operation as any).name + '</div>' : ''}
            `;
  }
  
  static addLanguageMap(){
    LanguageUtils.languageMap['priority'] = 'Prioritária/Bloqueada';
    LanguageUtils.languageMap['route'] = 'KML de Rota';
    LanguageUtils.languageMap['inspections'] = 'Pontos';
    LanguageUtils.languageMap['patrolTeam'] = 'Equipe';
    LanguageUtils.languageMap['situationFound'] = 'Quadro Encontrado';
    LanguageUtils.languageMap['valve'] = 'Válvula';
    LanguageUtils.languageMap['attachmentOpFile1'] = 'Anexo 1';
    LanguageUtils.languageMap['attachmentOpFile2'] = 'Anexo 2';
    LanguageUtils.languageMap['attachmentOpFile3'] = 'Anexo 3';
    LanguageUtils.languageMap['attachmentOpFile4'] = 'Anexo 4';
    LanguageUtils.languageMap['attachmentOpFile5'] = 'Anexo 5';
    LanguageUtils.languageMap['autoRoute'] = 'Rota Automatizada';
    LanguageUtils.languageMap['forms'] = 'Formulários';
    LanguageUtils.languageMap['valve'] = 'Válvula';
    }
}
