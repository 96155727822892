import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { EditComponent } from 'src/app/pages/edit-component';
import { NGXLogger } from 'ngx-logger';
import { FormService } from 'src/app/service/model/form.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/service/storage-service';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import * as GoldenLayout from 'golden-layout';
import { environment } from 'src/environments/environment';
import { FormModel } from 'src/app/model/form.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CrudType, NodeTypeDescription, Permission, QuestionType, UserType } from 'src/app/model/enums.enum';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { QuestionModel } from 'src/app/model/question.model';
import { Observable, Subscription } from 'rxjs';
import { QuestionCrudData, QuestionFormComponent } from '../question-form/question-form.component';
import { ConfirmationDialogComponent } from 'src/app/general/confirmation-dialog/confirmation-dialog.component';
import { first } from 'rxjs/operators';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { PlacementModel } from 'src/app/model/placement.model';
import { AuthenticationService } from '../../../login/login-services/authentication.service';

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.scss']
})
export class FormEditComponent extends EditComponent implements OnInit, OnDestroy{

  templateIdentifier: string | null;

  // Controle da árvore (Template)
  treeControl : NestedTreeControl<QuestionModel>;
  questions : MatTreeNestedDataSource<QuestionModel>;

  // Subscriptions
  quizDataSubscription: Subscription;

  //ENUMS
  quizNodeType = QuestionType;
  nodeTypeDescription = NodeTypeDescription;
  questionCrudType = CrudType;
  userPlacementId : string;

  constructor( logger:                     NGXLogger,
               public formService:         FormService,
               public singleDataCacheService:  SingleDataCacheService,
               dialog:                     MatDialog,
               protected http:             HttpClient,
               public entityCacheService:  EntityCacheService,
               toastr:                     ToastrService,
               protected storageService:   StorageService,
               authorizationService:     AuthorizationService,
               protected authenticationService:    AuthenticationService,
               @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
               @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super (logger, formService, dialog, environment.FORM_MODEL_LABEL, environment.FORM_TITLE_LABEL,
           storageService, 'forms-edit', environment.FORM_GROUP_LABEL,toastr, authorizationService, goldenLayout, container )
  }


  ngOnInit(): void {
    this.logger.info('**** FormEditComponent - ngOnInit()');
    this.treeControl = new NestedTreeControl<QuestionModel>(this.getChildren);
    this.questions = new MatTreeNestedDataSource();
    this.subscribeOnQuizDataChanges();
    this.userPlacementId = this.authenticationService.getUserPlacementId();
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.logger.debug('**** QuizBuilderComponent - ngOnDestroy()');

    this.quizDataSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  private subscribeOnQuizDataChanges(){
    this.logger.debug('**** subscribeOnQuizDataChanges(): Inscrição para ouvir as mudanças no template');

    /** Inscrição para ouvir mudanças na árvore */
    this.quizDataSubscription = this.formService.onQuizDataChange().subscribe( (quizData: FormModel) => {
      this.logger.trace('**** quizDataSubscription - Notificação de mudança no template');

      if(quizData.id == this.model.id){
        this.questions.data = quizData?.questions;
        this.view['questions'] = quizData?.questions;
        this.renderChanges();
      }
    });
  }

  // Métodos de controle da árvore
  private getChildren = ( node: QuestionModel ) => node.children;

  hasNestedChild = (_: number, node: QuestionModel) => {
    return (node.children && node.children.length > 0);
  }

  getQuizIndex(quizNode: QuestionModel) {
     return this.questions.data.indexOf(quizNode) + 1;
  }

  /** Método de edição de nós na árvore */
  onEdit(quizNode: QuestionModel){
    this.handleQuestionCrud(CrudType.EDIT, quizNode, this.view as FormModel); // Edição de pergunta
  }

  /** Método de remoção de nós na árvore */
  onRemove(quizNode: QuestionModel){
    this.removeQuestion(quizNode);
  }

  loadFormOptionsData(){
    this.logger.debug('FormEditComponent.loadFormOptionsData()');

    this.entityCacheService.loadPlacements(this.loadingListService);

    const metadatas: string[] = [
      "InspectionType"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  createData(options?) {
    this.logger.debug('FormEditComponent.createData()');
    this.model = new FormModel();
    this.view = {};
    this.questions.data = [];
    this.view['questions'] = [];

    this.view['identifier'] = '[NOVO]';
  }

  // Necessário para a atualização da árvore
  public renderChanges(): void {
    const data = this.questions.data;
    this.questions.data = [];
    this.questions.data = data;
  }

  /**
   * Método que abre o componente de CRUD das perguntas e exibe um toast em caso positivo
   * @param quizNode No pai no caso de inserção ou nó a ser editado
   * @param crudType Tipo de CRUD (ADD ou EDIT)
   */
   handleQuestionCrud(crudType: CrudType, quizNode?: QuestionModel, formModel?: FormModel){
    const questionCrudData = new QuestionCrudData(crudType );
    questionCrudData.quizNode = quizNode as QuestionModel;
    questionCrudData.formModel = formModel as FormModel;

    this.openQuestionDialog(questionCrudData).pipe(first()).subscribe( (successed : boolean) => {
      if(successed){
        this.logger.debug('**** handleQuestionCrud(quizNode: QuestionModel, crudType: QuestionCrudType): Pergunta salva / editada com sucesso');
      }
    }, error => {
      this.toastr.error('Ocorreu um erro ao tentar salvar/editar a pergunta');
      this.logger.error(error);
    });
  }

  /** Remoção de questões da árvore */
  removeQuestion(quizNode: QuestionModel){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: "Tem certeza que deseja remover a pergunta: " + quizNode.label +
        "? Ao fazer isso, todas as subperguntas em consequência desta pergunta serão perdidas",
        title: 'Atenção',
        okLabel: 'Remover Pergunta'
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
    if (result) {
      this.formService.removeQuestion(quizNode, this.view as FormModel);
      this.toastr.info('Pergunta removida');
      this.logger.debug('**** removeQuestion(quizNode: QuestionModel): Pergunta removida com sucesso');

      }
    }, (error) => {
      this.toastr.error('Ocorreu um erro ao tentar remover a pergunta');
      this.logger.error(error);
    }
    );
  }

  /** Abre diálog de CRUD das perguntas */
  openQuestionDialog(questionCrudData: QuestionCrudData) : Observable<boolean>{
    const dialogRef = this.dialog.open(QuestionFormComponent, {
      width: '900px',
      panelClass: 'sipd-modal',
      data: questionCrudData
    });
    return dialogRef.afterClosed();
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['identifier'] = null;
    this.model['published'] = false;
    this.model['name'] = ' ';
  }

  isRequiredFieldFilled(): boolean {
    if (!this.view['name'] ||
        !this.view['placement'] ||
        !this.view['inspectionType']) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    if (!this.view['placement']) names.push('Lotação');
    if (!this.view['inspectionType']) names.push('Tipo de Inspeção');
    return names;
  }

  /** Altera o published para true */
  onPublishClick(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: "Tem certeza de que deseja publicar o formulário?",
        title: 'Atenção',
        okLabel: 'Publicar'
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
    if (result) {
      this.isSaving = true;
      this.backupModel = this.copyModel(this.model);
      this.mapViewToModel();
      
      this.model['published'] = true;
      this.saveEditedModel();
    }});
  }

  clearPreviousData(){
    this.questions.data = [];
  }

  mapModelToView() {
    super.mapModelToView();
    this.logger.debug('FormEditComponent.mapModelToView()');

    if (this.model['questions']) {
      this.questions.data = this.model['questions'];
      this.view['questions'] = this.model['questions'];
    }

    if (this.view['name']) {
      this.glUpdateTabTitle(this.modelName + ': "' + this.view['name'] + '"');
    }
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if(this.isUserAdministrator())
      return true;

    if(!this.copy &&  this.model['placement']?.id != this.userPlacementId){
        return false;
    }

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_FORM)){
      return false
    }

    return true;
  }

  canPublish() : boolean {
    return !!this.view && !!this.view['id'] &&  // Só pode publicar se estiver criada
    !this.isPublished() && // Só pode publicar uma vez
    (this.isUserAdministrator() || this.model['placement']?.id == this.userPlacementId) // se administrador ou a lotação de origem do usuário é igual à lotação do formulário
  }

  isPublished(): boolean {
    return this.view['published'];
  }

  /** Indica se o usuário logado é ou não um administrador. */
  isUserAdministrator(): boolean {
    return this.loggedUserProfile.userType === UserType.ADMINISTRATOR;
  }
}
