import { GeoModel } from './geo.model';
import { environment } from 'src/environments/environment';

export class SimfModel extends GeoModel{
  band:        string; // Nome da faixa
  bandId:      string; // Id da faixa
  stretch:      string;  // Nome do trecho
  extension:    string;

  nameCombo:    string; // Para o filtro de alertas

  public static getPopupContent(feature): string{

    if (feature && feature.properties){
      
      const simf = feature.properties as SimfModel;

      let states: string = simf.states ? simf.states.toString() : '';
      let contents: string =  `<h5 style="text-align: center"> SIMF </h5>
                               <h6 style="text-align: center"><b>${ simf.name }</b></h6>
                               <div><b>Faixa:</b> ${simf.band? simf.band: ''} </div>
                               <div><b>Trecho:</b> ${simf.stretch? simf.stretch: ''} </div>
                               <div><b>Extensão (m):</b> ${simf.extension? simf.extension: ''} </div>
                               <div><b>UF(s):</b> ${states} </div>
                               `;
      if (!environment.showPopupLayerTables || simf.description == null || simf.description === "") {                              
        return contents;
      }
      else {
        return contents + "<div style='height:200px;overflow:auto;'><hr/>" + simf.description
      }
    }

    return `<h5>Sem Dados</h5>`;
  }
}


