<div class="sipd-container">

  <header class="sipd-header">
    <span class="mat-title m-0">Área Observada: {{view['identifier']}}</span>
    <div class="m-0 text-emphasis-2">
      <span class="header-tag ml-2" *ngIf="view['status']"> <span>&#9679;</span> {{ statusDescription[view['status']] }}</span>
    </div>
    <div class="fill-space"></div>
    <div class="actions">
      <button mat-raised-button 
        matTooltip="Habilitar Edição"
        [hidden]="!canEdit() || !readOnly"
        (click)="onEnableEditClick()">
        Habilitar Edição
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar"
        (click)="onCopyClick()"
        [disabled]="!id"
        [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_OBSERVED_AREA)'>
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDeleteClick()"
        matTooltip="Remover"
        [hidden]='!authorizationService.userHasPermission(permission.DELETE_OBSERVED_AREA)'>
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <div>
        <button mat-icon-button style="vertical-align: middle; font-size: 1.5rem;" 
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_USER)'
          [disabled]="readOnly"
          [matMenuTriggerFor]="sideMenu" matTooltip="Alterar Status">
          <img src="assets/icons/event_status.png" />
        </button>
        <mat-menu #sideMenu="matMenu">
          <button mat-menu-item  (click) = "view['status'] = status.ACTIVE">
            <span>{{ statusDescription.ACTIVE }}</span>
          </button>
          <button mat-menu-item (click)= "view['status'] = status.INACTIVE">
            <span>{{ statusDescription.INACTIVE }}</span>
          </button>
        </mat-menu>
      </div>
      <button mat-icon-button  (click)="onLocationClick(this.view, mapEvents.OBSERVED_AREA_LOCATION)" matTooltip="Mostrar no Mapa" [disabled]="!hasLocation()">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!canRevert()" (click)="onRevertClick()" 
        [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_OBSERVED_AREA)'
        matTooltip="Desfazer Modificações">
        <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
      </button>
      <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
        <button mat-icon-button [disabled]="!canSave() || startDateEl.invalid || startTimeEl.invalid || endDateEl.invalid  || endTimeEl.invalid
                                                       || volumeCheckDateEl.invalid|| km.invalid || stretchStartKM.invalid || stretchEndKM.invalid
                                                       || latLong.invalid || stretchStartLatLong.invalid || stretchEndLatLong.invalid"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_OBSERVED_AREA)'
          (click)="onSaveClick($event)" matTooltip="Salvar">
          <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
      </div>
    </div>
  </header>

  <mat-divider></mat-divider>

  <div class="sipd-content">
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms" color="accent" class="-bg-white h-100 -overflow">

      <!-- Identificação (Tab) -->
      <mat-tab label="Identificação">
        <form class="edit-form">

          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

            <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
              <mat-label>Data/Hora (Criação)</mat-label>
              <input readonly matInput name="createdAt" matTooltip="{{ view['createdAt'] }}" value="{{ view['createdAt'] }}">
            </mat-form-field>

            <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
              <mat-label>Cadastrador</mat-label>
              <input readonly matInput matTooltip="{{ view['creator'] | toView }}" name="name" placeholder="Cadastrador" value="{{ view['creator'] | toView }}" >
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Nome da Área</mat-label>
              <input [readonly]="readOnly" matInput matTooltip="{{ view['name'] }}" name="name" placeholder="Nome da Área" [(ngModel)]="view['name']" required>
            </mat-form-field>

            <single-autocomplete [data]="areaTypeDescriptions | keyvalue: originalOrder" name="value" optionValue="key" placeholder="Tipo" [(valueData)]="view['areaType']"
              [required]="true" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="responsibles" name="name" placeholder="Responsável" [(valueData)]="view['responsible']"
              [required]="true" [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <date-chooser placeholder="Data de Início" [(valueData)]="view['startDate']" [disabled]="readOnly"
               matTooltip="{{ view['startDate'] }}" #startDateEl="dateChooserContext" [required]="true" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Hora Início</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" name="startTime" placeholder="Hora Início"
                matTooltip="{{ view['startTime'] }}" [(ngModel)]="view['startTime']" #startTimeEl="ngModel" required>
              <mat-icon matSuffix>query_builder</mat-icon>
              <mat-error>Campo Obrigatório</mat-error>
              <mat-hint class="mat-error" *ngIf="!startTimeEl.valid">Formato inválido para a hora</mat-hint>
            </mat-form-field>

            <date-chooser placeholder="Data de Fim" [(valueData)]="view['endDate']" [disabled]="readOnly"
               matTooltip="{{ view['endDate'] }}" #endDateEl="dateChooserContext" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Hora Fim</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="endTime" placeholder="Hora Fim"
                #endTimeEl="ngModel" matTooltip="{{ view['endTime'] }}" [(ngModel)]="view['endTime']">
              <mat-icon matSuffix>query_builder</mat-icon>
              <mat-hint class="mat-error" *ngIf="!endTimeEl.valid">Formato inválido para a hora</mat-hint>
            </mat-form-field>

            <single-autocomplete [data]="singleDataCacheService.getValues('Product')" placeholder="Produto" [(valueData)]="view['product']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Volume (m³)</mat-label>
              <input [readonly]="readOnly" name="volume" matTooltip="{{ view['volume'] }}"  matInput type="number" step=".01" placeholder="Volume (m3)"
                [(ngModel)]="view['volume']">
            </mat-form-field>

            <date-chooser placeholder="Data Verificação de Volume" [(valueData)]="view['volumeCheckDate']" [disabled]="readOnly"
               matTooltip="{{ view['volumeCheckDate'] }}" #volumeCheckDateEl="dateChooserContext" fxFlex>
            </date-chooser>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Hora Verificação de Volume</mat-label>
              <input [readonly]="readOnly" matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="volumeCheckTime" placeholder="Hora Verificação de Volume"
                #endVolumeCheckTimeEl="ngModel" matTooltip="{{ view['volumeCheckTime'] }}" [(ngModel)]="view['volumeCheckTime']">
              <mat-icon matSuffix>query_builder</mat-icon>
              <mat-hint class="mat-error" *ngIf="!endVolumeCheckTimeEl.valid">Formato inválido para a hora</mat-hint>
            </mat-form-field>

            <single-autocomplete [data]="singleDataCacheService.getValues('CommunicationChannel')" placeholder="Canal de Comunicação" [(valueData)]="view['channel']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline" fxFlex class="no-hint-text mat-form-field-readonly">
              <mat-label>Atualizado</mat-label>
              <input readonly matInput name="updatedAt" matTooltip="{{ view['updatedAt'] }}" value="{{ view['updatedAt'] }}">
            </mat-form-field>

            <div class="w-100">
              <!-- Notas -->
              <mat-form-field appearance="outline" fxFlex="100%" class="w-100">
                <mat-label>Notas</mat-label>
                <textarea name="text" matInput [(ngModel)]="view['notes']" placeholder="Notas" rows="5" [readonly]="readOnly"></textarea>
              </mat-form-field>
            </div>

            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
          </div>
        </form>
      </mat-tab>

      <!-- Localização (Tab) -->
      <mat-tab label="Localização">
        <form class="edit-form">
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pb-3 pr-2">

            <single-autocomplete [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa" [(valueData)]="view['band']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="singleDataCacheService.getValues('Duct')" placeholder="Duto" [(valueData)]="view['duct']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>KM</mat-label>
              <input [readonly]="readOnly" name="km" matInput matTooltip="{{ view['km'] }}" placeholder="KM" [(ngModel)]="view['km']"
                #km="ngModel" km [dropSpecialCharacters]="false" mask="000+000" [validation]="true" />
              <mat-error *ngIf="km.invalid">Formato inválido para o KM.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>KM (Lat, Long)</mat-label>
                <input [readonly]="readOnly" name="latLong" matInput placeholder="KM (Lat, Long)" matTooltip="{{ view['latLong'] }}"
                [(ngModel)]="view['latLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'latLong')"
                #latLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="true" [required]="!hasLocation()"/>
                <mat-error *ngIf="latLong.invalid">Formato inválido para o KM (Lat, Long)).</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Início Trecho (KM)</mat-label>
              <input [readonly]="readOnly" name="stretchStartKM" matInput placeholder="Início Trecho (KM)" matTooltip="{{ view['stretchStartKM'] }}"
                [(ngModel)]="view['stretchStartKM']" #stretchStartKM="ngModel" stretchStartKM
                [dropSpecialCharacters]="false" mask="000+000" [validation]="true" />
              <mat-error *ngIf="stretchStartKM.invalid">Formato inválido para o Início do trecho (KM).</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Fim Trecho (KM)</mat-label>
              <input [readonly]="readOnly" name="stretchEndKM" matInput placeholder="Fim Trecho (KM)" [(ngModel)]="view['stretchEndKM']"
                matTooltip="{{ view['stretchEndKM'] }}" #stretchEndKM="ngModel" stretchEndKM [dropSpecialCharacters]="false" mask="000+000" />
              <mat-error *ngIf="stretchEndKM.invalid">Formato inválido para o Fim do trecho (KM).</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Início Trecho (Lat, Long)</mat-label>
              <input [readonly]="readOnly" name="stretchStartLatLong" matInput placeholder="Início Trecho (Lat, Long)" matTooltip="{{ view['stretchStartLatLong'] }}"
                [(ngModel)]="view['stretchStartLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'stretchStartLatLong')"
                #stretchStartLatLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="false" [required]="!hasLocation()"/>
              <mat-error *ngIf="stretchStartLatLong.invalid">Formato inválido para o Início do trecho (Lat, Long).</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Fim Trecho (Lat, Long)</mat-label>
              <input [readonly]="readOnly" name="stretchEndLatLong" matInput placeholder="Fim Trecho (Lat, Long)" matTooltip="{{ view['stretchEndLatLong'] }}"
                [(ngModel)]="view['stretchEndLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'stretchEndLatLong')"
                #stretchEndLatLong="ngModel" [patterns]="latLongPattern" [dropSpecialCharacters]="false" [validation]="false" [required]="!hasLocation()"/>
              <mat-error *ngIf="stretchEndLatLong.invalid">Formato inválido para o Fim do trecho (Lat, Long).</mat-error>
            </mat-form-field>

            <single-autocomplete [data]="singleDataCacheService.getValues('PipeStretch')" placeholder="Trecho" [(valueData)]="view['stretch']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="singleDataCacheService.getValues('City')" placeholder="Cidade" [(valueData)]="view['city']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <multiselect-autocomplete [data]="singleDataCacheService.getValues('State')" placeholder="UF(s)"
              [(valueData)]="view['states']" [disabled]="readOnly" fxFlex>
            </multiselect-autocomplete>

            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>

          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
    <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
  </div>
</div>
