import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import * as moment from 'moment';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { LatLongMask, LatLongPattern } from 'src/app/common/constants';
import { MapEvents, ObservedAreaStatus, ObservedAreaStatusDescription, ObservedAreaTypeDescription, Permission, UserType } from 'src/app/model/enums.enum';
import { ObservedAreaModel } from 'src/app/model/observed.area.model';
import { UserModel } from 'src/app/model/user.model';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { ObservedAreaService } from 'src/app/service/model/observed.area.service';
import { StorageService } from 'src/app/service/storage-service';
import FieldUtils from 'src/app/service/util/field-utils';
import { environment } from 'src/environments/environment';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { EditComponent } from '../../../edit-component';
import { Subscription } from 'rxjs';
import DateUtils from 'src/app/service/util/date-utils';

@Component({
  selector: 'app-observed-area-edit',
  templateUrl: './observed-area-edit.component.html',
  styleUrls: ['./observed-area-edit.component.scss']
})
export class ObservedAreaEditComponent extends EditComponent implements OnInit, OnDestroy {

  mapEvents = MapEvents;

  status = ObservedAreaStatus;
  statusDescription = ObservedAreaStatusDescription;

  areaTypeDescriptions = ObservedAreaTypeDescription;

  /** Os usuários carregados do serviço do ccpd-registrations */
  responsibles: UserModel[];

  startDate: moment.Moment;
  endDate: moment.Moment;
  volumeCheckDate: moment.Moment;

  latLongPattern = LatLongPattern;
  latLongMask = LatLongMask;

  private reloadUsersSubscription: Subscription;

  constructor(logger:                           NGXLogger,
              protected observedAreasService:   ObservedAreaService,
              public singleDataCacheService:    SingleDataCacheService,
              public storageService:            StorageService,
              public entityCacheService:        EntityCacheService,
              protected dialog:                 MatDialog,
              public toastr:                 ToastrService,
              public authorizationService:   AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
          super(logger, observedAreasService, dialog, environment.OBSERVED_AREA_MODEL_LABEL, environment.OBSERVED_AREA_TITLE_LABEL, storageService,
          'observed-areas-edit', environment.OBSERVED_AREA_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
}

  ngOnInit(): void {
    this.initializeFields(); // Precisa vir antes da super.ngOnInit

   super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.reloadUsersSubscription?.unsubscribe();
  }

  createData(options?: any) {
    this.logger.debug('ObservedAreaEditComponent.createData()');

    this.model = new ObservedAreaModel();
    this.view = {};

    this.initializeFields();
      
    this.currentUserAsField('creator');

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['identifier'] = null;
    this.model['status'] = ObservedAreaStatus.ACTIVE;
  }

  loadFormOptionsData(){
    let _this = this; // Necessário por causa do contexto das callacks
    const onUsersLoad = function() {
      const responsibleUserTypes = [UserType.ANALYSIS_CCPD, UserType.COORDINATOR_CCPD, UserType.COORDINATOR_OPPD, UserType.PLANNER];
      _this.responsibles = _this.entityCacheService.getFilteredUsers(responsibleUserTypes);
    };
    this.entityCacheService.loadUsers(this.loadingListService, onUsersLoad);
    this.reloadUsersSubscription = this.entityCacheService.onUsersReload().subscribe(onUsersLoad);

    const metadatas: string[] = [
      "CommunicationChannel",
      "Band",
      "PipeStretch",
      "City",
      "State",
      "Product",
      "Duct"];
  
    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  onEnableEditClick() {
    if (this.canEdit()) {
      this.readOnly = false;
      
      if (!this.view['creator']) {
        this.currentUserAsField('creator');
      }
    }
  }

  initializeFields(){
    this.view['identifier'] = "[NOVA]";
    this.view['type'] = 'ObservedArea';
    this.view['states'] = [];
    this.view['status'] = ObservedAreaStatus.ACTIVE;

    this.startDate = moment();
    this.view['startDate'] = this.startDate.format('DD/MM/yyyy');
    this.view['startTime'] = this.startDate.format('HH:mm');

    this.view['endDate'] = '';
    this.view['endTime'] = '';

    this.volumeCheckDate = moment();
    this.view['volumeCheckDate'] = '';
    this.view['volumeCheckTime'] = '';
  }

  mapModelToView() {
    this.logger.debug('ObservedAreaEditComponent.mapModelToView()');

    super.mapModelToView();

    if (!this.model['status']) {
      this.model['status'] = ObservedAreaStatus.ACTIVE;
    }

    if (this.model['createdAt'] && this.model['id'] !=  null) {
      this.startDate = moment(this.model['createdAt']);
    }
    else {
      this.startDate = moment();
    }
    this.view['createdAt'] = this.startDate.format('DD/MM/yyyy HH:mm:ss');

    if (this.model['updatedAt']) {
      this.startDate = moment(this.model['updatedAt']);
      this.view['updatedAt'] = this.startDate.format('DD/MM/yyyy HH:mm:ss');
    }

    if (this.model['startDate']) {
      this.startDate = moment(this.model['startDate']);
    }else {
      this.startDate = moment();
    }
    this.view['startDate'] = this.startDate.format('DD/MM/yyyy');
    this.view['startTime'] = this.startDate.format('HH:mm');

    if (this.model['endDate']) {
      this.endDate = moment(this.model['endDate']);
      this.view['endDate'] =  this.endDate.format('DD/MM/yyyy');
      this.view['endTime'] = this.endDate.format('HH:mm');
    }
    else {
      this.view['endDate'] =  '';
      this.view['endTime'] =  '';
    }

    if (this.model['volumeCheckDate']) {
      this.volumeCheckDate = moment(this.model['volumeCheckDate']);
      this.view['volumeCheckDate'] = this.volumeCheckDate.format('DD/MM/yyyy');
      this.view['volumeCheckTime'] = this.volumeCheckDate.format('HH:mm');
    }
    else {
      this.volumeCheckDate = moment();
      this.view['volumeCheckDate'] = '';
      this.view['volumeCheckTime'] = '';
      }

    this.view['identifier'] = this.model['identifier'] ?  this.model['identifier'] : "[NOVA]";

    if (this.copy){
      this.currentUserAsField('creator');
    }

    this.glUpdateTabTitle(this.modelName + ': ' + this.view['identifier']);
  }

  mapViewToModel() {
    if (this.isSaving && this.isCreating())
      this.view['identifier'] = null;

    super.mapViewToModel();

    //Esse campos são controlados pelo backend. Não precisa enviar.
    this.model['createdAt'] = null;
    this.model['updatedAt'] = null;

    const startDate = DateUtils.stringDateTimeToTimestamp(this.view['startDate'], this.view['startTime'], true);
    this.model['startDate'] = startDate;

    const endDate = DateUtils.stringDateTimeToTimestamp(this.view['endDate'], this.view['endTime'], true);
    this.model['endDate'] = endDate;

    const volumeCheckDate = DateUtils.stringDateTimeToTimestamp(this.view['volumeCheckDate'], this.view['volumeCheckTime'], true);
    this.model['volumeCheckDate'] = volumeCheckDate;

    this.model['type'] = 'ObservedArea';
  }

  hasLocation(): boolean {
    return this.view &&
           ((this.view['latLong']) ||
            (this.view['stretchStartLatLong'] && this.view['stretchEndLatLong']));
  }
  
  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário
    this.replaceModelViewDateData(data, 'createdAt');
    this.replaceModelViewDateData(data, 'updatedAt');
    data['type'] = 'ObservedArea';
    this.replaceModelViewData(data, 'type');
  }

  dateChanged(fieldName: string) {
    if(fieldName === 'startDate'){
      this.view[fieldName] = this.startDate.format("DD/MM/yyyy");
    }else if(fieldName === 'endDate'){
      this.view[fieldName] = this.endDate.format("DD/MM/yyyy");
    }else if(fieldName === 'volumeCheckDate'){
      this.view[fieldName] = this.volumeCheckDate.format("DD/MM/yyyy");
    }
  }

  applyStartDate(dateString: string) {
    this.startDate = moment(dateString, "DD/MM/YYYY", true);
  }

  applyEndDate(dateString: string) {
    if (dateString === '') {
      this.endDate = null;
    }
    else {
      this.endDate = moment(dateString, "DD/MM/YYYY", true);
    }
  }

  applyVolumeCheckDate(dateString: string) {
    if (dateString === '') {
      this.endDate = null;
    }
    else {
      this.volumeCheckDate = moment(dateString, "DD/MM/YYYY", true);
    }
  }

  onPasteLatLong(event: ClipboardEvent, name: string){
    let pastedValue = FieldUtils.pasteLatLong(event, this.view[name]);
    if (pastedValue != null) {
       this.view[name] = pastedValue;
       return true;
    }
    return false;
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_OBSERVED_AREA)){
      return false
    }

    return true;
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['name'] ||
        !this.view['responsible'] ||
        !this.view['areaType'] ||
        !this.view['startDate'] ||
        !this.view['startTime'] ||
        !(DateUtils.stringDateTimeToTimestamp(this.view['startDate'], this.view['startTime'], true)) ||
        !this.hasLocation()) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['name']) names.push('Nome');
    if (!this.view['responsible']) names.push('Responsável');
    if (!this.view['areaType']) names.push('Tipo');
    if (!this.view['startDate']) names.push('Data de Início');
    if (!this.view['startTime']) names.push('Hora de Início');
    if (!this.hasLocation()) names.push('KM (Lat, Long) ou Trecho (Lat, Long)');
    return names;
  }
}
