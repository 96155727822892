<div class="sipd-container">

  <div class="sipd-header">
    <button mat-icon-button matTooltip="Filtrar Verificações" (click)="openFilterDialog()" color="accent"  [hidden]="editedEvent">
      <mat-icon class="material-icons-outlined">filter_list</mat-icon>
    </button>
    <span class="mat-title m-0" [hidden]="editedEvent">{{title}}</span><span [hidden]="editedEvent"><small matTooltip="Número de Registros Filtrados">[ {{getListCount()}} ]</small></span>
    <div class="fill-space"></div>
    <div class="actions" [hidden]="editedEvent">
      <mat-form-field class="list-filter  header-input" appearance="outline">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
    </div>

    <div class="actions">
      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <div matTooltip="É necessario salvar o evento atual para adicionar Verificação" [matTooltipDisabled]="canCreateVerification()">
      <button mat-icon-button color="primary"
              matTooltip="Adicionar Verificação"
              [hidden]="isHiddenButtonCreateEditVerification || !editedEvent"
              [disabled]="!canCreateVerification() || readOnly"
              (click)="onCreateVerificationClick()">
              <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
     </div>
      <button mat-icon-button color="primary"
        matTooltip="Abrir Verificação(es) Selecionada(s)"
        [disabled]="this.selection.selected.length == 0"
        (click)="onEditManyClick(this.selection.selected)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar Verificação Selecionada
                    (Somente 1 Verificação pode estar selecionada)"
        [hidden]="isHiddenButtonCreateEditVerification"
        [disabled]="this.selection.selected.length != 1 || readOnly"
        (click)="onCopyClick(this.selection.selected.pop())">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary" matTooltip="Remover Verificações Selecionadas"
        [disabled]="denyRemoveMany || readOnly" 
        (click)="onDeleteManyClick(selection.selected, $event)"
        [hidden]="isHiddenButtonDeleteVerification">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Exportar Verificação Selecionada ou
                                          Exportar Lista de Verificações"
        (click)="onExportManyClick()">
        <mat-icon class="material-icons-outlined">save_alt</mat-icon>
      </button>
      <button mat-icon-button [disabled]="denyArchiving || readOnly"
        (click)="onArchiveManyClick()"
        [matTooltip]= "archiveTooltipText()"
        [hidden]="isHiddenButtonArchiveForUser">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Mostrar no Mapa as Verificações Selecionadas"
        [disabled]="this.selection.selected.length == 0"
        (click)="onLocationManyClick(mapEvents.MANY_OPERATION_LOCATION)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Mostrar Rastro no Mapa das Verificações Selecionadas"
        [disabled]="this.selection.selected.length == 0"
        (click)="onHistoricalTrackingManyClick()">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Mostrar Mensagens da Verificação Selecionada
                                        (Somente 1 Verificação pode estar selecionada)"
        [disabled]="this.selection.selected.length != 1"
        (click)="onMessagesClick(selection.selected[0])">
        <mat-icon class="material-icons-outlined">forum</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="sipd-content bg-white">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th class="txt-center fit-content" mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td class="txt-center fit-content" mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="identifier">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.identifier}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td class="nowrap pr-4" mat-cell *matCellDef="let element">
          <mat-chip-list class="mat-chip-list-stacked">
            <mat-chip [class]="element.status? element.status + ' text-white': null " class="justify-content-center" [disabled]="element.status ? null:'disabled'">
              <span class="">{{getOperationStatus(element) + ((element.expired && element.status=='FINISHED')? ' (expirada)': '') }}</span>
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <!-- Event Column -->
      <ng-container matColumnDef="eventId" *ngIf="!editedEvent">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Evento </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ element?.eventId }} </td>
      </ng-container>

      <!-- Sent Date Column -->
      <ng-container matColumnDef="sentDate">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Data/Hora (Solicitação) </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.sentDate | date:'dd/MM/yyyy HH:mm'}} </td>
      </ng-container>

      <!-- PatrolTeam Column -->
      <ng-container matColumnDef="patrolTeam.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Equipe </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.patrolTeam?.name}} </td>
      </ng-container>

      <!-- Priority Column -->
      <ng-container matColumnDef="priority">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Prioritária </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{ (element?.priority ? 'Sim': 'Não') }} </td>
      </ng-container>

      <!-- Liberação de faixa -->
      <ng-container matColumnDef="bandRelease">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Liberação de Faixa </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{verificationBandReleaseDescription[element?.bandRelease] || ""}} </td>
      </ng-container>

      <!-- Analyst Column -->
      <ng-container matColumnDef="analyst.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Analista </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{getUserNameLoginTitle(element?.analyst)}} </td>
      </ng-container>

      <!-- Faixa Column -->
      <ng-container matColumnDef="band">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Faixa </th>
        <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
      </ng-container>

      <!-- KM Column -->
      <ng-container matColumnDef="km">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> KM </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.stretchStartKM && element?.stretchEndKM ?
            element?.stretchStartKM + " - " + element?.stretchEndKM : element?.km}} </td>
      </ng-container>

       <!-- Placement Column -->
       <ng-container matColumnDef="patrolTeam.company.placement.name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Lotação </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.placement?.name}} </td>
      </ng-container>

      <!-- Duto Column -->
      <ng-container matColumnDef="duct">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Duto </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.duct}} </td>
      </ng-container>

      <!-- Last Sync Column -->
      <ng-container matColumnDef="lastSync">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Última Atualização (GPS)</th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element.lastSync | date:'dd/MM/yyyy HH:mm:ss'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
        (contextmenu)="openContextMenu($event, element)"
        [ngClass]="{ 'highlighted': selection.isSelected(row) }">
      </tr>
    </table>

    <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>

    <mat-menu #contextMenu="matMenu">
      <button mat-menu-item (click)="onEditClick(this.contextMenuSelectedItem, $event)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        <span>Abrir Verificação</span>
      </button>
      <button mat-menu-item [hidden]="isHiddenButtonCreateEditVerification" (click)="onCopyClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        <span>Copiar Verificação</span>
      </button>
      <button mat-menu-item (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.identifier, $event)" [hidden]="isHiddenButtonDeleteVerification" [disabled]="!canDelete(this.contextMenuSelectedItem)">
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span>Remover Verificação</span>
      </button>
      <button mat-menu-item (click)="onExportClick(this.contextMenuSelectedItem, $event)">
        <mat-icon class="material-icons-outlined">save_alt</mat-icon>
        <span>Exportar Verificação</span>
      </button>
      <button mat-menu-item [disabled]="!canArchive(this.contextMenuSelectedItem)" (click)="onArchiveClick(this.contextMenuSelectedItem)" [hidden]="isHiddenButtonArchiveForUser">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
        <span>{{ archiveButtonLabel }}</span>
      </button>
      <button mat-menu-item (click)="onLocationClick(this.contextMenuSelectedItem, mapEvents.OPERATION_LOCATION)" [disabled]="!hasLocation(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
        <span>Mostrar no Mapa</span>
      </button>
      <button mat-menu-item color="primary" (click)="onHistoricalTrackingClick(this.contextMenuSelectedItem)" [disabled]="!canHasHistoricalTracking(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">my_location</mat-icon><span>Mostrar Rastro no Mapa</span>
      </button>
      <button mat-menu-item (click)="onMessagesClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">forum</mat-icon>
        <span>Mensagens da Verificação</span>
      </button>
    </mat-menu>

  </div>

  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
