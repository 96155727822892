/* Padronização de Enums:

enum XxxxYyyy => valor do enumerador usado no modelo, e salvo no banco de dados
enum XxxxYyyyDescription => Nomes dos enums para Labels, Combos, etc...

Xxxx é o nome do objeto: Alert, Patrol, User, etc
Yyyy é o atributo do objeto no singular: Type, Priority, etc

Em casos aonde o atributo é usado em vários objets diferentes Xxxx não é utilizado

*/

export enum AlertType {
  ROUTE_DEVIATION                   = 'ROUTE_DEVIATION',
  INCONSISTENT_DATA                 = 'INCONSISTENT_DATA',
  ATTENTION_PATROL_CHECK            = 'ATTENTION_PATROL_CHECK',
  PANIC                             = 'PANIC',
  SIMF                              = 'SIMF',
  ELETRONIC_FENCE                   = 'ELETRONIC_FENCE',
  STATIONARY                        = 'STATIONARY'
}

export enum AlertTypeDescription {
  ELETRONIC_FENCE                   = 'Cerca Eletrônica',
  ATTENTION_PATROL_CHECK            = 'Comunicação',
  INCONSISTENT_DATA                 = 'Dados não conferem',
  ROUTE_DEVIATION                   = 'Desvio de Rota',
  PANIC                             = 'Pânico',
  STATIONARY                        = 'Profissional Parado'
  //SIMF                              = 'SIMF'  // Não mostrar nas opções de filtro
}

export enum SimfAlertTypeDescription {
  'Threat.Type.Digging'            = 'Escavação Manual',
  'Threat.Type.Mechanical_Digging' = 'Escavação Mecânica',
  'Threat.Type.Pig'                = 'PIG',
  'Threat.Type.FibreBreak'         = 'Fibra Quebrada',
  'Threat.Type.Vehicle'            = 'Veículo',
  'Threat.Type.LeakNPP'            = 'NPP',
}

export enum AlertPriorityDescription {
  HIGH    = "Alto",
  MEDIUM  = "Médio",
  LOW     = "Baixo"
}

export enum AlertPriority {
  HIGH    = "HIGH",
  MEDIUM  = "MEDIUM",
  LOW     = "LOW"
}

export enum AlertPriorityValue {
  HIGH    = 2,
  MEDIUM  = 1,
  LOW     = 0
}

export enum EventSituationDescription {
  STARTED     = 'Operando',
  STOPPED     = 'Interrompido'
}

/* Ocorrências da aba de resultados de eventos e rondas */
export enum ResultOccurrenceDescription {
  DUCT          = 'Duto',
  VALVE         = 'Válvula',
  DUCT_ATTEMPT  = 'Tentativa no Duto',
  VALVE_ATTEMPT = 'Tentativa na Válvula',
}

export enum SourceTypeDescription {
  MOBILE_APP = 'App Móvel',
  WEB_APP    = 'App Web',
  SIMF       = 'SIMF',
  VEHICLE    = 'Veículo'
}

// Usado no SignalModel, MarkerModel e AlertModel
export enum SourceType {
  MOBILE_APP = 'MOBILE_APP',
  WEB_APP    = 'WEB_APP',    // Não faz sentido para Alert e Signal
  SIMF       = 'SIMF',       // Não faz sentido para Signal e Marker
  VEHICLE    = 'VEHICLE'     // Não faz sentido para Marker e Alert
}

export enum EventValidation {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum EventValidationDescription {
  COMPLETE = 'Completo',
  INCOMPLETE = 'Incompleto'
}

export enum ShiftDescription {
  MORNING       = 'Manhã',
  NIGHT         = 'Noite'
}

export enum ServiceTypeDescription {
  PD_MOBILE     = 'PD-Móvel',
  PD_TECHNICAL  = 'PD-Técnica'
}

export enum UserType {
  ADMINISTRATOR     = 'ADMINISTRATOR',  // Só pode haver um único perfil administrador
  ANALYSIS_CAD      = 'ANALYSIS_CAD',
  ANALYSIS_CCPD     = 'ANALYSIS_CCPD',
  CNCL              = 'CNCL',
  COORDINATOR_CCPD  = 'COORDINATOR_CCPD',
  COORDINATOR_OPPD  = 'COORDINATOR_OPPD',
  MANAGER           = 'MANAGER',
  CENTRAL_OPERATOR  = 'CENTRAL_OPERATOR',
  PLANNER           = 'PLANNER',
  PROFESSIONAL      = 'PROFESSIONAL',
  COORDINATOR_CNCL  = 'COORDINATOR_CNCL'
}

export enum UserTypeDescription {
  ADMINISTRATOR     = 'Administrador',
  ANALYSIS_CAD      = 'Analista CAD',
  ANALYSIS_CCPD     = 'Analista CCPD',
  CNCL              = 'Analista CNCL',
  COORDINATOR_CCPD  = 'Coordenador CCPD',
  COORDINATOR_OPPD  = 'Coordenador OPPD',
  MANAGER           = 'Gestor',
  CENTRAL_OPERATOR  = 'Operador Central',
  PLANNER           = 'Planejador OPPD',
  PROFESSIONAL      = 'Profissional',
  COORDINATOR_CNCL  = 'Coordenador CNCL'
}

export enum OperationStatus {
  PLANNED     = 'PLANNED',
  SENT        = 'SENT',
  STARTED     = 'STARTED',
  PAUSED      = 'PAUSED',
  FINISHED    = 'FINISHED'
}

export enum OperationStatusDescription {
  PLANNED     = 'Planejada',
  SENT        = 'Solicitada',
  STARTED     = 'Iniciada',
  PAUSED      = 'Pausada',
  FINISHED    = 'Concluída'
}

export enum PointStatus {
  APPROVED     = 'APPROVED',
  IN_ANALYSIS  = 'IN_ANALYSIS'
}

export enum PointStatusDescription {
  APPROVED     = 'Aprovado',
  IN_ANALYSIS     = 'Em Análise'
}

/* Liberação de faixa da aba de resultados de verificaçãoes */
export enum VerificationBandReleaseDescription {
  CLEAR   = 'Liberado',
  WAITING = 'Aguardando'
}

export enum EventStatus {
  IN_TREATMENT    =   'IN_TREATMENT',
  PENDING         =   'PENDING',
  FINALIZED       =   'FINALIZED'
}

export enum EventStatusDescription {
  IN_TREATMENT    =   'Andamento',
  PENDING         =   'Pendente',
  FINALIZED       =   'Concluído'
}

export enum OperationType {
  PATROL             = 'PATROL',
  EVENT_VERIFICATION = 'EVENT_VERIFICATION'
}

export enum OperationTypeDescription {
  PATROL             = 'Ronda',
  EVENT_VERIFICATION = 'Verificação',
}

export enum MarkerType {
  TEXT_MESSAGE = 'TEXT_MESSAGE',
  IMAGE_MESSAGE = 'IMAGE_MESSAGE',
  AUDIO_MESSAGE = 'AUDIO_MESSAGE',
  VIDEO_MESSAGE = 'VIDEO_MESSAGE',
  PANIC_MESSAGE = 'PANIC',
  INCONSISTENT_DATA = 'INCONSISTENT_DATA',
  // Marcadores de Status da Operação
  START_SHIFT = 'START_SHIFT',
  PAUSE_SHIFT = 'PAUSE_SHIFT',
  RESUME_SHIFT = 'RESUME_SHIFT',
  STOP_SHIFT = 'STOP_SHIFT',
  // Marcadores de Status de Ponto
  START_INSPECTION = 'START_INSPECTION',
  MOVING_TOWARDS_INSPECTION = 'MOVING_TOWARDS_INSPECTION',
  FINISH_INSPECTION = 'FINISH_INSPECTION',
  POINT_ACCESS = 'POINT_ACCESS',
  // Marcadores Administrativos
  OPERATION_RECEIVED = 'OPERATION_RECEIVED',
  SITUATION_FOUND = 'SITUATION_FOUND',
  MANAGERIAL_MESSAGE = 'MANAGERIAL_MESSAGE',
  VERIFICATION_SENT_MESSAGE = 'VERIFICATION_SENT_MESSAGE',
  INFO_MESSAGE = 'INFO_MESSAGE'
}

export enum MarkerTypeDescription {
  TEXT_MESSAGE = 'Mensagem de Texto',
  IMAGE_MESSAGE = 'Imagem',
  AUDIO_MESSAGE = 'Áudio',
  VIDEO_MESSAGE = 'Vídeo',
  PANIC_MESSAGE = 'Pânico',
  INCONSISTENT_DATA = 'Dados Inconsistentes',
  // Marcadores de Status da Operação
  START_SHIFT = 'Operação Iniciada',
  PAUSE_SHIFT = 'Operação Pausada',
  RESUME_SHIFT = 'Operação Retomada',
  STOP_SHIFT = 'Operação Concluída',
  // Marcadores de Status de Ponto
  START_INSPECTION = 'Ponto Iniciado',
  MOVING_TOWARDS_INSPECTION = 'Percurso Iniciado',
  FINISH_INSPECTION = 'Ponto Concluído',
  POINT_ACCESS = 'Ponto Sem Acesso',
  // Marcadores Administrativos
  OPERATION_RECEIVED = 'Operação Recebida',
  SITUATION_FOUND = 'Quadro Encontrado',
  MANAGERIAL_MESSAGE = 'Mensagem Gerencial',
  VERIFICATION_SENT_MESSAGE = 'Verificação Enviada',
  INFO_MESSAGE = 'Informação Operacional'
}

export enum PointType {
  BASE           = 'BASE',
  CRITICAL_POINT = 'CRITICAL_POINT'
}

export enum InspectionBooleanDescription {
  YES = 'Sim',
  NOT = 'Não'
}

export enum InspectionStatusType {
  NOT_STARTED = "NOT_STARTED",
  ON_THE_WAY = "ON_THE_WAY",
  PENDING = "PENDING",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
  SUSPENDED = "SUSPENDED"
}

export enum InspectionStatusTypeDescription {
  NOT_STARTED = "Não Iniciado",
  ON_THE_WAY = "Em Percurso",
  PENDING = "Pendente",
  STARTED = "Iniciado",
  FINISHED = "Concluído",
  SUSPENDED = "Suspenso"
}

export enum QuestionType {
  ANSWER = 'ANSWER',
  YESNO = 'YESNO',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  ACTIVEINACTIVE = 'ACTIVEINACTIVE',
  VIOLATEDCOMPLETE = 'VIOLATEDCOMPLETE'
}

export enum EvidenceRequirement {
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED"
}

export enum EvidenceRequirementDescription {
  OPTIONAL = "Opcional",
  REQUIRED = "Obrigatório"
}

export enum NodeType {
  YESNO = 'YESNO',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  ACTIVEINACTIVE = 'ACTIVEINACTIVE',
  VIOLATEDCOMPLETE = 'VIOLATEDCOMPLETE'
}

export enum NodeTypeDescription {
  YESNO = 'Sim/Não',
  TEXT = 'Texto',
  NUMBER = 'Valor numérico',
  ACTIVEINACTIVE = 'Ativo/Inativo',
  VIOLATEDCOMPLETE = 'Violada/Íntegra'
}

export enum CrudType{
  ADD,
  EDIT
}

export enum ObservedAreaType {
  SUSPECT       = 'SUSPECT',
  SERVICES      = 'SERVICES',
  MAINTENANCE   = 'MAINTENANCE',
  CONSTRUCTION  = 'CONSTRUCTION',
  OTHER         = 'OTHER'
}

export enum ObservedAreaTypeDescription {
  SUSPECT       = 'Suspeita',
  SERVICES      = 'Serviços',
  MAINTENANCE   = 'Manutenção',
  CONSTRUCTION  = 'Obras',
  OTHER         = 'Outros'
}

export enum ObservedAreaStatus {
  ACTIVE    = 'ACTIVE',
  INACTIVE  = 'INACTIVE',
  ALL = 'ALL'

}

export enum ObservedAreaStatusDescription {
  ACTIVE    = 'Ativa',
  INACTIVE  = 'Inativa'
}

export enum MapEvents {
  OPERATION_LOCATION = 'OPERATION_LOCATION',
  MANY_OPERATION_LOCATION = 'MANY_OPERATION_LOCATION',
  EVENT_LOCATION = 'EVENT_LOCATION',
  MANY_EVENT_LOCATION = 'MANY_EVENT_LOCATION',
  OBSERVED_AREA_LOCATION = 'OBSERVED_AREA_LOCATION',
  MANY_OBSERVED_AREA_LOCATION = 'MANY_OBSERVED_AREA_LOCATION'
}

export enum PredictedDialogRuleOption {
  CUSTOM    = 'CUSTOM',
  DISABLE  = 'DISABLE',
  EVERY_PERIOD  = 'EVERY_PERIOD',
  IN_THE_DAYS  = 'IN_THE_DAYS',
  NEVER_FINISH = 'NEVER_FINISH',
  FINISH_DATE =  'FINISH_DATE',
  FINISH_AFTER_QUANTITY = 'FINISH_AFTER_QUANTITY'
}

export enum ChannelType {
  CCPD = 'CCPD',
  CNCL = 'CNCL',
  MANAGERIAL = 'MANAGERIAL',
  CORE = 'CORE',
  PD_MOBILE = 'PD_MOBILE',
  PD_TECHNICAL = 'PD_TECHNICAL',
  SYNERGY = 'SYNERGY'
}

export enum ChannelTypeDescription {
  CCPD = 'CCPD',
  CNCL = 'CNCL',
  MANAGERIAL = 'Gerencial',
  CORE = 'Núcleo',
  PD_MOBILE = 'PD-Móvel',
  PD_TECHNICAL = 'PD-Técnica',
  SYNERGY = 'Sinergia'
}

export enum Permission {
  ACCES_APP                             = 'ACCES_APP',
  ACCES_WEB                             = 'ACCES_WEB',
  ACCESS_TO_ALL_PLACEMENT               = 'ACCESS_TO_ALL_PLACEMENT',
  VIEW_MAP_DC_HISTORY_ID                = 'VIEW_MAP_DC_HISTORY_ID',
  VIEW_MAP_OBSERVED_AREA_ID             = 'VIEW_MAP_OBSERVED_AREA_ID',
  VIEW_MAP_SIMF                         = 'VIEW_MAP_SIMF',
  VIEW_MAP_BAND                         = 'VIEW_MAP_BAND',
  VIEW_MAP_OILDUCT                      = 'VIEW_MAP_OILDUCT',
  VIEW_MAP_GASDUCT                      = 'VIEW_MAP_GASDUCT',
  VIEW_MAP_KILOMETER_MARK               = 'VIEW_MAP_KILOMETER_MARK',
  VIEW_MAP_VALVE                        = 'VIEW_MAP_VALVE',
  VIEW_MAP_TERMINAL                     = 'VIEW_MAP_TERMINAL',
  VIEW_MAP_REFINARY                     = 'VIEW_MAP_REFINARY',
  VIEW_MAP_DELIVERY_POINT               = 'VIEW_MAP_DELIVERY_POINT',
  VIEW_MAP_TEAM                         = 'VIEW_MAP_TEAM',
  MENU_TRACKING                         = 'MENU_TRACKING',
  VIEW_TRACKING_TEAM                    = 'VIEW_TRACKING_TEAM',
  MENU_ALERT                            = 'MENU_ALERT',
  LIST_ALERTS                           = 'LIST_ALERTS',
  EDIT_ALERT                            = 'EDIT_ALERT',
  LIST_EVENTS                           = 'LIST_EVENTS',
  CREATE_EDIT_EVENT                     = 'CREATE_EDIT_EVENT',
  DELETE_EVENT                          = 'DELETE_EVENT',
  CREATE_EDIT_EVENT_NOTES               = 'CREATE_EDIT_EVENT_NOTES',
  DELETE_EVENT_NOTES                    = 'DELETE_EVENT_NOTES',
  EDIT_END_EVENT                        = 'EDIT_END_EVENT',
  CHANGE_EVENTS_FROM_ANY_SOURCE         = 'CHANGE_EVENTS_FROM_ANY_SOURCE',
  CHANGE_ANY_EVENT_FIELD                = 'CHANGE_ANY_EVENT_FIELD',
  LIST_VERIFICATIONS                    = 'LIST_VERIFICATIONS',
  VIEW_INFORME_GERENCIAL_VERIFICATIONS  = 'VIEW_INFORME_GERENCIAL_VERIFICATIONS',
  CREATE_EDIT_VERIFICATIONS             = 'CREATE_EDIT_VERIFICATIONS',
  DELETE_VERIFICATIONS                  = 'DELETE_VERIFICATIONS',
  LIST_PATROLS                          = 'LIST_PATROLS',
  CREATE_EDIT_PATROL                    = 'CREATE_EDIT_PATROL',
  DELETE_PATROL                         = 'DELETE_PATROL',
  LIST_USERS_ADMINISTRATION             = 'LIST_USERS_ADMINISTRATION',
  CREATE_EDIT_USER                      = 'CREATE_EDIT_USER',
  DELETE_USER                           = 'DELETE_USER',
  LIST_PATROL_TEAM_ADMINISTRATION       = 'LIST_PATROL_TEAM_ADMINISTRATION',
  CREATE_EDIT_PATROL_TEAM               = 'CREATE_EDIT_PATROL_TEAM',
  DELETE_PATROL_TEAM                    = 'DELETE_PATROL_TEAM',
  LIST_PLACEMENT_ADMINISTRATION         = 'LIST_PLACEMENT_ADMINISTRATION',
  CREATE_EDIT_PLACEMENT                 = 'CREATE_EDIT_PLACEMENT',
  DELETE_PLACEMENT                      = 'DELETE_PLACEMENT',
  LIST_COMPANY_ADMINISTRATION           = 'LIST_COMPANY_ADMINISTRATION',
  CREATE_EDIT_COMPANY                   = 'CREATE_EDIT_COMPANY',
  DELETE_COMPANY                        = 'DELETE_COMPANY',
  LIST_VEHICLE_ADMINISTRATION           = 'LIST_VEHICLE_ADMINISTRATION',
  CREATE_EDIT_VEHICLE                   = 'CREATE_EDIT_VEHICLE',
  DELETE_VEHICLE                        = 'DELETE_VEHICLE',
  LIST_MAIN_EVENT_ADMINISTRATION        = 'LIST_MAIN_EVENT_ADMINISTRATION',
  CREATE_EDIT_MAIN_EVENT                = 'CREATE_EDIT_MAIN_EVENT',
  DELETE_MAIN_EVENT                     = 'DELETE_MAIN_EVENT',
  LIST_OBSERVED_AREA_ADMINISTRATION     = 'LIST_OBSERVED_AREA_ADMINISTRATION',
  CREATE_EDIT_OBSERVED_AREA             = 'CREATE_EDIT_OBSERVED_AREA',
  DELETE_OBSERVED_AREA                  = 'DELETE_OBSERVED_AREA',
  LIST_FORMS_ADMINISTRATION             = 'LIST_FORMS_ADMINISTRATION',
  CREATE_EDIT_FORM                      = 'CREATE_EDIT_FORM',
  DELETE_FORM                           = 'DELETE_FORM',
  LIST_POINTS_ADMINISTRATION            = 'LIST_POINTS_ADMINISTRATION',
  CREATE_EDIT_POINT                     = 'CREATE_EDIT_POINT',
  DELETE_POINT                          = 'DELETE_POINT',
  LIST_CHANELS                          = 'LIST_CHANELS', // typing error (mantido para evitar mudança no banco de dados)
  LIST_REGISTRATION_GROUP               = 'LIST_REGISTRATION_GROUP',
  EDIT_REGISTRATION_GROUP               = 'EDIT_REGISTRATION_GROUP',
  DELETE_REGISTRATION_GROUP             = 'DELETE_REGISTRATION_GROUP',
  LIST_PROFILE_ADMINISTRATION           = 'LIST_PROFILE_ADMINISTRATION' // usado somente para Admin
}

export enum PermisionRuleOption {
  ALL_FIELD         = 'ALL_FIELD',
  SOME_FIELDS       = 'SOME_FIELDS',
  ALL_PROFILE       = 'ALL_PROFILE',
  SOME_PROFILE      = 'SOME_PROFILE',
  ALL_VERIFICATIONS = 'ALL_VERIFICATIONS',
  ONLY_NOT_STARTED  = 'ONLY_NOT_STARTED',
  ALL_PATROLS       = 'ALL_PATROLS',
  SOME_SOURCE       = 'SOME_SOURCE', 
  ALL_SOURCE        = 'ALL_SOURCE'
}

export enum EventFields {
  "CCPD_ANALYST"  =  'CCPD_ANALYST',
  "VALIDATION_ANALYST" = 'VALIDATION_ANALYST'
}

export enum EventFieldsDescription {
  "CCPD_ANALYST"  =  'Analista CCPD',
  "VALIDATION_ANALYST" = 'Analista Validação'
}

export enum UserFields {
  "LOGIN_APP"  = 'LOGIN_APP',
  "COMPANY" = 'COMPANY'
}

export enum UserFieldsDescription {
  "LOGIN_APP" = 'Login App',
  "COMPANY" = 'Empresa'
}

export enum FilterByAssociatedEvent {
  ASSOCIATED     = 'ASSOCIATED',
  NOT_ASSOCIATED = 'NOT_ASSOCIATED'  
}

export enum RegistrationType {
  CHANNEL = 'CHANNEL',
  CHANNEL_RESET = 'CHANNEL_RESET',
  COMPANY = 'COMPANY',
  EVENT_MAIN_ACTION = 'EVENT_MAIN_ACTION',
  FORM = 'FORM',
  INSPECTION_POINT = 'INSPECTION_POINT',
  OBSERVED_AREA = 'OBSERVED_AREA',
  PATROL_TEAM = 'PATROL_TEAM',
  PLACEMENT = 'PLACEMENT',
  PROFILE = 'PROFILE',
  USER = 'USER',
  VEHICLE = 'VEHICLE'
}

export enum AttachmentNumber {
  ONE         =   'ONE',
  TWO         =   'TWO',
  THREE       =   'THREE',
  FOUR        =   'FOUR',
  FIVE        =   'FIVE'
}

export enum EvidenceNumber {
  ONE         =   'ONE',
  TWO         =   'TWO',
  THREE       =   'THREE',
  FOUR        =   'FOUR',
  FIVE        =   'FIVE'
}