import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { PointService } from '../../../service/model/point.service';
import { first } from 'rxjs/operators';
import { UserModel } from '../../../model/user.model';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';

@Component({
  selector: 'app-import-points-xls-dialog',
  templateUrl: './import-points-xls-dialog.component.html',
  styleUrls: ['./import-points-xls-dialog.component.scss']
})
export class ImportPointsXlsDialogComponent implements OnInit, OnDestroy {

  filename: string;
  xlsFile: File;
  validFilename: boolean;
  observations: string;
  imported: boolean;
  
  loadingListService = new LoadingListService();

  constructor(private logger:                     NGXLogger,
    public dialogRef:                             MatDialogRef<ImportPointsXlsDialogComponent>,
    protected pointService:                       PointService,
    public toastr:                                ToastrService,
    @Inject(MAT_DIALOG_DATA) public currentUser:  UserModel){}

  ngOnInit() {
    this.logger.debug(this.currentUser);
  }
  
  ngOnDestroy() {
    this.loadingListService.destroy();
  }

  selectFile(file: File) {
    if (file) {  // Usuário selecionou um arquivo
      this.xlsFile = file;
      this.filename = file.name;
      var fileExt = this.filename.split('.').pop();
      this.validFilename = (fileExt === "xlsx") ? true : false;

    }  
    else {  // Usuário pressionou Remover
      this.xlsFile = undefined;
      this.filename = undefined;
    }
    this.imported = false;
    if (this.validFilename) {
      this.observations = "";
    }
    else {
      this.observations = "Arquivo inválido!\nÉ esperado arquivo com extensão Xlsx!"
      this.imported = true;
    }
}

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  select() {
    this.logger.debug('onSaveClick: Saving create operation.');

    this.observations = "";

    this.loadingListService.loadingOn();

    this.pointService.importXls(this.currentUser, this.xlsFile).pipe(first()).subscribe( errors => {
      let jsonData = JSON.stringify(errors);
      const obj = JSON.parse(jsonData);
      this.observations += "Foram importados (" + obj["inspections"].length + ") pontos com sucesso.\n";
      if (obj["errors"]["0"]) {
        this.observations += "O formato da planilha é inválido!\n";
      }
      else {
        var first: boolean = true;
        for(var line in obj["errors"]) {
          if (first) {
            this.observations += "Alguns pontos não puderam ser importados. Pontos com problema:\n";
            first = false;
          }
          this.observations += "Linha : " + line + "\n";  
            for(var i=0; i<obj["errors"][line].length; i++) {
              this.observations += "      Coluna : " + obj["errors"][line][i] + "\n";  
          }
        }
      }
      this.imported = true;
      this.loadingListService.loadingOff();
    })
  }
}
