<div mat-dialog-title>
    <span>Importar XLS</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
  <div fxLayout="column wrap" class="h-100 ">
    <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
      <mat-card fxFlex class="form-spacer mb-3 bg-shade-100 mat-elevation-z border">
        <app-upload [(filename)]="filename" class="app-upload"
          file_label="XLS de Pontos" button_text="Selecionar..." file_icon="location_on"
          file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="selectFile($event)">
        </app-upload>
      </mat-card>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pl-3 pr-2">

    <mat-form-field fxFlex appearance="outline" class="mr-2">
      <textarea matInput name="observations" matInput rows="5" [(ngModel)]="observations" readonly></textarea>
    </mat-form-field>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="select()" [disabled]="!validFilename || imported">Importar</button>
  <button mat-raised-button [mat-dialog-close]=true mat-dialog-close>Fechar</button>
</mat-dialog-actions>
