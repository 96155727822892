<div mat-dialog-title>
  <span>Filtrar Alertas {{sourceApp? "App Móvel": "SIMF"}}</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="container-fluid">

    <div class="p-5">
      <label id="period-group-label" class="period-label mr-4">Período:</label>
      <mat-radio-group aria-labelledby="period-group-label" [(ngModel)]="filterModel.current">
        <mat-radio-button class="mr-4" color="accent" [value]="true">Hoje</mat-radio-button>
        <mat-radio-button color="accent" [value]="false">Especificado</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Não usar ngIf, se não startDateEl não será criada -->
    <div [hidden]="filterModel.current == true" fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
      <date-chooser placeholder="Data Inicial" [(valueData)]="viewFilterStartDate" #startDateEl="dateChooserContext" fxFlex></date-chooser>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Hora Inicial</mat-label>
        <input matInput name="startTime" mask="Hh:m0" [dropSpecialCharacters]="false" name="startTime"
          placeholder="Hora Inicial" [(ngModel)]="viewFilterStartTime">
        <mat-icon matSuffix>query_builder</mat-icon>
      </mat-form-field>

      <date-chooser placeholder="Data Final" #endDateEl="dateChooserContext" [(valueData)]="viewFilterEndDate" fxFlex>
      </date-chooser>

      <mat-form-field  class="mr-2" appearance="outline" fxFlex>
        <mat-label>Hora Final</mat-label>
        <input matInput name="endTime" mask="Hh:m0" [dropSpecialCharacters]="false"
          placeholder="Hora Final" [(ngModel)]="viewFilterEndTime">
        <mat-icon matSuffix>query_builder</mat-icon>
      </mat-form-field>

    </div>
    <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

      <multiselect-autocomplete [data]="entityCacheService.getPatrolTeams()" name="name" optionValue="id" placeholder="Equipe"
        [(valueData)]="filterModel.patrolTeams" fxFlex *ngIf="sourceApp">
      </multiselect-autocomplete>

      <multiselect-autocomplete [data]="entityCacheService.getVehicles()" name="plate" optionValue="id" placeholder="Placa" *ngIf="sourceApp"
        [(valueData)]="filterModel.vehicles" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete [data]="alertTypeDescriptionFiltered" name="value" optionValue="name" placeholder="Origem"
        [(valueData)]="filterModel.types" fxFlex *ngIf="sourceApp">
      </multiselect-autocomplete>

      <multiselect-autocomplete [data]="simfAlertTypeDescription | keyvalue: originalOrder" name="value" optionValue="key" placeholder="Origem SIMF"
        [(valueData)]="filterModel.simfAlertTypes" fxFlex *ngIf="!sourceApp">
      </multiselect-autocomplete>

      <multiselect-autocomplete [data]="alertPriorityDescription | keyvalue: originalOrder" name="value" optionValue="key" placeholder="Nível"
        [(valueData)]="filterModel.priorities" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete [data]="operationTypeDescription | keyvalue: originalOrder" name="value" optionValue="key" placeholder="Tipo de Operação"
        [(valueData)]="filterModel.operationTypes" fxFlex *ngIf="sourceApp">
      </multiselect-autocomplete>

      <multiselect-autocomplete [data]="serviceTypeDescription | keyvalue: originalOrder" name="value" optionValue="key" placeholder="Tipo de Serviço"
        [(valueData)]="filterModel.serviceTypes" fxFlex *ngIf="sourceApp">
      </multiselect-autocomplete>

       <mat-form-field appearance="outline" *ngIf="!sourceApp" fxFlex>
        <mat-label>Kpoint Inicial</mat-label>
        <input matInput  name="startKpoint" placeholder="Kpoint Inicial" [(ngModel)]="filterModel.startScalePoint" mask="000+000" [dropSpecialCharacters]="false">
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!sourceApp" fxFlex>
        <mat-label>Kpoint Final</mat-label>
        <input matInput  name="endKpoint" placeholder="Kpoint Final" [(ngModel)]="filterModel.endScalePoint" mask="000+000" [dropSpecialCharacters]="false">
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!sourceApp" fxFlex>
        <mat-label>Canal Inicial</mat-label>
        <input  type="number" name="startChannel" matInput placeholder="Canal Inicial" [(ngModel)]="filterModel.startChannel"/>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!sourceApp" fxFlex>
        <mat-label>Canal Final</mat-label>
        <input  type="number" name="endChannel" matInput placeholder="Canal Final" [(ngModel)]="filterModel.endChannel"/>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!sourceApp" fxFlex>
        <mat-label>OPS</mat-label>
        <input  matInput type="number" name="ops"  placeholder="OPS"  [(ngModel)]="filterModel.cpsNumber"/>
      </mat-form-field>

      <div class="form-spacer" fxFlex></div>
    </div>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="clearFilter()">Limpar</button>
  <button mat-raised-button [disabled]="startDateEl.invalid || endDateEl.invalid" (click)="onFilter()">Aplicar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
