import { RegistrationType, UserType, UserTypeDescription } from 'src/app/model/enums.enum';
import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UserService } from 'src/app/service/model/user.service';
import { UserModel } from '../../../../model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from 'src/app/general/confirmation-dialog/confirmation-dialog.component';
import { PatrolTeamService } from 'src/app/service/model/patrol.team.service';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from '../../../../service/authorization/authorization.service';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { EntityModel } from '../../../../model/entity.model';
import { PatrolService } from '../../../../service/model/patrol.service';
import { PatrolFilterModel } from '../../../patrol/patrol-filter-dialog/patrol.filter.model';
import { PatrolModel } from 'src/app/model/patrol.model';
import { VerificationFilterModel } from 'src/app/pages/verification/verification-filter-dialog/verification.filter.model';
import { VerificationService } from 'src/app/service/model/verification.service';
import { VerificationModel } from 'src/app/model/verification.model';
import { PlacementModel } from 'src/app/model/placement.model';
import { DEFAULT_PAGE_INDEX, ESP, LARGE_PAGE_SIZE, MAP_PAGE, SORT_NAME_ASC } from 'src/app/common/constants';
import { ProfileModel } from 'src/app/model/profile.model';
import { RegistrationListComponent } from '../../registration-list-component';
import { first } from 'rxjs/operators';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { Subscription } from 'rxjs';
import { SAVE_DATA_PREFIX, DELETE_DATA_PREFIX } from '../../../../common/constants';
import { PatrolTeamModel } from '../../../../model/patrolteam.model';
import { MarkersService } from 'src/app/service/model/markers.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['../../../../app.component.scss', '../../../list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent extends RegistrationListComponent implements OnInit, OnDestroy {

  userTypeDescription = UserTypeDescription;
  profileMap: Map<string, ProfileModel> = new Map();
  patrolTeamMap: Map<string, PatrolTeamModel> = new Map();

  private reloadProfilesSubscription: Subscription;
  private reloadPatrolTeamsSubscription: Subscription;
 
  constructor(logger:                             NGXLogger,
              protected userService:              UserService,
              dialog:                             MatDialog,
              protected patrolTeamService:        PatrolTeamService,
              private patrolService:              PatrolService,
              private verificationService:        VerificationService,
              private markerService:              MarkersService,
              public entityCacheService:          EntityCacheService,
              protected changeDetector:           ChangeDetectorRef,
              protected toastr:                   ToastrService,
              public authorizationService:        AuthorizationService,
              protected storageService:           StorageService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, userService, dialog, 'users', environment.USERS_GROUP_LABEL, environment.USERS_TITLE_LABEL,
      environment.USERS_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('UserListComponent.constructor()');
    this.subscribeToSavePlacementsData();
    this.subscribeToDeletePlacementsData();
    this.subscribeToSaveCompaniesData();
    this.registrationType = RegistrationType.USER;
    this.sortOptions = SORT_NAME_ASC;
  }

  ngOnInit() {
    super.ngOnInit();

    this.logger.debug('UserListComponent.ngOnInit()');

    this.loadProfilesFromServer();

    this.loadPatrolTeamsFromServer();

    this.displayedColumns = ['select',
                             'name',
                             'originPlacement',
                             'company.name',
                             'email',
                             'login',
                             'cpf',
                             'profileId',
                             'mobilePhone',
                             'appAccess',
                             'patrolTeamId'];

    this.loadRecordsFromServer();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.reloadProfilesSubscription?.unsubscribe();
    this.reloadPatrolTeamsSubscription?.unsubscribe();
  }

  loadRecordsFromServer(): void {
    this.logger.debug('UserListComponent.loadRecordsFromServer()');

    // Carrega para todas as lotações, filtra a lista depois
    this.filterModel.placements = [];
    
    super.loadRecordsFromServer();
  }

  loadProfilesFromServer(){
    let _this = this; // Necessário por causa do contexto das callacks
    const onProfilesLoad = function() {
      _this.profileMap.clear();
      _this.entityCacheService.getAllProfiles().forEach(profile => {
        _this.profileMap.set(profile.id, profile);
      });
    };
    this.entityCacheService.loadProfiles(this.loadingListService, onProfilesLoad);
    this.reloadProfilesSubscription = this.entityCacheService.onProfilesReload().subscribe(onProfilesLoad);
  }

  loadPatrolTeamsFromServer(){
    let _this = this; // Necessário por causa do contexto das callacks
    const onPatrolTeamsLoad = function() {
      _this.patrolTeamMap.clear();
      _this.entityCacheService.getPatrolTeams().forEach(team => {
        _this.patrolTeamMap.set(team.id, team);
      });
    };
    this.entityCacheService.loadPatrolTeams(this.loadingListService, onPatrolTeamsLoad);
    this.reloadPatrolTeamsSubscription = this.entityCacheService.onUsersReload().subscribe(onPatrolTeamsLoad);
  }

  public subscribeToSavePlacementsData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'placements-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  public subscribeToDeletePlacementsData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(DELETE_DATA_PREFIX + 'placements-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  public subscribeToSaveCompaniesData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'companies-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  postLoadProcess(): void {
    // Somente administradores podem ver outros administradores
    if (!this.authorizationService.isAdmin()) {
      this.model = this.model.filter(user => !this.entityCacheService.isAdminProfile(user['profileId']));
    }

    this.model.forEach((user: UserModel) => {
      if (this.entityCacheService.isAdminProfile(user['profileId'])) {
        // Administradores acessam todas as lotações
        user.placements = this.entityCacheService.getPlacements();
      }

      if (!user.originPlacement && user.placements && user.placements.length == 1) {
        user.originPlacement = user.placements[0];
      }
    });
  
    let specificPlacementsIds: string[] = this.storageService.getSpecificPlacementIds();
    if(specificPlacementsIds.length > 0){
      this.model = this.model.filter((user: UserModel) => specificPlacementsIds.includes(user.originPlacement?.id));
    }

    this.pageLength = this.model.length;
  }

   /**
   * Indica se o botão de envio de senha deve ou não ser escondido para aquele usuário a ser editado,
   * dependendo do perfil do usuário logado.
   */
  isSendPasswordButtonDisabledForUser(userToBeEdited: EntityModel): boolean {
      return !userToBeEdited
             || (!this.getAppAccess(userToBeEdited as UserModel)
                || !(userToBeEdited as UserModel).email
                || (userToBeEdited as UserModel).email === '');
  }

  isSendPasswordButtonHiddenForUser(): boolean {
    return !this.authorizationService.isSendingPasswordToUserAllowed();
  }

  /**
   * Método sobrescrito da classe pai para chamar a mesma lógica de deleção em massa
   * @param row UserModel
   * @param identifier Identificador do usuário
   * @param event Evento
   */
  onDeleteClick(row: EntityModel, identifier: string, event: any) {
    this.onDeleteManyClick([row]);
  }

  /**
   * Ação para remover vários usuários.
   */
  onDeleteManyClick(rows: EntityModel[]) {
    if (!rows?.length) {
      return;
    }

    let usersToDelete: EntityModel[] = [];
    rows.forEach(row => {
      if (this.canDelete(row)) {
        usersToDelete.push(row);
      }
    });

    if (usersToDelete.length == 0){
      this.toastr.warning("Nenhum usuário pode ser removido, perfil não permite.");
      return;
    }
    if (usersToDelete.length < rows.length){
      this.toastr.warning("Alguns usuários não podem ser removidos, perfil não permite.");
      return;
    }

    const questionMsg = usersToDelete.length > 1 ?
      `Tem certeza que deseja remover o(s) ${usersToDelete.length} usuário(s)?` :
      `Tem certeza que deseja remover ${this.getUserNameLoginTitle(usersToDelete[0])}?`;

    // Cópia do list-component.onDeleteClick
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: questionMsg,
        title: 'Confirmação',
        okLabel: 'Remover',
        identifier: null,
        showIdentifier: false
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.reloadUsersAndDeleteUsers(usersToDelete);
        this.postListDeleteProcess(usersToDelete);
      }
    });
  }

  private reloadUsersAndDeleteUsers(userListToDelete: EntityModel[]) {
    const ids = userListToDelete.map(u => u.id);
    // Passo 1: Recuperar a versão mais atual dos usuários a serem removidos no banco
    this.loadingOn();
    this.userService.loadUsersByIds(ids).pipe(first()).subscribe((resultList: UserModel[]) => {
      // Verificar se os usuários têm equipe
      const usersInTeams = resultList.filter(u => !!u.patrolTeamId && u.profileId === this.getProfessionalProfileId());

      if (usersInTeams?.length) {
        this.checkTeamListInPatrolsAndDeleteUsers(resultList);
      }else{
        this.deleteSelectedEntities(resultList);
      }
    },
    error => {
      this.logger.error(`Erro ao obter a última versão dos usuários ${ids}: ${JSON.stringify(error)}`);
      this.toastr.error('Erro ao obter a última versão dos usuários', 'Erro');
    },
    () => {
      this.loadingOff();
    });
  }

  private checkTeamListInPatrolsAndDeleteUsers(userList: UserModel[]) {
    if (!userList?.length) {
      return;
    }
    const filter: PatrolFilterModel = new PatrolFilterModel();
    const patrolTeamIdList = userList.map(u => u.patrolTeamId);
    filter.patrolTeams = patrolTeamIdList;
    filter.status = this.patrolService.listActiveStatus();

    this.loadingOn();
    this.patrolService.loadFilteredListFromRestApi(DEFAULT_PAGE_INDEX, LARGE_PAGE_SIZE, null, filter).pipe(first()).subscribe((patrolList: PatrolModel[]) => {
      if (patrolList?.length) {
        const questionMsg = patrolList.length > 1 ?
          `Não é possivel remover os usuários, pois suas equipes estão em rondas não concluídas.` :
          `Não é possivel remover o usuário, pois sua equipe está em ronda não concluída.`;

        this.dialog.open(ConfirmationDialogComponent, {
          width: '480px',
          panelClass: 'sipd-modal',
          data: {
            msg: questionMsg ,
            title: 'Atenção!',
            hideOkButton: true,
            cancelLabel: 'Fechar'
          }
        });
      } else {
        // A equipe do usuário não está em nenhuma ronda, portanto verificar se está em uma verificação e seguir
        this.checkTeamListInVerificationsAndDeleteUsers(userList);
      }
    },
    error => {
      this.logger.error(`Erro ao verificar se os usuários estão em uma equipe com ronda não concluída: ${JSON.stringify(error)}`);
      this.toastr.error('Erro ao verificar rondas da equipe do(s) usuário(s)', 'Erro');
    },
    () => {
      this.loadingOff();
    });
  }

  public postListDeleteProcess(rows: EntityModel[]): void {
    rows.forEach( row => {
      const user = row as UserModel;
      if (user.patrolTeamId) {
        this.markerService.sendTeamInfoMessage(this.loggedUser, user.patrolTeamId, "Usuário \"" + user.name + "\" removido da Equipe pois foi removido.");
      }
    });
  }

  private checkTeamListInVerificationsAndDeleteUsers(userList: UserModel[]) {
    if (!userList?.length) {
      return;
    }
    const filter: VerificationFilterModel = new VerificationFilterModel();
    const patrolTeamIdList = userList.map(u => u.patrolTeamId);
    filter.patrolTeams = patrolTeamIdList;
    filter.status = this.verificationService.listActiveStatus();

    this.loadingOn();
    this.verificationService.loadFilteredListFromRestApi(DEFAULT_PAGE_INDEX, LARGE_PAGE_SIZE, null, filter).pipe(first()).subscribe((verificationList: VerificationModel[]) => {
      if (verificationList?.length) {
        const questionMsg = verificationList.length > 1 ?
          `Não é possivel remover os usuários, pois suas equipes estão em verificações não concluídas.` :
          `Não é possivel remover o usuário, pois sua equipe está em verificação não concluída.`;

        this.dialog.open(ConfirmationDialogComponent, {
          width: '480px',
          panelClass: 'sipd-modal',
          data: {
            msg: questionMsg ,
            title: 'Atenção!',
            hideOkButton: true,
            cancelLabel: 'Fechar'
          }
        });
      } else {
        // A equipe do usuário não está em nenhuma verificação, portanto o usuário pode ser removido da sua equipe.
        this.deleteSelectedEntities(userList);
      }
    },
    error => {
      this.logger.error(`Erro ao verificar se os usuários estão em uma equipe com verificação não concluída: ${JSON.stringify(error)}`);
      this.toastr.error('Erro ao verificar as verificações da(s) equipe(s) do(s) usuário(s)', 'Erro');
    },
    () => {
      this.loadingOff();
    });
  }

  onPasswordSendClick(row: EntityModel) {
    if (!(row as UserModel).email) {
      this.toastr.warning('Não foi possível enviar o email. Verifique se o usuário tem email válido cadastrado.', 'Atenção!');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg: 'Enviar a senha por email deste usuário?',
        title: 'Enviar Senha',
        okLabel: 'Enviar',
        identifier: (row as UserModel).login + ' - ' + (row as UserModel).email,
        showIdentifier: true
      }
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
            this.logger.debug('ListComponent.onPasswordSendClick()');
            this.userService.sendEmailWithPassword((row as UserModel)).pipe(first()).subscribe( (user: UserModel) => {
              if(user!=null){
                this.logger.debug(`Email enviado para : ${user.email}`);
                this.toastr.success('Senha enviada com sucesso para o usuário: ' + user.login);
              }else{
                this.toastr.error('Não foi possível enviar o email. Verifique se o usuário tem email válido cadastrado.');
              }
            }, error => {
              this.toastr.warning('Não foi possível enviar o email. Verifique se o usuário tem email válido cadastrado.');
              this.logger.error(error);
            });
        }
    });
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   */
  protected getStringEntityForFilter(user: UserModel): string {
    const userName = super.lowerAndTrimText(user.name);
    const company = user.company? super.lowerAndTrimText(user.company.name): '';
    const login = super.lowerAndTrimText(user.login);
    const cpf = super.lowerAndTrimText(user.cpf);
    const mobile = super.lowerAndTrimText(user.mobilePhone);
    const email = super.lowerAndTrimText(user.email);
    const userProfile = super.lowerAndTrimText(this.getProfileName(user.profileId));
    const originPlacement = (user.originPlacement) ? super.lowerAndTrimText(user.originPlacement.name) : '';
    const team = this.getTeam(user.patrolTeamId);
    return userName + ESP + company + ESP + login + ESP + cpf + ESP + mobile + ESP + email + ESP + userProfile + ESP + originPlacement + team;
  }

  protected getAppAccess(user: UserModel): boolean {
    return user.appAccess;
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'appAccess':
        {
         return item['appAccess'] ? 'Sim' : 'Não';
        }
      case 'patrolTeamId':
        {
           return this.lowerAndTrimText(this.getTeam(item['patrolTeamId']));
        }
      case 'profileId':
        {
          return this.lowerAndTrimText(this.getProfileName(item['profileId']));
        }
      case 'originPlacement':
        {
          return item['originPlacement'] ? this.lowerAndTrimText(item['originPlacement']['name']) : '';
        }
      default: return super.getPropertyByPath(item, property);
   }
  }

  /**
   * Verifica se o usuário possui apenas permiso para editar "alguns perfis".
   * Em caso positivo, os botões para editar usuário são  desabilitados para usuários que não tiverem o mesmo perfil do usuário e,
   * não estiverem marcados na seleção de perfis que o usuário pode alterar
   */
   canCopy(user : any){
    if(user && this.authorizationService.canEditUser((user as UserModel).profileId))
      return true;
    else
      return false;
  }

/**
   * Verifica se o usuário possui apenas permissão para editar "alguns profiles".
   * Em caso positivo, os botão para remover usuário é desabilitados para usuários que não tiverem o mesmo perfil do usuário e,
   * não estiverem marcados na seleção de perfis que o usuário pode alterar
   */
  canDelete(user : any){
    if(user && this.authorizationService.canDeleteUser((user as UserModel).profileId))
      return true;
    else
      return false;
  }

  getProfileName(profileId):string {
    let profile = this.profileMap.get(profileId);
    if (profile) return profile.name;
    return null;
  }

  getProfessionalProfileId():string{
    let profileId;
    this.profileMap.forEach((profile) => {
      if (profile.userType === UserType.PROFESSIONAL)
        profileId = profile.id;
    });
    return profileId;
  }

  onHistoricalTrackingClick(user: UserModel ) {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingUser: user});
  }

  onHistoricalTrackingManyClick(){
    let historicalTrackingUserList = [];
    this.selection.selected.forEach( (user: UserModel) => {
      historicalTrackingUserList.push(user);
    });

    // Note que depois de abrir o mapa pode descobrir que não tem sinais
    this.glOpenContainer(MAP_PAGE, {historicalTrackingUserList: historicalTrackingUserList});
  }

  getTeam(patrolTeamId: string) {
    if (patrolTeamId){
      let team: PatrolTeamModel = this.patrolTeamMap.get(patrolTeamId);
      return team ? team.name : "não encontrada";
    }
    else return "";
  }
}
