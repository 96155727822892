<div class="sipd-container">

  <div class="sipd-header">
    <button mat-icon-button color="accent" matTooltip="Filtrar Rondas" (click)="openFilterDialog()">
      <mat-icon class="material-icons-outlined">filter_list</mat-icon>
    </button>
    <span class="mat-title m-0">{{title}}</span><small matTooltip="Número de Registros Filtrados">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions">
      <mat-form-field class="list-filter  header-input" appearance="outline" >
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>
    </div>

    <div class="actions">

      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button (click)="onCreateClick()"
        matTooltip="Adicionar Ronda"
        [hidden]="isHiddenButtonCreateEditPatrol">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Abrir Ronda(s) Selecionada(s)"
        [disabled]="this.selection.selected.length == 0"
        (click)="onEditManyClick(this.selection.selected)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar Ronda Selecionada
                    (Somente 1 Ronda pode estar selecionada)"
        [hidden]="isHiddenButtonCreateEditPatrol"
        [disabled]="this.selection.selected.length != 1 || isPredicted(selection.selected[0])"
        (click)="onCopyClick(this.selection.selected.pop())">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>

      <button mat-icon-button color="primary"
        matTooltip="Repetição de Ronda Selecionada
                    (Somente 1 Ronda pode estar selecionada)"
        [disabled]="this.selection.selected.length != 1"
        (click)="onPatrolRepetitionClick(this.selection.selected.pop())">
        <mat-icon class="material-icons-outlined">repeat</mat-icon>
      </button>

      <button mat-icon-button color="primary" matTooltip="Remover Rondas Selecionadas"
        [disabled]="denyRemoveMany" 
        (click)="onDeleteManyClick(selection.selected, $event)"
        [hidden]="isHiddenButtonDeletePatrol">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Exportar Ronda Selecionada ou
                                          Exportar Lista de Rondas"
                                          [disabled]="this.selection.selected.length == 1 && isPredicted(selection.selected[0])"
                                          (click)="onExportManyClick()">
        <mat-icon class="material-icons-outlined">save_alt</mat-icon>
      </button>
      <button mat-icon-button [disabled]="denyArchiving"
        (click)="onArchiveManyClick()"
        [matTooltip]= "archiveTooltipText()"
        [hidden]="isHiddenButtonArchiveForUser">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar no Mapa as Rondas Selecionadas"
        [disabled]="this.selection.selected.length == 0"
        (click)="onLocationManyClick(mapEvents.MANY_OPERATION_LOCATION)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar Rastro no Mapa das Rondas Selecionadas"
        [disabled]="this.selection.selected.length == 0"
        (click)="onHistoricalTrackingManyClick()">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar Mensagens da Ronda Selecionada
                    (Somente 1 Ronda pode estar selecionada)"
        [disabled]="this.selection.selected.length != 1 || isPredicted(selection.selected[0])"
        (click)="onMessagesClick(selection.selected[0])">
        <mat-icon class="material-icons-outlined">forum</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="sipd-content bg-white">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="identifier">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.identifier}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell class="nowrap fit-content" *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell class="nowrap fit-content" *matCellDef="let element">
          <mat-chip-list class="mat-chip-list-stacked">
            <mat-chip [class]="element.status? element.status + ' text-white': null " class="justify-content-center" [disabled]="element.status ? null:'disabled'">
              <span class="">{{getOperationStatus(element) + ((element.expired && element.status=='FINISHED')? ' (expirada)': '') }}</span>
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="nowrap" mat-header-cell *matHeaderCellDef mat-sort-header> Nome da Ronda</th>
        <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.name}} </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Data/Hora (Início)</th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.startDate | date:'dd/MM/yyyy HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="patrolTeam.name">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Equipe </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element.patrolTeam?.name}} </td>
      </ng-container>      
      
      <ng-container matColumnDef="priority">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Bloqueada </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element.priority | booleanbr }} </td>
      </ng-container>

      <ng-container matColumnDef="band">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Faixa </th>
        <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
      </ng-container>

      <ng-container matColumnDef="km">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> KM </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.stretchStartKM && element?.stretchEndKM ?
          element?.stretchStartKM + " - " + element?.stretchEndKM : element?.km}} </td>
      </ng-container>
      
      <ng-container matColumnDef="patrolInspectionType">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo de Inspeção </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.patrolInspectionType}} </td>
      </ng-container>

      <ng-container matColumnDef="patrolTeam.company.name">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Empresa </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element.patrolTeam?.company?.name}} </td>
      </ng-container>      

      <ng-container matColumnDef="patrolTeam.serviceType">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Tipo de Serviço </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{getServiceTypeDescription(element)}} </td>
      </ng-container>
      
      <ng-container matColumnDef="patrolTeam.vehicle.plate">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header matTooltip="Placa do Veículo da Equipe"> Placa </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element.patrolTeam?.vehicle?.plate}} </td>
      </ng-container>

      <ng-container matColumnDef="author">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Planejador </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{ element?.author | toView}} </td>
      </ng-container>

      <ng-container matColumnDef="lastSync">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Última Atualização (GPS)</th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element.lastSync | date:'dd/MM/yyyy HH:mm:ss'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
        (contextmenu)="openContextMenu($event, element)"
        [ngClass]="{ 'highlighted': selection.isSelected(row) }">
      </tr>
    </table>

    <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>

    <mat-menu #contextMenu="matMenu">
      <button  mat-menu-item color="primary" (click)="onEditClick(this.contextMenuSelectedItem, $event)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon><span>Abrir Ronda</span>
      </button>
      <button mat-menu-item color="primary" [hidden]="isHiddenButtonCreateEditPatrol" (click)="onCopyClick(this.contextMenuSelectedItem)" [disabled]="isPredicted(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon> <span>Copiar Ronda</span>
      </button>
      <button mat-menu-item color="primary" (click)="onPatrolRepetitionClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">repeat</mat-icon> <span>Repetição de Ronda</span>
      </button>
      <button mat-menu-item color="primary" [hidden]="isHiddenButtonDeletePatrol" [disabled]="!canDelete(contextMenuSelectedItem)" (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.identifier, $event)">
        <mat-icon class="material-icons-outlined">delete</mat-icon> <span>Remover Ronda</span>
      </button>
      <button mat-menu-item color="primary" (click)="onExportClick(this.contextMenuSelectedItem, $event)" [disabled]="isPredicted(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">get_app</mat-icon><span>Exportar Ronda</span>
      </button>
      <button mat-menu-item [disabled]="!canArchive(this.contextMenuSelectedItem)" (click)="onArchiveClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
        <span>{{ archiveButtonLabel }}</span>
      </button>
      <button mat-menu-item color="primary" (click)="onLocationClick(this.contextMenuSelectedItem, mapEvents.OPERATION_LOCATION)" [disabled]="!hasLocation(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">location_on</mat-icon><span>Mostrar no Mapa</span>
      </button>
      <button mat-menu-item color="primary" (click)="onHistoricalTrackingClick(this.contextMenuSelectedItem)" [disabled]="!canHasHistoricalTracking(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">my_location</mat-icon><span>Mostrar Rastro no Mapa</span>
      </button>
      <button mat-menu-item color="primary" (click)="onMessagesClick(this.contextMenuSelectedItem)" [disabled]="isPredicted(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">forum</mat-icon><span>Mensagens da Ronda</span>
      </button>
    </mat-menu>
  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
