import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '../user.model';
/*
 * Exibe o valor de um userModel formatado para componentes visuais como combos, listas, etc
 * Usa apenas o userModel como parâmetro
 * Uso:
 *   user | toView
 * Example:
 *   {{ user | toView }}
 *   formats to: Username ( login )
*/
@Pipe({name: 'toView'})
export class ToViewPipe implements PipeTransform {
  transform(user: UserModel): String {
    return UserModel.getTitle(user);
  }
}
