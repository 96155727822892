import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { OperationAnswerModel } from 'src/app/model/operation.answer.model';
import { ListComponent } from 'src/app/pages/list-component';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { OperationFormAnswersService } from 'src/app/service/model/operation.form.answers.service';
import { VerificationService } from 'src/app/service/model/verification.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { EvidenceNumber } from '../../model/enums.enum';

@Component({
  selector: 'app-answer-list',
  templateUrl: './answer-list.component.html',
  styleUrls: ['./answer-list.component.scss']
})
export class AnswerListComponent extends ListComponent implements OnInit {

  operationAnswersDisplayedColumns: string[];

  _operationId : string;
  _operationType : string;
  _readonly    : boolean;
  @Input() 
  set operationId(id : string){
    this._operationId = id;
    this.loadRecordsFromServer();
  }
  get operationId(): string {
    return this._operationId;
  }

  @Input()
  set editReadOnly(readonly: boolean){
    this._readonly = readonly;
  }

  get editReadOnly() : boolean{
    return this._readonly;
  }

  @Input()
  set operationType(type: string){
    this._operationType = type;
  }

  get operationType() : string{
    return this._operationType;
  }

  evidenceNumber = EvidenceNumber;

  constructor(public logger:                       NGXLogger,
              public authorizationService: AuthorizationService,
              protected operationFormAnswersService:  OperationFormAnswersService,
              protected dialog:               MatDialog,
              protected toastr:             ToastrService,
              protected storageService:     StorageService,
              protected changeDetector:     ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container,
              protected http: HttpClient) {
    super(logger, authorizationService, operationFormAnswersService, dialog, toastr, 'operationFormAnswers',
    environment.VERIFICATION_GROUP_LABEL, environment.VERIFICATION_TITLE_LABEL, //TODO lbarros rever
    environment.VERIFICATION_MODEL_LABEL, storageService, changeDetector,
      goldenLayout, container);
   }

  ngOnInit(): void {
    super.ngOnInit();    
    this.loadRecordsFromServer();
    this.operationAnswersDisplayedColumns = ['answerIndex', 'inspectionPointIndex', 'band', 'km',
      'date', 'time', 'latLong', 'professional', 'inspectionType', 'formName', 'question',
      'answer', 'comments', 'evidence1', 'evidence2', 'evidence3', 'evidence4', 'evidence5' ];
  }

  loadRecordsFromServer() {

    if(this.operationId == "[NOVA]") // se é uma operação nova não precisa chamar ao endpoint
      return;
    
    this.loadingOn();
    this.dataSource = new MatTableDataSource([]);

    let params = new HttpParams();
    params = params.set('id', this.operationId);    

    this.operationFormAnswersService.loadAnswersFromRestApi(params, this.operationType).subscribe( (result) => {
      if(result){
        this.updateDataSource(result);
        this.updateEvidenceFile(result);        
      }
      
      this.loadingOff();
    }, error => {
      this.logger.error(error);
      this.loadingOff();
    });
  }

  updateEvidenceFile(answers : OperationAnswerModel[]){
    for( let i=0 ; i < answers.length ; i++){
      let answer = answers[i];
      if(answer.evidence1)
        this.getEvidenceFile(answer.evidence1 , answer,  EvidenceNumber.ONE);

      if(answer.evidence2)
        this.getEvidenceFile(answer.evidence2 , answer, EvidenceNumber.TWO);
      
      if(answer.evidence3)
        this.getEvidenceFile(answer.evidence3 , answer,  EvidenceNumber.THREE);
      
      if(answer.evidence4)
        this.getEvidenceFile(answer.evidence4 , answer,  EvidenceNumber.FOUR);
      
      if(answer.evidence5)  
        this.getEvidenceFile(answer.evidence5 , answer, EvidenceNumber.FIVE);      
    }
  }

  getEvidenceFile(idFile : string , answer : OperationAnswerModel,  evidenceNumber : EvidenceNumber){
    
    this.operationFormAnswersService.loadFileById(idFile, this.operationType).pipe(first()).subscribe((file) =>{

      if(file.body.size === 0) return;

      const entity: any = this.dataSource.data.find(entity => entity.id === answer.id);
      if(!entity) return;

      var reader = new FileReader();
      reader.readAsDataURL(file.body);
      

      switch(evidenceNumber){
        case EvidenceNumber.ONE:
          reader.onload = (_event) => {            
            entity.evidenceFileUrl1 = reader.result;
          }
          break;
        case EvidenceNumber.TWO:
          reader.onload = (_event) => {
            entity.evidenceFileUrl2 = reader.result;
          }
          break;
        case EvidenceNumber.THREE:
          reader.onload = (_event) => {
            entity.evidenceFileUrl3 = reader.result;
          }
          break;
        case EvidenceNumber.FOUR:
          reader.onload = (_event) => {
            entity.evidenceFileUrl4 = reader.result;
          }
          break;
        case EvidenceNumber.FIVE:
          reader.onload = (_event) => {
            entity.evidenceFileUrl5 = reader.result;
          }
          break;
      }      
    }, (error : any) =>this.logger.error(error));
  }

  protected getDefaultSortColumn(): string {
    return "answerIndex";
  }

  protected getDefaultSortDirection(): 'asc' | 'desc' {
    return 'asc';
  }
  
  viewFile(fileData){    
    let image = new Image(); 
    image.src = fileData;
    let w = window.open("");
    w.document.write(image.outerHTML);
  }

}
