import { GeoModel } from './geo.model';
import { environment } from 'src/environments/environment';

export class DuctModel extends GeoModel{
  DUTO_CD_DUTO: string; // um outro id do duto, mas pode estar vazio. (não é referenciado pelos outros kmls, mas deixamos na importação)
  to:           string;
  from:         string;
  extension:    string;
  diameter:     string;

  public static getPopupContent(feature): string{

    if (feature && feature.properties){
      
      const duct = feature.properties as DuctModel;

      let typeValues = {OilDuct:"Oleoduto",  GasDuct:"Gasoduto"};
      let states: string = duct.states ? duct.states.toString() : '';
      let contents: string =  `<h5 style="text-align: center">${ typeValues[duct.type] }</h5>
                               <h6 style="text-align: center"><b>${ duct.name }</b></h6>
                               <div><b>Origem:</b> ${duct.from?duct.from:''} </div>
                               <div><b>Destino:</b> ${duct.to?duct.to:''} </div>
                               <div><b>Extensão (m):</b> ${duct.extension?duct.extension:''} </div>
                               <div><b>Diâmetro (in):</b> ${duct.diameter?duct.diameter:''} </div>
                               <div><b>UF(s):</b> ${states} </div>
                              `;
      if (!environment.showPopupLayerTables || duct.description == null || duct.description === "") {                              
        return contents;
      }
      else {
        return contents + "<div style='height:200px;overflow:auto;'><hr/>" + duct.description
      }
    }

    return `<h5>Sem Dados</h5>`;
  }
}


