import DateUtils from '../service/util/date-utils';
import FieldUtils from '../service/util/field-utils';
import {EntityModel} from './entity.model';
import { AlertPriorityDescription, AlertTypeDescription, OperationTypeDescription, SimfAlertTypeDescription, SourceType } from './enums.enum';
import {PatrolTeamModel} from './patrolteam.model';

export class AlertModel extends EntityModel {
  identifier:             string;
  acknowledged:           boolean;
  acknowledgmentAuthor:   string;
  acknowledgmentDate:     number;
  alertType:              string;
  alertPriority:          string;
  description:            string;
  observation:            string;
  sourceType:             string;
  location:               GeoJSON.Point;  // inicialização do GeoPoint para alertas está errada, está sendo feito (x=latitude e y=longitude)
  timestamp:              number;
  patrolTeam:             PatrolTeamModel;
  operationId:            string;
  operationIdentifier:    string;
  operationType:          string;
  eventIdentifier:        string;
  inspectionId:           string;
  userId:                 string;
  userName:               string;
  band:                   string;
  duct:                   string;
  km:                     string;
  simfAlertId:            string;
  simfAlertType:          string;
  observedArea:           string;
  locationTimestamp:      number;
  endChannel:             number;
  scalePoint:             string;
  cpsNumber:              number;
  createdAt:              number;
  
  simf: any;

  constructor(){
    super();
    this.acknowledged = false;
  }

  public static getPopupContent(alert: AlertModel, search: boolean = false){
    const patrolTeam: PatrolTeamModel = alert.patrolTeam;

    return `<h5 style="text-align: center">Alerta ${ alert.sourceType == SourceType.MOBILE_APP ? 'App Móvel': 'SIMF'}</h5>
            ${alert.identifier? `<div> <b>ID:</b> ${ alert.identifier } </div>` : ''}
            <div> <b>Origem:</b> ${ alert.sourceType == SourceType.MOBILE_APP ? AlertTypeDescription[alert.alertType]: (SimfAlertTypeDescription[alert.simfAlertType]? SimfAlertTypeDescription[alert.simfAlertType]: alert.simfAlertType) } </div>
            <div> <b>Reconhecido:</b> ${ alert.acknowledged? 'Sim': 'Não' } </div>
            <div> <b>Nível:</b> ${ AlertPriorityDescription[alert.alertPriority] } </div>
            ${ alert.operationType? `<div> <b>Operação:</b>  ${ OperationTypeDescription[alert.operationType] } </div>` : '' }
            ${ alert.eventIdentifier || alert.operationIdentifier ? `<div style="margin-left: 1rem"> <b>ID:</b> ${ alert.eventIdentifier? alert.eventIdentifier: alert.operationIdentifier}</div>` : '' }
            ${ alert.sourceType == SourceType.MOBILE_APP ? PatrolTeamModel.getPopupContent(patrolTeam, search): '' }
            ${ alert.sourceType == SourceType.MOBILE_APP ? '<div> <b>Descrição: </b>' + alert?.description: '' }
            ${ alert.sourceType == SourceType.SIMF ? '<div> <b>Dados SIMF: </b><div style="margin-left: 1em">' + this.getSimfInfo(alert, "<br>") + '</div>': '' }
            <div> <b>Lat, Long:</b>  ${!search? FieldUtils.coordToString(alert.location.coordinates[0]): ''},${!search? FieldUtils.coordToString(alert.location.coordinates[1]): ''} </div>
            <div> <b>Data/Hora da localização:</b> ${alert.locationTimestamp ? DateUtils.timestampToStringInMinutes(alert.locationTimestamp) : "Não"} </div>
            <div> <b>Data/Hora:</b>  ${DateUtils.timestampToStringInMinutes(alert.timestamp)} </div>
          `;
  }

  public static getSimfInfo(alert, linebreak): string{
    if (!alert)
      return '';

    if (!alert.simf)
      return '';

    let info = "" +
      "Id: " + alert.simf.alertId + linebreak +
      "Início: " + DateUtils.timestampToStringInMinutes(alert.simf.startTime) + linebreak +
      "Fim: " + DateUtils.timestampToStringInMinutes(alert.simf.endTime) + linebreak +
      "OPS: " + alert.simf.cpsNumber + linebreak +
      "Canal Inicial: " + alert.simf.startChannel + linebreak +
      "Canal Final: " + alert.simf.endChannel + linebreak +
      "Distância Ótica Inicial (metros): " + alert.simf.startOpticalDistanceMetres + linebreak +
      "Distância Ótica Final (metros): " + alert.simf.endOpticalDistanceMetres + linebreak +
      "Latitude Inicial: " + FieldUtils.coordToString(alert.simf.startLatitude) + linebreak +
      "Longitude Inicial: " + FieldUtils.coordToString(alert.simf.startLongitude) + linebreak +
      ((alert.simf.endLatitude && alert.simf.endLatitude != 0)? "Latitude Final: " + FieldUtils.coordToString(alert.simf.endLatitude) + linebreak: "") +
      ((alert.simf.endLongitude && alert.simf.endLongitude != 0)? "Longitude Final: " + FieldUtils.coordToString(alert.simf.endLongitude) + linebreak: "") +
      "Kpoint:   " + alert.simf.scalePoint + linebreak +
      ((alert.simf.zoneName && alert.simf.zoneName.lenght > 0)? "Zona: " + alert.simf.zoneName + linebreak: "") +
      "Origem: " + (SimfAlertTypeDescription[alert.simf.alertType]? SimfAlertTypeDescription[alert.simf.alertType]: alert.simf.alertType) + linebreak +
      "Nível: " + AlertPriorityDescription[alert.simf.alertLevel.toUpperCase()] + linebreak +
      "Velocidade: " + alert.simf.speed + linebreak +
      (alert.simf.externalType? "Tipo Externo: " + alert.simf.externalType + linebreak: '') +
      (alert.simf.externalId? "Id Externo: " + alert.simf.externalId: '');

    return info;
  }
}
