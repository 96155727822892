import { environment } from '../../environments/environment';

/**
 * Use to patch all functions/methods in a class and make them print out run time
 * in ms to the console.
 *
 * This decorator will only patch functions declared in the target class.
 * It will **not** patch functions reside in the **base class**.
 * Dynamically created functions or functions received from the outside as input
 * may also not be patched.
 *
 * Keep in mind that the act of printing stuffs to the console itself will slow down
 * some function a little. This could add up if that function is called multiple times in a loop.
 * Callbacks may also not be tracked so functions that rely on
 * callbacks to do heavy lifting may appear to take very little time
 * here.
 *
 * @param threshold allow filtering log to only those that took more than the threshold (in ms)
 */
export function ProfileClassToConsole({ prefix = '', threshold = environment.profileThresHold } = {}) {

    return function (target: Function) {

        // Guard to skip patching
        if (!environment.profileClassToConsoleEnabled) {
            return;
        }

        // Loop through all property of the class
        for (const propName of Object.getOwnPropertyNames(target.prototype)) {

            const descriptor = Object.getOwnPropertyDescriptor(target.prototype, propName);

            // If not a function, skip
            if (propName == 'constructor' || !(descriptor.value instanceof Function)) {
                continue;
            }

            const windowsPerfomance = window.performance;
            const fn = descriptor.value;

            descriptor.value = function (...args: any[]): any {

                const before = windowsPerfomance.now();

                const result = fn.apply(this, args);

                const after = windowsPerfomance.now();
                const runTime = after - before;
                if (runTime > threshold) {
                    let time = runTime.toFixed(0);
                    console.log("ProfileClassToConsole ", prefix, target.name, ': ', propName, 'took', time, 'ms');
                }

                return result;

            }
            
            Object.defineProperty(target.prototype, propName, descriptor);
        }
    }
}
