
export class ValidationNoteModel  {
  
  public date:           number;
  public author:         string;
  public note:           string;

  constructor(date:number, author:string, note:string) {
    this.date = date;
    this.author = author;
    this.note = note;
  }
}
