import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/service/storage-service';
import { NGXLogger } from 'ngx-logger';
import { UserModel } from '../model/user.model';


@Injectable({
    providedIn: 'root'
  })
export class AuthInterceptor implements HttpInterceptor {

    constructor(private logger: NGXLogger, private storageService: StorageService) {
    }

    // Toda requisição http é interceptada para receber o token do usuário
    // O estado de erro do acesso ao backend é tratado pelo InvalidTokenApiService

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const loggedUser: UserModel = this.storageService.getLocalUser();
        if (loggedUser) {
            request = this.addToken(request, loggedUser.token);
            return next.handle(request);
        }
        this.logger.error('HttpInterceptor: sem usuário logado');
        return next.handle(request);
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        const headers = req.headers
            .set('Cache-Control', 'no-cache')
            .set('access-control-expose-headers', 'Authorization')
            .set('Access-Control-Allow-Origin', '*')
            .set('withCredentials', 'true')
            .set('Authorization', token);
        return req.clone({ headers });
    }
}
