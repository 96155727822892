<div class="sipd-container ">
  <div class="sipd-content bg-shade-50">
    <div class="p-2">
      <label id="period-group-label " class="mb-2 w-100">Ativos:</label>
      <mat-radio-group class="d-flex flex-column" aria-labelledby="period-group-label"
        [(ngModel)]="filterModel.areaStatus">
        <mat-radio-button class="mr-4 my-2" color="accent" [value]="'ALL'">Todos
        </mat-radio-button>
        <mat-radio-button class="mr-4 mb-2" color="accent" [value]="status.ACTIVE">{{ statusDescription[status.ACTIVE] }}
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="status.INACTIVE">{{ statusDescription[status.INACTIVE] }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="p-2">
      <label id="period-group-label " class="mb-2 w-100">Período:</label>
      <mat-radio-group class="d-flex flex-column" aria-labelledby="period-group-label"
        [(ngModel)]="filterModel.current">
        <mat-radio-button class="mr-4 mb-2" color="accent" [value]="true">Hoje</mat-radio-button>
        <mat-radio-button color="accent" [value]="false">Especificado</mat-radio-button>
      </mat-radio-group>
    </div>

    <div [hidden]="filterModel.current == true">
      <div class="d-flex flex-column p-2">
        <date-chooser placeholder="Data Inicial" [(valueData)]="viewFilterStartDate" #startDateEl="dateChooserContext" fxFlex></date-chooser>

        <mat-form-field appearance="outline" class="no-hint-text" fxFlex>
          <mat-label>Hora Inicial</mat-label>
          <input matInput mask="Hh:m0" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" name="startTime" placeholder="Hora Inicial" #startTimeEl="ngModel"
            [(ngModel)]="viewFilterStartTime">
          <mat-icon matSuffix>query_builder</mat-icon>
        </mat-form-field>

        <date-chooser placeholder="Data Final" [(valueData)]="viewFilterEndDate" #endDateEl="dateChooserContext" fxFlex></date-chooser>

        <mat-form-field class="mr-2" appearance="outline" class="no-hint-text" fxFlex>
          <mat-label>Hora Final</mat-label>
          <input matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="endTime" placeholder=""
            [(ngModel)]="viewFilterEndTime" #endTimeEl="ngModel">
          <mat-icon matSuffix>query_builder</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-column p-2">

      <multiselect-autocomplete class="no-hint-text" [data]="responsibles" name="name" optionValue="id" placeholder="Responsável"
        [(valueData)]="filterModel.responsibles" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="typesDescription | keyvalue: originalOrder" placeholder="Tipo" name="value" optionValue="key"
        [(valueData)]="filterModel.types" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa"
        [(valueData)]="filterModel.bands" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="singleDataCacheService.getValues('City')" placeholder="Cidade"
        [(valueData)]="filterModel.cities" fxFlex>
      </multiselect-autocomplete>

      <div class="form-spacer" fxFlex></div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="m-2 py-2" fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0">
    <button mat-raised-button (click)="clearFilter()" fxFlex>Limpar</button>
    <button mat-raised-button [disabled]="startDateEl.invalid || endDateEl.invalid" (click)="onFilter()" fxFlex>Aplicar</button>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
