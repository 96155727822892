
<div mat-dialog-title>
  <span>Importar do Cadastro</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div class="sipd-container">
  <div class="sipd-header">
    <button mat-icon-button color="accent" matTooltip="Filtrar Pontos" (click)="openFilterDialog()">
      <mat-icon class="material-icons-outlined">filter_list</mat-icon>
    </button>
    <span class="mat-title m-0">Pontos</span>
    <mat-form-field class="list-filter header-input" appearance="outline" style="margin-left: 300px;">
         <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
    </mat-form-field>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="p-0">
    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100 mb-5">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content"  *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content"  *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="identifier">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> ID </th>
        <td  mat-cell class="nowrap" *matCellDef="let element"> {{element?.identifier}} </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell class="nowrap"  *matCellDef="let element">{{pointStatusDescription[element?.status] || "Sem status"}}</td>
      </ng-container>

      <!-- source Column -->
      <ng-container matColumnDef="source">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Origem </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.source}} </td>
      </ng-container>

      <!-- creationDate Column -->
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Data/Hora da criação </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.creationDate | date:'dd/MM/yyyy HH:mm'}} </td>
      </ng-container>

      <!-- band Column -->
      <ng-container matColumnDef="band">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Faixa </th>
        <td mat-cell class="ellipsis nowrap" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
      </ng-container>

      <!-- stretch Column -->
      <ng-container matColumnDef="stretch">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Trecho </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.stretch}} </td>
      </ng-container>

      <!-- KM Column -->
      <ng-container matColumnDef="km">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> KM </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{kmIntToString(element?.km)}} </td>
      </ng-container>

      <!-- DC Column -->
      <ng-container matColumnDef="dc">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> DC </th>
        <td mat-cell class="nowrap" *matCellDef="let element" [style.color]="element.dc === 'YES' ? 'red' : 'black'"> {{inspectionBooleanDescription[element?.dc] || " "}} </td>
      </ng-container>

      <!-- accessType Column -->
      <ng-container matColumnDef="accessType">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Tipo de Acesso </th>
        <td mat-cell class="nowrap"  *matCellDef="let element"> {{element?.accessType}} </td>
      </ng-container>

      <!-- category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell class="nowrap"  *matHeaderCellDef mat-sort-header> Categoria </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.category}} </td>
      </ng-container>

        <!-- vulnerabilityView Column -->
      <ng-container matColumnDef="vulnerabilityView">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Vulnerabilidade </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.vulnerabilityView}} </td>
      </ng-container>

      <!-- city Column -->
      <ng-container matColumnDef="city">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Cidade </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.city}} </td>
      </ng-container>

      <!-- UF Column -->
      <ng-container matColumnDef="uf">
        <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> UF </th>
        <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.uf}} </td>
      </ng-container>

      <!-- reference Column -->
      <ng-container matColumnDef="reference">
          <th mat-header-cell class="nowrap" *matHeaderCellDef mat-sort-header> Referência </th>
          <td mat-cell class="nowrap" *matCellDef="let element"> {{element?.reference}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
        [ngClass]="{ 'highlighted': selection?.isSelected(row), 'actived': row.importedByOperation }">
      </tr>
    </table>
</div>
<mat-divider></mat-divider>
<mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onImportClick()" [disabled]="selection?.selected.length == 0">Importar</button>
  <button mat-raised-button mat-dialog-close> Cancelar </button>
</mat-dialog-actions>
