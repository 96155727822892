import { AbstractSignalModel } from "./abstract.signal.model";

/**
 * Sinal recebido de um veículo
 */
export class VehicleSignalModel extends AbstractSignalModel {

  public placa: string; // O mesmo que mobileObjectId
  public data: string;
  public hora: string;
  public empresa: string;
  public marca: string;
  public motor: string;
  public movimento: string;
  public velocidade: string;
  public gps: string;
  public precisao: string;
  public direcao: string;
  
  public enriched: boolean;

  // Enriquecido apenas quando o veículo não tem equipe
  public companyId:string;
  public placementId: string;

  constructor(){
    super();
  }

  static getPopupContent(vehicleSignal: VehicleSignalModel) : string {
    return `<div> <b>Dados:</b></div>
            <div style="margin-left: 1rem"> <b>Empresa:</b> ${vehicleSignal.empresa ? vehicleSignal.empresa : ``} </div>
            <div style="margin-left: 1rem"> <b>Carro:</b> ${vehicleSignal.marca ? vehicleSignal.marca : ``} </div>
            <div style="margin-left: 1rem"> <b>Motor:</b> ${vehicleSignal.motor ? vehicleSignal.motor : ``} </div>
            <div style="margin-left: 1rem"> <b>Movimento:</b> ${vehicleSignal.movimento ? vehicleSignal.movimento : ``} </div>
            <div style="margin-left: 1rem"> <b>Velocidade:</b> ${vehicleSignal.velocidade ? vehicleSignal.velocidade : ``} </div>
            <div style="margin-left: 1rem"> <b>GPS:</b> ${vehicleSignal.gps ? vehicleSignal.gps : ``} </div>
            <div style="margin-left: 1rem"> <b>Precisão:</b> ${vehicleSignal.precisao ? vehicleSignal.precisao : ``} </div>
            <div style="margin-left: 1rem"> <b>Direção:</b> ${vehicleSignal.direcao ? vehicleSignal.direcao : ``} </div>
           `;
  }
}
