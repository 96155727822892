import { Component, OnDestroy, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { FormService } from 'src/app/service/model/form.service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { FormFilterModel } from '../form-filter-dialog/form.filter.model';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { FormFilterDialogComponent } from '../form-filter-dialog/form-filter-dialog.component';
import { Subscription } from 'rxjs';
import { FormModel } from 'src/app/model/form.model';
import { ESP, SORT_NAME_ASC } from 'src/app/common/constants';
import { RegistrationListComponent } from '../../registration-list-component';
import { RegistrationType, UserType } from 'src/app/model/enums.enum';
import { first } from 'rxjs/operators';
import { PlacementModel } from 'src/app/model/placement.model';
import { AuthenticationService } from '../../../login/login-services/authentication.service';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListComponent extends RegistrationListComponent implements OnInit, OnDestroy {

  model: FormModel[];

  filterModel: FormFilterModel;
  formUpdateSubscription: Subscription;
  userPlacements: PlacementModel[] = [];
  userPlacementId : string;

  constructor(logger:                           NGXLogger,
              formService:                      FormService,
              dialog:                           MatDialog,
              public authorizationService:      AuthorizationService,
              protected storageService:         StorageService,
              protected toastr:                 ToastrService,
              protected changeDetector:         ChangeDetectorRef,
              protected authenticationService:  AuthenticationService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, formService, dialog, 'forms', environment.FORM_GROUP_LABEL, environment.FORM_TITLE_LABEL,
          environment.FORM_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('FormListComponent.constructor()');
    this.filterModel = new FormFilterModel();
    this.registrationType = RegistrationType.FORM;
    this.sortOptions = SORT_NAME_ASC;
    this.userPlacementId = this.authenticationService.getUserPlacementId();
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.logger.debug('PointListComponent.ngOnInit()');


    this.displayedColumns = ['select',
                             'identifier',
                             'name',
                             'placement.name',
                             'inspectionType',
                             'published'
                            ];

    this.loadRecordsFromServer();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.formUpdateSubscription?.unsubscribe();
  }

  protected getDefaultSortColumn(): string {
    return "name";
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FormFilterDialogComponent, {
      width: '800px',
      data: this.filterModel,
      panelClass: 'sipd-modal'
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.model = result;
        this.buildDataSource();
      }
    });
  }

  protected getStringEntityForFilter(form: FormModel): string {
    const name = super.lowerAndTrimText(form.name);
    const placement = super.lowerAndTrimText(form.placement.name);
    const inspectionType = super.lowerAndTrimText(form.inspectionType);
    const identifier = super.lowerAndTrimText(form.identifier);
    const published = form.published? 'Sim': 'Não'
    return name + ESP + placement + ESP + inspectionType + ESP + identifier + published;
  }
  
  /**
   * Verifica se o formulário ou formulários selecionados pertencem à lotação do usuário logado no sistema
   * @param selectedForms 
   * @returns disabled
   */
  checkManyUserPlacements(selectedForms){
    if(!selectedForms)
      return;

    if(this.isUserAdministrator()) // administrador pode deletar formulários de qualquer lotação
      return false; 

    let disabled = false;    
    for(var i= 0; i < selectedForms.length ; i++){      
      if(selectedForms[i].placement.id != this.userPlacementId ){
        disabled = true;
        break;
      }     
    }    
    return disabled;  this.loggedUser
  }
  
  checkUserPlacement(selectedForm){
    if(!selectedForm)
      return;

    const form = [selectedForm]
    return this.checkManyUserPlacements(form);
  }

  /** Indica se o usuário logado é ou não um administrador. */
  isUserAdministrator(): boolean {
    return this.loggedUserProfile.userType === UserType.ADMINISTRATOR;
  }

}
