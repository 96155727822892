import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PatrolTeamModel} from '../../model/patrolteam.model';
import {EntityService} from './entity.service';
import {environment} from '../../../environments/environment';
import {Observable } from 'rxjs';
import { EntityModel } from 'src/app/model/entity.model';
import { FilterModel } from 'src/app/general/filter-component/filter.model';

@Injectable({
  providedIn: 'root'
})
export class PatrolTeamService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/patrol-teams`);
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]> (pageIndex?: number, pageSize?: number, sort?: string, filter?: FilterModel): Observable<T> {
    let params: HttpParams = new HttpParams();

    if (filter){
      if (filter.placements){
        filter.placements.forEach( placement => {
          params = params.append('placements', placement);
        });
      }
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  public loadTeamByUser(userId: string): Observable<PatrolTeamModel>{
    let params: HttpParams = new HttpParams();
    params = params.set('userId', userId);

    return this.http.get<PatrolTeamModel>(this.apiUrl + '/user', {params: params});
  }
}
