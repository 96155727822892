import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChange, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() button_text = 'Escolher...';
  
  @Input() file_icon = 'article';
  @Input() file_label = 'Arquivo';
  @Input() file_placeholder = 'Nenhum arquivo selecionado';
  @Input() filename: string;
  @Input() seeFile: boolean;
  @Input() readOnly: boolean;
  @Output() onFileSelected = new EventEmitter<File>();
  @Output() viewFileSelected = new EventEmitter<string>();

  @ViewChild('uploadfileinput') uploadFileInput;

  changed: boolean;
  file: File;

  constructor(public dialog: MatDialog,
	            private logger: NGXLogger) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    this.init();
  }

  init() {
    this.changed = false;
  }

  fileSelected(): void {
    const files = this.uploadFileInput.nativeElement.files;
    if (files.length > 0) {
      const file = files[0];
      this.file = file;
      this.logger.debug('this.file: ', this.file);
      /* this.filename = file.name; Não atribuir filename aqui, deixar para o cliente */
      this.changed = true;
      this.onFileSelected.emit(this.file);
    }
  }

  clearFile(): void {
    /* this.filename = undefined; */
    this.file = undefined;
    this.changed = true;
    this.onFileSelected.emit(this.file);
  }

  openFile(): void {
    this.viewFileSelected.emit(this.filename);
  }

  hasChanged(): boolean {
    return this.changed;
  }

  getFile(): File {
    return this.file;
  }
}
