
export enum ArchivedType{
  ALL,
  ARCHIVED,
  NON_ARCHIVED
}

export class FilterModel {
  archived:       ArchivedType;
  current:        boolean;
  startDate:      number;
  endDate:        number;
  status:         string[];
  // Usado para o perfil nas listas 
  placements: string[] = []; 
  associatedEventId: string;  // usado para filtro de eventos asociados

  // por enquanto são somente utilizados por eventos e verificações, ainda não usados para rondas
  analysts:       string[];
  ducts:          string[];
  valves:         string[];

  constructor(){
    this.current = false;
    this.archived = ArchivedType.NON_ARCHIVED;
  }
}
