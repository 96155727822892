import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { NGXLogger } from 'ngx-logger';
import { HttpClient, HttpParams} from '@angular/common/http';
import { FormModel } from 'src/app/model/form.model';
import { Observable, Subject } from 'rxjs';
import { FormFilterModel } from 'src/app/pages/registrations/form/form-filter-dialog/form.filter.model';
import { QuestionModel } from 'src/app/model/question.model';
import { QuestionType } from 'src/app/model/enums.enum';
import {environment} from '../../../environments/environment';
import { EntityModel } from 'src/app/model/entity.model';

import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FormService extends EntityService {

  /** Serviço de notificação de mudanças na árvore do questionário */
  private quizDataSubject = new Subject<FormModel>();

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
                super(logger, httpClient, `${environment.settings.registrations_address}/forms`);
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: FormFilterModel): Observable<T> {
    let params: HttpParams = new HttpParams();

    if(filter){
      if (filter.name){
        params = params.set('name', filter.name);
      }

      if (filter.placement){
        params = params.set('placement', filter.placement);
      }

      if (filter.inspectionType){
        params = params.set('inspectionType', filter.inspectionType);
      }
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  /** Provê o observable para as notificações de mudanças na árvore */
  public onQuizDataChange(){
    return this.quizDataSubject.asObservable();
  }

   /** Notifica a mudança na árvore */
  private notifyQuizDataChange(forModel: FormModel){
    this.quizDataSubject.next(forModel);
  }

  /**
   * Método recursivo que busca pelo nó pai na árvore
   * ou, se passado como parametro onlyQuestions = true, busca pela pergunta pai na árvore
   * @param childNode
   * @param quizNodes
   * @param onlyQuestions
   * @returns
   */
  findParentNode(childNode: QuestionModel, quizNodes: QuestionModel[], onlyQuestions: boolean = false) : QuestionModel | undefined{
    for(let targetNode of quizNodes){

      if(targetNode.children.includes(childNode)){
        return targetNode;
      }

      if(targetNode.children && targetNode.children.length > 0){ // Se o nó não é o pai, mas ele possui filhos, continua descendo na árvore
        let foundNode = this.findParentNode(childNode, targetNode.children, onlyQuestions);

        if(foundNode !== undefined) return foundNode;
      }
    }
    return undefined;
  }

  /**
   * Cria um novo nó para a árvore
   * @param text
   * @param nodeType Tipo do nó da árvore
   * @returns O novo nó criado
   */
   public createQuizNode(text: string, nodeType: QuestionType): QuestionModel{
    const quizNode = new QuestionModel();
    quizNode.type = nodeType;
    quizNode.label = text;
    quizNode.identifier = uuidv4();


    if(nodeType === QuestionType.ANSWER){
      quizNode.evidenceRequirement = undefined;
    }

    return quizNode;
  }

   /** Copia os dados do nó target para o nó source */
   copyQuizNode(sourceQuestion: QuestionModel, targetNode: QuestionModel){
    targetNode.evidenceRequirement = sourceQuestion.evidenceRequirement;
    targetNode.label = sourceQuestion.label;

    if(targetNode.type !== sourceQuestion.type){
      targetNode.children = sourceQuestion.children;
    }

    targetNode.type = sourceQuestion.type;
  }

  /**
   * ######################
   * Métodos das Perguntas
   * ######################
   */

  /**
   * Adiciona o novo nó aos filhos do nó pai e verifica o tipo de pergunta.
   * No caso de YESNO ou NUMBER, adiciona os nós filhos padrão dos dois casos e notifica a mudança na árvore.
   * @param parentNode
   * @param newQuestion
   */
   addQuestion(parentNode: QuestionModel, newQuestion: QuestionModel, formModel: FormModel){
    if(parentNode){
      parentNode.children.push(newQuestion);
    }else{
      formModel.questions.push(newQuestion);
    }

    this.checkSubQuestions(newQuestion);
    this.notifyQuizDataChange(formModel);

    this.logger.debug('**** addQuestion(parentNode: QuestionModel, newQuestion: QuestionModel): Nova pergunta adicionada');
    this.logger.trace(newQuestion);
  }

  /**
   * Copia os valores do nó editado no nó original e verifica o tipo de pergunta.
   * No caso de YESNO ou NUMBER, adiciona os nós filhos padrão dos dois casos e notifica a mudança na árvore.
   * @param originalQuestion
   * @param editedQuestion
   */
     editQuestion(originalQuestion: QuestionModel, editedQuestion: QuestionModel, formModel: FormModel){

      if(editedQuestion.type !== originalQuestion.type){
        this.checkSubQuestions(editedQuestion);
      }

      this.copyQuizNode(editedQuestion, originalQuestion);

      this.notifyQuizDataChange( formModel);

      this.logger.debug('**** editQuestion(originalQuestion: QuestionModel, editedQuestion: QuestionModel): Pergunta editada');
      this.logger.trace(originalQuestion);
    }

    /**
   * Verifica o tipo de pergunta. No caso de YESNO ou NUMBER, adiciona os nós filhos padrão dos dois casos.
   * @param question
   */
  checkSubQuestions(question: QuestionModel){
    question.children = [];
    switch(question.type) {
      case QuestionType.YESNO:
        question.children.push(this.createQuizNode('Sim', QuestionType.ANSWER));
        question.children.push(this.createQuizNode('Não', QuestionType.ANSWER));
        question.children.push(this.createQuizNode('N/A', QuestionType.ANSWER));
        break;
      case QuestionType.ACTIVEINACTIVE:
        question.children.push(this.createQuizNode('Ativo', QuestionType.ANSWER));
        question.children.push(this.createQuizNode('Inativo', QuestionType.ANSWER));
        question.children.push(this.createQuizNode('N/A', QuestionType.ANSWER));
        break;
      case QuestionType.VIOLATEDCOMPLETE:
        question.children.push(this.createQuizNode('Violada', QuestionType.ANSWER));
        question.children.push(this.createQuizNode('Íntegra', QuestionType.ANSWER));
        question.children.push(this.createQuizNode('N/A', QuestionType.ANSWER));
        break;
      case QuestionType.NUMBER:
        question.children.push(this.createQuizNode('[0-0]', QuestionType.ANSWER)); //Padrão de interpretação do reader
        question.children.push(this.createQuizNode('[1-*]', QuestionType.ANSWER));
    }
  }

   /**
   * Remove o nó da árvore e notifica as mudanças na árvore
   * @param deletedQuestion
   */
  removeQuestion(deletedQuestion: QuestionModel, formModel : FormModel){
    let targetNode = this.findParentNode(deletedQuestion, formModel.questions);
    if(targetNode){
      targetNode.children.splice(targetNode.children.indexOf(deletedQuestion), 1);
    }else{
      formModel.questions.splice(formModel.questions.indexOf(deletedQuestion), 1);
    }

    this.notifyQuizDataChange( formModel);
    this.logger.debug('**** removeQuestion(deletedQuestion: QuestionModel): Pergunta removida');
    this.logger.trace(deletedQuestion);
  }

  public updatePublisher <T extends EntityModel>(form: FormModel, publish: boolean): Observable<T>{
    form.published = publish;
    return this.http.put<T>(this.apiUrl + '/'+ form.id, form );
  }




}
