import { Directive } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { EntityService } from "src/app/service/model/entity.service";
import { UserService } from "src/app/service/model/user.service";

import { LayerService } from "src/app/service/model/layer.service";
import { FilterModel } from "./filter.model";
import { StorageService } from "src/app/service/storage-service";
import { PatrolTeamService } from "src/app/service/model/patrol.team.service";
import { FilterComponent } from "./filter-component";
import { SingleDataCacheService } from '../../service/model/single.data.cache.service';
import { EntityCacheService } from "src/app/service/model/entity.cache.service";

@Directive()
export abstract class OperationFilterComponent extends FilterComponent  {

  constructor(protected logger:             NGXLogger,
              protected userService:        UserService,
              protected layerService:       LayerService,
              protected patrolTeamService:  PatrolTeamService,
              public singleDataCacheService:  SingleDataCacheService,
              public entityCacheService:    EntityCacheService,
              protected entityService:      EntityService,
              protected filterModel:        FilterModel,
              protected storageService:     StorageService
              ) { super(logger, userService, layerService, singleDataCacheService, entityCacheService, entityService, filterModel, storageService); }

  loadPatrolTeams(){
    this.entityCacheService.loadPatrolTeams(this.loadingListService);
  }
}
