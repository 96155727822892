import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';

import { EventMainActionModel } from 'src/app/model/event.main.action.model';
import { EditComponent } from 'src/app/pages/edit-component';
import { EventMainActionsService } from 'src/app/service/model/event.main.actions.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { Permission } from 'src/app/model/enums.enum';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';

@Component({
  selector: 'app-event-main-actions-edit',
  templateUrl: './event-main-actions-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './event-main-actions-edit.component.scss']
})
export class EventMainActionsEditComponent extends EditComponent {

  constructor(logger:                   NGXLogger,
              eventMainActionService:   EventMainActionsService,
              dialog:                   MatDialog,
              toastr:                   ToastrService,
              public singleDataCacheService:  SingleDataCacheService,
              protected storageService:       StorageService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, eventMainActionService, dialog, environment.EVENT_MAIN_ACTIONS_MODEL_LABEL, environment.EVENT_MAIN_ACTIONS_TITLE_LABEL, storageService,
          'event-main-actions-edit', environment.EVENT_MAIN_ACTIONS_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
    this.logger.debug('EventMainActionsEditComponent.constructor()');
  }

  createData(options?) {
    this.logger.debug('EventMainActionsEditComponent.createData()');
    
    this.model = new EventMainActionModel();
    this.view = {};

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['sourceType'] || 
        !this.view['action']) {
      return false;
    }

    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['sourceType']) names.push('Origem');
    if (!this.view['action']) names.push('Ação Principal');
    return names;
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['sourceType'] = null;
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_MAIN_EVENT)){
      return false
    }

    return true;
  }

  loadFormOptionsData(){
    const metadatas: string[] = [
      "ReportSource"];

    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }
}
