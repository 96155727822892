
import { EntityModel } from './entity.model';
import { UserModel } from './user.model';
import { InspectionBooleanDescription, PointStatus } from './enums.enum';
import LanguageUtils from '../service/util/language-utils';
import FieldUtils from '../service/util/field-utils';
import DateUtils from '../service/util/date-utils';

export class InspectionPointModel extends EntityModel {
    
    identifier:                     string;
    status:                         string;
    source:                         string; 
    author:                         UserModel;
    approve:                        UserModel;
    creationDate:                   number;
    band:                           string;
    stretch:                        string;
    km:                             number;
    latLong:                        string;
    district:                       string;
    city:                           string;
    uf:                             string;
    accessArrangement:              string; 
    parallelM:                      string; 
    parallelStartKM:                string;
    parallelEndKM:                  string;
    reference:                      string;
    routeName:                      string;
    accessType:                     string;
    blockType:                      string;
    paved:                          string;
    roadCondition:                  string;
    outputNumber:                   string;
    trafficVolume:                  string;
    trafficPossibility:             string [];
    nearestPavedRoad:               string;
    distanceToPavedRoad:            string;
    residences:                     string;
    abandoned:                      string; 
    suspiciousResidenceNearby:      string;
    videoMonitoring:                string [];
    areaUnderControl:               string;
    reasonOfDoubt:                  string;
    dc:                             string;
    vulnerabilityView:              string;
    category:                       string;
    weight:                         string;
    accessCriticality:              string;
    
    constructor (){
        super();
        this.status = PointStatus.IN_ANALYSIS; 
    }
  
  static addLanguageMap(){
    LanguageUtils.languageMap['status'] = 'Status';
    LanguageUtils.languageMap['author'] = 'Criador';
    LanguageUtils.languageMap['approve'] = 'Aprovador';
    LanguageUtils.languageMap['district'] = 'Bairro';
    LanguageUtils.languageMap['accessArrangement'] = 'Disposição do Acesso';
    LanguageUtils.languageMap['parallelM'] = 'Paralelo (metros)';
    LanguageUtils.languageMap['parallelStartKM'] = 'Paralelo (do KM)';
    LanguageUtils.languageMap['parallelEndKM'] = 'Paralelo (ao KM)';
    LanguageUtils.languageMap['reference'] = 'Referência';
    LanguageUtils.languageMap['routeName'] = 'Nome da Via';
    LanguageUtils.languageMap['accessType'] = 'Tipo de Acesso';
    LanguageUtils.languageMap['blockType'] = 'Tipo de Bloqueio';
    LanguageUtils.languageMap['paved'] = 'Pavimentada';
    LanguageUtils.languageMap['roadCondition'] = 'Condição da Estrada de Terra';
    LanguageUtils.languageMap['outputNumber'] = 'Quantidade de Saídas';
    LanguageUtils.languageMap['trafficVolume'] = 'Volume de Tráfego';
    LanguageUtils.languageMap['trafficPossibility'] = 'Possibilidade de Tráfego';
    LanguageUtils.languageMap['nearestPavedRoad'] = 'Rodovia Asfaltada mais Próxima';
    LanguageUtils.languageMap['distanceToPavedRoad'] = 'Distância a essa Rodovia (metros)';
    LanguageUtils.languageMap['residences'] = 'Residências';
    LanguageUtils.languageMap['abandoned'] = 'Abandonadas';
    LanguageUtils.languageMap['suspiciousResidenceNearby'] = 'Propriedade Suspeita Próxima';
    LanguageUtils.languageMap['videoMonitoring'] = 'Vídeo Monitoramento';
    LanguageUtils.languageMap['areaUnderControl'] = 'Área sob Controle';
    LanguageUtils.languageMap['reasonOfDoubt'] = 'Motivo da Suspeita';
    LanguageUtils.languageMap['dc'] = 'DC';
    LanguageUtils.languageMap['vulnerabilityView'] = 'Vulnerabilidade na Visão do Técnico de Campo';
    LanguageUtils.languageMap['category'] = 'Categoria';
    LanguageUtils.languageMap['weight'] = 'Pesos';
    LanguageUtils.languageMap['accessCriticality'] = 'Criticidade do Acesso';
  }

  static getPopupContent(inspectionPoint: InspectionPointModel, latitude: number, longitude: number, search: boolean = false){
    return `<h5 style="text-align: center">Ponto</h5>
            ${inspectionPoint.identifier? `<div> <b>ID:</b> ${ inspectionPoint.identifier } </div>` : ''}
            <div> <b>Faixa:</b>  ${inspectionPoint.band} </div>
            <div> <b>KM:</b>  ${FieldUtils.kmIntToString(inspectionPoint.km)} </div>
            <div> <b>Vulnerabilidade:</b>  ${ inspectionPoint.vulnerabilityView? inspectionPoint.vulnerabilityView: '' } </div>
            <div> <b>Lat, Long:</b>  ${!search? FieldUtils.coordToString(latitude): ''},${!search? FieldUtils.coordToString(longitude): ''} </div>
            <div> <b>Data/Hora Criação:</b>  ${DateUtils.timestampToStringInMinutes(inspectionPoint.creationDate)} </div>
            ${inspectionPoint.dc? `<div> <b>DC:</b> ${ InspectionBooleanDescription[inspectionPoint.dc] } </div>` : ''}
          `;
  }
}
