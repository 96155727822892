import LanguageUtils from '../service/util/language-utils';
import {EntityModel} from './entity.model';
import { PlacementModel } from './placement.model';

export class CompanyModel extends EntityModel {
  cnpj:         string;
  name:         string;
  address:      string;
  phone:        string;
  website:      string;
  email:        string;
  observations: string;
  uf:           string;
  placement:    PlacementModel;
  
  static addLanguageMap(){
    LanguageUtils.languageMap['address'] = 'Endereço';
    LanguageUtils.languageMap['phone'] = 'Telefone';
    LanguageUtils.languageMap['email'] = 'E-mail';
    LanguageUtils.languageMap['website'] = 'Website';
    LanguageUtils.languageMap['cnpj'] = 'CNPJ';
    LanguageUtils.languageMap['placement'] = 'Lotação';
    LanguageUtils.languageMap['uf'] = 'UF';
  }
}
