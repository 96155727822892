import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CompanyService } from 'src/app/service/model/company.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../../../../service/authorization/authorization.service';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { CompanyModel } from 'src/app/model/company.model';
import { ESP, SORT_NAME_ASC } from 'src/app/common/constants';
import { RegistrationListComponent } from '../../registration-list-component';
import { RegistrationType } from 'src/app/model/enums.enum';
import { ToastrService } from 'ngx-toastr';
import { SAVE_DATA_PREFIX } from '../../../../common/constants';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['../../../../app.component.scss', '../../../list.component.scss']
})
export class CompanyListComponent extends RegistrationListComponent implements OnInit {

  constructor(logger:                      NGXLogger,
              companyService:              CompanyService,
              dialog:                      MatDialog,
              public authorizationService: AuthorizationService,
              protected storageService:    StorageService,
              protected changeDetector:    ChangeDetectorRef,
              protected toastr:            ToastrService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, companyService, dialog, 'companies', environment.COMPANY_GROUP_LABEL, environment.COMPANY_TITLE_LABEL,
      environment.COMPANY_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('CompanyListComponent.constructor()');
    this.subscribeToSavePlacementsData();
    this.registrationType = RegistrationType.COMPANY;
    this.sortOptions = SORT_NAME_ASC;
  }

  ngOnInit() {
    super.ngOnInit();

    this.logger.debug('CompanyListComponent.ngOnInit()');

    this.displayedColumns = ['select',
                             'name',
                             'address',
                             'phone',
                             'email',
                             'website',
                             'cnpj',
                             'uf',
                             'placement.name',
                             'observations'];

    this.loadRecordsFromServer();
  }

  public subscribeToSavePlacementsData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'placements-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   */
   protected getStringEntityForFilter(company: CompanyModel): string {
    const name = super.lowerAndTrimText(company.name);
    const address = super.lowerAndTrimText(company.address);
    const phone = super.lowerAndTrimText(company.phone);
    const email = super.lowerAndTrimText(company.email);
    const website = super.lowerAndTrimText(company.website);
    const cnpj = super.lowerAndTrimText(company.cnpj);
    const uf = super.lowerAndTrimText(company.uf);
    const placement = (company.placement) ? super.lowerAndTrimText(company.placement.name) : '';
    const observations = super.lowerAndTrimText(company.observations);
    return name + ESP + address + ESP + phone + ESP + email + ESP + website + ESP + cnpj + ESP + uf + ESP + placement + ESP + observations;
  }
}
