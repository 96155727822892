import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EventStatusDescription, EventStatus, ResultOccurrenceDescription } from 'src/app/model/enums.enum';
import { EventFilterModel } from './event.filter.model';
import { EventService } from 'src/app/service/model/event.service';

import { LayerService } from 'src/app/service/model/layer.service';
import { UserService } from 'src/app/service/model/user.service';
import { FilterComponent } from 'src/app/general/filter-component/filter-component';
import { StorageService } from 'src/app/service/storage-service';
import { SingleDataCacheService } from '../../../service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { EventModel } from 'src/app/model/event.model';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss', '../../filter.component.scss']
})
export class EventFilterComponent extends FilterComponent implements OnInit, OnDestroy  {

  @Input() inputFilterModel: EventFilterModel;

  /** ENUMS */
  resultOccurrenceDescription = ResultOccurrenceDescription;
  eventStatusDescription =  EventStatusDescription;
  eventStatus = EventStatus;
  
  constructor(protected logger:             NGXLogger,
              protected userService:        UserService,
              protected layerService:       LayerService,
              public singleDataCacheService:  SingleDataCacheService,
              public entityCacheService:    EntityCacheService,
              protected eventService:       EventService,
              protected toastr:             ToastrService,
              public filterModel:           EventFilterModel,
              protected storageService:     StorageService ) {
    super(logger, userService, layerService, singleDataCacheService, entityCacheService, eventService, filterModel, storageService);
  }

  ngOnInit(): void {
    this.filterModel = new EventFilterModel();
    this.inMemoryFilterModel = this.inputFilterModel;

    super.ngOnInit(); // A cópia do filtro será feita aqui
  }

  loadFormOptionsData() {
    this.loadAnalysts();

    const metadatas: string[] = [
      "Valve",
      "Duct",
      "CommunicationChannel",
      "ReportSource"];
    
    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  /**
   * Limpa todos os campos do filtro
   */
  clearFilter(){
    super.clearFilter();

    this.filterModel.sources     = [];
    this.filterModel.occurrences = [];
    this.filterModel.channels    = [];
    this.filterModel.startKM = undefined;
    this.filterModel.endKM = undefined;
  }

  onFilter(){
    this.loadingListService.loadingOn();
    this.loadFilteredDataModel().pipe(first()).subscribe( (entity : EventModel[]) => {
      this.filtered.emit(entity);
    }, error => {
      if(error.status == 413){
        this.toastr.warning("Sua consulta retornou mais de 2000 registros, por favor refina sua busca.");
        this.filtered.emit([]);
      }else{
      this.logger.error(error)
      }    
    },
    () => {
      this.loadingListService.loadingOff();
    });
  }
}
