import DateUtils from "src/app/service/util/date-utils";
import { Injectable } from '@angular/core';
import { SourceType } from '../../../model/enums.enum';

@Injectable({
  providedIn: 'root'
})
export class CentralFilterModel {

  current:      boolean;
  startDate:    number;
  endDate:      number;
  priorities:   string[];
  operationTypes:   string[];
  serviceTypes:   string[];
  patrolTeams:  string[];
  vehicles:     string[];
  types:        string[];
  sourceTypes:  string[];
  simfAlertTypes: string[];
  placements: string[] = []; // PlacementIds
  operationId:            string; // usado para rondas/verificações
  startScalePoint: string; // Kpoint inicial
  endScalePoint: string; // Kpoint final
  cpsNumber : number; //OPS
  startChannel : number;
  endChannel : number;

  constructor() {
    this.current = true;
    this.startDate = DateUtils.getTodayStartDate();
    this.endDate = DateUtils.getTodayEndDate();
    this.sourceTypes = [SourceType.MOBILE_APP];
  }
}
