export class ServiceResponse {
  name:    string;
  url:     string;
  version: string;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
  }
}
