<form class="edit-form h-100">
  <div class="sipd-container">
    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVA]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button 
          matTooltip="Habilitar Edição"
          [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar"
          (click)="onCopyClick()"
          [disabled]="!id"
          [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_MAIN_EVENT)'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()"
          matTooltip="Remover"
          [hidden]='!authorizationService.userHasPermission(permission.DELETE_MAIN_EVENT)'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button  [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_MAIN_EVENT)'>
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button (click)="onSaveClick($event)" [disabled]="!canSave()" matTooltip="Salvar" [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_MAIN_EVENT)'>
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
        <single-autocomplete [data]="singleDataCacheService.getValues('ReportSource')" placeholder="Origem" [(valueData)]="view['sourceType']"
          [required]="true" [disabled]="readOnly" fxFlex>
        </single-autocomplete>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pl-3 pr-2">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Ação Principal</mat-label>
          <textarea matInput name="action" matInput placeholder="Ação Principal" [(ngModel)]="view['action']" rows="10" required [readonly]="readOnly"></textarea>
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

</form>
