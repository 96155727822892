import { SimpleModel } from "src/app/model/simple.model";

/**
 * Classe utilitária para manipulação de listas
 */
export default class ListUtils{

  /**
   * Método estático que ordena listas pelo atributo name (SimpleModel)
   */
  static orderSimpleModelList(list: SimpleModel[]){
    if(!list) return;

    list.sort( (a, b) => {
      if ( a.name && b.name ) {
        return a.name.localeCompare(b.name);
      }
      else {
        if (a.name) {
          return -1;
        }
        if (b.name) {
          return 1;
        }
        return 0;
      }
    });
  }


  static removeDuplicatesFromArray(array, key) {
    return array.filter((obj, index, self) =>
        index === self.findIndex((el) => (
            el[key] === obj[key]
        ))
    )
  }

}
