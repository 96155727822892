import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { PointFilterDialogComponent } from 'src/app/pages/registrations/point/point-filter-dialog/point-filter-dialog.component';
import { PointFilterModel } from 'src/app/pages/registrations/point/point-filter-dialog/point.filter.model';
import { InspectionBooleanDescription, PointStatus, PointStatusDescription } from 'src/app/model/enums.enum';
import { InspectionModel } from 'src/app/model/inspection.model';
import { InspectionPointModel } from 'src/app/model/inspection.point.model';
import { PointService } from 'src/app/service/model/point.service';
import FieldUtils from 'src/app/service/util/field-utils';
import { MatSort } from '@angular/material/sort';
import { ESP, LARGE_PAGE_SIZE } from '../../../common/constants';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-import-points-dialog',
  templateUrl: './import-points-dialog.component.html',
  styleUrls: ['./import-points-dialog.component.scss']
})
export class ImportPointsDialogComponent implements OnInit {

  displayedColumns: string[];

  inspectionPoints: InspectionPointModel[];

  dataSource: MatTableDataSource<InspectionPointModel>= new MatTableDataSource();

  selection = new SelectionModel<InspectionPointModel>(true, []);

  pointStatusDescription = PointStatusDescription;
  inspectionBooleanDescription =  InspectionBooleanDescription;
  kmIntToString = FieldUtils.kmIntToString;

  filterModel: PointFilterModel;

  @ViewChild(MatInput, { static: true }) searchInput: MatInput;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** The MatPaginator bound to the grid to show data */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // pagination
  initialPageSize = environment.useServerPagination? environment.defaultPageSize: LARGE_PAGE_SIZE;
  pageSize = environment.defaultPageSize;
  pageLength = undefined;
  sortOptions = 'identifier,ASC';

  constructor(public dialogRef: MatDialogRef<ImportPointsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public inspections: InspectionModel[],
              protected dialog: MatDialog,
              private logger:       NGXLogger,
              private pointService: PointService,
              private toastr:       ToastrService) {
    this.filterModel = new PointFilterModel();
  }

  ngOnInit(): void {
    this.displayedColumns = ['select',
                                            'identifier',
                                            'status',
                                            'source',
                                            'creationDate',
                                            'band',
                                            'stretch',
                                            'km',
                                            'dc',
                                            'accessType',
                                            'category',
                                            'vulnerabilityView',
                                            'city',
                                            'uf',
                                            'reference'];

    this.filterModel.status = [PointStatus.APPROVED]; // Só os pontos aprovados podem ser importados para a operação
    this.sort.sort({id: "identifier", start: 'asc', disableClear: false});

    this.loadRecordsFromServer(0);
  }

  loadRecordsFromServer(pageIndex: number){
    this.pointService.loadFilteredRawListFromRestApi(this.paginator? pageIndex: null, this.paginator? (pageIndex==0? this.initialPageSize: this.pageSize): null, this.sortOptions, this.filterModel).pipe(first()).subscribe((result) => {
      this.inspectionPoints = this.handleRestApiResult(result);
      this.checkImportedByOperation();
      this.buildDataSource();
    },
    error => {
      this.toastr.error("Erro ao carregar pontos","Erro");
      this.logger.error("Erro ao carregar pontos ", error);
    });
  }

  buildDataSource(){
    this.dataSource.data = this.inspectionPoints;

    this.dataSource.filterPredicate = (entity: InspectionPointModel, filter: string): boolean => {
      return this.getStringEntityForFilter(entity).indexOf(filter) != -1;
    };

    this.dataSource.sort = this.sort;

    if (!environment.useServerPagination){
      this.dataSource.paginator = this.paginator;
    }
    
    this.selection.clear();
  }

  handleRestApiResult(result): InspectionPointModel[]{
    // checa se retorno eh um objeto pair
    if (result) {
      if ( result['first'] || result['second'] ){
          this.pageLength = result['first'];
          return result['second'];
      } else {
          return result;
      }
    }
  }

  protected lowerAndTrimText(text: string) {
    return (text) ? text.trim().toLowerCase() : '';
  }

  formatDate(date: number){
    if (!date || date == 0)
      return "";
    const startMoment = moment(date);
    return startMoment.format('DD/MM/YYYY HH:mm');
  }

  protected getStringEntityForFilter(point: InspectionPointModel): string {
    const identifier = this.lowerAndTrimText(point.identifier);
    const status = point.status ? this.lowerAndTrimText(this.pointStatusDescription[point.status]) : '';
    const source = this.lowerAndTrimText(point.source);
    const creationDate = this.formatDate(point.creationDate);
    const band = this.lowerAndTrimText(point.band);
    const stretch = this.lowerAndTrimText(point.stretch);
    const km = FieldUtils.kmIntToString(point.km);
    const dc = point.dc ? this.lowerAndTrimText(this.inspectionBooleanDescription[point.dc]): '';
    const accessType = this.lowerAndTrimText(point.accessType);
    const category = this.lowerAndTrimText(point.category);
    const vulnerabilityView = this.lowerAndTrimText(point.vulnerabilityView);
    const city = this.lowerAndTrimText(point.city);
    const uf = this.lowerAndTrimText(point.uf);
    const reference = this.lowerAndTrimText(point.reference);

    return identifier + ESP + status + ESP + source + ESP + creationDate + ESP + band + ESP + stretch + ESP + km + ESP + dc + ESP + accessType + ESP + category + ESP + vulnerabilityView + ESP + city + ESP + uf + ESP + reference;
  }

  sortSelectedPoints() {
    this.selection.selected.sort((a, b) => {
      let s1, s2: string;
      switch (this.dataSource.sort.active) {
        case 'km': {
          s1 = this.kmIntToString(a[this.dataSource.sort.active]);
          s2 = this.kmIntToString(b[this.dataSource.sort.active]);
          break;
        }
        case 'creationDate': {
          s1 = this.formatDate(a[this.dataSource.sort.active]);
          s2 = this.formatDate(b[this.dataSource.sort.active]);
          break;
        }
        default: {
          s1 = a[this.dataSource.sort.active];
          s2 = b[this.dataSource.sort.active];
        }
      }
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();
      return this.dataSource.sort.direction === 'asc' ? s1.localeCompare(s2) : s2.localeCompare(s1);
      });
  }

  onImportClick(){
    if (this.dataSource.sort.direction != '') {
      this.sortSelectedPoints();
    }
    this.dialogRef.close(this.selection.selected);
  }

  masterToggle(){
    if(this.isAllSelected())
      this.selection.deselect(...this.getPageData());
    else
      this.selection.select(...this.getPageData());
  }

  isAllSelected() {
    //Chamada diretamente no html, datasource pode não estar inicializado ainda.
    if(!this.dataSource) return false; 
    
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  checkboxTip(row?: InspectionPointModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Desmarcar' : 'Selecionar'} Todos`;
    }
    return `${this.selection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Ponto`;
  }

  onCheckboxClick(row) {
    this.selection.toggle(row);
  }

  applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.selection.clear();
  }

  onRemoveSearch(){
    this.searchInput.value = '';
    this.dataSource.filter = '';
    this.selection.clear();
  }

  onPageChanged(event){
    if (environment.useServerPagination) {
      const pageIndex = event.pageIndex;
      this.loadRecordsFromServer(pageIndex.toString());
    }
  }

  openFilterDialog(){
    const dialogRef = this.dialog.open(PointFilterDialogComponent, {
      width: '800px',
      data: this.filterModel,
      panelClass: 'sipd-modal'
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.loadRecordsFromServer(0);
        this.selection.clear();
      }
    });
  }

  protected checkImportedByOperation() {
    this.inspections.forEach((inspection: InspectionModel) => {
      let ip: any = this.inspectionPoints.find(inspectionPoint => (inspection.inspectionPointId === inspectionPoint.identifier));
      if (ip) {
          ip.importedByOperation = true;
      }
    });
  }
}
