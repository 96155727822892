<div class="sipd-container">
  <div class="sipd-header">
    <div class="mat-title m-0">{{title}}</div><small matTooltip="Número de Registros Lidos">[ {{getListCount()}} ]</small>
    <div class="fill-space"></div>
    <div class="actions">
      <mat-form-field class="list-filter header-input" appearance="outline">
        <mat-icon matPrefix class="material-icons-outlined">search</mat-icon>
        <input matInput type="search" (keyup)="applySearch($event.target.value)" placeholder="Buscar...">
        <button mat-icon-button matSuffix (click)="onRemoveSearch()"><mat-icon class="material-icons-outlined">close</mat-icon></button>
      </mat-form-field>

      <button mat-icon-button (click)="onRefreshClick()" matTooltip="Atualizar Lista">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
      <button mat-icon-button (click)="onCreateClick()"
        [hidden]="!authorizationService.userHasPermission(permission.CREATE_EDIT_USER)"
        matTooltip="Adicionar Usuário">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Abrir Usuário(s) Selecionado(s)"
        [disabled]="this.selection.selected.length == 0"
        (click)="onEditManyClick(this.selection.selected)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar Usuário Selecionado
                    (Somente 1 Usuário pode estar selecionado)"
        [disabled]="this.selection.selected.length != 1 || !canCopy(this.selection.selected[0])"
        (click)="onCopyClick(selection.selected[0])"
        [hidden]="!authorizationService.userHasPermission(permission.CREATE_EDIT_USER)">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Enviar Senha por E-mail
                    (Somente 1 Usuário pode estar selecionado)"
        [disabled]="this.selection.selected.length != 1 || isSendPasswordButtonDisabledForUser(selection.selected[0])"
        [hidden]='isSendPasswordButtonHiddenForUser()'
        (click)="onPasswordSendClick(this.selection.selected.pop())">
        <mat-icon class="material-icons-outlined">email</mat-icon>
      </button>
      <button mat-icon-button color="primary" 
        (click)="onDeleteManyClick(this.selection.selected)" 
        matTooltip="Remover Usuários Selecionados"
        [disabled]="this.selection.selected.length == 0"
        [hidden]="!authorizationService.userHasPermission(permission.DELETE_USER)">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Mostrar Rastro no Mapa dos Usuários Selecionados"
        [disabled]="this.selection.selected.length == 0"
        (click)="onHistoricalTrackingManyClick()">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="sipd-content bg-white">

    <table mat-table [dataSource]="dataSource" matSort class="-table-hover w-100">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell class="txt-center fit-content" *matHeaderCellDef >
          <mat-checkbox (change)="$event ? masterToggle() : null" color="accent"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [matTooltip]="checkboxTip()">
          </mat-checkbox>
        </th>
        <td mat-cell class="txt-center fit-content" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="accent"
            (change)="$event ? onCheckboxClick(row) : null" [checked]="selection.isSelected(row)"
            [matTooltip]="checkboxTip(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{element?.name}} </td>
      </ng-container>

      <ng-container matColumnDef="originPlacement">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Lotação de Origem </th>
        <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.originPlacement?.name">{{element?.originPlacement?.name}}</td>
      </ng-container>

      <ng-container matColumnDef="company.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
        <td mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.company?.name">{{element?.company?.name}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> E-mail </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{element?.email}}</td>
      </ng-container>

      <ng-container matColumnDef="login">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> Chave / Login </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{element?.login}} </td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> CPF </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{element?.cpf}}</td>
      </ng-container>

      <ng-container matColumnDef="profileId">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> Perfil </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{ getProfileName(element?.profileId) }}</td>
      </ng-container>

      <ng-container matColumnDef="mobilePhone">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> Celular </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{element?.mobilePhone}}</td>
      </ng-container>

      <ng-container matColumnDef="appAccess">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> Acesso ao App </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{ getAppAccess(element)? 'Sim': 'Não' }}</td>
      </ng-container>

      <ng-container matColumnDef="patrolTeamId">
        <th mat-header-cell class="fit-content" *matHeaderCellDef mat-sort-header> Equipe </th>
        <td mat-cell class="fit-content" *matCellDef="let element"> {{ getTeam(element?.patrolTeamId) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"
        (contextmenu)="openContextMenu($event, element)"
        [ngClass]="{ 'highlighted': selection.isSelected(row)}">
      </tr>
    </table>


    <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>

    <mat-menu #contextMenu="matMenu">
      <button mat-menu-item (click)="onEditClick(this.contextMenuSelectedItem, $event)">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        <span>Abrir Usuário</span>
      </button>

      <button mat-menu-item [hidden]="!authorizationService.userHasPermission(permission.CREATE_EDIT_USER)" (click)="onCopyClick(this.contextMenuSelectedItem)"
        [disabled]="!canCopy(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">file_copy</mat-icon><span>Copiar Usuário</span>
      </button>

      <button mat-menu-item [hidden]="!authorizationService.userHasPermission(permission.DELETE_USER)"
        (click)="onDeleteClick(this.contextMenuSelectedItem, this.contextMenuSelectedItem.name, $event)"
        [disabled]="!canDelete(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">delete</mat-icon> <span>Remover Usuário</span>
      </button>
      
      <button mat-menu-item color="primary" (click)="onHistoricalTrackingClick(this.contextMenuSelectedItem)">
        <mat-icon class="material-icons-outlined">my_location</mat-icon><span>Mostrar Rastro no Mapa</span>
      </button>

      <button mat-menu-item (click)="onPasswordSendClick(this.contextMenuSelectedItem)"  [disabled]='isSendPasswordButtonDisabledForUser(this.contextMenuSelectedItem)'
        [hidden]='isSendPasswordButtonHiddenForUser()'
        matTooltip="Enviar Senha por E-mail">
        <mat-icon class="material-icons-outlined">email</mat-icon> <span>Enviar Senha por E-mail</span>
      </button>
    </mat-menu>

  </div>
  <mat-divider></mat-divider>
  <mat-paginator #pagination [pageSize]="pageSize" [length]="pageLength" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
