import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { EntityModel } from 'src/app/model/entity.model';
import { EventFilterModel } from 'src/app/pages/event/event-filter/event.filter.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { API_VERSION_ENDPOINT } from '../../common/constants';
import { ArchiveService } from './archive.service';
import { ArchivedType } from 'src/app/general/filter-component/filter.model';
import { EventModel } from '../../model/event.model';
import { AttachmentModel } from '../../model/attachment.model';
import { first, map } from 'rxjs/operators';

export const OBJECT_NAME = 'eventObject';
@Injectable({
  providedIn: 'root'
})
export class EventService extends ArchiveService {

  constructor(logger: NGXLogger,
              httpClient: HttpClient
              ) {
    super(logger, httpClient, `${environment.settings.event_verifications_address}/events`);

  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: EventFilterModel ): Observable<T> {

    let params: HttpParams = new HttpParams();

    if (filter){
      if (filter.startDate){
        const startDateTime = moment(filter.startDate).format('x');
        params = params.set('start', startDateTime);
      }

      if (filter.endDate){
        const endDateTime = moment(filter.endDate).format('x');
        params = params.set('end', endDateTime);
      }

      if (filter.status){
        filter.status.forEach( status => {
          params = params.append('status', status);
        });
      }

      if (filter.occurrences){
        filter.occurrences.forEach( occurrence => {
          params = params.append('occurrences', occurrence);
        });
      }

      if (filter.analysts){
        filter.analysts.forEach( analystId => {
          params = params.append('analysts', analystId);
        });
      }

      if (filter.channels){
        filter.channels.forEach( channel => {
          params = params.append('communicationChannels', channel);
        });
      }

      if (filter.sources){
        filter.sources.forEach( source => {
          params = params.append('sources', source);
        });
      }

      if (filter.ducts){
        filter.ducts.forEach( duct => {
          params = params.append('ducts', duct);
        });
      }

      if (filter.valves){
        filter.valves.forEach( valve => {
          params = params.append('valves', valve);
        });
      }

      if (filter.startKM){
        params = params.append('startKM', filter.startKM);
      }

      if (filter.endKM){
        params = params.append('endKM', filter.endKM);
      }

      if (filter.archived != ArchivedType.ALL){
        params = params.append('archived', (filter.archived == ArchivedType.ARCHIVED) ? String(true) : String(false));
      }

      if (filter.associatedEventId ){
        params = params.append('associatedEventId', filter.associatedEventId);
      }

      if (filter.filterByAssociatedEvent ){
        params = params.append('filterByAssociatedEvent', filter.filterByAssociatedEvent);
      }

      if (filter.identifier ){
        params = params.append('identifier', filter.identifier);
      }
      
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  public exportEvent(event: EventModel): Observable<any> {
    return this.http.get(`${this.apiUrl}/${event.id}/data`, { responseType: 'blob' });
  }

  /**
   * Obtem a versão do projeto Event-Verification
   */
  public getServiceVersion(): Observable<any> {
    return this.http.get(`${this.apiUrl}/${API_VERSION_ENDPOINT}`,
      { responseType: 'text' }
    );
  }

  getFormElements() {
    const formElements = new Map<string, object>();
    return formElements;
  }

  private updateRecord(event: EventModel) {
    const formElements = this.getFormElements();
    return this.edit(event, formElements);
  }

  public changeAssociatedEvent(events: EventModel[], associatedEventId: string, associatedIdentifier: string){   
    const observables: Observable<Object>[] = [];
    events.forEach( (event: EventModel) => {
      event.associatedEventId = associatedEventId;
      event.associatedIdentifier = associatedIdentifier;
      observables.push(this.updateRecord(event));
    });
    return forkJoin(observables);
  }

  public create(record: EntityModel, formElements: Map<string, object>) {
    this.logger.debug('EventService.create()');
    const formData: FormData = super.buildFormData( OBJECT_NAME , record, formElements);
    return super.postAsFormData(this.apiUrl, formData);
  }

  public edit(record: EntityModel, formElements: Map<string, object>) {
    this.logger.debug('EventService.edit()');
    const formData: FormData = super.buildFormData( OBJECT_NAME , record, formElements);
    return super.putAsFormData(this.apiUrl + `/${record.id}`, formData);
  }
  
  public loadAttachmentFile(data : AttachmentModel) : Observable<any>{    
    let baseUrl = this.apiUrl+"/attachment-file";    
    return this.http.get(`${baseUrl}/${data.id}`, { responseType: 'blob' }).pipe(first(), map((blob) => {
      return this.blobToFile(blob, data.name);
    }));
  }

  private blobToFile = (blob: Blob, fileName:string): File => {
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(blob);
    let file = new File(arrayOfBlob, fileName);
    return file;
  }

  public editRecord<T extends EntityModel> (record: EntityModel): Observable<T> {
    const formData: FormData = super.buildFormData(OBJECT_NAME, record, new Map<string, object>());
    return super.putAsFormData(this.apiUrl + '/' + record.id, formData);
  }
}
