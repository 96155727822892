import { Injectable } from "@angular/core";
import { OperationFilterModel } from "src/app/general/filter-component/operation.filter.model";

@Injectable({
  providedIn: 'root'
})
export class VerificationFilterModel extends OperationFilterModel{
  
  // Utilizado para carregar as verificações de um determinado Evento
  // Não é utilizado na interface do filtro de verificações
  eventIds: string[];
}
