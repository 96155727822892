import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyEditComponent } from './company/company-edit/company-edit.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { PlacementEditComponent } from './placement/placement-edit/placement-edit.component';
import { PlacementListComponent } from './placement/placement-list/placement-list.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { VehicleEditComponent } from './vehicle/vehicle-edit/vehicle-edit.component';
import { VehicleListComponent } from './vehicle/vehicle-list/vehicle-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatrolTeamEditComponent } from './patrolTeam/patrol-team-edit/patrol-team-edit.component';
import { PatrolTeamListComponent } from './patrolTeam/patrol-team-list/patrol-team-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { EventMainActionsListComponent } from './event-main-actions/event-main-actions-list/event-main-actions-list.component';
import { EventMainActionsEditComponent } from './event-main-actions/event-main-actions-edit/event-main-actions-edit.component';
import { GeneralModule } from 'src/app/general/general.module';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PointListComponent } from './point/point-list/point-list.component';
import { PointEditComponent } from './point/point-edit/point-edit.component';
import { PointFilterDialogComponent } from './point/point-filter-dialog/point-filter-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormEditComponent } from './form/form-edit/form-edit.component';
import { FormListComponent } from './form/form-list/form-list.component';
import { FormFilterDialogComponent } from './form/form-filter-dialog/form-filter-dialog.component';
import { QuestionFormComponent } from './form/question-form/question-form.component';
import {MatTreeModule} from '@angular/material/tree';
import { MatRadioModule } from '@angular/material/radio';
import { ObservedAreaListComponent } from './observed-area/observed-area-list/observed-area-list.component';
import { ObservedAreaEditComponent } from './observed-area/observed-area-edit/observed-area-edit.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { ObservedAreaFilterComponent } from './observed-area/observed-area-filter/observed-area-filter.component';
import { MatChipsModule } from '@angular/material/chips';
import { ProfileListComponent } from './profile/profile-list/profile-list.component' ;
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CompanyEditComponent,
    CompanyListComponent,
    PatrolTeamEditComponent,
    PatrolTeamListComponent,
    PlacementEditComponent,
    PlacementListComponent,
    UserEditComponent,
    UserListComponent,
    VehicleEditComponent,
    VehicleListComponent,
    EventMainActionsListComponent,
    EventMainActionsEditComponent,
    PointListComponent,
    PointEditComponent,
    PointFilterDialogComponent,
    FormEditComponent,
    FormListComponent,
    FormFilterDialogComponent,
    QuestionFormComponent,
    ObservedAreaListComponent,
    ObservedAreaEditComponent,
    ObservedAreaFilterComponent,
    ProfileListComponent,
    ProfileEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatTreeModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    MatDatepickerModule,
    MatRadioModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatExpansionModule,
    MatChipsModule,
    MatAutocompleteModule,
    GeneralModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ]
})
export class RegistrationsModule { }
