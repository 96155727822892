import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PatrolTeamModel } from '../../model/patrolteam.model';
import { VehicleModel } from '../../model/vehicle.model';
import { UserModel } from '../../model/user.model';
import { CompanyModel } from '../../model/company.model';
import { OperationModel } from '../../model/operation.model';
import { PatrolModel } from '../../model/patrol.model';
import { InspectionModel } from '../../model/inspection.model';
import { LocationModel } from '../../model/location.model';
import { PlacementModel } from 'src/app/model/placement.model';
import { EventModel } from 'src/app/model/event.model';
import {
  EventStatus,
  SourceType
} from 'src/app/model/enums.enum';
import { VerificationModel } from 'src/app/model/verification.model';
import { EventMainActionModel } from 'src/app/model/event.main.action.model';
import { ResultsModel } from 'src/app/model/results.model';
import { SignalModel } from 'src/app/model/signal.model';

@Injectable({
  providedIn: 'root'
})
export class TestDataGeneratorService {

  /**
   * Retona um exemplo de alerta, em formato JSON, de acordo com o tipo especificado.
   * O formato do JSON foi simplificado para só conter os dados efetivamente usados pela central de alertas.
   *
   * @param alertType tipo de alerta (numérico).
   */
  public getSampleAlertInJson(alertType: number) : string {
    switch (alertType) {
      case 1: return "{" + // Desvio de rota - Prioridade MÉDIA (2)
          "\"objectId\" : \"1eee\","+
          "\"timestamp\" : " + moment().valueOf() + ","+
          "\"latitude\" : -22.9111206,"+
          "\"longitude\" : -43.1062025,"+
          "\"sourceType\" : \"MOBILE_APP\","+
          "\"operationId\" : \"5ed91c29b15bfa00015bd0a2\","+
          "\"operationType\" : \"PATROL\","+
          "\"patrolTeam\" : {"+
          "\"name\" : \"Equipe Julio\","+
          "   \"vehicle\" : {"+
          "       \"plate\" : \"QAM-2220\""+
          "   },"+
          "   \"shift\" : \"MORNING\","+
          "  \"users\" : [ "+
          "      {"+
          "           \"login\" : \"juliock\","+
          "           \"name\" : \"Julio Cesar\","+
          "           \"email\" : \"juliock@gmail.com\","+
          "            \"userType\" : \"PROFESSIONAL\""+
          "       }, "+
          "       {"+
          "           \"name\" : \"Jose Antonio\""+
          "       }"+
          "   ],"+
          "   \"company\" : {"+
          "       \"name\" : \"GPS\","+
          "       \"phone\" : \"21 2524-3454\""+
          "   },"+
          "   \"patrolCoordinator\" : {"+
          "       \"name\" : \"ALBERTO PINTO DE MENDONCA\""+
          "   },"+
          "   \"placement\" : {"+
          "       \"name\" : \"lotação\","+
          "       \"gs\" : \"SSPCO\""+
          "   },"+
          "   \"state\" : \"RJ\""+
          "},"+
          "\"alertType\" : \"ROUTE_DEVIATION\","+
          "\"description\" : \"Desvio de rota detectado\","+
          "\"alertPriority\" : \"MEDIUM\""+
      "}";
      case 2: return "{"+ // Comunicação normal de ronda - Prioridade BAIXA (3)
          "\"objectId\" : \"2eee\","+
          "\"timestamp\" : "+ moment().valueOf() + ","+
          "\"latitude\" :  -22.9109491,"+
          "\"longitude\" : -43.1060948,"+
          "\"sourceType\" : \"MOBILE_APP\","+
          "\"operationId\" : \"5ed6563bb15bfa00015bd060\","+
          "\"operationType\" : \"PATROL\","+
          "\"patrolTeam\" : {"+
          "   \"name\" : \"equipe julio vigilante\","+
          "   \"vehicle\" : {"+
          "       \"plate\" : \"QQL-6979\""+
          "   },"+
          "   \"shift\" : \"NIGHT\","+
          "   \"users\" : [ "+
          "       {"+
          "           \"name\" : \"julio vigilante\""+
          "       }, "+
          "       null"+
          "   ],"+
          "   \"company\" : {"+
          "       \"name\" : \"GPS-ENGESEG\","+
          "       \"phone\" : \"12 2139-2900\""+
          "   },"+
          "   \"patrolCoordinator\" : {"+
          "       \"name\" : \"ALBERTO PINTO DE MENDONCA\""+
          "   },"+
          "   \"placement\" : {"+
          "       \"name\" : \"lotação\","+
          "       \"gs\" : \"SSPCO\""+
          "   },"+
          "   \"state\" : \"RJ\""+
          "},"+
          "\"alertType\" : \"ATTENTION_PATROL_CHECK\","+
          "\"description\" : \"Mensagem Ronda\","+
          "\"alertPriority\" : \"LOW\""+
      "}";
      case 3: return "{"+ // Comunicação prioritária de ronda - Prioridade ALTA (1)
        "\"objectId\" : \"2eee\","+
        "\"timestamp\" : " + moment().valueOf() + ","+
        "\"latitude\" : -22.9568701010899,"+
        "\"longitude\" : -43.1980871491438,"+
        "\"sourceType\" : \"MOBILE_APP\","+
        "\"operationId\" : \"5ed8f7ffb15bfa00015bd09c\","+
        "\"operationType\" : \"PATROL\","+
        "\"patrolTeam\" : {"+
          "   \"name\" : \"equipe julio vigilante\","+
          "   \"vehicle\" : {"+
          "       \"plate\" : \"QQL-6979\""+
          "   },"+
          "   \"shift\" : \"NIGHT\","+
          "   \"users\" : [ "+
          "       {"+
          "           \"name\" : \"julio vigilante\""+
          "       }, "+
          "       null"+
          "   ],"+
          "   \"company\" : {"+
          "       \"name\" : \"GPS-ENGESEG\","+
          "       \"phone\" : \"12 2139-2900\""+
          "   },"+
          "   \"patrolCoordinator\" : {"+
          "       \"name\" : \"ALBERTO PINTO DE MENDONCA\""+
          "   },"+
          "   \"placement\" : {"+
          "       \"name\" : \"lotação\","+
          "       \"gs\" : \"SSPCO\""+
          "   },"+
          "   \"state\" : \"RJ\""+
          "},"+
        "\"alertType\" : \"ATTENTION_PATROL_CHECK\","+
        "\"description\" : \"Mensagem Ronda\","+
        "\"alertPriority\" : \"HIGH\""+
      "}";
      default: return "{"+// Pânico - Prioridade MÁXIMA (0)
        "\"objectId\" : \"2eee\","+
        "\"timestamp\" : " + moment().valueOf() +","+
        "\"latitude\" : -22.938677,"+
        "\"longitude\" : -43.1934483,"+
        "\"sourceType\" : \"MOBILE_APP\","+
        "\"operationId\" : \"5ed7eea1b15bfa00015bd090\","+
        "\"operationType\" : \"PATROL\","+
        "\"patrolTeam\" : {"+
          "   \"name\" : \"equipe julio vigilante\","+
          "   \"vehicle\" : {"+
          "       \"plate\" : \"QQL-6979\""+
          "   },"+
          "   \"shift\" : \"NIGHT\","+
          "   \"users\" : [ "+
          "       {"+
          "           \"name\" : \"julio vigilante\""+
          "       }, "+
          "       null"+
          "   ],"+
          "   \"company\" : {"+
          "       \"name\" : \"GPS-ENGESEG\","+
          "       \"phone\" : \"12 2139-2900\""+
          "   },"+
          "   \"patrolCoordinator\" : {"+
          "       \"name\" : \"ALBERTO PINTO DE MENDONCA\""+
          "   },"+
          "   \"placement\" : {"+
          "       \"name\" : \"lotação\","+
          "       \"gs\" : \"SSPCO\""+
          "   },"+
          "   \"state\" : \"RJ\""+
          "},"+
        "\"alertType\" : \"PANIC\","+
        "\"description\" : \"Mensagem Ronda\","+
        "\"alertPriority\" : \"HIGH\""+
        "}";
    }
  }

  public getSampleOperation(): OperationModel {
    const patrol = new PatrolModel();
    patrol.patrolTeam = this.getSamplePatrolTeam();        
    patrol.author = this.getSampleUser(3);
    patrol["operationType"] = "PATROL";
    const patrolStart = moment("2020-05-20 10:30:26.555")
    patrol.startDate = patrolStart.valueOf();
    patrol.id = "5ece8646e7e790000145e351";
    patrol.status = "SENT";
    patrol.inspections = [this.getSampleInspection(1), this.getSampleInspection(2), this.getSampleInspection(3)];
    return patrol;
  }

  getSampleLocationModel(locationIndex: number): LocationModel {
    switch (locationIndex) {
      case 1: {
        const location = new LocationModel();
        location.latitude = "-22.9565543";
        location.longitude = "-43.1963033";
        return location;
      }
      case 2: {
        const location = new LocationModel();
        location.latitude = "-22.9573375";
        location.longitude = "-43.1963606";
        return location;
      }
      case 3: {
        const location = new LocationModel();
        location.latitude = "-22.956821";
        location.longitude = "-43.1977125";
        return location;
      }
    }
  }

  getSampleInspection(inspectionIndex: number): InspectionModel {
    switch (inspectionIndex) {
      case 1: {
        const inspection = new InspectionModel();
        inspection.identifier = "0_5ece8646e7e790000145e351";
        inspection.name = "Ponto 1 - Clínica";
        inspection.location = this.getSampleLocationModel(1);
        inspection.activities = ["Tirar foto"];
        inspection.type = "CRITICAL_POINT";
        return inspection;
      }
      case 2: {
        const inspection = new InspectionModel();
        inspection.identifier = "1_5ece8646e7e790000145e351";
        inspection.name = "Ponto 2 - Fuska Bar";
        inspection.location = this.getSampleLocationModel(2);
        inspection.activities = ["Tirar foto", "Gravar áudio"];
        inspection.type = "CRITICAL_POINT";
        return inspection;
      }
      case 3: {
        const inspection = new InspectionModel();
        inspection.identifier = "2_5ece8646e7e790000145e351";
        inspection.name = "Base";
        inspection.location = this.getSampleLocationModel(3);
        inspection.type = "BASE";
        return inspection;
      }
    }
  }

  public getSampleVehicle(): VehicleModel {
    const vehicle = new VehicleModel();
    vehicle.plate = "QAO-2028";
    vehicle.model = "Amarok";
    vehicle.company = this.getSampleCompany();
    return vehicle;
  }

  public getSampleUser(userIndex: number): UserModel {
    switch (userIndex) {
      case 1: {
        const user1 = new UserModel();
        user1.login = "marcos";
        user1.name = "Marcos Antonio Caldas";
        user1.cpf = "000.000.000-00";
        user1.mobilePhone = "21 99696-3704";
        user1.email = "marcos@gmail.com";        
        user1.patrolTeamId = "5ec2f72b9b44b70001588e4b";
        return user1;
      }
      case 2: {
        const user2 = new UserModel();
        user2.login = "mariaf";
        user2.name = "Maria Fernanda Pires";
        user2.cpf = "000.000.000-00";
        user2.mobilePhone = "21 99343-5334";
        user2.email = "mariafernanda@email.com";        
        user2.patrolTeamId = "5ec2f72b9b44b70001588e4b";
        return user2;
      }
      case 3: {
        const user2 = new UserModel();
        user2.login = "UV60";
        user2.name = "EDSON CARDOSO";
        user2.cpf = "000.000.000-00";
        user2.mobilePhone = "21 97502-0000";
        user2.email = "edson@transpetro.com.br";        
        return user2;
      }
    }
  }

  public getSamplePatrolTeam(): PatrolTeamModel {
    const team = new PatrolTeamModel();
    team.name = "Team Leonardo";
    team.vehicle = this.getSampleVehicle();
    team.shift = "NIGHT";
    team.users = [this.getSampleUser(1), this.getSampleUser(2)];
    team.patrolCoordinator = this.getSampleUser(3);
    return team;
  }

  public getSamplePlacement(): PlacementModel {
    const placement = new PlacementModel()
    placement.regional = "OSBSUL";
    placement.gs = "RJMGESBA"
    placement.name = placement.regional + ' / ' + placement.gs;
    return placement;
  }

  public getSampleCompany(): CompanyModel {
    const company = new CompanyModel();
    company.cnpj = "00.000.000/0000-00";
    company.name = "GPS";
    company.address = "Rua Sá Freire, 70 - São Cristóvão, Rio de Janeiro - RJ";
    company.phone = "21 2524-0000";
    company.email = "gps@gmail.com";
    return company;
  }

    /**
     * Retorna um exemplo de sinal em JSON. De acordo com o parâmetro fornecido, retorna modelos
     * diferentes de sinais.
     *
     * @param signalType inteiro referenciando o tipo de sinal desejado.
     */
    public getSampleSignalInJson(signalType: number) : string {
      switch (signalType) {
          case 1: return "{"+ // Sem ronda
              "\"mobileObjectId\": \"123570dc9f60a77c\","+
              "\"timestamp\": " + moment().valueOf() +","+
              "\"latitude\": -22.9564035,"+
              "\"longitude\": -43.1968648,"+
              "\"sourceType\": \"MOBILE_APP\","+
              "\"operationId\": null,"+
              "\"operationType\": null,"+
              "\"teamId\": \"5ec2f72b9b44b70001588e4b\""+
          "}";
          case 2: return "{"+ // Sem ronda, mesmo mobileId, outra equipe e outra posição
              "\"mobileObjectId\": \"123570dc9f60a77c\","+
              "\"timestamp\": " + moment().valueOf() +","+
              "\"latitude\": -22.9566035,"+
              "\"longitude\": -43.1970648,"+
              "\"sourceType\": \"MOBILE_APP\","+
              "\"operationId\": null,"+
              "\"operationType\": null,"+
              "\"teamId\": \"5e458d2262332541c7ebc8da\""+
          "}";
          case 3: return "{"+ // Com ronda
              "\"mobileObjectId\": \"123570dc9f60a77c\","+
              "\"timestamp\": " + moment().valueOf() +","+
              "\"latitude\": -22.9564035,"+
              "\"longitude\": -43.1968648,"+
              "\"sourceType\": \"MOBILE_APP\","+
              "\"operationId\": \"5ecc5288e7e790000145e318\","+
              "\"operationType\": \"PATROL\","+
              "\"teamId\": \"5ec2f72b9b44b70001588e4b\""+
          "}";
          case 4: return "{"+ // Com verificação
              "\"mobileObjectId\": \"123570dc9f60a77c\","+
              "\"timestamp\": " + moment().valueOf() +","+
              "\"latitude\": -22.9564035,"+
              "\"longitude\": -43.1968648,"+
              "\"sourceType\": \"MOBILE_APP\","+
              "\"operationId\": \"5ecc59ab3cc6920001385b74\","+
              "\"operationType\": \"EVENT_VERIFICATION\","+
              "\"teamId\": \"5ec2f72b9b44b70001588e4b\""+
          "}";
          default: return "{"+ // Sem ronda, um pouco deslocado
              "\"mobileObjectId\": \"123570dc9f60a77c\","+
              "\"timestamp\": " + moment().valueOf() +","+
              "\"latitude\": -22.9565035,"+
              "\"longitude\": -43.1969648,"+
              "\"sourceType\": \"MOBILE_APP\","+
              "\"operationId\": null,"+
              "\"operationType\": null,"+
              "\"teamId\": \"5ec2f72b9b44b70001588e4b\""+
              "}";
        }
    }

    /**
     * Carga inicial de dados históricos - O dado mais recente deve estar no início e o mais antigo no fim
     */
    public getSampleHistoricalTestData() {
      var now = moment();

      var posNow = new SignalModel();
      posNow.timestamp = now.valueOf();
      posNow.latitude = -22.95662869;
      posNow.longitude = -43.1973716;
      posNow.mobileObjectId = "12e570dc9f60a77c";
      posNow.operationId = "5ec6984225c93d6e6ca2bd6b",
      posNow.operationType = "PATROL";
      posNow.sourceType = "MOBILE_APP";
      posNow.teamId = "5ec2f72b9b44b70001588e4b";

      var pos1minAgo = new SignalModel();
      pos1minAgo.timestamp = now.subtract(1, "minute").valueOf();
      pos1minAgo.latitude = -22.95663869;
      pos1minAgo.longitude = -43.1974716;
      pos1minAgo.mobileObjectId = "12e570dc9f60a77c";
      pos1minAgo.operationId = "5ec6984225c93d6e6ca2bd6b",
      pos1minAgo.operationType = "PATROL";
      pos1minAgo.sourceType = "MOBILE_APP";
      pos1minAgo.teamId = "5ec2f72b9b44b70001588e4b";

      var pos2minAgo = new SignalModel();
      pos2minAgo.timestamp = now.subtract(1, "minute").valueOf();
      pos2minAgo.latitude = -22.95664869;
      pos2minAgo.longitude = -43.1975716;
      pos2minAgo.mobileObjectId = "12e570dc9f60a77c";
      pos2minAgo.operationId = "5ec6984225c93d6e6ca2bd6b",
      pos2minAgo.operationType = "PATROL";
      pos2minAgo.sourceType = "MOBILE_APP";
      pos2minAgo.teamId = "5ec2f72b9b44b70001588e4b";

      var pos3minAgo = new SignalModel();
      pos3minAgo.timestamp = now.subtract(1, "minute").valueOf();
      pos3minAgo.latitude = -22.95665869;
      pos3minAgo.longitude = -43.1976716;
      pos3minAgo.mobileObjectId = "12e570dc9f60a77c";
      pos3minAgo.operationId = "5ec6984225c93d6e6ca2bd6b",
      pos3minAgo.operationType = "PATROL";
      pos3minAgo.sourceType = "MOBILE_APP";
      pos3minAgo.teamId = "5ec2f72b9b44b70001588e4b";

      var pos4minAgo = new SignalModel();
      pos4minAgo.timestamp = now.subtract(1, "minute").valueOf();
      pos4minAgo.latitude = -22.95666869;
      pos4minAgo.longitude = -43.1977716;
      pos4minAgo.mobileObjectId = "12e570dc9f60a77c";
      pos4minAgo.operationId = "5ec6984225c93d6e6ca2bd6b",
      pos4minAgo.operationType = "PATROL";
      pos4minAgo.sourceType = "MOBILE_APP";
      pos4minAgo.teamId = "5ec2f72b9b44b70001588e4b";

      return [ posNow, pos1minAgo, pos2minAgo, pos3minAgo, pos4minAgo ];
  }

  /**
   * Simula o recebimento de sinais em tempo real no rastro histórico.
   *
   * @param callNumber a cada valor - de 1 a 5 - o método irá retornar um dado histórico diferente,
   * simulando a passagem do tempo
   */
  public getSampleRealTimeSignal(callNumber: number) {
      switch (callNumber) {
      case 5: {
          var pos5 = new SignalModel();
          pos5.timestamp = moment().valueOf();
          pos5.latitude = -22.95602869;
          pos5.longitude = -43.1973716;
          pos5.mobileObjectId = "12e570dc9f60a77c";
          pos5.operationId = "5ec6984225c93d6e6ca2bd6b",
          pos5.operationType = "PATROL";
          pos5.sourceType = "MOBILE_APP";
          pos5.teamId = "5ec2f72b9b44b70001588e4b";
          return [ pos5 ];
      }
      case 4: {
          var pos4 = new SignalModel();
          pos4.timestamp = moment().valueOf();
          pos4.latitude = -22.95613869;
          pos4.longitude = -43.1974716;
          pos4.mobileObjectId = "12e570dc9f60a77c";
          pos4.operationId = "5ec6984225c93d6e6ca2bd6b",
          pos4.operationType = "PATROL";
          pos4.sourceType = "MOBILE_APP";
          pos4.teamId = "5ec2f72b9b44b70001588e4b";
          return [ pos4 ];
      }
      case 3: {
          var pos3 = new SignalModel();
          pos3.timestamp = moment().valueOf();
          pos3.latitude = -22.95624869;
          pos3.longitude = -43.1975716;
          pos3.mobileObjectId = "12e570dc9f60a77c";
          pos3.operationId = "5ec6984225c93d6e6ca2bd6b",
          pos3.operationType = "PATROL";
          pos3.sourceType = "MOBILE_APP";
          pos3.teamId = "5ec2f72b9b44b70001588e4b";
          return [ pos3 ];
      }
      case 2: {
          var pos2 = new SignalModel();
          pos2.timestamp = moment().valueOf();
          pos2.latitude = -22.95635869;
          pos2.longitude = -43.1976716;
          pos2.mobileObjectId = "12e570dc9f60a77c";
          pos2.operationId = "5ec6984225c93d6e6ca2bd6b",
          pos2.operationType = "PATROL";
          pos2.sourceType = "MOBILE_APP";
          pos2.teamId = "5ec2f72b9b44b70001588e4b";
          return [ pos2 ];
      }
      case 1: {
          var pos1 = new SignalModel();
          pos1.timestamp = moment().valueOf();
          pos1.latitude = -22.95646869;
          pos1.longitude = -43.1977716;
          pos1.mobileObjectId = "12e570dc9f60a77c";
          pos1.operationId = "5ec6984225c93d6e6ca2bd6b",
          pos1.operationType = "PATROL";
          pos1.sourceType = "MOBILE_APP";
          pos1.teamId = "5ec2f72b9b44b70001588e4b";
          return [ pos1 ];
      }
      default: return null;
      }
  }

  public getSampleVerifications() : VerificationModel[] {
    const verifications : VerificationModel[] = [];
    verifications.push(this.getSampleVerification(1));
    verifications.push(this.getSampleVerification(2));
    verifications.push(this.getSampleVerification(3));

    return verifications;
  }

  public getSampleVerification(verificationIndex: number): VerificationModel {
    switch(verificationIndex){
      case 1:{
        const verification = new VerificationModel();
        verification.id = "1";
        verification.identifier = "0201202-V1400010";
        verification.status = EventStatus.PENDING;
        verification.analyst = this.getSampleUser(verificationIndex);
// TODO falta atualizar esses valores para usar as novas definições, essas eram temporárias
// verification.duct = EventDuct.OCAB;
// verification.valve = EventValve.VALVE_7;
        verification.band = "000+500 - 000+600";
        verification.priority = true;
        verification.analyst = this.getSampleUser(verificationIndex);
        verification.patrolTeam = this.getSamplePatrolTeam();
        verification.startDate = moment().valueOf();
        return verification;
      }
      case 2: {
        const verification = new VerificationModel();
        verification.id = "2";
        verification.identifier = "0201202-V4556";
        verification.status = EventStatus.PENDING;
        verification.analyst = this.getSampleUser(verificationIndex);
        // verification.duct = EventDuct.OCAB2;
//        verification.valve = EventValve.VALVE_10;
        verification.band = "000+500 - 000+600";
        verification.priority = false;
        verification.analyst = this.getSampleUser(verificationIndex);
        verification.patrolTeam = this.getSamplePatrolTeam();
        verification.startDate = moment().valueOf();
        return verification;
      }
      case 3: {
        const verification = new VerificationModel();
        verification.id = "3";
        verification.identifier = "0201202-V1400010";
        verification.status = EventStatus.PENDING;
        verification.analyst = this.getSampleUser(verificationIndex);
        // verification.duct = EventDuct.OCAB;
//        verification.valve = EventValve.VALVE_5;
        verification.band = "000+500 - 000+600";
        verification.priority = true;
        verification.analyst = this.getSampleUser(verificationIndex);
        verification.patrolTeam = this.getSamplePatrolTeam();
        verification.startDate = moment().valueOf();
        return verification;
      }
    }
  }

  public getSampleAction(actionIndex: number): EventMainActionModel {
    switch(actionIndex){
      case 1:{
        const action = new EventMainActionModel();
        action.id = "1";
        action.action = "Ligar para o ccpd";
        action.comment = "CCPD não atendeu";
        return action;
      }
      case 2: {
        const action = new EventMainActionModel();
        action.id = "2";
        action.action = "Tirar fotos do local";
        action.comment = "Não foi possível uma aproximação por causa dos riscos";
        return action;
      }
      case 3: {
        const action = new EventMainActionModel();
        action.id = "3";
        action.action = "Enviar localização do local";
        action.comment = "";
        return action;
      }
    }
  }


  public getSampleResult(): ResultsModel{
    const result = new ResultsModel();
    result.id = "1";
    result.occurrenceKM = "23km";
    result.occurrence = "Ocorrência do resultado";
    result.notes = "Notas do resultado";
    result.occurrenceLatLong = "-22.913727, -43.2328779";

    return result;
  }

  public getSampleEvent(): EventModel {
    const event = new EventModel();
    event.id = "1";
    event.identifier = "0201201-e1400001";
    event.status = EventStatus.IN_TREATMENT;
    event.date = moment().valueOf();
//    event.analyst = "Daniel Gonçalves";
//    event.communicationChannel = Channel.COMPLAINT;
    event.source = SourceType.MOBILE_APP;
    // event.duct = EventDuct.OCAB;
//    event.valve = EventValve.VALVE_7;
//    event.stretch = EventStretch.STRETCH_1;
//    event.band = EventBand.BAND_1;
    event.targetPointKM = "000+500 - 000+600";
    return event;
  }

  public getSampleEventList(): EventModel[] {
    const eventList : EventModel[]= [];

    const event = new EventModel();
    event.id = "1";
    event.identifier = "0201201-e1400001";
    event.status = EventStatus.IN_TREATMENT;
    event.date = moment().valueOf();
  //  event.analyst = "Daniel Gonçalves";
//    event.communicationChannel = Channel.COMPLAINT;
    event.source = SourceType.MOBILE_APP;
    // event.duct = EventDuct.OCAB;
//    event.valve = EventValve.VALVE_7;
//    event.stretch = EventStretch.STRETCH_5;
//    event.band = EventBand.BAND_9;
    event.targetPointKM = "0";
    event.targetPointLatLong = "000+500 - 000+600";
    event.stretchStartKM = "0";
    event.stretchEndKM = "255";
    event.street = "Rua Dr. Mario Vianna 459 ap 804";
    event.city = "Niterói";
    event.state = "RJ";
    event.zipCode = "24241-000";
    event.result = this.getSampleResult();
    // event.verifications = [];
    // event.verifications.push(this.getSampleVerification(1));
    // event.verifications.push(this.getSampleVerification(2));
    // event.verifications.push(this.getSampleVerification(3));
    event.actions = []
    event.actions.push(this.getSampleAction(1));
    event.actions.push(this.getSampleAction(2));
    event.actions.push(this.getSampleAction(3));


    eventList.push(event);

    const event2 = new EventModel();
    event2.id = "2";
    event2.identifier = "0201202-e1400010";
    event2.status = EventStatus.FINALIZED;
    event2.date = moment().valueOf();
//    event2.analyst = "Maradona";
//    event2.communicationChannel = Channel.COMPLAINT;
    event2.source = SourceType.MOBILE_APP;
//    event2.duct = EventDuct.OCAB;
//    event2.valve = EventValve.VALVE_7;
    event2.targetPointKM = "000+500 - 000+600";
    event2.result = this.getSampleResult();
    // event2.verifications = [];
    // event2.verifications.push(this.getSampleVerification(1));
    // event2.verifications.push(this.getSampleVerification(2));
    // event2.verifications.push(this.getSampleVerification(3));
    event2.actions = []
    event2.actions.push(this.getSampleAction(1));
    event2.actions.push(this.getSampleAction(2));
    event2.actions.push(this.getSampleAction(3));

    eventList.push(event2);

    const event3 = new EventModel();
    event3.id = "3";
    event3.identifier = "0201202-e1400010";
    event3.status = EventStatus.FINALIZED;
    event3.date = moment().valueOf();
//    event3.analyst = "Maradona";
//    event3.communicationChannel = Channel.MAIL_168;
    event3.source = SourceType.SIMF;
   // event3.duct = EventDuct.OCAB;
//    event3.valve = EventValve.VALVE_7;
    event3.targetPointKM = "000+500 - 000+600";
    event3.result = this.getSampleResult();
    // event3.verifications = [];
    // event3.verifications.push(this.getSampleVerification(1));
    // event3.verifications.push(this.getSampleVerification(2));
    // event3.verifications.push(this.getSampleVerification(3));
    event3.actions = []
    event3.actions.push(this.getSampleAction(1));
    event3.actions.push(this.getSampleAction(2));
    event3.actions.push(this.getSampleAction(3));

    eventList.push(event3);

    const event4 = new EventModel();
    event4.id = "4";
    event4.identifier = "0201202-e1400010";
    event4.status = EventStatus.FINALIZED;
    event4.date = moment().valueOf();
//    event4.analyst = "Maradona";
//    event4.communicationChannel = Channel.GPS_CENTER_PHONE;
    event4.source = SourceType.MOBILE_APP;
  //  event4.duct = EventDuct.OCAB;
//    event4.valve = EventValve.VALVE_7;
    event4.targetPointKM = "000+500 - 000+600";
    event4.result = this.getSampleResult();
    // event4.verifications = [];
    // event4.verifications.push(this.getSampleVerification(1));
    // event4.verifications.push(this.getSampleVerification(2));
    // event4.verifications.push(this.getSampleVerification(3));
    event4.actions = []
    event4.actions.push(this.getSampleAction(1));
    event4.actions.push(this.getSampleAction(2));
    event4.actions.push(this.getSampleAction(3));

    eventList.push(event4);

    const event5 = new EventModel();
    event5.id = "5";
    event5.identifier = "0201202-e1400010";
    event5.status = EventStatus.IN_TREATMENT;
    event5.date = moment().valueOf();
    // event5.analyst = "Pelé";
//    event5.communicationChannel = Channel.CNCL_DC_ZAP;
    event5.source = SourceType.MOBILE_APP;
  //  event5.duct = EventDuct.OCAB;
//    event5.valve = EventValve.VALVE_10;
    event5.targetPointKM = "000+500 - 000+600";
    event5.result = this.getSampleResult();
    // event5.verifications = [];
    // event5.verifications.push(this.getSampleVerification(1));
    // event5.verifications.push(this.getSampleVerification(2));
    // event5.verifications.push(this.getSampleVerification(3));
    event5.actions = []
    event5.actions.push(this.getSampleAction(1));
    event5.actions.push(this.getSampleAction(2));
    event5.actions.push(this.getSampleAction(3));

    eventList.push(event5);

    const event6 = new EventModel();
    event6.id = "6";
    event6.identifier = "0201202-e1400010";
    event6.status = EventStatus.PENDING;
    event6.date = moment().valueOf();
    // event6.analyst = "Pelé";
//    event6.communicationChannel = Channel.CHANNEL_168;
    event6.source = SourceType.VEHICLE;
  //  event6.duct = EventDuct.OCAB;
//    event6.valve = EventValve.VALVE_10;
    event6.targetPointKM = "000+500 - 000+600";
    event6.result = this.getSampleResult();
    // event6.verifications = [];
    // event6.verifications.push(this.getSampleVerification(1));
    // event6.verifications.push(this.getSampleVerification(2));
    // event6.verifications.push(this.getSampleVerification(3));
    event6.actions = []
    event6.actions.push(this.getSampleAction(1));
    event6.actions.push(this.getSampleAction(2));
    event6.actions.push(this.getSampleAction(3));

    eventList.push(event6);

    const event7 = new EventModel();
    event7.id = "7";
    event7.identifier = "0201128-e1400030";
    event7.status = EventStatus.PENDING;
    event7.date = moment().valueOf();
    // event7.analyst = "Maradona";
//    event7.communicationChannel = Channel.COMPLAINT;
    event7.source = SourceType.MOBILE_APP;
    event7.duct = "OCAB2";
//    event7.valve = EventValve.VALVE_10;
    event7.targetPointKM = "000+500 - 000+600";
    event7.result = this.getSampleResult();
    // event7.verifications = [];
    // event7.verifications.push(this.getSampleVerification(1));
    // event7.verifications.push(this.getSampleVerification(2));
    // event7.verifications.push(this.getSampleVerification(3));
    event7.actions = []
    event7.actions.push(this.getSampleAction(1));
    event7.actions.push(this.getSampleAction(2));
    event7.actions.push(this.getSampleAction(3));

    eventList.push(event7);

    return eventList;
  }

}
