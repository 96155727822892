import { Component, Inject, OnInit, OnDestroy, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { NGXLogger }  from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { StorageService } from '../../service/storage-service';

import { BaseGoldenPanel } from '../base-golden-panel/base-golden-panel';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { StompState } from '@stomp/ng2-stompjs';

import { OperationTypeDescription, SourceType, OperationStatusDescription, SourceTypeDescription, Permission, MapEvents, OperationType } from '../../model/enums.enum';
import { TrackingService } from '../../service/model/tracking.service';
import { Websocket } from '../../service/websocket/websocket';
import { ESP, FILL_DATA_PREFIX, MAP_PAGE, TRACKING_PAGE, USER_NOT_FOUND } from 'src/app/common/constants';
import { TrackingModel } from '../../model/tracking.model';

import * as GoldenLayout from 'golden-layout';
import * as moment from 'moment';
import { UserModel } from 'src/app/model/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { SelectionModel } from '@angular/cdk/collections';
import { MatMenuTrigger } from '@angular/material/menu';
import { LoadingListService } from 'src/app/service/loading/loading-list.service';
import { AuthorizationService } from '../../service/authorization/authorization.service';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import DateUtils from 'src/app/service/util/date-utils';
import { ToastrService } from 'ngx-toastr';
import { EntityCacheService } from '../../service/model/entity.cache.service';
import { VehicleSignalModel } from 'src/app/model/vehicle.signal.model';
import { AuthenticationService } from '../login/login-services';

/** SIGNALS WEBSOCKET STATES */
const DISCONNECTED = { value: 0, text: 'Sinais Desconectado' };
const CONNECTING = { value: 1, text: 'Sinais Conectando' };
const CONNECTED = { value: 2, text: 'Sinais Conectado' };

@ProfileClassToConsole()
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent extends BaseGoldenPanel implements OnInit, OnDestroy {

  /** VARIÁVEIS EXTERNAS (referenciadas no HTML) */
  sourceType = SourceType;
  operationTypeDescription = OperationTypeDescription;
  operationStatusDescription = OperationStatusDescription;
  title = environment.TRACKING_TITLE_LABEL;
  tabTitle = environment.TRACKING_GROUP_LABEL;
  USER_NOT_FOUND = USER_NOT_FOUND;

  signalsConnectionState: {value: number, text: string} = DISCONNECTED;
  private vehicleSignalsWebsocket: Websocket;
  private signalsWebsocket: Websocket;

  private signalsWebsocketStateSubscription: Subscription;
  private signalsWebsocketConnectionSubscription: Subscription;
  private vehicleSignalsWebsocketConnectionSubscription: Subscription;

  filteredTrackings: TrackingModel[] = []; // apenas os filtrados do trackingMap (aponta para os mesmos modelos armazenados em trackingMap)
  displayedColumns: string[];
  dataSource: MatTableDataSource<TrackingModel>; // Baseado em filteredTrackings
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatInput, { static: true }) searchInput: MatInput;
  selection = new SelectionModel<TrackingModel>(true, []);
  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger, static: true }) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuSelectedItem; // Leave it as any, for now

  // permissões de acordo com o perfil
  permission = Permission;

  loadingListService: LoadingListService = new LoadingListService();

  authenticatedSubscription: Subscription;

  constructor(public logger:                    NGXLogger,
              protected ngZone:                 NgZone,
              protected toastr:                 ToastrService,              
              private trackingService:          TrackingService,
              private changeDetector:           ChangeDetectorRef,
              private dialog:                   MatDialog,
              private storageService:           StorageService,
              protected authorizationService:   AuthorizationService,
              public entityCacheService:        EntityCacheService,
              private authenticationService:    AuthenticationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, goldenLayout, container);
  }

  ngOnInit() {
    this.logger.debug('TrackingComponent.ngOnInit()');

    this.glUpdateTabTitle(this.tabTitle);
    this.subscribeOnAuthenticatedStatus();

    // Para forçar uma leitura dos últimos sinais ao abrir essa tela
    this.trackingService.loadLastVehicleSignals(this.loadingListService, false);
    this.trackingService.loadLastSignals(this.loadingListService, false);
    this.entityCacheService.loadCompanies(this.loadingListService);

    this.subscribeOnFillData();
  
    this.initWebsocket();

    this.displayedColumns = ['select',
                            'signal.sourceType',
                            'description',
                            'company',
                            'patrolTeam.name',
                            'operation.identifier',
                            'operation.type',
                            'operation.status',
                            'operation.startDate',
                            'signal.receivedTimestamp'
                            ];

    this.dataSource = new MatTableDataSource(this.filteredTrackings);
    this.sort.sort({id: "signal.sourceType", start: 'asc', disableClear: false});
  }

  initWebsocket() {
    this.ngZone.runOutsideAngular(() => {
      this.subscribeToSignalsWebsocketStatusCheck();
      this.subscribeOnSignalWebsocketConnected();
      this.subscribeOnVehicleSignalWebsocketConnected();
    });
  }

  ngOnDestroy(){
    const loggedUser = this.storageService.getLocalUser();
    if(!loggedUser || !loggedUser.id ) return;

    this.signalsWebsocketStateSubscription?.unsubscribe();
    this.signalsWebsocketConnectionSubscription?.unsubscribe();
    this.vehicleSignalsWebsocketConnectionSubscription?.unsubscribe();

    this.signalsWebsocket?.destroy();
    this.signalsWebsocket = null;
    this.vehicleSignalsWebsocket?.destroy();
    this.vehicleSignalsWebsocket = null;

    this.glUnSubscribeEvent(FILL_DATA_PREFIX + TRACKING_PAGE);

    this.loadingListService.destroy();
    this.authenticatedSubscription?.unsubscribe();
  }

  private subscribeOnFillData(){
    this.glSubscribeEvent(FILL_DATA_PREFIX + TRACKING_PAGE, (data) => {
      if (data.updateFilter) {
        this.updateFilteredTrackings();
      }
    });
  }

  protected renderComponent(){
    // aguarda um pouco para outras tarefas terminarem
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 1000);
  }

  /** Verifica o estado da conexão com o websocket de sinais */
  private subscribeToSignalsWebsocketStatusCheck(){
    this.signalsWebsocket = Websocket.createSignalsWebsocket();
    this.signalsWebsocketStateSubscription = this.signalsWebsocket.state().subscribe((state: StompState) => {
      if (state === 0 || state === 3){
        this.signalsConnectionState = DISCONNECTED;
      }else if (state === 1){
        this.signalsConnectionState = CONNECTING;
      }else if (state === 2){
        this.signalsConnectionState = CONNECTED;
      }
      this.changeDetector.detectChanges();
    }, error => this.logger.error(error));
  }

  /**
   * Ao conectar com o websocket, recarrega os últimos sinais do backend para evitar sinais antigos perdidos
   */
  private subscribeOnSignalWebsocketConnected(){
    this.signalsWebsocketConnectionSubscription = this.signalsWebsocket.onConnectCallback().subscribe(() => {
      this.logger.log('OnSignalWebsocketConnected ***** Conexão com o websocket estabelecida.');
      this.trackingService.loadLastSignals(this.loadingListService, false);
      this.renderComponent();
    }, (error: any) => this.logger.error(error));
  }

   /**
   * Ao conectar com o websocket, recarrega os últimos sinais de veículos do backend para evitar sinais antigos perdidos
   */
  private subscribeOnVehicleSignalWebsocketConnected(){
    this.vehicleSignalsWebsocket = Websocket.createVehicleSignalsWebsocket();
    this.vehicleSignalsWebsocketConnectionSubscription = this.vehicleSignalsWebsocket.onConnectCallback().subscribe(() => {
      this.logger.log('OnVehicleSignalWebsocketConnected ***** Conexão com o websocket estabelecida.');
      this.trackingService.loadLastVehicleSignals(this.loadingListService, false);
      this.renderComponent();
    }, (error: any) => this.logger.error(error));
  }

  // Filtra os itens de rastreamento pela lotação e pelos excluídos manualmente
  private updateFilteredTrackings(){
    const trackingList = this.trackingService.getTrackingList();
    const specificPlacements = this.storageService.getSpecificPlacementIds();

    this.filteredTrackings = [];

    trackingList.forEach((tracking)=>{
      // Se não foi removido manualmente da lista, e se a lotação é permitida, então adiciona na lista
      if(!tracking.invalidInfo && !tracking.removedFromTracking && this.trackingService.isPermittedPlacement(tracking, specificPlacements)) {
        this.filteredTrackings.push(tracking);
      }
    });

    // Deixar comentado. Usar apenas quando estiver depurando o tracking
    // this.logger.debug("TrackingComponent Total de Itens: " + trackingList.length);
    // this.logger.debug("TrackingComponent Total de Itens Filtrados: " + (trackingList.length - this.filteredTrackings.length));
    
    this.filteredTrackings.sort((track1, track2) => {
      if(track1?.patrolTeam?.name < track2?.patrolTeam?.name) return 1 ;
      if(track1?.patrolTeam?.name > track2?.patrolTeam?.name) return -1;
      return 0;
    })

    this.buildDataSource();
  }
  
  onRemoveManyClick(trackings: TrackingModel[]) {
    trackings.forEach(tracking => {
      let indexToRemove = this.filteredTrackings.findIndex( (element: TrackingModel) => element.signal.mobileObjectId ===  tracking.signal.mobileObjectId);
      this.filteredTrackings.splice(indexToRemove,1);
      tracking.removedFromTracking = true;
    });

    this.buildDataSource();
    this.selection.clear();
  }

  onRemoveItemClick(tracking: TrackingModel) {
    let indexToRemove = this.filteredTrackings.findIndex( (element: TrackingModel) => element.signal.mobileObjectId ===  tracking.signal.mobileObjectId);
    this.filteredTrackings.splice(indexToRemove,1);
    tracking.removedFromTracking = true;

    this.buildDataSource();
    this.selection.clear();
  }

  /** Callback para a seleção de um item da lista de objetos móveis */
  onTrackingClick(tracking: TrackingModel){
    this.glOpenContainer(MAP_PAGE, {tracking: tracking, fit: true});
  }

  onTrackingManyClick(trackings: TrackingModel[]) {
    let entityList = [];
    trackings.forEach( (tracking: TrackingModel) => {
        entityList.push(tracking);
    });

    this.glOpenContainer(MAP_PAGE, {trackingList: entityList, fit: true});
  }

  /** Retorna estilo de destaque para o item selecionado */
  getStyle(item: TrackingModel){
    if (this.selection.isSelected(item)) {
      return 'tracking-side-selected';
    } else {
      return 'tracking-side-not-selected';
    }
  }

  onHistoricalTrackingClick(tracking: TrackingModel) {
    this.glOpenContainer(MAP_PAGE, {historicalTracking: tracking});
  }

  onHistoricalTrackingManyClick(trackings: TrackingModel[]) {
    let entityList = [];
    trackings.forEach( (tracking: TrackingModel) => {
        entityList.push(tracking);
    });

    this.glOpenContainer(MAP_PAGE, {historicalTrackingList: entityList});
  }

  onOperationLocationClick(tracking: TrackingModel) {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingLastOperation: tracking});
  }

  onOperationClick(tracking: TrackingModel) {
    if (tracking.operation.type == OperationType.PATROL){
      this.glOpenContainer('patrols-edit', {id: tracking.operation.id});
    }
    else {
      this.glOpenContainer('verifications-edit', {id: tracking.operation.id});
    }
  }

  onOperationLocationManyClick(trackings: TrackingModel[]) {
    let entityList = [];
    trackings.forEach( (tracking: TrackingModel) => {
      if(this.hasOperationLocation(tracking))
        entityList.push(tracking);
    });

    if(entityList.length > 0)
      this.glOpenContainer(MAP_PAGE, {historicalTrackingLastOperationList: entityList});
    else
      this.toastr.warning("Nenhum rastreamento selecionado possui operação.");
  }

  hasOperation(tracking: TrackingModel): boolean {
    return !!tracking?.operation;
  }

  hasOperationLocation(tracking: TrackingModel): boolean {
    return tracking?.operation &&
           ((tracking?.operation.route && !!tracking?.operation.route.fileRouteKml) ||
            (tracking?.operation.inspections && tracking?.operation.inspections.length != 0));
  }

  getSourceClass(tracking: TrackingModel){
    if (tracking && tracking.operation){
      return tracking.operation.status;
    }
    return 'OTHER';
  }

  getSourceTooltip(tracking: TrackingModel){
    if(tracking && tracking.operation){
      return OperationStatusDescription[tracking.operation.status];
    }
  }

  getLastUpdateTime(tracking: TrackingModel, fullDate: boolean = false) {
    let timestamp = tracking.signal.receivedTimestamp? tracking.signal.receivedTimestamp: tracking.signal.timestamp;
    let lastMoment = moment(timestamp);
    const day = lastMoment.dayOfYear();
    const nowDay = moment().dayOfYear();
    if (fullDate)
      return lastMoment.format('yyyy/MM/DD HH:mm:ss');
    else if (nowDay != day)
      return lastMoment.format('DD/MM HH:mm:ss');
    else
      return lastMoment.format('HH:mm:ss');
  }

  getUserNameLoginTitle(user) {
    return UserModel.getUserTitle(user);
  }

  buildDataSource(){
    this.dataSource = new MatTableDataSource(this.filteredTrackings);
    this.dataSource.filterPredicate = (entity: TrackingModel, filter: string): boolean => {
      return this.getStringEntityForFilter(entity).indexOf(filter) != -1;
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };

    this.dataSource.sort = this.sort;
    
    let filterValue = this.searchInput.value.trim().toLowerCase();
    if (filterValue != '') {
      this.dataSource.filter = filterValue;
    }
  }

  getDescription(tracking: TrackingModel) {
    if (!tracking) return;
    if (tracking.signal.sourceType === SourceType.VEHICLE) {
      const vehicleSignal = tracking.signal as VehicleSignalModel;
      return tracking.title + ' (' + vehicleSignal.movimento?.toLowerCase() + ')';
    }
    else {
      return tracking.title;
    }
  }

  protected getStringEntityForFilter(tracking: TrackingModel): string {
    const description = this.lowerAndTrimText(this.getDescription(tracking));
    const company = tracking.company? this.lowerAndTrimText(tracking.company.name): '';
    const patrolTeam = (tracking.patrolTeam) ? this.lowerAndTrimText(tracking.patrolTeam?.name) : '';
    const operationType = (tracking?.operation) ? this.lowerAndTrimText(this.operationTypeDescription[tracking.operation.type]) : '';
    const identifier = (tracking.operation) ? this.lowerAndTrimText(tracking.operation.identifier) : '';
    const operationStatus = (tracking.operation) ? this.lowerAndTrimText(OperationStatusDescription[tracking.operation.status]) : '';
    const sourceType = this.lowerAndTrimText(SourceTypeDescription[tracking.signal.sourceType]); // pode buscar por veículo ou App
    const startDate = tracking.operation ? DateUtils.timestampToStringInMinutes(tracking.operation.startDate) : '';
    const lastUpdate = this.getLastUpdateTime(tracking);
    return description + ESP + patrolTeam + ESP +company + ESP + sourceType + ESP + identifier + ESP + operationType + ESP +  operationStatus + ESP + startDate + ESP + lastUpdate;
  }

  /** Método utilitário para passar strings para caixa alta e remover espaços (se existirem) */
  protected lowerAndTrimText(text: string) {
    return (text) ? text.trim().toLowerCase() : '';
  }

  getPropertyByPath(item: Object, property: string){
    let value = property.split('.').reduce(this.getProperty, item);
    if (property === 'sourceType') {
      value = SourceTypeDescription[value];
    }
    else if (property === 'description') {
      value = this.getDescription(item as TrackingModel);
    }
    else if (property === 'company') {
      value = item['company']? item['company'].name: '';
    }
    else if (property === 'signal.receivedTimestamp') {
      value = this.getLastUpdateTime(item as TrackingModel, true);
      console.log(value);
    }

    if (typeof value === 'string'){
        value = this.lowerAndTrimText(value);
    }
    return value ? value : '';
  }

   /* reducer method */
  getProperty (o, i) {
    if ( !o ) { return ''; }
    return o[i];
  }

  applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onRemoveSearch(){
    this.searchInput.value = '';
    this.dataSource.filter = '';
  }

  openContextMenu(event: MouseEvent, row) {
    event.preventDefault();
    this.contextMenuSelectedItem = row;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  checkboxTip(row?: TrackingModel): string {
    return `${this.selection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Item`;
  }

  onCheckboxClick(alert: TrackingModel){
    this.selection.toggle(alert);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.filteredData.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.selection.hasValue() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  getListCount(){
    return this.dataSource? this.dataSource.filteredData.length: 0;
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  /**Como a tela de mensagem é carregado logo após a atenticação, é preciso verificar se o usuário tem acesso */
  subscribeOnAuthenticatedStatus() {
    this.authenticatedSubscription = this.authenticationService.authenticated$.subscribe(authenticated => {
      this.logger.debug('TrackingComponent.OnAuthenticatedStatus() - ' + authenticated);
      if (authenticated) {
        if(!this.authorizationService.userHasPermission(this.permission.VIEW_TRACKING_TEAM))
          this.glRemoveLayout('tracking');
      }
    });
  }
}
