import { EvidenceRequirement, QuestionType } from "./enums.enum";

export class QuestionModel {
    identifier: string;
    label: string;
    type: QuestionType;
    evidenceRequirement?: EvidenceRequirement;
    children: QuestionModel[];
  
    constructor(){
      this.children = [];
      this.type = QuestionType.YESNO; //Default YESNO
      this.evidenceRequirement = EvidenceRequirement.OPTIONAL; //Default OPTIONAL
    }
  }