import { environment } from 'src/environments/environment';
import { GeoModel } from './geo.model';

export class ValveModel extends GeoModel{
  // Não tem no momento no kml original. Já deixamos pronto na importação para o futuro.
  km:           string;
  duct:         string; // Nome do duto

  public static getPopupContent(feature, search: boolean = false): string{
    
    const valve = feature.properties as ValveModel;

    let states: string = valve.states ? valve.states.toString() : '';
    let latLong: string = GeoModel.getLatLong(valve);
    let contents:string = `<h5 style="text-align: center">Válvula</h5>
                           <h6 style="text-align: center"><b>${valve.name}</b></h6>
                           ${valve.duct? '<div><b>Duto:</b>' + valve.duct + '</div>': ''}
                           ${valve.km? '<div><b>KM:</b>' + valve.km + '</div>': ''}
                           <div><b>UF(s):</b> ${states} </div>
                           <div><b>Lat, Long:</b> ${!search? latLong: ''} </div>
                          `;
    if (!environment.showPopupLayerTables || valve.description == null || valve.description === "") {                              
      return contents;
    }
    else {
      return contents + "<hr/>" + valve.description
    }
  }
}
