import { AbstractSignalModel } from "./abstract.signal.model";

/**
 * Sinal recebido de um aplicativo ou GPS.
 */
export class SignalModel extends AbstractSignalModel{

  public userId: string; // O mesmo que mobileObjectId

  // O ideal seria ter o login do usuário para não depender da busca do usuário para saber quem é
  // Para veículos usamos somente a placa, por exemplo

  constructor(){
    super();
  }
}
