import { Directive, Inject, NgZone, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import * as GoldenLayout from "golden-layout";
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from "ngx-golden-layout";
import { NGXLogger } from "ngx-logger";
import { ToastrService } from "ngx-toastr";
import { EntityModel } from '../../model/entity.model';
import { AuthorizationService } from "../../service/authorization/authorization.service";
import { StorageService } from "../../service/storage-service";
import { HttpError, REGISTRATION_UPDATE_EVENT } from '../../common/constants';
import { FilteredListComponent } from './../filtered-list-component';
import { EntityService } from 'src/app/service/model/entity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';


/**
 * Componente para ouvir mensagens de atualizações envolvendo cadastros e atualizar a lista correspondente.
 * TODO lbarros esta classe tem muito código em comum com a {OperationListComponent}. Deve ser promovido o reuso.
 */
@Directive()
export class RegistrationListComponent extends FilteredListComponent implements OnInit, OnDestroy {

  protected registrationType;

  constructor(public    logger:               NGXLogger,
              public authorizationService: AuthorizationService,
              protected entityService:        EntityService,
              protected dialog:               MatDialog,
              public    componentName:        string,
              public    tabTitle:             string,
              public    title:                string,
              public    modelName:            string,   // Usado em diálogos de confirmação
              protected storageService:       StorageService,
              protected changeDetector:                ChangeDetectorRef,
              protected toastr:               ToastrService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, entityService, dialog, componentName, tabTitle, title, modelName, storageService, changeDetector,toastr, goldenLayout, container);
  }

  ngOnInit() {
    super.ngOnInit();
    this.logger.debug('RegistrationListComponent.ngOnInit()');
    this.subscribeToRegistrationUpdateEvent();
  }

  ngOnDestroy(){
    super.ngOnDestroy();
    this.glUnSubscribeEvent(REGISTRATION_UPDATE_EVENT);
  }

  // Chamada quando algum item da lista foi atualizado pelo backend
  updateModelItem(id: string){
    const extraParams = new Map();
    extraParams.set('id', id);
    extraParams.set('metadata', this.registrationType);

    this.entityService.getRecord(id, extraParams).pipe(first()).subscribe((entity: EntityModel) => {
      if(!entity) {
        return;
      };

      this.findAndUpdateModel(entity);
    }, (error: HttpErrorResponse) => {
      if (error.status === HttpError.NOT_FOUND) { // objeto removido
        this.model = this.model.filter( model => model.id != id);
        this.buildDataSource();
        return;
      }
      this.logger.error(error);
    });
  }

  /** Se inscreve para o ouvir o websocket de mudanças nos registros */
  private subscribeToRegistrationUpdateEvent() {
    this.glSubscribeEvent(REGISTRATION_UPDATE_EVENT, (data) => {
      if (data.registrationType != this.registrationType) {
        return;
      }
      
      this.updateModelItem(data.id);
      
    });
  }
}
