import { Injectable } from "@angular/core";
import { FilterModel } from "src/app/general/filter-component/filter.model";

@Injectable({
    providedIn: 'root'
  })
  export class FormFilterModel extends FilterModel {
    name: string;
    placement: string;
    inspectionType: string;
  }
