import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {EntityService} from './entity.service';
import {UserModel} from '../../model/user.model';
import {environment} from '../../../environments/environment';

import {EntityModel} from '../../model/entity.model';
import { Observable, throwError } from 'rxjs';
import { FilterModel } from 'src/app/general/filter-component/filter.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends EntityService {

  constructor(logger:         NGXLogger,
              httpClient:     HttpClient) {
    super(logger, httpClient, `${environment.settings.registrations_address}/users`);
  }

  public loadUsersByIds(ids: string[] ): Observable<EntityModel[]> {
    let params: HttpParams = new HttpParams();

    ids.forEach( id => {
      params = params.append('id', id);
    });

    return super.loadListFromRestApi(null, null, null, params);
  }

  protected filteredLoadFromRestApi<T extends EntityModel[]>(pageIndex?: number, pageSize?: number, sort?: string, filter?: FilterModel): Observable<T> {

    let params: HttpParams = new HttpParams();

    if (filter){

      if (filter.placements){
        filter.placements.forEach( placement => {
          params = params.append('placements', placement);
        });
      }
    }

    return super.loadFromRestApi(pageIndex, pageSize, sort, params);
  }

  public sendEmailWithPassword(record: EntityModel) {
    this.logger.debug('EntityService.sendEmailWithPassword()');
    return this.http.post(this.apiUrl + '/email/', record);
  }

  public getUserFromEmail(email: string): Observable<UserModel> {
    return this.http.get<UserModel>(this.apiUrl + '/email?email=' + email);
  }
  
  protected handleError(error: HttpErrorResponse | any) {    
    return throwError(error);
  }
}
