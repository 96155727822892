import { ConfirmationDialogData } from './confirmation.data';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  lines:string[];
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject (MAT_DIALOG_DATA) public data: ConfirmationDialogData) { }

  ngOnInit() {
    if(this.data.linesMsg)
    this.lines = this.data.linesMsg.split("\n");
  }
}
