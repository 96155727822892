<div mat-dialog-title>
  <span>Versões dos Microserviços</span>
  <button mat-icon-button mat-dialog-close matTooltip="Fechar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="-table-hover w-100" *ngIf="dataSource && dataSource.length > 0; else elseBlock">

      <!-- Coluna nome do serviço -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Serviço </th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <!-- Coluna de URL -->
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef> URL </th>
        <td mat-cell *matCellDef="let element">{{element.url}}</td>
      </ng-container>

      <!-- Coluna de versão -->
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Versão </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.version; else loading">{{element.version}}</div>
          <ng-template #loading>
            <mat-spinner diameter="20"></mat-spinner>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="table-row" tabindex="1" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  <ng-template #elseBlock>Não há serviços listados</ng-template>
</div>
