import { Component, Inject, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { VehicleService } from 'src/app/service/model/vehicle.service';
import { VehicleModel } from 'src/app/model/vehicle.model';
import { MatDialog } from '@angular/material/dialog';
import { EditComponent } from '../../../edit-component';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { Permission } from 'src/app/model/enums.enum';
import { EntityCacheService } from '../../../../service/model/entity.cache.service';
import { MAP_PAGE } from 'src/app/common/constants';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['../../../../app.component.scss', './vehicle-edit.component.scss']
})
export class VehicleEditComponent extends EditComponent implements OnInit {
  
  
  constructor(logger:                     NGXLogger,
              vehicleService:             VehicleService,
              dialog:                     MatDialog,
              toastr:                     ToastrService,
              protected storageService:   StorageService,
              public entityCacheService:  EntityCacheService,
              authorizationService: AuthorizationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, vehicleService, dialog, environment.VEHICLES_MODEL_LABEL, environment.VEHICLES_TITLE_LABEL, storageService,
          'vehicles-edit', environment.VEHICLES_GROUP_LABEL, toastr, authorizationService, goldenLayout, container);
    this.logger.debug('VehicleEditComponent.constructor()');
  }

  createData(options?) {
    this.logger.debug('VehicleEditComponent.createData()');

    this.model = new VehicleModel();
    this.view = {};
  }

  clearCopyData(){
    super.clearCopyData();

    this.model['plate'] = null;
  }

  loadFormOptionsData() {
    this.logger.debug('VehicleEditComponent.loadFormOptionsData()');
    this.entityCacheService.loadCompanies(this.loadingListService);
  }

  mapModelToView() {
    super.mapModelToView();

    if (this.view['plate']) {
      this.glUpdateTabTitle(this.modelName + ': ' + this.view['plate']);
    }
  }

  canEdit(): boolean {
    if (!this.model) return false;

    if (!this.authorizationService.userHasPermission(Permission.CREATE_EDIT_VEHICLE)){
      return false
    }

    return true;
  }

  isRequiredFieldFilled(): boolean{
    if (!this.view['plate'] ||
        !this.view['company']) {
      return false;
    }
    return true;
  }

  getRequiredFieldNames(): string[] {
    let names: string [] = [];
    if (!this.view['plate']) names.push('Placa');
    if (!this.view['company']) names.push('Empresa');
    return names;
  }

  onHistoricalTrackingClick() {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingVehicle: this.model});
  }
}
