<div mat-dialog-title>
  <span>{{data.title}} </span>
  <button mat-icon-button mat-dialog-close [matTooltip]="data.cancelLabel ? data.cancelLabel : 'Cancelar'" *ngIf="!data.hideCancelButton" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="m-4">
  <span class="mb-2 d-block">
    {{data.msg}}
  </span>

  <div *ngIf="lines">
    <div *ngFor="let line of lines">
      <span class="d-block">{{line}} </span> 
    </div>
  </div>
  
  <div class="p-2 d-block bg-shade-100 rounded" *ngIf="data.showIdentifier">
    <span > <span style="opacity: .5;">&#9679;</span> "{{data.identifier}}"</span>
  </div>
</div>

<mat-dialog-actions align="end">  
  <button mat-raised-button mat-dialog-close *ngIf="data.showDiscardButton" [matTooltip]="data.discardTooltip"> {{ data.discardLabel ? data.discardLabel : "Descartar" }}</button>
  <button mat-raised-button [mat-dialog-close]=true *ngIf="!data.hideOkButton" [matTooltip]="data.okTooltip">{{data.okLabel}}</button>
  <button mat-raised-button [mat-dialog-close]=false mat-dialog-close *ngIf="!data.hideCancelButton" [matTooltip]="data.cancelTooltip"> {{ data.cancelLabel ? data.cancelLabel :
    "Cancelar" }}</button>
</mat-dialog-actions>