import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlankComponent } from './pages/base-golden-panel/blank/blank.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', component: BlankComponent, canActivate: [ MsalGuard ] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
