import {UserModel} from './user.model';
import { OperationModel } from './operation.model';
import { OperationType, OperationStatus } from './enums.enum';
import LanguageUtils from '../service/util/language-utils';

export class VerificationModel extends OperationModel {
  analyst:          UserModel;
  bandRelease:      string;  
  eventId:          string; // TODO: Trocar nome para eventIdentifier (não é event.id)
  sentDate:         number;
  createdAt:        number;
    
  // Resultados
  startDateMovement : number; // Data/Hora Inicio de Percurso
  startDateInspection : number; // Data/Hora Inicio de Inspeção
  endDateInspection : number; //Data/Hora fim  de Inspeção 
  evaluation: string;    
  kmPlanned : number; 
  kmExecuted : number;
  endDateVerification : number; // Data/Hora fim da Verificação
  receivedDateApp : number; // Data/Hora App recebida
  eventCreatedAt : number; // Data/hora do evento (eventId)
  resultObservations: string;
  managementReport: string;
  midia             :   string; 
  dateManagementReport : number;
  dateSendEndVerification: number; // data/hora fim da verificação no aplicativo
 
  // usados apenas no front-end: 
  taccpd :string; // sentDate - eventCreatedAt
  tacs : string;  // sentDate - createdAt
  tapd : string;  // dateSendEndVerification - sentDate
  tta : string;   // dateSendEndVerification - eventCreatedAt
  terf : string;  // dateSendEndVerification - dateManagementReport
  tvi : string;   // endDateInspection - startDateInspection
  tsi : string;   // startDateInspection - sentDate
  trivi : string;  // startDateMovement - sentDate

  pointsDescription:    string; // usado somente na exportação de eventos
  requestedPoints;      number; // usado somente na exportação de eventos
  visitedPoints:        number; // usado somente na exportação de eventos
  pointsDiff:           number; // usado somente na exportação de eventos
  noAccesVerificationPoints: number; //usado somente na exportação de eventos  

  constructor() {
    super();
    this.type = OperationType.EVENT_VERIFICATION;
    this.status = OperationStatus.PLANNED;
  }
  
  static addLanguageMap(){
    LanguageUtils.languageMap['analyst'] = 'Analista CCPD';
    LanguageUtils.languageMap['eventId'] = 'Evento Associado';
    LanguageUtils.languageMap['sentDate'] = 'Data de Envio';
    LanguageUtils.languageMap['bandRelease'] = 'Liberação de Faixa';
    LanguageUtils.languageMap['evaluation'] = 'Avaliação';
    LanguageUtils.languageMap['resultObservations'] = 'Notas';
    LanguageUtils.languageMap['managementReport'] = 'Informe Gerencial';
    LanguageUtils.languageMap['evaluation'] = 'Avaliação';
  }
}
