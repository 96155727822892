import { PatrolTeamModel } from './../../../../model/patrolteam.model';
import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { PatrolTeamService } from 'src/app/service/model/patrol.team.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { RegistrationType, ServiceTypeDescription, ShiftDescription } from 'src/app/model/enums.enum';
import { AuthorizationService } from '../../../../service/authorization/authorization.service';
import { StorageService } from '../../../../service/storage-service';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { ESP, MAP_PAGE, SORT_NAME_ASC } from 'src/app/common/constants';
import { UserService } from 'src/app/service/model/user.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RegistrationListComponent } from '../../registration-list-component';
import { SAVE_DATA_PREFIX } from '../../../../common/constants';

@Component({
  selector: 'app-patrol-team-list',
  templateUrl: './patrol-team-list.component.html',
  styleUrls: ['../../../../app.component.scss', '../../../list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatrolTeamListComponent extends RegistrationListComponent implements OnInit, OnDestroy {

  shiftDescription = ShiftDescription;
  private userDataSubscription: Subscription;

  serviceTypeDescription = ServiceTypeDescription;

  constructor(logger:                               NGXLogger,
              protected patrolTeamService:          PatrolTeamService,
              dialog:                               MatDialog,
              protected toastr:                     ToastrService,
              public authorizationService:          AuthorizationService,
              protected storageService:             StorageService,
              protected userService:                UserService,
              protected changeDetector:                      ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, patrolTeamService, dialog, 'patrol-teams',  environment.PATROL_TEAM_GROUP_LABEL,  environment.PATROL_TEAM_TITLE_LABEL, environment.PATROL_TEAM_MODEL_LABEL, storageService, changeDetector,
    toastr, goldenLayout, container);
      logger.debug('PatrolTeamListComponent.constructor()');
      this.subscribeToSaveCompaniesData();
      this.subscribeToSaveUsersData();
      this.subscribeToSaveVehiclesData();
      this.registrationType = RegistrationType.PATROL_TEAM;
      this.sortOptions = SORT_NAME_ASC;
    }

  ngOnInit() {
    super.ngOnInit();

    this.logger.debug('PatrolTeamListComponent.ngOnInit()');
    this.loadRecordsFromServer();
    this.displayedColumns = ['select',
                             'name',
                             'company.name',
                             'company.placement.name',
                             'serviceType',
                             'shift',
                             'users.0.name',
                             'users.1.name',
                             'users.2.name',
                             'vehicle.plate',
                             'patrolCoordinator.name'
                             ];
  }

  ngOnDestroy(): void {
      this.userDataSubscription?.unsubscribe();
  }

  public subscribeToSaveCompaniesData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'companies-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  public subscribeToSaveUsersData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'users-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  public subscribeToSaveVehiclesData() {
    // A lista escuta a edição correspondente para saber se algo foi modificado
    this.glSubscribeEvent(SAVE_DATA_PREFIX + 'vehicles-edit', (data) => {
      this.loadRecordsFromServer();
    });
  }

  loadRecordsFromServer(): void {
    this.logger.debug('ListComponent.PatrolTeamListComponent()');
    /**Verifica se o perfil do usuário logado  possui a restrição "Acesso somente a dados de Lotações específicas" da seção Web.
     * Em caso positivo, somente as verificações que pertencem à mesma Lotação do usuário */

    this.filterModel.placements = this.storageService.getSpecificPlacementIds()
    super.loadRecordsFromServer();
  }

  onHistoricalTrackingClick(patrolTeam: PatrolTeamModel ) {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingTeam: patrolTeam});
  }

  onHistoricalTrackingManyClick(){
    let historicalTrackingTeamList = [];
    this.selection.selected.forEach( (patrolTeam: PatrolTeamModel) => {
      historicalTrackingTeamList.push(patrolTeam);
    });

    // Note que depois de abrir o mapa pode descobrir que não tem sinais
    this.glOpenContainer(MAP_PAGE, {historicalTrackingTeamList: historicalTrackingTeamList});
  }

  getCompany(element){
    let patrolTeam = element as PatrolTeamModel;
    if (patrolTeam.company){
      return patrolTeam.company;
    }
    else {
      if (patrolTeam.users &&
          patrolTeam.users[0] &&
          patrolTeam.users[0].company) {
        return patrolTeam.users[0].company; // backward compatibility code - para equipes que ainda não foram atualizadas
      }
    }
  }

  protected getStringEntityForFilter(team: PatrolTeamModel): string {
    const teamName = super.lowerAndTrimText(team.name);
    const vehiclePlate = (team.vehicle) ? super.lowerAndTrimText(team.vehicle.plate) : '';
    const serviceType = team.serviceType? super.lowerAndTrimText(ServiceTypeDescription[team.serviceType]): '';
    const shift = (team.shift) ? super.lowerAndTrimText(ShiftDescription[team.shift]) : '';
    let userNames = '';
    if (team.users) {
      for (let i = 0; i < team.users.length; i++) {
        if (team.users[i]) {
          userNames = userNames + super.lowerAndTrimText(this.getUserNameLoginTitle(team.users[i])) + ESP;
        }
      }
    }
    const coordinator = (team.patrolCoordinator) ? super.lowerAndTrimText(this.getUserNameLoginTitle(team.patrolCoordinator)) : '';
    const company = this.getCompany(team);
    const companyName = company? super.lowerAndTrimText(company.name): '';
    const placementName = company && company.placement ? super.lowerAndTrimText(company.placement.name): '';
    return teamName + ESP + vehiclePlate + ESP + serviceType + ESP + shift + ESP + coordinator +  ESP + companyName + ESP + placementName + ESP + userNames ;
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'company.name': {
        const val = this.getCompany(item);
        return val ? this.lowerAndTrimText(val.name) : '';
      }      
      case 'company.placement.name': {
        const val = this.getCompany(item);
        return val && val.placement ? this.lowerAndTrimText(val.placement.name) : '';
      }      
      case 'serviceType': {
        const val = ServiceTypeDescription[(item as PatrolTeamModel).serviceType];
        return val ? val.name : '';
      }      
      default: return super.getPropertyByPath(item, property);
    }
  }
}
