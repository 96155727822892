import { Inject, OnInit, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponentHost, GoldenLayoutComponent, GoldenLayoutContainer } from 'ngx-golden-layout';
import { OperationType, OperationStatus, VerificationBandReleaseDescription, Permission, UserType, ServiceTypeDescription } from 'src/app/model/enums.enum';
import { VerificationModel } from 'src/app/model/verification.model';
import { RouteGeographicalService } from 'src/app/service/model/route-geographical-service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { SAVE_DATA_PREFIX, MOUSE_ENTER_TIME, EvaluationValues } from 'src/app/common/constants';
import { ConfirmationDialogComponent } from '../../../general/confirmation-dialog/confirmation-dialog.component';
import { saveAs } from 'file-saver/dist/FileSaver';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef } from '@angular/core';
import { VerificationService } from '../../../service/model/verification.service';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { OperationEditComponent } from '../../operation-edit.component';
import { PointService } from '../../../service/model/point.service';
import { LatLongMask, LatLongPattern } from '../../../common/constants';
import FieldUtils from 'src/app/service/util/field-utils';
import DateUtils from 'src/app/service/util/date-utils';
import { MarkersService } from '../../../service/model/markers.service';
import { MarkerModel } from '../../../model/marker.model';
import { MarkerType, SourceType, EventSituationDescription } from '../../../model/enums.enum';
import { v4 as uuidv4 } from 'uuid';
import { first, map } from 'rxjs/operators';
import { ChannelService } from '../../../service/model/channel.service';
import { ChannelModel } from '../../../model/channel.model';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { EntityCacheService } from 'src/app/service/model/entity.cache.service';
import { EventService } from '../../../service/model/event.service';
import { EventFilterModel } from '../../event/event-filter/event.filter.model';
import { EventModel } from '../../../model/event.model';
import { OperationModel } from 'src/app/model/operation.model';
import { ArchivedType } from 'src/app/general/filter-component/filter.model';

@ProfileClassToConsole()
@Component({
  selector: 'app-verification-edit',
  templateUrl: './verification-edit.component.html',
  styleUrls: ['../../../app.component.scss', './verification-edit.component.scss']
})
export class VerificationEditComponent extends OperationEditComponent implements OnInit, OnDestroy {

  private CANAL168 = 0
  private CNCL = 2
  private MANAGEMENT_REPORT = 3
  private OTHER = 4
  
  // usado para apresentar as datas enquanto não foram calculadas
  standadFormatTime:string = "00:00:00"

  verificationBandReleaseDescription = VerificationBandReleaseDescription;
  evaluationValues = EvaluationValues;

  latLongPattern = LatLongPattern;
  latLongMask = LatLongMask;

  timeOutCanSend;
  
  isHiddenButtonCreateEditVerification : boolean;
  isHiddenButtonDeleteVerification : boolean;
  isHiddenButtonEditForUser : boolean;
  isHiddenInformeGerencial: boolean;

  verificationEvent: EventModel;

  originalPatrolTeamId: string; // para detectar mudanças na equipe

  constructor(logger:                                   NGXLogger,
              public entityCacheService:                EntityCacheService,
              public singleDataCacheService:            SingleDataCacheService,
              protected eventService:                   EventService,
              protected verificationService:            VerificationService,
              protected routeGeographicalObjectService: RouteGeographicalService,
              protected dialog:                         MatDialog,
              protected storageService:                 StorageService,
              private markerService:                    MarkersService,
              private channelService:                   ChannelService,
              toastr:                                   ToastrService,
              protected changeDetector:                 ChangeDetectorRef,
              authorizationService:                     AuthorizationService,
              protected pointService:                   PointService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, OperationType.EVENT_VERIFICATION, verificationService, dialog,
          environment.VERIFICATION_MODEL_LABEL, environment.VERIFICATION_TITLE_LABEL, storageService,
          'verifications-edit', environment.VERIFICATION_GROUP_LABEL, entityCacheService, 
          routeGeographicalObjectService, changeDetector,
          toastr, authorizationService, pointService, goldenLayout, container);
  }

  ngOnInit(){
    super.ngOnInit();
    this.logger.debug('VerificationEditComponent.ngOnInit()');
    console.log("David, o this.loggedUserProfile é", this.loggedUserProfile)
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.logger.debug('VerificationEditComponent.ngOnDestroy()');
  }

  afterLoadData(options){
    this.model['type'] = OperationType.EVENT_VERIFICATION;
    this.originalPatrolTeamId = this.model['patrolTeam']? this.model['patrolTeam'].id: null;
    this.updateAttachmentData(); // carrega os arquivos anexo da operação
  }

  loadFormOptionsData() {
    super.loadFormOptionsData();

    this.logger.debug('EventEditComponent.loadFormOptionsData()');

    const metadatas: string[] = [
      "Valve",
      "Evaluation",
      "Band",
      "Duct",
      "PipeStretch"
    ];
    
    this.singleDataCacheService.loadMulipleValues(metadatas, this.loadingListService);
  }

  createData(options?) {
    this.logger.debug('VerificationEditComponent.createData()');

    this.model = new VerificationModel();
    this.view = {};

    if (options && options.event) {
      this.model['eventId'] = this.view['eventId'] = options.event.identifier;
      this.model['band'] = this.view['band'] = options.event?.band != null ? options.event.band : null;
      this.model['stretch'] = this.view['stretch'] = options.event?.stretch != null ? options.event.stretch : null;
      this.model['duct'] = this.view['duct'] = options.event?.duct != null ? options.event.duct : null;
      this.model['valve'] = this.view['valve'] = options.event?.valve != null ? options.event.valve : null;
      this.model['km'] = this.view['km'] = options.event?.targetPointKM != null ? options.event.targetPointKM : null;
      this.model['latLong'] = this.view['latLong'] = options.event?.targetPointLatLong != null ? options.event.targetPointLatLong : null;
      this.model['stretchStartKM'] = this.view['stretchStartKM'] = options.event?.stretchStartKM != null ? options.event.stretchStartKM : null;
      this.model['stretchEndKM'] = this.view['stretchEndKM'] = options.event?.stretchEndKM != null ? options.event.stretchEndKM : null;
      this.model['stretchStartLatLong'] = this.view['stretchStartLatLong'] = options.event?.stretchStartLatLong != null ? options.event.stretchStartLatLong : null;
      this.model['stretchEndLatLong'] = this.view['stretchEndLatLong'] = options.event?.stretchEndLatLong != null ? options.event.stretchEndLatLong : null;

    }

    this.createOperationData();

    /** Tem que ir após createOperationData */
    if (options && options.patrolTeam) { // options é usado ao criar a verificação a partir de uma equipe no mapa
      this.model['patrolTeam'] = this.view['patrolTeam'] = options.patrolTeam;
      this.model['company'] = this.view['company'] = options.patrolTeam.company;
      this.model['placement'] = this.view['placement'] = options.patrolTeam.company?.placement;
      this.model['serviceType'] = this.view['serviceType'] = options.patrolTeam.serviceType;
    }

    this.glUpdateTabTitle(this.modelName + ': [NOVA]');
  }

  mapViewToModel() {
    super.mapViewToModel();

    this.logger.debug('VerificationEditComponent.mapViewToModel()');

    if (this.view['sentDate']) {
      // Mesmo sendo read-only precisa desafazer a conversão feita em mapModelToView
      const sentDate = DateUtils.stringDateToTimestamp(this.view['sentDate']);
      this.model['sentDate'] = sentDate;
    }

    if (this.view['createdAt']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['createdAt']);
      this.model['createdAt'] = createdDate;
    }
    
    if (this.view['startDateMovement']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['startDateMovement']);
      this.model['startDateMovement'] = createdDate;
    }
    
    if (this.view['startDateInspection']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['startDateInspection']);
      this.model['startDateInspection'] = createdDate;
    }
    
    if (this.view['endDateInspection']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['endDateInspection']);
      this.model['endDateInspection'] = createdDate;
    }

    if (this.view['dateSendEndVerification']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['dateSendEndVerification']);
      this.model['dateSendEndVerification'] = createdDate;
    }

    if (this.view['receivedDateApp']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['receivedDateApp']);
      this.model['receivedDateApp'] = createdDate;
    }

    if (this.view['dateSituationFound']) {      
      const createdDate = DateUtils.stringDateToTimestamp(this.view['dateSituationFound']);
      this.model['dateSituationFound'] = createdDate;
    }
  }

  mapModelToView() {
    super.mapModelToView();

    this.logger.debug('VerificationEditComponent.mapModelToView()');

    if (this.model['sentDate']) {
      let sentMoment = moment(this.model['sentDate']);
      this.view['sentDate'] = sentMoment.format('DD/MM/yyyy HH:mm');
      
      this.view['sentDateDay'] = moment(this.model['sentDate']).format('DD/MM/yyyy');
      this.view['sentDateHour'] = moment(this.model['sentDate']).format("HH:mm");
    }

    if (this.model['createdAt']) {
      let createdMoment = moment(this.model['createdAt']);
      this.view['createdAt'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }

    if (this.model['startDateMovement']) {
      let createdMoment = moment(this.model['startDateMovement']);
      this.view['startDateMovement'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }

    if (this.model['startDateInspection']) {
      let createdMoment = moment(this.model['startDateInspection']);
      this.view['startDateInspection'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }

    if (this.model['endDateInspection']) {
      let createdMoment = moment(this.model['endDateInspection']);
      this.view['endDateInspection'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }

    if (this.model['dateSendEndVerification']) {
      let createdMoment = moment(this.model['dateSendEndVerification']);
      this.view['dateSendEndVerification'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }

    if (this.model['receivedDateApp']) {
      let createdMoment = moment(this.model['receivedDateApp']);
      this.view['receivedDateApp'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }
    
    //taccpd 
    if(this.model['eventCreatedAt'] && this.model['sentDate'])  {
      let sendDateMoment = moment(this.model['sentDate']);
      let eventMoment = moment(this.model['eventCreatedAt']);
      const duration  = moment.duration(sendDateMoment.diff(eventMoment));      
      this.view['taccpd'] = this.secondsToHms(duration.asSeconds());
    }

    //tacs 
    if(this.model['createdAt'] && this.model['sentDate'])  {
      let createdAtMoment = moment(this.model['createdAt']);
      let sendDateMoment = moment(this.model['sentDate']);
      const duration  = moment.duration(sendDateMoment.diff(createdAtMoment));      
      this.view['tacs'] = this.secondsToHms(duration.asSeconds());
    }

    // tapd
    if(this.model['sentDate'] && this.model['dateSendEndVerification'])  {
      let endMoment = moment(this.model['dateSendEndVerification']);
      let sendDateMoment = moment(this.model['sentDate']);
      const duration  = moment.duration(endMoment.diff(sendDateMoment));       
      this.view['tapd'] = this.secondsToHms(duration.asSeconds());
    }

    //tta
    if(this.model['dateSendEndVerification'] && this.model['eventCreatedAt'])  {
      let endMoment = moment(this.model['dateSendEndVerification']);
      let eventMoment = moment(this.model['eventCreatedAt']);
      const duration  = moment.duration(endMoment.diff(eventMoment));
      this.view['tta'] = this.secondsToHms(duration.asSeconds());
    }

    if (this.model['dateSituationFound']) {
      let createdMoment = moment(this.model['dateSituationFound']);
      this.view['dateSituationFound'] = createdMoment.format('DD/MM/yyyy HH:mm');
    }

    //tvi
    if(this.model['startDateInspection'] && this.model['endDateInspection'])  {
      let startInspMoment = moment(this.model['startDateInspection']);
      let endInspMoment = moment(this.model['endDateInspection']);
      const duration  = moment.duration(endInspMoment.diff(startInspMoment));      
      this.view['tvi'] = this.secondsToHms(duration.asSeconds());
    }

    //tsi
    if(this.model['startDateInspection'] && this.model['sentDate'])  {
      let startInspMoment = moment(this.model['startDateInspection']);
      let sendMoment = moment(this.model['sentDate']);
      const duration  = moment.duration(startInspMoment.diff(sendMoment));      
      this.view['tsi'] = this.secondsToHms(duration.asSeconds());
    }

    //trivi
    if(this.model['startDateMovement'] && this.model['sentDate'])  {
      let startMovementMoment = moment(this.model['startDateMovement']);
      let sendMoment = moment(this.model['sentDate']);
      const duration  = moment.duration(startMovementMoment.diff(sendMoment));      
      this.view['trivi'] = this.secondsToHms(duration.asSeconds());
    }
  }

  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário
    this.replaceModelViewData(data, 'endDateVerification'); // Não converter como data
    this.replaceModelViewData(data, 'eventCreatedAt'); // Não converter como data
    this.replaceModelViewDateData(data, 'createdAt');
    this.replaceModelViewDateData(data, 'sentDate');
    this.replaceModelViewDateData(data, 'startDateMovement');
    this.replaceModelViewDateData(data, 'startDateInspection');
    this.replaceModelViewDateData(data, 'endDateInspection');
    this.replaceModelViewData(data, 'midia');
    this.replaceModelViewData(data, 'kmPlanned');
    this.replaceModelViewData(data, 'kmExecuted');
    this.replaceModelViewDateData(data, 'dateSendEndVerification');
    this.replaceModelViewDateData(data, 'dateSituationFound');
    this.replaceModelViewData(data, 'dateManagementReport'); // Não converter como data
    this.replaceModelViewDateData(data, 'receivedDateApp');
  
    super.replaceRunTimeData(data);
  }

  enterCanSend() {
    this.timeOutCanSend = setTimeout(() => {
      if (!this.canSend()) {
        if (!this.model || !this.model.id) {
          this.toastr.info('Verificação ainda não foi criada. Salve antes de Solicitar.');
          return;
        }

        let requiredFields = '';

        if (!this.view['analyst']) {
          requiredFields = requiredFields + '<li>Analista CCPD</li>';
        }

        if (!this.view['placement']) {
          requiredFields = requiredFields + '<li>Lotação</li>';
        }

        if (!this.view['company']) {
          requiredFields = requiredFields + '<li>Empresa</li>';
        }

        if (!this.view['serviceType']) {
          requiredFields = requiredFields + '<li>Tipo de Serviço</li>';
        }

        if (!this.view['patrolTeam'] || !this.view['patrolTeam'].name) {
          requiredFields = requiredFields + '<li>Equipe</li>';
        }

        if (!this.hasNonBasePoint()) {
          requiredFields = requiredFields + '<li>1 ponto que não seja base</li>';
        }

        if (!this.isInspectionsFilled()) {
          requiredFields = requiredFields + '<li>Pontos sem Faixa ou KM</li>';
        }

        if (requiredFields != '') {
          this.toastr.info('Campo(s) obrigatório(s) para Solicitar não preenchido(s):<br>' + requiredFields);
          return;
        }

        if (this.viewIsChanged()) {
          this.toastr.info('Verificação modificada. Salve antes de Solicitar.');
          return;
        }

        this.timeOutCanSend = setTimeout(() => {
          this.toastr.info('Verificação já foi solicitada.');
          this.timeOutCanSend = null;
        }, 2 * MOUSE_ENTER_TIME);
        return;
      }
      this.timeOutCanSend = null;
    }, MOUSE_ENTER_TIME);
  }

  leaveCanSend() {
    if (this.timeOutCanSend) {
      clearTimeout(this.timeOutCanSend);
      this.timeOutCanSend = null;
    }
  }

  canSend(): boolean {
    return !!this.model && !!this.model.id && // Só pode solicitar se estiver criada
           (this.view['patrolTeam'] && this.view['patrolTeam'].name) && // Só pode solicitar se possuir os seguintes campos
           this.view['analyst'] &&
           this.view['placement'] &&
           this.view['company'] &&
           this.view['serviceType'] &&
           this.hasNonBasePoint() && // Tem que ter ao meno 1 ponto
           this.isInspectionsFilled() && // Os pontos precisam ter Faixa e KM
           !this.isSent() && // Só pode solicitar 1 vez
           !this.viewIsChanged(); // Só pode solicitar se estiver Salvo
  }

  isSent(): boolean {
    return this.view['status'] != OperationStatus.PLANNED;
  }

  hasArchivalRequirements(model: VerificationModel){
    if(model.status != OperationStatus.FINISHED){ // Verifica se o status é Concluído
      return false;
    }

    if(this.authorizationService.isControl()){ // Se for perfil ANALYSIS_CCPD, verifica se os eventos foram criados por ele
      if(!model.analyst || model.analyst.id !== this.loggedUser.id.toString()){
        return false;
      }
    }

    return true;
  }

  onExportClick() {
    this.loadingListService.loadingOn();
    (<VerificationService>this.entityService).exportVerification(this.model as VerificationModel).pipe(first()).subscribe(response => {
      this.loadingListService.loadingOff();

      let filename = `${(this.model as VerificationModel).identifier}.zip`;
      filename = filename.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      saveAs(response, filename);
    },
    error =>{
      this.loadingListService.loadingOff();
      this.logger.error(error);
    });
  }

  operationEdited(operation: OperationModel){
    const viewPatrolTeamId = this.view['patrolTeam']? this.view['patrolTeam'].id: null;
    if (operation.status != OperationStatus.PLANNED && this.originalPatrolTeamId != viewPatrolTeamId) {
      this.originalPatrolTeamId = viewPatrolTeamId;
      this.sendSentNotification(true);
    }
  }

  sendSentNotification(patrolTeamChanged: boolean) {
    const formElements = new Map<string, object>();

    const marker = this.createTeamSentMarker();
    if (patrolTeamChanged) marker.message += " (Troca de Equipe)";
    this.logger.info('VerificationEditComponent.sendSentNotification - Mensagem de Verificação enviada: ', marker);

    this.markerService.create(marker, formElements).pipe(first()).subscribe((marker: MarkerModel) => {
      this.logger.info('VerificationEditComponent.sendSentNotification - envio OK');
    }, error => this.logger.error(error));
    
    this.loadVerificationEvent().then( () => {
      const newManagerialMarker = this.createManagerialSentMarker();
      if (patrolTeamChanged) newManagerialMarker.message += " (Troca de Equipe)";
      this.logger.info('VerificationEditComponent.sendSentNotification - Mensagem de Verificação enviada para canal gerencial: ', newManagerialMarker);
      this.markerService.create(newManagerialMarker, formElements).pipe(first()).subscribe((marker: MarkerModel) => {
        this.logger.info('VerificationEditComponent.sendSentNotification - envio gerencial OK');
      }, error => this.logger.error(error));
    });
  }

  /**
   * Altera o status da verificação para PLANNED para que ela seja visivel no mobile app.
   */
  onSendClick(){
    this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      data: {
        msg: "ATENÇÃO: após solicitada, a verificação ficará disponível para os profissionais e esta operação não poderá ser desfeita. Confirma a solicitação?",
        title: "Solicitar Verificação",
        okLabel: "Confirmar",
      },
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.verificationService.updateStatus(<VerificationModel>this.model, OperationStatus.SENT).pipe(first()).subscribe( (updatedVerification : VerificationModel) => {
          if(updatedVerification){
            this.model = updatedVerification;
            this.mapModelToView();
            this.backupInitialView();
            this.toastr.info('Verificação solicitada com sucesso!');
            this.glEmitEvent(SAVE_DATA_PREFIX + 'verifications-edit', {entity: this.model});

            this.sendSentNotification(false);
          }
        }, error => {
          this.logger.error(error);
          this.toastr.error('Não foi possível solicitar a verificação.');
        });
      }
    });
  }

  private loadVerificationEvent(){
    const filterModel = new EventFilterModel();
    filterModel.identifier = this.view['eventId'];
    filterModel.archived = ArchivedType.ALL;
    return this.eventService.loadFilteredListFromRestApi(null, null, null, filterModel).pipe(first(), map((events: EventModel[]) =>{
      if(!events || events.length === 0) 
      {
        this.toastr.error("Evento da Verificação não encontrado!");
        return;
      }
      this.verificationEvent = events[0];
    })).toPromise();
  }

  private createTeamSentMarker(): MarkerModel{
    let markerMessage: MarkerModel = new MarkerModel();

    const priorityName = this.model['priority']? 'PRIORITÁRIA ': '';
    let channel = this.getTeamChannel(this.view['patrolTeam'].id);

    markerMessage.message = 'Verificação '+ priorityName+ this.view['identifier'] + ' enviada. Verifique a lista de operações.';
    markerMessage.patrolTeamId = this.view['patrolTeam'].id;
    markerMessage.operationId = this.view['id'];
    markerMessage.operationType = this.operationType;
    markerMessage.operationIdentifier = this.view['identifier'];
    markerMessage.markerType = MarkerType.VERIFICATION_SENT_MESSAGE;
    markerMessage.sourceType = SourceType.WEB_APP;
    markerMessage.priority = this.model['priority']? true: false;
    markerMessage.location = {lat: 0, lng: 0};
    markerMessage.timestamp = moment().valueOf();
    markerMessage.serverTimestamp = null; // Não temos essa estimativa na Web
    markerMessage.userId = this.loggedUser.id.toString();
    markerMessage.userName = this.loggedUser.login; // Esconde o nome de quem não é profissional em canais de equipe
    markerMessage.channelId = channel.id;
    markerMessage.channelName = channel.name;
    markerMessage.uuid = uuidv4();

    return markerMessage;
  }

  private buildManagerialMessage(channel: ChannelModel, communicationChannel: number): MarkerModel {
    let markerMessage: MarkerModel = new MarkerModel();

    const priorityName = this.model['priority']? 'PRIORITÁRIA ': '';
    let notes = communicationChannel == this.CANAL168 && this.verificationEvent.result.notes != null ? this.verificationEvent.result.notes : '';
    let band = this.view['band'] ? this.view['band'] : "";
    let duct = this.view['duct'] ? this.view['duct'] : "";
    let source = this.verificationEvent.source ? this.verificationEvent.source : '';
    let category = [this.verificationEvent.category168 ? this.verificationEvent.category168 : null, 
                    this.verificationEvent.subcategory168 ? this.verificationEvent.subcategory168 : null];
    category = category.filter(ele => ele!=null && ele!='');
    let type = communicationChannel == this.CANAL168 ? category.join(' - ') : source;
    let startKm = this.view['stretchStartKM'] ? this.view['stretchStartKM'] : null;
    let endKm = this.view['stretchEndKM'] ? this.view['stretchEndKM'] : null;
    let km = (startKm && endKm) ? "Início: " + startKm + " | Fim: " + endKm : this.view['km'] ? this.view['km'] : "";
    let startLatlong = this.view['stretchStartLatLong'] ? this.view['stretchStartLatLong'] : null;
    let endLatlong = this.view['stretchEndLatLong'] ? this.view['stretchEndLatLong'] : null;
    let latlong = (startLatlong && endLatlong) ? "Início: " + startLatlong + " | " + "Fim: " + endLatlong : this.view['latLong'] ? this.view['latLong'] : '';
    let address = [this.verificationEvent.street, this.verificationEvent.city, this.verificationEvent.state];
    address = address.filter(ele => ele!=null && ele!='');
    let local = [latlong, address.join(' - ')];
    local = local.filter(ele => ele!=null && ele!='');
    let product = this.verificationEvent.product ? this.verificationEvent.product : '';
    let situation = this.verificationEvent.situation ? EventSituationDescription[this.verificationEvent.situation] : '';
    let sentDate = (this.view['sentDate']) ? this.view['sentDate'] : '';
    let suspiciousDate = this.verificationEvent.suspiciousDate ? DateUtils.timestampToStringInMinutes(this.verificationEvent.suspiciousDate) : '';
    let company = [this.view['company'] ? this.view['company']['name'] : null, this.view['placement'] ? this.view['placement']['regional'] : null];
    company = company.filter(ele => ele!=null && ele!='');
    let patrolTeam = [this.view['patrolTeam']['serviceType'] ? ServiceTypeDescription[this.view['patrolTeam']['serviceType']] : null, 
                      this.view['patrolTeam'] ? this.view['patrolTeam']['name'] : null];
    patrolTeam = patrolTeam.filter(ele => ele!=null && ele!='');
    let flow = this.verificationEvent.flow ? this.verificationEvent.flow : '';
    let stolenVolume = this.verificationEvent.stolenVolume ? this.verificationEvent.stolenVolume : '';
    let startDate = this.verificationEvent.date ? DateUtils.timestampToStringInMinutes(this.verificationEvent.date) : "";
    let endDate = this.verificationEvent.endDate ? DateUtils.timestampToStringInMinutes(this.verificationEvent.endDate) : "";
    let report = this.view['managementReport'] ? this.view['managementReport'] : '';

    markerMessage.message = 'Verificação '+ priorityName + this.view['identifier'] + (communicationChannel == this.MANAGEMENT_REPORT ? '': ' Enviada')+'\n' +
                            this.verificationEvent.communicationChannel + '\n' +
                            'Tipo: ' + type + '\n' +
                            (communicationChannel == this.CANAL168 ?
                             'Descrição: ' + notes + '\n' : '') +
                            'Data/Horário da Solicitação: ' + sentDate + '\n' +
                            'Faixa: ' + band + '\n' +
                            'Duto: ' + duct + '\n' +
                            'KM: ' + km + '\n' +
                            'Localização: ' + local.join(' | ' ) + '\n' +
                            (communicationChannel == this.CNCL ?
                              'Produto: ' + product + "\n" +
                              'Situação: ' + situation + "\n" +
                              'Data/Horário da Suspeita: ' + suspiciousDate + '\n' +
                              'Data/Horário Início da Despressurização: ' + startDate + '\n' +
                              'Data/Horário Fim da Despressurização: ' + endDate + '\n' +
                              'Vazão: ' + flow + ' m³/h\n' +
                              'Volume: ' + stolenVolume + ' m³\n' : '') +
                            'Empresa: ' + company.join(' - ') + "\n" +
                            'Equipe Acionada: ' + patrolTeam.join(' - ') + '\n' +
                            (communicationChannel == this.MANAGEMENT_REPORT ? 'Informe Gerencial: ' + report : '');
                            
    markerMessage.patrolTeamId = this.view['patrolTeam'].id;
    markerMessage.markerType = MarkerType.MANAGERIAL_MESSAGE;
    markerMessage.sourceType = SourceType.WEB_APP;
    markerMessage.priority = false; // Não é de atenção, mesmo sendo prioritária
    markerMessage.location = {lat: 0, lng: 0};
    markerMessage.timestamp = moment().valueOf();
    markerMessage.serverTimestamp = null; // Não temos essa estimativa na Web
    markerMessage.userId = this.loggedUser.id.toString();
    markerMessage.userName = this.loggedUser.name;
    markerMessage.channelId = channel.id;
    markerMessage.channelName = channel.name;
    markerMessage.uuid = uuidv4();

    return markerMessage;
  }

  private createManagerialSentMarker(): MarkerModel{
    if (!this.verificationEvent){
      this.toastr.error("Evento da Verificação não encontrado! Não é possível enviar a mensagem.");
      return;
    }

    const foundChannel = this.channelService.inMemoryChannelList.find((channel: ChannelModel) => channel.type === "MANAGERIAL");

    if (this.verificationEvent.communicationChannel === "Canal 168") {
      return this.buildManagerialMessage(foundChannel, this.CANAL168);        
    }
    else if (this.verificationEvent.communicationChannel.startsWith("CNCL")) {
      return this.buildManagerialMessage(foundChannel, this.CNCL);        
    }
    else {
      return this.buildManagerialMessage(foundChannel, this.OTHER);        
    }
  }

  private createManagementReportMarker(): MarkerModel{
    if (!this.verificationEvent){
      this.toastr.error("Evento da Verificação não encontrado! Não é possível enviar a mensagem.");
      return;
    }
    
    const foundChannel = this.channelService.inMemoryChannelList.find((channel: ChannelModel) => channel.type === "MANAGERIAL");
    return this.buildManagerialMessage(foundChannel, this.MANAGEMENT_REPORT);        
  }

  getTeamChannel(patrolTeamId: string): ChannelModel {
    let channel: ChannelModel = this.channelService.getChannelIdByReferenceId(patrolTeamId);
    if (!channel) {
      this.logger.error("Canal não encontrado para Equipe de id " + patrolTeamId);
    }
    return channel;
  }

  onPasteLatLong(event: ClipboardEvent, name: string){
    let pastedValue = FieldUtils.pasteLatLong(event, this.view[name]);
    if (pastedValue != null) {
       this.view[name] = pastedValue;
       return true;
    }
    return false;
  }

  /** Método que converte segundos para formato de dias, horas , minutos e segundos */
  secondsToHms(seconds) {
    seconds = Number(seconds);
    //var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60 % 60);

    var hDisplay = "00";
    var mDisplay = "00"
    var sDisplay = "00"
    hDisplay = h > 0 && h < 10 ? "0"+ h  : (h <= 0 ? "00" : h+"") ;
    mDisplay = m > 0 && m < 10 ? "0" + m  : (m <= 0 ? "00" : m+"") ;
    sDisplay = s > 0 && s < 10  ? "0" + s : (s <= 0 ? "00" : s+"");
    return  hDisplay+ ":" + mDisplay +":"+ sDisplay;
  }

  clearCopyData(): void {
    super.clearCopyData();
    // Deleta os dados dos resultados ( se houver )
    this.model['startDateMovement'] = null;
    this.model['startDateInspection'] = null;
    this.model['endDateInspection'] = null;
    this.model['midia'] = null;
    this.model['evaluation'] = null;
    this.model['kmPlanned'] = null;
    this.model['kmExecuted'] = null;
    this.model['dateSendEndVerification'] = null;
    this.model['receivedDateApp'] = null;
    this.model['dateSituationFound'] = null;
    this.model['eventCreatedAt'] = null;
    this.model['resultObservations'] = null;
    this.model['managementReport'] = null;
    this.model['sentDate'] = null;
    this.model['createdAt'] = null;
  }
  
  isRequiredFieldFilled(): boolean {
    return true; // Para verificações não tem obrigatórios para salvar, apenas para solicitar
  }

  isVerificationDeletionPermitted() {
    return this.authorizationService.isVerificationDeletionAllowed(<VerificationModel>this.model);
  }

  checkPermissions(){
    this.isHiddenButtonCreateEditVerification = !this.authorizationService.userHasPermission(Permission.CREATE_EDIT_VERIFICATIONS);
    this.isHiddenButtonDeleteVerification = !this.authorizationService.userHasPermission(Permission.DELETE_VERIFICATIONS);
    this.isHiddenButtonEditForUser = !this.authorizationService.userHasPermission(Permission.CREATE_EDIT_VERIFICATIONS);
    this.isHiddenInformeGerencial = this.authorizationService.userHasPermission(Permission.VIEW_INFORME_GERENCIAL_VERIFICATIONS);
    console.log('Carregou as permissões')
  }  

  sendManagementReport() {
    this.openConfirmSaveOpAndSendReportDialog().then(result =>{
      let resp = result.toString();
      if(resp == 'true'){
        this.view['dateManagementReport'] = moment().valueOf();
        this.backupModel = this.copyModel(this.model);
        this.mapViewToModel();
        this.saveEditedModel(); // só verificações salvas podem enviar reporte gerencial

        const formElements = new Map<string, object>();
        this.loadVerificationEvent().then( ()=>{
          const newManagerialMessage = this.createManagementReportMarker();
          this.logger.info('VerificationEditComponent.sendManagementReport - Mensagem de Verificação enviada para canal gerencial: ', newManagerialMessage);
          this.markerService.create(newManagerialMessage, formElements).pipe(first()).subscribe((marker: MarkerModel) => {
            this.logger.info('VerificationEditComponent.sendManagementReport - envio OK');
            this.toastr.info('Informe Gerencial enviado com sucesso');
          }, error => {
            this.logger.error(error);
            this.toastr.error('Informe Gerencial falhou no envio');
          });
        });
      }
    });
  }

  openConfirmSaveOpAndSendReportDialog(): Promise<any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      panelClass: 'sipd-modal',
      data:{
        msg: 'ATENÇÃO: Ao enviar o informe gerencial, será salva a Verificação, confirma?',
        title: 'Salvar Verificação e enviar Informe Gerencial',
        cancelLabel: 'Cancelar',
        okLabel: 'Confirmar'        
      }
    }).afterClosed().toPromise().then( result => {
      return Promise.resolve(result);
    });
  }

  onHistoricalTrackingClick(){
    if(this.model['sendDate'] != null && this.model['startDate'] == null)
      this.model['startDate'] = this.model['sentDate'];
    
      super.onHistoricalTrackingClick();
  }
}