import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { EventStatusDescription, EventStatus, EventValidationDescription, EventValidation, UserType, FilterByAssociatedEvent, MarkerType, ResultOccurrenceDescription, PermisionRuleOption, Permission } from 'src/app/model/enums.enum';
import { EventModel } from 'src/app/model/event.model';
import { EventService } from 'src/app/service/model/event.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { EventFilterModel } from '../event-filter/event.filter.model';
import { AuthorizationService } from '../../../service/authorization/authorization.service';
import { saveAs } from 'file-saver/dist/FileSaver';

import DateUtils from 'src/app/service/util/date-utils';
import { Websocket } from 'src/app/service/websocket/websocket';

import { Message } from '@stomp/stompjs';
import { EntityModel } from 'src/app/model/entity.model';
import { TrackingService } from '../../../service/model/tracking.service';
import { ASSOCIATED_EVENT_UPDATE_PREFIX, ESP, HttpError, LIST_UPDATE_PREFIX } from '../../../common/constants';
import { EventListDialogComponent } from '../../../general/operation/event-list-dialog/event-list-dialog.component';
import { ConfirmationDialogComponent } from '../../../general/confirmation-dialog/confirmation-dialog.component';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { AlertPriorityDescription, EventSituationDescription, ServiceTypeDescription, OperationStatusDescription } from '../../../model/enums.enum';
import { first, map } from 'rxjs/operators';
import { VerificationModel } from '../../../model/verification.model';
import { VerificationFilterModel } from '../../verification/verification-filter-dialog/verification.filter.model';
import { VerificationService } from '../../../service/model/verification.service';
import { InspectionModel } from '../../../model/inspection.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MarkersService } from '../../../service/model/markers.service';
import { ProfileClassToConsole } from 'src/app/common/profile-class.decorator';
import { ArchivedType } from 'src/app/general/filter-component/filter.model';
import { ArchiveListComponent } from '../../archive-list-component';

@ProfileClassToConsole()
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['../../../app.component.scss', '../../list.component.scss', './event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListComponent extends ArchiveListComponent implements OnInit, OnDestroy {

  // Indica que esse componente de lista está sendo usado dentro de uma edição de evento
  @Input()
  insideEventEdition: boolean = false;

  private editedEventSubscription: Subscription;
  @Input() editedEventObservable: Observable<EventModel>;

  private editedEventId: string;
  private editedEventIdentifier: string;

  private _readonly      : boolean;

  @Input()
  set editReadOnly(readonly: boolean){
    this._readonly = readonly;
  }

  get editReadOnly() : boolean{
    return this._readonly;
  }

  // Enums
  resultOccurrenceDescription = ResultOccurrenceDescription;
  eventStatus = EventStatus;

  eventUpdateWebsocket: Websocket;
  eventUpdateSubscription: Subscription;

  // Usado na exportação
  currentVerifications: VerificationModel[];

  columnsTitles = ['ID Evento', 'Status', 'Validação', 'Analista Validação', 'Ocorrência', 'Data', 'Hora', 'Analista CCPD', 'Canal de Comunicação', 'Quem?', 'Origem',
                    'Nível de Alerta', 'Cotur CNCL', 'Operador CNCL', 'Situação', 'Horário de Suspeita', 'Horário de Parada', 'Produto',
                    'Vazão - m3/h', 'Volume Subtraído (m3)', 'ID Chamada 168', 'Categoria 168', 'Subcategoria 168', 'Faixa', 'Trecho',
                    'Duto', 'Válvula', 'KM', 'KM (Lat, Long)', 'Início Trecho (KM)', 'Fim Trecho (KM)', 'Início Trecho (Lat, Long)',
                    'Fim Trecho (Lat, Long)', 'CEP', 'Logradouro', 'Cidade', 'UF', 'KM Ocorrência', 'Lat, Long Ocorrência', 'Notas',
                    'ID Verificação', 'Status Verificação', 'Coordenador', 'Gerência', 'Regional', 'Empresa', 'Tipo de serviço', 'Equipe',
                    'Placa', 'Profissional 1', 'Profissional 2', 'Profissional 3', 'Data/Hora (Solicitação)', 'Data/Hora Início de Percurso',
                    'Data/Hora Início de Inspeção', 'Data/Hora Fim de Inspeção', 'Data/Hora Fim da Verificação', ' Data/Hora App (recebida)', 'Pontos Planejados',
                    'Descrição dos Pontos', 'Mídia', 'Pontos Executados', 'Diferença de Pontos', 'Pontos sem Acesso','TACCPD', 'TACS', 'TAPD', 'TTA', 'TERF', 'TVI', 'TSI', 'TRIVI',
                    'KM sem acesso', 'Lat sem acesso', 'Long sem acesso'];

  eventFields = ['identifier', 'status', 'validation', 'validationAnalyst','result.occurrence', 'date', 'analyst', 'communicationChannel', 'who', 'source',
                  'alertLevel', 'coturCNCL', 'operatorCNCL', 'situation', 'suspiciousTime', 'stopTime', 'product', 'flow', 'stolenVolume',
                  'callId168', 'category168', 'subcategory168', 'band', 'stretch', 'duct', 'valve', 'targetPointKM', 'targetPointLatLong',
                  'stretchStartKM', 'stretchEndKM', 'stretchStartLatLong', 'stretchEndLatLong', 'zipCode', 'street', 'city', 'state',
                  'result.occurrenceKM' , 'result.occurrenceLatLong', 'result.notes', '', '', '', '', '', '', '', '', '', '', '', '', '',
                  '', '', '', '', '', 'requestedPoints', 'pointsDescription', '', '', 'visitedPoints', 'pointsDiff'];

  verificationFields = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
                        'band', 'stretch', 'duct', 'valve', 'km', 'latLong',
                        'stretchStartKM', 'stretchEndKM', 'stretchStartLatLong', 'stretchEndLatLong', '', '', '', '', '' , '', '',
                        'identifier', 'status', 'coordinator', 'gs', 'regional', 'company', 'serviceType', 'patrolTeam', 'plate', 'professional1',
                        'professional2', 'professional3', 'sentDate', 'startDateMovement', 'startDateInspection', 'endDateInspection', 'dateSendEndVerification',
                        'receivedDateApp', 'requestedPoints', 'pointsDescription', 'midia', 'visitedPoints', 'pointsDiff', 'noAccesVerificationPoints','taccpd', 'tacs', 'tapd', 'tta', 'terf', 'tvi', 'tsi', 'trivi',
                         'noaccessKM', 'noaccessLat', 'noacessLong'];

  isHiddenButtonCreateEditEvent: boolean;
  isHiddenButtonDeleteEvent: boolean;  
  
  constructor(logger:                         NGXLogger,
              protected changeDetector:       ChangeDetectorRef,
              private ngZone:                 NgZone,
              storageService:                 StorageService,
              protected toastr:               ToastrService,
              public authorizationService:    AuthorizationService,
              dialog:                         MatDialog,
              private eventService:           EventService,
              protected trackingService:      TrackingService,
              protected verificationService:  VerificationService,
              protected markersService:       MarkersService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer)  protected container: GoldenLayout.Container) {
    super(logger, changeDetector, authorizationService, eventService, dialog, 'events', environment.EVENT_GROUP_LABEL, environment.EVENT_TITLE_LABEL,
      environment.EVENT_MODEL_LABEL, storageService, trackingService, toastr, goldenLayout, container);
    logger.debug('EventListComponent.constructor()');
  }

  ngOnDestroy(){
    super.ngOnDestroy();
    this.eventUpdateSubscription?.unsubscribe();
    this.editedEventSubscription?.unsubscribe();

    this.eventUpdateWebsocket?.destroy();
    this.eventUpdateWebsocket = null;
  }

  ngOnInit() {
    super.ngOnInit();    
    this.logger.debug('EventListComponent.ngOnInit()');
    if(this.insideEventEdition){
      this.displayedColumns = ['select', 'validation', 'identifier', 'status', 'result.occurrence', 'date', 'analyst.name',
      'communicationChannel', 'source', 'duct', 'valve', 'targetPointKM'];
    }else{
      this.displayedColumns = ['select', 'validation', 'identifier', 'status', 'result.occurrence', 'date', 'analyst.name',
                              'communicationChannel', 'source', 'duct', 'valve', 'targetPointKM', 'associatedIdentifier'];
    }
    
    this.subscribeOnChangeEditedEventId();
    this.initWebsocket();
  }

  protected getDefaultSortColumn(): string {
    return "identifier";
  }

  protected getDefaultSortDirection(): 'asc' | 'desc' {
    return 'desc';
  }

  protected newFilterModel() {
    let filter = new EventFilterModel();

    if (this.insideEventEdition) {
      filter.associatedEventId = this.editedEventId;
      if (this.editedEventId) filter.archived = ArchivedType.ALL;
      filter.filterByAssociatedEvent = FilterByAssociatedEvent.ASSOCIATED;
    }

    return filter;
  }

  initWebsocket(){
    this.ngZone.runOutsideAngular(() => {
      this.eventUpdateWebsocket = Websocket.createEventUpdateWebsocket();
      this.subscribeToEventUpdateWebsocket();
    });
  }

  // Chamada quando algum item da lista foi atualizado pelo backend
  updateModelItem(id: string){
    this.entityService.getRecord(id).pipe(first()).subscribe((entity: EntityModel) => {
      if(!entity) {
        return;
      };

      //Se está numa edição de eventos, o loadRecordsFromServer não foi chamado quando o evento é novo, assim o modelo não existe
      //Quando o websocket envía a mensagem de atualização de listas, o model na findAndUpdateModel é undefined para essa lista   
      if(!this.model){
        return;
      }

      let event = entity as EventModel;
      
      if(!this.checkInsideEventlistOrNotInsideEvent(entity)){
        return;
      }

      let exclude = false; 
      let toFilter = false;
      const oldModel = this.model.find( model => event.id == model.id);
      
      if(this.checkFilterDates(event)) {
        toFilter = true;
      }
      else if(this.checkFilterOccurrences(event)) {
        toFilter = true;
      }
      else if(this.checkFilterStatus(event)) {
        toFilter = true;
      }
      else if(this.checkFilterCommunicationChannel(event)) {
        toFilter = true;
      }
      else if(this.checkFilterAnalyst(event)) {
        toFilter = true;
      } 
      else if(this.checkFilterSources(event)) {
        toFilter = true;
      } 
      else if(this.checkFilterKM(event)) {
        toFilter = true;
      }

      if (toFilter == true) {
        if (!oldModel) {
          return;
        } else {
          exclude = true;
        }
      }

      this.findAndUpdateModel(entity, exclude);
    }, (error: HttpErrorResponse) => {
      if (error.status === HttpError.NOT_FOUND) { // Evento removido (mesmo tratamento do Registration, diferente de operações)
        this.model = this.model.filter( model => model.id != id);
        this.buildDataSource();
        return;
      }
      this.logger.error(error);
    });
  }

  private subscribeToEventUpdateWebsocket() {
    this.eventUpdateSubscription = this.eventUpdateWebsocket.stream().subscribe((message: Message) => {
      this.logger.debug("EventListComponent.OnUpdateEventWebsocket()");
      const entity: EntityModel = JSON.parse(message.body);
      if (!entity) {
        return;
      }

      this.updateModelItem(entity.id);
    }, (error) => {
      const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`EventListComponent.OnUpdateEventWebsocket: ${errMsg}`);
    });
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'targetPointKM':
        {
          const val = item['stretchStartKM'] && item['stretchEndKM'] ? item['stretchStartKM'] + ' - ' +
          item['stretchEndKM'] : item['targetPointKM'];
         return val ? val : '';
        }
      default: return super.getPropertyByPath(item, property);
   }
  }
  
  checkFilterDates(event: EventModel){
    // data
    if(this.filterModel.startDate != null && this.filterModel.endDate != null){
      if(!(event.date >= this.filterModel.startDate && event.date <= this.filterModel.endDate))
        return true;
      }
      else if(this.filterModel.startDate == null && this.filterModel.endDate != null){
        if(!(event.date <= this.filterModel.endDate))
          return true;
      }
      else if(this.filterModel.startDate != null && this.filterModel.endDate == null){
        if(!(event.date >= this.filterModel.startDate))
          return true;
      }
      return false;
  }  
  
  checkFilterOccurrences(event: EventModel){
    let filter: EventFilterModel = this.filterModel as EventFilterModel;
    if(filter.occurrences != null && filter.occurrences.length > 0){
      if(!filter.occurrences.includes(event.result.occurrence))
        return true;
    }
    return false;
  }  
  
  checkFilterStatus(event: EventModel){
    let filter: EventFilterModel = this.filterModel as EventFilterModel;
    if(filter.status != null && filter.status.length > 0){
      if(!filter.status.includes(event.status))
        return true;
    }
    return false;
  }  
  
  checkFilterCommunicationChannel(event: EventModel){
    let filter: EventFilterModel = this.filterModel as EventFilterModel;
    if(filter.channels != null && filter.channels.length > 0){
      if(!filter.channels.includes(event.communicationChannel))
        return true;
    }
    return false;
  }  
  
  checkFilterAnalyst(event: EventModel){
    let filter: EventFilterModel = this.filterModel as EventFilterModel;
    if(filter.analysts != null && filter.analysts.length > 0){
      if(!filter.analysts.includes(event.analyst.id))
        return true;
    }
    return false;
  }  
  
  checkFilterSources(event: EventModel){
    let filter: EventFilterModel = this.filterModel as EventFilterModel;
    if(filter.sources != null && filter.sources.length > 0){
      if(!filter.sources.includes(event.source))
        return true;
    }
    return false;
  }  
  
  checkFilterKM(event: EventModel){
    let filter: EventFilterModel = this.filterModel as EventFilterModel;

    if (filter.startKM == null && filter.endKM == null) {
      return false;
    }

    if ((event.stretchStartKM != null && event.stretchStartKM.trim().length != 0) && (event.stretchEndKM != null && event.stretchEndKM.trim().length != 0)) {
      if (filter.startKM != null && filter.endKM != null) {
        if(event.stretchStartKM >= filter.endKM && event.stretchEndKM <= filter.startKM)
          return true;
      }
      else if (filter.startKM == null) {
        if(event.stretchStartKM >= filter.endKM) {
          return true;
        }
      }
      else {
        if(event.stretchEndKM <= filter.startKM) {
          return true;
        }
      }
    }
    else if (event.targetPointKM != null) {
      if ((filter.startKM !== null && event.targetPointKM <= filter.startKM) ||
          (event.targetPointKM >= filter.endKM))
        return true;
    }
    else {
      return true;
    }
    return false;
  }  

  onCreateVerificationClick(row) {
    this.glOpenContainer('verifications-edit', {id: null, options: {event: row}});
  }

  onExportClick(row: any, event: any) {
    this.loadingListService.loadingOn();
    this.eventService.exportEvent(row).pipe(first()).subscribe(response => {
      this.loadingListService.loadingOff();
      this.renderComponent();

      let filename = `${row.identifier}.zip`;
      filename = filename.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      saveAs(response, filename);
    },
    error =>{
      this.loadingListService.loadingOff();
      this.renderComponent();
      this.logger.error(error);
    });
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para Buscar.
   */
  protected getStringEntityForFilter(event: EventModel): string {
    const identifier = (event.identifier) ? super.lowerAndTrimText(event.identifier) : '';
    const status = super.lowerAndTrimText(this.getEventStatus(event));
    const occurrence = event.result && event.result.occurrence ?
      super.lowerAndTrimText(this.resultOccurrenceDescription[event.result.occurrence]) : '';
    const date = (event.date) ? this.formatDate(event.date) : '';
    const analyst = (event.analyst) ? super.lowerAndTrimText(this.getUserNameLoginTitle(event.analyst)) : '';
    const communicationChannel = super.lowerAndTrimText(event.communicationChannel);
    const source = super.lowerAndTrimText(event.source);
    const duct = super.lowerAndTrimText(event.duct);
    const associatedIdentifier = super.lowerAndTrimText(event.associatedIdentifier);
    const km = event.stretchStartKM && event.stretchEndKM ? event.stretchStartKM + ' - ' +
               event.stretchEndKM : (event.targetPointKM ? event.targetPointKM : '');
    const valve = super.lowerAndTrimText(event.valve);
    return identifier + ESP + status + ESP + occurrence + ESP + date + ESP + analyst + ESP + communicationChannel + ESP + source + ESP + duct + ESP + km + ESP + valve + ESP + associatedIdentifier;
  }

  protected getEventExportColValue(col, model):string[] {
    let values: string[] = [];
    let value: string;
    switch (col) {
      case "identifier":
      case "communicationChannel":
      case "who":
      case "source":
      case "product":
      case "callId168":
      case "category168":
      case "subcategory168":
      case "band":
      case "stretch":
      case "duct":
      case "valve":
      case "targetPointKM":
      case "targetPointLatLong":
      case "stretchStartKM":
      case "stretchEndKM":
      case "stretchStartLatLong":
      case "stretchEndLatLong":
      case "zipCode":
      case "street":
      case "city":
      case "state":
      case "pointsDescription":
        value = model[col] ? model[col] : "";
        values.push(value);
        break;
      case "flow":
      case "stolenVolume":
        value = model[col] ? model[col] : "";
        values.push(Number(value).toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2}));
        break;
      case "requestedPoints":
      case "visitedPoints":
      case "pointsDiff":
        value = model[col] ? model[col] : 0;
        values.push(value);
        break;
      case "status":
        values.push(this.getEventStatus(model));
        break;
      case "validation":
        value = model[col] ? EventValidationDescription[model[col]] : ""
        values.push(value);
        break;
      case "validationAnalyst":
        values.push(this.getUserNameLoginTitle(model[col]));
        break;
      case "result.occurrence":
        value = model['result'] ? model['result']['occurrence'] ? ResultOccurrenceDescription[model['result']['occurrence']] : "" : "";
        values.push(value);
        break;
      case "result.occurrenceKM":
        value = model['result'] ? model['result']['occurrenceKM'] ? model['result']['occurrenceKM'] : "" : "";
        values.push(value);
        break;
      case "result.occurrenceLatLong":
        value = model['result'] ? model['result']['occurrenceLatLong'] ? model['result']['occurrenceLatLong'] : "" : "";
        values.push(value);
        break;
      case "result.notes":
        value = model["result"] ? model["result"]['notes'] ? model['result']['notes'] : "" : "";
        values.push(value);
        break;
      case "date":
        values.push(model[col] ? DateUtils.timestampToStringInDays(model[col]) : "");
        values.push(model[col] ? DateUtils.timestampToTimeString(model[col]) : "");
        break;
      case "analyst":
        values.push(this.getUserNameLoginTitle(model['analyst']));
        break;
      case "alertLevel":
        value = model[col] ? AlertPriorityDescription[model[col]] : "";
        values.push(value);
        break;
      case "coturCNCL":
        values.push(this.getUserNameLoginTitle(model[col]));
        break;
      case "operatorCNCL":
        values.push(this.getUserNameLoginTitle(model[col]));
        break;
      case "situation":
        value = model[col] ? EventSituationDescription[model[col]] : "";
        values.push(value);
        break;
      case "suspiciousTime":
        values.push(model[col] ? DateUtils.timestampToTimeString(model[col]) : "");
        break;
      case "stopTime":
        values.push(model[col] ? DateUtils.timestampToTimeString(model[col]) : "");
        break;
      default:
        values.push("");
        break;
    }
    return values;
  }

  protected getVerificationExportColValue(col, model):string[] {
    let values: string[] = [];
    let value: string;
    switch (col) {
      case "identifier":
      case "band":
      case "stretch":
      case "duct":
      case "valve":
      case "km":
      case "latLong":
      case "stretchStartKM":
      case "stretchEndKM":
      case "stretchStartLatLong":
      case "stretchEndLatLong":
      case "pointsDescription":
      case "midia":
        value = model[col] ? model[col] : "";
        values.push(value);
        break;
      case "requestedPoints":
      case "visitedPoints":
      case "pointsDiff":
        value = model[col] ? model[col] : 0;
        values.push(value);
        break;
      case "noAccesVerificationPoints":
        value = model[col] ? model[col] : 0;
        values.push(value);
        break;
      case "status":
        values.push(this.getVerificationSatus(model) + ((model.expired && model.status=='FINISHED')? ' (expirada)': ''));
        break;
      case "coordinator":
        value = model?.patrolTeam?.patrolCoordinator ? this.getUserNameLoginTitle(model.patrolTeam.patrolCoordinator) : "";
        values.push(value);
        break;
      case "gs":
        value = model.patrolTeam?.company?.placement?.gs;
        values.push(value ? value : "");
        break;
      case "regional":
        value = model.patrolTeam?.company?.placement?.regional;
        values.push(value ? value : "");
        break;
      case "company":
        value = model.patrolTeam?.company?.name;
        values.push(value ? value : "");
        break;
      case "serviceType":
        value = model.patrolTeam?.serviceType ? ServiceTypeDescription[model.patrolTeam.serviceType] : "";;
        values.push(value);
        break;
      case "patrolTeam":
        value = model.patrolTeam?.name;
        values.push(value ? value : "");
        break;
      case "plate":
        value = model.patrolTeam?.vehicle?.plate;
        values.push(value ? value : "");
        break;
      case "professional1":
        value = model.patrolTeam?.users[0];
        values.push(this.getUserNameLoginTitle(value));
        break;
      case "professional2":
        value = model.patrolTeam?.users[1];
        values.push(this.getUserNameLoginTitle(value));
        break;
      case "professional3":
        value = model.patrolTeam?.users[2];
        values.push(this.getUserNameLoginTitle(value));
        break;
      case "sentDate":
      case "startDateMovement":
      case "startDateInspection":
      case "endDateInspection":
      case "dateSendEndVerification":
        values.push(model[col] ? DateUtils.timestampToStringInMinutes(model[col]) : "");
        break;
      case "receivedDateApp":
        values.push(model[col] ? DateUtils.timestampToStringInMinutes(model[col]) : "");
        break;
      case "taccpd":
        value = model?.taccpd;
        values.push(model[col] ? value : '');
        break;
      case "tacs":
        value = model?.tacs;
        values.push(model[col] ? value : '');
        break;
      case "tapd":
        value = model?.tapd;
        values.push(model[col] ? value : '');
        break;
      case "tta":
        value = model?.tta;
        values.push(model[col] ? value : '');
        break;
      case "terf":
        value = model?.terf;
        values.push(model[col] ? value : '');
        break;
      case "tvi":
        value = model?.tvi;
        values.push(model[col] ? value : '');
        break;
      case "tsi":
        value = model?.tsi;
        values.push(model[col] ? value : '');
        break;
      case "trivi":
        value = model?.trivi;
        values.push(model[col] ? value : '');
        break;
      default:
        values.push("");
        break;
    }
    return values;
  }

  private buildDataArray(events, columnsTitles, eventFields, verificationFields){
    let ws_data = [];
    let lines = [];
    let line = [];
    columnsTitles.forEach((col:string) => { line.push(col); });
    ws_data.push(line);

    events.forEach((model: EventModel) => {
      let line = [];
      eventFields.forEach(col => {
        line = line.concat(this.getEventExportColValue(col, model));
      });
      lines.push(line);
      if (model.verifications?.length > 0) {
        model.verifications.forEach((verification: VerificationModel) => {
          line = [];
          verificationFields.forEach(col => {
            line = line.concat(this.getVerificationExportColValue(col, verification));
          });
          lines.push(line);
        });
      }
      model.noAccessPoints?.forEach((point: InspectionModel) => {
        line = [];
        let index: number = this.columnsTitles.findIndex((el: string) => el === "KM sem acesso");
        line[index] = point.km;
        line[++index] = point.location.latitude;
        line[++index] = point.location.longitude;
        lines.push(line);
      });
    });
    lines?.forEach(element => {
      ws_data.push(element);
    });
    return ws_data;
  }

  private aggregateInspectionsData() {
    let kms: string;
    this.selection.selected.forEach((event: EventModel) => {
      kms = "";
      event.pointsDescription = "";
      event.requestedPoints = 0;
      event.visitedPoints = 0;
      let requestedPoints = 0;      
      let noAccesVerificationPoints = 0; 
      event.noAccessPoints = [];
      event.verifications?.forEach((verification: VerificationModel) => {
        requestedPoints = verification.inspections.length;        
        verification.inspections.forEach((inspection: InspectionModel) => {
          if (inspection.km) {
             kms += inspection.km + ";";
          }
          if (inspection.inspectionPointNoAccess) {
            noAccesVerificationPoints += 1;
          }
          if (inspection.inspectionPointNoAccess) {
            event.noAccessPoints.push(inspection);
          }
        })
        verification.pointsDescription = kms;
        verification.requestedPoints = requestedPoints;
        verification.noAccesVerificationPoints = noAccesVerificationPoints;
        verification.visitedPoints = parseInt(verification.executedPoints) + noAccesVerificationPoints;
        verification.pointsDiff = requestedPoints - verification.visitedPoints;
        event.pointsDescription += kms;
        event.requestedPoints += requestedPoints;
        event.visitedPoints += verification.visitedPoints;
      })
      event.pointsDiff = event.requestedPoints - event.visitedPoints;
    })
  }

  public exportEventsToXls(){
    const EXCEL_EXTENSION = '.xlsx';
    var ws_name = this.tabTitle;
    var wb = XLSX.utils.book_new();

    let exportDate = moment().format('DD-MM-yyyy HH-mm');

    /* make worksheet */
    var events = (this.selection.selected.length > 0) ? this.selection.selected : this.dataSource.filteredData;

    var ws_data = this.buildDataArray(events, this.columnsTitles, this.eventFields, this.verificationFields);

    var ws = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, ws_name+' '+exportDate+EXCEL_EXTENSION);
  }

  private loadVerifications(eventsId: string[]) : Promise<any>{
    const filter: VerificationFilterModel = new VerificationFilterModel();
    filter.eventIds = eventsId;
    filter.archived = ArchivedType.ALL;
    this.currentVerifications = [];    
    
    return  new Promise<any>((resolve, reject)=>{
      this.verificationService.loadFilteredListFromRestApi(null, null, null, filter).pipe(first()).subscribe((model: VerificationModel[]) =>{
        if(!model || model.length === 0) resolve(false);
        this.currentVerifications = model;
        resolve(true);
      
    }, error => {
      this.logger.error(error);
      this.toastr.error('Erro ao ler lista de verificações');
      resolve(false);
    });
  });
  }

  public async onExportManyClick() {
    if( this.selection.selected.length == 1) {
      this.onExportClick(this.selection.selected[0], null);
    }
    else {
      let events: EventModel[];
      if(this.selection.selected.length > 0) {
        events = this.selection.selected.slice() as EventModel[];
      }
      else {
        events = this.dataSource.filteredData as EventModel[];
      }

      var operationsIds: string[] = [];
        events.forEach( (event: EventModel) => {
        operationsIds.push(event.identifier);
        event.verifications = []
      });

      //verifica quantas operações tem, se maior que 100 a consulta será dividida
      let successDataRead = false;      
      if(operationsIds.length > 100){
        successDataRead =  await this.loadListGreaterThanOneHundred(operationsIds);
      }else{
        successDataRead = await this.loadVerifications(operationsIds);
      }
      
      if (successDataRead && this.currentVerifications){        

        this.currentVerifications.forEach( (model: VerificationModel) => {
          let currentEvent: EventModel = events.find( (event: EventModel) => event.identifier === model.eventId);          
          currentEvent.verifications.push(model);
        
          let sendDateMoment, eventMoment, createdAtMoment, endMoment, dateSituationFoundMoment, startInspMoment, startMovementMoment;

          //TACCPD
          if(model.eventCreatedAt && model.sentDate){
            sendDateMoment = moment(model.sentDate);
            eventMoment = moment(model.eventCreatedAt);
            const duration  = moment.duration(sendDateMoment.diff(eventMoment));      
            model.taccpd = this.secondsToHms(duration.asSeconds());
          }

          //TACS
          if(model.createdAt && model.sentDate){
            createdAtMoment = moment(model.createdAt);
            sendDateMoment = moment(model.sentDate);
            const duration  = moment.duration(sendDateMoment.diff(createdAtMoment));
            model.tacs = this.secondsToHms(duration.asSeconds());
          }

          //TAPD
          if(model.dateSendEndVerification && model.sentDate){
            endMoment = moment(model.dateSendEndVerification);
            sendDateMoment = moment(model.sentDate);
            const duration  = moment.duration(endMoment.diff(sendDateMoment));
            model.tapd = this.secondsToHms(duration.asSeconds());
          }

          //TTA
          if(model.dateSendEndVerification && model.eventCreatedAt){
            endMoment = moment(model.dateSendEndVerification);
            const eventCreatedMoment = moment(model.eventCreatedAt);
            const duration  = moment.duration(endMoment.diff(eventCreatedMoment));
            model.tta = this.secondsToHms(duration.asSeconds());
          }

          //TERF
          if(model.dateSendEndVerification && model.dateSituationFound){
            endMoment = moment(model.dateSendEndVerification);
            dateSituationFoundMoment = moment(model.dateSituationFound);
            const duration  = moment.duration(dateSituationFoundMoment.diff(endMoment));
            model.terf = this.secondsToHms(duration.asSeconds());
          }

          //TVI
          if(model.startDateInspection && model.endDateInspection){
            startInspMoment = moment(model.startDateInspection);
            const endInspMoment = moment(model.endDateInspection);
            const duration  = moment.duration(endInspMoment.diff(startInspMoment));
            model.tvi = this.secondsToHms(duration.asSeconds());
          }

          //TSI
          if(model.startDateInspection && model.sentDate){
            startInspMoment = moment(model.startDateInspection);
            sendDateMoment = moment(model.sentDate);
            const duration  = moment.duration(startInspMoment.diff(sendDateMoment)); 
            model.tsi = this.secondsToHms(duration.asSeconds());
          }

          //TRIVI
          if(model.startDateMovement && model.sentDate){
            startMovementMoment = moment(model.startDateMovement);
            sendDateMoment = moment(model.sentDate);
            const duration  = moment.duration(startMovementMoment.diff(sendDateMoment));  
            model.trivi = this.secondsToHms(duration.asSeconds());          
          }
        
        });

        this.aggregateInspectionsData();
        this.exportEventsToXls();
      }
    }
  }

  private loadListGreaterThanOneHundred(operationsIds: string[]) : Promise<any> {
    this.logger.debug('loadListGreaterThanOneHundred');
    return new Promise<any>((resolve, reject)=>{ 
      this.verificationService.loadListVerifications(operationsIds).pipe(first()).subscribe((verifications: VerificationModel[]) => {
      this.currentVerifications = [];
      verifications.forEach(entities => {
        if ( entities['first'] || entities['second'] ){
          this.currentVerifications = this.currentVerifications.concat(entities['second']);
        }else{
          this.currentVerifications = this.currentVerifications.concat(entities);
        }
      });
     
      resolve(true);
    }, error => {
      this.logger.error(error);
      this.toastr.error('Erro ao ler lista de verificações');
      resolve(false);
    });
  });
  }

  /**
   * Método sobreposto da super classe que verifica se o evento tem os requisitos para arquivamento
   * @param model
   * @returns
   */
   hasArchivalRequirements(model: EventModel): boolean{

    if(model.status != EventStatus.FINALIZED){ // Verifica se o status é Concluído
      return false;
    }

    if(model.validation != EventValidation.COMPLETE){ // Verifica se a validação é Completo
      return false;
    }

    if( this.authorizationService.isControl()){ // Se for perfil ANALYSIS_CCPD, verifica se os eventos foram criados por ele
      if(!model.analyst || model.analyst.id !== this.loggedUser.id.toString()){
        return false;
      }
    }

    return true;
  }

  archiveTooltipText() {
    return `${this.archiveButtonLabel}  Evento(s) Selecionado(s)`;
  }

  getEventStatus(event: EventModel): string {
    if (!event) return '';

    if (event.archived)
      return "Arquivado";
    else if (event.status)
      return EventStatusDescription[event.status];
    else
      return '';
  }

  getVerificationSatus(verification : VerificationModel): string {
    if (!verification) return '';

    if (verification.archived)
      return "Arquivada";
    else if (verification.status)
      return OperationStatusDescription[verification.status];
    else
      return '';
  }

  canCopy(model: EntityModel): boolean {
    if (!model) return false;

    // Não preciso testar permissão de criar/editar porque o botão está escondido nesse caso

    // Verifica a permissão de alterar eventos por origem
    const event = model as EventModel;
    return this.authorizationService.canEditEventBySource(event.source);
  }

  canDelete(model: EntityModel) : boolean {
    if (!model) return false;

    // Não preciso testar permissão de remover porque o botão está escondido nesse caso

    /* Verifica  se o usuário possui a permissão "Alterar eventos concluídos */
    const event = model as EventModel;
    if(event.status == EventStatus.FINALIZED && !this.authorizationService.userHasPermission(Permission.EDIT_END_EVENT)){
      return false
    }

    // Verifica a permissão de alterar eventos por origem
    return this.authorizationService.canEditEventBySource(event.source);
  }

  hasLocation(entity: EntityModel): boolean {
    let event = <EventModel>entity;
    return event &&
           (!!event.targetPointLatLong ||
            (!!event.stretchStartLatLong && !!event.stretchEndLatLong));
   }

  saveData(entity){
    // Se esta editando um evento então deixa para subscribeOnChangeEventId fazer a atualização da lista
    if(!this.insideEventEdition){
      super.saveData(entity);
    }
  }

  onLinkEventClick(){
    let filter: EventFilterModel = new EventFilterModel();
    filter.associatedEventId = this.editedEventId;
    filter.filterByAssociatedEvent = FilterByAssociatedEvent.NOT_ASSOCIATED; // Carrega somente quem ainda não foi associado
    // Não carrega os arquivados no diálogo de seleção de evento

    let dialogRef = this.dialog.open(EventListDialogComponent, {
      panelClass: 'sipd-modal',
      data: filter
    });
    dialogRef.afterClosed().pipe(first()).subscribe( (events: EventModel[]) => {
      if(events && events.length > 0){
        // Note que quem é alterado são os filhos selecionados, não o pai
        this.changeAssociatedEvents(events, this.editedEventId, this.editedEventIdentifier, true);
      }
    });
  }

  canLinkEvent(){
    return this.insideEventEdition && this.editedEventId;
  }

  onLinkEventManyClick(event: EventModel){
    let filter: EventFilterModel = new EventFilterModel();
    filter.associatedEventId = event.id;
    filter.filterByAssociatedEvent = FilterByAssociatedEvent.NOT_ASSOCIATED; // Carrega somente quem ainda não foi associado
    // Não carrega os arquivados no diálogo de seleção de evento

    let dialogRef = this.dialog.open(EventListDialogComponent, {
      panelClass: 'sipd-modal',
      data: filter
    });
    dialogRef.afterClosed().pipe(first()).subscribe( (events: EventModel[]) => {
      if(events && events.length > 0){
        // Note que quem é alterado são os filhos selecionados, não o pai
        this.changeAssociatedEvents(events, event.id, event.identifier, true);
      }
    });
  }

  onAssociateEvent(associateEvent: EventModel){
    let filter: EventFilterModel = new EventFilterModel();
    filter.associatedEventId = associateEvent.id;
    filter.filterByAssociatedEvent = FilterByAssociatedEvent.NOT_ASSOCIATED; // Carrega somente quem ainda não foi associado
    // Não carrega os arquivados no diálogo de seleção de evento

    let dialogRef = this.dialog.open(EventListDialogComponent, {
      panelClass: 'sipd-modal',
      data: filter
    });
    dialogRef.afterClosed().pipe(first()).subscribe( (events: EventModel[]) => {
      if(events && events.length == 1){
        // Note que quem é alterado são os filhos selecionados, não o pai
        // A diferença aqui é que passamos o filho como parâmetro, e selecionamos o pai no diálogo
        this.changeAssociatedEvents([associateEvent], events[0].id, events[0].identifier, true);
      }
    });
  }

  private changeAssociatedEvents(events: EventModel[], associatedEventId: string, associatedIdentifier: string, associateType?: boolean){
    this.logger.debug('changeAssociatedEvents');
    this.eventService.changeAssociatedEvent( events, associatedEventId, associatedIdentifier).pipe(first()).subscribe((changedEvents: EventModel[]) => {
      if(associateType){
        this.toastr.success('Eventos vinculados com sucesso');
      }else {
        this.toastr.success('Eventos desvinculados com sucesso');
      }
      changedEvents.forEach( (event: EventModel) => {
        this.glEmitEvent(ASSOCIATED_EVENT_UPDATE_PREFIX + 'events-edit', event);
      });
      this.glEmitEvent(LIST_UPDATE_PREFIX + 'events', null); // Atualiza todas as listas de eventos (normal e dentro de edições)
    }, error => {
      this.logger.error(error);
      this.toastr.error('Erro ao vincular eventos');
    });
  }

  loadRecordsFromServer(): void {
    if (this.insideEventEdition) {
      if (!this.editedEventId){
        return;
      }

      let filter: EventFilterModel = this.filterModel as EventFilterModel;
      filter.associatedEventId = this.editedEventId;
      filter.archived = ArchivedType.ALL;
    }

    super.loadRecordsFromServer();
  }
  
  subscribeOnChangeEditedEventId(){
    // É uma lista dentro da edição de evento
    if(this.insideEventEdition){
      // Este observador é instanciado na respectiva edição de evento
      this.editedEventSubscription = this.editedEventObservable.subscribe((event: EventModel) => {
        this.logger.debug('EventListComponent.OnChangeEditedEventId()');
        // Chamado depois que o evento da edição de evento é carregado do backend

        this.editedEventId = event.id;
        this.editedEventIdentifier = event.identifier;
       
        this.loadRecordsFromServer(); // Carrega a lista de eventos relacionados
      });
    }
  }

  onUnLinkEventClick(event: EventModel){
    this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: 'Esse Evento será desvinculado do Evento relacionado. Confirma?',
        title: "Desvincular de Evento",
        okLabel: "Desvincular"
      }
    }).afterClosed().pipe(first()).subscribe((option:boolean) => {
      if(option) {
        // Note que quem é alterado são os filhos selecionados, não o pai
        this.changeAssociatedEvents([event], null, null, false);
      }
    });
  }

  canUnLinkEvent(event: EventModel){
    return event && event.associatedEventId ? true: false;
  }

  onUnLinkEventManyClick(){
    const selectEvents = this.getSelectedEvents();
    this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: 'Os '  + this.selection.selected.length + ' Eventos selecionados serão desvinculados do Evento relacionado. Confirma?',
        title: "Desvincular de Eventos",
        okLabel: "Desvincular"
      }
    }).afterClosed().pipe(first()).subscribe((option:boolean) => {
      if(option) {
        // Note que quem é alterado são os filhos selecionados, não o pai
        this.changeAssociatedEvents(selectEvents, null, null, false);
      }
    });
  }

  getSelectedEvents(): EventModel[] {
    const selectedEvents: EventModel[] = [];

    if(this.selection.selected.length > 0) {
      this.selection.selected.forEach((event:EventModel) => {
          selectedEvents.push(event)
        });
    }
    return selectedEvents;
  }

  /** Remoção de multiplos eventos, precisa analizar se na seleção tem eventos relacionados */
  onDeleteManyClick(rows: EntityModel[], event?: any ) {
    let  dialogRef =  this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data: {
            msg: 'Remover ' + rows.length + ' item(s) selecionado(s)?',
            title: 'Remover Evento',
            okLabel: 'Remover',
            showIdentifier: false
        }
      });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.deleteSelectedEntities(rows);
        this.postListDeleteProcess(rows);
      }
    });
  }

  isChildEvent(row: EntityModel){
    return (<EventModel>row).associatedEventId != null;
  }

  onDeleteClick(row: EntityModel, identifier: string, event: any) {
    if(!row) return;

    let  dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        panelClass: 'sipd-modal',
        data: {
          msg: 'Remover ' + this.modelName + '?',
          title: 'Remover ' + this.modelName,
          okLabel: 'Remover',
          identifier: identifier,
          showIdentifier: true
        }
      });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let eventsToDelete: EventModel[] = [<EventModel>row];
        this.deleteSelectedEntities(eventsToDelete);
      }
    });
  }

  /** Método sobrescrito do pai que permite reusar o componente da lista de eventos
   *  sem trocar o título do componente que o está contido (evento) */
  glUpdateTabTitle(){
    if(!this.insideEventEdition) super.glUpdateTabTitle(this.tabTitle);
  }

  checkPermissions(){
    this.isHiddenButtonCreateEditEvent = !this.authorizationService.userHasPermission(this.permission.CREATE_EDIT_EVENT);
    this.isHiddenButtonDeleteEvent = !this.authorizationService.userHasPermission(this.permission.DELETE_EVENT);      
  } 

  /** Método que converte segundos para formato de dias, horas , minutos e segundos */
  secondsToHms(seconds) {
    seconds = Number(seconds);
    //var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60 % 60);

    var hDisplay = "00";
    var mDisplay = "00"
    var sDisplay = "00"
    hDisplay = h > 0 && h < 10 ? "0"+ h  : (h <= 0 ? "00" : h+"") ;
    mDisplay = m > 0 && m < 10 ? "0" + m  : (m <= 0 ? "00" : m+"") ;
    sDisplay = s > 0 && s < 10  ? "0" + s : (s <= 0 ? "00" : s+"");
    return  hDisplay+ ":" + mDisplay +":"+ sDisplay;
  }

  /** chamada pelo updateModelItem inscrito no websocket que ouve as mudanças nas operações */
  checkInsideEventlistOrNotInsideEvent(entity): boolean{
    if(!this.insideEventEdition) // se a lista não está numa edição de evento
       return true;

    if(entity.associatedEventId === this.editedEventId ){ // se a lista pertence a esse evento
      return true;
    }
    else {
      return false;
    }
  }
}
