<div mat-dialog-title>
  <span>Filtrar Formulários</span>
  <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <!--Nome-->
  <div class="p-5">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Nome</mat-label>
      <input matInput matTooltip="{{ filterModel.name }}" name="name" placeholder="Nome" [(ngModel)]="filterModel.name">
    </mat-form-field>
  </div>

  <!--Lotação-->
  <div class="p-5">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Lotação</mat-label>
      <input matInput matTooltip="{{ filterModel.placement }}" name="placement" placeholder="Lotação" [(ngModel)]="filterModel.placement">
    </mat-form-field>
  </div>
    <!--Tipo de Inspeção-->  
  <div class="p-5">
    <single-autocomplete [data]="singleDataCacheService.getValues('InspectionType')" placeholder="Tipo de Inspeção"
      [(valueData)]="filterModel.inspectionType" fxFlex>
    </single-autocomplete>
  </div>
  
  <div class="form-spacer" fxFlex></div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="clearFilter()">Limpar</button>
  <button mat-raised-button (click)="onFilter()">Aplicar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>  
</mat-dialog-actions>
