import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ChannelFilterModel {
  channelTypes:   string[] = [];
  placementNames: string[] = []; 
  channelIds:     string[] = [];
}
