import {Injectable} from '@angular/core';
import { first } from 'rxjs/operators';
import { DEFAULT_PAGE_INDEX, LARGE_PAGE_SIZE, SORT_NAME_ASC } from 'src/app/common/constants';
import { LoadingListService } from '../loading/loading-list.service';
import { SingleDataService } from './single.data.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from './user.service';
import { UserModel } from 'src/app/model/user.model';
import ListUtils from '../util/list-utils';
import { StorageService } from '../storage-service';
import { PatrolTeamService } from './patrol.team.service';
import { FilterModel } from 'src/app/general/filter-component/filter.model';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { PlacementService } from './placement.service';
import { PlacementModel } from 'src/app/model/placement.model';
import { VehicleService } from './vehicle.service';
import { VehicleModel } from 'src/app/model/vehicle.model';
import { Permission, RegistrationType, UserType } from 'src/app/model/enums.enum';
import { ProfileModel } from 'src/app/model/profile.model';
import { CompanyService } from './company.service';
import { CompanyModel } from 'src/app/model/company.model';
import { ProfileService } from './profile.service';
import { FormModel } from 'src/app/model/form.model';
import { FormService } from './form.service';
import { Observable, Subject } from 'rxjs';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class EntityCacheService {

  private users: UserModel[] = []; // Filtrado por lotação
  private patrolTeams: PatrolTeamModel[] = []; // Filtrado por lotação
  private placements: PlacementModel[] = []; // Filtrado por lotação
  private vehicles: VehicleModel[] = []; // Filtrado por lotação
  private companies: CompanyModel[] = []; // Filtrado por lotação

  private profiles: ProfileModel[] = []; // Não inclui admin
  private allProfiles: ProfileModel[] = []; // Inclui admin

  private publishedForms: FormModel[] = []; // Todos os formulários publicados, mesmo de outras lotações

  private usersReload: Subject<boolean> = new Subject();
  private vehiclesReload: Subject<boolean> = new Subject();
  private companiesReload: Subject<boolean> = new Subject();
  private patrolTeamsReload: Subject<boolean> = new Subject();
  private profilesReload: Subject<boolean> = new Subject();
  private placementsReload: Subject<boolean> = new Subject();
  private formsReload: Subject<boolean> = new Subject();

  constructor(
        public logger: NGXLogger,
        private userService: UserService,
        private placementService: PlacementService,
        public profileService: ProfileService,
        private companyService: CompanyService,
        protected formService: FormService,
        private vehicleService: VehicleService,
        protected patrolTeamService: PatrolTeamService,
        protected storageService: StorageService,
        protected authorizationService: AuthorizationService,
        protected singleDataService: SingleDataService) { 
    console.log("EntityCacheService.constructor");
  }

  // Filtrado por lotação
  getUsers(): UserModel[]{
    return this.users;
  }

  // Filtrado por lotação
  getPatrolTeams(): PatrolTeamModel[]{
    return this.patrolTeams;
  }

  // Filtrado por lotação
  getPlacements(): PlacementModel[]{
    return this.placements;
  }

  // Filtrado por lotação
  getVehicles(): VehicleModel[]{
    return this.vehicles;
  }

  // Filtrado por lotação
  getCompanies(): CompanyModel[]{
    return this.companies;
  }

  // Nunca inclui o perfil Admin, mesmo quando o usuário logado é Admin
  getProfiles(): ProfileModel[]{
    return this.profiles;
  }

  // Sempre inclui o perfil Admin
  getAllProfiles(): ProfileModel[]{
    return this.allProfiles;
  }

  getPublishedForms(): FormModel[]{
    return this.publishedForms;
  }

  isAdminProfile(profileId: string): boolean{
    if (!profileId) return false;
    return !!this.allProfiles.find(p => p.id === profileId && p.userType === UserType.ADMINISTRATOR);
  }

  loadUsers(loadingListService: LoadingListService, onLoadCallback?: Function){
    if (this.users.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    // Carrega todos os usuários
    let specificPlacementsIds: string[] = this.storageService.getSpecificPlacementIds();

    loadingListService?.loadingOn();
    this.userService.loadListFromRestApi(DEFAULT_PAGE_INDEX, LARGE_PAGE_SIZE, SORT_NAME_ASC).pipe(first()).subscribe((users: UserModel[]) => {

      users.forEach((user: UserModel) => {
        if (this.isAdminProfile(user['profileId'])) {
          // Administradores acessam todas as lotações
          user.placements = this.getPlacements();
        }
  
        if (!user.originPlacement && user.placements && user.placements.length == 1) {
          user.originPlacement = user.placements[0];
        }
      });
  
      if(specificPlacementsIds.length > 0){
        this.users = users.filter((user: UserModel) => specificPlacementsIds.includes(user.originPlacement?.id));
      }else {
        this.users = users;
      }

      ListUtils.orderSimpleModelList(this.users);

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar usuários `, error);
      loadingListService?.loadingOff();
    });
  }

  getFilteredUsers(userTypes: UserType[]): UserModel[] {
    const profiles = this.getAllProfiles();
    const p = profiles.filter((profile: ProfileModel) => { return userTypes.includes(UserType[profile.userType]); });
    const profileIds = p.map(a => a.id);

    let users = this.users.filter((user: UserModel) => {
      return profileIds.includes(user.profileId);
    });

    ListUtils.orderSimpleModelList(users); // Precisa ordenar de novo por causa do filter

    return users;
  }

  loadPatrolTeams(loadingListService: LoadingListService, onLoadCallback?: Function){
    if (this.patrolTeams.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    // Somente carrega as equipes que o usuário logado possui acesso
    let filterModel: FilterModel = new FilterModel();
    filterModel.placements = this.storageService.getSpecificPlacementIds();

    loadingListService?.loadingOn();
    this.patrolTeamService.loadFilteredListFromRestApi(DEFAULT_PAGE_INDEX, LARGE_PAGE_SIZE, SORT_NAME_ASC, filterModel).pipe(first()).subscribe( (patrolTeams: PatrolTeamModel[]) => {
      this.patrolTeams = patrolTeams;

      // Workaround para dados antigos do banco de dados
      this.patrolTeams.forEach( (patrolTeam: PatrolTeamModel) => {
        if (!patrolTeam.users) {
          patrolTeam.users = [];
        }
      });

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar equipes `, error);
      loadingListService?.loadingOff();
    });
  }

  loadPlacements(loadingListService: LoadingListService, onLoadCallback?: Function){
    if (this.placements.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    let specificPlacementsIds: string[] = this.storageService.getSpecificPlacementIds();

    const userType = this.authorizationService.getLoggedUserType();
    if (!userType) {
      this.logger.warn('Falha ao carregar lotações. Sem dados do perfil.');
      return;
    }

    // É um erro, usuário de qualquer outro profile que não Admin e não tem acesso a todas as lotações tem que ter alguma lotação
    if(specificPlacementsIds.length == 0 && userType != UserType.ADMINISTRATOR && !this.authorizationService.userHasPermission(Permission.ACCESS_TO_ALL_PLACEMENT)){
      this.logger.error('Erro ao carregar lotações. Usuário não profissional sem lotações de acesso definidas.');
      return;
    }
      
    loadingListService?.loadingOn();
    this.placementService.loadListFromRestApi().pipe(first()).subscribe( (placements: PlacementModel[]) => {
      // Carrega todas as lotações, depois filtra de acordo (não tem filtro de placements para a lista de placements)
      if(specificPlacementsIds.length > 0){
        this.placements = placements.filter((placement:PlacementModel) => specificPlacementsIds.includes(placement.id));
      }else {
        this.placements = placements;
      }

      ListUtils.orderSimpleModelList(this.placements);

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar lotações `, error);
      loadingListService?.loadingOff();
    });
  }

  loadVehicles(loadingListService: LoadingListService, onLoadCallback?: Function) {
    if (this.vehicles.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    // Somente carrega os veículos que o usuário logado possui acesso
    let filterModel: FilterModel = new FilterModel();
    filterModel.placements = this.storageService.getSpecificPlacementIds();

    loadingListService?.loadingOn();
    this.vehicleService.loadFilteredListFromRestApi(DEFAULT_PAGE_INDEX, LARGE_PAGE_SIZE, SORT_NAME_ASC, filterModel).pipe(first()).subscribe( (vehicles: VehicleModel[]) => {
      vehicles.sort((a, b) => a.plate.localeCompare(b.plate));
      this.vehicles = vehicles;

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar veículos `, error);
      loadingListService?.loadingOff();
    });
  }

  loadCompanies(loadingListService: LoadingListService, onLoadCallback?: Function) {
    if (this.companies.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    let specificPlacementsIds: string[] = this.storageService.getSpecificPlacementIds();

    loadingListService?.loadingOn();
    this.companyService.loadListFromRestApi().pipe(first()).subscribe( (companies: CompanyModel[]) => {

      // Carrega todas as empresas, depois filtra de acordo (não tem filtro de placements para a lista de empresas)
      if(specificPlacementsIds.length > 0){
        this.companies = companies.filter((company: CompanyModel) => specificPlacementsIds.includes(company.placement?.id));
      }else {
        this.companies = companies;
      }

      ListUtils.orderSimpleModelList(this.companies);

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar empresas `, error);
      loadingListService?.loadingOff();
    });
  }

  loadPublishedForms(loadingListService: LoadingListService, onLoadCallback?: Function) {
    if (this.publishedForms.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    loadingListService?.loadingOn();
    this.formService.loadListFromRestApi().pipe(first()).subscribe( (forms: FormModel[]) => {
      this.publishedForms = forms.filter((form)=> form.published === true);

      ListUtils.orderSimpleModelList(this.publishedForms);

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar formulários `, error);
      loadingListService?.loadingOff();
    });
  }

  loadProfiles(loadingListService: LoadingListService, onLoadCallback?: Function) {
    if (this.profiles.length != 0) {
      if (onLoadCallback) onLoadCallback();
      return;
    }

    loadingListService?.loadingOn();
    this.profileService.loadListFromRestApi().pipe(first()).subscribe( (loadedProfiles: ProfileModel[]) => {
      this.allProfiles = loadedProfiles;

      this.profiles = Object.assign([], this.allProfiles); // cria uma cópia porque precisa ser um array diferente do allProfiles
      
      const index = this.profiles.findIndex(item => item.userType === UserType.ADMINISTRATOR); // Só pode haver um único perfil administrador
      this.profiles.splice(index, 1); // remove o item Administrador dessa lista

      ListUtils.orderSimpleModelList(this.allProfiles);
      ListUtils.orderSimpleModelList(this.profiles);

      if (onLoadCallback) onLoadCallback();
      loadingListService?.loadingOff();
    },
    (error) => {
      this.logger.error(`Erro ao carregar perfis `, error);
      loadingListService?.loadingOff();
    });
  }

  onUsersReload(): Observable<boolean> {
    return this.usersReload.asObservable();
  }

  onVehiclesReload(): Observable<boolean> {
    return this.vehiclesReload.asObservable();
  }

  onCompaniesReload(): Observable<boolean> {
    return this.companiesReload.asObservable();
  }

  onPatrolTeamsReload(): Observable<boolean> {
    return this.patrolTeamsReload.asObservable();
  }

  onProfilesReload(): Observable<boolean> {
    return this.profilesReload.asObservable();
  }

  onPlacementsReload(): Observable<boolean> {
    return this.placementsReload.asObservable();
  }

  onFormsReload(): Observable<boolean> {
    return this.formsReload.asObservable();
  }
 
  reloadObjects(registrationType: string){
    switch (registrationType) {
      case RegistrationType.USER:
        this.users = [];
        this.loadUsers(null, () => this.usersReload.next(true) );
        break;
      case RegistrationType.PATROL_TEAM:
        this.patrolTeams = [];
        this.loadPatrolTeams(null, () => this.patrolTeamsReload.next(true) );
        break;
      case RegistrationType.PLACEMENT:
        this.placements = [];
        this.loadPlacements(null, () => this.placementsReload.next(true) );
        break;
      case RegistrationType.VEHICLE:
        this.vehicles = [];
        this.loadVehicles(null, () => this.vehiclesReload.next(true) );
        break;
      case RegistrationType.COMPANY:
        this.companies = [];
        this.loadCompanies(null, () => this.companiesReload.next(true) );
        break;
      case RegistrationType.PROFILE:
        this.profiles = [];
        this.allProfiles = [];
        this.loadProfiles(null, () => this.profilesReload.next(true) );
        break;
      case RegistrationType.FORM:
        this.publishedForms = [];
        this.loadPublishedForms(null, () => this.formsReload.next(true) );
        break;
    }      
  }

  getPatrolTeamById(teamId: string): PatrolTeamModel {
    let patrolTeam = this.patrolTeams.find(team => team.id === teamId);
    return patrolTeam;
  }

  getPatrolTeamByUserId(userId: string): PatrolTeamModel {
    let patrolTeam = this.patrolTeams.find(team => (team.users[0] && team.users[0].id == userId) || (team.users[1] && team.users[1].id == userId) || (team.users[2] && team.users[2].id === userId));
    return patrolTeam;
  }

  getUserById(userId: string ): UserModel{
    let user = this.users.find(user => user.id === userId);
    return user;
  }

  // Consulta valida para todos os perfis
  getProfileById(profileId: string) {
    let profile = this.allProfiles.find(profile => profile.id === profileId);
    return profile;
  }

  getVehicleByPlate(plate: string ): VehicleModel{
    let vehicle = this.vehicles.find(vehicle => vehicle.plate === plate);
    return vehicle;
  }
}
