<form class="user-form edit-form h-100">
  <div class="sipd-container">
    <div class="sipd-header">
      <span class="mat-title m-0">{{modelName + (isCreating()? ': [NOVO]':'')}}</span>
      <div class="fill-space"></div>
      <div class="actions">
        <button mat-raised-button 
          matTooltip="Habilitar Edição"
          [hidden]="!canEdit() || !readOnly"
          (click)="onEnableEditClick()">
          Habilitar Edição
        </button>
        <button mat-icon-button color="primary"
          matTooltip="Copiar"
          (click)="onCopyClick()"
          [disabled]="!id"
          [hidden]='!canEdit()'>
          <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteClick()"
          matTooltip="Remover"
          [hidden]='!canDelete()'>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Mostrar Rastro no Mapa"
          (click)="onHistoricalTrackingClick()">
          <mat-icon class="material-icons-outlined">my_location</mat-icon>
        </button>
        <button mat-icon-button (click)="onPasswordSendClick()"  
          [disabled]='isSendPasswordButtonDisabledForUser()'
          [hidden]='isSendPasswordButtonHiddenForUser()'
          matTooltip="Enviar Senha por E-mail">
          <mat-icon class="material-icons-outlined">email</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!canRevert()" (click)="onRevertClick()" 
          [hidden]='!canEdit()'
          matTooltip="Desfazer Modificações">
          <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
        </button>
        <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
          <button mat-icon-button  (click)="onSaveClick($event)" name="buttonSaveUser"
            [hidden]='!authorizationService.userHasPermission(permission.CREATE_EDIT_USER)'
            [disabled]="!canSave() || email.invalid" matTooltip="Salvar">
            <mat-icon class="material-icons-outlined">save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="sipd-content bg-white">
      <div class="p-3">
        <h5 class="user-edit-label">Identificação</h5>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Nome</mat-label>
          <input cdkFocusInitial matInput name="name" placeholder="Nome" [(ngModel)]="view['name']"
            oninput="this.value = this.value.trimLeft()" required [readonly]="readOnly">
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>E-mail</mat-label>
          <input matInput name="email" placeholder="E-mail" [required]="!isProfessionalProfile()" [(ngModel)]="view['email']" #email="ngModel" email
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            oninput="this.value = this.value.toLowerCase().trim()" [readonly]="readOnly">
          <mat-error *ngIf="email.invalid">Formato de e-mail inválido</mat-error>
          <mat-error *ngIf="email.hasError('required')">Campo Obrigatório</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>CPF</mat-label>
          <input matInput name="cpf" placeholder="CPF" [(ngModel)]="view['cpf']" #cpf="ngModel" cpf
            mask="000.000.000-00" [dropSpecialCharacters]="false" [readonly]="readOnly">
          <mat-error *ngIf="cpf.invalid">Entre apenas com números, ponto e hífen</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Celular</mat-label>
          <input matInput type="tel" name="mobilePhone" matInput placeholder="Celular" [(ngModel)]="view['mobilePhone']"
            #mobilePhone="ngModel" mobilePhone mask="(00) 0000-0000||(00) 0 0000-0000" [dropSpecialCharacters]="false" [readonly]="readOnly">
          <mat-error *ngIf="mobilePhone.invalid">Entre apenas com números, espaço ou hífen</mat-error>
        </mat-form-field>

        <single-autocomplete [data]="entityCacheService.getCompanies()" name="name" placeholder="Empresa" [(valueData)]="view['company']"
          required [disabled] ="readOnly" fxFlex>
        </single-autocomplete>

        <single-autocomplete [data]="entityCacheService.getPlacements()" name="name" placeholder="Lotação de Origem" [(valueData)]="view['originPlacement']" (selectionChangedEvent)="onOriginPlacementChanged($event)"
          required [disabled] ="readOnly || !canProfileEditField()" fxFlex>
        </single-autocomplete>

        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
      </div>

      <div class="p-3">
        <h5 class="user-edit-label">Perfil de Acesso</h5>
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

        <single-autocomplete [data]="getAllowedProfiles()" name="name" placeholder="Perfil" [(valueData)]="view['profileId']" optionValue="id"
          [required]="true" [disabled]="!canProfileEditField() || readOnly" (selectionChangedEvent)="onProfileChanged($event)"  fxFlex>
        </single-autocomplete>
        
          <div class="form-spacer" fxFlex>
          <mat-slide-toggle class="mt-3 mb-5" name="appAccess" [(ngModel)]="view['appAccess']"
          [disabled]="disableAppAccess || !canProfileEditField() || readOnly" [checked]="getAppAccess()"
            color="primary">
            Acesso ao App
          </mat-slide-toggle>
        </div> 

        <multiselect-autocomplete #listPlacements [data]="entityCacheService.getPlacements()" name="name" placeholder="Outras Lotações Acessadas" [matTooltip]="placementsTooltip()"
          [(valueData)]="view['placements']" [readonly] ="readOnly" [required]=true [disabled]="disableOtherPlacements || readOnly || !canProfileEditField()" fxFlex>
        </multiselect-autocomplete>
       
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{isProfessionalProfile() ? 'Login App': 'Chave Petrobras'}}</mat-label>
          <input name="login" matInput
            [placeholder]="isProfessionalProfile() ? 'Login App': 'Chave Petrobras'"
            [(ngModel)]="view['login']" oninput="this.value = this.value.toLowerCase().trim()" required [readonly]="readOnly">
          <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field> 
        
        <mat-form-field *ngIf="!passwordHide" appearance="outline" fxFlex>
          <input name="password" matInput placeholder="Senha App" [type]="hide ? 'password' : 'text'" type="password"
            [(ngModel)]="view['password']" autocomplete="new-password" [readonly]="readOnly">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>

        <div class="form-spacer" fxFlex>
          <button mat-icon-button class="mt-3 mb-5 btn-register-small" name="buttonChangePassword"
            (click)="passwordHide = !passwordHide" *ngIf="!isCreating() && getAppAccess() && !readOnly">
            {{passwordHide ? 'Redefinir Senha' : 'Cancelar'}}
          </button>
        </div>

        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>
        <div class="form-spacer" fxFlex></div>

      </div>

      <div class="p-3">
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
        <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
          <mat-label>Equipe</mat-label>
          <input name="patrolTeam" matInput [value]="patrolTeamName" readonly>
        </mat-form-field>
      </div>

    </div>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</form>
