import LanguageUtils from '../service/util/language-utils';
import { EntityModel } from './entity.model';

export class PlacementModel extends EntityModel {
  gs:         string;
  regional:   string;
  name:       string;
  
  static addLanguageMap(){
    LanguageUtils.languageMap['gs'] = 'Gerência';
    LanguageUtils.languageMap['regional'] = 'Regional';
  }
}
