
import { UserModel } from './user.model';
import { GeoModel } from './geo.model';
import { ObservedAreaStatusDescription, ObservedAreaTypeDescription } from './enums.enum';
import * as moment from 'moment';
import LanguageUtils from '../service/util/language-utils';

export class ObservedAreaModel extends GeoModel {

  status:               string;
  areaType:             string;
  responsible:          UserModel;
  creator:              UserModel;
  createdAt:            number;
  updatedAt:            number;
  startDate:            number;
  endDate:              number;
  product:              string;
  volume:               string;
  volumeCheck:          string;
  channel:              string; // TODO scuri Deveria se chamar communicationChannel
  notes:                string;
  band:                 string; // Nome da Faixa (não é id)
  duct:                 string;
  km:                   string;
  latLong:              string;
  stretchStartKM:       string;
  stretchEndKM:         string;
  stretchStartLatLong:  string;
  stretchEndLatLong:    string;
  stretch:              string;
  city:                 string;

  constructor(){
    super();
    this.type = 'ObservedArea';
  }

  public static getPopupContent(feature): string{
    const area = feature.properties;
    return `<h5 style="text-align: center">Área Observada</h5>
            <h6 style="text-align: center"><b>${ area.name }</b></h6>
            <div><b>Status:</b> ${ ObservedAreaStatusDescription[area.status] } </div>
            <div><b>Tipo:</b> ${ ObservedAreaTypeDescription[area.areaType] } </div>
            <div><b>Responsável:</b> ${ area.responsible?.name } </div>
            <div><b>Faixa:</b> ${ area.band? area.band: '' } </div>
            <div><b>KM:</b> ${ area.stretchStartKM && area.stretchEndKM? area.stretchStartKM + " - " + area.stretchEndKM : (area.km ? area.km : '') } </div>
            <div><b>Data de Início:</b> ${moment( area.startDate ).format('DD/MM/YYYY HH:mm:ss') } </div>
            <div><b>Data de Fim:</b> ${moment( area.endDate ).format('DD/MM/YYYY HH:mm:ss') } </div>
            <div><b>UF(s):</b> ${ area.states } </div>
           `;
  }
  
  static addLanguageMap(){
    LanguageUtils.languageMap['creator'] = 'Cadastrador';
    LanguageUtils.languageMap['areaType'] = 'Tipo';
    LanguageUtils.languageMap['startDate'] = 'Data de Início';
    LanguageUtils.languageMap['endDate'] = 'Data de Fim';
    LanguageUtils.languageMap['responsible'] = 'Responsável';
    LanguageUtils.languageMap['product'] = 'Produto';
    LanguageUtils.languageMap['volume'] = 'Volume (m³)';
    LanguageUtils.languageMap['band'] = 'Faixa';
    LanguageUtils.languageMap['duct'] = 'Duto';
    LanguageUtils.languageMap['stretchStartKM'] = 'Início Trecho (KM)';
    LanguageUtils.languageMap['stretchEndKM'] = 'Fim Trecho (KM)';
    LanguageUtils.languageMap['stretchStartLatLong'] = 'Início Trecho (Lat, Long)';
    LanguageUtils.languageMap['stretchEndLatLong'] = 'Fim Trecho (Lat, Long)';
    LanguageUtils.languageMap['stretch'] = 'Trecho';
    LanguageUtils.languageMap['km'] = 'KM';
    LanguageUtils.languageMap['latLong'] = 'KM (Lat, Long)';
    LanguageUtils.languageMap['city'] = 'Cidade';
    LanguageUtils.languageMap['volumeCheck'] = 'Data Verificação de Volume';
    LanguageUtils.languageMap['channel'] = 'Canal de Comunicação';
    LanguageUtils.languageMap['notes'] = 'Notas';
  }
}
