import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { OperationStatusDescription, OperationType, OperationTypeDescription, SourceType } from 'src/app/model/enums.enum';
import FieldUtils from 'src/app/service/util/field-utils';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { AbstractSignalModel } from 'src/app/model/abstract.signal.model';
import { VehicleSignalModel } from 'src/app/model/vehicle.signal.model';
import DateUtils from 'src/app/service/util/date-utils';
import * as XLSX from 'xlsx';
import { PatrolService } from 'src/app/service/model/patrol.service';
import { VerificationService } from 'src/app/service/model/verification.service';
import { OperationModel } from 'src/app/model/operation.model';
import { ESP } from 'src/app/common/constants';
import * as turf from '@turf/turf'

@Component({
  selector: 'app-track-points-dialog',
  templateUrl: './track-points-dialog.component.html',
  styleUrls: ['./track-points-dialog.component.scss']
})
export class TrackPointsDialogComponent implements OnInit {

  displayedColumns: string[];

  dataSource: MatTableDataSource<AbstractSignalModel>= new MatTableDataSource();

  @ViewChild(MatInput, { static: true }) searchInput: MatInput;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** The MatPaginator bound to the grid to show data */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // pagination
  pageSize = environment.defaultPageSize;
  pageLength = undefined;

  // Enum
  operationStatusDescription = OperationStatusDescription;
  operationTypeDescription = OperationTypeDescription;

  constructor(public dialogRef: MatDialogRef<TrackPointsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                signals: AbstractSignalModel[],
                title: string,
                isVehicle: boolean,
                showOperation: boolean,
                companyName: string},
                private patrolService: PatrolService,
                private verificationService: VerificationService,
                protected dialog: MatDialog) {
    if (data.signals[0].sourceType == SourceType.MOBILE_APP) {
      this.data.isVehicle = false;
    }
    else {
      this.data.isVehicle = true;
    }

    this.pageLength = data.signals.length;

    if (this.data.showOperation) this.loadOperations();
  }

  ngOnInit(): void {
    if (this.data.isVehicle) {
      this.displayedColumns = [
        'timestamp',
        'receivedTimestamp',
        'latLong',
        'motor',
        'movimento',
        'velocidade',
        'gps',
        'precisao',
        'direcao'];
    }
    else {
      this.displayedColumns = [
        'timestamp',
        'receivedTimestamp',
        'latLong'];
    }

    if (this.data.showOperation){
      this.displayedColumns.push('OperationIdentifier');
      this.displayedColumns.push('OperationStatus');
    }

    this.sort.sort({id: "timestamp", start: 'asc', disableClear: false});
    this.buildDataSource();
  }

  buildDataSource(){
    this.dataSource.data = this.data.signals;
    this.dataSource.paginator = this.paginator;

    this.dataSource.filterPredicate = (entity: AbstractSignalModel, filter: string): boolean => {
      return this.getStringEntityForFilter(entity).indexOf(filter) != -1;
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };

    this.dataSource.sort = this.sort;
  }

  /* reducer method */
  getProperty (o, i) {
    if ( !o ) { return ''; }
    return o[i];
  }

  getPropertyByPathDefault(item: Object, property: string){
    let value = property.split('.').reduce(this.getProperty, item);
    if (typeof value === 'string'){
        value = this.lowerAndTrimText(value);
    }
    return value ? value : '';
  }

  getPropertyByPath(item: Object, property: string){
    switch (property) {
      case 'latLong':
        return this.getLatLong(item as AbstractSignalModel);
      case 'timestamp':
        return this.getTimestamp(item['timestamp']);
      case 'receivedTimestamp':
        return this.getTimestamp(item['receivedTimestamp']);
      case 'OperationStatus':
        return item['operationStatus']? OperationStatusDescription[item['operationStatus']]: '';
      case 'OperationIdentifier':
        return (item['operationType'] && item['operationId'])? OperationTypeDescription[item['operationType']] + ' - ' +  item['operationIdentifier']: '';
      default: return this.getPropertyByPathDefault(item, property);
   }
  }

  protected lowerAndTrimText(text: string) {
    return (text) ? text.trim().toLowerCase() : '';
  }

  getLatLong(signal: AbstractSignalModel){
    if(!signal) return "";
    return FieldUtils.coordToString(signal.latitude) + ',' + FieldUtils.coordToString(signal.longitude);
  }

  getTimestamp(timestamp: number){
    return DateUtils.timestampToStringInSeconds(timestamp);
  }

  protected getStringEntityForFilter(signal: AbstractSignalModel): string {
    const timestamp = this.getTimestamp(signal.timestamp);
    const receivedTimestamp = this.getTimestamp(signal.receivedTimestamp);
    const latLong = this.getLatLong(signal);

    let str = timestamp + ESP + receivedTimestamp + ESP + latLong;

    if (this.data.showOperation){
      str += (signal.operationType && signal.operationId)? OperationTypeDescription[signal.operationType] + ' - ' +  (signal as any).operationIdentifier: '';
      str += signal.operationStatus? OperationStatusDescription[signal.operationStatus]: '';
    }

    if (this.data.isVehicle) {
      const motor = this.lowerAndTrimText((<VehicleSignalModel>signal).motor);
      const movimento = this.lowerAndTrimText((<VehicleSignalModel>signal).movimento);
      const velocidade = this.lowerAndTrimText((<VehicleSignalModel>signal).velocidade);
      const gps = this.lowerAndTrimText((<VehicleSignalModel>signal).gps);
      const precisao = this.lowerAndTrimText((<VehicleSignalModel>signal).precisao);
      const direcao = this.lowerAndTrimText((<VehicleSignalModel>signal).direcao);

      str += ESP + motor + ESP + movimento + ESP + velocidade + ESP + gps + ESP + precisao + ESP + direcao;
    }

    return str;
  }

  applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onRemoveSearch(){
    this.searchInput.value = '';
    this.dataSource.filter = '';
  }

  onPageChanged(event){
  }

  protected getExportColValue(col, model):string[] {
    let colsValues: string[] = [];
    if (col == 'timestamp'){
      colsValues.push(this.getTimestamp(model['timestamp']));
    }
    else if (col == 'receivedTimestamp'){
      colsValues.push(this.getTimestamp(model['receivedTimestamp']));
    }
    else if (col == 'latLong'){
      colsValues.push(this.getLatLong(model));
    }
    else if (col == 'OperationIdentifier'){
      colsValues.push((model.operationType && model.operationIdentifier)? OperationTypeDescription[model.operationType] + ' - ' + model.operationIdentifier: '');
    }
    else if (col == 'OperationStatus'){
      colsValues.push(model.operationStatus? OperationStatusDescription[model.operationStatus]: '');
    }
    else {
      colsValues.push(model[col]);
    }
    return colsValues;
  }

  private pushTitle(ws_data){
    let line = [];
    line.push("Rastro de:"); line.push(this.data.title);
    ws_data.push(line);
    line = [];
    line.push("Empresa:"); line.push(this.data.companyName);
    ws_data.push(line);
    ws_data.push([]); // linha vazia
    return ws_data;
  }

  private latLngToTurfPoint(data: AbstractSignalModel): turf.Coord{
    return turf.point([data.longitude, data.latitude]); // Note que é invertido (longitude, latitude)
  }

  private updateExtraData(){
    const data = this.dataSource.filteredData; 
    let distance = 0;
    let time = 0;
    let prevLatLng = this.latLngToTurfPoint(data[0]);
  
    for(var i=0; i<data.length; i++) {
      let signal = data[i] as any;
      if (i == 0){
        signal.distance = 0;
        signal.time = '00:00:00';
        signal.deltaDistance = 0;
        signal.deltaTime = 0;
      }
      else {
        let curLatLng = this.latLngToTurfPoint(data[i]);
        const deltaDistanceKm = turf.distance(prevLatLng, curLatLng, {units: 'kilometers'});
        prevLatLng = curLatLng;

        signal.deltaDistance = deltaDistanceKm*1000;
        distance += deltaDistanceKm;
        signal.distance = distance;

        let deltaTimestamp = data[i].timestamp - data[i-1].timestamp;
        signal.deltaTime = Math.floor(deltaTimestamp / 1000);
        time += deltaTimestamp;
        signal.time = DateUtils.durationToTimeString(time);
      }
    }
  }

  private parseDataToArray(columnsTitles, ws_data){
    let line = [];
    columnsTitles.forEach((col:string) => { line.push(col); });
    ws_data.push(line);

    this.updateExtraData();

    this.dataSource.filteredData.forEach(model => {
      let line = [];
      this.displayedColumns.forEach(col => {
        line = line.concat(this.getExportColValue(col, model));
      });
      line = line.concat(this.getExportColValue('distance', model));
      line = line.concat(this.getExportColValue('time', model));
      line = line.concat(this.getExportColValue('deltaDistance', model));
      line = line.concat(this.getExportColValue('deltaTime', model));
      ws_data.push(line);
    })

    return ws_data;
  }

  public exportXls(columnsTitles){
    const EXCEL_EXTENSION = '.xlsx';
    var ws_name = this.data.title.substring(0, 30); // Aba não pode ter mais de 30 characteres
    var wb = XLSX.utils.book_new();

    const startTimestamp = this.data.signals[0].timestamp;
    const endTimestamp = this.data.signals[this.data.signals.length-1].timestamp;

    let startDate = moment(startTimestamp).format('DD-MM-yyyy HH-mm');
    let endDate = moment(endTimestamp).format('DD-MM-yyyy HH-mm');

    let ws_data = [];

    ws_data = this.pushTitle(ws_data);

    /* make worksheet */
    ws_data = this.parseDataToArray(columnsTitles, ws_data);

    var ws = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, ws_name+' ['+startDate+', '+endDate+']'+EXCEL_EXTENSION);
  }

  onExportClick(){
    let columnsTitles = [
      'Data/Hora',
      'Data/Hora Recebido',
      'Lat, Long'];
    if (this.data.isVehicle) {
      columnsTitles.push(
        'Motor',
        'Movimento',
        'Velocidade',
        'GPS',
        'Precisão',
        'Direção');
    }
    if (this.data.showOperation){
      columnsTitles.push('Operação');
      columnsTitles.push('Status');
    }
    columnsTitles.push(
      'Distância (Km)',
      'Tempo',
      'Δd (m)',
      'Δt (s)',
    );

    this.exportXls(columnsTitles);
  }

  private updateOperation(operationId: string, operationType: string, op: OperationModel){
    this.data.signals.forEach(signal => {
      if (signal.operationId == operationId && signal.operationType == operationType) {
        signal['operationIdentifier'] = op.identifier;
      }
    });    
  }

  private loadOperation(operationId: string, operationType: string) {
    if (!operationId) return null;

    switch (operationType) {
      case (OperationType.PATROL): 
        this.patrolService.loadById(operationId).toPromise().then (op => {
          op.type = operationType;
          this.updateOperation(operationId, operationType, op);
        });
        break;
      case (OperationType.EVENT_VERIFICATION): 
        this.verificationService.loadById(operationId).toPromise().then (op => {
          op.type = operationType;
          this.updateOperation(operationId, operationType, op);
        });
        break;
    }
    return null;
  }

  private loadOperations() {
    let ops: Map<string, {operationId: string, operationType: string}> = new Map<string, {operationId: string, operationType: string}>();

    this.data.signals.forEach(signal => {
      if (!ops.get(signal.operationId + signal.operationType)) {
        ops.set(signal.operationId + signal.operationType, {operationId: signal.operationId, operationType: signal.operationType});
      }
    });

    ops.forEach(op => {
      this.loadOperation(op.operationId, op.operationType);
    });
  }
}
