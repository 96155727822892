import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { EventMainActionModel } from 'src/app/model/event.main.action.model';

@Component({
  selector: 'app-event-main-action-dialog',
  templateUrl: './event-main-action-dialog.component.html',
  styleUrls: ['../../../app.component.scss', './event-main-action-dialog.component.scss']
})
export class EventMainActionDialogComponent implements OnInit {

  comment: string;

  constructor(private logger:                               NGXLogger,
              public dialogRef:                             MatDialogRef<EventMainActionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public action:       EventMainActionModel){
    this.comment = action.comment;
  }

  ngOnInit(): void {
    this.logger.debug(this.action);
  }

  onSaveClick() {
    this.action.comment = this.comment;
  }
}
