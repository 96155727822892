import { UserType, OperationStatusDescription, OperationStatus, InspectionStatusTypeDescription, InspectionStatusType } from './../model/enums.enum';
import { FileKmlModel } from 'src/app/model/filekml.model';
/* tslint:disable:import-spacing */
import { OnDestroy, ChangeDetectorRef, Inject, OnInit, Directive, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import { Subscription } from 'rxjs';
import ObjectID from 'bson-objectid';
import {UserModel} from '../model/user.model';
import { OperationModel } from '../model/operation.model';
import {VehicleModel} from '../model/vehicle.model';
import {PatrolTeamModel} from '../model/patrolteam.model';
import {RouteModel} from '../model/route.model';
import {OperationService} from '../service/model/operation.service';
import { OperationType, PointStatus, ShiftDescription, ServiceTypeDescription } from 'src/app/model/enums.enum';
import * as moment from 'moment';
import {RouteGeographicalService} from '../service/model/route-geographical-service';
import { StorageService } from 'src/app/service/storage-service';
import { ToastrService } from 'ngx-toastr';
import { LOCATION_UPDATE_PREFIX, MAX_FILE_SIZE, MESSAGE_PAGE, MapInfo, SAVE_DATA_PREFIX } from 'src/app/common/constants';
import { FILL_DATA_PREFIX, MAP_PAGE } from 'src/app/common/constants';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import * as GoldenLayout from 'golden-layout';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationDialogComponent } from '../general/confirmation-dialog/confirmation-dialog.component';
import { InspectionModel } from '../model/inspection.model';
import { InspectionPointModel } from 'src/app/model/inspection.point.model';
import { PointType, AttachmentNumber } from '../model/enums.enum';
import { PointService } from 'src/app/service/model/point.service';
import FieldUtils from '../service/util/field-utils';
import ListUtils from 'src/app/service/util/list-utils';
import { ImportPointsDialogComponent } from '../general/operation/import-points-dialog/import-points-dialog.component';
import { InsertPointDialogComponent } from '../general/operation/insert-point-dialog/insert-point-dialog.component';
import { ImportPointsKmlDialogComponent } from '../general/operation/import-points-kml-dialog/import-points-kml-dialog.component';
import { first } from 'rxjs/operators';
import { CompanyModel } from '../model/company.model';
import { EntityModel } from '../model/entity.model';
import { CommonModel } from '../model/common.model';
import { MessageComponentData, MessageComponentType } from '../general/message/message.component';
import { CentralFilterModel } from './central/central-filter-dialog/central-filter.model';
import { EntityCacheService } from '../service/model/entity.cache.service';
import { EditComponent } from './edit-component';
import { AttachmentModel } from '../model/attachment.model';
import { AnswerListComponent } from '../general/answer-list/answer-list.component';
import * as esri_routing from "@esri/arcgis-rest-routing";
import * as esri_auth from '@esri/arcgis-rest-auth';
import tokml from '../general/tokml';

const authentication = new esri_auth.UserSession({
  token: MapInfo.ARCGIS_TOKEN
})

@Directive()
export abstract class OperationEditComponent extends EditComponent implements OnInit, OnDestroy {

  filteredTeams:       PatrolTeamModel[]=[]; 
  filteredCompanies:   CompanyModel[]=[];

  shiftDescription = ShiftDescription;
  serviceTypeDescription = ServiceTypeDescription;
  inspectionStatusTypeDescription = InspectionStatusTypeDescription;

  /** The list of columns to display on the points grid */
  pointDisplayedColumns: string[];

  /** The data source for points table */
  pointsDataSource: MatTableDataSource<InspectionModel>;

  /** The structure to control points grid selection  */
  pointSelection = new SelectionModel<InspectionModel>(true, []);

  // Usados somente quando os kmls são modificados pela interface
  fileKmlRoute:   File;
  removedKmlRoute: boolean;  

  tempId: string;

  private reloadCompaniesSubscription: Subscription;
  private reloadPatrolTeamsSubscription: Subscription;

  denySavePointsToRegistration:boolean = true;

  // Edição dos pontos no mapa
  editOnMap: boolean = false;

  noAccessPointsDataSource: MatTableDataSource<InspectionModel>;
  /** The list of columns to display on the not access points grid */
  noAccessPointDisplayedColumns: string[];

  // Pontos de inspeção que foram reportados como fora da cerca eletrónica
  electronicFencePoints: InspectionModel[] = [] ;
  
  finishedPoints = 0; // quantidade de pontos marcados como concluídos e que não foram reportados nas alertas por cerca eletrônica 

  alertFilterModel:  CentralFilterModel;

  isHiddenButtonArchiveForUser : boolean;

  // arquivos axexo
  attachmentFile1 :   File;
  attachmentFile2 :   File;
  attachmentFile3 :   File;
  attachmentFile4 :   File;
  attachmentFile5 :   File;

  //arquivos backup, em caso de eliminar um ou mais arquivo e desfazer as modificações
  back_attachmentFile1 :   File;
  back_attachmentFile2 :   File;
  back_attachmentFile3 :   File;
  back_attachmentFile4 :   File;
  back_attachmentFile5 :   File;

  seeFile : boolean = true;
  attachmentNumber  = AttachmentNumber
  
  /* Lista de respostas do formulário */
  @ViewChild('listAnswerOperation', { static: true }) listAnswerComponent: AnswerListComponent;
  
  constructor(logger:                     NGXLogger,
              public operationType:       OperationType,
              public operationService:    OperationService,
              dialog:                     MatDialog,

              public modelName:           string,
              public title:               string,
              protected storageService:   StorageService,

              public componentName:       string,
              public tabTitle:            string,
              public entityCacheService:  EntityCacheService,

              protected routeGeographicalObjectService: RouteGeographicalService,
              protected changeDetector:   ChangeDetectorRef,
              toastr:                     ToastrService,
              authorizationService:       AuthorizationService,
              protected pointService: PointService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, operationService, dialog, modelName, title, storageService,
          componentName, tabTitle, toastr, authorizationService, goldenLayout, container);
  }

  ngOnInit() {
    this.logger.debug('OperationEditComponent.ngOnInit()');

    this.subscribeOnLocationUpdate();

    this.initializeFields(); // Precisa vir antes da super.ngOnInit
    super.ngOnInit();
    this.view['forms'] = [];

    this.pointDisplayedColumns = ['select',
                                  'base',
                                  'number',
                                  'id',
                                  'band',
                                  'km',
                                  'latlong',
                                  'city',
                                  'state',
                                  'activities',
                                  'status',
                                  'access',
                                  'form-fill'
                                  ];

    this.noAccessPointDisplayedColumns = ['number',                                  
                                  'band',
                                  'km',
                                  'latlong'                                  
                                  ];
    
    if(!this.alertFilterModel){
      this.alertFilterModel = new CentralFilterModel();      
    }
    
    this.isOperationArchiveButtonHiddenForUser();

    this.glOnResize();
  }

  ngOnDestroy() {
    this.logger.debug('OperationEditComponent.ngOnDestroy()');

    this.removeFromMap(true);

    this.reloadCompaniesSubscription?.unsubscribe();
    this.reloadPatrolTeamsSubscription?.unsubscribe();

    this.glUnSubscribeEvent(LOCATION_UPDATE_PREFIX + 'operation-edit');

    super.ngOnDestroy();
  }

  getOperationId(): string {
    if (this.model.id)
      return this.model.id;
    else {
      if (!this.tempId)
        this.tempId = ObjectID.generate(); // id temporário para mostrar operação no mapa
      return this.tempId;
    }
  }

  private removeFromMap(removeEdition: boolean) {
    if (!this.model)
      return;

    if (removeEdition) {
      if (this.editOnMap) {
        // Remove a edição mas deixa a operação lá do jeito que estiver
        this.editOnMap = false;
        this.glEmitEvent(FILL_DATA_PREFIX + MAP_PAGE, {operationId: this.getOperationId(), operationType: this.operationType,
                                                       removeEditionMode: true});
      }
    }
    else {
      // Remove somente o temporário, mas mantém a operação se ela possuir informação de localização,
      // mantém inclusive o modo de edição no mapa se estiver ativo
      if (this.fileKmlRoute || this.removedKmlRoute)
        this.glEmitEvent(FILL_DATA_PREFIX + MAP_PAGE, {operationId: this.getOperationId(), operationType: this.operationType,
                                                       removedKmlRoute: true});
    }
  }

  getEmptyPatrolTeam(): PatrolTeamModel {
    let patrolTeam: PatrolTeamModel = new PatrolTeamModel();
    patrolTeam.users = [new UserModel(), new UserModel()];
    patrolTeam.vehicle = new VehicleModel();
    patrolTeam.company = new CompanyModel();
    patrolTeam.patrolCoordinator = new UserModel();
    patrolTeam.shift = '';
    return patrolTeam;
  }

  initializeFields(){
    this.clearKmlRoute();

    // Inicializando campos para evitar erros no HTML
    this.view['startTime'] = "";

    this.view['patrolTeam'] = this.getEmptyPatrolTeam();

    this.view['inspections'] = [];

    this.buildPointsDataSource(0);
  }

  createOperationData() {
    this.logger.debug('OperationEditComponent.createOperationData()');

    this.initializeFields();

    this.initializeAnalystOrAuthor();

    this.view['status'] = OperationStatus.PLANNED;
    this.view['type'] = this.operationType;
    this.view['identifier'] = "[NOVA]";
    this.view['inspections'] = [];
    this.view['autoRoute'] = true;
  }

  clearCopyData(){
    this.copyKmlFiles();

    super.clearCopyData();

    this.model['identifier'] = null;
    this.model['status'] = OperationStatus.PLANNED;
    this.model['archived'] = false;
    this.model['executedPoints'] = null;
    this.model['situationFound'] = null;
    this.model['dateSituationFound'] = null;
    this.model['expired'] = null;
    this.model['expiredDate'] = null;
    this.model['updatedAt'] = null;
    this.model['lastSync'] = null;

    this.model['attachmentOpFile1'] = null;
    this.model['attachmentOpFile2'] = null;
    this.model['attachmentOpFile3'] = null;
    this.model['attachmentOpFile4'] = null;
    this.model['attachmentOpFile5'] = null;

    if (this.model['inspections']) {
      let inspections: InspectionModel [] = this.model['inspections'];
      if (inspections.length > 0) {
        inspections.forEach( (inspection: InspectionModel) => {
          inspection.identifier = null;
          inspection.status = 'NOT_STARTED';
          inspection.inspectionPointNoAccess = false;
          inspection.inspectionForms = null;
        });
      }
    }
  }

  mapViewToModel() {
    if (this.isSaving && this.isCreating())
      this.view['identifier'] = null;

    super.mapViewToModel();

    this.logger.debug('OperationEditComponent.mapViewToModel()');

    this.extractKmlNameAndBuildRouteObject();
    this.extractAttachmentFileName();
  }

  updateOperationFromPatrolTeam(operation: OperationModel) {
    // Melhoria para abrir operações antigas de antes da mudanças de placement e company (mas a equipe precisa estar atualizada)
    if (!operation.patrolTeam) return;

    if (!operation.company && operation.patrolTeam.company) {
      operation.company = operation.patrolTeam.company;
    }

    if (!operation.placement && operation.company) {
      operation.placement = operation.company.placement;
    }

    if (!operation.serviceType && operation.patrolTeam.serviceType) {
      operation.serviceType = operation.patrolTeam.serviceType;
    }
  }

  mapModelToView() {
    if (!this.model['type']) {
      this.model['type'] = this.operationType;
    }

    this.updateOperationFromPatrolTeam(<OperationModel>this.model);

    super.mapModelToView();

    this.logger.debug('OperationEditComponent.mapModelToView()');

    const operation: OperationModel = <OperationModel>this.model;
    if (operation.route) {
      if (operation.route.fileRouteKml) {
        this.view['filenameKmlRoute'] = operation.route.fileRouteKml.name.charAt(0) == '['? operation.route.fileRouteKml.name: "[Rota Importada] " + operation.route.fileRouteKml.name;
      }
    }

    if (!this.model['inspections'])
      this.view['inspections'] = [];

    if (!this.model['identifier']){
      this.view['identifier'] = "[NOVA]";
    }

    this.buildPointsDataSource(1);

    if(!this.model['executedPoints'])
      this.finishedPoints = 0;
    else
      this.finishedPoints = parseInt(this.model['executedPoints']); 

    if (this.copy){
      this.view['analyst'] = null;
      this.view['author'] = null;
      this.initializeAnalystOrAuthor();
    }
  
    this.glUpdateTabTitle(this.modelName + ': ' + this.view['identifier']);

    if (this.model['attachmentOpFile1']) {
      this.view['attachmentFile1'] = this.model['attachmentOpFile1'].name + "." + this.model['attachmentOpFile1'].extension;
    }

    if (this.model['attachmentOpFile2']) {
      this.view['attachmentFile2'] = this.model['attachmentOpFile2'].name + "." + this.model['attachmentOpFile2'].extension;
    }

    if (this.model['attachmentOpFile3']) {
      this.view['attachmentFile3'] = this.model['attachmentOpFile3'].name + "." + this.model['attachmentOpFile3'].extension;
    }

    if (this.model['attachmentOpFile4']) {
      this.view['attachmentFile4'] = this.model['attachmentOpFile4'].name + "." + this.model['attachmentOpFile4'].extension;
    }

    if (this.model['attachmentOpFile5']) {
      this.view['attachmentFile5'] = this.model['attachmentOpFile5'].name + "." + this.model['attachmentOpFile5'].extension;
    }
  }

  onEnableEditClick() {
    if (this.canEdit()) {
      this.readOnly = false;

      this.initializeAnalystOrAuthor();
    }
  }

  clearPreviousData() {
    this.removeFromMap(true);
    this.clearKmlRoute();
  }

  initializeAnalystOrAuthor(){
    if (this.operationType == OperationType.EVENT_VERIFICATION) {
      if (!this.view['analyst']) {
        this.currentUserAsField('analyst');
      }
    }

    if (this.operationType == OperationType.PATROL) {
      if (!this.view['author']) {
        this.currentUserAsField('author');
      }
    }
  }

  loadFormOptionsData() {
    this.entityCacheService.loadUsers(this.loadingListService);

    this.entityCacheService.loadPlacements(this.loadingListService);
    //this.checkObjectInOptionData('placement', this.placements); // TODO scuri Não podemos mudar esse array

    let _this = this; // Necessário por causa do contexto das callacks

    const onCompaniesLoad = function() {
      _this.updateFilteredCompanies();
      _this.updateCompanyForCopy();
    };
    this.entityCacheService.loadCompanies(this.loadingListService, onCompaniesLoad);
    this.reloadCompaniesSubscription = this.entityCacheService.onCompaniesReload().subscribe(onCompaniesLoad);

    this.entityCacheService.loadPublishedForms(this.loadingListService);

    const onPatrolTeamsLoad = function() {
      _this.updateFilteredTeams();
      _this.updatePatrolTeamForCopy();
    };
    this.entityCacheService.loadPatrolTeams(this.loadingListService, onPatrolTeamsLoad);
    this.reloadPatrolTeamsSubscription = this.entityCacheService.onPatrolTeamsReload().subscribe(onPatrolTeamsLoad);
  }

  clearKmlRoute(removed?) {
    this.fileKmlRoute = undefined;
    this.view['filenameKmlRoute'] = undefined;
    this.view['KmlRouteId'] = undefined;
    this.view['route'] = undefined;
    this.removedKmlRoute = removed? true: undefined;
    this.view['autoRoute'] = false;
  }

  setKmlRouteFile(file: File): void {
    this.logger.debug('OperationEditComponent.setKmlRouteFile()');

    if (file) { // Usuário selecionou um arquivo
      // A Rota é importada aqui apenas para validação
      this.routeGeographicalObjectService.importKmlRoute(file, <OperationModel>this.model).then( (geoRoute) => {
        geoRoute.on('ready', () => {
          if (this.routeGeographicalObjectService.validateRouteGeometry(geoRoute)) {
            this.fileKmlRoute = file;
            this.view['autoRoute'] = false;

            this.view['filenameKmlRoute'] = "[Rota Importada] " + file.name;
            this.view['KmlRouteId'] = ObjectID.generate(); // Para que a mudança possa ser detectada mesmo que o nome do arquivo seja identico ao anterior
            this.removedKmlRoute = false;

            if (this.editOnMap) this.sendEditionModeToMap();
          }
          else {
            this.clearKmlRoute();

            const title = 'KML Inválido!';
            const message = 'A Rota deve ser formada por ao menos uma linha.';
            this.toastr.error(message, title);
          }
        });
        geoRoute.on('error', () => {
          this.clearKmlRoute();

          const title = 'KML Inválido!';
          const message = 'Erro no arquivo KML de Rota';
          this.toastr.error(message, title);
         });
      });
    }
    else { // Usuário pressionou Remover
      this.clearKmlRoute(true);

      if (this.editOnMap) this.sendEditionModeToMap();
    }
  }

  copyKmlFiles(){
    const operation: OperationModel = <OperationModel>this.model;
    if (operation.route && operation.route.fileRouteKml) {
      this.routeGeographicalObjectService.loadKMLFile(this.model.id, this.operationType, operation.route.fileRouteKml.name).pipe(first()).subscribe( (file) => {
        this.fileKmlRoute = file;

        if (operation.route && operation.route.fileRouteKml) {
          operation.route.fileRouteKml.id = undefined;
        }
      }, (error: any) => this.logger.error(error));
    }
  }

  private extractKmlNameAndBuildRouteObject() {
    if (this.fileKmlRoute) {
      let operation: OperationModel = <OperationModel>this.model;
      if (!operation.route) {
        operation.route = new RouteModel();
      }

      operation.route.fileRouteKml = new FileKmlModel();
      operation.route.fileRouteKml.name = this.fileKmlRoute.name;
      operation.route.fileRouteKml.id = undefined;
    }
  }

   /**
   * @description Called when the user clicks the Save button. It saves the Operation.
   * @param saveClickEvent The buttonClick event of the Save button
   */
  onSaveClick(saveClickEvent) {
    this.logger.debug('OperationEditComponent.onSaveClick()');

    // Necessary to avoid the form to be submitted before attaching the KML files
    saveClickEvent?.preventDefault();

    this.isSaving = true;
    this.backupModel = this.copyModel(this.model);
    this.mapViewToModel();

    if (this.isCreating()) {
      this.saveCreatedModel();
    }
    else {
      this.saveEditedModel();
    }
  }

  saveEditedModel(){
    this.logger.debug('OperationEditComponent.saveEditedModel');

    const formElements = this.getFormElements();

    this.operationService.edit(<OperationModel>this.model, formElements).pipe(first()).subscribe( entity => {
      (entity as OperationModel).type = this.operationType;

      this.entitySaved(entity);

      this.operationEdited(entity);
    },
    error => this.entitySaveError(error));
  }

  operationEdited(entity){}

  saveCreatedModel(){
    this.logger.debug('OperationEditComponent.saveCreatedModel');

    const formElements = this.getFormElements();

    this.operationService.create(<OperationModel>this.model, formElements).pipe(first()).subscribe( entity => {
      (entity as OperationModel).type = this.operationType;
      
      // Quando salva uma operação nova, tem que remover o temporário do mapa
      this.removeFromMap(false);

      // Atualizando o ID dos componentes criados.
      // Com isso a próxima ação de salvar será reconhecida como uma edição
      this.id = entity.id;
      this.glUpdateCreateId(this.id);

      this.entitySaved(entity);

      this.operationCreated(entity);
    },
    error => this.entitySaveError(error));
  }

  operationCreated(entity){}

  /**
   * Mapeia elementos que serão inseridos nas chamadas de criação/edição de verificações, por exemplo arquivo KML de rota
   * e arquivo KML de pontos.
   */
  getFormElements() {
    const formElements = new Map<string, object>();

    // If there's a new defined Route KML file, set it to the formElements to be sent on the request
    if (this.fileKmlRoute){
      formElements.set( 'fileKmlRoute',  this.fileKmlRoute );
    }
    // Attachment File

    if(this.attachmentFile1){
      formElements.set('attachmentOpFile1',  this.attachmentFile1);
    }

    if(this.attachmentFile2){
      formElements.set('attachmentOpFile2',  this.attachmentFile2);
    }

    if(this.attachmentFile3){
      formElements.set('attachmentOpFile3',  this.attachmentFile3);
    }

    if(this.attachmentFile4){
      formElements.set('attachmentOpFile4',  this.attachmentFile4);
    }

    if(this.attachmentFile5){
      formElements.set('attachmentOpFile5',  this.attachmentFile5);
    }

    return formElements;
  }

  private isInspectionFilled(elem: InspectionModel) {
    if ((elem.type != PointType.BASE) && (!elem.band ||
                                          (elem.inspectionPointId && !elem.km) ||  // Só testa Km nesse caso, para quem veio do cadastro
                                          !elem.location.latitude ||
                                          !elem.location.longitude))
      return false;
    return true;
  }

  hasNonBasePoint() {
    if (!this.view['inspections'] || (<[]>this.view['inspections']).length == 0)
      return false;

    return (<[]>this.view['inspections']).some( (elem: InspectionModel) => {
      if (elem.type != PointType.BASE) return true;
      else return false;
    })
  }

  isInspectionsFilled(): boolean {
    if (!this.view['inspections'] || (<[]>this.view['inspections']).length == 0)
      return true; // Não verifica o preenchimento se a lista está vazia

    return this.view['inspections'].every(this.isInspectionFilled);
  }

  getUpdatedOperation() {
    let copiedModel = this.copyModel(this.model);
    this.mapViewToModel();
    let updatedModel = this.model;
    this.model = copiedModel;
    return updatedModel;
  }

  onLocationClick() {
    // Envia os dados temporários modificados durante a edição
    this.glOpenContainer(MAP_PAGE, {operationId: this.getOperationId(), operationType: this.operationType, operation: this.getUpdatedOperation(), // Envia a operação atualizada
                         fileKmlRoute: this.fileKmlRoute, removedKmlRoute: this.removedKmlRoute});
  }

  hasLocation(): boolean {
    return !!this.view['filenameKmlRoute'] || !!this.fileKmlRoute || this.removedKmlRoute ||
           (this.view['inspections'] && (<[]>this.view['inspections']).length > 0);
  }

  // ########################################################
  // GRID METHODS POINTS
  // #######################################################

  /**
   * Atualiza o datasource das TableDataSource do cliente. A tabela de verificações
   */
   buildPointsDataSource(update = 2){
    this.pointsDataSource = new MatTableDataSource(this.view['inspections']);
    this.pointSelection.clear();
    this.updateSavePointsToRegistration();
    this.updateNoAccessInspection();

    if (update == 1){
      if (this.editOnMap) this.sendEditionModeToMap();
    }

    if (update == 2) {
      if (this.view['autoRoute']) this.buildAutoRoute(); // Já chama a sendEditionModeToMap
      else if (this.editOnMap) this.sendEditionModeToMap();
    }
  }

  openConfirmDuplicatedPointDialog(info: string): Promise<any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      panelClass: 'sipd-modal',
      data:{
        msg: 'Já existe um ponto com exatamente as mesmas coordenadas geográficas.',
        linesMsg: 'Deseja substituir os dados do ponto existente na base com os novos dados ou manter os dados existentes?\nDados:\n' + info,
        title: 'Ponto Duplicado',
        discardLabel: 'Manter',
        cancelLabel: 'Cancelar',
        okLabel: 'Substituir',
        showDiscardButton: true
      }
    }).afterClosed().toPromise().then( result => {
      return Promise.resolve(result);
    });
  }

  async saveDuplicatedPoints(duplicatedList: {wasCanceled: boolean, hasSaved: boolean, points: Array<{inspection:InspectionModel, inspectionPoint:InspectionPointModel}>}) {
    let p = duplicatedList.points.pop(); // remove ponto da lista
    if (!p || duplicatedList.wasCanceled) {
      // último ponto duplicado foi processado
      // Se algum deles foi salvo, então mostra lista de pontos e notifica edição de ponto se estiver aberta
      if (duplicatedList.hasSaved) {
        this.glOpenContainer('points', null); // TODO selecionar os pontos modificados/acrescentados
        this.glEmitEvent(SAVE_DATA_PREFIX + "points-edit", null);
      }
      return;
    }

    let inspection:InspectionModel = p.inspection;
    let inspectionPoint:InspectionPointModel = p.inspectionPoint;

    let info = '';

    if(inspectionPoint.band != inspection.band) {
      let infoBand_OLD  = 'Faixa existente: ' + inspectionPoint.band;
      let infoBand_NEW = 'Nova Faixa: ' + inspection.band;
      info +=  infoBand_NEW + ' | ' + infoBand_OLD + '\n';
    }

    if(FieldUtils.kmIntToString(inspectionPoint.km) != inspection.km) {
      let infoKM_OLD  = 'KM existente: ' + FieldUtils.kmIntToString(inspectionPoint.km);
      let infoKM_NEW = 'Novo KM: ' + inspection.km;
      info += infoKM_NEW + ' | ' + infoKM_OLD + '\n';
    }

    if(inspectionPoint.city != inspection.city) {
      let infoCity_OLD  = 'Cidade existente: ' + (inspectionPoint.city ? inspectionPoint.city : '');
      let infoCity_NEW = 'Nova cidade: ' + (inspection.city ? inspection.city : '');
      info += infoCity_NEW + ' | ' + infoCity_OLD + '\n';
    }

    if(inspectionPoint.uf != inspection.state) {
      let infoUF_OLD  = 'UF existente: ' + (inspectionPoint.uf ? inspectionPoint.uf : '');
      let infoUF_NEW = 'Nova UF: ' + (inspection.state ? inspection.state : '');
      info += infoUF_NEW + ' | ' + infoUF_OLD + '\n';
    }

    if (info == '') {
      // se todos os dados forem iguais, apenas adicionar o Id para registrar que ele já está no cadastro
      inspection.inspectionPointId = inspectionPoint.identifier;
      return;
    }

    let result = await this.openConfirmDuplicatedPointDialog(info); // Aqui a execução dessa função é interrompida (mas da função onSavePointsToRegistrationClick não)

    let resp = result.toString();
    if(resp == 'true') { // Substituir
      duplicatedList.hasSaved = true;
      inspectionPoint.band = inspection.band;
      inspectionPoint.km = FieldUtils.kmStringToInt(inspection.km);
      inspectionPoint.city = inspection.city;
      inspectionPoint.uf = inspection.state;
      this.pointService.editRecord(inspectionPoint).pipe(first()).subscribe((savedInspectionPoint: InspectionPointModel) => {
        inspection.inspectionPointId = savedInspectionPoint.identifier;
      },
      err => {
        this.toastr.error("Falha ao atualizar ponto "+inspectionPoint?.identifier);
        this.logger.error(JSON.stringify(err));
      });
    }
    else if(resp == 'false') { // Cancelar
      duplicatedList.wasCanceled = true;
    }
    else { // Manter
    }

    this.saveDuplicatedPoints(duplicatedList); // Segue recursivamente para o próximo ponto e retorna para onSavePointsToRegistrationClick
  }

  saveNewPoint(inspection:InspectionModel){
    let inspectionPoint = new InspectionPointModel();
    inspectionPoint.latLong = inspection.location.latitude + ',' + inspection.location.longitude;
    inspectionPoint.band = inspection.band;
    inspectionPoint.km = FieldUtils.kmStringToInt(inspection.km);
    inspectionPoint.city = inspection.city;
    inspectionPoint.uf = inspection.state;

    inspectionPoint.creationDate = moment().valueOf();
    inspectionPoint.source = 'Web';
    if (this.authorizationService.getLoggedUserType() === UserType.COORDINATOR_CCPD || this.authorizationService.getLoggedUserType()  === UserType.COORDINATOR_OPPD ){
      inspectionPoint.status = PointStatus.APPROVED;
    }
    else {
      inspectionPoint.status = PointStatus.IN_ANALYSIS;
    }

    this.pointService.createRecord(inspectionPoint).pipe(first()).subscribe((savedInspectionPoint: InspectionPointModel) => {
      // Mostra lista de pontos, se já está aberta apenas atualiza
      this.glOpenContainer('points', null); // TODO selecionar os pontos modificados/acrescentados

      // Atualiza esta lista
      inspection.inspectionPointId = savedInspectionPoint.identifier;
    },
    err => {
      this.toastr.error("Falha ao atualizar ponto "+inspectionPoint?.identifier);
      this.logger.error(JSON.stringify(err));
    });
  }

  /** Abre o container de salvar no cadastro de pontos, passando o identificador da operação */
  /** Ao ser clicado, o sistema irá verificar se os novos pontos são ou não duplicatas de pontos existentes no cadastro */
  onSavePointsToRegistrationClick(){
    this.pointService.loadListFromRestApi().pipe(first()).subscribe((inspectionPoints:InspectionPointModel[]) =>{
      let duplicatedList = {wasCanceled: false, hasSaved: false, points: []};

      this.pointSelection.selected?.forEach((inspection:InspectionModel) => {
        let latLong = inspection.location.latitude + ',' + inspection.location.longitude;
        let inspectionPoint:InspectionPointModel = inspectionPoints.find( (inspectionPoint:InspectionPointModel) => inspectionPoint.latLong == latLong);
        if(inspectionPoint) {
          duplicatedList.points.push({inspection: inspection, inspectionPoint: inspectionPoint});
        }
        else {
          this.saveNewPoint(inspection); // TODO salvar todos os pontos em um único endpoint
        }
      });

      if (duplicatedList.points.length > 0) {
        this.saveDuplicatedPoints(duplicatedList);
      }
    });
  }

  onSaveToRegistrationClick(inspection: InspectionModel){
    this.pointService.loadListFromRestApi().pipe(first()).subscribe((inspectionPoints:InspectionPointModel[]) =>{
      let duplicatedList = {wasCanceled: false, hasSaved: false, points: []};

      let latLong = inspection.location.latitude + ',' + inspection.location.longitude;
      let inspectionPoint:InspectionPointModel = inspectionPoints.find( (inspectionPoint:InspectionPointModel) => inspectionPoint.latLong == latLong);
      if(inspectionPoint) {
        duplicatedList.points.push({inspection: inspection, inspectionPoint: inspectionPoint});
      }
      else {
        this.saveNewPoint(inspection); // TODO salvar todos os pontos em um único endpoint
      }

      if (duplicatedList.points.length > 0) {
        this.saveDuplicatedPoints(duplicatedList);
      }
    });
  }

  canSaveToRegistration(inspection: InspectionModel){
    if (!inspection || inspection.inspectionPointId || !inspection.km) {
      return false;
    }
    return true;
  }

  /** Abre o diálogo de confirmação para remoção de pontos */
  onDeletePointsClick(){
    if (this.pointSelection.isEmpty()){
      this.toastr.warning('Selecione um ponto para excluir');
      return;
    }

    const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: 'Confirma a remoção dos pontos selecionados?',
        title: 'Remover Pontos',
        okLabel: 'Remover'
      }
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result){
        this.deleteSelectedPoints();
      }
    },
    error => this.logger.error(error),
    () => openConfirmationSubscription?.unsubscribe());
  }

  /**
   * Remove os pontos e atualiza o datasource
   */
   private deleteSelectedPoints(){
    this.pointSelection.selected.forEach( (selectedItem: InspectionModel) => {
        const id = this.view['inspections'].indexOf(selectedItem);
        this.view['inspections'].splice(id, 1);
    });

    this.pointSelection.clear();
    this.buildPointsDataSource();
  }

  onDeletePointClick(row: InspectionModel){
    const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data:{
        msg: 'Remover ponto(s) selecionado(s)?',
        title: 'Remover Ponto',
        okLabel: 'Remover'
      }
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result){
        const id = this.view['inspections'].indexOf(row);
        this.view['inspections'].splice(id, 1);
        this.buildPointsDataSource();
      }
    },
    error => this.logger.error(error),
    () => openConfirmationSubscription?.unsubscribe());
  }

  onMovePointUp(){
    const point = this.pointSelection.selected[0];
    const id = this.view['inspections'].indexOf(point);

    if (id === 0) {
      return;
    }

    this.view['inspections'].splice(id, 1);
    this.view['inspections'].splice(id - 1, 0, point);

    // Não chama a buildPointsDataSource() propositadamente,
    // Porque estamos mudando apenas a ordem dos pontos
    this.pointsDataSource = new MatTableDataSource(this.view['inspections']);
    if (this.view['autoRoute']) this.buildAutoRoute();
    if (this.editOnMap) this.sendEditionModeToMap();
  }

  onMovePointDown(){
    const point = this.pointSelection.selected[0];
    const id = this.view['inspections'].indexOf(point);

    if (id === this.view['inspections'].length-1) {
      return;
    }

    this.view['inspections'].splice(id, 1);
    this.view['inspections'].splice(id + 1, 0, point);

    // Não chama a buildPointsDataSource() propositadamente,
    // Porque estamos mudando apenas a ordem dos pontos
    this.pointsDataSource = new MatTableDataSource(this.view['inspections']);
    if (this.view['autoRoute']) this.buildAutoRoute();
    if (this.editOnMap) this.sendEditionModeToMap();
  }

  onImportPointsClick(){
    let dialogRef = this.dialog.open(ImportPointsDialogComponent, {
      data:this.view['inspections'],
      panelClass: 'sipd-modal'
    });

    dialogRef.afterClosed().pipe(first()).subscribe( (inspectionPoints:InspectionPointModel[]) => {
      let inspections:InspectionModel[] = this.view['inspections'];

      if(inspectionPoints && inspectionPoints.length > 0) {
        inspectionPoints.forEach((inspectionPoint:InspectionPointModel) => {
            let inspection = new InspectionModel();
            inspection.inspectionPointId = inspectionPoint.identifier;
            inspection.km = FieldUtils.kmIntToString(inspectionPoint.km);
            let latlong:any = inspectionPoint.latLong.split(",");
            inspection.location.latitude  = latlong[0];
            inspection.location.longitude = latlong[1];
            inspection.band = inspectionPoint.band;
            inspection.city = inspectionPoint.city;
            inspection.state = inspectionPoint.uf;
            inspection.name = inspection.band + ' ' + inspection.km;
            inspections.push(inspection);
        });
        this.view['inspections'] = inspections;
        this.buildPointsDataSource();
      }
    });
  }

  onInsertPointClick(){
    const insertPointDialogComponent = this.dialog.open(InsertPointDialogComponent, {
      width: '800px',
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe( (result) => {
      if (result) {
        this.view['inspections'].push(result);
        this.buildPointsDataSource();
      }
    },
    error => this.logger.error(error),
    () => insertPointDialogComponent?.unsubscribe());
  }

  onEditPointClick(row: InspectionModel){
    const insertPointDialogComponent = this.dialog.open(InsertPointDialogComponent, {
      width: '800px',
      data: row,
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result) {
        this.buildPointsDataSource();
      }
    },
    error => this.logger.error(error),
    () => insertPointDialogComponent?.unsubscribe());
  }

  onCopyPointClick(row: InspectionModel)  {
    let newPoint: InspectionModel = this.copyModel(row);
    newPoint.status = InspectionStatusType.NOT_STARTED;
    newPoint.inspectionPointNoAccess = false;

    const insertPointDialogComponent = this.dialog.open(InsertPointDialogComponent, {
      width: '800px',
      data: newPoint,
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result) {
        this.view['inspections'].push(result);
        this.buildPointsDataSource();
      }
    },
    error => this.logger.error(error),
    () => insertPointDialogComponent?.unsubscribe());
  }

  onKmlImportPointClick(){
    const importPointsKmlDialogComponent = this.dialog.open(ImportPointsKmlDialogComponent, {
      width: '800px',
      data: this.view['inspections'],
      panelClass: 'sipd-modal'
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result) {
        this.buildPointsDataSource();
      }
    },
    error => this.logger.error(error),
    () => importPointsKmlDialogComponent?.unsubscribe());
  }

  /**
   * Ação de alteração de ponto em base
   */
  onChangeBasePoint(item: any) {
    if (item['type'] !== PointType.BASE) {
      item['type'] = PointType.BASE;
    } else {
      item['type'] = PointType.CRITICAL_POINT;
    }

    if (this.editOnMap) this.sendEditionModeToMap();
  }

  kmlStringToFile(kmlStr: string){
    let strArray = [];
    strArray.push(kmlStr);
    let blob = new Blob(strArray);
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(blob);
    let file = new File(arrayOfBlob, '[Rota Automatizada]');
    return file;
  }

  getKmlRoute(resultGeoJson) {
    let kmlStr = tokml(resultGeoJson, {
      name: 'Rota Automatizada',
      description: 'description'
    });

    let fileKmlRoute = this.kmlStringToFile(kmlStr);
    return fileKmlRoute;
  }

  buildKmlRoute(resultGeoJson){
    let fileKmlRoute = resultGeoJson? this.getKmlRoute(resultGeoJson): undefined;
    if (!fileKmlRoute) {
      // Limpa a rota sem afetar o flag de rota automática
      this.clearKmlRoute();
      this.view['autoRoute'] = true;
    }
    else {
      this.fileKmlRoute = fileKmlRoute;
      this.removedKmlRoute = false;
      this.view['filenameKmlRoute'] = "[Rota Automatizada]";
      this.view['KmlRouteId'] = ObjectID.generate(); // Para que a mudança possa ser detectada mesmo que o nome do arquivo seja identico ao anterior
    }

    // envía os dados temporarios para o mapa
    if (this.editOnMap) this.sendEditionModeToMap();
  }

  buildAutoRoute(){
    let stops = [];

    let inspections: InspectionModel [] = this.view['inspections'];
      
    inspections.forEach(inspection => {
      const coordinates = [+inspection.location.longitude, +inspection.location.latitude]; // Note que é invertido em coordinates (longitude, latitude)
      stops.push(coordinates)
    });

    if(stops.length>1){
      esri_routing.solveRoute({
        stops: stops,
        endpoint: 'https://route.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World',
        authentication
      }).then(response => {
        const resultGeoJson = response.routes.geoJson as any;
        let coordinates = resultGeoJson.features[0].geometry.coordinates;
        if(coordinates){
          this.buildKmlRoute(resultGeoJson);
          this.toastr.info("Rota automática gerada!");
        }
      }).catch((error) => {
        this.logger.error(error);
        this.toastr.warning("Ocorreu um problema ao utilizar o serviço de rota automática do ArcGIS. Tente novamente mais tarde.");
      });
    }
    else { // 1 ponto ou menos
      this.buildKmlRoute(null); // Limpa a rota automática se existia uma
    }
  }

  OnChangeAutomaticRoute() {
    if(this.view['autoRoute']){ // se o botão estava marcado
      this.view['autoRoute'] = false;
      if (this.editOnMap) this.sendEditionModeToMap();
    }
    else{ //se o botão não estava marcado
      //verifico se a janela do mapa esta aberta em modo de edição
      if(this.editOnMap){
        const openConfirmationSubscription = this.dialog.open(ConfirmationDialogComponent, {
          width: '480px',
          panelClass: 'sipd-modal',
          disableClose: true,
          data:{
            msg: 'Atenção! Esta operação irá descartar quaisquer edições realizadas na rota. Confirma a operação?',
            title: 'Mensagem',
            okLabel: 'Confirmar'
          }
        }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
          if (result){
            this.view['autoRoute'] = true;
            this.buildAutoRoute();
          }
          else{
             this.view['autoRoute'] = false;
          }
        },
        error => this.logger.error(error),
        () => openConfirmationSubscription?.unsubscribe());
      }
      else {
        this.view['autoRoute'] = true;
        this.buildAutoRoute();
      }
    }
  }

  /**
   * Verifica se ponto é uma base
   */
  isBasePoint(item: any) {
    return item['type'] === PointType.BASE;
  }

  formatPointActivities(activities: Array<string>) {
    // Usado apenas para visualização na lista de pontos
    let ret: string = "";

    if (!activities)
      return ret;

    for( let i=0; i<activities.length; i++) {
      if (i==0) {
        ret = activities[i];
      }
      else {
        ret += " | " + activities[i];
      }
    }

    return ret;
  }

  updateSavePointsToRegistration() {
    if(!this.pointSelection || this.pointSelection.selected.length == 0){
      this.denySavePointsToRegistration = true;
      return;
    }

    this.denySavePointsToRegistration = false;
    this.pointSelection.selected.forEach((inspection:InspectionModel) => {
      if (inspection.inspectionPointId || // Se já está no cadastro ou
          !inspection.km) {  // Falta o km
        this.denySavePointsToRegistration = true;
      }
    });
  }

  masterTogglePoints() {
    if(this.isAllSelectedPoints()) {
      this.pointSelection.clear();
    }
    else {
      this.pointsDataSource.filteredData.forEach(row => this.pointSelection.select(row));
    }
    this.updateSavePointsToRegistration();
  }

  isAllSelectedPoints(){
    const numSelected = this.pointSelection.selected.length;
    const numRows = this.pointsDataSource.filteredData.length;
    return numSelected === numRows;
  }

  checkboxTipPoints(row?: InspectionModel): string {
    if (!row) {
      return `${this.isAllSelectedPoints() ? 'Desmarcar' : 'Selecionar'} Todos`;
    }
    return `${this.pointSelection.isSelected(row) ? 'Desmarcar' : 'Selecionar'} Ponto`;
  }

  onCheckboxPointsClick(row) {
    this.pointSelection.toggle(row);
    this.updateSavePointsToRegistration();
  }

  sendEditionModeToMap(openMap?) {
    let data = {
      editionMode: this.editOnMap,
      autoRoute: this.view['autoRoute'],
      operationId: this.getOperationId(),
      operation: this.getUpdatedOperation(), // Envia a operação atualizada
      inspections: this.view['inspections'],
      fileKmlRoute: this.fileKmlRoute,
      removedKmlRoute: this.removedKmlRoute}; // Envia os dados temporários modificados durante a edição

    if (openMap)
      this.glOpenContainer(MAP_PAGE, data);
    else
      this.glEmitEvent(FILL_DATA_PREFIX + MAP_PAGE, data);
  }

  onEditOnMapClick(){
    if(!this.editOnMap) this.editOnMap = true;
    else this.editOnMap = false;

    this.sendEditionModeToMap(true);
  }

  private subscribeOnLocationUpdate(){
    this.glSubscribeEvent(LOCATION_UPDATE_PREFIX + 'operation-edit', (data) => {
      // Emitido quando uma informação de localização da operação (pontos ou rotas) é modificada diretamente no mapa (vem do mapa)
      if (data.id == this.getOperationId()) {
        this.logger.debug("OperationEditComponent.OnLocationUpdate");
        if (data.clearEditionMode) {
          this.editOnMap = false;
          return;
        }
        else {
          if (data.inspections) {
            this.view['inspections'] = this.copyModel(data.inspections);
            this.buildPointsDataSource(0);
            this.toastr.info("Pontos atualizados a partir do mapa!");
          }

          if (data.removedKmlRoute) {
            this.clearKmlRoute(true);
            this.view['autoRoute'] = data.autoRoute; // O mapa só consegue mudar para false (essa atribuição é redundante)
            this.toastr.info("Rota removida a partir do mapa!");
          }
          else if (data.fileKmlRoute) {
            this.fileKmlRoute = data.fileKmlRoute;
            this.view['KmlRouteId'] = ObjectID.generate(); // Para cada modificação gera um novo Id
            this.view['filenameKmlRoute'] = "[Rota Editada]";
            this.removedKmlRoute = false;
            this.view['autoRoute'] = data.autoRoute; // O mapa só consegue mudar para false
            this.toastr.info("Rota atualizada a partir do mapa!");
          }
          else {
            // Rota não foi modificada no mapa, mas os pontos foram
            // Verifica se é rota automatizada e gera uma nova
            if (data.inspections && this.view['autoRoute']) {
              this.buildAutoRoute();
            }
          }
        }
      }
    });
  }

  onHistoricalTrackingClick() {
    this.glOpenContainer(MAP_PAGE, {historicalTrackingOperation: this.model});
  }

  canHasHistoricalTracking(): boolean {
    let operation = this.model as OperationModel;
    if (operation && operation.status != OperationStatus.PLANNED
                  && operation.status != OperationStatus.SENT)
      return true;
    else
      return false;
  }
  
  onMessagesClick() {
    this.openMessageComponent(<OperationModel>this.model);
  }
  
  openMessageComponent(operation: OperationModel){
    const messageData: MessageComponentData = new MessageComponentData();
    messageData.componentType = MessageComponentType.OPERATION_MESSAGE;
    messageData.operationId = operation.id;
    messageData.operationType = operation.type;
    this.glOpenContainer(MESSAGE_PAGE, messageData);
  }
  
  isOperationArchiveButtonHiddenForUser() {
     this.isHiddenButtonArchiveForUser = !this.authorizationService.isOperationArchiveAllowed();     
  }
  
  canArchive(): boolean{
    if (!this.model)
      return false;

    if (this.authorizationService.isAdmin())
      return true;

    let canArchive = this.authorizationService.isControl();
    if (canArchive) {
      if (!this.hasArchivalRequirements(this.model as CommonModel))
        canArchive = false;
    }

    return canArchive;
  }

  // Sobreescrito para verificações e rondas
  hasArchivalRequirements(model: CommonModel){
    return true;
  }

  onArchiveClick(){
    let entities: EntityModel[] = [];
    entities.push(this.model);
    this.operationService.archive(entities).pipe(first()).subscribe( values => {
      let archiveButtonLabel = this.model['archived'] ? 'Arquivar' : 'Desarquivar';
      this.toastr.info('Ação de ' + archiveButtonLabel + ' ' + this.modelName + ' foi realizada com sucesso.');
      this.view['archived'] = this.model['archived'];
      this.updateInitialView('archived');
      this.glEmitEvent(SAVE_DATA_PREFIX + this.componentName, {entity: this.model});
    }, error => this.logger.error(error));
  }

  replaceRunTimeData(data){
    // Substitui mudanças dinamicas, que independem da edição do usuário

    this.replaceModelViewData(data, 'updatedAt'); // Não converter como data
    this.replaceModelViewData(data, 'expired');
    this.replaceModelViewData(data, 'status');
    this.replaceModelViewData(data, 'archived');
    this.replaceModelViewData(data, 'executedPoints');
    this.replaceModelViewData(data, 'situationFound');
    this.replaceModelViewData(data, 'dateSituationFound'); // Não converter como data
    this.replaceModelViewData(data, 'lastSync');

    ////////// InspectionStatus e InspectionAccess
    let updatedInspections: InspectionModel [] = data.inspections;
    let modelInspections: InspectionModel [] = this.model['inspections'];
    let viewInspections: InspectionModel [] = this.view['inspections'];
    let initialViewInspections: InspectionModel [] = this.initialView['inspections'];

    // Para todas as inspeções que vieram do backend
    // Atualiza somente os dados de InspectionForm, InspectionStatus e InspectionAccess dos pontos em memória
    // Temos 3 listas para atualizar: model, view e initialView
    updatedInspections.forEach(updatedInspection => {
      let modelinspection = modelInspections.find( isp => isp.identifier == updatedInspection.identifier)
      if (modelinspection) {
        this.updateInspection(modelinspection, updatedInspection);
      }

      let viewInspection = viewInspections.find( isp => isp.identifier == updatedInspection.identifier)
      if (viewInspection) {
        this.updateInspection(viewInspection, updatedInspection);
      }

      // Não dá para chamar essas duas funções, então fazemos uma atualização manual da initialView
      // this.backupInitialView();  -- copiaria tudo, mesmo se temos algumas modificações em outros lugares
      // this.updateInitialView('inspections');  -- copiaria apenas a referência

      let initialViewInspection = initialViewInspections.find( isp => isp.identifier == updatedInspection.identifier)
      if (initialViewInspection) {
        this.updateInspection(initialViewInspection, updatedInspection);
      }
    });

    this.pointsDataSource = new MatTableDataSource(this.view['inspections']);
    this.updateNoAccessInspection();
  }

  private updateInspection(inspection: InspectionModel, updatedInspection: InspectionModel){
    inspection.status = updatedInspection.status;
    inspection.inspectionPointNoAccess = updatedInspection.inspectionPointNoAccess;
    inspection.inspectionForms = updatedInspection.inspectionForms;
  }  

  getFormNameAndInspection(form) {
    if (!form) { return null; }

    let title = '';
    if (form['name']) {
      title += form['name'];
    }

    if (form['inspectionType']) {
      title += '     |' + form['inspectionType'] ;
    }

    return title;
  }

  compareWithFn(form1, form2) {
    return form1 && form2 ? form1.id === form2.id : form1 === form2;
  }

  getOperationStatus(): string {
    if (this.view['archived'])
      return "Arquivada";
    else if (this.view['status'])
      return OperationStatusDescription[this.view['status']];
    else
      return '';
  }
  
  /**verifica se o(s) ponto(s) selecionados podem ser removidos da lista
   * Só podem ser removidos da lista se a operação é planejada ou enviada
   * Nas outras opções, verifica se o id do ponto é diferente de null e que não esteja  iniciada, em percurso ou concluído
    */
   canDeleteInspectionsPoints(rows : InspectionModel[]): boolean{

    if((this.view['status'] === OperationStatus.PLANNED) || (this.view['status'] === OperationStatus.SENT)) return true;
    
    let canDelete = true; 
    if(rows.length>0){      
      for(let i = 0; i < rows.length ; i++){
        if(rows[i].identifier && (rows[i].status === InspectionStatusType.STARTED || 
                                  rows[i].status === InspectionStatusType.ON_THE_WAY || 
                                  rows[i].status === InspectionStatusType.FINISHED))
        {
          canDelete = false;
          break
        }
      }
    }
    return canDelete;    
  }

  canDeleteInspectionPoint(inspection: InspectionModel){
    if(!inspection) return;
    
    if((this.view['status'] === OperationStatus.PLANNED) || (this.view['status'] === OperationStatus.SENT)) return true;
    
    if (inspection.identifier && (inspection.status === InspectionStatusType.STARTED || 
                                  inspection.status === InspectionStatusType.ON_THE_WAY || 
                                  inspection.status === InspectionStatusType.FINISHED)) 
    {
      return false;
    }
    return true;
  }

  // Atualiza a lista de pontos de inspeção sem acesso
  updateNoAccessInspection(){
    let noAccessInspectionPoints : InspectionModel[] = [];
    
    if(!this.view['inspections']) return;

    this.view['inspections'].forEach((inspection, index) => {
      if(inspection.inspectionPointNoAccess == true){        
        let inspectionNoAccess:InspectionModel  = this.copyModel(inspection)
        inspectionNoAccess.number = ""+(index+1);
        noAccessInspectionPoints.push(inspectionNoAccess);
      }
          
    });
    
    this.noAccessPointsDataSource = new MatTableDataSource(noAccessInspectionPoints);
  }

  updateFilteredCompanies(){
    if (!this.view['placement'] || this.entityCacheService.getCompanies().length == 0) {
      this.filteredCompanies = [];
    }
    else {
      this.filteredCompanies = this.entityCacheService.getCompanies().filter((company:CompanyModel)=>{return company.placement?.id === this.view['placement'].id;})
      this.checkObjectInOptionData('company', this.filteredCompanies);
    }
  }

  updateFilteredTeams(){
    this.filteredTeams = this.entityCacheService.getPatrolTeams().filter((team:PatrolTeamModel )=>{
      return (team.company?.id === this.view['company']?.id && team?.serviceType === this.view['serviceType']) ;
    });
    this.checkObjectInOptionData('patrolTeam', this.filteredTeams);
    ListUtils.orderSimpleModelList(this.filteredTeams);

    // O primeiro da lista será uma equipe vazia, que permite limpar o campo Equipe
    // O que identifica a equipe vazia é a não existem do nome da equipe
    //this.filteredTeams.unshift(this.getEmptyPatrolTeam());
  }

  filterByService(){
    // Tipo de Serviço mudou, precisa atualizar as Equipes
    if(!this.view['company'] || !this.view['serviceType'] ){
      this.filteredTeams = [];
    }
    else{
      this.updateFilteredTeams();
    }    
  }

  filterByCompany(){
    // Empresa mudou, precisa atualizar as Equipes
    this.filterByService();
  }

  filterByPlacement(){
    // Lotação mudou, precisa atualizar Empresas e Equipes

    // Filtra as empresas pela Lotação escolhida
    this.updateFilteredCompanies();
    // Limpa a empresa selecionada
    this.view['company'] = null;
    this.view['serviceType'] = null;
    this.view['patrolTeam'] = null;

    this.filterByCompany();
  }

  /** Se é uma operação cópia, atualiza os dados da equipe
   * A equipe depende da lotação
   */
  updatePatrolTeamForCopy(){
    if(!this.copy) return;
    
    const index = this.filteredTeams.findIndex(team => team.id === this.model['patrolTeam'].id); 
    if(index >= 0 ) { 
      this.view['patrolTeam'] = this.filteredTeams[index]; 
    }else{// pode ser que a equipe tem mudado de lotação
      this.view['patrolTeam'] = this.getEmptyPatrolTeam();        
    }
      
    this.backupInitialView();
  }

  /** Se é uma operação cópia, atualiza os dados da empresa
   * A empresa depende da lotação
   */
   updateCompanyForCopy(){
    if(!this.copy) return;
   
    const index = this.filteredCompanies.findIndex(company => company.id === this.model['company'].id);     
    if(index >= 0 ) {
      this.view['company'] = this.filteredCompanies[index];    
    }else{// pode ser que a equipe tenha mudado de lotação
      this.view['company'] = null        
    }
      
    this.backupInitialView();
  }
  
  /**Se o campo (key) está salvo no modelo, verifica se a opção está na lista a ser apresentada e que não seja um pedido de cópia.
   * Assim adiciona na lista para restaurar a informação como foi salva. */
  checkObjectInOptionData(key, optionArray){
    console.log('OperationEditComponent.checkObjectInOptionData ', key );   
    if(this.model[key]){
      // Procura o item anterior na lista de opções
      const object = optionArray.find(option =>  option.id === this.model[key].id)    
      if(!object && this.copy) {
        // Se não tem o item anterior, então adiciona um item em branco
        if (key == "patrolTeam") {
          this.view[key] = this.getEmptyPatrolTeam();
        }
        else {
          this.view[key] = "";
        }
        this.updateInitialView(key);
      }
    }
  }

  /**** MÉTODOS PARA ANEXAR ARQUIVO NA OPERAÇÃO */

  /** Adiciona um arquivo anexo
   * file: arquivo
   * attachmentNumber: número de anexo
   */
  addFile(file: File, attachmentNumber: AttachmentNumber): void {
    this.logger.debug('OperationEditComponent.addFile()');

    if (file) { // Usuário selecionou um arquivo

      this.logger.debug('File size : ', file.size);
      // 1. Valida o peso do arquivo <=2Mb
      if(file.size > MAX_FILE_SIZE){        
        this.clearOperationFile(true, attachmentNumber, false);
        const title = 'Arquivo Inválido!';
        const message = 'Só podem ser carregados arquivos com até 2Mb';
        this.toastr.error(message, title);        
        return; 
      }

      let attachmentFile = new AttachmentModel();
      let filename = file.name.replace(/[\x00-\x1f?<>:"/\\*|]/g, '_');
      attachmentFile.name = filename.split('.').slice(0, -1).join('.');
      attachmentFile.extension = file.name.split('.').pop();
      attachmentFile.type = file.type;

      switch(attachmentNumber){
        case AttachmentNumber.ONE:
          this.attachmentFile1 = file;
          this.view['attachmentFile1'] = this.attachmentFile1.name;
          this.view['attachmentOpFile1'] = attachmentFile;
          break;
        case AttachmentNumber.TWO:
          this.attachmentFile2 = file;
          this.view['attachmentFile2'] = this.attachmentFile2.name;
          this.view['attachmentOpFile2'] = attachmentFile;
          break; 
        case AttachmentNumber.THREE:
          this.attachmentFile3 = file;
          this.view['attachmentFile3'] = this.attachmentFile3.name;
          this.view['attachmentOpFile3'] = attachmentFile;
          break; 
        case AttachmentNumber.FOUR:
          this.attachmentFile4 = file;
          this.view['attachmentFile4'] = this.attachmentFile4.name;
          this.view['attachmentOpFile4'] = attachmentFile;
          break; 
        case AttachmentNumber.FIVE:
          this.attachmentFile5 = file;
          this.view['attachmentFile5'] = this.attachmentFile5.name;
          this.view['attachmentOpFile5'] = attachmentFile;
          break;        
      }
    }
    else {
      this.clearOperationFile(true, attachmentNumber, true);
    }
  }

  /** Deleta o arquivo da vista
   * Faz um backup do arquivo, em caso mais para frente precisar desfazer as modificações  */
  clearOperationFile(removed, attachmentNumber: AttachmentNumber, backup: boolean){

    switch(attachmentNumber){
      case AttachmentNumber.ONE:
        if(backup) this.back_attachmentFile1 = this.attachmentFile1;
        this.attachmentFile1 = undefined;
        this.view['attachmentFile1'] = undefined;
        this.view['attachmentOpFile1'] = undefined;
        break;
      case AttachmentNumber.TWO:
        if(backup) this.back_attachmentFile2 = this.attachmentFile2;
        this.attachmentFile2 = undefined;
        this.view['attachmentFile2'] = undefined;
        this.view['attachmentOpFile2'] = undefined;
        break;
      case AttachmentNumber.THREE:
        if(backup) this.back_attachmentFile3 = this.attachmentFile3;
        this.attachmentFile3 = undefined;
        this.view['attachmentFile3'] = undefined;
        this.view['attachmentOpFile3'] = undefined;
        break;
      case AttachmentNumber.FOUR:
        if(backup) this.back_attachmentFile4 = this.attachmentFile4;
        this.attachmentFile4 = undefined;
        this.view['attachmentFile4'] = undefined;
        this.view['attachmentOpFile4'] = undefined;
        break; 
      case AttachmentNumber.FIVE:
        if(backup) this.back_attachmentFile5 = this.attachmentFile5;
        this.attachmentFile5 = undefined;
        this.view['attachmentFile5'] = undefined;
        this.view['attachmentOpFile5'] = undefined;
        break;
    }
  }
  
  /** Chama o método para apresentar ou baixar o arquivo anexo */
  openOperationFile(fileName: string, attachmentNumber: AttachmentNumber) : void {
    
    switch(attachmentNumber){
      case AttachmentNumber.ONE:
        this.viewFile(this.attachmentFile1 , this.view['attachmentOpFile1'] );
        break;
      case AttachmentNumber.TWO:
        this.viewFile(this.attachmentFile2 , this.view['attachmentOpFile2']);
        break;
      case AttachmentNumber.THREE:
        this.viewFile(this.attachmentFile3 , this.view['attachmentOpFile3']);
        break;
      case AttachmentNumber.FOUR:
        this.viewFile(this.attachmentFile4 ,  this.view['attachmentOpFile4']);
        break;
      case AttachmentNumber.FIVE:
        this.viewFile(this.attachmentFile5 ,  this.view['attachmentOpFile5']);
        break;
    }
   }   
   
   /** chamado após desfazer as modificações */
   restoreFiles(){
    if(this.back_attachmentFile1) this.attachmentFile1 = this.back_attachmentFile1;
    if(this.back_attachmentFile2) this.attachmentFile2 = this.back_attachmentFile2;
    if(this.back_attachmentFile3) this.attachmentFile3 = this.back_attachmentFile3;
    if(this.back_attachmentFile4) this.attachmentFile4 = this.back_attachmentFile4;
    if(this.back_attachmentFile5) this.attachmentFile5 = this.back_attachmentFile5;
  }

  /** Add o nome do arquivo para cada anexo da operação */
  private extractAttachmentFileName(){
      
    let operation: OperationModel = <OperationModel>this.model;
    
    if(operation.attachmentOpFile1)
      operation.attachmentOpFile1 = this.view['attachmentOpFile1'];
    
    if(operation.attachmentOpFile2)
      operation.attachmentOpFile2 = this.view['attachmentOpFile2'];
    
    if(operation.attachmentOpFile3)
      operation.attachmentOpFile3 = this.view['attachmentOpFile3'];
    
    if(operation.attachmentOpFile4)
      operation.attachmentOpFile4 = this.view['attachmentOpFile4'];
    
    if(operation.attachmentOpFile5)
      operation.attachmentOpFile5 = this.view['attachmentOpFile5'];
  
  }
  
  updateAttachmentData() {
    if(this.model['attachmentOpFile1'])
     this.getAttachmentFile(this.model['attachmentOpFile1'], AttachmentNumber.ONE );

    if(this.model['attachmentOpFile2'])
     this.getAttachmentFile(this.model['attachmentOpFile2'], AttachmentNumber.TWO );

    if(this.model['attachmentOpFile3'])
     this.getAttachmentFile(this.model['attachmentOpFile3'], AttachmentNumber.THREE);

    if(this.model['attachmentOpFile4'])
     this.getAttachmentFile(this.model['attachmentOpFile4'], AttachmentNumber.FOUR);

    if(this.model['attachmentOpFile5'])
     this.getAttachmentFile(this.model['attachmentOpFile5'], AttachmentNumber.FIVE);
  }

  getAttachmentFile(fileData: AttachmentModel, attachmentNumber: AttachmentNumber){
    this.operationService.loadAttachmentFile(fileData).pipe(first()).subscribe( (file) => {
        switch(attachmentNumber){
          case AttachmentNumber.ONE:
            this.attachmentFile1 = file;
            break;
          case AttachmentNumber.TWO:
            this.attachmentFile2 = file;
            break;
          case AttachmentNumber.THREE:
            this.attachmentFile3 = file;
            break;
          case AttachmentNumber.FOUR:
            this.attachmentFile4 = file;
            break; 
          case AttachmentNumber.FIVE: 
            this.attachmentFile5 = file;
            break;
        }        
      }, (error: any) => this.logger.error(error));
  }
  
}
