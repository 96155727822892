import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthorizationService } from '../../service/authorization/authorization.service';
import { StorageService } from '../../service/storage-service';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { SingleDataService } from '../../service/model/single.data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { SORT_NAME_ASC, MAP_PAGE, FILL_DATA_PREFIX } from '../../common/constants';
import { RegistrationListComponent } from 'src/app/pages/registrations/registration-list-component';
import { ImportGeoKmlDialogComponent } from '../import-geo-kml-dialog/import-geo-kml-dialog.component';
import { SingleDataModel } from '../../model/singleData.model';
import { DcModel } from '../../model/dc.model';
import { EntityModel } from 'src/app/model/entity.model';
import { environment } from 'src/environments/environment';
import { SingleDataCacheService } from 'src/app/service/model/single.data.cache.service';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-single-data-list',
  templateUrl: './single-data-list.component.html',
  styleUrls: ['./single-data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleDataListComponent  extends RegistrationListComponent implements OnInit, OnDestroy {

  dataType: string;
  hasGeometry: boolean;

  constructor(logger:                             NGXLogger,
              protected singleDataService:        SingleDataService,
              public singleDataCacheService:      SingleDataCacheService,
              protected dialog:                   MatDialog,
              public authorizationService:        AuthorizationService,
              protected storageService:           StorageService,
              protected toastr:                   ToastrService,
              protected changeDetector:                ChangeDetectorRef,
              @Inject(GoldenLayoutComponentHost)  protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer)      protected container: GoldenLayout.Container) {
    super(logger, authorizationService, singleDataService, dialog, container.title, "", "",
          "", storageService, changeDetector, toastr, goldenLayout, container);

    const dataInfo = singleDataService.getDataInfo(container.title);
    this.dataType = dataInfo.type;
    this.tabTitle = dataInfo.groupLabel;
    this.title = dataInfo.titleLabel;
    this.modelName = dataInfo.modelLabel;
    this.hasGeometry = dataInfo.geometry;

    this.registrationType = this.dataType;

    this.sortOptions = SORT_NAME_ASC;
    if (this.dataType == "Dc") {
      this.sortOptions = "description,ASC";
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.logger.debug('SingleDataListComponent.ngOnInit()');
    if (this.hasGeometry) {
      this.displayedColumns = ['select', 'name', 'geometry'];
    }
    else{
      this.displayedColumns = ['select', 'name'];
    }
  }

  loadRecordsFromServer() {
    this.logger.debug('SingleDataListComponent.loadRecordsFromServer()');

    let params: HttpParams = new HttpParams();
    params = params.append("metadata", this.dataType);

    this.dataSource = new MatTableDataSource([]);
    
    this.loadingOn();
    this.singleDataService.loadFromRestApi(this.paginator? 0: null, this.paginator? this.initialPageSize: null, this.sortOptions, params).pipe(first()).subscribe( (result) => {
      this.updateDataSource(result);
      this.loadingOff();
    }, error => {      
      this.logger.error(error);      
      this.loadingOff();
    });
  }

  postLoadProcess() {
    if (this.dataType == "Dc") {
      this.model.forEach((dc: DcModel) => {
        DcModel.updateName(dc);
      });
    }

    this.model.forEach((element: SingleDataModel) => {
      element.type = this.dataType;
    });
  }

  protected getDefaultSortColumn(): string {
    if (this.dataType == "Dc") {
      return "description"
    }

    return "name";
  }

  onImportClick() {
    const importGeoKmlDialogComponent = this.dialog.open(ImportGeoKmlDialogComponent, {
      width: '800px',
      panelClass: 'sipd-modal',
      data: this.dataType
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result) {
        this.loadRecordsFromServer();
        this.glEmitEvent(FILL_DATA_PREFIX + MAP_PAGE, {updatedLayer: this.dataType});
      }
    },
    error => {
      this.logger.error(error);
      this.toastr.error(error, 'Erro!');

    },
    () => importGeoKmlDialogComponent?.unsubscribe());
}

  onPageChanged(event){
    if (environment.useServerPagination) {
      const pageIndex = event.pageIndex;
      
      let params: HttpParams = new HttpParams();
      params = params.append("metadata", this.dataType);

      this.loadingOn();
      this.singleDataService.loadFromRestApi(pageIndex.toString(), this.pageSize, this.sortOptions, params).pipe(first()).subscribe( (result) => {
        this.updateDataSource(result);
        this.loadingOff();
      }, error => {
        this.logger.error(error);
        this.loadingOff();
      });
    }
  }

  onDeleteClick(row: EntityModel, identifier: string, event: any): void {

    const extraParams = new Map();
    extraParams.set('id', row.id);
    extraParams.set('metadata', this.dataType);

    super.onDeleteClick(row, identifier, event, extraParams);
  }

  protected deleteSelectedEntities(selectedEntities: EntityModel[]){
  
    const extraParams = new Map();
    extraParams.set('metadata', this.dataType);

    super.deleteSelectedEntities(selectedEntities, extraParams);
  }

  public postListDeleteProcess(rows: EntityModel[]): void {
    if (this.hasGeometry) {
      this.glEmitEvent(FILL_DATA_PREFIX + MAP_PAGE, {updatedLayer: this.dataType});
    }
  }

  /**
   * Retorna a entidade em formato string, contendo só os campos visualizados na interface, para filtro.
   */ 
   protected getStringEntityForFilter(single: SingleDataModel): string {
    return super.lowerAndTrimText(single.name);
  }

  onLocationManyClick(){
    let list = [];
    this.selection.selected.forEach( (entity) => {
      if (this.hasLocation(entity))
      list.push(entity);
    });

    if (list.length > 0)
      this.glOpenContainer(MAP_PAGE, {layerDataList: list});
    else
      this.toastr.warning("Nenhuma camada georreferenciada selecionada.");
  }

  onLocationClick(row: EntityModel){
    this.glOpenContainer(MAP_PAGE, {layerData: row});
  }

  hasLocation(row: EntityModel){
    if (row && row['geometry'])
      return true;
    return false;
  }
}
