import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from "@angular/core";

@Injectable()
export class MatPaginatorIntlBr extends MatPaginatorIntl{
    itemsPerPageLabel   =   'Itens por Página';
    nextPageLabel       =   'Próxima';
    previousPageLabel   =   'Anterior';
    lastPageLabel       =   'Última página';
    firstPageLabel       =   'Primeira página';

    getRangeLabel       =   function(page, pageSize, length){
        if(length === 0){return 'Nenhum item'};

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : (startIndex + pageSize);

        return startIndex + 1 + ' - ' + endIndex + ' de ' + length
    }
}
