import { MatPaginatorIntlBr } from './general/internationalization/mat-paginator-intl-br';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, ApplicationRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { InvalidTokenApiService } from './interceptors/invalid-token-api-service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HammerModule } from '@angular/platform-browser';
import { AppComponent }        from './app.component';
import { GlobalErrorHandlerService } from './error/global-error-handler.service';
import { LayerService } from './service/model/layer.service';
import { RouteGeographicalService } from './service/model/route-geographical-service';
import { StorageService } from './service/storage-service';
import { TestDataGeneratorService } from './general/test-data-generator/test-data-generator.service';
import { CtrlClickDirective } from './directives/ctrl-click.directive';
import { EventModule } from './pages/event/event.module';
import { RegistrationsModule } from './pages/registrations/registrations.module';
import { PatrolModule } from './pages/patrol/patrol.module';
import { GeneralModule } from './general/general.module';
import { VerificationModule } from './pages/verification/verification.module';
import { TrackingModule } from './pages/tracking/tracking.module';
import { LoginModule } from './pages/login/login.module';
import { CentralModule } from './pages/central/central.module';
import { CookieService } from 'ngx-cookie-service';
import { MatSidenavModule } from '@angular/material/sidenav';

import { TrackingComponent } from './pages/tracking/tracking.component';
import { EventListComponent } from './pages/event/event-list/event-list.component';
import {
  GoldenLayoutModule,
  ComponentType
} from 'ngx-golden-layout';

import * as $ from 'jquery';
import { EventEditComponent } from './pages/event/event-edit/event-edit.component';
import { PatrolEditComponent } from './pages/patrol/patrol-edit/patrol-edit.component';
import { PatrolListComponent } from './pages/patrol/patrol-list/patrol-list.component';
import { CompanyEditComponent } from './pages/registrations/company/company-edit/company-edit.component';
import { CompanyListComponent } from './pages/registrations/company/company-list/company-list.component';
import { PatrolTeamEditComponent } from './pages/registrations/patrolTeam/patrol-team-edit/patrol-team-edit.component';
import { PatrolTeamListComponent } from './pages/registrations/patrolTeam/patrol-team-list/patrol-team-list.component';
import { PlacementEditComponent } from './pages/registrations/placement/placement-edit/placement-edit.component';
import { PlacementListComponent } from './pages/registrations/placement/placement-list/placement-list.component';
import { UserEditComponent } from './pages/registrations/user/user-edit/user-edit.component';
import { UserListComponent } from './pages/registrations/user/user-list/user-list.component';
import { VehicleEditComponent } from './pages/registrations/vehicle/vehicle-edit/vehicle-edit.component';
import { VehicleListComponent } from './pages/registrations/vehicle/vehicle-list/vehicle-list.component';
import { VerificationEditComponent } from './pages/verification/verification-edit/verification-edit.component';
import { VerificationListComponent } from './pages/verification/verification-list/verification-list.component';
import { CentralComponent } from './pages/central/central.component';
import { EventMainActionsListComponent } from './pages/registrations/event-main-actions/event-main-actions-list/event-main-actions-list.component';
import { EventMainActionsEditComponent } from './pages/registrations/event-main-actions/event-main-actions-edit/event-main-actions-edit.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MapComponent } from './pages/map/map.component';
import { MapModule } from './pages/map/map.module';
import { TRACKING_PAGE, MAP_PAGE, MESSAGE_PAGE } from './common/constants';
import { PointListComponent } from './pages/registrations/point/point-list/point-list.component';
import { PointEditComponent } from './pages/registrations/point/point-edit/point-edit.component';
import { FormEditComponent } from './pages/registrations/form/form-edit/form-edit.component';
import { FormListComponent } from './pages/registrations/form/form-list/form-list.component';
import { ObservedAreaEditComponent } from './pages/registrations/observed-area/observed-area-edit/observed-area-edit.component';
import { ObservedAreaListComponent } from './pages/registrations/observed-area/observed-area-list/observed-area-list.component';
import { ChannelListComponent } from './pages/channel/channel-list/channel-list.component';
import { ChannelModule } from './pages/channel/channel.module';
import { ProfileListComponent } from './pages/registrations/profile/profile-list/profile-list.component';
import { ProfileEditComponent } from './pages/registrations/profile/profile-edit/profile-edit.component';
import { MessagePageComponent } from './pages/message-page/message-page.component';
import { MessagePageModule } from './pages/message-page/message-page.module';
import { SingleDataListComponent } from './general/single-data-list/single-data-list.component';
import { SingleDataEditComponent } from './general/single-data-edit/single-data-edit.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LogLevel, Configuration, BrowserCacheLocation, ProtocolMode } from '@azure/msal-browser';
import { LoginErrorComponent } from './general/login-error/login-error.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
 const msalConfig: Configuration = {
   auth: {
       clientId: 'd865034b-a9c5-4e4a-8c7f-b55e1c8dd810',//'9854c99b-8a76-4760-8b90-8c48c409b592', // This is the ONLY mandatory field that you need to supply.
       authority: 'https://login.microsoftonline.com/46f6a780-86e1-4570-9459-bb97b7d99f9d', // Defaults to "https://login.microsoftonline.com/common"
       redirectUri: window.location.origin, //app.component.html. You must register this URI on Azure portal/App Registration.
       postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
       navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
   },
   cache: {
       cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
       storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
   },
   system: {
       loggerOptions: {
           loggerCallback(logLevel: LogLevel, message: string) {
               console.log(message);
           },
           logLevel: LogLevel.Verbose,
           piiLoggingEnabled: false
       }
   }
 }


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {

  if (environment.development){
      msalConfig.auth.clientId = 'd865034b-a9c5-4e4a-8c7f-b55e1c8dd810';
  }
  else {
    if (environment.hmg) { // Homologação
      msalConfig.auth.clientId = '9854c99b-8a76-4760-8b90-8c48c409b592';
    }
    else { // Produção
      msalConfig.auth.clientId = '3a21b411-083a-4853-8372-d5939f0fa00c';
    }
  }

  console.log("redirectUri = " + msalConfig.auth.redirectUri)
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

window['$'] = $;

// Define all component types known at compile time to the golden-layout binding.
// It's possible to modify these at runtime using the `ComponentRegistryService`

export const componentTypes: ComponentType[] = [
  {
    name: MAP_PAGE,
    type: MapComponent
  },
  {
    name: MESSAGE_PAGE,
    type: MessagePageComponent
  },
  {
    name: 'event-main-actions',
    type: EventMainActionsListComponent
  },
  {
    name: 'event-main-actions-edit',
    type: EventMainActionsEditComponent
  },
  {
    name: TRACKING_PAGE,
    type: TrackingComponent
  },
  {
    name: 'central',
    type: CentralComponent
  },
  {
    name: 'events',
    type: EventListComponent
  },
  {
    name: 'events-edit',
    type: EventEditComponent
  },
  {
    name: 'patrols-edit',
    type: PatrolEditComponent
  },
  {
    name: 'patrols',
    type: PatrolListComponent
  },
  {
    name: 'verifications-edit',
    type: VerificationEditComponent
  },
  {
    name: 'verifications',
    type: VerificationListComponent
  },
  {
    name: 'patrol-teams-edit',
    type: PatrolTeamEditComponent
  },
  {
    name: 'patrol-teams',
    type: PatrolTeamListComponent
  },
  {
    name: 'users-edit',
    type: UserEditComponent
  },
  {
    name: 'users',
    type: UserListComponent
  },
  {
    name: 'profiles-edit',
    type: ProfileEditComponent
  },
  {
    name: 'profiles',
    type: ProfileListComponent
  },
  {
    name: 'Band',
    type: SingleDataListComponent
  },
  {
    name: 'Band-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'GasDuct',
    type: SingleDataListComponent
  },
  {
    name: 'GasDuct-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'OilDuct',
    type: SingleDataListComponent
  },
  {
    name: 'OilDuct-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Simf',
    type: SingleDataListComponent
  },
  {
    name: 'Simf-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Dc',
    type: SingleDataListComponent
  },
  {
    name: 'Dc-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'KilometerMark',
    type: SingleDataListComponent
  },
  {
    name: 'KilometerMark-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'DeliveryPoint',
    type: SingleDataListComponent
  },
  {
    name: 'DeliveryPoint-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Refinary',
    type: SingleDataListComponent
  },
  {
    name: 'Refinary-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Terminal',
    type: SingleDataListComponent
  },
  {
    name: 'Terminal-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Valve',
    type: SingleDataListComponent
  },
  {
    name: 'Valve-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'PipeStretch',
    type: SingleDataListComponent
  },
  {
    name: 'PipeStretch-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'CommunicationChannel',
    type: SingleDataListComponent
  },
  {
    name: 'CommunicationChannel-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'ReportSource',
    type: SingleDataListComponent
  },
  {
    name: 'ReportSource-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Category168',
    type: SingleDataListComponent
  },
  {
    name: 'Category168-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Subcategory168',
    type: SingleDataListComponent
  },
  {
    name: 'Subcategory168-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'City',
    type: SingleDataListComponent
  },
  {
    name: 'City-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'Product',
    type: SingleDataListComponent
  },
  {
    name: 'Product-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'State',
    type: SingleDataListComponent
  },
  {
    name: 'State-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'InspectionType',
    type: SingleDataListComponent
  },
  {
    name: 'InspectionType-edit',
    type: SingleDataEditComponent
  },
  {
    name: 'companies-edit',
    type: CompanyEditComponent
  },
  {
    name: 'companies',
    type: CompanyListComponent
  },
  {
    name: 'placements-edit',
    type: PlacementEditComponent
  },
  {
    name: 'placements',
    type: PlacementListComponent
  },
  {
    name: 'vehicles-edit',
    type: VehicleEditComponent
  },
  {
    name: 'vehicles',
    type: VehicleListComponent
  },
  {
    name: 'points-edit',
    type: PointEditComponent
  },
  {
    name: 'points',
    type: PointListComponent
  },
  {
    name: 'forms-edit',
    type: FormEditComponent
  },
  {
    name: 'forms',
    type: FormListComponent
  },
  {
    name: 'observed-areas-edit',
    type: ObservedAreaEditComponent
  },
  {
    name: 'observed-areas',
    type: ObservedAreaListComponent
  },
  {
    name: 'channels',
    type: ChannelListComponent
  },
  {
    name: 'login-error',
    type: LoginErrorComponent
  }

];

@NgModule({
  declarations: [
    AppComponent,
    CtrlClickDirective
  ],
  imports: [
    ToastrModule.forRoot({ preventDuplicates: true, enableHtml: true }),
    HammerModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDividerModule,
    FlexLayoutModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      // @ts-ignore
      timestampFormat: 'HH:mm:ss.SSS',
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    HttpClientModule,
    EventModule,
    RegistrationsModule,
    PatrolModule,
    VerificationModule,
    TrackingModule,
    CentralModule,
    LoginModule,
    GeneralModule,
    MapModule,
    MessagePageModule,
    ChannelModule,
    GoldenLayoutModule.forRoot(componentTypes),
    MsalModule
  ],
  providers: [
    LayerService,
    RouteGeographicalService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: InvalidTokenApiService,
      multi: true
    },
    { provide: ErrorHandler,
      useClass:
      GlobalErrorHandlerService
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    StorageService,
    {provide:  MatPaginatorIntl, useClass: MatPaginatorIntlBr},
    TestDataGeneratorService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
