<div mat-dialog-title>
    <span>{{ data?.crudType == crudType.ADD ? 'Nova' : 'Editar' }} Pergunta</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>
  
<div mat-dialog-content>
    <div fxLayout="column wrap" fxLayoutGap=".5rem">
        <!-- Pergunta -->
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Pergunta</mat-label>
            <textarea matInput rows="5" placeholder="Digite a pergunta" required
                [(ngModel)]="formQuizNode.label"></textarea>
            <mat-error>Campo Obrigatório</mat-error>
        </mat-form-field>
        <!-- Tipo de Resposta -->
        <single-autocomplete [data]="nodeTypeDescription | keyvalue" placeholder="Tipo de Resposta"
            [(valueData)]="formQuizNode.type" name="value" optionValue="key" fxFlex>
        </single-autocomplete>

        <!-- Evidência obrigatória -->
        <div class="form-section" >
            <mat-label id="evidence-label">Anexar mídia:</mat-label>
            <mat-radio-group aria-labelledby="evidence-label" [(ngModel)]="formQuizNode.evidenceRequirement" class="w-100 d-block">
                <mat-radio-button color="primary"
                    *ngFor="let requirement of evidenceRequirement | keyvalue: originalOrder" [value]="requirement.key">
                    {{ evidenceRequirementDescription[requirement.value] }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="upsertQuestion()" [disabled]="isMissingMandatoryField()">{{ data?.crudType == crudType.ADD ? 'Inserir' : 'Alterar' }}</button>
    <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
