<div class="sipd-container ">
  <div class="sipd-content bg-shade-50">
    <div class="p-2">
      <label id="period-group-label " class="mb-2 w-100">Arquivados:</label>
      <mat-radio-group class="d-flex flex-column" aria-labelledby="period-group-label"
        [(ngModel)]="filterModel.archived">
        <mat-radio-button class="mr-4 my-2" color="accent" [value]="archivedFilterType.ALL">Todos
        </mat-radio-button>
        <mat-radio-button class="mr-4 mb-2" color="accent" [value]="archivedFilterType.ARCHIVED">Arquivados
        </mat-radio-button>
        <mat-radio-button color="accent" [value]="archivedFilterType.NON_ARCHIVED">Não Arquivados
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="p-2">
      <label id="period-group-label " class="mb-2 w-100">Período:</label>
      <mat-radio-group class="d-flex flex-column" aria-labelledby="period-group-label"
        [(ngModel)]="filterModel.current">
        <mat-radio-button class="mr-4 mb-2" color="accent" [value]="true">Hoje</mat-radio-button>
        <mat-radio-button color="accent" [value]="false">Especificado</mat-radio-button>
      </mat-radio-group>
    </div>

    <div [hidden]="filterModel.current == true">
      <div class="d-flex flex-column p-2">
        <date-chooser placeholder="Data Inicial" [(valueData)]="viewFilterStartDate" #startDateEl="dateChooserContext" fxFlex></date-chooser>

        <mat-form-field appearance="outline" class="no-hint-text" fxFlex>
          <mat-label>Hora Inicial</mat-label>
          <input matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="startTime" placeholder="Hora Inicial"
            [(ngModel)]="viewFilterStartTime">
          <mat-icon matSuffix>query_builder</mat-icon>
        </mat-form-field>

        <date-chooser placeholder="Data Final" [(valueData)]="viewFilterEndDate" #endDateEl="dateChooserContext" fxFlex></date-chooser>

        <mat-form-field class="mr-2" appearance="outline" class="no-hint-text" fxFlex>
          <mat-label>Hora Final</mat-label>
          <input matInput mask="Hh:m0" [dropSpecialCharacters]="false" name="endTime" placeholder=""
            [(ngModel)]="viewFilterEndTime">
          <mat-icon matSuffix>query_builder</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-column p-2">
      <multiselect-autocomplete class="no-hint-text" [data]="eventStatusDescription | keyvalue: originalOrder" placeholder="Status"
        name="value" optionValue="key" [(valueData)]="filterModel.status" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="resultOccurrenceDescription | keyvalue: originalOrder" placeholder="Ocorrência"
        name="value" optionValue="key" [(valueData)]="filterModel.occurrences" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="analysts" placeholder="Analista"
        [(valueData)]="filterModel.analysts" name="name" optionValue="id" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="singleDataCacheService.getValues('CommunicationChannel')" placeholder="Canal de Comunicação"
        [(valueData)]="filterModel.channels" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="singleDataCacheService.getValues('ReportSource')" placeholder="Origem"
        [(valueData)]="filterModel.sources" fxFlex>
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="singleDataCacheService.getValues('Duct')" placeholder="Duto"
        [(valueData)]="filterModel.ducts" fxFlex *ngIf="false">
      </multiselect-autocomplete>

      <multiselect-autocomplete class="no-hint-text" [data]="singleDataCacheService.getValues('Valve')" placeholder="Válvula"
        [(valueData)]="filterModel.valves" fxFlex *ngIf="false">
      </multiselect-autocomplete>

      <mat-form-field appearance="outline" class="no-hint-text" fxFlex>
        <mat-label>KM Inicial</mat-label>
        <input name="startKM" matInput placeholder="KM Inicial" matTooltip="KM Inicial"
          [(ngModel)]="filterModel.startKM" #startKM="ngModel" startKM [dropSpecialCharacters]="false" mask="000+000" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="no-hint-text" fxFlex>
        <mat-label>KM Final</mat-label>
        <input name="endKM" matInput placeholder="KM Final" matTooltip="KM Final" [(ngModel)]="filterModel.endKM"
          #endKM="ngModel" endKM [dropSpecialCharacters]="false" mask="000+000" />
      </mat-form-field>

      <div class="form-spacer" fxFlex></div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="m-2 py-2" fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0">
    <button mat-raised-button (click)="clearFilter()" fxFlex>Limpar</button>
    <button mat-raised-button [disabled]="startDateEl.invalid || endDateEl.invalid" (click)="onFilter()" fxFlex>Aplicar</button>
  </div>
</div>
