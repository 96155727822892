import { Component, Inject, OnInit } from '@angular/core';
import { BaseGoldenPanel } from 'src/app/pages/base-golden-panel/base-golden-panel';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import * as GoldenLayout from 'golden-layout';
import { AuthenticationService } from 'src/app/pages/login/login-services';


@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent  extends BaseGoldenPanel implements OnInit {

  error: string;

  constructor(public logger: NGXLogger,
              private authenticationService: AuthenticationService,
              @Inject(GoldenLayoutComponentHost) protected goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
                super(logger, goldenLayout, container);
              }

  ngOnInit(): void {    
    this.error = localStorage.getItem("Login Error");
    super.glUpdateTabTitle("Erro!"); 
  }

  resetLogin(){
    this.authenticationService.logout();
  }
}
