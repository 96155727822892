<mat-form-field appearance="outline" [color]="controlColor">
	<mat-label>{{placeholder}}</mat-label>
	<input matInput [placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="dataControl" [required]="_required" [disabled]="_disabled"
		(focusin)="focusInFunction()" (input)="onSearchChange($event.target.value)" #someInput>
	<mat-error>Campo Obrigatório</mat-error>
	<mat-icon matSuffix disabled>keyboard_arrow_down</mat-icon>
	<mat-autocomplete #auto="matAutocomplete" (opened)="onOpenedEvent($event)" (closed)="onClosedEvent($event)"
	 (optionSelected)="onSelectionEvent($event)" [displayWith]="displayFn">
	<mat-option *ngIf="!_required" [value]="">{{ '' }}</mat-option>
	<mat-option *ngIf="extraData" [value]="extraData">{{ getData(extraData) }}</mat-option>
	<mat-option *ngFor="let data of filteredData | async" [value]="data">
		{{ getData(data) }}
	</mat-option>
	</mat-autocomplete>
</mat-form-field>