import { OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as GoldenLayout from 'golden-layout';
import { GoldenLayoutComponent, GoldenLayoutComponentHost, GoldenLayoutContainer } from 'ngx-golden-layout';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/service/authorization/authorization.service';
import { PointService } from 'src/app/service/model/point.service';
import { StorageService } from 'src/app/service/storage-service';
import { environment } from 'src/environments/environment';
import { PointFilterDialogComponent } from '../point-filter-dialog/point-filter-dialog.component';
import { PointStatusDescription, InspectionBooleanDescription, RegistrationType} from '../../../../model/enums.enum';
import { PointFilterModel } from '../point-filter-dialog/point.filter.model';
import { InspectionPointModel } from 'src/app/model/inspection.point.model';
import { ESP, MAP_PAGE } from 'src/app/common/constants';
import FieldUtils from '../../../../service/util/field-utils';
import { ImportPointsXlsDialogComponent } from '../../../../general/operation/import-points-xls-dialog/import-points-xls-dialog.component';
import { UserService } from '../../../../service/model/user.service';
import { RegistrationListComponent } from '../../registration-list-component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-point-list',
  templateUrl: './point-list.component.html',
  styleUrls: ['./point-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointListComponent extends RegistrationListComponent implements OnInit, OnDestroy {

  pointStatusDescription    = PointStatusDescription;
  inspectionBooleanDescription = InspectionBooleanDescription;

  pointUpdateSubscription: Subscription;
  usersSubscription:       Subscription;

  kmIntToString = FieldUtils.kmIntToString;

  constructor(logger:                           NGXLogger,
              protected pointService:           PointService,
              public toastr:                    ToastrService,
              protected changeDetector:                  ChangeDetectorRef,
              dialog:                           MatDialog,
              public userService:               UserService,
              public authorizationService:      AuthorizationService,
              protected storageService:         StorageService,
              @Inject(GoldenLayoutComponentHost) protected  goldenLayout: GoldenLayoutComponent,
              @Inject(GoldenLayoutContainer) protected container: GoldenLayout.Container) {
    super(logger, authorizationService, pointService, dialog, 'points', environment.POINT_GROUP_LABEL, environment.POINT_TITLE_LABEL,
          environment.POINT_MODEL_LABEL, storageService, changeDetector, toastr, goldenLayout, container);
    logger.debug('PointListComponent.constructor()');
    this.registrationType = RegistrationType.INSPECTION_POINT;
    this.sortOptions = 'identifier,ASC';
  }

  ngOnInit() {
    super.ngOnInit();

    this.logger.debug('PointListComponent.ngOnInit()');

    this.displayedColumns = ['select',
                            'identifier',
                            'status',
                            'source',
                            'creationDate',
                            'band',
                            'stretch',
                            'km',
                            'dc',
                            'accessType',
                            'category',
                            'vulnerabilityView',
                            'city',
                            'uf',
                            'reference'];
  }

  protected getDefaultSortColumn(): string {
    return "identifier";
  }

  protected newFilterModel() {
    return new PointFilterModel();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.pointUpdateSubscription?.unsubscribe();
    this.usersSubscription?.unsubscribe();
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(PointFilterDialogComponent, {
      width: '800px',
      data: this.filterModel,
      panelClass: 'sipd-modal'

    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.loadRecordsFromServer();
      }
    });
  }

  onImportPointsClick(){
    const importPointsXlsDialogComponent = this.dialog.open(ImportPointsXlsDialogComponent, {
      width: '800px',
      panelClass: 'sipd-modal',
      data: this.loggedUser
    }).afterClosed().pipe(first()).subscribe( (result: boolean) => {
      if (result) {
        this.loadRecordsFromServer();
      }
    },
    error => this.logger.error(error),
    () => importPointsXlsDialogComponent?.unsubscribe());
  }

  onLocationManyClick(){
    let inspectionPointList = [];
    this.selection.selected.forEach( (inspectionPoint: InspectionPointModel) => {
      if (this.hasLocation(inspectionPoint))
        inspectionPointList.push(inspectionPoint);
    });

    if (inspectionPointList.length > 0)
      this.glOpenContainer(MAP_PAGE, {inspectionPointList: inspectionPointList});
    else
      this.toastr.warning("Nenhum ponto selecionado possui (Lat, Long) definido.");
  }

  onLocationClick(row: InspectionPointModel){
    this.glOpenContainer(MAP_PAGE, {inspectionPoint: row});
  }

  hasLocation(row: InspectionPointModel){
    if (row && row.latLong)
      return true;
    return false;
  }
  
  protected getStringEntityForFilter(point: InspectionPointModel): string {
    const identifier = super.lowerAndTrimText(point.identifier);
    const status = point.status ? super.lowerAndTrimText(this.pointStatusDescription[point.status]) : '';
    const source = super.lowerAndTrimText(point.source);
    const creationDate = super.formatDate(point.creationDate);
    const band = super.lowerAndTrimText(point.band);
    const stretch = this.lowerAndTrimText(point.stretch);
    const km = FieldUtils.kmIntToString(point.km);
    const dc = point.dc ? super.lowerAndTrimText(this.inspectionBooleanDescription[point.dc]): '';
    const accessType = super.lowerAndTrimText(point.accessType);
    const category = super.lowerAndTrimText(point.category);
    const vulnerabilityView = super.lowerAndTrimText(point.vulnerabilityView);
    const city = super.lowerAndTrimText(point.city);
    const uf = super.lowerAndTrimText(point.uf);
    const reference = super.lowerAndTrimText(point.reference);    

    return identifier + ESP + status + ESP + source + ESP + creationDate + ESP + band + ESP + stretch + ESP + km + ESP + dc + ESP + accessType + ESP + category + ESP + vulnerabilityView + ESP + city + ESP + uf + ESP + reference;
  }

  glOnPopout() {
    this.setListPopoutData({filterModel: this.filterModel});
  }

  glOnPopin() {
    this.setListPopoutData({filterModel: this.filterModel});
  }

}
