<div class="sipd-container">
<div class="center">
    <h1>SIPD - Erro de Autorização!</h1>
    <h4>Não foi possível autorizar a entrada do usuário.</h4>
    <h4>Erro: {{error}}</h4>
    <h4>Por favor entre em contato com o suporte.</h4>
    <button mat-raised-button (click)="resetLogin()">Limpar Dados e Reiniciar</button>
</div>   
<div class="container">    
</div>
</div>
