<div *ngIf="loggedUser">
  <mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem">
        <div fxFlex="50px">
          <img mat-card-sm-image class="w-100" src="assets/images/ccpd-logo-branco.png" />
        </div>
        <div fx-flex fxLayout="column" class="logo-type">
          <span>{{environment.APP_TITLE}}</span>
          <span class="mat-small m-0" (ctrl-click)="onControlClick()">Versão {{environment.APP_VERSION}} {{environment.development? '(DSV)': ''}}{{environment.hmg? ' [Hmg]': ''}}{{environment.production? '': ' [Local]'}}</span>
        </div>
      </div>
      <div *ngIf="hasUserProfile" fxLayout="row" fxLayoutAlign="center center" fxFill fxFlex class="toolbar-button-div">
        <div  #mainMenu>
          <button mat-button class="mat-button-action" value="central" (click)="glOpenContainerFromMainMenu('central')" title="{{environment.CENTRAL_GROUP_LABEL}}"
            [hidden]='isHiddenButtonListAlert'>
            {{environment.CENTRAL_GROUP_LABEL}}
          </button>
          <button mat-button class="mat-button-action" value="tracking" (click)="glOpenContainerFromMainMenu('tracking')" title="{{environment.TRACKING_GROUP_LABEL}}"
          [hidden]= "isHiddenButtonTrackingTeam">
            {{environment.TRACKING_GROUP_LABEL}}
          </button>
          <button mat-button class="mat-button-action" value="events" (click)="glOpenContainerFromMainMenu('events')" title="{{environment.EVENT_GROUP_LABEL}}"
          [hidden]='isHiddenButtonListEvents'>
            {{environment.EVENT_GROUP_LABEL}}
          </button>
          <button mat-button class="mat-button-action" value="verifications" (click)="glOpenContainerFromMainMenu('verifications')" title="{{environment.VERIFICATION_GROUP_LABEL}}"
          [hidden]='isHiddenButtonListVerification'>
            {{environment.VERIFICATION_GROUP_LABEL}}
          </button>
          <button mat-button class="mat-button-action" value="patrols" (click)="glOpenContainerFromMainMenu('patrols')" title="{{environment.PATROL_GROUP_LABEL}}"
          [hidden]='isHiddenButtonListPatrols'>
            {{environment.PATROL_GROUP_LABEL}}
          </button>
          <button mat-button class="mat-button-action" value="channels" (click)="glOpenContainerFromMainMenu('channels')" title="{{environment.CHANNEL_GROUP_LABEL}}"
          [hidden]='isHiddenButtonListChannels'>
            {{environment.CHANNEL_GROUP_LABEL}}
          </button>
        </div>
        <button mat-button class="mat-button-action" 
          [matMenuTriggerFor]="adminPopMenu"
          [hidden]='isHiddenButtonAdministration'>
          {{environment.ADMINISTRATION_GROUP_LABEL}}
          <mat-icon class="material-icons-outlined">arrow_drop_down</mat-icon>
        </button>
        <button mat-button class="mat-button-action" 
          [matMenuTriggerFor]="registerPopMenu"
          [hidden]='isHiddenButtonRegistration'>
          {{environment.REGISTRATION_GROUP_LABEL}}
          <mat-icon class="material-icons-outlined">arrow_drop_down</mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
        <div #mapButton>
          <button mat-icon-button value="map" (click)="glOpenContainerFromMainMenu('map')" title="{{environment.MAP_TITLE_LABEL}}" matTooltip="{{environment.MAP_TITLE_LABEL}}">
            <mat-icon class="material-icons-outlined icon-lg">public</mat-icon>
          </button>
        </div>
        <mat-icon class="material-icons-outlined">person</mat-icon>
        <span class="mat-body-2" (ctrl-click)="onControlClickUser()" matTooltip="{{loggedUser?.name}}&#13;[{{ authorizationService.getLoggedProfileName() }}]">
          {{loggedUser?.login}}
        </span>
        <button mat-icon-button (click)="logoutClick($event)" (ctrl-click)="logoutClick($event)" matTooltip="Logout">
          <mat-icon class="material-icons-outlined">logout</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-menu #adminPopMenu="matMenu">
    <div #adminMenu>
      <button mat-menu-item value="patrol-teams" (click)="glOpenContainerFromMainMenu('patrol-teams')" title="{{environment.PATROL_TEAM_GROUP_LABEL}}" 
      [hidden]='isHiddenButtonListPatrolTeam'>
        <mat-icon class="material-icons-outlined">group</mat-icon>
        <span>{{environment.PATROL_TEAM_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="users" (click)="glOpenContainerFromMainMenu('users')" title="{{environment.USERS_GROUP_LABEL}}"
      [hidden]='isHiddenButtonListUsers'>
        <mat-icon class="material-icons-outlined">person_add</mat-icon>
        <span>{{environment.USERS_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="profiles" (click)="glOpenContainerFromMainMenu('profiles')" title="{{environment.PROFILES_GROUP_LABEL}}"
      [hidden]='isHiddenButtonListProfiles'>
        <mat-icon class="material-icons-outlined">supervised_user_circle</mat-icon>
        <span>{{environment.PROFILES_GROUP_LABEL}}</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item value="vehicles" (click)="glOpenContainerFromMainMenu('vehicles')" title="{{environment.VEHICLES_GROUP_LABEL}}"
          [hidden]='isHiddenButtonListVehicles'>
        <mat-icon class="material-icons-outlined">directions_car</mat-icon>
        <span>{{environment.VEHICLES_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="companies" (click)="glOpenContainerFromMainMenu('companies')" title="{{environment.COMPANY_GROUP_LABEL}}"
      [hidden]='isHiddenButtonListCompany'>
        <mat-icon class="material-icons-outlined">domain</mat-icon>
        <span>{{environment.COMPANY_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="placements" (click)="glOpenContainerFromMainMenu('placements')" title="{{environment.PLACEMENT_GROUP_LABEL}}"
      [hidden]='isHiddenButtonListPlacement'>
        <mat-icon class="material-icons-outlined">business_center</mat-icon>
        <span>{{environment.PLACEMENT_GROUP_LABEL}}</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item value="event-main-actions" (click)="glOpenContainerFromMainMenu('event-main-actions')" title="{{environment.EVENT_MAIN_ACTIONS_GROUP_LABEL}}"
      [hidden]='isHiddenButtonMainEvents'>
        <mat-icon class="material-icons-outlined">event_note</mat-icon>
        <span>{{ environment.EVENT_MAIN_ACTIONS_GROUP_LABEL }}</span>
      </button>
      <button mat-menu-item value="observed-area" (click)="glOpenContainerFromMainMenu('observed-areas')" title="{{environment.OBSERVED_AREA_GROUP_LABEL}}"
      [hidden]='isHiddenButtonObservedArea'>
        <mat-icon class="material-icons-outlined">travel_explore</mat-icon>
        <span>{{ environment.OBSERVED_AREA_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="forms" (click)="glOpenContainerFromMainMenu('forms')" title="{{environment.FORM_GROUP_LABEL}}"
      [hidden]='isHiddenButtonForms'>
        <mat-icon class="material-icons-outlined">list_alt</mat-icon>
        <span>{{ environment.FORM_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="points" (click)="glOpenContainerFromMainMenu('points')" title="{{environment.POINT_GROUP_LABEL}}"
      [hidden]='isHiddenButtonPoints'>
        <mat-icon class="material-icons-outlined">app_registration</mat-icon>
        <span>{{ environment.POINT_GROUP_LABEL }}</span>
      </button>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="resetLayout()">
      <mat-icon class="material-icons-outlined">refresh</mat-icon>
      <span>Fechar Janelas</span>
    </button>

  </mat-menu>
  <mat-menu #registerPopMenu="matMenu">
    <div #registerMenu>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Band')" title="{{environment.BANDS_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/duct.png" />
        <span>{{environment.BANDS_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('GasDuct')" title="{{environment.GASDUCT_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/duct.png" />
        <span>{{environment.GASDUCT_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Dc')" title="{{environment.DC_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/dc.png" />
        <span>{{environment.DC_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('KilometerMark')" title="{{environment.KILOMETERMARK_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/km-marker.png" />
        <span>{{environment.KILOMETERMARK_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('OilDuct')" title="{{environment.OILDUCT_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/duct.png" />
        <span>{{environment.OILDUCT_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('DeliveryPoint')" title="{{environment.DELIVERYPOINT_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/delivery-point.png" />
        <span>{{environment.DELIVERYPOINT_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Refinary')" title="{{environment.REFINARY_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/refinery.png" />
        <span>{{environment.REFINARY_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Terminal')" title="{{environment.TERMINAL_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/terminal.png" />
        <span>{{environment.TERMINAL_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Valve')" title="{{environment.VALVE_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/valve.png" />
        <span>{{environment.VALVE_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Simf')" title="{{environment.SIMF_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <img class="mr-3" src="assets/icons/filters/duct.png" />
        <span>{{environment.SIMF_GROUP_LABEL}}</span>
      </button>

      <mat-divider></mat-divider>

      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('CommunicationChannel')" title="{{environment.COMMUNICATION_CHANNEL_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.COMMUNICATION_CHANNEL_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Category168')" title="{{environment.CATEGORY_168_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.CATEGORY_168_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('City')" title="{{environment.CITY_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.CITY_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('State')" title="{{environment.STATE_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.STATE_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('ReportSource')" title="{{environment.REPORT_SOURCE_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.REPORT_SOURCE_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Product')" title="{{environment.PRODUCT_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.PRODUCT_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('Subcategory168')" title="{{environment.SUBCATEGORY_168_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.SUBCATEGORY_168_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('InspectionType')" title="{{environment.INSPECTION_TYPE_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.INSPECTION_TYPE_GROUP_LABEL}}</span>
      </button>
      <button mat-menu-item value="single-data" (click)="glOpenContainerFromMainMenu('PipeStretch')" title="{{environment.STRETCH_GROUP_LABEL}}"
      [hidden]='isHiddenButtonRegistrationGroup'>
        <mat-icon class="material-icons-filled">list</mat-icon>
        <span>{{environment.STRETCH_GROUP_LABEL}}</span>
      </button>
    </div>

  </mat-menu>
</div>
<golden-layout-root #comp [layout]="layout$" (stateChanged)="stateChanged()" class="golden-layout-container">
</golden-layout-root>
