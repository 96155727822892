import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/general/confirmation-dialog/confirmation-dialog.component';
import { PatrolRepetitionModel } from 'src/app/model/patrol.repetition.model';
import { ToastrService } from 'ngx-toastr';
import { PatrolModel } from 'src/app/model/patrol.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { PatrolService } from 'src/app/service/model/patrol.service';
import { PredictedDialogRuleOption } from 'src/app/model/enums.enum';

interface PeriodTypes {
  unit: moment.unitOfTime.DurationConstructor;
  viewValue: string;
}

interface DaysOfWeek {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-patrol-repetition-dialog',
  templateUrl: './patrol-repetition-dialog.component.html',
  styleUrls: ['./patrol-repetition-dialog.component.scss']
})
export class PatrolRepetitionDialogComponent implements OnInit, OnDestroy{

  patrolRepetitionModel: PatrolRepetitionModel = new PatrolRepetitionModel();
  patrol: PatrolModel;

  startDate: moment.Moment = moment().add(1, 'day').startOf('day'); // Horário local - dia default é o inicio do dia de amanhã
  viewStartDate: string = this.startDate.format("DD/MM/yyyy");
  viewStartTime: string = '';

  endDate: moment.Moment;
  viewEndDateFilter: string;
 
  scheduleOption = PredictedDialogRuleOption.CUSTOM;
  startRule = PredictedDialogRuleOption.EVERY_PERIOD;
  finishRule = PredictedDialogRuleOption.NEVER_FINISH;
  
  theEachQuantity: number = 1; // Quantidade de unidades para a próxima repetição, vem 0 se for por dias da semana
  afterRepetitionQuantity:number = 1; // Número de repetições, vem 0 se final não for por número
  
  periodTypesView: PeriodTypes[] = [
    {unit: "days", viewValue: 'Dia(s)'},
    {unit: 'weeks', viewValue: 'Semana(s)'},
    {unit: 'months', viewValue: 'Mese(s)'},
    {unit: 'years', viewValue: 'Ano(s)'},
  ];
  
  unitPeriodSelected: moment.unitOfTime.DurationConstructor = "days";
  
  // ISO Day of Week
  daysOfWeekView: DaysOfWeek[] = [
    {value: 1, viewValue: 'Seg'},
    {value: 2, viewValue: 'Ter'},
    {value: 3, viewValue: 'Qua'},
    {value: 4, viewValue: 'Qui'},
    {value: 5, viewValue: 'Sex'},
    {value: 6, viewValue: 'Sab'},
    {value: 7, viewValue: 'Dom'},
  ];

  selectedDaysOfWeek: boolean[] = [false,false,false,false,false,false,false]; // Array com todos os dias da semana, indicando se está marcado ou não
  daysOfWeek:number[] = []; // Array com apenas os dias marcados
  
  _observableListWeek: BehaviorSubject<DaysOfWeek[]> = new BehaviorSubject(this.daysOfWeekView);
  listweek$:Observable<DaysOfWeek[]> = this._observableListWeek.asObservable();

  constructor(public dialogRef: MatDialogRef<PatrolRepetitionDialogComponent>, 
              @Inject (MAT_DIALOG_DATA) public data: PatrolModel,
              protected confirmDialog: MatDialog,
              private toastr: ToastrService,
              protected patrolService: PatrolService) {

    this.patrol = data;
    this._observableListWeek.asObservable();
  }

  ngOnInit(): void {
    this.viewStartTime = moment(this.patrol.startDate).format("HH:mm");

    // Default para novas repetições
    this.patrolRepetitionModel.referencePatrolId = this.patrol.id;
    this.patrolRepetitionModel.referencePatrolIdentifier = this.patrol.identifier;

    // Tenta carregar a repetição
    this.loadFromServer();
  }

  private copyModel(src){
      return JSON.parse(JSON.stringify(src));
  }

  public get predictedDialogRuleOption(): typeof PredictedDialogRuleOption {
    return PredictedDialogRuleOption; 
  }

  loadFromServer(){
    this.patrolService.getPatrolRepetitionByReferencePatrolId(this.patrol.id).pipe(first()).subscribe((patrolRepetitionModel:PatrolRepetitionModel) => {
      if(patrolRepetitionModel != null) {
        this.patrolRepetitionModel = this.copyModel(patrolRepetitionModel);

        this.startRule = this.patrolRepetitionModel.eachPeriod ? PredictedDialogRuleOption.EVERY_PERIOD : PredictedDialogRuleOption.IN_THE_DAYS;
        
        if(this.patrolRepetitionModel.neverFinish) 
          this.finishRule = PredictedDialogRuleOption.NEVER_FINISH;
        else 
          this.finishRule = this.patrolRepetitionModel.afterNumberRepetitionsToFinish ? PredictedDialogRuleOption.FINISH_AFTER_QUANTITY : PredictedDialogRuleOption.FINISH_DATE;
        
        this.startDate = moment(this.patrolRepetitionModel.startDate);
        this.viewStartDate = this.startDate.format("DD/MM/yyyy");
        this.viewStartTime = this.patrolRepetitionModel.startTime;

        this.theEachQuantity = this.patrolRepetitionModel.theEachQuantity;
        this.unitPeriodSelected = this.patrolRepetitionModel.unitPeriodSelected as moment.unitOfTime.DurationConstructor;

        this.daysOfWeek = this.patrolRepetitionModel.daysOfWeek;
        if(this.daysOfWeek && this.daysOfWeek.length > 0){
          this.populateDaysOfWeek();
        }

        this.afterRepetitionQuantity = this.patrolRepetitionModel.afterRepetitionQuantity;

        this.endDate = this.patrolRepetitionModel.endDate ?  moment(this.patrolRepetitionModel.endDate) : undefined;
        if(this.endDate){
          this.viewEndDateFilter = this.endDate.format("DD/MM/yyyy");;
        }
      } 
    });
  }

  ngOnDestroy(): void {
    this._observableListWeek?.unsubscribe();
    this.listweek$ = undefined;
  }

  fillDaysOfWeek() {
    this.daysOfWeek = [];
    for(let i = 0 ; i < 7; i++){
      if (this.selectedDaysOfWeek[i]){
        this.daysOfWeek.push(i + 1);
      }
    }
  }

  selectDaysOfWeek(day: number, event: MatCheckboxChange) {
    let checkBoxElem: MatCheckbox = event.source;
    if(event.checked) {
      checkBoxElem.checked = true;
      this.selectedDaysOfWeek[day - 1] = true;
    } else {
      checkBoxElem.checked = false;
      this.selectedDaysOfWeek[day - 1] = false;
    }

    this.fillDaysOfWeek();
  }

  private setDayOfWeekFromStartDate(){
    // Ao mudar a data de início resetamos os dias da semana selecionados
    // e selecionamos o dia da semana da data de início
    let day = this.startDate.isoWeekday(); // Sempre 1-7, independe do locale

    this.selectedDaysOfWeek=[false,false,false,false,false,false,false];
    this.selectedDaysOfWeek[day - 1] = true;
    
    this.fillDaysOfWeek();

    this._observableListWeek.next(this.daysOfWeekView);
  }

  private populateDaysOfWeek(){
    this.selectedDaysOfWeek=[false,false,false,false,false,false,false];
    this.daysOfWeek.forEach(day => {
      this.selectedDaysOfWeek[day - 1] = true;
    });

    this._observableListWeek.next(this.daysOfWeekView);
  }

  changeStartRule(){
    if(this.startRule === PredictedDialogRuleOption.EVERY_PERIOD && this.theEachQuantity == 0){
      this.theEachQuantity = 1;
    }
  }

  changeFinishRule(){
    if(this.finishRule === PredictedDialogRuleOption.FINISH_AFTER_QUANTITY && this.afterRepetitionQuantity == 0){
      this.afterRepetitionQuantity = 1;  
    }
    else if (this.finishRule === PredictedDialogRuleOption.FINISH_DATE) {
      this.viewEndDateFilter = " ";
    }
  }

  applyStartTime(timeString:string){
    this.viewStartTime = timeString;
  }

  applyStartDate(startDate:moment.Moment) {
    this.startDate = startDate.startOf('day'); // Horário local

    if(this.startRule === PredictedDialogRuleOption.IN_THE_DAYS) {
      this.setDayOfWeekFromStartDate();
    }
  }

  applyEndDate(dateString:string) {
    this.endDate = moment(dateString, "DD/MM/YYYY", true).startOf('day').hours(23).minutes(59).seconds(59).milliseconds(999); // Horário local
  }

  onRemoveClick() {
    this.confirmDialog.open( ConfirmationDialogComponent, {
      width: '480px',
      panelClass: 'sipd-modal',
      data: {
        msg:  "ATENÇÃO! Esta operação irá remover a repetição e cancelar todas as rondas previstas. Deseja prosseguir?",
        title: "Remover Repetição",
        okLabel: "Remover",
      }
    }).afterClosed().pipe(first()).subscribe((result: boolean) => {
      if (result) {
        this.patrolRepetitionModel.active = false; // só é usado para remover a repetição na volta do diálogo
        this.dialogRef.close(this.patrolRepetitionModel);
      } 
    });
  }

  onSaveClick() {
    if(this.finishRule === PredictedDialogRuleOption.FINISH_DATE){
      let todayDate = moment().startOf('day'); // Horário local
      if(this.endDate.isBefore(this.startDate) || this.endDate.isBefore(todayDate)){
        this.toastr.info("Informe uma data a partir de " + this.startDate.format("DD/MM/yyyy"), "Data Final Inválida");
        return;
      } 
    }

    if(this.startRule === PredictedDialogRuleOption.IN_THE_DAYS){
      if(this.daysOfWeek.length == 0){
        this.toastr.info("Informe pelo menos um dia da semana","Dia(s) Selecionado(s)");
        return;
      }
    }

    this.viewToModel();
    this.dialogRef.close(this.patrolRepetitionModel);
  }

  private viewToModel(){
    this.patrolRepetitionModel.startDate = this.startDate.valueOf();
    this.patrolRepetitionModel.startTime = this.viewStartTime;

    if(this.startRule === PredictedDialogRuleOption.EVERY_PERIOD){
      this.patrolRepetitionModel.dayWeek    = false;
      this.patrolRepetitionModel.eachPeriod = true;
      this.patrolRepetitionModel.daysOfWeek = [];
      this.patrolRepetitionModel.theEachQuantity = this.theEachQuantity;
    } else if(this.startRule === PredictedDialogRuleOption.IN_THE_DAYS){ 
      this.patrolRepetitionModel.dayWeek    = true;
      this.patrolRepetitionModel.eachPeriod = false;
      this.patrolRepetitionModel.theEachQuantity = 0;
      this.patrolRepetitionModel.daysOfWeek = this.daysOfWeek;
    }
    this.patrolRepetitionModel.unitPeriodSelected = this.unitPeriodSelected;

    if(this.finishRule === PredictedDialogRuleOption.NEVER_FINISH) {
      this.patrolRepetitionModel.neverFinish = true;
      this.patrolRepetitionModel.afterNumberRepetitionsToFinish = false;
      this.patrolRepetitionModel.finishDate = false;
      this.patrolRepetitionModel.endDate = undefined;
      this.patrolRepetitionModel.afterRepetitionQuantity = 0;
    } else if(this.finishRule === PredictedDialogRuleOption.FINISH_AFTER_QUANTITY) {
      this.patrolRepetitionModel.neverFinish = false;
      this.patrolRepetitionModel.afterNumberRepetitionsToFinish = true;
      this.patrolRepetitionModel.finishDate = false;
      this.patrolRepetitionModel.endDate = undefined;
      this.patrolRepetitionModel.afterRepetitionQuantity = this.afterRepetitionQuantity;
    } else if(this.finishRule === PredictedDialogRuleOption.FINISH_DATE) { 
      this.patrolRepetitionModel.neverFinish = false;
      this.patrolRepetitionModel.afterNumberRepetitionsToFinish = false;
      this.patrolRepetitionModel.finishDate = true;
      this.patrolRepetitionModel.afterRepetitionQuantity = 0;
      this.patrolRepetitionModel.endDate = this.endDate.valueOf();
    }
  }
}
